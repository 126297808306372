import { Loading } from "../../../components/elements/loading/Loading";
import { FindingShortCard } from "../../findings/findingsCards/FindingShortCard";
import { Icon } from "../../../components/elements/icon/Icon";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { useContext, useRef } from "react";
import { useInfiniteScroll, useScreenWidth } from "../../../hooks/utilsHooks";
import { useApiFindingsPaging } from "../../../hooks/queries/findingContext";
import { Asset } from "../../../types/Asset";
import { useApiFindingsCommentsDeltaData } from "../../../hooks/queries/findingsCommentsContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { objectToBase64 } from "../../../shared/helper";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { SCREEN_MOBILE_WIDTH } from "../../../shared/consts";
import { FindingCard } from "../../findings/findingsCards/FindingCard";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { getProject } from "../../../shared/findingsHelper";

type Props = {
  asset: Asset | undefined;
};

const FindingsCardList = ({ findings_ids }: { findings_ids: number[] }) => {
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const [searchParams] = useSearchParams();
  const { data: projects } = useApiProjects({
    with_findings_counts: true,
  });

  const {
    data: findings,
    isFetchingNextPage: isFetchingFindings,
    hasNextPage: hasFindingsNextPage,
    fetchNextPage: fetchFindingsNextPage,
  } = useApiFindingsPaging({
    id: findings_ids,
    page_size: 25,
    "admin-mode": searchParams.has("admin-mode"),
  });
  const pagedFindings =
    findings?.pages?.map((page) => page?.results || []).flat() || [];

  const { data: unreadComments } = useApiFindingsCommentsDeltaData();

  const observerElemForFetchFindings = useRef(null);

  useInfiniteScroll(
    observerElemForFetchFindings,
    !!hasFindingsNextPage,
    fetchFindingsNextPage
  );

  return (
    <>
      {isFetchingFindings && (
        <div>
          <Loading />
        </div>
      )}
      {pagedFindings.map((finding) =>
        isMobile ? (
          <FindingCard
            finding={finding}
            key={`finding-pane-${finding.id}`}
            onOpenProjectPane={() => {}}
            onSelectedFinding={() => navigate(`/finding-details/${finding.id}`)}
            project={getProject(finding, projects)}
            hasUnreadComments={
              !!unreadComments?.some((c) => c.finding_id === finding.id)
            }
          />
        ) : (
          <FindingShortCard
            key={`finding-pane-${finding.id}`}
            finding={finding}
            hasUnreadComments={
              !!unreadComments?.some((c) => c.finding_id === finding.id)
            }
          />
        )
      )}
      <div className="d-flex" ref={observerElemForFetchFindings} />
    </>
  );
};

const RelatedVulnerabilitiesBox = (props: Props) => {
  const { asset } = props;
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  return (
    <Flex column gap="16px" w100>
      <Flex justify="between" align="center" w100>
        <Flex align="center" gap="8px">
          <Icon name="findings" size={32} color={theme.primary} />
          <HeaderSecondary>Related Vulnerabilities</HeaderSecondary>
        </Flex>
        {!isMobile && (
          <Link
            to={`/findings?filters=${objectToBase64({
              affected_assets: [asset?.id],
            })}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <TextButton label="View in Findings Page" color={theme.primary} />
          </Link>
        )}
      </Flex>
      <Flex
        column
        gap="16px"
        className="pe-1"
        style={{
          maxHeight: isMobile ? "" : "calc(100vh - 470px)",
          overflowY: "scroll",
        }}
      >
        {asset?.related_findings && asset.related_findings?.length !== 0 && (
          <FindingsCardList findings_ids={asset?.related_findings || []} />
        )}
      </Flex>
    </Flex>
  );
};

export default RelatedVulnerabilitiesBox;
