import React from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Illustration } from "../../../components/elements/illustrations/Illustration";

export const ChatWelcomeBanner = () => {
  const { data: me } = useApiMe();
  return (
    <Flex gap="16px" justify="center" align="center" column>
      <Illustration name="education" />
      <Flex gap="8px" justify="center" align="center" column>
        <HeaderMain>Welcome, {me?.first_name}</HeaderMain>
        <BodyBold>
          I'm here to streamline your security operations. How can I assist you
          today?
        </BodyBold>
      </Flex>
    </Flex>
  );
};
