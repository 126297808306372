import { ReactourStep } from "reactour";
import {
  BodyRegular,
  HeaderSecondary,
} from "../components/elements/typography/Typography";
import { Link } from "react-router-dom";
import { TextButton } from "../components/elements/button/text/TextButton";

export const insightsSteps = (theme: any): ReactourStep[] => [
  {
    selector: '[data-tut="most-frequent"]',
    content: () => (
      <>
        <HeaderSecondary>Most Frequently Seen CWEs</HeaderSecondary>
        <BodyRegular>
          Offers a breakdown of findings categorized by Common Weakness
          Enumeration (CWE)
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="prod-exposure"]',
    content: () => (
      <>
        <HeaderSecondary>Products Exposure</HeaderSecondary>
        <BodyRegular>
          Delivers a visual representation of the current security posture
          status, either for specific products or for the entire organization.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="assets-dist"]',
    content: () => (
      <>
        <HeaderSecondary>Assets Distributions</HeaderSecondary>
        <BodyRegular>
          An advanced widget illustrating the distribution of assets in various
          categories.
        </BodyRegular>
        <Link to="/wasp-ai?tour=on" style={{ textDecoration: "none" }}>
          <TextButton label="Go to Next Tour - WASP AI" />
        </Link>
      </>
    ),
  },
];
