import { Option } from "../../../components/elements/dropdowns/Dropdown";
import { SEVERITIES } from "../../../shared/consts";
import { getDate, getDateTimeDaysFromNow } from "../../../shared/helper";
import { AssetsView, AssetsViewProps, Filter } from "../../../types/AssetsView";
import { Product } from "../../../types/Product";
import { priorityOptions } from "../AssetUtils";

export type FilterType =
  | "string"
  | "number"
  | "date"
  | "options"
  | "options_positive"
  | "is_and_not"
  | "list";

export type FilterColumnType = {
  label: string;
  value: string;
  type: FilterType;
};

export const assetsFiltersColumns: FilterColumnType[] = [
  {
    label: "Name",
    value: "name",
    type: "string",
  },
  {
    label: "Type",
    value: "type",
    type: "options",
  },
  {
    label: "Environment",
    value: "environment",
    type: "string",
  },
  {
    label: "Priority",
    value: "priority",
    type: "options",
  },
  {
    label: "Score",
    value: "risk_score",
    type: "number",
  },
  {
    label: "Product",
    value: "product",
    type: "options",
  },
  {
    label: "Tags",
    value: "tags",
    type: "list",
  },
  {
    label: "Creation Date",
    value: "created_at",
    type: "date",
  },
  {
    label: "Root Asset",
    value: "root_asset",
    type: "options_positive",
  },
  {
    label: "Status Code",
    value: "status_code",
    type: "is_and_not",
  },
  {
    label: "Domain Name",
    value: "domain_name",
    type: "string",
  },
  {
    label: "Webpage Title",
    value: "webpage_title",
    type: "string",
  },
  {
    label: "WAF",
    value: "waf",
    type: "string",
  },
  {
    label: "WAF Exists",
    value: "waf_exists",
    type: "options_positive",
  },
  {
    label: "Web Server",
    value: "webserver",
    type: "string",
  },
  {
    label: "IP",
    value: "ip",
    type: "string",
  },
  {
    label: "Technologies",
    value: "technologies",
    type: "list",
  },
  {
    label: "Cnames",
    value: "cnames",
    type: "list",
  },
  {
    label: "Findings Creation Date",
    value: "findings_created_at",
    type: "date",
  },
  {
    label: "Findings Breached SLA",
    value: "findings_breached_sla",
    type: "options_positive",
  },
  {
    label: "Findings Risk",
    value: "findings_risk",
    type: "options",
  },
  {
    label: "Attack Surface Mapping (ASM)",
    value: "is_asm_enabled",
    type: "options_positive",
  },
  {
    label: "PT Status",
    value: "pt_status",
    type: "options",
  },
  {
    label: "Last PT Date",
    value: "last_pt_date",
    type: "date",
  },
  {
    label: "Open Ports",
    value: "ports_data",
    type: "list",
  },
  {
    label: "Source",
    value: "source",
    type: "options",
  },
  {
    label: "Scan ID",
    value: "scan_id",
    type: "string",
  },
  {
    label: "Is Verified",
    value: "is_verified",
    type: "options_positive",
  },
];

export const emptyFilter: Filter = {
  column: "",
  value: "",
  condition: "",
  order: 0,
  next_condition: "and",
};

export const emptyAssetsView: AssetsView = {
  name: "",
  filters: [],
  id: -1,
  created_at: "",
  updated_at: "",
  user: "",
  customer: -1,
};

export const emptyAssetsViewProps: AssetsViewProps = {
  name: "",
  filters: [],
};

export type FilterConditionType = {
  label:
    | "less than"
    | "greater than"
    | "is"
    | "is not"
    | "contains"
    | "not contains"
    | "after"
    | "before";
  value:
    | "lt"
    | "gt"
    | "is"
    | "is not"
    | "contains"
    | "not contains"
    | "after"
    | "before";
  type: FilterType[];
};

export const filterConditions = [
  {
    label: "less than",
    value: "lt",
    type: ["number"],
  },
  {
    label: "greater than",
    value: "gt",
    type: ["number"],
  },
  {
    label: "is",
    value: "is",
    type: ["string", "number", "options", "options_positive", "is_and_not"],
  },
  {
    label: "is not",
    value: "is not",
    type: ["string", "number", "options", "is_and_not"],
  },
  {
    label: "contains",
    value: "contains",
    type: ["string", "list"],
  },
  {
    label: "not contains",
    value: "not contains",
    type: ["string", "list"],
  },
  {
    label: "is after",
    value: "after",
    type: ["date"],
  },
  {
    label: "is before",
    value: "before",
    type: ["date"],
  },
];

export const dateOptions = [
  { label: "Last day", value: 1 },
  { label: "Last three days", value: 3 },
  { label: "Last week", value: 7 },
  { label: "Last Month", value: 30 },
  { label: "Custom date", value: -1 },
];

export const sourceOptions = [
  { label: "WASP", value: "wasp" },
  { label: "AWS", value: "aws" },
  { label: "Cloudflare", value: "cloudflare" },
  { label: "Manual", value: "manual" },
  { label: "AWS", value: "aws" },
];

export const typeOptions = [
  { label: "Domain", value: "domain" },
  { label: "Cloud", value: "cloud" },
  { label: "S3 Bucket", value: "s3_bucket" },
];

export function getFilterLabel(filter: Filter): string {
  return (
    assetsFiltersColumns.find((c) => c.value === filter.column)?.label || ""
  );
}

export function getFilterCondition(filter: Filter): string {
  return (
    filterConditions.find((c) => c.value === filter.condition)?.label || ""
  );
}

export function getFilterValue(filter: Filter, products: Product[]): string {
  const column =
    assetsFiltersColumns.find((c) => c.value === filter.column) ||
    assetsFiltersColumns[0];
  if (column.type.startsWith("options")) {
    const options = getOptionsPerColumn(products)[column.value];
    return (
      options.find((o) => o.value.toString() === filter.value)?.label || ""
    );
  } else if (column.type === "date") {
    if (dateOptions.map((o) => o.value.toString()).includes(filter.value)) {
      return getDate(getDateTimeDaysFromNow(-1 * parseInt(filter.value))) || "";
    }
    return getDate(filter.value) || "";
  }
  return filter.value;
}

export function getOptionsPerColumn(products: Product[]): {
  [key: string]: Option[];
} {
  const TRUE_FALSE_OPTIONS = [
    { value: "True", label: "True" },
    { value: "False", label: "False" },
  ];

  return {
    findings_risk: Object.keys(SEVERITIES).map((k) => {
      return { value: k, label: SEVERITIES[k] };
    }),
    findings_breached_sla: TRUE_FALSE_OPTIONS,
    root_asset: TRUE_FALSE_OPTIONS,
    waf_exists: TRUE_FALSE_OPTIONS,
    is_verified: TRUE_FALSE_OPTIONS,
    is_asm_enabled: [
      { value: "Enabled", label: "Enabled" },
      { value: "Disabled", label: "Disabled" },
    ],
    pt_status: [
      { value: "Scheduled", label: "Scheduled" },
      { value: "Pending", label: "Pending request" },
      { value: "Ongoing", label: "Ongoing" },
      { value: "None", label: "None" },
    ],
    product: products.map((p) => {
      return { value: p.id, label: p.name };
    }),
    priority: priorityOptions,
    type: typeOptions,
    source: sourceOptions,
  };
}

export function isFilterEmpty(filter: Filter): boolean {
  return !filter.column || !filter.value || !filter.condition;
}

export function isValidFilters(filters: Filter[]): boolean {
  return !!filters.length && filters.every((filter) => !isFilterEmpty(filter));
}

export function isValidView(assetView: AssetsViewProps | undefined): boolean {
  return !!assetView && !!assetView.name && isValidFilters(assetView.filters);
}

export function setFiltersOrder(filters: Filter[]): Filter[] {
  return filters.map((filter, index) => ({
    ...filter,
    order: index,
    next_condition: filter.next_condition || "and",
  }));
}

export function mutateAssetsViewWrapper(
  runMutation: any,
  onClose: () => void,
  addToast: any,
  mutateFunc: any,
  data: any,
  beforeMessage: string,
  successMessage: string,
  errorMessage: string
) {
  runMutation(
    () =>
      mutateFunc({
        ...data,
        onSuccessCallback: () => {
          addToast({
            message: successMessage,
            type: "success",
          });
          onClose();
        },
        onErrorCallback: () => {
          addToast({
            message: errorMessage,
            type: "error",
          });
        },
      }),
    beforeMessage
  );
}
