import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { SEVERITIES } from "../../../../shared/consts";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { light } from "../../../../shared/theme";
import { Dispatch, SetStateAction, useContext } from "react";
import { ThemeContext } from "styled-components";
import { SeverityDropdown } from "../../../../components/composed/severityDropdown/SeverityDropdown";
import { useParams } from "react-router";
import {
  getOptionFromKeyValuePairs,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";

type Props = {
  finding: Finding | undefined | null;
  isEditable?: boolean;
  noSeparator?: boolean;
  width?: string;
  isReport?: boolean;
  dropdownVariant?: "border" | "outline";

  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const ExploitabilityItem = (props: Props) => {
  const {
    finding,
    noSeparator = false,
    width,
    isReport = false,
    isEditable,
    dropdownVariant = "outline",
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const theme = useContext(ThemeContext);

  const { id: updateFindingId } = useParams();
  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  return (
    <Section
      title="Exploitability"
      noSeparator={noSeparator}
      width={width}
      isReport={isReport}
    >
      {!isEditable ? (
        <BodyRegular
          style={{ color: isReport ? light.black800 : theme.black800 }}
        >
          {SEVERITIES[finding?.exploitability || 0]}
        </BodyRegular>
      ) : (
        <SeverityDropdown
          placeholder="Select exploitability level"
          dataTestId="exploitability"
          onChange={(opt) => {
            if (opt?.value) {
              setEditableUpdateFinding &&
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  exploitability: Number(opt.value),
                }));
              updateInPlace({ exploitability: Number(opt.value) });
              setCachedFindingData &&
                setCachedFindingData({ exploitability: Number(opt.value) });
            }
          }}
          value={getOptionFromKeyValuePairs(
            SEVERITIES,
            editableUpdateFindingData?.exploitability || 0
          )}
          disabled={queryStatus !== "idle"}
          queryStatus={
            changedField === "exploitability" ? queryStatus : undefined
          }
          variant={dropdownVariant}
        />
      )}
    </Section>
  );
};
