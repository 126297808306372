import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { OpPoc, Project, Report } from "../../../types/Project";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { DatePicker } from "../../../components/elements/datetimePicker/DatePicker";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { ThemeContext } from "styled-components";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { WarningModal } from "../../../components/composed/warningModal/WarningModal";
import { Box } from "../../../components/elements/box/Box";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import {
  getDate,
  getIsoString,
  stringsListToSelectOptions,
} from "../../../shared/helper";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import {
  ProjectParams,
  useApiCreateProject,
  useApiDeleteProject,
  useApiUpdateProject,
} from "../../../hooks/queries/projectsContext";
import useToastContext from "../../../hooks/toastHook";
import { useApiAssetsPaging } from "../../../hooks/queries/assetsContext";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { AssetsInScope } from "./AssetsInScope";
import { SuperUsersDropdown } from "../../../components/composed/dropdowns/SuperUsersDropdown";
import { useApiProjectRequests } from "../../../hooks/queries/projectRequestsContext";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { useApiMe } from "../../../hooks/queries/meContext";
import { AccountsDropdown } from "../../../components/composed/dropdowns/AccountsDropdown";
import { RequiredField } from "../../../components/elements/requiredField/RequiredField";
import { Asset } from "../../../types/Asset";
import { RichTextEditorWASP } from "../../../components/elements/richTextEditor/RichTextEditor";

const reportTypeOptions = stringsListToSelectOptions([
  "Full Report",
  "Attestation Letter",
  "Executive Report",
  "Retest Report",
]);

const now = new Date();
const createdAt = now.toISOString();

const initReport: Report = {
  title: "",
  filename: "",
  data: "",
  type: "Full Report",
  status: "done",
  created_at: createdAt,
  requested_at: createdAt,
};

const projectStatusOptions = stringsListToSelectOptions([
  "created",
  "in_progress",
  "done",
]);

const defaultInitialStatusOption = { value: "created", label: "Created" };

type FormErrors = { [key: string]: string };

type FormValues = {
  name: string;
  status: string;
  start: string;
  end: string;
  jira_project?: string;
  reports: Report[];
  assets: number[];
  products: number[];
  opinnovate_poc: OpPoc;
  opinnovate_poc_avatar_url?: string;
  project_request: number;
  project_scope?: string;
};

const emptyFormValues = {
  name: "",
  status: "",
  start: "",
  end: "",
  jira_project: "",
  reports: [],
  assets: [],
  products: [],
  opinnovate_poc: { name: "", email: "", role: "" },
  opinnovate_poc_avatar_url: "",
  project_request: 0,
};

type Props = {
  project?: Project | null;
  onClose: () => void;
  closeEditMode: () => void;
  isCreateMode?: boolean;
};

export const AddEditProject = (props: Props) => {
  const { project, onClose, closeEditMode, isCreateMode = false } = props;
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const observerElemForFetchPage = useRef(null);
  const isSuperUser = useIsSuperuser();
  const rteTrigger = useRef(true);

  const { data: me } = useApiMe();
  const { mutate: createProject } = useApiCreateProject();
  const { mutate: updateProject } = useApiUpdateProject();
  const { mutate: deleteProject } = useApiDeleteProject();

  const initFormValues: FormValues = project
    ? {
        name: project.name || "",
        status: project.status || "",
        start: project.start,
        end: project.end,
        jira_project: project.jira_project,
        reports: project.reports,
        assets: [],
        products: project.products,
        opinnovate_poc: project.opinnovate_poc,
        opinnovate_poc_avatar_url: project.opinnovate_poc_avatar_url,
        project_request: project.project_request || 0,
        project_scope: project.project_scope,
      }
    : emptyFormValues;

  const [deletedReport, setDeletedReport] = useState<Report | undefined>(
    undefined
  );
  const [deletedProject, setDeletedProject] = useState<Project | undefined>(
    undefined
  );
  const [newReport, setNewReport] = useState<Report>(initReport);
  const [showAddReport, setShowAddReport] = useState(false);
  const [initialDataProjectScope, setInitialDataProjectScope] =
    useState<string>(project?.project_scope || " ");

  const [includedAssetsObj, setIncludedAssetsObj] = useState<Asset[]>([]);

  const [formValues, setFormValues] = useState<FormValues>(initFormValues);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  // on open editing we get assets with project id in projects
  // after editing we get all assets in ids list if not all assets included
  const getQueryParams = () => {
    if (!formValues.assets?.length && project?.id)
      return { projects: project?.id };
    if (!!formValues.assets.length) return { id: formValues.assets };
    return {};
  };

  const {
    data: assets,
    hasNextPage: hasAssetsNextPage,
    fetchNextPage: fetchAssetsNextPage,
  } = useApiAssetsPaging(
    { ...getQueryParams(), page_size: 25 },
    !!project?.id || !!formValues.assets.length
  );

  useInfiniteScroll(
    observerElemForFetchPage,
    !!hasAssetsNextPage,
    fetchAssetsNextPage
  );

  const pagedAssets = useMemo(
    () => assets?.pages?.map((page) => page?.results || []).flat() || [],
    [assets]
  );

  useEffect(() => {
    if (project?.project_scope) {
      setInitialDataProjectScope(project.project_scope);
    }
  }, [project?.project_scope]);

  useEffect(() => {
    // update included assets list when assets are loaded
    if (!!pagedAssets?.length) setIncludedAssetsObj(pagedAssets);
  }, [pagedAssets]);

  const { data: projectRequests } = useApiProjectRequests();

  let projectRequestsOptions =
    projectRequests?.map((pr) => ({
      value: pr.id,
      label: `${pr.user_email}| at: ${getDate(pr.created_at)}| start: ${getDate(
        pr.start_date
      )}`,
    })) || [];

  projectRequestsOptions = [
    { label: "None", value: 0 },
    ...projectRequestsOptions,
  ];

  const handleReportChange = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = () => {
      var block = reader?.result?.toString().split(";");
      var realData = !!block?.length && block[1].split(",")[1];
      var tempReport = { ...newReport };
      if (typeof realData === "string") tempReport.data = realData;
      setNewReport(tempReport);
    };
    reader.onerror = (error) => console.error(error);
    reader.readAsDataURL(e.target.files[0]);
  };

  const addReport = () => {
    setFormValues((prev) => ({
      ...prev,
      reports: [...prev.reports, newReport],
    }));
    setNewReport(initReport);
    setShowAddReport(false);
  };

  const isFormValid = (): boolean => {
    let errors: FormErrors = {};
    setFormErrors(errors);
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // checks yyyy-mm-dd
    if (!formValues.name) errors.name = "Project must have a name";

    if (!formValues.start) errors.start = "Please select a start date";
    if (!dateRegex.test(formValues.start))
      errors.start = "Please enter a valid start date";

    if (!formValues.end) errors.end = "Please select a end date";
    if (!dateRegex.test(formValues.end))
      errors.end = "Please enter a valid end date";

    console.log({ errors, formValues });
    setFormErrors(errors);

    return !Object.keys(errors).length;
  };

  const handleCreateProject = () => {
    if (!isFormValid()) return;
    const projectData: ProjectParams = {
      customer: me?.customer.id || 0,
      ...formValues,
      status: "created",
      start: getIsoString(formValues.start),
      end: getIsoString(formValues.end),
      assets: includedAssetsObj.map((a) => a.id) || [],
      onSuccessCallback: () => {
        addToast({ message: "Project created successfully", type: "success" });
        closeEditMode();
        onClose();
      },
      onErrorCallback: () =>
        addToast({ message: "Failed to create project", type: "error" }),
    };
    delete projectData.project_request;
    if (projectData.jira_project === "") delete projectData.jira_project;
    createProject(projectData);
  };

  const handleUpdateProject = () => {
    if (!project || !isFormValid()) return;
    const projectData: ProjectParams = {
      ...project,
      ...formValues,
      start: getIsoString(formValues.start),
      end: getIsoString(formValues.end),
      assets: includedAssetsObj.map((a) => a.id) || [] || [],
      onSuccessCallback: () => {
        addToast({ message: "Project updated successfully", type: "success" });
        closeEditMode();
        onClose();
      },
      onErrorCallback: () =>
        addToast({ message: "Failed to update project", type: "error" }),
    };
    if (projectData.project_request === 0) {
      delete projectData.project_request;
    }
    project && updateProject({ projectData, projectId: project.id });
  };

  const handleDeleteProject = () => {
    deletedProject && deleteProject(deletedProject);
    setDeletedProject(undefined);
    onClose();
  };

  const FormHeader = () => (
    <Flex gap="8px" w100 align="center" justify="between">
      <HeaderSecondary>
        {isCreateMode ? "Create" : "Edit"} Project
      </HeaderSecondary>
      <Flex gap="8px">
        <SecondaryButton
          label="Cancel"
          onClick={isCreateMode ? onClose : closeEditMode}
          variant="danger"
          size="medium"
        />
        <MainButton
          label={isCreateMode ? "Create" : "Apply"}
          onClick={isCreateMode ? handleCreateProject : handleUpdateProject}
          size="medium"
        />
      </Flex>
    </Flex>
  );

  const FormFooter = () => (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        right: "0",
        width: "100%",
        padding: "24px",
        paddingTop: "0",
        zIndex: "1600",
        background: "inherit",
      }}
    >
      <SeparatorHorizontal />

      <Flex w100 justify="center" style={{ marginTop: "24px" }}>
        <TextButton
          label="Delete Project"
          color={theme.redPrimary}
          onClick={() => project && setDeletedProject(project)}
        />
      </Flex>
    </div>
  );

  return (
    <>
      <FormHeader />
      <SeparatorHorizontal />
      <div style={{ overflowY: "hidden", height: "calc(100vh - 220px)" }}>
        <Flex column h100>
          <Flex column align="center">
            {!!Object.keys(formErrors).length &&
              Object.keys(formErrors).map((field) => (
                <BodyBold color={theme.redPrimary}>
                  {formErrors[field]}
                </BodyBold>
              ))}
          </Flex>
          <Flex
            column
            gap="24px"
            w100
            style={{ overflowY: "auto", height: "100%" }}
          >
            <Flex column gap="8px" w100>
              <Flex>
                <HeaderSubBold>Name</HeaderSubBold> <RequiredField />
              </Flex>
              <InputText
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, name: e.target.value }))
                }
                placeholder="Please enter a project name"
                value={formValues.name || ""}
                width="100%"
                dataTestId="project-name"
              />
            </Flex>

            <Flex gap="24px">
              <Flex column gap="8px" w100>
                <HeaderSubBold>Project Leader</HeaderSubBold>
                {isSuperUser && (
                  <SuperUsersDropdown
                    placeholder={"Select OP User"}
                    valueByEmail={formValues.opinnovate_poc?.email}
                    isClearable
                    onSelect={(opUser) =>
                      setFormValues((prev) => ({
                        ...prev,
                        opinnovate_poc_avatar_url: opUser?.avatar_url,
                        opinnovate_poc: {
                          email: opUser?.email || "",
                          role: "",
                          name: opUser
                            ? `${opUser.first_name} ${opUser.last_name}`
                            : "",
                        },
                      }))
                    }
                  />
                )}
                {!isSuperUser && me?.customer.is_multi_tenant && (
                  <AccountsDropdown
                    placeholder="Select Project Leader"
                    valueByEmail={formValues.opinnovate_poc?.email}
                    isClearable
                    onSelect={(account) =>
                      setFormValues((prev) => ({
                        ...prev,
                        opinnovate_poc_avatar_url: account?.user.avatar_url,
                        opinnovate_poc: {
                          email: account?.email || "",
                          role: "",
                          name: account
                            ? `${account.user.first_name} ${account.user.last_name}`
                            : "",
                        },
                      }))
                    }
                  />
                )}
              </Flex>

              <Flex column gap="8px" w100>
                <HeaderSubBold>Status</HeaderSubBold>
                <Dropdown
                  options={projectStatusOptions}
                  value={
                    formValues.status
                      ? { value: formValues.status, label: formValues.status }
                      : defaultInitialStatusOption
                  }
                  onChange={(opt) =>
                    setFormValues((prev) => ({
                      ...prev,
                      status: `${opt?.value}`,
                    }))
                  }
                  variant="border"
                  disabled={isCreateMode}
                />
                {formValues.status === "done" && project?.status !== "done" ? (
                  <LabelRegular
                    color={theme.redPrimary}
                    style={{ textTransform: "none" }}
                  >
                    On applying this change emails will be sent to all
                    customer's users
                  </LabelRegular>
                ) : null}
              </Flex>
            </Flex>

            <Flex gap="24px">
              <Flex column gap="8px" w100>
                <Flex>
                  <HeaderSubBold>Project dates</HeaderSubBold>
                  <RequiredField />
                </Flex>

                <Flex gap="16px">
                  <Flex column>
                    <LabelRegular>Start Date</LabelRegular>
                    <DatePicker
                      value={formValues.start || ""}
                      onChange={(e) =>
                        setFormValues((prev) => ({
                          ...prev,
                          start: e.target.value,
                        }))
                      }
                    />
                  </Flex>
                  <Flex column>
                    <LabelRegular>End Date</LabelRegular>
                    <DatePicker
                      value={formValues.end || ""}
                      onChange={(e) =>
                        setFormValues((prev) => ({
                          ...prev,
                          end: e.target.value,
                        }))
                      }
                    />
                  </Flex>
                </Flex>
              </Flex>
              {(!isCreateMode || isSuperUser) && (
                <Flex column gap="8px" w100>
                  <HeaderSubBold>Project Request</HeaderSubBold>
                  <Dropdown
                    placeholder="Select Project Request"
                    value={projectRequestsOptions?.find(
                      (pr) => pr.value === formValues.project_request
                    )}
                    onChange={(opt) =>
                      setFormValues((prev) => ({
                        ...prev,
                        project_request: parseInt(`${opt?.value}`),
                      }))
                    }
                    options={projectRequestsOptions}
                    variant="border"
                  />
                </Flex>
              )}
            </Flex>
            {isSuperUser && (
              <Flex column gap="8px" w100>
                <HeaderSubBold>
                  Internal JIRA Project (OPInnovate's side):
                </HeaderSubBold>
                <InputText
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      jira_project: e.target.value,
                    }))
                  }
                  value={formValues.jira_project}
                  width="100%"
                  dataTestId="op_jira_field"
                />
              </Flex>
            )}
            {!isCreateMode && (
              <>
                <Box>
                  <Flex column gap="16px" w100>
                    <Flex align="center" justify="between">
                      <HeaderSubBold>Reports</HeaderSubBold>
                      <IconButton
                        iconName={showAddReport ? "cancel" : "plus"}
                        onClick={() => setShowAddReport((prev) => !prev)}
                      />
                    </Flex>
                    {showAddReport && (
                      <Box>
                        <Flex column gap="16px" w100>
                          <HeaderSubBold>Add New Report</HeaderSubBold>
                          <Flex column gap="8px" w100>
                            <LabelRegular>Report Title</LabelRegular>
                            <InputText
                              width="100%"
                              onChange={(e) =>
                                setNewReport((prev) => ({
                                  ...prev,
                                  title: `${e.target.value}`,
                                }))
                              }
                              value={newReport.title}
                            />
                          </Flex>

                          <Flex column gap="8px" w100>
                            <LabelRegular>Report Type</LabelRegular>
                            <Dropdown
                              options={reportTypeOptions}
                              onChange={(opt) => {
                                //@ts-ignore
                                setNewReport((prev) => ({
                                  ...prev,
                                  type: `${opt?.value}`,
                                }));
                              }}
                              variant="border"
                              value={{
                                value: newReport.type,
                                label: newReport.type,
                              }}
                            />
                          </Flex>
                          <input
                            className=""
                            onChange={handleReportChange}
                            style={{
                              borderWidth: "0px 0px 1px 0px",
                              borderRadius: "4px",
                            }}
                            id="report"
                            type="file"
                            multiple={false}
                            accept="application/pdf"
                          />
                          <MainButton
                            label="Upload Report"
                            onClick={addReport}
                            disabled={
                              !newReport.type ||
                              !newReport.title ||
                              !newReport.data
                            }
                            size="medium"
                          />
                        </Flex>
                      </Box>
                    )}
                    <SeparatorHorizontal />
                    {formValues.reports?.map((report, idx) => (
                      <>
                        <Flex
                          gap="16px"
                          align="center"
                          justify="between"
                          key={idx}
                        >
                          <BodyRegular style={{ width: "200px" }}>
                            {report.title}
                          </BodyRegular>
                          <BodyRegular>{report.type || "Other"}</BodyRegular>
                          <IconButton
                            iconName="remove"
                            color={theme.redPrimary}
                            onClick={() => setDeletedReport(report)}
                          />
                        </Flex>
                        <SeparatorHorizontal />
                      </>
                    ))}
                  </Flex>
                </Box>
              </>
            )}

            <Box>
              <AssetsInScope
                includedAssetsObj={includedAssetsObj}
                includedProductsId={formValues.products}
                onAddAssetTag={(opt, asset) => {
                  if (!asset) return;
                  setIncludedAssetsObj((prev) => [...prev, asset]);
                  setFormValues((prev) => ({
                    ...prev,
                    products: [...prev.products, asset.product],
                  }));
                }}
                onDeleteAssetOption={(opt, asset) => {
                  if (!asset) return;
                  setIncludedAssetsObj((prev) =>
                    prev.filter((a) => a.id !== opt?.value)
                  );

                  // Check product is not related to any other asset on scope
                  const isProductInScope = includedAssetsObj
                    .filter((a) => a.id !== opt?.value)
                    .some((a) => a.product === asset.product);

                  if (!isProductInScope)
                    setFormValues((prev) => ({
                      ...prev,
                      products: prev.products.filter(
                        (id) => id !== asset.product
                      ),
                    }));
                }}
              />
            </Box>

            <Box>
              <HeaderSubBold>Project Scope</HeaderSubBold>
              <RichTextEditorWASP
                isHidden
                placeholderText="Provide a detailed description of the project scope..."
                value={initialDataProjectScope}
                valueTrigger={rteTrigger}
                onChange={(html: string, markdown: string) => {
                  setFormValues((prev) => ({
                    ...prev,
                    project_scope: html,
                  }));
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </div>
      {!isCreateMode && <FormFooter />}
      {deletedReport && (
        <WarningModal
          header={`Are you sure you want delete`}
          body={deletedReport.title}
          approveButtonLabel="Delete"
          onApprove={() => {
            setFormValues((prev) => ({
              ...prev,
              reports: prev.reports.filter(
                (report) => report.title !== deletedReport.title
              ),
            }));
            setDeletedReport(undefined);
          }}
          onClose={() => setDeletedReport(undefined)}
        />
      )}
      {deletedProject && (
        <WarningModal
          header={`Are you sure you want delete this project`}
          body={deletedProject.name}
          approveButtonLabel="Delete"
          onApprove={handleDeleteProject}
          onClose={() => setDeletedProject(undefined)}
        />
      )}
    </>
  );
};
