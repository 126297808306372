import { useContext, useEffect, useState } from "react";
import { AssetGraphEngine, nodeType } from "./AssetGraphEngine";
import { ThemeContext } from "styled-components";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import { Switch } from "../../../../components/elements/switch/Switch";
import { Loading } from "../../../../components/elements/loading/Loading";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Box } from "../../../../components/elements/box/Box";
import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";
import { GroupButton } from "../../../../components/elements/button/group/GroupButton";
import { useScreenWidth } from "../../../../hooks/utilsHooks";
import { Menu } from "../../../../components/elements/menu/Menu";
import { MenuItem } from "../../../../components/elements/menu/MenuItem";
import useToastContext from "../../../../hooks/toastHook";
import { AssetPane } from "../../assetsPane/AssetPane";
import { FindingPane } from "../../../findings/findingPane/FindingPane";
import { Finding } from "../../../../types/Finding";
import { useApiSingleFinding } from "../../../../hooks/queries/findingContext";
import { Filter } from "../../../../types/AssetsView";
import { Mixpanel } from "../../../../shared/mixpanel";
import {
  Dropdown,
  Option,
} from "../../../../components/elements/dropdowns/Dropdown";
import { AssetConnectionsGraphEngine } from "./AssetConnectionsGraphEngine";
import { useIsSuperuser } from "../../../../hooks/useIsSuperuser";
import { AssetOwnerPane } from "../../assetsPane/AssetOwnerPane";

export const SIDE_DRAWER_WIDTH_OPEN = "330px";
export const SIDE_DRAWER_WIDTH_CLOSE = "40px";

type Props = {
  setAssetsCount?: (count: number) => void;
  filters: Filter[];
};

const GraphViewOptions: Option[] = [
  { label: "Assets Risk", value: "risk" },
  {
    label: "Connections",
    value: "connections",
  },
];

function AssetsGraph(props: Props) {
  const { setAssetsCount, filters } = props;
  const isSuperuser = useIsSuperuser();
  const { data: products } = useApiProducts();

  const [graphEnginInstance, setGraphEnginInstance] = useState<
    AssetGraphEngine | AssetConnectionsGraphEngine | null
  >(null);
  // const [showLabels, setShowLabels] = useState(true);
  const [showSubdomains, setShowSubdomains] = useState(false);
  const [showAssetPane, setShowAssetPane] = useState(false);
  const [showAssetOwnerPane, setShowCorpPane] = useState(false);
  const [graphViewType, setGraphViewType] = useState<"risk" | "connections">(
    "risk"
  );
  const [selectedAssetId, setSelectedAssetId] = useState<number | null>(null);
  const [selectedCorp, setSelectedCorp] = useState<string | null>(null);
  const [selectedFindingId, setSelectedFindingId] = useState<
    number | undefined
  >(undefined);
  const { data: selectedFinding, isLoading: isLoadingSelectedFinding } =
    useApiSingleFinding(selectedFindingId);
  const [showVulnerabilityPane, setShowVulnerabilityPane] = useState(false);
  useState<Finding | null>(null);

  const screenWidth = useScreenWidth();
  const addToast = useToastContext();

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (!!graphEnginInstance && !!filters) {
      graphEnginInstance.onFilterChange(filters);
    }
  }, [filters, graphEnginInstance]);

  useEffect(() => {
    if (!!graphEnginInstance && !!theme) {
      graphEnginInstance.onThemeChange(theme);
    }
  }, [theme, graphEnginInstance]);

  useEffect(() => {
    if (!!graphEnginInstance) {
      Mixpanel.track("Assets graph");
      graphEnginInstance.init();
    }
  }, [graphEnginInstance]);

  useEffect(() => {
    if (!products || products.length === 0 || !theme) return;
    const sigmaContainer = document.getElementById("sigma-container");
    const isTypeChanged =
      !!graphEnginInstance && graphEnginInstance.type !== graphViewType;
    if (!sigmaContainer || (sigmaContainer.hasChildNodes() && !isTypeChanged)) {
      // Sigma container not rendered yet or it's already running
      return;
    }

    const handleNodeSingleClick = (
      nodeId: number | string,
      nodeType: nodeType
    ) => {
      if (nodeType === "product") {
        addToast({
          type: "info",
          message: `Details of product is still under development`,
        });
      } else if (nodeType === "asset") {
        setShowAssetPane(true);
        setSelectedAssetId(Number(nodeId));
      } else if (nodeType === "vulnerability") {
        setShowVulnerabilityPane(true);
        setSelectedFindingId(Number(nodeId));
      } else if (nodeType === "owner") {
        setShowCorpPane(true);
        setSelectedCorp(nodeId.toString());
      }
    };

    const graphEngine =
      graphViewType === "risk"
        ? new AssetGraphEngine(products, handleNodeSingleClick, filters)
        : new AssetConnectionsGraphEngine(handleNodeSingleClick, filters);
    graphEngine.startGraph("sigma-container", theme);

    // disable right click inside the graph
    sigmaContainer.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    setGraphEnginInstance(graphEngine);
    return () => {
      // graphEngine.stop();
    };
  }, [
    products,
    setAssetsCount,
    filters,
    theme,
    addToast,
    graphViewType,
    graphEnginInstance,
  ]);

  // const showLabelsCallback = () => {
  //   if (!!graphEnginInstance) {
  //     graphEnginInstance.showLabels(!showLabels);
  //   }
  //   setShowLabels(!showLabels);
  // };

  const showSubdomainsCallback = () => {
    if (!!graphEnginInstance) {
      graphEnginInstance.showSubdomains(!showSubdomains);
    }
    setShowSubdomains(!showSubdomains);
  };

  setTimeout(() => {
    if (graphEnginInstance) {
      graphEnginInstance.initToolsButtons();
    }
  }, 1000);

  return (
    <Box
      id="graph-component-container"
      className="d-flex flex-column w-100"
      style={{
        position: "relative",
        height: `calc(100vh - 260px)`,
        cursor: "grab",
        backgroundColor: theme.bg2,
      }}
    >
      {showAssetPane && (
        <AssetPane
          onClose={() => setShowAssetPane(false)}
          assetId={selectedAssetId || 0}
        />
      )}
      {showVulnerabilityPane && (
        <FindingPane
          onClose={() => setShowVulnerabilityPane(false)}
          findingId={selectedFinding?.id}
          isLoading={isLoadingSelectedFinding}
        />
      )}
      {showAssetOwnerPane && (
        <AssetOwnerPane
          onClose={() => setShowCorpPane(false)}
          assets={graphEnginInstance?.getAssetsByCorp(selectedCorp || "") || []}
          corporateName={selectedCorp || ""}
        />
      )}

      <Flex justify="between" gap="8px" align="center">
        <Flex align="center" gap="24px">
          <InputText
            placeholder="Search..."
            iconName="search"
            onChange={(e) =>
              !!graphEnginInstance
                ? graphEnginInstance.searchQuery(e.target.value)
                : {}
            }
          />
          {isSuperuser && (
            <Flex align="center" gap="8px">
              <BodyRegular>View</BodyRegular>
              <Dropdown
                options={GraphViewOptions}
                onChange={(option) => {
                  setGraphViewType(
                    option?.value.toString() === "connections"
                      ? "connections"
                      : "risk"
                  );
                }}
                variant="border"
                value={GraphViewOptions.find((v) => v.value === graphViewType)}
                width="150px"
                size="small"
              />
            </Flex>
          )}
          {/* <Flex align="center" gap="8px">
            <BodyRegular>Show labels</BodyRegular>
            <Switch checked={showLabels} onChange={showLabelsCallback} />
          </Flex> */}
          {graphViewType === "connections" && (
            <Flex align="center" gap="8px">
              <BodyRegular>Show Subdomains</BodyRegular>
              <Switch
                checked={showSubdomains}
                onChange={showSubdomainsCallback}
              />
            </Flex>
          )}
        </Flex>
        <Flex align="center" gap="24px" key={screenWidth}>
          {/* // If full-screen is active */}
          {document.fullscreenElement !== null ? (
            <Tooltip placement="bottom" content="Exit full screen">
              <IconButton
                size="small"
                iconName="exitFullScreen"
                color={theme.primary}
                id="exit-full-screen-btn"
              />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" content="Full screen">
              <IconButton
                size="small"
                iconName="fullScreen"
                color={theme.primary}
                id="full-screen-btn"
              />
            </Tooltip>
          )}

          <SeparatorVertical height="24px" />
          <Tooltip placement="bottom" content="Zoom to fit">
            <IconButton
              size="small"
              iconName="reverse"
              color={theme.primary}
              id="zoom-reset-btn"
            />
          </Tooltip>

          <GroupButton
            buttonsProps={[
              {
                iconName: "zoomIn",
                onClick: () => console.log("zoomIn"),
                id: "zoom-in-btn",
              },
              {
                iconName: "zoomOut",
                onClick: () => console.log("zoomOut"),
                id: "zoom-out-btn",
              },
            ]}
          />
        </Flex>
      </Flex>

      <div id="loading-assets-icon" className=" text-center py-5">
        <Loading />
      </div>

      <div
        className="h-100"
        style={{
          width: `calc(100% - 40px)`,
        }}
        // style={{
        //   width: `calc(100% - ${
        //     collapseSideDrawer
        //       ? SIDE_DRAWER_WIDTH_CLOSE
        //       : SIDE_DRAWER_WIDTH_OPEN
        //   })`,
        // }}
        id="sigma-container"
      ></div>
      <Menu
        style={{
          display: "none",
          position: "fixed",
          zIndex: "5",
        }}
      >
        <MenuItem
          label="Hide from graph"
          iconName="wasp"
          id="node-hide-from-graph-btn"
        />
        <MenuItem
          label="Expand children"
          iconName="friends"
          id="node-expand-children-btn"
        />
        <MenuItem
          label="View details"
          iconName="info"
          id="node-view-details-btn"
        />
      </Menu>
    </Box>
  );
}

export default AssetsGraph;
