import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import Tour, { ReactourStep } from "reactour";
import { Flex } from "../components/layouts/flex/Flex";
import { useSearchParams } from "react-router-dom";
import "./tours.css";
import { IconButton } from "../components/elements/button/icon/IconButton";
import { Mixpanel } from "../shared/mixpanel";

type Props = {
  steps: ReactourStep[];
};

export const Tours = (props: Props) => {
  const { steps } = props;
  const theme = useContext(ThemeContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const showTourOnFirstLogin = localStorage.getItem("show_tour");
  const tourState = searchParams.get("tour") || showTourOnFirstLogin;
  const [showTour, setShowTour] = useState(!!tourState);

  const tourStepStyle = { background: theme.bg1 };

  return (
    <>
      {!showTour && (
        <Flex
          style={{
            position: "fixed",
            bottom: "50px",
            left: "30px",
            zIndex: 300,
          }}
        >
          <IconButton
            label="Take a Tour"
            iconName="wasp"
            onClick={() => {
              Mixpanel.track("Take a Tour - Button pressed");
              setShowTour(true);
            }}
            color={theme.white80}
            style={{
              backgroundColor: theme.primary,
              border: `3px solid ${theme.primary}`,
            }}
          />
        </Flex>
      )}
      <Tour
        isOpen={showTour}
        onRequestClose={() => {
          setShowTour(false);
          searchParams.delete("tour");
          setSearchParams(searchParams);
        }}
        accentColor={theme.primary}
        rounded={24}
        startAt={0}
        steps={steps.map((s) => ({ ...s, style: tourStepStyle }))}
        scrollOffset={-200}
        disableInteraction
        maskClassName="tour-mask"
        onAfterOpen={() => localStorage.removeItem("show_tour")}
        closeWithMask={false}
      />
    </>
  );
};
