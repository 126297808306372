import { Flex } from "../../../components/layouts/flex/Flex";

import waspLogo from "../../../assets/images/Brand-logo.png";
import waspTitle from "../../../assets/images/Brand-title-black.png";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

export const WaspLogo = () => {
  const theme = useContext(ThemeContext);
  return (
    <Flex align="center" style={{ width: "156px" }}>
      <img src={waspLogo} alt="" width="80px" />
      <img
        src={waspTitle}
        alt=""
        width="90px"
        style={{
          filter:
            theme.name === "dark" ? "invert(1) hue-rotate(180deg)" : "none",
        }}
      />
    </Flex>
  );
};
