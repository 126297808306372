import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import {
  Checkbox,
  CheckboxState,
} from "../../../components/elements/checkbox/Checkbox";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { Loading } from "../../../components/elements/loading/Loading";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import {
  useApiAssetsViewsPaging,
  useApiDeleteAssetsView,
} from "../../../hooks/queries/assetsViewsContext";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { AssetsView, AssetsViewProps } from "../../../types/AssetsView";
import { emptyAssetsViewProps } from "./FiltersUtils";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  defaultAssetsView: AssetsViewProps;
  setAssetsView: (assetsView: AssetsViewProps) => void;
  onEditAssetsView: (assetsView: AssetsView) => void;
};

export const SavedFiltersPane = (props: Props) => {
  const { defaultAssetsView, setAssetsView, onEditAssetsView } = props;
  const [search, setSearch] = useState<string>("");
  const [selectedView, setSelectedView] = useState<AssetsView | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const {
    mutate: deleteView,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    reset: resetDelete,
  } = useApiDeleteAssetsView();
  const theme = useContext(ThemeContext);
  const {
    data: views,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useApiAssetsViewsPaging(search ? { name: search } : {});

  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(
    observerElemForFetchPage,
    hasNextPage || false,
    fetchNextPage
  );

  useEffect(() => {
    if (
      !!defaultAssetsView.name &&
      !!defaultAssetsView.filters?.length &&
      !!views
    ) {
      const defaultView = views.pages
        .map((page) => page.results || [])
        .flat()
        .find((view) => view.name === defaultAssetsView.name);
      if (!!defaultView) {
        setSelectedView(defaultView);
      }
    }
  }, [views, defaultAssetsView]);

  const getAssetsViews = (): AssetsView[] => {
    return views?.pages.map((page) => page.results || []).flat() || [];
  };

  const handleSelectView = (view: AssetsView, state: CheckboxState) => {
    if (state === "unchecked") {
      setSelectedView(null);
      setAssetsView(emptyAssetsViewProps);
    } else {
      setSelectedView(view);
      setAssetsView({ name: view.name, filters: view.filters });
    }
  };

  const handleRemoveView = (view: AssetsView) => {
    setSelectedView(view);
    setShowDeleteModal(true);
  };

  const removeView = () => {
    deleteView(parseInt(selectedView?.id.toString() || "-1"));
  };

  return (
    <Flex column>
      {showDeleteModal && (
        <DeleteModal
          itemType="Filter"
          itemName={selectedView?.name || ""}
          onConfirm={() => removeView()}
          onClose={() => {
            setShowDeleteModal(false);
            resetDelete();
          }}
          isLoading={isDeleting}
          isSuccess={isDeleted}
          body={
            <>
              <BodyRegular>The filter has </BodyRegular>
              <BodyBold>{selectedView?.filters.length || 0}</BodyBold>
              <BodyRegular>attributes</BodyRegular>
            </>
          }
        />
      )}
      <SeparatorHorizontal />
      <Flex column style={{ padding: "24px" }}>
        <InputText
          iconName="search"
          placeholder="Search Filters"
          onChange={(e) => setSearch(e.target.value)}
          width="100%"
        />
        {isFetching && <Loading />}
        <Flex
          column
          className="mt-2"
          style={{
            height: "calc(100vh - 264px)",
            overflowY: "auto",
          }}
        >
          {getAssetsViews().map((view, i) => (
            <div
              key={`view-${view.id}-${i}-${selectedView?.id}`}
              className="d-flex align-items-center justify-content-between gap-8 me-1"
              style={{ padding: "24px 0" }}
            >
              <Checkbox
                hasMidCheck={false}
                onChange={(state: CheckboxState) =>
                  handleSelectView(view, state)
                }
                state={selectedView?.id === view.id ? "checked" : "unchecked"}
                label={view.name}
                isLabelBefore={false}
                dataTestId={`select-view-${view.id}`}
              />
              <div className="d-flex align-items-center gap-8">
                <IconButton
                  iconName="edit"
                  size="small"
                  color={theme.primary}
                  onClick={() => onEditAssetsView(view)}
                  dataTestId={`edit-view-${view.id}`}
                  // disabled={selectedView?.id !== view.id}
                />
                <SeparatorVertical style={{ height: "24px" }} />
                <IconButton
                  iconName="remove"
                  size="small"
                  color={theme.redPrimary}
                  onClick={() => handleRemoveView(view)}
                  // disabled={selectedView?.id !== view.id}
                />
              </div>
            </div>
          ))}
          <div ref={observerElemForFetchPage}>
            {isFetchingNextPage && (
              <div className="d-flex flex-column justify-content-center">
                <Loading />
              </div>
            )}
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};
