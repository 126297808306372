import React from "react";
import { SecondaryButton } from "../../../../components/elements/button/secondary/SecondaryButton";
import { useApiPushFindingToCustomerWorkato } from "../../../../hooks/queries/findingContext";
import useToastContext from "../../../../hooks/toastHook";

type Props = {
  findingId: number;
};

export const WorkatoSyncButton = ({ findingId }: Props) => {
  const { mutate: sendTicket, isLoading } =
    useApiPushFindingToCustomerWorkato();
  const addToast = useToastContext();

  const handleClickSendToWorkato = () => {
    sendTicket({
      findingsId: [findingId],
      onSuccessCallback: () => {
        addToast({
          message: `Ticket were synced successfully`,
          type: "success",
        });
      },
      onErrorCallback: () => {
        addToast({
          message: `Failed to sync ticket`,
          type: "error",
        });
      },
    });
  };

  return (
    <SecondaryButton
      label="Trigger sync"
      onClick={handleClickSendToWorkato}
      size="small"
      inProgress={isLoading}
    />
  );
};
