import { useSearchParams } from "react-router-dom";
import AlertBanner from "../../components/elements/toastTypes/AlertBanner";
import {
  BodyMedium,
  BodyRegular,
} from "../../components/elements/typography/Typography";
import { useApiMe } from "../../hooks/queries/meContext";
import { useApiScanEventsPaging } from "../../hooks/queries/scansContext";
import { Flex } from "../../components/layouts/flex/Flex";

type OnboardingAlertProps = {
  onRender?: () => void;
};

export const OnboardingAlert = ({ onRender }: OnboardingAlertProps) => {
  const { data: me } = useApiMe();
  const [, setSearchParams] = useSearchParams();

  const { data: activeScanEventsPages } = useApiScanEventsPaging(
    {
      customer_id: me?.customer?.id,
      scanner: "sublister",
      status: "running",
    },
    me?.customer.onboarding_stage === 5
  );

  const { data: doneScanEventsPages } = useApiScanEventsPaging(
    {
      customer_id: me?.customer?.id,
      scanner: "sublister",
      status: "done",
    },
    me?.customer.onboarding_stage === 5
  );

  const isRunningSublister =
    me?.customer.onboarding_stage === 5 &&
    !!activeScanEventsPages?.pages[0].count;

  const isDoneSublister =
    me?.customer.onboarding_stage === 5 &&
    !!doneScanEventsPages?.pages[0].count;

  const onboardingMsg = () => (
    <BodyRegular>
      Your onboarding is{" "}
      {!!me?.customer.onboarding_stage
        ? (me.customer.onboarding_stage - 1) * 20
        : 0}
      % done
    </BodyRegular>
  );

  const sublisterMsg = () => (
    <Flex column>
      <BodyMedium>Subdomain enumeration in process</BodyMedium>
      <BodyRegular>This process may take several minutes...</BodyRegular>
    </Flex>
  );

  const getMessage = isRunningSublister ? sublisterMsg : onboardingMsg;

  if (me?.customer.onboarding_stage === -1) return null;
  return me?.customer.onboarding_stage === 5 && isDoneSublister ? (
    <AlertBanner
      customBackground={"success"}
      customIcon={"success"}
      buttonLabel="Select Assets"
      onButtonClick={() =>
        setSearchParams((prev) => ({
          ...prev,
          onboarding: me?.customer.onboarding_stage,
        }))
      }
      message={
        <Flex column>
          <BodyMedium>Subdomain enumeration is done</BodyMedium>
          <BodyRegular>Let's Select assets to scan </BodyRegular>
        </Flex>
      }
      showInPages={["/", "/dashboard"]}
      onRender={onRender}
    />
  ) : (
    <AlertBanner
      message={getMessage()}
      customBackground={"info"}
      customIcon={"info"}
      buttonLabel={
        me?.customer.onboarding_stage === 0
          ? "Start Onboarding"
          : "Finish Onboarding"
      }
      showInPages={["/", "/dashboard"]}
      onButtonClick={
        !isRunningSublister
          ? () =>
              setSearchParams((prev) => ({
                ...prev,
                onboarding: me?.customer.onboarding_stage,
              }))
          : undefined
      }
      onRender={onRender}
    />
  );
};
