import React, { useMemo, useRef } from "react";
import { useParams } from "react-router";
import {
  ConversationInfo,
  ConversationMessage,
  useApiWaspAIConversations,
} from "./context/AIContext";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  HeaderMain,
  HeaderSecondary,
} from "../../components/elements/typography/Typography";
import { Box } from "../../components/elements/box/Box";
import { ChatMessage } from "./chat/ChatMessage";
import { BackButton } from "../../components/composed/backButton/BackButton";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";
import { useInfiniteScroll } from "../../hooks/utilsHooks";
import { Loading } from "../../components/elements/loading/Loading";

export const WaspAIConversation = () => {
  const { id: threadId } = useParams();
  const {
    data: conversations,
    isFetching,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useApiWaspAIConversations(true);

  const conversation = useMemo(
    () => conversations?.pages.map((page) => page.results || []).flat() || [],
    [conversations]
  );

  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(observerElemForFetchPage, !!hasNextPage, fetchNextPage);

  const isSuperuser = useIsSuperuser();
  const conversationInfo: ConversationInfo | undefined = Array.isArray(
    conversation
  )
    ? (conversation as (ConversationInfo | ConversationMessage)[]).find(
        (c): c is ConversationInfo =>
          "thread_id" in c && c.thread_id === threadId
      )
    : undefined;

  if (!isSuperuser)
    return (
      <HeaderMain>
        Access Denied: You do not have the necessary permissions to view this
        page.
      </HeaderMain>
    );

  return (
    <Flex column gap="8px" style={{ paddingBottom: "8px" }}>
      <Flex w100 justify="between" style={{ paddingTop: "16px" }}>
        <BackButton defaultLink={"/wasp-ai-conversations/"} />
        <HeaderSecondary>
          {conversationInfo
            ? `${conversationInfo.account_name} from ${conversationInfo.customer_name}`
            : "Unknown"}
        </HeaderSecondary>
        <Flex></Flex> {/* Placeholder for alignment */}
      </Flex>
      <Box
        style={{
          height: "calc(100vh - 130px)",
          marginBottom: "16px",
          alignItems: "center",
        }}
      >
        <Flex column h100 w100 style={{ overflowY: "scroll" }} align="center">
          <Flex column h100 padding="16px" gap="16px" style={{ width: "65%" }}>
            {conversationInfo ? (
              conversationInfo.conversation.map((c, index) => (
                <ChatMessage
                  key={index}
                  chatMessage={{
                    text: c.content,
                    role: c.sender,
                    fullName: conversationInfo.account_name,
                  }}
                />
              ))
            ) : (
              <Flex w100 h100 justify="center" align="center">
                No info
              </Flex>
            )}
            <div className="d-flex" ref={observerElemForFetchPage} />
            {(isFetching || isRefetching || isFetchingNextPage) && <Loading />}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
