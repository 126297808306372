import { Flex } from "../../../components/layouts/flex/Flex";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { HeaderMain } from "../../../components/elements/typography/Typography";
import { SlaThreshold } from "../../settings/sla/SlaThreshold";
import { SlaPreBreached } from "../../settings/sla/SlaPreBreached";
import { OnboardingFooter } from "./OnboardingFooter";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  goToPrevStage: () => void;
};

const SetSla = (props: Props) => {
  const { onSuccess, onClose, goToPrevStage } = props;
  return (
    <Flex column justify="between" align="center" style={{ height: "600px" }}>
      <HeaderMain>Define the settings for SLA policies</HeaderMain>
      <Flex align="center" gap="32px">
        <SlaThreshold width="600px" />
        <SlaPreBreached width="600px" />
      </Flex>
      <OnboardingFooter onClose={onClose}>
        <Flex gap="8px">
          <SecondaryButton label="Back" onClick={goToPrevStage} size="medium" />
          <MainButton label="Next" onClick={onSuccess} size="medium" />
        </Flex>
      </OnboardingFooter>
    </Flex>
  );
};

export default SetSla;
