import styled from "styled-components";
import { TypographyBodyRegular } from "../../typography/Typography";
type ButtonProps = {
  size: number;
  fontSize?: string;
  color?: string;
};

export const StyleTextButton = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  ${TypographyBodyRegular}
  font-size: ${(props) => props.fontSize || 12 * props.size}px;
  border: none;
  color: ${(props) => props.color || props.theme.primary};
  box-shadow: unset;
  background-color: transparent;
  cursor: pointer;
  &:hover:enabled {
    font-weight: 600;
  }
  &:disabled {
    color: ${(props) => props.theme.black600};
    cursor: not-allowed;
  }
  &:active:enabled {
    color: ${(props) => props.theme.secondary};
  }
  &:focus {
    outline: none;
  }
`;
