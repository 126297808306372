import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../components/elements/badge/Badge";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { Column, Table } from "../../components/elements/table/Table";
import { BodyRegular } from "../../components/elements/typography/Typography";
import { useApiProducts } from "../../hooks/queries/productsContext";
import { useApiReportRequest } from "../../hooks/queries/projectsContext";
import useToastContext from "../../hooks/toastHook";
import { ReportItem } from "./ReportsGenerator";
import { useApiMe } from "../../hooks/queries/meContext";
import { getCustomerNameUrlFormat } from "../../shared/helper";
import { Mixpanel } from "../../shared/mixpanel";
import { Tooltip } from "../../components/elements/tooltip/Tooltip";

export const ReportsTable = ({
  reports,
  refetchReports,
}: {
  reports: ReportItem[];
  refetchReports: () => void;
}) => {
  const { data: products } = useApiProducts();
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();

  const GenerateBtn = ({ report }: { report: ReportItem }) => {
    const addToast = useToastContext();
    const { mutate, isLoading } = useApiReportRequest({
      projectId: report.projectId,
      onSuccessCallback: () => refetchReports(),
      onErrorCallback: (error) =>
        addToast({
          type: "error",
          message: error?.message || "",
          durationMs: 5000,
        }),
    });

    const handleGenerateReport = (report: ReportItem) => {
      Mixpanel.track("Generate Report", { report_type: report.type });
      if (me?.customer && report.type === "Retest Report") {
        window.open(
          `/${getCustomerNameUrlFormat(me.customer.name)}/projects/${
            report.projectId
          }/retest-report`
        );
      } else if (me?.customer && report.type === "Full Report") {
        window.open(
          `/${getCustomerNameUrlFormat(me.customer.name)}/projects/${
            report.projectId
          }/full-report`
        );
      } else if (me?.customer && report.type === "Executive Report") {
        window.open(
          `/${getCustomerNameUrlFormat(me.customer.name)}/projects/${
            report.projectId
          }/executive-summary-report`
        );
      } else if (me?.customer && report.type === "Attestation letter") {
        window.open(
          `/${getCustomerNameUrlFormat(me.customer.name)}/projects/${
            report.projectId
          }/attestation-letter-report`
        );
      } else {
        mutate({ reportData: { type: report.type } });
      }
    };

    return me?.customer.is_locked ? (
      <Tooltip
        content={
          "Access to certain features is removed when licenses expire. To regain access, please contact our support team at sales@op-c.net"
        }
        placement="left"
      >
        <MainButton
          disabled={true}
          label="Generate"
          onClick={() => {}}
          inProgress={isLoading}
          iconName="details"
          size="small"
          style={{ width: "80%" }}
          dataTestId={`report-btn-${report.projectId}-${report.type}`}
        />
      </Tooltip>
    ) : (
      <MainButton
        label="Generate"
        onClick={() => {
          handleGenerateReport(report);
        }}
        inProgress={isLoading}
        iconName="details"
        size="small"
        style={{ width: "80%" }}
        dataTestId={`report-btn-${report.projectId}-${report.type}`}
      />
    );
  };

  const columns: Column[] = [
    {
      title: "Report Type",
      key: "type",
      sortable: true,
      cell: (row) => (
        <BodyRegular>
          <span className="text-capitalize" style={{ marginLeft: "8px" }}>
            {row.type}
          </span>
        </BodyRegular>
      ),
    },
    {
      title: "Related Products",
      key: "productName",
      sortable: true,
      cell: (row) =>
        products
          ?.filter((p) => row.productsId.includes(p.id))
          .map((p) => (
            <Badge key={p.id} style={{ background: theme.blue100 }}>
              {" "}
              {p.name}{" "}
            </Badge>
          )),
    },
    {
      title: "Project Name",
      key: "projectName",
      sortable: true,
      cell: (row) => <BodyRegular>{row.projectName}</BodyRegular>,
    },
    {
      title: "Status",
      key: "status",
      sortable: true,
      maxWidth: "170px",
      cell: (row) => <GenerateBtn report={row} />,
    },
  ];

  const rows = reports.map((report) => ({
    ...report,
    productName: products?.find((p) => report.productsId[0] === p.id)?.name,
    type: report.type || "other",
  }));

  return (
    <Table
      rows={rows || []}
      columns={columns || []}
      isStriped
      defaultSortKey="projectName"
      defaultSortOrder="desc"
    />
  );
};
