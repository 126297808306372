import React from "react";
import {
  BodyBold,
  LabelMedium,
} from "../../components/elements/typography/Typography";
import { Flex } from "../../components/layouts/flex/Flex";
import { QuickScanResult } from "../../types/QuickScanResult";

export type IssueCategory = "header" | "general" | "ssl" | "cookie";

export type Issue = {
  description: string;
  title: string;
  mitigation: JSX.Element | JSX.Element[];
  details: JSX.Element | JSX.Element[];
  category: IssueCategory;
};

export type Goodie = {
  title: string;
  description: string;
  category: IssueCategory;
};

export type QuickScanGrade = "A" | "B" | "C" | "D" | "F";

export type AnalysisResult = {
  grade: QuickScanGrade;
  issues: Issue[];
  goodies: Goodie[];
};

const Details = ({ method, result }: { method: string; result: string }) => (
  <Flex column>
    <BodyBold>Details</BodyBold>
    <Flex column>
      <LabelMedium>{method}</LabelMedium>
      <LabelMedium>{result}</LabelMedium>
    </Flex>
  </Flex>
);

const Mitigation = ({ text }: { text: string }) => (
  <Flex column>
    <BodyBold>Mitigation:</BodyBold>
    <LabelMedium>{text}</LabelMedium>
  </Flex>
);

const HeaderDetails = ({ name }: { name: string }) => (
  <Details
    method={`We scanned the HTTP headers for the presence of the ${name} header.`}
    result={`The header was not found.`}
  />
);

export function analyzeScanResult(scanResult: QuickScanResult): AnalysisResult {
  let score = 100;
  const issues: Issue[] = [];
  const goodies: Goodie[] = [];

  if (!scanResult.ssl_valid.valid) {
    score -= 20;
    issues.push({
      title: "SSL certificate is not valid",
      description: `The SSL certificate for the domain is either expired or not properly configured.`,
      details: (
        <Flex column>
          <Flex column>
            <BodyBold>Details:</BodyBold>
            <LabelMedium>
              We checked the SSL certificate validity for the domain.
            </LabelMedium>
            <LabelMedium>
              The SSL certificate is either expired or not correctly configured.
            </LabelMedium>
          </Flex>
          {scanResult.ssl_valid.error && (
            <Flex column>
              <BodyBold>Error:</BodyBold>
              <LabelMedium>{scanResult.ssl_valid.error}</LabelMedium>
            </Flex>
          )}
          {scanResult.ssl_valid.reason && (
            <Flex>
              <BodyBold>Reason</BodyBold>
              <LabelMedium>{scanResult.ssl_valid.reason}</LabelMedium>
            </Flex>
          )}
        </Flex>
      ),
      mitigation: (
        <Flex column>
          <BodyBold>Mitigation:</BodyBold>
          <LabelMedium>
            <ul>
              <li>
                Ensure that a valid SSL certificate is installed and correctly
                configured for the domain.
              </li>
              <li>
                Reissue or renew the SSL certificate from your certificate
                authority if it is expired or invalid.
              </li>
              <li>
                Configure your server to properly serve the SSL certificate.
                Refer to the documentation of your server for specific
                instructions:
              </li>
              <ul>
                <li>
                  Apache:{" "}
                  <a href="https://httpd.apache.org/docs/2.4/ssl/ssl_howto.html">
                    https://httpd.apache.org/docs/2.4/ssl/ssl_howto.html
                  </a>
                </li>
                <li>
                  Nginx:{" "}
                  <a href="https://nginx.org/en/docs/http/configuring_https_servers.html">
                    https://nginx.org/en/docs/http/configuring_https_servers.html
                  </a>
                </li>
                <li>
                  IIS:{" "}
                  <a href="https://docs.microsoft.com/en-us/iis/manage/configuring-security/how-to-set-up-ssl-on-iis">
                    https://docs.microsoft.com/en-us/iis/manage/configuring-security/how-to-set-up-ssl-on-iis
                  </a>
                </li>
              </ul>
            </ul>
          </LabelMedium>
        </Flex>
      ),
      category: "ssl",
    });
  } else {
    goodies.push({
      title: "SSL certificate is valid",
      description: "The SSL certificate for the domain is valid.",
      category: "ssl",
    });
  }

  // Check security headers
  if (
    scanResult.security_headers["Strict-Transport-Security"] === "Not Found"
  ) {
    score -= 10;
    issues.push({
      title: "Missing Strict-Transport-Security header",
      description:
        "The Strict-Transport-Security header is not present. This header helps to prevent man-in-the-middle attacks by ensuring that browsers only communicate with the server using HTTPS.",
      category: "header",
      details: <HeaderDetails name="Strict-Transport-Security" />,
      mitigation: (
        <Mitigation text="Add the Strict-Transport-Security header to enforce HTTPS connections." />
      ),
    });
  } else {
    goodies.push({
      title: "Strict-Transport-Security header is present",
      description:
        "The Strict-Transport-Security header is present, enforcing HTTPS connections.",
      category: "header",
    });
  }

  if (scanResult.security_headers["Content-Security-Policy"] === "Not Found") {
    score -= 10;
    issues.push({
      title: "Missing Content-Security-Policy header",
      description:
        "The Content-Security-Policy header is missing. This header helps to prevent cross-site scripting (XSS) attacks by specifying allowed sources of content.",
      category: "header",
      details: <HeaderDetails name="Content-Security-Policy" />,
      mitigation: (
        <Mitigation text="Add a Content-Security-Policy header to specify allowed content sources and prevent XSS attacks." />
      ),
    });
  } else {
    goodies.push({
      title: "Content-Security-Policy header is present",
      description:
        "The Content-Security-Policy header is present, helping to prevent cross-site scripting (XSS) attacks.",
      category: "header",
    });
  }

  if (scanResult.security_headers["X-Frame-Options"] === "Not Found") {
    score -= 10;
    issues.push({
      title: "Missing X-Frame-Options header",
      description:
        "The X-Frame-Options header is not present. This header helps to prevent clickjacking attacks by controlling whether the browser should be allowed to render a page in a <frame> or <iframe>.",
      category: "header",
      details: <HeaderDetails name="X-Frame-Options" />,
      mitigation: (
        <Mitigation text="Add the X-Frame-Options header to prevent your website from being framed and protect against clickjacking attacks." />
      ),
    });
  } else {
    goodies.push({
      title: "X-Frame-Options header is present",
      description:
        "The X-Frame-Options header is present, preventing your website from being framed and protecting against clickjacking attacks.",
      category: "header",
    });
  }

  if (scanResult.security_headers["X-Content-Type-Options"] === "Not Found") {
    score -= 10;
    issues.push({
      title: "Missing X-Content-Type-Options header",
      description:
        "The X-Content-Type-Options header is missing. This header prevents browsers from interpreting files as a different MIME type than what is specified.",
      category: "header",
      details: <HeaderDetails name="X-Content-Type-Options" />,
      mitigation: (
        <Mitigation text="Add the X-Content-Type-Options header with the value 'nosniff' to prevent MIME type sniffing." />
      ),
    });
  } else {
    goodies.push({
      title: "X-Content-Type-Options header is present",
      description:
        "The X-Content-Type-Options header is present, preventing MIME type sniffing.",
      category: "header",
    });
  }

  if (scanResult.security_headers["Referrer-Policy"] === "Not Found") {
    score -= 10;
    issues.push({
      title: "Missing Referrer-Policy header",
      description:
        "The Referrer-Policy header is not set. This header controls how much referrer information is included with requests.",
      category: "header",
      details: <HeaderDetails name="Referrer-Policy" />,
      mitigation: (
        <Mitigation text="Add the Referrer-Policy header to control the amount of referrer information sent with requests." />
      ),
    });
  } else {
    goodies.push({
      title: "Referrer-Policy header is present",
      description:
        "The Referrer-Policy header is present, controlling the amount of referrer information sent with requests.",
      category: "header",
    });
  }

  if (scanResult.security_headers["Permissions-Policy"] === "Not Found") {
    score -= 10;

    issues.push({
      title: "Missing Permissions-Policy header",
      description:
        "The Permissions-Policy header is not present. This header allows you to control which web platform features can be used in the browser.",
      category: "header",
      details: <HeaderDetails name="Permissions-Policy" />,
      mitigation: (
        <Mitigation text="Add the Permissions-Policy header to control the usage of features like geolocation, camera, microphone, etc." />
      ),
    });
  } else {
    goodies.push({
      title: "Permissions-Policy header is present",
      description:
        "The Permissions-Policy header is present, controlling the usage of web platform features.",
      category: "header",
    });
  }

  // Check directory listing
  if (scanResult.directory_listing) {
    score -= 15;
    issues.push({
      title: "Directory listing is enabled",
      description:
        "Directory listing is enabled on the server, which allows users to view the contents of directories.",
      category: "general",
      details: (
        <Details
          method="We checked the server configuration for directory listing settings."
          result="Directory listing is enabled."
        />
      ),
      mitigation: (
        <Mitigation text="Disable directory listing on the server to prevent users from viewing the contents of directories." />
      ),
    });
  }

  var hasCookieSecureIssue = false;
  var hasHttpOnlyIssue = false;
  var hasSameSiteIssue = false;
  scanResult.cookies_security_results.forEach((cookies_security_result) => {
    var issuesList = (<ul></ul>) as JSX.Element;
    if (!issuesList.props.children) {
      issuesList = React.cloneElement(issuesList, { children: [] });
    }
    cookies_security_result.issues.forEach((issue) => {
      if (issue === "Secure") {
        hasCookieSecureIssue = true;

        issuesList.props.children.push(
          <li>
            <LabelMedium>
              Cookies are not marked as Secure, which means they can be sent
              over non-HTTPS connections.
            </LabelMedium>
          </li>
        );
      }
      if (issue === "HttpOnly") {
        hasHttpOnlyIssue = true;
        issuesList.props.children.push(
          <li>
            <LabelMedium>
              Cookies are not marked as HttpOnly, which means they can be
              accessed via JavaScript.
            </LabelMedium>
          </li>
        );
      }
      if (issue === "SameSite") {
        hasSameSiteIssue = true;
        issuesList.props.children.push(
          <li>
            <LabelMedium>
              Cookies are not marked as SameSite, which helps to protect against
              cross-site request forgery (CSRF) attacks.
            </LabelMedium>
          </li>
        );
      }
    });
    if (cookies_security_result.issues.length > 0) {
      issues.push({
        title: `Cookie ${cookies_security_result.name} is missing security attributes`,
        description: `Cookie ${cookies_security_result.name} is missing ${
          cookies_security_result.issues.length > 1
            ? "one"
            : cookies_security_result.issues.length
        } security attribute${
          cookies_security_result.issues.length > 1 ? "s" : ""
        }`,
        category: "cookie",
        details: (
          <Flex column>
            <BodyBold>
              Cookie {cookies_security_result.name} is missing the following
              security attributes:
            </BodyBold>
            {issuesList}
          </Flex>
        ),
        mitigation: (
          <Flex column>
            <BodyBold>Mitigation</BodyBold>
            <LabelMedium>
              <ul>
                {hasCookieSecureIssue && (
                  <li>
                    Ensure that cookies are marked as Secure to prevent them
                    from being sent over non-HTTPS connections.
                  </li>
                )}
                {hasHttpOnlyIssue && (
                  <li>
                    Ensure that cookies are marked as HttpOnly to prevent them
                    from being accessed via JavaScript.
                  </li>
                )}
                {hasSameSiteIssue && (
                  <li>
                    Ensure that cookies are marked as SameSite to protect
                    against cross-site request forgery (CSRF) attacks.
                  </li>
                )}
              </ul>
            </LabelMedium>
          </Flex>
        ),
      });
    }

    if (hasCookieSecureIssue) {
      score -= 10;
    }
    if (hasHttpOnlyIssue) {
      score -= 10;
    }
    if (hasSameSiteIssue) {
      score -= 5;
    }
  });
  if (!hasCookieSecureIssue && !hasHttpOnlyIssue && !hasSameSiteIssue) {
    goodies.push({
      title: "Cookies have proper security attributes",
      description: "Cookies have the necessary security attributes set.",
      category: "cookie",
    });
  }

  // Check CORS policy
  if (scanResult.cors_policy) {
    score -= 15;
    issues.push({
      title: "The CORS policy is too permissive",
      description:
        "The CORS policy allows too many origins, which can expose your site to cross-origin attacks.",
      category: "general",
      details: (
        <Details
          method="We analyzed the CORS policy settings."
          result="The CORS policy is too permissive."
        />
      ),
      mitigation: (
        <Mitigation text="Restrict the CORS policy to allow only trusted origins." />
      ),
    });
  } else {
    goodies.push({
      title: "CORS policy is properly configured",
      description:
        "The CORS policy is properly configured, restricting access to trusted origins.",
      category: "general",
    });
  }

  // Check for mixed content
  if (scanResult.mixed_content_urls?.length > 0) {
    score -= 15;
    issues.push({
      title: "Mixed content found",
      description:
        "Mixed content is present on the site, meaning both HTTPS and HTTP resources are being loaded. This can lead to security vulnerabilities.",
      category: "general",
      details: (
        <Flex column>
          <Details
            method="We scanned the site for mixed content issues."
            result="Mixed content was found, including the following URLs:"
          />
          <LabelMedium>
            <ul>
              {scanResult.mixed_content_urls.map((url) => (
                <li>{url}</li>
              ))}
            </ul>
          </LabelMedium>
        </Flex>
      ),
      mitigation: (
        <Mitigation text="Ensure all resources are loaded over HTTPS to avoid mixed content issues." />
      ),
    });
  }

  // Check deprecated headers
  if (scanResult.deprecated_headers.length > 0) {
    score -= 5;
    issues.push({
      title: "Deprecated headers found",
      description: `The following deprecated headers are being used: ${scanResult.deprecated_headers.join(
        ", "
      )}`,
      category: "general",
      details: (
        <Flex column>
          <Details
            method="We scanned the HTTP headers for deprecated headers."
            result={`The following deprecated headers were found:`}
          />
          <LabelMedium>
            <ul>
              {scanResult.deprecated_headers.map((url) => (
                <li>{url}</li>
              ))}
            </ul>
          </LabelMedium>
        </Flex>
      ),
      mitigation: (
        <Mitigation text="Remove or replace the deprecated headers with their modern equivalents." />
      ),
    });
  }

  // Check CSP strength
  if (scanResult.is_weak_csp) {
    score -= 15;

    issues.push({
      title: "Header Content-Security-Policy is weak",
      description:
        "The header Content-Security-Policy is weak, which makes the site vulnerable to XSS attacks.",
      category: "general",
      details: (
        <Details
          method="We analyzed the strength of the Content-Security-Policy."
          result="The policy is weak. You should consider strengthening it"
        />
      ),
      mitigation: (
        <Mitigation
          text={`Implement a strong Content-Security-Policy to mitigate the risk of XSS attacks. Consider adding the rules "default-src 'self'" and "script-src 'self'" to restrict content sources.`}
        />
      ),
    });
  }

  // Assign grades based on score
  let grade: QuickScanGrade;
  if (score >= 95) {
    grade = "A";
  } else if (score >= 75) {
    grade = "B";
  } else if (score >= 65) {
    grade = "C";
  } else if (score >= 50) {
    grade = "D";
  } else {
    grade = "F";
  }

  return {
    grade,
    issues,
    goodies,
  };
}

export const getCategoriesWithIssues = (
  analysisResult: AnalysisResult
): Record<IssueCategory, number> =>
  analysisResult.issues.reduce(
    (categories: Record<IssueCategory, number>, issue: Issue) => {
      if (categories[issue.category]) {
        categories[issue.category]++;
      } else {
        categories[issue.category] = 1;
      }
      return categories;
    },
    {} as Record<IssueCategory, number>
  );

export const GetCategoriesWithGoodies = (
  analysisResult: AnalysisResult
): Record<IssueCategory, number> =>
  analysisResult.goodies.reduce(
    (categories: Record<IssueCategory, number>, goodie: Goodie) => {
      if (categories[goodie.category]) {
        categories[goodie.category]++;
      } else {
        categories[goodie.category] = 1;
      }
      return categories;
    },
    {} as Record<IssueCategory, number>
  );

export const getCategoryName = (category: string, count: number): string => {
  switch (category) {
    case "cookie":
      return "Cookie" + (count > 1 ? "s" : "");
    case "general":
      return "General";
    case "header":
      return "Header" + (count > 1 ? "s" : "");
    case "ssl":
      return "TLS/Certificate";
    default:
      return "Unknown";
  }
};
