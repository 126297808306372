import { Dispatch, SetStateAction, useContext } from "react";
import { SeverityDropdown } from "../../../../components/composed/severityDropdown/SeverityDropdown";
import { TagsLine } from "../../../../components/composed/tagsLine/TagsLine";
import { FormError } from "../../../../components/elements/FormsElements/FormError";
import { Box } from "../../../../components/elements/box/Box";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../../components/elements/icon/Icon";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import {
  RequiredField,
  OptionalField,
} from "../../../../components/elements/requiredField/RequiredField";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  FormErrors,
  FormModeState,
  getOptionFromKeyValuePairs,
  mapToOptions,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";
import { cweMap } from "../../../insights/cwePieChart/cweMap";
import { ThemeContext } from "styled-components";
import { SEVERITIES } from "../../../../shared/consts";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { useParams } from "react-router";

type Props = {
  formMode: FormModeState;
  formErrors: FormErrors;
  setFormErrors: (formErrors: FormErrors) => void;
  createFindingData: AdminFindingEdit;
  setCreateFinding: Dispatch<SetStateAction<AdminFindingEdit>>;
  updateFindingData?: Finding;
  setUpdateFinding: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
};

export const RiskAssessment = (props: Props) => {
  const {
    formMode,
    formErrors,
    setFormErrors,
    createFindingData,
    setCreateFinding,
    updateFindingData,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
  } = props;

  const theme = useContext(ThemeContext);
  const { id: updateFindingId } = useParams();

  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  return (
    <Box style={{ width: "100%" }}>
      <Flex column gap="32px">
        <Flex gap="8px">
          <Icon name="data" size={30} color={theme.primary} />{" "}
          <HeaderSecondary>Risk Assessment</HeaderSecondary>
        </Flex>
        <Flex gap="32px" w100>
          <Flex column style={{ width: "50%" }} gap="16px">
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>Overall Risk</LabelRegular>
                  <RequiredField />
                </Flex>
              </Flex>
              <SeverityDropdown
                placeholder="Select risk level"
                dataTestId="overall-risk"
                variant="border"
                onChange={(opt) => {
                  if (opt?.value) {
                    if (formMode === FormModeState.Update) {
                      setEditableUpdateFinding((prev) => ({
                        ...prev,
                        overall_risk: Number(opt.value),
                      }));
                      updateInPlace({ overall_risk: Number(opt.value) });
                    }
                    if (formMode === FormModeState.Create) {
                      setCreateFinding((prev) => ({
                        ...prev,
                        overall_risk: Number(opt.value),
                      }));
                    }
                    let errors = { ...formErrors };
                    errors.overall_risk = [];
                    setFormErrors(errors);
                  }
                }}
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.overall_risk !== undefined &&
                      editableUpdateFindingData.overall_risk > -1
                      ? getOptionFromKeyValuePairs(
                          SEVERITIES,
                          editableUpdateFindingData.overall_risk
                        )
                      : null
                    : createFindingData?.overall_risk !== undefined &&
                        createFindingData.overall_risk > -1
                      ? getOptionFromKeyValuePairs(
                          SEVERITIES,
                          createFindingData.overall_risk
                        )
                      : null
                }
                queryStatus={
                  changedField === "overall_risk" ? queryStatus : undefined
                }
                disabled={queryStatus !== "idle"}
                isError={!!formErrors?.overall_risk?.length}
              />
              {formErrors?.overall_risk?.map((err) => (
                <FormError errorMessage={err} />
              ))}
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>Exploitability</LabelRegular>
                  <RequiredField />
                </Flex>
              </Flex>
              <SeverityDropdown
                placeholder="Select exploitability level"
                dataTestId="exploitability"
                variant="border"
                onChange={(opt) => {
                  if (opt?.value) {
                    if (formMode === FormModeState.Update) {
                      setEditableUpdateFinding((prev) => ({
                        ...prev,
                        exploitability: Number(opt.value),
                      }));
                      updateInPlace({ exploitability: Number(opt.value) });
                    }
                    if (formMode === FormModeState.Create) {
                      setCreateFinding((prev) => ({
                        ...prev,
                        exploitability: Number(opt.value),
                      }));
                    }
                    let errors = { ...formErrors };
                    errors.exploitability = [];
                    setFormErrors(errors);
                  }
                }}
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.exploitability !== undefined &&
                      editableUpdateFindingData.exploitability !== null &&
                      editableUpdateFindingData.exploitability > -1
                      ? getOptionFromKeyValuePairs(
                          SEVERITIES,
                          editableUpdateFindingData.exploitability
                        )
                      : null
                    : createFindingData?.exploitability !== undefined &&
                        createFindingData.exploitability !== null &&
                        createFindingData.exploitability > -1
                      ? getOptionFromKeyValuePairs(
                          SEVERITIES,
                          createFindingData.exploitability
                        )
                      : null
                }
                disabled={queryStatus !== "idle"}
                queryStatus={
                  changedField === "exploitability" ? queryStatus : undefined
                }
                isError={!!formErrors?.exploitability?.length}
              />
              {formErrors?.exploitability?.map((err) => (
                <FormError errorMessage={err} />
              ))}
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>CWE ID</LabelRegular>
                  <OptionalField />
                </Flex>
              </Flex>
              <Dropdown
                placeholder="Select CWE id"
                searchable
                options={mapToOptions(cweMap)}
                variant="border"
                size="medium"
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.cwe_id !== null &&
                      editableUpdateFindingData?.cwe_id !== undefined
                      ? getOptionFromKeyValuePairs(
                          cweMap,
                          editableUpdateFindingData.cwe_id
                        )
                      : null
                    : createFindingData?.cwe_id !== null &&
                        createFindingData?.cwe_id !== undefined
                      ? getOptionFromKeyValuePairs(
                          cweMap,
                          createFindingData.cwe_id
                        )
                      : null
                }
                onChange={(opt) => {
                  if (opt?.value) {
                    if (formMode === FormModeState.Update) {
                      setEditableUpdateFinding((prev) => ({
                        ...prev,
                        cwe_id: Number(opt.value),
                      }));
                      updateInPlace({ cwe_id: Number(opt.value) });
                    }
                    if (formMode === FormModeState.Create) {
                      setCreateFinding((prev) => ({
                        ...prev,
                        cwe_id: Number(opt.value),
                      }));
                    }
                  }
                }}
                disabled={queryStatus !== "idle"}
                queryStatus={
                  changedField === "cwe_id" ? queryStatus : undefined
                }
              />
            </Flex>
          </Flex>
          <Flex column style={{ width: "50%" }} gap="32px">
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>CVEs</LabelRegular>
                  <OptionalField />
                </Flex>
              </Flex>
              <TagsLine
                isEditable={queryStatus === "idle"}
                selectedTags={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.cve_ids || []
                    : createFindingData?.cve_ids || []
                }
                onTagAdd={(tagCve: string) => {
                  if (formMode === FormModeState.Update) {
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cve_ids: prev?.cve_ids
                        ? [...prev.cve_ids, tagCve]
                        : [tagCve],
                    }));
                    updateInPlace({
                      cve_ids: editableUpdateFindingData?.cve_ids
                        ? [...editableUpdateFindingData.cve_ids, tagCve]
                        : [tagCve],
                    });
                  }
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      cve_ids: prev?.cve_ids
                        ? [...prev.cve_ids, tagCve]
                        : [tagCve],
                    }));
                  }
                }}
                onTagRemove={(tagCve: string) => {
                  if (formMode === FormModeState.Update) {
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cve_ids: prev?.cve_ids
                        ? prev.cve_ids.filter((cve) => cve !== tagCve)
                        : [],
                    }));
                    updateInPlace({
                      cve_ids: editableUpdateFindingData?.cve_ids
                        ? editableUpdateFindingData.cve_ids.filter(
                            (cve) => cve !== tagCve
                          )
                        : [],
                    });
                  }
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      cve_ids: prev?.cve_ids
                        ? prev.cve_ids.filter((cve) => cve !== tagCve)
                        : [],
                    }));
                  }
                }}
              />
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>CVSS Score</LabelRegular>
                  <OptionalField />
                </Flex>
              </Flex>
              <InputText
                placeholder="Insert Score..."
                type="number"
                width="100%"
                saveChangesMode={formMode === FormModeState.Update}
                onSave={() => {
                  updateInPlace({
                    cvss_score: Number(editableUpdateFindingData?.cvss_score),
                  });
                }}
                onCancel={() => {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    cvss_score: updateFindingData?.cvss_score,
                  }));
                }}
                queryStatus={
                  changedField === "cvss_score" ? queryStatus : undefined
                }
                disabled={queryStatus !== "idle"}
                initValue={updateFindingData?.cvss_score?.toString() || "0"}
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.cvss_score
                      ? String(editableUpdateFindingData.cvss_score)
                      : "0"
                    : createFindingData?.cvss_score
                      ? String(createFindingData.cvss_score)
                      : "0"
                }
                onChange={(e) => {
                  if (formMode === FormModeState.Update) {
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cvss_score: Number(e.target.value),
                    }));
                  }
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      cvss_score: Number(e.target.value),
                    }));
                  }
                }}
                isError={!!formErrors.cvss_score}
              />
              {formErrors?.cvss_score?.map((err) => (
                <FormError errorMessage={err} />
              ))}
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>CVSS Vector</LabelRegular>
                  <OptionalField />
                </Flex>
              </Flex>
              <InputText
                placeholder="Insert Vector..."
                width="100%"
                saveChangesMode={formMode === FormModeState.Update}
                onSave={() => {
                  updateInPlace({
                    cvss_vector: editableUpdateFindingData?.cvss_vector,
                  });
                }}
                onCancel={() => {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    cvss_vector: updateFindingData?.cvss_vector,
                  }));
                }}
                queryStatus={
                  changedField === "cvss_vector" ? queryStatus : undefined
                }
                initValue={updateFindingData?.cvss_vector || undefined}
                disabled={queryStatus !== "idle"}
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.cvss_vector
                      ? editableUpdateFindingData.cvss_vector
                      : ""
                    : createFindingData?.cvss_vector
                      ? createFindingData.cvss_vector
                      : ""
                }
                onChange={(e) => {
                  if (formMode === FormModeState.Update) {
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cvss_vector: e.target.value,
                    }));
                  }
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      cvss_vector: e.target.value,
                    }));
                  }
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
