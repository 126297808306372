import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../components/elements/icon/Icon";
import { Loading } from "../../../components/elements/loading/Loading";
import { Section } from "../../../components/elements/section/Section";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Switch } from "../../../components/elements/switch/Switch";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useApiProjectRequests } from "../../../hooks/queries/projectRequestsContext";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { environmentOptions } from "../../../shared/consts";
import { getDate } from "../../../shared/helper";
import {
  Asset,
  AssetCloudProperties,
  AssetDomainProperties,
  AssetEdit,
} from "../../../types/Asset";
import {
  getAssetCoverageStatus,
  getAssetLastPt,
  getAssetNextPt,
  getPriorityLabel,
  priorityOptions,
} from "../AssetUtils";
import { AssetGradeBadge } from "../assetsTable/AssetGradeBadge";
import { FindingsItem } from "../assetsTable/FindingsItem";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Product } from "../../../types/Product";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { AssetCoverageIndicator } from "./AssetCoverageScoreProgressBar";

type Props = {
  asset: Asset | undefined | null;
  isEditable: boolean;
  editAsset: AssetEdit | undefined;
  setEditAsset: (filters: AssetEdit | undefined) => void;
};

export const AssetDetailsBox = (props: Props) => {
  const { asset, isEditable, editAsset, setEditAsset } = props;
  const theme = useContext(ThemeContext);
  const isSuperuser = useIsSuperuser();

  const { data: projects } = useApiProjects();
  const { data: products } = useApiProducts();
  const { data: projectRequests } = useApiProjectRequests();

  const lastPT = asset ? getAssetLastPt(asset, projects || []) : undefined;
  const productsOptions: Option[] =
    products?.map((p) => ({ label: p.name, value: p.id })) || [];

  const notSelectedOption: Option = {
    label: "Not selected",
    value: -1,
  };
  const assetToUse = isEditable ? editAsset : asset;
  if (!asset) {
    return <Loading />;
  }

  const product: Product | undefined = products?.find(
    (p) => p.id === assetToUse?.product
  );

  return (
    <Box className="d-flex flex-column gap-24">
      <Flex align="center" justify="around">
        <Section title="ASM">
          <Flex align="center" gap="8px">
            {!isEditable && <Icon name="asm" size={24} color={theme.primary} />}
            <BodyBold>
              {assetToUse?.is_asm_enabled ? "Enabled" : "Disabled"}
            </BodyBold>
            {isEditable && (
              <Switch
                checked={assetToUse?.is_asm_enabled || false}
                dataTestId="asm-switch"
                onChange={(checked: boolean) =>
                  setEditAsset(
                    editAsset
                      ? { ...editAsset, is_asm_enabled: checked }
                      : undefined
                  )
                }
                // If not superuser - Asset must be verified to run ASM
                disabled={!isSuperuser && !asset?.is_verified}
              />
            )}
          </Flex>
        </Section>
        <Section title="Protection Coverage">
          <Flex align="center" gap="8px">
            {asset?.coverage_score && (
              <AssetCoverageIndicator asset={asset} placement="bottom" />
            )}
          </Flex>
        </Section>

        <Section title="Next Scheduled PT">
          <Flex align="center" gap="8px">
            {!isEditable && (
              <Icon name="calender" size={24} color={theme.primary} />
            )}
            {getAssetCoverageStatus(
              asset,
              projects || [],
              projectRequests || []
            ) === "pt_scheduled" ? (
              <BodyBold>
                {getDate(
                  getAssetNextPt(asset, projects || [])?.toISOString() || ""
                )}
              </BodyBold>
            ) : (
              <BodyBold>Not scheduled</BodyBold>
            )}
          </Flex>
        </Section>
        <Section title="Last PT" noSeparator>
          <Flex align="center" gap="8px">
            {!isEditable && (
              <Icon name="calender" size={24} color={theme.primary} />
            )}
            {asset && lastPT ? (
              <BodyBold>{getDate(lastPT.toISOString() || "")}</BodyBold>
            ) : (
              <BodyBold>None</BodyBold>
            )}
          </Flex>
        </Section>
      </Flex>
      <SeparatorHorizontal />
      <Flex align="center" justify="around">
        <Section title="Product">
          <Flex align="center" gap="8px">
            {isEditable ? (
              <Dropdown
                size="large"
                dataTestId="product-dropdown"
                options={productsOptions}
                value={
                  productsOptions.find(
                    (po) => po.value === assetToUse?.product
                  ) || notSelectedOption
                }
                isMenuPositionFixed={true}
                onChange={(option) =>
                  editAsset
                    ? setEditAsset({
                        ...editAsset,
                        product:
                          products?.find(
                            (p) =>
                              p.id === parseInt(option?.value.toString() || "0")
                          )?.id || asset.product,
                      })
                    : {}
                }
              />
            ) : (
              <BodyBold>{product?.name || "N/A"}</BodyBold>
            )}
          </Flex>
        </Section>
        <Section title="Environment">
          <Flex align="center" gap="8px">
            {isEditable ? (
              <Dropdown
                size="large"
                options={environmentOptions}
                dataTestId="environment-dropdown"
                isMenuPositionFixed={true}
                value={
                  environmentOptions.find(
                    (eo) => eo.value === assetToUse?.environment
                  ) || notSelectedOption
                }
                onChange={(option) =>
                  editAsset
                    ? setEditAsset({
                        ...editAsset,
                        environment:
                          option?.value.toString() || editAsset.environment,
                      })
                    : {}
                }
              />
            ) : (
              <BodyBold>{asset.environment}</BodyBold>
            )}
          </Flex>
        </Section>
        <Section title="Priority">
          <Flex align="center" gap="8px">
            {" "}
            {isEditable ? (
              <Dropdown
                size="large"
                options={priorityOptions}
                dataTestId="priority-dropdown"
                value={
                  priorityOptions.find(
                    (eo) => eo.value === assetToUse?.priority
                  ) || notSelectedOption
                }
                isMenuPositionFixed={true}
                onChange={(option) =>
                  editAsset
                    ? setEditAsset({
                        ...editAsset,
                        priority:
                          parseInt(option?.value.toString() || "1") ||
                          editAsset.priority,
                      })
                    : {}
                }
              />
            ) : (
              <BodyBold>{getPriorityLabel(asset.priority)}</BodyBold>
            )}
          </Flex>
        </Section>
        <Section title="Security Grade" noSeparator>
          <Flex align="center" gap="8px">
            <AssetGradeBadge riskScore={asset.risk_score || 0} />
          </Flex>
        </Section>
      </Flex>
      <Flex align="center" justify="around" className="mt-2">
        <Section title="Discoverd On">
          <BodyRegular>{getDate(asset?.created_at) || "N/A"}</BodyRegular>
        </Section>
        <Section title="Findings">
          <FindingsItem relatedFindings={asset.related_findings || []} />
        </Section>
        <Section title="Type">
          <BodyRegular className="text-capitalize">
            {asset?.type || "Domain"}
          </BodyRegular>
        </Section>
        {asset?.type === "cloud" ? (
          <Section title="Resource Name" noSeparator>
            <BodyRegular
              className="text-truncate w-100"
              title={
                (asset?.properties as AssetCloudProperties).resource_name || ""
              }
              style={{
                maxWidth: "200px",
              }}
            >
              {(asset?.properties as AssetCloudProperties).resource_name}
            </BodyRegular>
          </Section>
        ) : (
          <Section title="Domain Name" noSeparator>
            <BodyRegular
              className="text-truncate w-100"
              title={
                (asset?.properties as AssetDomainProperties)?.domain_name || ""
              }
            >
              {(asset?.properties as AssetDomainProperties)?.domain_name}
            </BodyRegular>
          </Section>
        )}
      </Flex>
    </Box>
  );
};
