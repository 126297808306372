import React, { ReactNode, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { IconButton } from "../button/icon/IconButton";
import { Icon } from "../icon/Icon";
import { SeparatorHorizontal } from "../separators/SeparatorHorizontal";
import { HeaderSecondary } from "../typography/Typography";
import { Flex } from "../../layouts/flex/Flex";

type Props = {
  header?: string | ReactNode;
  iconName?: string;
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
  keepOpenOnOutsideClick?: boolean;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  hideCloseIcon?: boolean;
};

type ModalContentProps = {
  width?: string;
  height?: string;
};

const ModalBackground = styled.div`
  position: fixed;
  z-index: 320;
  background: ${(props) => props.theme.backdrop};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2.5px);
`;

const ModalContent = styled.div<ModalContentProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: ${(props) =>
    props.height ? `calc(50% - ${props.height} / 2)` : "50px"};
  left: ${(props) => `calc(50% - ${props.width || 0} / 2)`};
  background: ${(props) => props.theme.bg2};
  transition: all 0.4s;
  padding: 24px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 16px;
  z-index: 330;
  box-shadow: ${(props) => `0px 8px 40px ${props.theme.lightCardShadow}`};
`;

export const Modal = (props: Props) => {
  const {
    header,
    iconName,
    onClose: close,
    keepOpenOnOutsideClick,
    width,
    height,
    style,
    hideCloseIcon = false,
  } = props;
  const theme = useContext(ThemeContext);

  // Close modal on escape button
  const handleEscape = (event: KeyboardEvent) =>
    event.key === "Escape" && close();
  document.addEventListener("keydown", handleEscape);

  return (
    <>
      <ModalBackground onClick={() => !keepOpenOnOutsideClick && close()} />
      <ModalContent height={height} width={width} style={style}>
        <Flex align="center" gap="8px" style={{ position: "relative" }}>
          {header ? (
            <>
              {iconName ? (
                <Icon name={iconName} size={32} color={theme.primary} />
              ) : null}
              <HeaderSecondary>{header}</HeaderSecondary>
            </>
          ) : null}
          {!hideCloseIcon && (
            <div
              style={{
                position: "absolute",
                right: "0px",
                top: "-5px",
              }}
            >
              <IconButton
                iconName="cancel"
                onClick={close}
                dataTestId="close-modal-btn"
              />
            </div>
          )}
        </Flex>
        {header ? (
          <SeparatorHorizontal
            style={{ marginBottom: "16px", marginTop: "12px" }}
          />
        ) : null}
        {props.children}
      </ModalContent>
    </>
  );
};
