import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { IconButton } from "../button/icon/IconButton";
import { LabelMedium } from "../typography/Typography";
import { Badge } from "./Badge";

type Props = {
  content: string | number;
  onDelete?: () => void;
  onClick?: () => void;
  className?: string;
  maxWidth?: string;
};

export const PrimaryBadge = (props: Props) => {
  const { content, onDelete, onClick, className, maxWidth } = props;
  const theme = useContext(ThemeContext);
  return (
    <Badge
      className={`d-flex align-items-center gap-2 text-truncate  ${className}`}
      style={{
        maxWidth: maxWidth ? maxWidth : "300px",
        backgroundColor: theme.blue100,
        color: theme.primary,
        cursor: !!onClick ? "pointer" : "unset",
        paddingInline: "8px",
      }}
    >
      <LabelMedium
        className={`text-truncate ${className}`}
        style={{
          color: theme.primary,
          maxWidth: "300px",
        }}
        onClick={() => onClick && onClick()}
      >
        {content}
      </LabelMedium>
      {!!onDelete && (
        <IconButton iconName="cancel" size="very-small" onClick={onDelete} />
      )}
    </Badge>
  );
};
