import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { LabelRegular } from "../typography/Typography";

export const FormError = ({ errorMessage }: { errorMessage?: string }) => {
  const theme = useContext(ThemeContext);
  return (
    <LabelRegular style={{ color: theme.redPrimary, textTransform: "none" }}>
      {errorMessage}
    </LabelRegular>
  );
};
