import { Option } from "../components/elements/dropdowns/Dropdown";
import { AssetsViewProps, Filter } from "../types/AssetsView";
import { Finding, FindingsCounts } from "../types/Finding";
import { Buffer } from "buffer";

export function removeNullKeys(obj: any): object {
  if (!obj) return obj;

  // remove all keys with null values recursively
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeNullKeys(obj[key]);
    else if (obj[key] === null) delete obj[key];
  });
  return obj;
}

export function bitAfterNow(): number {
  return Date.now() / 1000 + 100;
}

// Returns date in the format: Aug 22, 2022
export function getDateTime(date: string | Date) {
  const newDate = new Date(date);
  const month = newDate.toLocaleString("default", { month: "short" });
  const day = newDate.getDate();
  const year = newDate.getFullYear();
  return `${month} ${day}, ${year}`;
}

export const getTime = (date: string | Date) => {
  const newDate = new Date(date);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

// Format to - Jan 1 2-24
export function getDate(date: string | Date): string | null {
  if (!date) return null;
  const newDate = new Date(date);
  const month = newDate.toLocaleString("default", { month: "short" });
  const day = newDate.getDate();
  const year = newDate.getFullYear();
  return `${month} ${day}, ${year}`;
}

//  Date to "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'" format
export const getIsoString = (date: string): string => {
  const dateObj = new Date(date);
  return dateObj.toISOString();
};

export function getDateTimeDaysFromNow(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}

export function getDateTimeDaysBeforeNow(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
}

export const convertDateToDaysAgo = (inputDate: Date): number => {
  const today = new Date();
  const givenDate = new Date(inputDate);
  const timeDifference = today.getTime() - givenDate.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};

export const dateObjToDateString = (date: Date): string => {
  // Formats a date to a string - yyyy-mm-dd
  if (!date) return "";
  var year = date.toLocaleString("default", { year: "numeric" });
  var month = date.toLocaleString("default", { month: "2-digit" });
  var day = date.toLocaleString("default", { day: "2-digit" });
  return `${year}-${month}-${day}`;
};

export const calculateTimeDiff = (start: string | Date, end: string | Date) => {
  // returns time difference in format HH:MM
  const startDate = new Date(start);
  const endDate = new Date(end);
  const timeDifferenceMs = Math.abs(endDate.getTime() - startDate.getTime());
  const totalSeconds = timeDifferenceMs / 1000;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

export function isEmptyObject(obj: any) {
  // because Object.keys(new Date()).length === 0;
  // we have to do some additional check
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
}

export function getQueryParams(url: string) {
  return url
    ? Object.fromEntries(new URLSearchParams(new URL(url).search)) || {}
    : {};
}

export function calculateBasicScore(findingsCounts: FindingsCounts): number {
  return (
    100 -
    100 *
      0.85 ** findingsCounts.open_criticals *
      0.95 ** findingsCounts.open_highs *
      0.97 ** findingsCounts.open_mediums *
      0.99 ** findingsCounts.open_lows
  );
}

export function calculateProductScore(
  findingsCounts: FindingsCounts,
  customerFinalScore: number
): number {
  const basicScore = calculateBasicScore(findingsCounts);
  const finalScore = findingsCounts.breached_sla
    ? basicScore + (100 - basicScore) * 0.24
    : basicScore;
  const weightedScore = (100 / customerFinalScore) * finalScore;
  return Math.min(Math.round(weightedScore), 100);
}

export function calculateDaysGapFromNow(expiringDate: Date): number {
  return Math.round(
    (expiringDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24)
  );
}

export function getCustomerNameUrlFormat(customerName: String): string {
  return customerName.replace(/[ ]/g, "-").toLowerCase();
}

export function isTwoStringsArraysEqual(
  a: string[] | undefined,
  b: string[] | undefined
) {
  if (a === undefined && b === undefined) return true;
  if (a === undefined || b === undefined) return false;
  if (a.length !== b.length) return false;
  const sortedA = a.sort();
  const sortedB = b.sort();
  return sortedA.every((value, index) => value === sortedB[index]);
}

export function isClosedStatus(status: string): boolean {
  return ["fixed", "acceptable_risk", "dismissed"].includes(status);
}

export function isFindingValid(finding: Finding): boolean {
  const isStatusValid = !["acceptable_risk", "dismissed"].includes(
    finding.status
  );
  return isStatusValid && !finding.is_pending && !finding.is_false_positive;
}

export function range(start: number, end: number): number[] {
  return Array(end - start)
    .fill(undefined)
    .map((_, idx) => start + idx);
}

export function base64ToObject(encodedJson: string) {
  return JSON.parse(Buffer.from(encodedJson, "base64")?.toString("utf-8"));
}

export function objectToBase64(obj: object) {
  return Buffer.from(JSON.stringify(obj)).toString("base64");
}

export function toBase64Filters(filters: Filter[]): string {
  return Buffer.from(JSON.stringify(filters)).toString("base64");
}

export function toBase64AssetsView(assetView: AssetsViewProps): string {
  return Buffer.from(JSON.stringify(assetView)).toString("base64");
}

export function fromBase64AssetsView(
  assetView: string
): AssetsViewProps | null {
  if (!assetView) return null;
  return JSON.parse(Buffer.from(assetView, "base64").toString("utf-8"));
}

type ObjectOfAny = { [index: string]: any };

export function deepEqual(object1: ObjectOfAny, object2: ObjectOfAny): boolean {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object: ObjectOfAny): boolean {
  return object != null && typeof object === "object";
}

export const deepEqualArrays = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => {
    // Deep comparison for nested arrays or objects
    if (Array.isArray(value) && Array.isArray(sortedArr2[index]))
      return deepEqualArrays(value, sortedArr2[index]);
    else if (typeof value === "object" && value !== null)
      return deepEqual(value, sortedArr2[index]);
    return value === sortedArr2[index];
  });
};

export const getActivityType = (projectType: string | undefined) => {
  return projectType?.toLowerCase() === "pt"
    ? "Penetration Testing"
    : "Red Team Infrastructure Penetration Test";
};

export const numberToWord = (num: number): string | number => {
  const temp: { [key: number]: string } = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
  };
  if (num in temp) return temp[num];
  return num;
};

export const getCvssScoreName = (score: number): string => {
  if (score >= 9) return "Critical";
  if (score >= 7) return "High";
  if (score >= 4) return "Medium";
  if (score > 0) return "Low";
  return "None";
};

export const getCvssScoreNormalized = (score: number): 0 | 1 | 2 | 3 | 4 => {
  if (score >= 9) return 4;
  if (score >= 7) return 3;
  if (score >= 4) return 2;
  if (score > 0) return 1;
  return 0;
};

export const stringsListToSelectOptions = (
  strList: string[] | undefined
): Option[] => {
  if (!strList?.length) return [];
  return strList.map((str) => ({ value: str, label: str }));
};

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getLetterByIndex(startLetter: string, offset: number): string {
  const startCode = startLetter.charCodeAt(0); // Get ASCII code of the starting letter
  const targetCode = startCode + offset; // Calculate the ASCII code of the target letter
  return String.fromCharCode(targetCode); // Convert the ASCII code back to a character
}

export const triggerClickOnUrl = (url: string): void => {
  const link = document.createElement("a"); // Create a new anchor element
  link.href = url; // Set the URL
  link.target = "_blank"; // Open in a new tab (optional)
  link.rel = "noopener noreferrer"; // Improve security for new tabs
  document.body.appendChild(link); // Append to the DOM (required for Firefox)
  link.click(); // Trigger the click
  document.body.removeChild(link); // Clean up by removing the element
};
