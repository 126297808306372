import { Flex } from "../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyBoldHover,
  BodyRegular,
  BodyRegularHover,
} from "../../components/elements/typography/Typography";
import { IconButton } from "../../components/elements/button/icon/IconButton";
import { Table } from "../../components/elements/table/Table";
import { Project } from "../../types/Project";
import {
  ProjectProgress,
  calculate_project_progress,
} from "../dashboard/bottomBlock/projectList/ProjectProgress";
import { Assignee } from "../../components/composed/assignee/Assignee";
import { getDateTime, objectToBase64 } from "../../shared/helper";
import { Link } from "react-router-dom";
import { findingsDefaultFilters } from "../findings/Findings";
import { NotFoundBanner } from "../../components/composed/banner/NotFoundBanner";
import { TableSkeleton } from "../../components/elements/table/TableSkeleton";

interface Row extends Project {
  included_assets?: number[];
}

type Props = {
  projects: Project[];
  isFetchingProjects: boolean;
  onOpenProjectPane: (project: Project) => void;
};

export const ProjectsTable = (props: Props) => {
  const { onOpenProjectPane, projects, isFetchingProjects } = props;

  const columns = [
    {
      title: "Project Name",
      key: "name",
      sortable: true,
      minWidth: "200px",
      cell: (row: Row) => (
        <Flex w100>
          <BodyRegularHover onClick={() => onOpenProjectPane(row)}>
            {row.name}
          </BodyRegularHover>
        </Flex>
      ),
    },
    {
      title: "Project Lead",
      key: "project_lead",
      sortable: true,
      cell: (row: Row) => (
        <Flex w100>
          <Assignee
            name={row.opinnovate_poc?.name || ""}
            imageSrc={row.opinnovate_poc_avatar_url}
          />
        </Flex>
      ),
    },
    {
      title: "Start Date",
      key: "start",
      sortable: true,
      //   maxWidth: "120px",
      cell: (row: Row) => (
        <Flex w100>
          <BodyRegular>{getDateTime(row.start)}</BodyRegular>
        </Flex>
      ),
    },
    {
      title: "End Date",
      key: "end",
      sortable: true,
      //   maxWidth: "120px",
      cell: (row: Row) => (
        <Flex w100>
          <BodyRegular>{getDateTime(row.end)}</BodyRegular>
        </Flex>
      ),
    },
    {
      title: "Total Findings",
      key: "findings_total",
      sortable: true,
      maxWidth: "100px",
      cell: (row: Row) => (
        <Flex w100 justify="center">
          {row.findings_counts?.total ? (
            <Link
              to={`/findings?filters=${objectToBase64({
                ...findingsDefaultFilters,
                projects: row.id,
              })}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <BodyBoldHover>{row.findings_counts?.total}</BodyBoldHover>
            </Link>
          ) : (
            <BodyBold>-</BodyBold>
          )}
        </Flex>
      ),
    },
    {
      title: "Open",
      key: "findings_open",
      sortable: true,
      maxWidth: "100px",
      cell: (row: Row) => (
        <Flex w100 justify="center">
          {row.findings_counts?.open ? (
            <Link
              to={`/findings?filters=${objectToBase64({
                ...findingsDefaultFilters,
                projects: row.id,
                status: "presented,in_progress,re_test",
              })}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <BodyBoldHover>{row.findings_counts.open}</BodyBoldHover>
            </Link>
          ) : (
            <BodyBold>-</BodyBold>
          )}
        </Flex>
      ),
    },
    {
      title: "Solved",
      key: "findings_solved",
      sortable: true,
      maxWidth: "100px",
      cell: (row: Row) => {
        const solved =
          (row.findings_counts?.total || 0) - (row.findings_counts?.open || 0);
        return (
          <Flex w100 justify="center">
            {solved ? (
              <Link
                to={`/findings?filters=${objectToBase64({
                  ...findingsDefaultFilters,
                  projects: row.id,
                  status: "fixed",
                })}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <BodyBoldHover>{solved}</BodyBoldHover>
              </Link>
            ) : (
              <BodyBold>-</BodyBold>
            )}
          </Flex>
        );
      },
    },
    {
      title: "progress",
      key: "progress",
      sortable: true,
      maxWidth: "100px",
      cell: (row: Row) => (
        <Flex w100 justify="center">
          <ProjectProgress
            totalFindings={row.findings_counts?.total}
            openFindings={row.findings_counts?.open}
          />
        </Flex>
      ),
    },
    {
      title: "",
      key: "",
      maxWidth: "50px",
      isRequired: true,
      cell: (row: Row) => (
        <span data-tut="projects-sliders">
          <IconButton
            iconName="chevronRight"
            size="small"
            dataTestId={`asset-table-open-pane-btn-${row.id}`}
            onClick={() => onOpenProjectPane(row)}
          />
        </span>
      ),
    },
  ];

  const rows = projects.map((p) => ({
    ...p,
    project_lead: p.opinnovate_poc?.name || "",
    findings_total: p.findings_counts?.total || 0,
    findings_open: p.findings_counts?.open || 0,
    findings_solved:
      (p.findings_counts?.total || 0) - (p.findings_counts?.open || 0) || 0,
    progress:
      calculate_project_progress(
        p.findings_counts?.total,
        p.findings_counts?.open
      ) || 0,
  }));

  if (isFetchingProjects) {
    return <TableSkeleton height="calc(100vh - 450px)" />;
  }

  return !!projects.length ? (
    <Table rows={rows} columns={columns} />
  ) : (
    <Flex justify="center" align="center">
      <NotFoundBanner title="No project found!" text="" />
    </Flex>
  );
};
