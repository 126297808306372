import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Icon } from "../icon/Icon";
import { TypographyBodyRegular } from "../typography/Typography";

type Props = {
  label: string;
  iconName?: string;
  onClick?: () => void;
  selected?: boolean;
  style?: React.CSSProperties;
  id?: string;
  disabled?: boolean;
};

type TextWrapperProps = {
  selected?: boolean;
  disabled?: boolean;
};

const TextWrapper = styled.span<TextWrapperProps>`
  ${TypographyBodyRegular}
  color: ${(props) =>
    props.disabled
      ? props.theme.black600
      : props.selected
      ? props.theme.primary
      : props.theme.black900};
  &:hover {
    color: ${(props) =>
      props.disabled ? props.theme.black600 : props.theme.primary};
  }
`;

export const MenuItem = (props: Props) => {
  const {
    label,
    iconName,
    onClick,
    selected,
    id,
    disabled,
    style = {},
  } = props;
  const theme = useContext(ThemeContext);

  return (
    <div
      className="d-flex align-items-center"
      style={{
        padding: "8px 0",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      onClick={onClick}
      id={id}
    >
      {iconName && (
        <span
          style={{
            marginRight: "12px",
          }}
        >
          {
            <Icon
              name={iconName}
              size={24}
              color={disabled ? theme.black600 : theme.blue}
            />
          }
        </span>
      )}
      <TextWrapper
        style={style}
        selected={selected}
        disabled={disabled}
        id={`${id}-title`}
      >
        {label}
      </TextWrapper>
    </div>
  );
};
