import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalTypeaheadMenuPlugin, MenuOption, useBasicTypeaheadTriggerMatch, } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as ReactDOM from "react-dom";
import { $createWaspMentionNode } from "./WaspMentionNode";
const PUNCTUATION = "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";
const DocumentMentionsRegex = {
    NAME,
    PUNCTUATION,
};
const CapitalizedNameMentionsRegex = new RegExp("(^|[^#])((?:" + DocumentMentionsRegex.NAME + "{" + 1 + ",})$)");
const PUNC = DocumentMentionsRegex.PUNCTUATION;
const TRIGGERS = ["@"].join("");
// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";
// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS = "(?:" +
    "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
    " |" + // E.g. " " in "Josh Duck"
    "[" +
    PUNC +
    "]|" + // E.g. "-' in "Salier-Hellendag"
    ")";
const LENGTH_LIMIT = 75;
const AtSignMentionsRegex = new RegExp("(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    VALID_JOINS +
    "){0," +
    LENGTH_LIMIT +
    "})" +
    ")$");
// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;
// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp("(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    "){0," +
    ALIAS_LENGTH_LIMIT +
    "})" +
    ")$");
// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;
const mentionsCache = new Map();
const lookupService = {
    search(users, string, callback) {
        const results = users.filter((user) => user.name && user.name.toLowerCase().includes(string.toLowerCase()));
        callback(results);
    },
};
function useMentionLookupService(mentionString, users) {
    const [results, setResults] = useState([]);
    useEffect(() => {
        const cachedResults = mentionsCache.get(mentionString);
        if (mentionString == null) {
            setResults([]);
            return;
        }
        if (cachedResults === null) {
            return;
        }
        else if (cachedResults !== undefined) {
            setResults(cachedResults);
            return;
        }
        mentionsCache.set(mentionString, null);
        lookupService.search(users, mentionString, (newResults) => {
            mentionsCache.set(mentionString, newResults);
            setResults(newResults);
        });
    }, [mentionString]);
    return results;
}
function checkForCapitalizedNameMentions(text, minMatchLength) {
    const match = CapitalizedNameMentionsRegex.exec(text);
    if (match !== null) {
        // The strategy ignores leading whitespace but we need to know it's
        // length to add it to the leadOffset
        const maybeLeadingWhitespace = match[1];
        const matchingString = match[2];
        if (matchingString != null && matchingString.length >= minMatchLength) {
            return {
                leadOffset: match.index + maybeLeadingWhitespace.length,
                matchingString,
                replaceableString: matchingString,
            };
        }
    }
    return null;
}
function checkForAtSignMentions(text, minMatchLength) {
    let match = AtSignMentionsRegex.exec(text);
    if (match === null) {
        match = AtSignMentionsRegexAliasRegex.exec(text);
    }
    if (match !== null) {
        // The strategy ignores leading whitespace but we need to know it's
        // length to add it to the leadOffset
        const maybeLeadingWhitespace = match[1];
        const matchingString = match[3];
        if (matchingString.length >= minMatchLength) {
            return {
                leadOffset: match.index + maybeLeadingWhitespace.length,
                matchingString,
                replaceableString: match[2],
            };
        }
    }
    return null;
}
function getPossibleQueryMatch(text) {
    const match = checkForAtSignMentions(text, 1);
    return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}
export class MentionTypeaheadOption extends MenuOption {
    constructor(email, name, picture) {
        super(name);
        this.email = email;
        this.name = name;
        this.picture = picture;
    }
}
function MentionsTypeaheadMenuItem({ index, isSelected, onClick, onMouseEnter, option, theme, }) {
    let className = "item";
    if (isSelected) {
        className += " selected";
    }
    return (_jsxs("li", { tabIndex: -1, className: className, ref: option.setRefElement, role: "option", "aria-selected": isSelected, id: "typeahead-item-" + index, onMouseEnter: onMouseEnter, onClick: onClick, "data-testid": `mentions-menu-item-${index}`, style: theme
            ? {
                color: theme.textBody,
                borderRadius: "0px",
                background: isSelected ? theme.black500 : theme.bg3,
            }
            : {}, children: [option.picture, _jsx("span", { className: "text", children: option.name })] }, option.email));
}
export default function WaspMentionsPlugin(props) {
    const { theme, users } = props;
    const [editor] = useLexicalComposerContext();
    const [queryString, setQueryString] = useState(null);
    const results = useMentionLookupService(queryString, users);
    const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
        minLength: 0,
    });
    const options = useMemo(() => results
        .map((user) => new MentionTypeaheadOption(user.email, user.name, user.avatar_url ? (_jsx("img", { src: user.avatar_url, width: "22px", height: "22px", alt: "", style: { borderRadius: "50%", marginRight: "8px" } })) : (_jsx("i", { className: "icon user" }))))
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT), [results]);
    const onSelectOption = useCallback((selectedOption, nodeToReplace, closeMenu) => {
        editor.update(() => {
            const mentionNode = $createWaspMentionNode(selectedOption.name, selectedOption.email);
            if (nodeToReplace)
                nodeToReplace.replace(mentionNode);
            mentionNode.select();
            closeMenu();
        });
    }, [editor]);
    const checkForMentionMatch = useCallback((text) => {
        const slashMatch = checkForSlashTriggerMatch(text, editor);
        if (slashMatch !== null) {
            return null;
        }
        return getPossibleQueryMatch(text);
    }, [checkForSlashTriggerMatch, editor]);
    return (_jsx(LexicalTypeaheadMenuPlugin, { onQueryChange: setQueryString, onSelectOption: onSelectOption, triggerFn: checkForMentionMatch, options: options, menuRenderFn: (anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => anchorElementRef.current && results.length
            ? ReactDOM.createPortal(_jsx("div", { className: "typeahead-popover mentions-menu", children: _jsx("ul", { children: options.map((option, i) => (_jsx(MentionsTypeaheadMenuItem, { theme: theme, index: i, isSelected: selectedIndex === i, onClick: () => {
                            setHighlightedIndex(i);
                            selectOptionAndCleanUp(option);
                        }, onMouseEnter: () => {
                            setHighlightedIndex(i);
                        }, option: option }, option.email))) }) }), anchorElementRef.current)
            : null }));
}
