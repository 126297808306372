import { useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Switch } from "../../../components/elements/switch/Switch";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import {
  useApiRunScan,
  useApiStopScan,
} from "../../../hooks/queries/scansContext";
import { ScanEvent } from "../../../types/ScanEvent";
import { Scanner as ScannerType } from "../../../types/Scanner";
import { ConfirmModal } from "../../../components/composed/confirmModal/ConfirmModal";
import { useApiMe } from "../../../hooks/queries/meContext";
import useToastContext from "../../../hooks/toastHook";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { RunningBadge } from "../../../components/composed/badegs/RunningBadge";
import { useApiCreateCustomerLog } from "../../../hooks/queries/customerLogsContext";

type Props = {
  scanner: ScannerType;
  scanEvent: ScanEvent | null;
  isEnabled: boolean;
  onChange: (check: boolean) => void;
  dataTestId?: string;
};

export const Scanner = (props: Props) => {
  const { scanner, isEnabled, onChange, scanEvent, dataTestId } = props;
  const [showStopModal, setShowStopModal] = useState<boolean>(false);
  const [showStartModal, setShowStartModal] = useState<boolean>(false);
  const { data: me } = useApiMe();
  const addToast = useToastContext();

  const {
    mutate: stopScanMutation,
    isLoading: isStopping,
    isSuccess: isStopped,
    reset: resetDelete,
  } = useApiStopScan();

  const {
    mutate: runScanMutation,
    isLoading: isStarting,
    isSuccess: isStarted,
    reset: resetCreate,
  } = useApiRunScan();
  const { mutate: createCustomerLogMutation } = useApiCreateCustomerLog();

  const isRunning = !!scanEvent;
  const isOverflow = scanner.display_name.length > 18;

  const runScan = () => {
    runScanMutation({
      customerId: me?.customer.id || -1,
      scannerName: scanner.name,
      onSuccessCallback: () => {
        addToast({
          type: "success",
          message: `Scanner ${scanner.display_name} has started successfully`,
        });
        createCustomerLogMutation({
          scanner: scanner.name,
          action: "SCAN_STARTED",
        });
      },
      onErrorCallback: () =>
        addToast({
          type: "error",
          message: `Failed to start scanner ${scanner.display_name}`,
        }),
    });
  };

  const stopScan = () => {
    stopScanMutation({
      customerId: me?.customer.id || -1,
      scannerName: scanner.name,
      username: me?.email,
      customerName: me?.customer.name || "",
      onSuccessCallback: () => {
        addToast({
          type: "success",
          message: `Scanner ${scanner.display_name} has stopped successfully`,
        });
        createCustomerLogMutation({
          scanner: scanner.name,
          action: "SCAN_STOPPED",
        });
      },
      onErrorCallback: () =>
        addToast({
          type: "error",
          message: `Failed to stop scanner ${scanner.display_name}`,
        }),
    });
  };

  return (
    <Box
      className="d-flex flex-column justify-content-center gap-16"
      style={{ padding: "16px" }}
      data-testid={dataTestId}
    >
      <Flex justify="between" gap="24px" align="center" w100>
        {isOverflow ? (
          <Tooltip content={scanner.display_name} isTextTruncate>
            <HeaderSubBold className="text-truncate">
              {scanner.display_name}
            </HeaderSubBold>
          </Tooltip>
        ) : (
          <HeaderSubBold>{scanner.display_name}</HeaderSubBold>
        )}
        {isRunning && (
          <Flex align="center" gap="8px">
            <RunningBadge />
          </Flex>
        )}
      </Flex>
      <SeparatorHorizontal />
      <Flex justify="between" gap="24px" align="center" w100>
        <BodyRegular>Enable Scanner</BodyRegular>
        <Switch
          checked={isEnabled}
          onChange={(check) => onChange(check)}
          dataTestId={`switch-scanner-${scanner.name}`}
          disabled={!!me && !me.can_manage_customer}
        />
      </Flex>
      {isEnabled && (
        <SecondaryButton
          label={`${isRunning ? "Stop" : "Run"} Scanner`}
          onClick={() =>
            isRunning ? setShowStopModal(true) : setShowStartModal(true)
          }
          iconName={isRunning ? "alarm" : "play"}
          size="small"
          variant={isRunning ? "danger" : "primary"}
        />
      )}
      {!!showStopModal && (
        <ConfirmModal
          title={`Stop ${scanner.display_name}`}
          body={
            <BodyRegular>{`Proceeding with action will stop ${scanner.display_name}, The scanner can be turned on later.`}</BodyRegular>
          }
          onConfirm={stopScan}
          onClose={() => {
            setShowStopModal(false);
            resetDelete();
          }}
          isLoading={isStopping}
          isSuccess={isStopped}
          successMessage={`Scanner stopped!`}
          confirmButtonText="Turn off"
          cancelButtonText="Keep active"
          mainButtonVariant="danger"
        />
      )}
      {!!showStartModal && (
        <ConfirmModal
          title={`Start ${scanner.display_name}`}
          body={
            <BodyRegular>{`Proceeding with action will start ${scanner.display_name}, The scanner can be turned off later.`}</BodyRegular>
          }
          onConfirm={runScan}
          onClose={() => {
            setShowStartModal(false);
            resetCreate();
          }}
          isLoading={isStarting}
          isSuccess={isStarted}
          successMessage={`Scanner started!`}
          confirmButtonText="Turn on"
          cancelButtonText="Cancel"
        />
      )}
    </Box>
  );
};
