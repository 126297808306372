import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { dark, light } from "./shared/theme";
import { QueryClientProvider } from "@tanstack/react-query";
import { waspQueryClient } from "./hooks/queries/utils";
import AppRoutes from "./routes/AppRoutes";
import "./assets/css/bootstrap_v5.2.3.css";
import "./assets/css/google_fonts.css";
import "./RichTextEditorTheme.css";
import "./App.css";
import { ToastContextProvider } from "./components/elements/toastTypes/ToastContextProvider";
import TermsOfService from "./features/welcome/login/TermsOfService";
import AuthProvider from "./providers/auth/AuthProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QuickScan } from "./features/quickScan/QuickScan";

type AppContextType = {
  toggleTheme: (themeName: string) => void;
};

export const AppContext = React.createContext<AppContextType>({
  toggleTheme: () => {},
});

const App = () => {
  const defaultTheme = window.localStorage.getItem("wasp-theme") || "light";
  const [theme, setTheme] = useState(defaultTheme === "dark" ? dark : light);

  const toggleTheme = (themeName: string) => {
    const theme = themeName === "dark" ? dark : light;
    window.localStorage.setItem("wasp-theme", themeName);
    setTheme(theme);
  };

  // Match body background color to theme
  let body = document.querySelector("body");
  if (body) body.style.backgroundColor = theme.bg1;

  if (window.location.pathname === "/terms-of-service") {
    return <TermsOfService withPadding />;
  }
  if (window.location.pathname.startsWith("/quick-scan")) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <QuickScan />
        </ThemeProvider>
      </>
    );
  }

  return (
    <QueryClientProvider client={waspQueryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AppContext.Provider
        value={{
          toggleTheme,
        }}
      >
        <ThemeProvider theme={theme}>
          <ToastContextProvider>
            <AuthProvider />
            <div>
              <AppRoutes />
            </div>
          </ToastContextProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
