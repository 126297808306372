import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Box } from "../../components/elements/box/Box";
import { LinkButton } from "../../components/elements/button/link/LinkButton";
import {
  HeaderMain,
  HeaderSecondary,
  HeaderSecondaryRegular,
} from "../../components/elements/typography/Typography";
import { exportAssetsTable } from "../../hooks/queries/assetsContext";
import useToastContext from "../../hooks/toastHook";
import { AssetsTable } from "./assetsTable/AssetsTable";
import { AssetsFilterLine } from "./filters/AssetsFilterLine";
import { emptyAssetsViewProps } from "./filters/FiltersUtils";
import { Overview } from "./topBoxes/Overview";
import { TopDistribution } from "./topBoxes/TopDistribution";
import { ToggleWithIcon } from "../../components/elements/toggle/ToggleWithIcon";
import { Flex } from "../../components/layouts/flex/Flex";
import { SeparatorVertical } from "../../components/elements/separators/SeparatorVertical";
import { InputText } from "../../components/elements/input/textInput/InputText";
import { useDebounceCallback, useScreenWidth } from "../../hooks/utilsHooks";
import { useTrackPage } from "../../hooks/trackingHooks";
import { fromBase64AssetsView, toBase64AssetsView } from "../../shared/helper";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { AddAssetModal } from "./addAssets/AddAssetModal";
import { Tours } from "../../tours/Tours";
import { assetsSteps } from "../../tours/AssetsTourSteps";
import { VerifyAssetModal } from "./assetVerification/VerifyAssetModal";
import { AlertsBanners } from "../alertsBanners/AlertsBanners";
import { useApiMe } from "../../hooks/queries/meContext";
import { Mixpanel } from "../../shared/mixpanel";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";
import AssetsMobile from "./assetsPane/AssetsMobile";
import { AssetsExpired } from "../../licensing/sub-components/AssetsExipred";
import { AddAssetsMenu } from "./addAssets/AddAssetsMenu";
import { ImportAssetsModal } from "./addAssets/ImportAssetsModal";
import { AssetsReGraphCombos } from "./graph/regraph/AssetsReGraphCombos";
import { AssetsReGraphSeq } from "./graph/regraph/AssetsReGraphSeq";
import { AssetsReGraphDynamicSeq } from "./graph/regraph/AssetsReGraphDynamicSeq";
import { AssetsReGraphDynamicCombo } from "./graph/regraph/AssetsReGraphDynamicCombo";
import { AssetsReGraphTechs } from "./graph/regraph/AssetReGraphSeqTechs";
import AssetsGraph from "./graph/sigmaGraph/AssetsGraph";
import { AssetsReGraphIps } from "./graph/regraph/AssetsReGraphIPs";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";
import { AssetsViewProps, Filter } from "../../types/AssetsView";
import { AssetsFlowGraph } from "./graph/flowGraph/AssetsFlowGraph";
import { TextButton } from "../../components/elements/button/text/TextButton";

export const noDeadAssetsFilter = {
  column: "status_code",
  value: "0",
  condition: "is not",
  order: 0,
  next_condition: "and",
};

export const defaultAssetsView = {
  ...emptyAssetsViewProps,
  filters: [noDeadAssetsFilter],
};

export const Assets = () => {
  const { data: me } = useApiMe();
  useTrackPage("Assets", undefined, me);
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const addToast = useToastContext();
  const isSuperuser = useIsSuperuser();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const { view: viewMode } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const assetToVerify = searchParams.get("verify_asset");
  const filtersView = searchParams.get("view");

  const [showLegacyGraph, setShowLegacyGraph] = useState<boolean>(false);
  const [totalAssets, setTotalAssets] = useState<number | undefined>(undefined);

  const [assetsView, setAssetsView] =
    useState<AssetsViewProps>(emptyAssetsViewProps);

  const [groupByFilters, setGroupByFilters] = useState<Filter[]>([]);

  const [isMapView, setIsMapView] = useState<boolean>(viewMode === "map");
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const [showAddAssetModal, setShowAddAssetModal] = useState<boolean>(false);
  const [showImportAssetsModal, setShowImportAssetsModal] =
    useState<boolean>(false);
  const [showVerifyAssetModal, setShowVerifyAssetModal] = useState<number>(
    assetToVerify ? parseInt(`${assetToVerify}`) : 0
  ); // ID of asset to verify

  const setFiltersViewFromURL = useCallback(() => {
    // Sets the assets view state from URL params,
    // If no view attribute on URL params, sets the URL to default view
    let encodedView = searchParams.get("view");
    if (encodedView) {
      let decodedView = fromBase64AssetsView(encodedView);
      if (decodedView) setAssetsView(decodedView);
    } else {
      // Use a new instance to modify `searchParams` without replacing it
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("view", toBase64AssetsView(defaultAssetsView));
      setSearchParams(newSearchParams); // Set the updated `URLSearchParams` instance
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    //  handle filter and map-view url params
    if (
      !searchParams ||
      searchParams.has("assetId") ||
      searchParams.has("commentId")
    )
      return;
    setFiltersViewFromURL();
  }, [searchParams, setFiltersViewFromURL, filtersView]);

  const onExportTable = () => {
    Mixpanel.track("Export List");
    setIsExporting(true);
    exportAssetsTable(assetsView.filters)
      .then((blob) => {
        if (!blob) {
          addToast({
            type: "error",
            message: "Error exporting table",
          });
          return;
        }
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `assets_table.csv`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  const handleSearch = useDebounceCallback((e) => {
    // Updates the view on the URL params
    // This will affect the filters state
    Mixpanel.track("Search Assets");
    let viewBase64 = searchParams.get("view") || "";
    let view = fromBase64AssetsView(viewBase64) || emptyAssetsViewProps;
    let viewFilters = view.filters.filter((f) => f.column !== "name");

    if (e.target.value.length)
      viewFilters.push({
        column: "name",
        value: e.target.value,
        condition: "contains",
        order: 0,
        next_condition: "and",
      });

    view.filters = viewFilters;
    viewBase64 = toBase64AssetsView(view);
    // Use a new instance to modify `searchParams` without replacing it
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("view", viewBase64);
    setSearchParams(newSearchParams); // Set the updated `URLSearchParams` instance
  });

  if (isMobile) return <AssetsMobile assetsView={assetsView} />;
  return (
    <>
      <Flex
        align="start"
        justify="between"
        style={{ height: "calc(100vh - 90px)" }}
      >
        <Flex column gap="12px" className=" w-100">
          <AlertsBanners />
          <Tours steps={assetsSteps(theme)} />
          <Flex justify="between">
            <Flex
              align="baseline"
              gap="8px"
              style={{ paddingRight: "24px", color: theme.black900 }}
            >
              <HeaderMain data-testid="assets-title">Assets</HeaderMain>
              {isMapView && !!totalAssets && (
                <HeaderSecondaryRegular
                  style={{
                    color: theme.black700,
                  }}
                >
                  ({totalAssets})
                </HeaderSecondaryRegular>
              )}
              {isMapView && (
                <TextButton
                  label={showLegacyGraph ? "New View" : "Legacy Graph"}
                  onClick={() => setShowLegacyGraph(!showLegacyGraph)}
                  size="small"
                />
              )}
            </Flex>

            <Flex align="center" gap="16px">
              <ToggleWithIcon
                offIconName="map"
                offText=""
                checked={isMapView}
                onChange={(checked) => {
                  checked
                    ? Mixpanel.track("Toggle Assets view", { Selected: "map" })
                    : Mixpanel.track("Toggle Assets view", {
                        Selected: "table",
                      });
                  setIsMapView(checked);
                  navigate(
                    `/assets/${
                      checked ? "map" : "table"
                    }?view=${toBase64AssetsView(assetsView)}`
                  );
                }}
                onIconName="frame"
                onText=""
                width="120px"
              />
              <SeparatorVertical style={{ height: "16px" }} />

              <AddAssetsMenu
                OpenAddAssetModal={() => setShowAddAssetModal(true)}
                OpenImportAssetsModal={() => setShowImportAssetsModal(true)}
              />

              <MainButton
                label="Edit Assets"
                iconName="edit"
                size="small"
                onClick={() => {
                  Mixpanel.track("Edit Assets");
                  navigate(
                    `/assets/edit?view=${toBase64AssetsView(assetsView)}`
                  );
                }}
                dataTestId="edit-asset-button"
              />
            </Flex>
          </Flex>
          <Flex column gap="24px">
            {(showLegacyGraph || !isMapView) && (
              <AssetsFilterLine assetsView={assetsView} />
            )}

            {isMapView ? (
              me?.customer.is_locked ? (
                <AssetsExpired />
              ) : (
                <>
                  {showLegacyGraph ? (
                    <>
                      <AssetsGraph filters={assetsView.filters} />
                      {isSuperuser && me?.email !== "shahar.kgn@gmail.com" && (
                        <>
                          <AssetsReGraphIps />
                          <AssetsReGraphTechs />
                          {/* <AssetsReGraphDynamicSeqMany /> */}
                          <AssetsReGraphDynamicSeq />
                          <AssetsReGraphDynamicCombo />
                          <AssetsReGraphCombos />
                          <AssetsReGraphSeq />
                        </>
                      )}
                    </>
                  ) : (
                    <AssetsFlowGraph setTotalAssets={setTotalAssets} />
                  )}
                </>
              )
            ) : (
              <>
                <Flex align="stretch" justify="between" gap="24px">
                  <div data-tut="assets-overview">
                    <Overview filters={assetsView.filters} />
                  </div>

                  <div
                    style={{ minWidth: "444px", width: "100%" }}
                    data-tut="assets-dist"
                  >
                    <TopDistribution
                      filters={assetsView.filters}
                      onChange={(filters) => setGroupByFilters(filters)}
                    />
                  </div>
                </Flex>
                <div data-tut="assets-table">
                  <Flex
                    align="center"
                    gap="24px"
                    style={{ marginBottom: "16px" }}
                  >
                    <HeaderSecondary style={{ color: theme.black900 }}>
                      Assets Table
                    </HeaderSecondary>

                    <Flex align="center" style={{ marginLeft: "auto" }}>
                      <LinkButton
                        label="Export List"
                        iconName="export"
                        onClick={onExportTable}
                        inProgress={isExporting}
                      />

                      <div style={{ marginLeft: "16px" }}>
                        <InputText
                          dataTestId="assets-search-bar"
                          onChange={handleSearch}
                          placeholder="Search"
                          iconName="search"
                          style={{ backgroundColor: theme.bg2 }}
                          isClearable
                          onClear={() =>
                            handleSearch({ target: { value: "" } })
                          }
                        />
                      </div>
                    </Flex>
                  </Flex>
                  {me?.customer.is_locked ? (
                    <AssetsExpired />
                  ) : (
                    <>
                      <Box>
                        <AssetsTable
                          filters={[...assetsView.filters, ...groupByFilters]}
                          hasBackToTopButton
                          setShowVerifyAssetModal={setShowVerifyAssetModal}
                        />
                      </Box>
                    </>
                  )}
                </div>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      {showAddAssetModal && (
        <AddAssetModal
          onClose={() => setShowAddAssetModal(false)}
          onApply={(asset) =>
            asset.type === "domain"
              ? setShowVerifyAssetModal(asset.id)
              : setShowAddAssetModal(false)
          }
        />
      )}
      {showImportAssetsModal && (
        <ImportAssetsModal onClose={() => setShowImportAssetsModal(false)} />
      )}
      {(!!showVerifyAssetModal || assetToVerify === "0") && (
        <VerifyAssetModal
          assetId={showVerifyAssetModal}
          onClose={() => {
            setShowVerifyAssetModal(0);
            searchParams.delete("verify_asset");
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
};
