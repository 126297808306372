import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { Mixpanel } from "../shared/mixpanel";
import { Me } from "../types/Me";
import { IS_DEMO_ENV, IS_PRODUCTION_ENV } from "./queries/utils";

const HOTJAR_ID = 2847277;
const HOTJAR_SV = 6;

function getTrackableUserProperties(user: Me) {
  return {
    account_id: user.account_id,
    can_manage_customer: user.can_manage_customer,
    customer: {
      id: user.customer?.id,
      name: user.customer?.name,
      license_type: user.customer?.license,
    },
    email: user.email,
    first_name: user.first_name,
    is_superuser: user.is_superuser,
    last_name: user.last_name,
    name: user.name,
    role: user.role,
  };
}

export function useHotJar(user?: Me) {
  useEffect(() => {
    if (
      user === undefined ||
      !(IS_PRODUCTION_ENV || IS_DEMO_ENV) || // only track in prod/demo
      user.is_superuser // don't track superusers
    ) {
      return;
    }
    hotjar.initialize(HOTJAR_ID, HOTJAR_SV);
    hotjar.identify(user.email, getTrackableUserProperties(user));
  }, [user]);
}

export function useMixpanel(user?: Me) {
  useEffect(() => {
    if (user === undefined) {
      return;
    }
    Mixpanel.identify(user.email);
    Mixpanel.people.set(getTrackableUserProperties(user));
  }, [user]);
}

export function useTrackPage(
  page_name: string,
  params?: Record<string, any>,
  user?: Me
) {
  useEffect(() => {
    if (
      user === undefined ||
      !(IS_PRODUCTION_ENV || IS_DEMO_ENV) || // only track in prod/demo
      user.is_superuser // don't track superusers
    ) {
      return;
    }
    Mixpanel.track("Page View", { page_name: page_name, ...params });
  }, [page_name, params, user]);
}
