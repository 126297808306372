import React from "react";
import { IconProps } from "./WaspIcon";

export function InfoIcon(props: IconProps) {
  const size = props.size || 16;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3371 2.66248H4.66211C4.13185 2.66303 3.62346 2.87392 3.2485 3.24887C2.87355 3.62382 2.66266 4.13221 2.66211 4.66248V11.3375C2.66266 11.8678 2.87355 12.3762 3.2485 12.7511C3.62346 13.1261 4.13185 13.337 4.66211 13.3375H11.3371C11.8674 13.337 12.3758 13.1261 12.7508 12.7511C13.1257 12.3762 13.3366 11.8678 13.3371 11.3375V4.66248C13.3366 4.13221 13.1257 3.62382 12.7508 3.24887C12.3758 2.87392 11.8674 2.66303 11.3371 2.66248ZM12.5371 11.3375C12.5368 11.6557 12.4102 11.9607 12.1853 12.1856C11.9603 12.4106 11.6553 12.5372 11.3371 12.5375H4.66211C4.34396 12.5372 4.03894 12.4106 3.81398 12.1856C3.58901 11.9607 3.46247 11.6557 3.46211 11.3375V4.66248C3.46247 4.34433 3.58901 4.03931 3.81398 3.81434C4.03894 3.58938 4.34396 3.46284 4.66211 3.46248H11.3371C11.6553 3.46284 11.9603 3.58938 12.1853 3.81434C12.4102 4.03931 12.5368 4.34433 12.5371 4.66248V11.3375ZM8.39963 5.57336C8.39963 5.65247 8.37617 5.7298 8.33222 5.79558C8.28826 5.86136 8.22579 5.91263 8.1527 5.94291C8.07961 5.97318 7.99918 5.9811 7.92159 5.96567C7.844 5.95024 7.77273 5.91214 7.71679 5.8562C7.66084 5.80026 7.62275 5.72898 7.60731 5.65139C7.59188 5.5738 7.5998 5.49337 7.63008 5.42028C7.66035 5.34719 7.71162 5.28472 7.7774 5.24077C7.84318 5.19682 7.92052 5.17336 7.99963 5.17336C8.10572 5.17336 8.20746 5.2155 8.28247 5.29051C8.35749 5.36553 8.39963 5.46727 8.39963 5.57336ZM8.39963 6.82231V10.4266C8.39963 10.5327 8.35749 10.6345 8.28247 10.7095C8.20746 10.7845 8.10572 10.8266 7.99963 10.8266C7.89354 10.8266 7.7918 10.7845 7.71679 10.7095C7.64177 10.6345 7.59963 10.5327 7.59963 10.4266V7.22231C7.49354 7.22231 7.3918 7.18017 7.31679 7.10516C7.24177 7.03014 7.19963 6.9284 7.19963 6.82231C7.19963 6.71623 7.24177 6.61449 7.31679 6.53947C7.3918 6.46446 7.49354 6.42231 7.59963 6.42231H7.99963C8.10572 6.42231 8.20746 6.46446 8.28247 6.53947C8.35749 6.61449 8.39963 6.71623 8.39963 6.82231Z"
        fill={props.color || "currentColor"}
        fillOpacity="1"
      />
    </svg>
  );
}
