import { useContext } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Section } from "../../../components/elements/section/Section";
import { Asset, AssetDomainProperties } from "../../../types/Asset";
import { Badge } from "../../../components/elements/badge/Badge";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  asset: Asset | undefined;
  width?: string;
};

export const AssetTech = (props: Props) => {
  const { asset, width } = props;
  const theme = useContext(ThemeContext);

  if (asset?.type !== "domain") {
    return null;
  }

  return (
    <Box style={{ width }}>
      <Section title="Technologies" noSeparator>
        <Flex flexWrap gap="8px">
          {(asset?.properties as AssetDomainProperties)?.technologies?.map(
            (tech: string) => (
              <Badge
                key={`tech-item-${tech}`}
                style={{
                  backgroundColor: theme.blue100,
                  color: theme.blue700,
                }}
              >
                {tech}
              </Badge>
            )
          )}
        </Flex>
      </Section>
    </Box>
  );
};
