import styled from "styled-components";
import "../button/buttonsAnimation.css";

type SwitchBoxProps = {
  state: boolean;
  onChange?: (state: boolean) => void;
  disabled?: boolean;
  label?: string;
  dataTestId?: string;
  inProgress?: boolean;
  width?: string;
};

const SwitchBoxStyle = styled.div<SwitchBoxProps>`
  width: ${(props) => props.width};
  padding: 24px;
  border-radius: 16px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => props.theme.textBody};
  background-color: ${(props) =>
    props.state ? props.theme.blue100 : props.theme.bg2};
  border: 1px solid
    ${(props) => (props.state ? props.theme.primary : props.theme.black500)};
  transition: 0.4s;
  &:hover {
    background-color: ${(props) => props.theme.blue50};
    border: 1px solid ${(props) => props.theme.primary};
  }
`;

export const SwitchBox = (props: SwitchBoxProps) => {
  const { state, disabled, onChange, label, dataTestId, inProgress, width } =
    props;
  return (
    <SwitchBoxStyle
      data-testid={dataTestId}
      disabled={disabled}
      state={state}
      onClick={() => onChange && !disabled && onChange(state)}
      className={inProgress ? "loading-animation" : ""}
      width={width}
    >
      {label}
    </SwitchBoxStyle>
  );
};
