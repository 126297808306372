import React, { useContext } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { LegendItem } from "../../../../components/elements/legend/LegendItem";
import { ThemeContext } from "styled-components";
import {
  RiskScoreComponentBadge,
  RiskScoreComponentType,
} from "../riskScore/RiskScoreComponentBadge";
import { RiskScoreTrend } from "./RiskScoreTrend";

type Props = {
  componentType: RiskScoreComponentType;
  componentWeight: number;
  scoreDelta: number;
};

export const RiskScoreComponentLegend = ({
  componentType,
  scoreDelta,
  componentWeight,
}: Props) => {
  const theme = useContext(ThemeContext);

  const getLabel = () => {
    switch (componentType) {
      case "amount_findings":
        return "Amount of Findings";
      case "critical_findings":
        return "Critical Issues";
      case "breached_sla":
        return "Breached SLA";
    }
  };

  const getColor = () => {
    switch (componentType) {
      case "amount_findings":
        return theme.yellowPrimary;
      case "critical_findings":
        return theme.redPrimary;
      case "breached_sla":
        return theme.orangePrimary;
    }
  };

  return (
    <Flex align="center" justify="between" w100 padding="0 16px ">
      <LegendItem label={getLabel()} color={getColor()} ellipseSize={8} />
      <Flex
        align="center"
        gap="8px"
        justify="between"
        style={{
          width: "130px",
        }}
      >
        <RiskScoreComponentBadge
          componentType={componentType}
          componentWeight={parseFloat(componentWeight.toFixed(2))}
        />
        <RiskScoreTrend scoreDelta={parseFloat(scoreDelta.toFixed(2)) * 100} />
      </Flex>
    </Flex>
  );
};
