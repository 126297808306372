import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";
import { Box } from "../../../../components/elements/box/Box";
import {
  BodyMedium,
  BodyRegular,
  HeaderSecondary,
} from "../../../../components/elements/typography/Typography";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { objectToBase64 } from "../../../../shared/helper";
import { findingsDefaultFilters } from "../../Findings";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../../hooks/utilsHooks";
import { SCREEN_MEDIUM_LAPTOP_WIDTH } from "../../../../shared/consts";

const FindingFunnelLabels = ({
  values,
  timeframeDays,
  totalFindings,
  selectedProduct,
}: {
  values: number[][] | undefined;
  timeframeDays: number;
  totalFindings: number;
  selectedProduct: number | "all";
}) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;

  const icons = ["total", "fixed", "tickets", "sla"];
  const primaryLabels = [
    {
      name: "All",
      params: `?filters=${objectToBase64({
        ...findingsDefaultFilters,
        timeframe: timeframeDays,
        product: selectedProduct,
      })}`,
    },
    {
      name: "Valid",
      params: `?filters=${objectToBase64({
        ...findingsDefaultFilters,
        status: "presented,in_progress,re_test,fixed",
        is_false_positive: false,
        timeframe: timeframeDays,
        product: selectedProduct,
      })}`,
    },
    {
      name: "Open",
      params: `?filters=${objectToBase64({
        ...findingsDefaultFilters,
        status: "presented,in_progress,re_test",
        is_false_positive: false,
        timeframe: timeframeDays,
        product: selectedProduct,
      })}`,
    },
    {
      name: "Breached SLA",
      params: `?filters=${objectToBase64({
        ...findingsDefaultFilters,
        status: "presented,in_progress,re_test,fixed",
        is_false_positive: false,
        timeframe: timeframeDays,
        breached_sla: true,
        product: selectedProduct,
      })}`,
    },
  ];

  const labelStyle = {
    padding: "8px 16px",
  };

  return (
    <Flex justify="between" className="mt-5" gap={"16px"} w100>
      {primaryLabels?.map((label, idx) => {
        const findingsCount =
          values &&
          values[idx] &&
          !!values[idx].length &&
          values[idx].reduce((sum, a) => sum + a);
        const percentage =
          findingsCount &&
          Math.floor((findingsCount / totalFindings) * 100 || 0);

        return (
          <Box
            className={`d-flex text-truncate`}
            style={{
              ...labelStyle,
              width: "100%",
            }}
            data-testid={label.name}
            onClick={() => navigate(`/findings${label.params}`)}
            key={`funnal-label-${label.name}`}
          >
            <Link
              to={`/findings${label.params}`}
              style={{ textDecoration: "none" }}
              className="d-flex align-items-center justify-content-between w-100 gap-4 pointer text-truncate"
            >
              <div className="d-flex align-items-center gap-4 text-truncate">
                {!isLaptop && (
                  <Icon name={icons[idx]} color={theme.primary} size={28} />
                )}
                <HeaderSecondary>{findingsCount}</HeaderSecondary>
                <BodyRegular
                  className="text-truncate"
                  title={label.name}
                  style={{
                    color: theme.textSub,

                    marginInline: "8px",
                  }}
                >
                  {label.name}
                </BodyRegular>
              </div>
              <SeparatorVertical style={{ height: "16px" }} />
              <BodyMedium
                className="w-25 text-center text-truncate"
                style={{
                  color: theme.textSecondary,
                }}
                title={`${percentage}%`}
              >
                {percentage}%
              </BodyMedium>
            </Link>
          </Box>
        );
      })}
    </Flex>
  );
};

export default FindingFunnelLabels;
