import { ReactourStep } from "reactour";
import {
  BodyRegular,
  HeaderSecondary,
} from "../components/elements/typography/Typography";
import { TextButton } from "../components/elements/button/text/TextButton";
import { Link } from "react-router-dom";

export const projectsSteps = (theme: any): ReactourStep[] => [
  {
    selector: '[data-tut="pt-scheduler"]',
    content: () => (
      <>
        <HeaderSecondary>Projects Scheduler</HeaderSecondary>
        <BodyRegular>
          A self-service utility designed for users to define the scope,
          schedule, and launch penetration testing efforts autonomously.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="projects-table"]',
    content: () => (
      <>
        <HeaderSecondary>Projects Table</HeaderSecondary>
        <BodyRegular>
          Provides a comprehensive list of all projects, offering a high-level
          view of their attributes.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="projects-sliders"]',
    content: () => (
      <>
        <HeaderSecondary>View Projects Details</HeaderSecondary>
        <BodyRegular>
          Clicking the arrow will open a dedicated sliding window, revealing the
          projects details
        </BodyRegular>
        <Link to="/insights?tour=on" style={{ textDecoration: "none" }}>
          <TextButton label="Go to Next Tour - Insights Page" />
        </Link>
      </>
    ),
  },
];
