import { Dispatch, SetStateAction, useEffect } from "react";

export function useWaitLoading(
  timeout: number,
  setLoadingData: (ready: boolean) => void,
  isReady: boolean
) {
  return useEffect(() => {
    if (isReady) {
      setTimeout(() => {
        setLoadingData(false);
      }, timeout * 1000);
    }
  }, [isReady, timeout, setLoadingData]);
}

export function usePrint(isReady: boolean, pageTitlePrefix: string) {
  return useEffect(() => {
    if (isReady) {
      const titleBefore = document.title;
      document.title = `${pageTitlePrefix}-OPInnovate-${new Date().toLocaleDateString()}`;
      window.print();
      document.title = titleBefore;
    }
  }, [isReady, pageTitlePrefix]);
}

export function useCountDown(setCountdown: Dispatch<SetStateAction<number>>) {
  return useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(timer);
  }, [setCountdown]);
}
