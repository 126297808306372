import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../../components/elements/box/Box";
import { Icon } from "../../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import {
  BodyBold,
  HeaderSubHover,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Assignee } from "../../../../components/composed/assignee/Assignee";
import { useApiProjects } from "../../../../hooks/queries/projectsContext";
import { Project } from "../../../../types/Project";
import { ProjectProgress } from "./ProjectProgress";
import { ProjectTimes } from "./ProjectTimes";
import { ProjectListSkeleton } from "./ProjectListSkeleton";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../../hooks/utilsHooks";
import { SCREEN_VERY_SMALL_LAPTOP_WIDTH } from "../../../../shared/consts";

type Filters =
  | {
      with_findings_counts: boolean;
      with_poc_avatar: boolean;
      products: number[];
    }
  | {
      with_findings_counts: boolean;
      with_poc_avatar: boolean;
    };

type Props = {
  onOpenProjectPane: (project: Project) => void;
  selectedProduct?: number | "all";
};

export const ProjectList = (props: Props) => {
  const { selectedProduct, onOpenProjectPane } = props;
  const screenWidth = useScreenWidth();
  const isVerySmallLaptop = screenWidth < SCREEN_VERY_SMALL_LAPTOP_WIDTH;
  const defaultFilter = {
    with_findings_counts: true,
    with_poc_avatar: true,
  };
  const [projectFilters, setProjectFilters] = useState<Filters>(defaultFilter);
  const { data: projects, isFetching } = useApiProjects(projectFilters);
  useEffect(() => {
    setProjectFilters(
      !!selectedProduct && selectedProduct !== "all"
        ? { ...defaultFilter, products: [selectedProduct] }
        : defaultFilter
    ); // eslint-disable-next-line
  }, [selectedProduct]);

  const theme = useContext(ThemeContext);

  const StatusElement = (props: { title: string; content: number }) => {
    const { content, title } = props;
    return (
      <Flex column gap="8px">
        <LabelRegular style={{ color: theme.black600 }}>{title}</LabelRegular>
        <Flex align="center" gap="8px">
          <BodyBold>{content}</BodyBold>
        </Flex>
      </Flex>
    );
  };

  if (isFetching) return <ProjectListSkeleton />;

  return (
    <Flex
      column
      gap="16px"
      h100
      className="px-1"
      style={{
        overflowY: "scroll",
        marginTop: "24px",
      }}
    >
      {projects?.map((project, i) => (
        <Box
          key={`project-card-${i}-${project.id}`}
          className="d-flex align-items-center justify-content-between gap-16"
          style={{
            padding: "16px",
          }}
        >
          <Flex
            column
            gap="16px"
            style={{
              flexGrow: 1,
              width: "200px",
            }}
          >
            <Tooltip content={project.name} isTextTruncate>
              <HeaderSubHover
                className="text-truncate"
                style={{ color: theme.black900 }}
                onClick={() => onOpenProjectPane(project)}
              >
                {project.name}
              </HeaderSubHover>
            </Tooltip>

            <Flex align="center" gap="8px">
              <Assignee
                imageSrc={project.opinnovate_poc_avatar_url || ""}
                name={project.opinnovate_poc?.name || ""}
              />
              <SeparatorVertical />
              <LabelRegular
                className="text-uppercase"
                style={{ color: theme.black700 }}
              >
                {project.type}
              </LabelRegular>
            </Flex>
          </Flex>
          <SeparatorVertical />
          {!isVerySmallLaptop && (
            <ProjectTimes
              projectStart={project.start}
              projectEnd={project.end}
            />
          )}

          {!isVerySmallLaptop && <SeparatorVertical />}
          <Flex
            align="center"
            gap="24px"
            justify="between"
            style={{
              flexGrow: 1,
            }}
          >
            <StatusElement
              title="Findings"
              content={project.findings_counts?.total || 0}
            />
            <StatusElement
              title="Open"
              content={project.findings_counts?.open || 0}
            />
            <StatusElement
              title="Solved"
              content={
                (project.findings_counts?.total || 0) -
                  (project.findings_counts?.open || 0) || 0
              }
            />
          </Flex>
          <SeparatorVertical />
          <Flex
            column
            justify="center"
            gap="8px"
            style={{
              flexGrow: 1,
              maxWidth: "100px",
            }}
          >
            <LabelRegular style={{ color: theme.black600 }}>
              Progress
            </LabelRegular>
            <ProjectProgress
              totalFindings={project.findings_counts?.total}
              openFindings={project.findings_counts?.open}
            />
          </Flex>

          <Flex
            data-testid={`open-pane-${project.id}`}
            className="pointer"
            onClick={() => onOpenProjectPane(project)}
          >
            <Icon name="chevronRight" color={theme.primary} size={24} />
          </Flex>
        </Box>
      ))}
    </Flex>
  );
};
