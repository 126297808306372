import {
  ToggleStyle,
  SliderStyle,
  ToggleTextWrapperStyle,
} from "./Toggle.style";

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  offText: string;
  onText: string;
  disabled?: boolean;
  width?: string;
};

export function Toggle(props: Props) {
  const {
    checked,
    onChange,
    offText,
    onText,
    className = "",
    disabled = false,
    width = "142px",
  } = props;

  return (
    <div className={`${className} d-flex`}>
      <ToggleStyle width={width}>
        <input
          disabled={disabled}
          type="checkbox"
          role="button"
          aria-expanded="false"
          style={{
            display: "none",
            position: "relative",
            cursor: "unset",
          }}
          onChange={() => onChange(!checked)}
          checked={checked}
        />
        <SliderStyle checked={checked} disabled={disabled} />
        <ToggleTextWrapperStyle
          checked={checked}
          disabled={disabled}
          onClick={() => onChange(true)}
          data-testid="toggle-on-button"
        >
          <span
            onClick={() => onChange(true)}
            style={{
              position: "absolute",
              pointerEvents: "none",
            }}
          >
            {onText}
          </span>
        </ToggleTextWrapperStyle>
        <ToggleTextWrapperStyle
          onClick={() => onChange(false)}
          checked={!checked}
          disabled={disabled}
          data-testid="toggle-off-button"
        >
          <span
            onClick={() => onChange(false)}
            style={{
              position: "absolute",
              pointerEvents: "none",
            }}
          >
            {offText}
          </span>
        </ToggleTextWrapperStyle>
      </ToggleStyle>
    </div>
  );
}
