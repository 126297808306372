import React, { useContext } from "react";
import { Box } from "../../../../components/elements/box/Box";
import { ThemeContext } from "styled-components";
import { Chart } from "regraph";
import { formatDataForReGraphSeq } from "./RegraphUtils";
import { useApiReGraphItems } from "./context/ReGraphContext";
import { Loading } from "../../../../components/elements/loading/Loading";

export const AssetsReGraphSeq = () => {
  const theme = useContext(ThemeContext);
  var reGraphItems = {};
  const { data, isFetching } = useApiReGraphItems();

  if (!isFetching) {
    reGraphItems = formatDataForReGraphSeq(data);
  }

  console.log(data);

  return (
    <Box
      className="d-flex flex-column w-100"
      style={{
        position: "relative",
        height: `calc(100vh - 260px)`,
        cursor: "grab",
        backgroundColor: theme.white100,
      }}
    >
      {isFetching ? (
        <Loading />
      ) : (
        <Chart
          items={reGraphItems}
          layout={{
            linkShape: "curved",
            name: "sequential",
            stretch: 4,
            // top: ["prod-4", "prod-3", "prod-5", "prod-7", "prod-6"],
            orientation: "down",
            level: "level",
          }}
          options={{
            logo: { image: "/favicon.ico", position: "ne" },
          }}
          combine={{
            // level: ,
            properties: ["environment", "product_id"],
          }}
        />
      )}
    </Box>
  );
};
