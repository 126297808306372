import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "./utils";
import { getItems, getPage } from "./sdk";
import { bitAfterNow, getQueryParams } from "../../shared/helper";
import { FindingLog, FindingLogPage } from "../../types/FindingLog";

const key = "findings-logs";

export const useApiFindingLogsPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useInfiniteQuery<FindingLogPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<FindingLogPage> =>
      getPage(key, { ...filters, page: pageParam }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiFindingLogsLatestFindings = (
  filters?: { [key: string]: any },
  enabled?: boolean
) =>
  useQuery<FindingLog[], Error>({
    queryKey: [key, "latest_findings", filters],
    keepPreviousData: true,
    placeholderData: [],
    enabled,
    queryFn: async (): Promise<FindingLog[]> =>
      getItems(`${key}/latest_findings`, filters),
  });
