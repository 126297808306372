import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Flex } from "../../layouts/flex/Flex";
import { Icon } from "../../elements/icon/Icon";
import { LabelRegular } from "../../elements/typography/Typography";
import { Badge } from "../../elements/badge/Badge";

export const RunningBadge = () => {
  const theme = useContext(ThemeContext);
  return (
    <Badge
      backgroundColor={theme.green50}
      color={theme.green800}
      style={{ animation: "flash 1.4s infinite" }}
    >
      <Flex align="center" gap="4px">
        <Icon name="in_progress" size={18} />
        <LabelRegular>Running</LabelRegular>
      </Flex>
    </Badge>
  );
};
