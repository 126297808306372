import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
} from "./utils";
import {
  getItems,
  createItem,
  updateItem,
  deleteItem,
  getPage,
  getSingleItem,
} from "./sdk";
import {
  AssetsView,
  AssetsViewPage,
  AssetsViewProps,
} from "../../types/AssetsView";
import { bitAfterNow, getQueryParams } from "../../shared/helper";

const key = "assets-views";

export interface AssetsViewContext {
  assetsViewId?: number;
  assetsViewData: AssetsViewProps;
  onSuccessCallback?: (asset: AssetsView) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiAssetsViews = () =>
  useQuery<AssetsView[], Error>({
    queryKey: [key],
    keepPreviousData: true,
    placeholderData: [],
    enabled: true,
    queryFn: async (): Promise<AssetsView[]> => getItems(key),
  });

export const useApiSingleAssetsView = (id: string) =>
  useQuery<AssetsView | undefined, Error>({
    queryKey: [key, id],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: !!id,
    queryFn: async (): Promise<AssetsView | undefined> =>
      getSingleItem(key, id),
  });

export const useApiAssetsViewsPaging = (filters?: { [key: string]: any }) =>
  useInfiniteQuery<AssetsViewPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async ({ pageParam = 1 }): Promise<AssetsViewPage> =>
      getPage(key, { ...filters, page: pageParam }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiCreateAssetsView = () => {
  const queryClient = useQueryClient();
  return useMutation<AssetsView, Error, AssetsViewContext>({
    mutationKey: [key],
    mutationFn: async ({
      assetsViewData,
    }: AssetsViewContext): Promise<AssetsView> =>
      await createItem(key, assetsViewData),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiUpdateAssetsView = () => {
  const queryClient = useQueryClient();
  return useMutation<AssetsView, Error, AssetsViewContext>({
    mutationKey: [key],
    mutationFn: async ({
      assetsViewId,
      assetsViewData,
    }: AssetsViewContext): Promise<AssetsView> =>
      await updateItem(key, assetsViewId || -1, assetsViewData),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiDeleteAssetsView = () => {
  const queryClient = useQueryClient();
  return useMutation<AssetsView, Error, number>({
    mutationKey: [key],
    mutationFn: async (assetsViewId): Promise<any> =>
      await deleteItem(key, {}, assetsViewId),
    onSuccess: (data, id) =>
      removeItemFromContext({
        data,
        queryKey: [key, { id: id }],
        queryClient,
      }),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};
