import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
  updateContext,
} from "./utils";
import {
  getItems,
  createItem,
  getPage,
  getSingleItem,
  updateItem,
  deleteItem,
} from "./sdk";
import {
  Transaction,
  TransactionPage,
  TransactionParams,
} from "../../types/Transaction";
import { bitAfterNow, getQueryParams } from "../../shared/helper";

const key = "transactions";

export interface TransactionContext {
  transactionId: number;
  transactionData: TransactionParams;
  onSuccessCallback?: (data: Transaction) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiTransactions = () =>
  useQuery<Transaction[], Error>({
    queryKey: [key],
    keepPreviousData: true,
    placeholderData: [],
    enabled: true,
    queryFn: async (): Promise<Transaction[]> => getItems(key),
  });

export const useApiSingleTransaction = (id: string) =>
  useQuery<Transaction | undefined, Error>({
    queryKey: [key, parseInt(id)],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: false,
    queryFn: async (): Promise<Transaction | undefined> =>
      getSingleItem(key, id) as Promise<Transaction | undefined>,
  });

export const useApiTransactionsPaging = (filters?: { [key: string]: any }) =>
  useInfiniteQuery<TransactionPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async ({ pageParam = 1 }): Promise<TransactionPage> =>
      getPage(key, { ...filters, page: pageParam }).then((resp) => {
        var page = { ...resp };
        const TransactionsDicts = page?.results || [];
        page.results = TransactionsDicts as Transaction[];
        return page;
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiCreateTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation<Transaction, Error, TransactionParams>({
    mutationKey: [key],
    mutationFn: async (newTransaction): Promise<Transaction> =>
      await createItem(key, newTransaction),
    onSuccess: (data: Transaction, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries(["credits"], queryClient);
    },
  });
};

export const useApiUpdateTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation<Transaction, Error, TransactionContext>({
    mutationKey: [key],
    mutationFn: async ({
      transactionId,
      transactionData,
    }: TransactionContext): Promise<Transaction> =>
      await updateItem(key, transactionId, transactionData),
    onSuccess: (data: Transaction, variables) => {
      updateContext({ data, queryKey: [key, { id: data?.id }], queryClient });
      variables.onSuccessCallback && variables.onSuccessCallback(data);
    },
    onError: (err, variables) => {
      variables.onErrorCallback && variables.onErrorCallback(err);
    },
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiDeleteTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation<Transaction, Error, TransactionContext>({
    mutationKey: [key],
    mutationFn: async (context): Promise<any> =>
      await deleteItem(key, context.transactionData, context.transactionId),
    onSuccess: (data, variables) => {
      variables.onSuccessCallback && variables.onSuccessCallback(data);
      removeItemFromContext({
        data,
        queryKey: [key, { id: variables.transactionId }],
        queryClient,
      });
    },
    onError: (err, variables) => {
      variables.onErrorCallback && variables.onErrorCallback(err);
    },
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};
