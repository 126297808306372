import styled from "styled-components";

type ScrollToTopButtonProps = {
  isVisible: boolean;
  href: string;
};

export const ScrollToTopButtonStyle = styled.a<ScrollToTopButtonProps>`
  z-index: 119;
  display: flex;
  position: fixed;
  bottom: ${(props) => (props.isVisible ? "32px" : "-100px")};
  right: 32px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  color: ${(props) => props.theme.background};
  background-color: ${(props) => props.theme.primary};
  border-radius: 50%;
  border-color: transparent;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: ${(props) => props.theme.blue100};
    color: ${(props) => props.theme.primary};
  }
`;

type RoundBtnProps = {
  isVisible: boolean;
};

export const RoundButtonStyle = styled.button<RoundBtnProps>`
  z-index: 300;
  display: flex;
  position: fixed;
  bottom: ${(props) => (props.isVisible ? "100px" : "-100px")};
  right: 32px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  color: ${(props) => props.theme.background};
  background-color: ${(props) => props.theme.primary};
  border-radius: 50%;
  border-color: transparent;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: ${(props) => props.theme.blue100};
    color: ${(props) => props.theme.primary};
  }
`;
