import { useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SearchBar } from "../../../components/composed/searchBar/SearchBar";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { useApiDeleteAccount } from "../../../hooks/queries/accountsContext";
import { Account } from "../../../types/Account";
import AddEditUserForm from "./AddEditUserForm";
import { UsersTable } from "./UsersTable";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { Mixpanel } from "../../../shared/mixpanel";

const UsersManagement = () => {
  const { data: me } = useApiMe();
  useTrackPage("Settings - Users Management", undefined, me);

  const {
    mutate: deleteAccount,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    reset: resetDelete,
  } = useApiDeleteAccount();

  const [searchWord, setSearchWord] = useState<string>("");
  const [editedAccount, setEditedAccount] = useState<Account | undefined>();
  const [deletedAccount, setDeletedAccount] = useState<Account | undefined>();
  const [openAddPane, setOpenAddPane] = useState<boolean>(false);

  const closePane = () => {
    setEditedAccount(undefined);
    setOpenAddPane(false);
  };

  return (
    <Flex justify="between" style={{ height: "calc(100vh - 90px)" }}>
      <Flex w100 column gap="24px">
        <Flex justify="between">
          <Flex align="center" style={{ height: "50px" }}>
            <HeaderSecondary>Users Management</HeaderSecondary>
          </Flex>
          <Flex className="d-flex align-items-center gap-16">
            <SearchBar setSearchWord={setSearchWord} />
            <SeparatorVertical height="16px" />
            <MainButton
              dataTestId="add-user-btn"
              label="Add User"
              size="small"
              iconName="add"
              onClick={() => {
                Mixpanel.track("Add User");
                setEditedAccount(undefined);
                setOpenAddPane(true);
              }}
            />
          </Flex>
        </Flex>
        <Box>
          <UsersTable
            searchWord={searchWord}
            setEditedAccount={setEditedAccount}
          />
        </Box>
        {(!!editedAccount || openAddPane) && (
          <AddEditUserForm
            editedAccount={editedAccount} //new account if account is "0"
            setDeletedAccount={setDeletedAccount}
            onClose={closePane}
          />
        )}
      </Flex>
      {deletedAccount && (
        <DeleteModal
          itemType="User"
          itemName={deletedAccount.user.name}
          onConfirm={() => {
            Mixpanel.track("Delete User");
            deleteAccount(deletedAccount);
          }}
          onClose={() => {
            isDeleted && setEditedAccount(undefined);
            setDeletedAccount(undefined);
            resetDelete();
          }}
          isLoading={isDeleting}
          isSuccess={isDeleted}
        />
      )}
    </Flex>
  );
};
export default UsersManagement;
