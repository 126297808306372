import React, { useContext } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { AssetLog } from "../../../types/AssetLog";
import { priorityOptions } from "../AssetUtils";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { CardHeader } from "../../findings/findingHistoryAndComments/CardHeader";

type Props = {
  assetLog: AssetLog;
};

export function AssetLogUpdatedCard({ assetLog }: Props) {
  const theme = useContext(ThemeContext);
  const { data: products } = useApiProducts();

  const getValueString = (value: string): string => {
    if (assetLog.field === "priority") {
      return priorityOptions.find((option) => option.value === Number(value))
        ?.label as string;
    }
    if (assetLog.field === "product") {
      return products?.find((product) => product.id === Number(value))
        ?.name as string;
    }

    return value as string;
  };

  const getFieldString = (): string => {
    // remove the underscores and capitalize the first letter of each word
    return assetLog.field
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Box>
      <Flex column gap="16px">
        <CardHeader
          userName={assetLog.actor.name}
          avatarUrl={assetLog.actor.avatar_url}
          createdAt={assetLog.created_at}
        />

        <Flex align="center" gap="8px" flexWrap>
          <LabelRegular color={theme.black600}>{`The field`}</LabelRegular>
          <LabelBold className="text-capitalize">{getFieldString()}</LabelBold>
          <LabelRegular
            color={theme.black600}
          >{`has been changed to`}</LabelRegular>
          <LabelBold className="text-capitalize">
            {getValueString(assetLog.new_value)}
          </LabelBold>
        </Flex>
        {!!assetLog.comment && <SeparatorHorizontal />}
        {!!assetLog.comment && (
          <BodyRegular>{assetLog.comment.body as string}</BodyRegular>
        )}
      </Flex>
    </Box>
  );
}
