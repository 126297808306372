import { useState } from "react";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { Modal } from "../../../components/elements/modal/Modal";
import {
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";
import { CreditPack, CreditPacksParams } from "../../../types/CreditPack";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import {
  useApiCreateCreditPacks,
  useApiDeleteCreditPack,
  useApiUpdateCreditPack,
} from "../../../hooks/queries/creditPacksContext";
import useToastContext from "../../../hooks/toastHook";
import { getDate } from "../../../shared/helper";

var twoYearFromNow = new Date();
twoYearFromNow.setFullYear(twoYearFromNow.getFullYear() + 2);

export type Props = {
  onClose: () => void;
  editCreditPack: CreditPack | undefined;
};

export const AddCreditModal = (props: Props) => {
  const { onClose, editCreditPack } = props;

  const addToast = useToastContext();

  const { data: me } = useApiMe();
  const { mutate: createCreditPack } = useApiCreateCreditPacks();
  const { mutate: updateCreditPack } = useApiUpdateCreditPack();
  const { mutate: deleteCreditPack } = useApiDeleteCreditPack();

  const defaultFormValues: CreditPacksParams = {
    purchased_amount: 0,
    customer: me?.customer.id,
  };

  const initFormValues = editCreditPack
    ? {
        purchased_amount: editCreditPack.purchased_amount,
        customer: me?.customer.id,
      }
    : defaultFormValues;

  const [formValues, setFormValues] =
    useState<CreditPacksParams>(initFormValues);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const submitForm = () => {
    if (showConfirmDelete && !!editCreditPack)
      deleteCreditPack({
        creditPackData: formValues,
        creditPackId: editCreditPack.id,
        onErrorCallback: (err) => {
          addToast({
            message: `Failed to delete credit pack - ${err}`,
            type: "error",
          });
        },
        onSuccessCallback: () => {
          addToast({
            message: "Credit pack deleted successfully",
            type: "success",
          });
          onClose();
        },
      });
    else if (showConfirmation && !!editCreditPack)
      updateCreditPack({
        creditPackData: formValues,
        creditPackId: editCreditPack.id,
        onErrorCallback: (err) => {
          addToast({
            message: `Failed to update credit pack - ${err}`,
            type: "error",
          });
        },
        onSuccessCallback: () => {
          addToast({
            message: "Credit pack updated successfully",
            type: "success",
          });
          onClose();
        },
      });
    else
      createCreditPack({
        ...formValues,
        onErrorCallback: (err) => {
          addToast({
            message: `Failed to create credit pack - ${err}`,
            type: "error",
          });
        },
        onSuccessCallback: () => {
          addToast({
            message: "Transaction created successfully",
            type: "success",
          });
          onClose();
        },
      });
  };
  const disabled = showConfirmation || formValues.purchased_amount <= 0;

  return (
    <Modal
      onClose={onClose}
      header={`Add Credits Package for ${me?.customer.name}`}
      width="790px"
    >
      <Flex column gap="24px">
        <Flex column gap="8px">
          <Flex>
            <LabelBold>Purchased Credits Amount</LabelBold>
          </Flex>
          <InputText
            type="number"
            value={`${formValues.purchased_amount}`}
            onChange={(e) =>
              parseInt(`${e.target.value}`) > 0 &&
              setFormValues((prev) => ({
                ...prev,
                purchased_amount: parseInt(`${e.target.value}`),
              }))
            }
            width="742px"
            disabled={showConfirmation}
          />
        </Flex>

        <Flex gap="8px" justify="end">
          <SecondaryButton
            disabled={showConfirmation}
            label="Cancel"
            onClick={onClose}
            size="medium"
          />
          {editCreditPack && (
            <SecondaryButton
              variant="danger"
              label="Delete Credit Pack"
              onClick={() => {
                setShowConfirmDelete(true);
              }}
              size="medium"
            />
          )}
          <MainButton
            disabled={disabled}
            label={editCreditPack ? "Save Changes" : "Add Credit Pack"}
            onClick={() => setShowConfirmation(true)}
            size="medium"
          />
        </Flex>

        {showConfirmation && (
          <Flex column gap="12px">
            <Flex w100 justify="center">
              <LabelBold>Please confirm credit package</LabelBold>
            </Flex>
            <Flex gap="8px">
              <LabelBold>Purchased Credit Amount: </LabelBold>
              <LabelRegular>{formValues.purchased_amount}</LabelRegular>
            </Flex>
            <Flex gap="8px">
              <LabelBold>Expiration Date: </LabelBold>
              <LabelRegular>{getDate(twoYearFromNow)}</LabelRegular>
            </Flex>
            <SecondaryButton
              label="Edit Credit Pack"
              onClick={() => setShowConfirmation(false)}
              size="medium"
            />
            <MainButton
              label="Confirm Credit Pack"
              onClick={submitForm}
              size="medium"
            />
          </Flex>
        )}
        {showConfirmDelete && (
          <Flex column gap="12px">
            <Flex w100 justify="center">
              <LabelBold>Please confirm deleting credit package</LabelBold>
            </Flex>
            <SecondaryButton
              variant="danger"
              label="Confirm Delete Credit Package"
              onClick={submitForm}
              size="medium"
            />
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
