import { useContext, useRef } from "react";
import { useApiAssetsPaging } from "../../../hooks/queries/assetsContext";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Loading } from "../../../components/elements/loading/Loading";
import { TagBadge } from "../../../components/composed/tagsLine/TagBadge";
import { AssetsDropdown } from "./AssetsDropdown";
import { Option } from "../../../components/elements/dropdowns/Dropdown";
import { SingleValue } from "react-select";
import { Project } from "../../../types/Project";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Asset } from "../../../types/Asset";
import { useNavigate } from "react-router-dom";

type Props = {
  project?: Project;

  includedAssetsObj?: Asset[];
  includedProductsId?: number[];
  onAddAssetTag?: (opt: SingleValue<Option>, asset?: Asset) => void;
  onDeleteAssetOption?: (opt: SingleValue<Option>, asset?: Asset) => void;
  setShowAssetPane?: (id: number) => void;
};

export const AssetsInScope = (props: Props) => {
  const {
    project, // on display mode we only use project id to get data
    // edit mode props:
    includedAssetsObj,
    includedProductsId,
    onAddAssetTag,
    onDeleteAssetOption,
    setShowAssetPane,
  } = props;

  const theme = useContext(ThemeContext);
  const observerElemForFetchPage = useRef(null);
  const navigate = useNavigate();

  const queryParams = !!project?.id ? { projects: project?.id } : {};

  const {
    data: assets,
    isFetchingNextPage: isFetchingAssets,
    hasNextPage: hasAssetsNextPage,
    fetchNextPage: fetchAssetsNextPage,
  } = useApiAssetsPaging({ ...queryParams, page_size: 25 }, !!project?.id);

  const pagedAssets = !!project?.id
    ? assets?.pages?.map((page) => page?.results || []).flat() || []
    : [];

  useInfiniteScroll(
    observerElemForFetchPage,
    !!hasAssetsNextPage,
    fetchAssetsNextPage
  );

  const productsId = includedProductsId || project?.products || [];
  const { data: products } = useApiProducts(
    { id: productsId },
    !!productsId.length
  );

  return (
    <Flex column gap="16px">
      <HeaderSubBold>Assets in Scope</HeaderSubBold>

      {!!onAddAssetTag && (
        <AssetsDropdown
          selected={includedAssetsObj?.map((a) => a.id) || []}
          onChange={(opt, asset) => onAddAssetTag && onAddAssetTag(opt, asset)}
        />
      )}

      <Flex
        flexWrap
        gap="8px"
        style={{ maxHeight: "140px", overflowY: "scroll" }}
      >
        {/* NO DATA ON DISPLAY OR EDIT MODE */}
        {pagedAssets.length === 0 && !includedAssetsObj?.length ? (
          <BodyRegular style={{ color: theme.black600 }}>
            No assets in scope
          </BodyRegular>
        ) : null}

        {/* DISPLAY MODE */}
        {pagedAssets?.map((asset) => (
          <TagBadge
            key={`asset-in-scope-${asset.id}`}
            tag={asset.name}
            onClick={() => {
              setShowAssetPane && setShowAssetPane(asset.id);
              navigate(`/projects?assetId=${asset.id}`);
            }}
          />
        ))}

        {/* EDIT MODE */}
        {includedAssetsObj?.map((asset) => (
          <TagBadge
            option={{ value: asset.id, label: asset.name }}
            key={`asset-option-${asset.id}`}
            onDeleteOption={(opt) =>
              onDeleteAssetOption && onDeleteAssetOption(opt, asset)
            }
            isEditable={!!onDeleteAssetOption}
          />
        ))}

        <div className="d-flex" ref={observerElemForFetchPage} />
        {isFetchingAssets && <Loading />}
      </Flex>

      <SeparatorHorizontal />

      <HeaderSubBold>Products in Scope</HeaderSubBold>
      <Flex
        flexWrap
        gap="8px"
        style={{ maxHeight: "140px", overflowY: "scroll" }}
      >
        {!!productsId.length &&
          products?.map((prod) => (
            <TagBadge key={`prods-in-scope-${prod.id}`} tag={prod.name} />
          ))}
      </Flex>
    </Flex>
  );
};
