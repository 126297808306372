import { Link } from "react-router-dom";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import {
  BodyBold,
  BodyRegular,
  BodyRegularHover,
  HeaderSecondary,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Asset } from "../../../types/Asset";
import { AssetVerificationIndicator } from "../assetVerification/AssetVerificationIndicator";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import {
  useApiVerifyAsset,
  useApiVerifyAssetsBulk,
} from "../../../hooks/queries/assetsContext";
import useToastContext from "../../../hooks/toastHook";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { toBase64AssetsView } from "../../../shared/helper";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Illustration } from "../../../components/elements/illustrations/Illustration";

type Props = {
  assets: Asset[];
  onClose: () => void;
  isNewAssets?: boolean;
};

export const VerifyImportedAssets = (props: Props) => {
  const { assets, onClose, isNewAssets } = props;

  const addToast = useToastContext();

  // Use all assets for redirecting to edit assets table
  const assetsFilters = assets.map((a, idx) => ({
    column: "name",
    condition: "is",
    value: a.name,
    order: idx,
    next_condition: "or",
  }));
  assetsFilters[0].next_condition = "and";
  const assetsView = toBase64AssetsView({ name: "", filters: assetsFilters });

  const rootAssets = assets.filter((a) => !a.parent_asset);
  const areAllAssetsVerified = !rootAssets.filter((a) => !a.is_verified).length;

  const { data: me } = useApiMe();

  const { mutate: verify, isLoading } = useApiVerifyAsset();
  const { mutate: verifyBulk, isLoading: isLoadingBulk } =
    useApiVerifyAssetsBulk();

  const verifyAsset = (asset: Asset) =>
    asset &&
    verify({
      assetId: asset.id,
      assetData: asset,
      onSuccessCallback: (assetData) => {
        if (!assetData.is_verified)
          addToast({
            type: "error",
            message: "Token was not found on your DNS records",
          });
      },
      onErrorCallback: (err) =>
        addToast({
          type: "error",
          message: `Failed to verify asset ownership | ${err}`,
        }),
    });

  const verifyAllAssets = () => {
    rootAssets &&
      verifyBulk({
        asset_ids: assets.map((a) => a.id),
        onSuccessCallback: () => {},
        onErrorCallback: (err) =>
          addToast({
            type: "error",
            message: `Failed to verify asset ownership | ${err}`,
          }),
      });
  };

  return areAllAssetsVerified ? (
    <Flex column w100 justify="center" align="center" gap="24px">
      <Illustration name="success" />
      <HeaderSecondary>Your assets ownership is verified !</HeaderSecondary>
      <HeaderSubBold>Now, let's configure your new assets.</HeaderSubBold>
      <Link
        to={`/assets/edit?view=${assetsView}`}
        style={{ textDecoration: "none" }}
      >
        <MainButton label="Configure Assets" size="medium" />
      </Link>
      <SecondaryButton
        label="Close and configure later"
        onClick={onClose}
        size="medium"
      />
    </Flex>
  ) : (
    <Flex column w100>
      <Flex column w100 justify="center" align="center" gap="24px">
        {isNewAssets && (
          <>
            <BodyBold>Your new assets have been created successfully!</BodyBold>
            <BodyBold>Now let's verify Your Root Domains Ownership</BodyBold>
          </>
        )}
        <BodyRegular>
          Add this token to your DNS records, so we can safely scan your assets
        </BodyRegular>
        <InputText
          disabled
          copyToClipboard
          value={me?.customer.assets_verification_token}
          width={"330px"}
        />
        <Link
          style={{ textDecoration: "none" }}
          target="_blank"
          to="https://opinnovate.notion.site/WASP-Domains-verification-a1b9bf8afee6491e80df63367871a375"
        >
          <BodyRegularHover>
            Click here to learn how to add tokens to your DNS records
          </BodyRegularHover>
        </Link>
      </Flex>
      <Flex
        column
        gap="12px"
        style={{
          maxHeight: "240px",
          overflowY: "auto",
          margin: "24px",
        }}
      >
        {Object.values(rootAssets).map((asset) => (
          <>
            <Flex align="center" justify="between">
              <BodyRegular style={{ width: "240px" }}>{asset.name}</BodyRegular>
              <TextButton
                label="verify"
                disabled={isLoading}
                onClick={() => verifyAsset(asset)}
              />
              <AssetVerificationIndicator asset={asset} />
            </Flex>
            <SeparatorHorizontal />
          </>
        ))}
      </Flex>
      <Flex gap="8px" align="center" justify="end">
        <SecondaryButton label="Close" onClick={onClose} size="medium" />
        <MainButton
          label="Verify All Assets"
          onClick={verifyAllAssets}
          inProgress={isLoadingBulk}
          size="medium"
        />
      </Flex>
    </Flex>
  );
};
