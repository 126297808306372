import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BASE_API_URL,
  DEFAULT_STALE_TIME,
  getRandomAvatar,
  invalidateApiQueries,
  updateContext,
} from "./utils";
import { Me } from "../../types/Me";
import { bitAfterNow } from "../../shared/helper";
import { updateItem } from "./sdk";

type MeParams = {
  phone?: string;
};

interface MeContext {
  meData: MeParams;
}

export const useApiMe = () => {
  return useQuery<Me, Error>({
    queryKey: ["me"],
    queryFn: async () =>
      await fetch(`${BASE_API_URL}/users/me/`).then(async (res) => {
        var userData = await res.json();
        if (userData && !userData.avatar_url)
          userData.avatar_url = getRandomAvatar();
        return userData;
      }),

    staleTime: DEFAULT_STALE_TIME,
    cacheTime: DEFAULT_STALE_TIME,
    refetchInterval: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
  });
};

export const useApiUpdateMe = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation<Me, Error, MeContext>({
    mutationKey: ["me"],
    mutationFn: async ({ meData }: MeContext): Promise<Me> =>
      await updateItem("users/me/", 0, meData),
    onSuccess: (data: Me) => {
      updateContext({ data, queryKey: [["me"], { id: "" }], queryClient });
      onSuccessCallback && onSuccessCallback();
    },
    onError: (error) => onErrorCallback && onErrorCallback(error),
    onSettled: () => invalidateApiQueries(["me"], queryClient),
  });
};

// This might be unnecessary !!
// we can call queries invalidation on multiple queryKeys
// try it when we creating  and updating account on AddEditUserForm
export const useUpdateMeContext = () => {
  const queryClient = useQueryClient();
  return useMutation<Me, Error, Me, MeContext>(
    (newMe) => new Promise((resolve) => resolve(newMe)),
    { onSuccess: (newMe) => queryClient.setQueryData(["me"], newMe) }
  );
};
