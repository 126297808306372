import { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "../icon/Icon";
import { ScrollToTopButtonStyle } from "./ScrollToTopButtonStyle";

type Props = {
  onIntersect?: (state: boolean) => unknown;
};

export const ScrollToTop = (props: Props) => {
  const { onIntersect } = props;
  const [showTopBtn, setShowTopBtn] = useState<boolean>(false);
  const observerElement = useRef(null);

  const handleObserver = useCallback(
    (entries: any) => {
      const [target] = entries;

      const tableHeadY = document
        .querySelector("#top")
        ?.getBoundingClientRect().y;

      if (tableHeadY && tableHeadY > 0) return;
      onIntersect && onIntersect(!!!target.isIntersecting);
      setShowTopBtn(!!!target.isIntersecting);
    },
    [onIntersect]
  );

  useEffect(() => {
    const rootElement = document.querySelector(".scroll-to-top-root");

    const element = observerElement.current;
    if (!element) return;

    const option = { threshold: 0, root: rootElement };
    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [handleObserver, observerElement]);

  return (
    <>
      <div id="top" style={{ position: "relative", top: "-300px" }} />
      <div ref={observerElement} />
      <ScrollToTopButtonStyle
        href="#top"
        isVisible={showTopBtn}
        data-testid="scroll-to-top-btn"
      >
        <Icon name="chevronUp" size={32} />
      </ScrollToTopButtonStyle>
    </>
  );
};
