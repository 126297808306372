import { Icon } from "../../../components/elements/icon/Icon";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { BodyBold } from "../../../components/elements/typography/Typography";
import { FindingChartTooltipContent } from "./FindingChartTooltipContent";

type Props = {
  relatedFindings: number[];
  color?: string;
};

export const FindingsItem = (props: Props) => {
  const { relatedFindings, color } = props;

  return (
    <div className="d-flex gap-6 align-items-center">
      <BodyBold color={color}>{relatedFindings.length || 0}</BodyBold>
      {relatedFindings.length > 0 && (
        <Tooltip
          content={
            <FindingChartTooltipContent relatedFindings={relatedFindings} />
          }
        >
          <Icon name="info" size={16} color={color} />
        </Tooltip>
      )}
    </div>
  );
};
