import { useContext, useState } from "react";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { TransactionsTable } from "./TransactionsTable";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { CreditPacksModal } from "./CreditPacksModal";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { PolicyModal } from "./PolicyModal";
import { useApiCreditPacks } from "../../../hooks/queries/creditPacksContext";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { AddCreditModal } from "./AddCreditModal";
import { AddTransactionModal } from "./AddTransactionModal";
import { useApiTransactions } from "../../../hooks/queries/transactionsContext";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Mixpanel } from "../../../shared/mixpanel";

export const Credits = () => {
  const { data: me } = useApiMe();
  useTrackPage("Settings - Credits", undefined, me);
  const theme = useContext(ThemeContext);
  const { data: creditsPacks } = useApiCreditPacks();
  const { data: transactions } = useApiTransactions();

  const [showCreditPacksModal, setShowCreditPacksModal] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);

  const [showAddCreditModal, setShowAddCreditModal] = useState(false);
  const [showAddTransactionModal, setShowAddTransactionModal] = useState(false);
  const [editTransaction, setEditTransaction] = useState<number>(0);
  const [editCreditPack, setEditCreditPack] = useState<number>(0);

  const currentBalance =
    creditsPacks
      ?.filter((cp) => cp.status !== "expired")
      ?.map((cp) => cp.current_pack_balance)
      ?.reduce((cpBalance, total) => total + cpBalance, 0) || 0;

  const balanceColor = theme.greenPrimary;

  const isSuperuser = useIsSuperuser();
  if (!isSuperuser) return null;
  return (
    <>
      <Flex
        gap="16px"
        justify="between"
        style={{ height: "calc(100vh - 90px)" }}
      >
        <Flex column gap="16px" className="w-100">
          <Flex justify="between">
            <Flex
              w100
              justify="between"
              align="center"
              style={{ height: "50px" }}
            >
              <HeaderSecondary
                style={{ paddingRight: "24px", color: theme.black900 }}
              >
                Credits
              </HeaderSecondary>
              {isSuperuser ? (
                <Flex gap="8px">
                  <SecondaryButton
                    onClick={() => setShowAddCreditModal(true)}
                    label="Add Credit package"
                    variant="admin"
                    size="medium"
                  />
                  <SecondaryButton
                    onClick={() => setShowAddTransactionModal(true)}
                    label="Add Transaction"
                    variant="admin"
                    size="medium"
                  />
                </Flex>
              ) : null}
            </Flex>
          </Flex>
          <Flex align="center" justify="between">
            <Flex column>
              <Flex gap="24px">
                <BodyRegular>Available Credits</BodyRegular>
                <BodyBold color={balanceColor}>{currentBalance || 0}</BodyBold>
              </Flex>
              <Flex>
                <TextButton
                  label="Tokens Purchases History"
                  onClick={() => {
                    Mixpanel.track("Tokens Purchases History - Button clicked");
                    setShowCreditPacksModal(true);
                  }}
                />
                <SeparatorVertical height="32px" />
                <TextButton
                  label="Tokens Expiration Policy"
                  onClick={() => {
                    Mixpanel.track("Tokens Expiration Policy - Button clicked");
                    setShowPolicyModal(true);
                  }}
                />
              </Flex>
            </Flex>
            <MainButton
              label="Purchase Credits Package"
              onClick={() => {
                // implement purchase
                Mixpanel.track("Purchase Credits Package - Button click");
              }}
              size="medium"
            />
          </Flex>
          <BodyBold>Transactions history</BodyBold>
          <Box>
            <TransactionsTable
              setEditTransaction={setEditTransaction}
              setEditCreditPack={setEditCreditPack}
            />
          </Box>
        </Flex>
        {showCreditPacksModal && (
          <CreditPacksModal onClose={() => setShowCreditPacksModal(false)} />
        )}
        {showPolicyModal && (
          <PolicyModal onClose={() => setShowPolicyModal(false)} />
        )}
        {showAddCreditModal || editCreditPack ? (
          <AddCreditModal
            onClose={() => {
              setEditCreditPack(0);
              setShowAddCreditModal(false);
            }}
            editCreditPack={creditsPacks?.find((c) => c.id === editCreditPack)}
          />
        ) : null}
        {(showAddTransactionModal || editTransaction) && transactions ? (
          <AddTransactionModal
            onClose={() => {
              setEditTransaction(0);
              setShowAddTransactionModal(false);
            }}
            editTransaction={transactions.find((t) => t.id === editTransaction)}
          />
        ) : null}
      </Flex>
    </>
  );
};
