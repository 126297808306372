import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { useState } from "react";
import { AssetsTable } from "../../assets/assetsTable/AssetsTable";
import { AssetsFilterLine } from "../../assets/filters/AssetsFilterLine";
import { AssetsViewProps } from "../../../types/AssetsView";
import { emptyAssetsViewProps } from "../../assets/filters/FiltersUtils";
import { SuggestedFilters } from "./SuggestedFilters";
import { Asset } from "../../../types/Asset";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { useDebounceCallback, useScreenWidth } from "../../../hooks/utilsHooks";
import { useApiAssetsCount } from "../../../hooks/queries/assetsContext";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { AddAssetModal } from "../../assets/addAssets/AddAssetModal";
import { CheckboxState } from "../../../components/elements/checkbox/Checkbox";
import { Mixpanel } from "../../../shared/mixpanel";
import { noDeadAssetsFilter } from "../../assets/Assets";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { SCREEN_LAPTOP_WIDTH } from "../../../shared/consts";

type Props = {
  includedAssetsIds: number[];
  setIncludedAssetsIds: (rowsIds: number[]) => void;
  isSelectAll: boolean;
  setIsSelectAll: (state: boolean) => void;
};

export const WhatToScan = (props: Props) => {
  Mixpanel.track("Schedule a Test - What to scan");
  const {
    includedAssetsIds,
    setIncludedAssetsIds,
    isSelectAll,
    setIsSelectAll,
  } = props;

  const screenWidth = useScreenWidth();
  const isSmallLaptop = screenWidth ? screenWidth < SCREEN_LAPTOP_WIDTH : false;

  const [assetsView, setAssetsView] = useState<AssetsViewProps>({
    ...emptyAssetsViewProps,
    filters: [noDeadAssetsFilter],
  });

  const [showAddAssetsModal, setShowAddAssetsModal] = useState<boolean>(false);

  const onRowSelect = (row: Asset, state: CheckboxState) => {
    var temp = [...includedAssetsIds];
    if (state === "checked") temp.push(row.id);
    else temp = temp.filter((r) => r !== row.id);
    setIsSelectAll(false);
    setIncludedAssetsIds(temp);
  };

  const onSelectAll = (rowsId: number[], state: CheckboxState) => {
    setIncludedAssetsIds(state === "checked" ? rowsId : []);
    if (state !== "checked") setIsSelectAll(false);
  };

  const { data: assetsCount } = useApiAssetsCount({});

  const handleSearch = useDebounceCallback((e) => {
    const oldFilters = [...assetsView.filters].filter(
      (f) => f.column !== "name"
    );
    const newFilters = !!e.target.value
      ? [
          ...oldFilters,
          {
            column: "name",
            value: e.target.value,
            condition: "contains",
            order: 0,
            next_condition: "and",
          },
        ]
      : oldFilters;
    setAssetsView({ ...assetsView, filters: newFilters });
  });

  return (
    <>
      <Flex w100 style={{ height: "calc(92vh - 230px)", overflowY: "hidden" }}>
        <Flex column gap="24px" style={{ width: "30%" }}>
          <HeaderSecondary>Please Select Assets</HeaderSecondary>
          <BodyRegular>
            Define the project's scope by selecting assets to include.
            <br /> Our predefined filters will keep you from missing untested
            assets
          </BodyRegular>
          <SuggestedFilters
            assetsView={assetsView}
            setAssetsView={setAssetsView}
          />
        </Flex>

        <SeparatorVertical height="65vh" style={{ marginInline: "32px" }} />

        <Flex column gap="24px" w100>
          <Flex w100 align="center" justify="between">
            <Flex align="center" gap="24px">
              <HeaderSecondary>Assets List</HeaderSecondary>
              <SeparatorVertical height="24px" />
              <Flex align="center" gap="8px" column={isSmallLaptop}>
                <Flex gap="4px">
                  <BodyBold>
                    {isSelectAll
                      ? assetsCount?.total
                      : includedAssetsIds.length}
                  </BodyBold>
                  <BodyRegular>
                    Assets Selected {!isSelectAll ? "On This Page" : ""}
                  </BodyRegular>
                </Flex>
                <Flex justify="center" style={{ width: "180px" }}>
                  {isSelectAll ? (
                    <TextButton
                      fontSize="14px"
                      label="Clear Selection"
                      onClick={() => {
                        setIsSelectAll(false);
                        setIncludedAssetsIds([]);
                      }}
                    />
                  ) : (
                    <TextButton
                      fontSize="14px"
                      onClick={() => setIsSelectAll(true)}
                      label={`Select All ${assetsCount?.total} Assets`}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center">
              <InputText
                dataTestId="assets-search-bar"
                onChange={handleSearch}
                placeholder="Search"
                iconName="search"
              />
              <SeparatorVertical height="32px" style={{ marginLeft: "16px" }} />
              <LinkButton
                label="Add New Asset"
                iconName="plus"
                onClick={() => setShowAddAssetsModal(true)}
              />
            </Flex>
          </Flex>
          <AssetsFilterLine
            assetsView={assetsView}
            setAssetsView={setAssetsView}
          />
          <Flex w100 column style={{ overflowY: "auto" }}>
            <AssetsTable
              isSelectable
              // isRowClickable
              filters={assetsView.filters}
              selectedAssetsIds={includedAssetsIds || []}
              onRowSelect={onRowSelect}
              onSelectAll={onSelectAll}
              isSelectAll={isSelectAll}
              stateStorageKey="what_to_scan"
            />
          </Flex>
        </Flex>
      </Flex>
      {showAddAssetsModal && (
        <AddAssetModal onClose={() => setShowAddAssetsModal(false)} />
      )}
    </>
  );
};
