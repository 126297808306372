import { UseMutateFunction } from "@tanstack/react-query";
import { Finding, FindingImage } from "../../../types/Finding";
import {
  FindingImageParams,
  UpdateFindingParams,
} from "../../../hooks/queries/findingContext";
import { Dispatch, SetStateAction } from "react";
import { FormSubmissionState } from "../../../shared/formUtils";
import { ToastProps } from "../../../components/elements/toastTypes/ToastContextProvider";
import { JiraHtmlImageFixer } from "../../../shared/parser";

export type ImageHandlerReturn = {
  html: string;
  markdown: string;
  attachments: string[];
};

function extractSavedImageName(matchString: string) {
  const imageNameKey = "image_name=";
  const start = matchString.indexOf(imageNameKey) + imageNameKey.length;
  const end = matchString.indexOf('"', start);
  return matchString.slice(start, end);
}

const ensureCorrectImgNameInMarkdown = (markdown: string) => {
  const regex = /![^!]*image_name=([^!]+)!/g;

  // Function to extract the image name
  const extractMarkdownImageName = (match: string) => {
    const matchRegex = /image_name=([^!]+)/;
    const result = match.match(matchRegex);
    return result ? result[1] : null;
  };

  // Replace function that uses the image name to replace the match
  return markdown.replace(regex, (match) => {
    const imageName = extractMarkdownImageName(match);
    if (imageName) {
      return "!" + imageName + "!";
    }
    return match; // Return original match if no image name is found
  });
};

export const uploadFindingTextAndImages = async (
  findingId: number,
  descriptionWasp: string,
  attackDetailsWasp: string,
  mitigationWasp: string,
  descriptionMarkdown: string,
  attackDetailsMarkdown: string,
  mitigationMarkdown: string,
  descriptionJira: string,
  attackDetailsJira: string,
  mitigationJira: string,
  createImage: UseMutateFunction<
    FindingImage,
    Error,
    FindingImageParams,
    unknown
  >,
  updateFinding: UseMutateFunction<
    Finding,
    Error,
    UpdateFindingParams,
    unknown
  >,
  setSubmissionState?: Dispatch<SetStateAction<FormSubmissionState>>,
  addToast?: (toast: ToastProps) => void,
  onSuccessCallback?: (finding: Finding) => void
) => {
  var attachments: string[] = [];
  const {
    attachments: descriptionAttachments,
    html: descriptionHTMLFixed,
    markdown: descriptionMarkdownFixed,
  } = await handleFindingBase64Images(
    findingId,
    descriptionWasp
      ? descriptionWasp
      : JiraHtmlImageFixer(descriptionJira, findingId),
    descriptionMarkdown,
    createImage
  );
  attachments = attachments.concat(descriptionAttachments);

  const {
    attachments: attackDetailsAttachments,
    html: attackDetailsHTMLFixed,
    markdown: attackDetailsMarkdownFixed,
  } = await handleFindingBase64Images(
    findingId,
    attackDetailsWasp
      ? attackDetailsWasp
      : JiraHtmlImageFixer(attackDetailsJira, findingId),
    attackDetailsMarkdown,
    createImage
  );
  attachments = attachments.concat(attackDetailsAttachments);

  const {
    attachments: mitigationAttachments,
    html: mitigationHTMLFixed,
    markdown: mitigationMarkdownFixed,
  } = await handleFindingBase64Images(
    findingId,
    mitigationWasp
      ? mitigationWasp
      : JiraHtmlImageFixer(mitigationJira, findingId),
    mitigationMarkdown,
    createImage
  );
  attachments = attachments.concat(mitigationAttachments);

  updateFinding({
    id: findingId,
    description_wasp: descriptionHTMLFixed,
    attack_details_wasp: attackDetailsHTMLFixed,
    mitigation_wasp: mitigationHTMLFixed,
    description: descriptionMarkdownFixed,
    attack_details: attackDetailsMarkdownFixed,
    mitigation: mitigationMarkdownFixed,
    attachments: attachments,

    onSuccessCallback: (finding) => {
      if (setSubmissionState)
        setSubmissionState(FormSubmissionState.Successful);
      if (addToast) addToast({ message: `Updated finding!`, type: "success" });
      if (onSuccessCallback) onSuccessCallback(finding);
    },
    onErrorCallback: (error) => {
      if (setSubmissionState)
        setSubmissionState(FormSubmissionState.FailedSubmission);
      if (addToast)
        addToast({
          message: `Failed to update! Error: ${error}`,
          type: "error",
        });
    },
  });
};

export const handleFindingBase64Images = async (
  findingId: number,
  html: string,
  markdown: string,
  createImage: UseMutateFunction<
    FindingImage,
    Error,
    FindingImageParams,
    unknown
  >
): Promise<ImageHandlerReturn> => {
  // First, we find base64 images and save them in the WASP DB
  const regex = /<img[^>]*src="data:image\/([^;]+);base64,([^"]+)"[^>]*>/g;
  const matches = html.match(regex);
  let attachments: string[] = [];

  const updatePromises = matches
    ? matches.map((match) => {
        const base64 = match.split(",")[1]?.split('"')[0];
        const type = match.split("/")[1].split(";")[0];
        const img = `data:image/${type};base64,${base64}`;

        return new Promise((resolve, reject) => {
          createImage({
            findingId,
            base64,
            type,
            onSuccessCallback(data) {
              resolve({
                old: img,
                new: `/api/v1/findings/${findingId}/images?image_name=${data.image_name}`,
                imageName: data.image_name,
              });
            },
            onErrorCallback(error) {
              reject(error);
            },
          });
        });
      })
    : [];

  const results: PromiseSettledResult<any>[] =
    await Promise.allSettled(updatePromises);

  results.forEach((result) => {
    if (result.status === "fulfilled") {
      const { old, new: newUrl, imageName } = result.value;
      html = html.replace(old, newUrl);
      markdown = markdown.replace(old, imageName);
      attachments.push(imageName);
    }
  });

  // Then we search for already saved images, and make sure we don't skip
  // them, when building the attachments array
  const regexForSavedImages = /<img src="[^"]*image_name=([^"]+)"[^>]*>/g;
  const savedImagesMatches = html.match(regexForSavedImages);
  if (savedImagesMatches) {
    savedImagesMatches.forEach((savedPicMatch) => {
      const imageName = extractSavedImageName(savedPicMatch);
      if (!!imageName && !attachments.includes(imageName)) {
        attachments.push(imageName);
      }
    });
  }
  // Finally, we make sure the image name is correctly saved in the markdown
  // Since RTE converts it to an undesirable format
  markdown = ensureCorrectImgNameInMarkdown(markdown);

  return { html, markdown, attachments };
};
