import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AlertBannerReceiver } from "../../types/AlertBannerMessage";
import { getItems, patchItem } from "./sdk";
import { invalidateApiQueries } from "./utils";

const key = "alert-banner-receivers";
const ALERT_STALE_TIME = 1000 * 60 * 5; // 5 min, since alerts need to update frequently
interface AlertBannerReceiverDismissedParams {
  is_dismissed: boolean;
}

interface AlertBannerMessageDismissedContext {
  alertBannerReceiverId: number;
  alertBannerReceiverData: AlertBannerReceiverDismissedParams;
  onSuccessCallback?: (data: AlertBannerReceiver) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiAlertBannerReceivers = (params?: { [key: string]: any }) =>
  useQuery<AlertBannerReceiver[], Error>({
    queryKey: [key, params],
    placeholderData: [],
    refetchInterval: ALERT_STALE_TIME,
    queryFn: async (): Promise<AlertBannerReceiver[]> => getItems(key, params),
  });

export const useApiAlertBannerDismissed = () => {
  const queryClient = useQueryClient();
  return useMutation<
    AlertBannerReceiver,
    Error,
    AlertBannerMessageDismissedContext
  >({
    mutationKey: [key],
    mutationFn: async ({
      alertBannerReceiverId,
      alertBannerReceiverData,
    }: AlertBannerMessageDismissedContext): Promise<AlertBannerReceiver> =>
      await patchItem(key, alertBannerReceiverId, alertBannerReceiverData),
    onSuccess: (data: AlertBannerReceiver, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};
