import { License, LicenseType } from "../types/Customer";
import { DAYS_BEFORE_LICENSE_EXPIRED } from "../shared/consts";
import { useApiMe } from "../hooks/queries/meContext";

export interface ExpirationDetails {
  isExpired: boolean;
  isExpiringSoon: boolean;
  daysDiff: number;
}

export const getExpirationDetails = (license: License): ExpirationDetails => {
  const expirationDate = new Date(license.expiration_date);
  const currentDate = new Date();

  if (currentDate > expirationDate) {
    return { isExpired: true, isExpiringSoon: false, daysDiff: -1 };
  }
  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
  const differenceInTime = Math.abs(
    expirationDate.getTime() - currentDate.getTime()
  );
  const differenceInDays = Math.ceil(differenceInTime / MILLISECONDS_PER_DAY);

  if (
    expirationDate > currentDate &&
    differenceInDays <= DAYS_BEFORE_LICENSE_EXPIRED
  ) {
    return {
      isExpired: false,
      isExpiringSoon: true,
      daysDiff: differenceInDays,
    };
  }

  return { isExpired: false, isExpiringSoon: false, daysDiff: -1 };
};

export const useLicense = (): {
  licenseType: LicenseType;
  license: License | null;
  expirationDetails: ExpirationDetails;
} => {
  const { data: me } = useApiMe();
  const license: License | null = me?.customer?.license || null;
  const licenseType = me?.customer?.license?.type || "";
  const expirationDetails: ExpirationDetails = license
    ? getExpirationDetails(license)
    : { isExpired: false, isExpiringSoon: false, daysDiff: -1 };

  return { license, expirationDetails, licenseType };
};
