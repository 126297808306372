import React from "react";
import { IconProps } from "./WaspIcon";

export function CancelIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_229_1510"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_229_1510)">
        <path
          d="M12 12.8268L8.00394 16.8228C7.89895 16.9278 7.76457 16.9835 7.60079 16.9898C7.43648 16.9966 7.29528 16.9409 7.17717 16.8228C7.05906 16.7047 7 16.5669 7 16.4094C7 16.252 7.05906 16.1142 7.17717 15.9961L11.1732 12L7.17717 8.00394C7.07218 7.89895 7.01654 7.7643 7.01024 7.6C7.00341 7.43622 7.05906 7.29528 7.17717 7.17717C7.29528 7.05906 7.43307 7 7.59055 7C7.74803 7 7.88583 7.05906 8.00394 7.17717L12 11.1732L15.9961 7.17717C16.1011 7.07218 16.2357 7.01627 16.4 7.00945C16.5638 7.00315 16.7047 7.05906 16.8228 7.17717C16.9409 7.29528 17 7.43307 17 7.59055C17 7.74803 16.9409 7.88583 16.8228 8.00394L12.8268 12L16.8228 15.9961C16.9278 16.1011 16.9835 16.2354 16.9898 16.3992C16.9966 16.5635 16.9409 16.7047 16.8228 16.8228C16.7047 16.9409 16.5669 17 16.4094 17C16.252 17 16.1142 16.9409 15.9961 16.8228L12 12.8268Z"
          fill={props.color || "currentColor"}
        />
      </g>
    </svg>
  );
}
