import { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../elements/typography/Typography";
import { Illustration } from "../../elements/illustrations/Illustration";

type Props = {
  title: string;
  text: string;
};

export const NotFoundBanner = (props: Props) => {
  const { title, text } = props;
  const theme = useContext(ThemeContext);
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center gap-16 text-center"
      style={{
        maxWidth: "403px",
      }}
    >
      <Illustration name="empty" />
      <HeaderSecondary style={{ color: theme.black800 }}>
        {title}
      </HeaderSecondary>
      <BodyRegular style={{ color: theme.black600 }}>{text}</BodyRegular>
    </div>
  );
};
