import { LabelRegular } from "../../../components/elements/typography/Typography";
import { PrimaryBadge } from "../../../components/elements/badge/PrimaryBadge";
import { Finding } from "../../../types/Finding";
import { light } from "../../../shared/theme";

type Props = {
  finding: Finding;
};

export const AffectedAssetsDisplayedList = ({ finding }: Props) => {
  return (
    <div className="d-flex flex-column gap-8" data-testid="affected-assets">
      <LabelRegular color={light.textBody}>Affected Assets</LabelRegular>
      <div className="d-flex flex-wrap gap-8 ">
        {finding?.affected_assets_displayed?.map((affectedAsset, index) => (
          <PrimaryBadge
            className="badge-ward-wrap"
            key={`displayed-asset-${index}`}
            content={affectedAsset}
          />
        ))}
      </div>
    </div>
  );
};
