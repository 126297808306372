import { useContext } from "react";
import { NotificationReceiver } from "../../../types/Notifications";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { FindingField, StatusMapping } from "../../../shared/consts";
import { FindingStatus } from "../../findings/findingStatus/FindingStatus";

type Props = {
  notificationReceiver: NotificationReceiver;
};
export const NotificationItemDetails = (props: Props) => {
  const { notificationReceiver } = props;
  const theme = useContext(ThemeContext);

  const GetDetails = (baseText: string) => {
    const actorName = notificationReceiver?.notification_event.event_details[
      "actor"
    ]
      ? notificationReceiver.notification_event.event_details["actor"]["name"]
      : "";

    return (
      <Flex align="center" gap="4px" w100 flexWrap>
        {actorName && <LabelBold color={theme.blue700}>@{actorName}</LabelBold>}
        <LabelRegular color={theme.black600}>{baseText}</LabelRegular>
      </Flex>
    );
  };

  const getNewValue = (field: string, newValue: string) => {
    switch (field) {
      case "Status":
        return StatusMapping[newValue as keyof typeof FindingStatus];
      case "Assignee":
        return newValue;
    }
  };

  const eventHandlers = {
    FINDING_CREATED: () => GetDetails("added a new finding."),
    NEW_OCCURRENCE: () => GetDetails("has been detected on another asset."),
    FINDING_DELETED: () => {
      const id =
        notificationReceiver.notification_event.event_details["finding"]["id"];
      return GetDetails(`deleted the finding. (ID: ${id})`);
    },
    FINDING_UPDATED: () => {
      const fieldKey =
        notificationReceiver.notification_event.event_details["field"];
      const field = FindingField[fieldKey as keyof typeof FindingField];
      const newValue = getNewValue(
        field,
        notificationReceiver.notification_event.event_details["new_value"]
      );
      if (field === undefined || newValue === undefined) {
        return GetDetails(`made an update to the finding.`);
      }
      return GetDetails(`updated field ${field} to ${newValue}.`);
    },
    COMMENT_CREATED: () => GetDetails("added a comment."),
    COMMENT_MENTION: () => GetDetails("mentioned you on a comment."),
    SENT_TO_JIRA: () => GetDetails("synced finding with Jira."),
    BREACHED_SLA: () => (
      <LabelRegular color={theme.black600}>Finding breached SLA!</LabelRegular>
    ),
  };

  const eventType = notificationReceiver.notification_event.event_type;
  const handler = eventType ? eventHandlers[eventType] : null;

  return handler ? handler() : null;
};
