import styled, { keyframes } from "styled-components";

type Props = {
  width: string;
  height: string;
};

const skeletonLoadingAnimation = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
`;

export const LoadingLine = styled.div<Props>`
  background-color: ${(props) => props.theme.blue100};
  border-radius: 8px;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.primary};
    position: absolute;
    animation: ${skeletonLoadingAnimation} 1.5s ease-in-out infinite;
  }
`;
