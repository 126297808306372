import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { filterConditions, FilterType } from "../FiltersUtils";

type Props = {
  columnType: FilterType;
  onChange: (condition: string) => void;
  condition?: string;
};

export const FilterCondition = (props: Props) => {
  const { columnType, onChange, condition } = props;

  const options = filterConditions.filter((condition) =>
    condition.type.includes(`${columnType}`)
  );

  if (!condition) onChange(options[0].value.toString());

  return (
    <Dropdown
      variant="border"
      size="medium"
      width="100%"
      value={options.find((c) => c.value === condition) || options[0]}
      onChange={(option) => onChange(option?.value.toString() || "")}
      placeholder="Condition"
      dataTestId="filter-condition-dropdown"
      options={options}
    />
  );
};
