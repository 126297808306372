import { Modal } from "../../../components/elements/modal/Modal";
import { Flex } from "../../../components/layouts/flex/Flex";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Illustration } from "../../../components/elements/illustrations/Illustration";

type Props = {
  close: () => void;
  assetsCount: number;
};

export const ShareModal = (props: Props) => {
  const { close, assetsCount } = props;
  return (
    <Modal width="414px" onClose={close}>
      <Illustration name="success" />
      <Flex column gap="12px" className="text-center">
        <HeaderSecondary>
          Congratulations on scheduling a test for {assetsCount} assets!
        </HeaderSecondary>
      </Flex>
    </Modal>
  );
};
