import { Flex } from "../../../components/layouts/flex/Flex";
import {
  HeaderSecondary,
  HeaderSub,
} from "../../../components/elements/typography/Typography";
import { Meter } from "../../../components/elements/meter/Meter";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";

type Props = {
  label: string;
  value?: number;
  optimum?: number;
  isDays?: boolean;
  isPercent?: boolean;
  isPositiveTrend?: boolean; // fix the trend to reflect high score is good
};

export const BenchmarkMeter = (props: Props) => {
  const { label, value, optimum, isDays, isPercent, isPositiveTrend } = props;

  const theme = useContext(ThemeContext);

  const [val, setVal] = useState<number>(0);
  const [opt, setOpt] = useState<number>(0);
  const [max, setMax] = useState<number>(0);

  useEffect(() => {
    if (
      // Allow value to be 0
      optimum === null ||
      optimum === undefined ||
      value === null ||
      value === undefined
    )
      return;
    setVal(value);
    setOpt(optimum);
    // if percents - max is 100% else take higher value
    setMax(isPercent ? 100 : optimum > value ? optimum + 1 : value);
  }, [optimum, value, isPercent]);

  return (
    <Flex align="center">
      <Flex column style={{ width: "280px" }}>
        <HeaderSub color={theme.textSub}>{label}</HeaderSub>
        <HeaderSecondary>
          {value}
          {isDays && " Days"}
          {isPercent && "%"}
        </HeaderSecondary>
      </Flex>
      <Meter
        value={val}
        optimum={opt}
        max={max}
        isDays={isDays}
        isPercent={isPercent}
        isPositiveTrend={isPositiveTrend}
      />
    </Flex>
  );
};
