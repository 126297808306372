import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import {
  useApiCreateAssetsView,
  useApiUpdateAssetsView,
} from "../../../hooks/queries/assetsViewsContext";
import useToastContext from "../../../hooks/toastHook";
import { useMutationWithDelay } from "../../../hooks/utilsHooks";
import { AssetsView, AssetsViewProps } from "../../../types/AssetsView";
import { CreateEditFilterPane } from "./CreateEditFilterPane";
import {
  isValidView,
  mutateAssetsViewWrapper,
  setFiltersOrder,
} from "./FiltersUtils";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Mixpanel } from "../../../shared/mixpanel";

type Props = {
  assetsView: AssetsView;
  onClose: () => void;
};

export const EditFilterPane = (props: Props) => {
  const { assetsView, onClose } = props;
  const theme = useContext(ThemeContext);
  const [updatedAssetsView, setUpdatedAssetsView] = useState<AssetsViewProps>({
    name: assetsView.name,
    filters: assetsView.filters,
  });
  const [saveAsNewFilter, setSaveAsNewFilter] = useState<boolean>(false);

  const { mutate: updateAssetsView, isLoading: isUpdating } =
    useApiUpdateAssetsView();
  const { mutate: createAssetsView, isLoading: isCreating } =
    useApiCreateAssetsView();

  const { runMutation, isRunning } = useMutationWithDelay(3000);

  const addToast = useToastContext();

  const hasNoChanges = (updatedAssetsView: AssetsViewProps): boolean => {
    return (
      updatedAssetsView.name === assetsView.name &&
      updatedAssetsView.filters === assetsView.filters
    );
  };

  const handleSaveFilter = () => {
    Mixpanel.track("Assets View: Save Filter", updatedAssetsView);
    if (saveAsNewFilter) {
      mutateAssetsViewWrapper(
        runMutation,
        onClose,
        addToast,
        createAssetsView,
        {
          assetsViewData: {
            ...updatedAssetsView,
            filters: setFiltersOrder(updatedAssetsView.filters),
          },
        },
        `Adding filter "${updatedAssetsView.name}"`,
        `Filter "${updatedAssetsView.name}" added successfully `,
        `Failed to add filter "${updatedAssetsView.name}"`
      );
    } else {
      mutateAssetsViewWrapper(
        runMutation,
        onClose,
        addToast,
        updateAssetsView,
        {
          assetsViewId: assetsView?.id || -1,
          assetsViewData: {
            ...updatedAssetsView,
            filters: setFiltersOrder(updatedAssetsView.filters),
          },
        },
        `Updating filter "${updatedAssetsView?.name}"`,
        `Filter "${updatedAssetsView?.name}" updated successfully `,
        `Failed to update filter "${updatedAssetsView?.name}"`
      );
    }
  };

  return (
    <RightPane onClose={onClose}>
      <Flex column>
        <Flex column gap="24px" style={{ padding: "24px" }}>
          <Flex align="center" justify="between" gap="16px">
            <Flex align="center" gap="16px">
              <Icon name="edit" color={theme.primary} size={24} />
              <HeaderSecondary>Edit Filters</HeaderSecondary>
            </Flex>
            <Flex align="center" gap="16px">
              <TextButton
                label="Cancel"
                onClick={onClose}
                dataTestId="cancel-edit-filter-button"
              />
              <MainButton
                label="Save"
                size="small"
                dataTestId="save-edit-filter-button"
                onClick={handleSaveFilter}
                inProgress={isRunning || isCreating || isUpdating}
                disabled={
                  !isValidView(updatedAssetsView) ||
                  hasNoChanges(updatedAssetsView) ||
                  isRunning ||
                  isCreating ||
                  isUpdating
                }
              />
            </Flex>
          </Flex>
          <CreateEditFilterPane
            defaultAssetsView={assetsView}
            setAssetsView={setUpdatedAssetsView}
            isEdit
            saveAsNewFilter={saveAsNewFilter}
            setSaveAsNewFilter={setSaveAsNewFilter}
          />
        </Flex>
      </Flex>
    </RightPane>
  );
};
