import { useContext, useMemo } from "react";
import { RightPane } from "../../../../components/elements/rightPane/RightPane";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
} from "../../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { RiskScoreDoughnutChart } from "../riskScore/RiskScoreDoughnutChart";
import { useApiRiskScoreHistory } from "../../../../hooks/queries/riskScoreHistoryContext";
import { defaultTimeframeOptions } from "../../../findings/findingsCharts/TimeframeDropdown";
import { RiskScoreComponentLegend } from "./RiskScoreComponentLegend";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { RiskScoreTrend } from "./RiskScoreTrend";
import { Badge } from "../../../../components/elements/badge/Badge";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { useApiFindingsPaging } from "../../../../hooks/queries/findingContext";
import { FindingCard } from "../../../findings/findingsCards/FindingCard";
import { useApiProjects } from "../../../../hooks/queries/projectsContext";
import { getProject } from "../../../../shared/findingsHelper";
import { useNavigate } from "react-router";

type Props = {
  onClose: () => void;
};

const RiskScorePaneMobile = ({ onClose }: Props) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const timeframe = defaultTimeframeOptions[4];

  const { data: projects } = useApiProjects({
    with_findings_counts: true,
  });

  const { data: findings } = useApiFindingsPaging({
    ordering: "-overall_risk",
    is_open: true,
  });

  const findingsMapped = useMemo(
    () => findings?.pages.map((page) => page.results || []).flat() || [],
    [findings]
  );

  const { data: riskHistoryItems, isFetching } = useApiRiskScoreHistory({
    page_size: 2000,
    ordering: "-created_at",
  });

  const getNewestHistoryItem = () => {
    return riskHistoryItems?.[0];
  };

  const getRiskScoreItemBeforeNow = () => {
    const beforeMe = new Date();
    beforeMe.setTime(Date.now() - timeframe.value);
    return riskHistoryItems?.find(
      (item) => new Date(item.created_at) < beforeMe
    );
  };

  const latestScore = getNewestHistoryItem();
  const oldestScore = getRiskScoreItemBeforeNow();
  const scoreDelta =
    (latestScore?.risk_score || 0) - (oldestScore?.risk_score || 0);
  const slaDelta =
    (latestScore?.breached_sla_weight || 0) -
    (oldestScore?.breached_sla_weight || 0);
  const findingsDelta =
    (latestScore?.amount_findings_weight || 0) -
    (oldestScore?.amount_findings_weight || 0);
  const criticalDelta =
    (latestScore?.criticals_weight || 0) - (oldestScore?.criticals_weight || 0);

  return (
    <RightPane width={"100vw"} onClose={onClose}>
      <Flex
        column
        gap="24px"
        padding="16px"
        style={{ position: "relative", height: "100vh", overflowY: "scroll" }}
      >
        <IconButton
          iconName="cancel"
          onClick={onClose}
          style={{ position: "absolute", left: "12px", top: "12px" }}
        />

        <Flex align="center" justify="between">
          <Flex align="center" gap="8px">
            <Icon name="securityStatus" size={32} color={theme.redPrimary} />
            <HeaderSecondary>Risk Score</HeaderSecondary>
          </Flex>
          <RiskScoreDoughnutChart
            latestRiskHistory={latestScore}
            isLoadingData={isFetching && !scoreDelta}
            size={120}
            labelNumberSize={32}
            labelTextSize={10}
            top={"30px"}
          />
        </Flex>

        <Flex column w100 gap="24px">
          <Flex column justify="between" w100 gap="16px">
            <RiskScoreComponentLegend
              componentWeight={latestScore?.criticals_weight || 0}
              scoreDelta={criticalDelta}
              componentType="critical_findings"
            />
            <RiskScoreComponentLegend
              componentWeight={latestScore?.breached_sla_weight || 0}
              scoreDelta={slaDelta}
              componentType="breached_sla"
            />
            <RiskScoreComponentLegend
              componentWeight={latestScore?.amount_findings_weight || 0}
              scoreDelta={findingsDelta}
              componentType="amount_findings"
            />
          </Flex>
          {isFetching && !scoreDelta ? (
            <SkeletonLoading height="26px" width="100%" />
          ) : (
            <Badge
              className="d-flex align-items-center justify-content-between w-100"
              backgroundColor={theme.black300}
              data-testid={scoreDelta > 0 ? "trend-down" : "trend-up"}
              style={{ padding: "8px 16px", height: "auto", width: "100%" }}
            >
              <BodyRegular
                className="text-truncate"
                title={timeframe.label}
                color={theme.black800}
              >
                {timeframe.label}
              </BodyRegular>
              <RiskScoreTrend scoreDelta={scoreDelta} />
            </Badge>
          )}
        </Flex>

        <SeparatorHorizontal />
        <HeaderSubBold>Top Risky Issues</HeaderSubBold>
        <Flex column gap="16px">
          {findingsMapped?.map((finding) => (
            <FindingCard
              key={finding.id}
              project={getProject(finding, projects)}
              finding={finding}
              hasUnreadComments={false}
              onOpenProjectPane={() => {}}
              onSelectedFinding={() =>
                navigate(`/finding-details/${finding.id}`)
              }
            />
          ))}
        </Flex>
      </Flex>
    </RightPane>
  );
};

export default RiskScorePaneMobile;
