import styled, { css } from "styled-components";

type TypographyProps = {
  color?: string;
};

export const Common = css`
  font-family: "Poppins";
`;

export const TypographyKPIHuge = css`
  ${Common}
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
`;

export const KPIHuge = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textHeader};
  ${TypographyKPIHuge}
`;

export const TypographyKPILarge = css`
  ${Common}
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
`;

export const KPILarge = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textHeader};
  ${TypographyKPILarge}
`;

export const TypographyKPISmall = css`
  ${Common}
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`;

export const KPISmall = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textHeader};
  ${TypographyKPISmall}
`;

export const TypographyHeaderMain = css`
  ${Common}
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: ${(props) => props.theme.textHeader};
`;

export const HeaderMain = styled.div`
  ${TypographyHeaderMain}
`;

export const TypographyHeaderSecondary = css`
  ${Common}
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

export const HeaderSecondary = styled.div<TypographyProps>`
  color: ${(props) => props.color || props.theme.textHeader};
  ${TypographyHeaderSecondary}
`;

export const TypographyHeaderSecondaryRegular = css`
  ${TypographyHeaderSecondary}
  font-weight: 400;
`;

export const HeaderSecondaryRegular = styled.div`
  ${TypographyHeaderSecondaryRegular}
`;

export const TypographyHeaderSub = css`
  ${Common}
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const HeaderSubBold = styled.div<TypographyProps>`
  color: ${(props) => props.color || props.theme.textHeader};
  ${TypographyHeaderSub}
`;

export const HeaderSub = styled.div<TypographyProps>`
  color: ${(props) => props.color || props.theme.textHeader};
  font-weight: 400;
  ${TypographyHeaderSub}
`;

export const HeaderSubHover = styled.div`
  ${TypographyHeaderSub}
  color: ${(props) => props.color || props.theme.textHeader};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;

export const TypographyBodyBold = css`
  ${Common}
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
`;

export const BodyBold = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textBody};
  ${TypographyBodyBold}
`;

export const BodyBoldHover = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textBody};
  ${TypographyBodyBold}
  cursor: pointer;
  transition: 0.2s;
  color: "inherit";
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;
export const TypographyBodySemibold = css`
  ${TypographyBodyBold}
  font-weight: 600;
`;

export const BodySemiBold = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textBody};
  ${TypographyBodySemibold}
`;

export const TypographyBodyMedium = css`
  ${TypographyBodyBold}
  font-weight: 500;
`;

export const BodyMedium = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textBody};
  ${TypographyBodyMedium}
`;

export const BodyMediumHover = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textBody};
  ${TypographyBodyMedium}
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    color: ${(props) => props.theme.primary} !important;
  }
`;

export const TypographyBodyRegular = css`
  ${TypographyBodyBold}
  font-weight: 400;
`;

export const BodyRegular = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textBody};
  ${TypographyBodyRegular}
`;
export const BodyRegularHover = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textBody};
  ${TypographyBodyRegular}
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;

export const TypographyLabelBold = css`
  ${Common}
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
`;

export const LabelBold = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textSecondary};
  ${TypographyLabelBold}
`;

export const TypographyLabelMedium = css`
  ${TypographyLabelBold}
  font-weight: 500;
`;

export const LabelMedium = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textSecondary};
  ${TypographyLabelMedium}
`;

export const TypographyLabelRegular = css`
  ${TypographyLabelBold}
  font-weight: 400;
`;

export const LabelRegular = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textSecondary};
  ${TypographyLabelRegular}
`;

export const LabelRegularHover = styled(LabelRegular)`
  cursor: pointer;
  transition: 0.2s;
  color: "inherit";
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;

export const TypographyLabelMini = css`
  ${Common}
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
`;

export const LabelMini = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.textSecondary};
  ${TypographyLabelMini}
`;
