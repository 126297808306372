import { useContext } from "react";
import { Box } from "../../components/elements/box/Box";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  BodyRegular,
  LabelRegular,
} from "../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { DAY_MS } from "../../shared/consts";

type Props = {
  date: Date;
};

export const ASMUpdatesNoFindingsTimelineItem = ({ date }: Props) => {
  const theme = useContext(ThemeContext);
  const numOfDays = Math.round(
    (new Date().getTime() - date.getTime()) / DAY_MS
  );
  return (
    <Flex column gap="8px">
      <LabelRegular color={theme.textSub}>
        {numOfDays === 1
          ? "In the last 24 hours"
          : `In the last ${numOfDays} days`}
      </LabelRegular>
      <Box
        style={{
          backgroundColor: theme.black300,
          padding: "16px",
        }}
      >
        <BodyRegular
          color={theme.textSub}
        >{`No findings were detected`}</BodyRegular>
      </Box>
    </Flex>
  );
};
