import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { BodyBold } from "../../../../components/elements/typography/Typography";
import { SEVERITIES } from "../../../../shared/consts";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { getRiskColor } from "../../../../shared/findingsHelper";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
  isReport?: boolean;
};

export const RiskItem = (props: Props) => {
  const { finding, noSeparator = false, width, isReport = false } = props;
  const theme = useContext(ThemeContext);

  return (
    <Section
      title="Risk"
      noSeparator={noSeparator}
      width={width}
      isReport={isReport}
    >
      <BodyBold
        className="text-truncate w-100"
        color={theme[getRiskColor(finding?.overall_risk || 0)]}
      >
        {SEVERITIES[finding?.overall_risk || 0]}
      </BodyBold>
    </Section>
  );
};
