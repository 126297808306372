import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { IconButton } from "../button/icon/IconButton";
import { TextButton } from "../button/text/TextButton";
import { Icon } from "../icon/Icon";
import { BodyRegular } from "../typography/Typography";
import { ToastProps } from "./ToastContextProvider";
import { useToastStyles } from "./toastStyles";
import { Flex } from "../../layouts/flex/Flex";

export const Toast = (props: ToastProps) => {
  const { type, message, onUndo, onDismiss, withUndo = false } = props;
  const theme = useContext(ThemeContext);
  const {
    borderColors,
    backgroundColors,
    iconColors,
    statusToIcon,
    buttonColors,
  } = useToastStyles();

  const backgroundColor = backgroundColors[type];
  const iconName = statusToIcon[type];
  const buttonColor = buttonColors[type];
  const iconColor = iconColors[type];
  const borderColor = borderColors[type];

  return (
    <Flex
      padding="8px"
      justify="between"
      align="center"
      gap="16px"
      style={{
        margin: "2px",
        backgroundColor: backgroundColor,
        borderRadius: "8px",
        border: "1px solid " + borderColor + "",
        boxShadow: "0px 8px 40px rgba(123, 123, 123, 0.1)",
        // zIndex is set on ToastContextProvider.tsx
      }}
    >
      <div className="d-flex align-items-center gap-8">
        <Icon color={iconColor} name={iconName} size={32} />
        <BodyRegular style={{ color: theme.black800 }}>{message}</BodyRegular>
      </div>
      <div className="d-flex align-items-center gap-16">
        {withUndo && (
          <div
            style={{
              borderRadius: "32px",
              border: "1px solid " + borderColor + "",
            }}
          >
            <TextButton
              label="Undo"
              onClick={() => {
                onUndo && onUndo();
              }}
              color={buttonColor}
            />
          </div>
        )}
        {!!onDismiss && (
          <IconButton
            iconName="cancel"
            color={buttonColor}
            onClick={() => {
              onDismiss && onDismiss();
            }}
          />
        )}
      </div>
    </Flex>
  );
};
