import { useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SearchBar } from "../../../components/composed/searchBar/SearchBar";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { useApiDeleteProduct } from "../../../hooks/queries/productsContext";
import { Product } from "../../../types/Product";
import AddEditProductForm from "./AddEditProductForm";
import { ProductsTable } from "../../dashboard/bottomBlock/productsTable/ProductsTable";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Mixpanel } from "../../../shared/mixpanel";

function Products() {
  const { data: me } = useApiMe();
  useTrackPage("Settings - Products", undefined, me);
  const {
    mutate: deleteProduct,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    reset: resetDelete,
  } = useApiDeleteProduct();

  const [searchWord, setSearchWord] = useState<string>("");
  const [editedProduct, setEditedProduct] = useState<Product | undefined>();
  const [deletedProduct, setDeletedProduct] = useState<Product | undefined>();
  const [openAddPane, setOpenAddPane] = useState<boolean>(false);

  return (
    <Flex justify="between" gap="16px" style={{ height: "calc(100vh - 90px)" }}>
      <Flex column w100 gap="24px">
        <Flex justify="between">
          <Flex align="center" style={{ height: "50px" }}>
            <HeaderSecondary>Products</HeaderSecondary>
          </Flex>
          <Flex align="center" gap="16px">
            <SearchBar setSearchWord={setSearchWord} />
            <SeparatorVertical height="16px" />
            <MainButton
              label="Add Product"
              iconName="add"
              size="small"
              onClick={() => {
                Mixpanel.track("Add Product");
                setEditedProduct(undefined);
                setOpenAddPane(true);
              }}
            />
          </Flex>
        </Flex>
        <Box>
          <ProductsTable
            searchWord={searchWord}
            isProductsEditable={true}
            setEditedProduct={setEditedProduct}
          />
        </Box>
        {(!!editedProduct || openAddPane) && (
          <AddEditProductForm
            editedProduct={editedProduct}
            setDeletedProduct={setDeletedProduct}
            onClose={() => {
              setEditedProduct(undefined);
              setOpenAddPane(false);
            }}
          />
        )}
      </Flex>
      {deletedProduct && (
        <DeleteModal
          itemType="Product"
          itemName={deletedProduct.name}
          onConfirm={() => {
            Mixpanel.track("Delete product");
            deleteProduct(deletedProduct);
          }}
          onClose={() => {
            isDeleted && setEditedProduct(undefined);
            setDeletedProduct(undefined);
            setOpenAddPane(false);
            resetDelete();
          }}
          isLoading={isDeleting}
          isSuccess={isDeleted}
        />
      )}
    </Flex>
  );
}

export default Products;
