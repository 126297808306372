import React from "react";
import { Box } from "../../../components/elements/box/Box";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { SectionSkeleton } from "../../../components/elements/section/SectionSkeleton";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";

export const MiddleBoxSkeleton = () => {
  return (
    <Box className="d-flex flex-column gap-24">
      <div className="d-flex align-items-center justify-content-around">
        <SectionSkeleton />
        <SectionSkeleton />
        <SectionSkeleton />
        <SectionSkeleton noSeparator />
      </div>
      <div className="d-flex align-items-center justify-content-around">
        <SectionSkeleton />
        <SectionSkeleton />
        <SectionSkeleton />
        <SectionSkeleton noSeparator />
      </div>
      <SeparatorHorizontal />
      <SkeletonLoading width="100px" height="20px" />
      <SkeletonLoading width="600px" height="40px" />
      <SkeletonLoading width="100px" height="20px" />
      <SkeletonLoading width="600px" height="40px" />
    </Box>
  );
};
