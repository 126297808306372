import { ReactourStep } from "reactour";
import {
  BodyRegular,
  HeaderSecondary,
} from "../components/elements/typography/Typography";
import { TextButton } from "../components/elements/button/text/TextButton";
import { Link } from "react-router-dom";

export const assetsSteps = (theme: any): ReactourStep[] => [
  {
    selector: '[data-tut="assets-overview"]',
    content: () => (
      <>
        <HeaderSecondary>Overview</HeaderSecondary>
        <BodyRegular>
          Displays the total number of identified assets and their discovery
          trends over time
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="risky-assets"]',
    content: () => (
      <>
        <HeaderSecondary>Risky Assets</HeaderSecondary>
        <BodyRegular>
          Highlights assets based on the severity of the most critical issues
          associated with them.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="assets-dist"]',
    content: () => (
      <>
        <HeaderSecondary>Top 5 Distribution</HeaderSecondary>
        <BodyRegular>
          Showcases the distribution of various technologies, status codes, web
          servers, and Content Delivery Network (CDN) names.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="assets-table"]',
    content: () => (
      <>
        <HeaderSecondary>Assets Table</HeaderSecondary>
        <BodyRegular>
          Provides a comprehensive list of the entire asset inventory, offering
          a high-level view of their attributes.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="assets-sliders"]',
    content: () => (
      <>
        <HeaderSecondary>View Asset Details</HeaderSecondary>
        <BodyRegular>
          Clicking the arrow will open a dedicated sliding window, revealing
          detailed asset attributes and website specifics
        </BodyRegular>
        <Link to="/projects?tour=on" style={{ textDecoration: "none" }}>
          <TextButton label="Go to Next Tour - Projects Page" />
        </Link>
      </>
    ),
  },
];
