import { Assignee } from "../../../../components/composed/assignee/Assignee";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { Flex } from "../../../../components/layouts/flex/Flex";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  isEditable?: boolean;
};

export const AssignedToItem = (props: Props) => {
  const { finding, noSeparator, isEditable } = props;
  return (
    <Section title="Assigned To" noSeparator={noSeparator}>
      <Flex
        className="text-truncate w-100"
        style={{
          position: "relative",
          height: "100%",
          overflow: "visible",
        }}
      >
        <Assignee
          name={finding?.customer_jira_assignee?.display_name}
          imageSrc={finding?.customer_jira_assignee?.avatar_url}
          findingId={finding?.id}
          isEditable={isEditable}
        />
      </Flex>
    </Section>
  );
};
