import React, { useContext } from "react";
import { Flex } from "../../layouts/flex/Flex";
import { Icon } from "../../elements/icon/Icon";
import { FindingStatus } from "../../../types/Finding";
import { ThemeContext } from "styled-components";
import { LabelRegular } from "../../elements/typography/Typography";
import { getStatusName } from "../../../shared/findingsHelper";

type Props = {
  status: FindingStatus;
  isSmall?: boolean;
};

export function StatusLegend({ status, isSmall }: Props) {
  const theme = useContext(ThemeContext);
  return (
    <Flex align="center" gap="1px">
      <Icon
        name={status}
        color={status === "fixed" ? theme.greenPrimary : theme.primary}
        size={24}
      />
      {!isSmall && <LabelRegular>{getStatusName(status)}</LabelRegular>}
    </Flex>
  );
}
