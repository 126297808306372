import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { BodyBold } from "../typography/Typography";
import "./CircularProgressBar.css";

type Props = {
  value: number;
  size?: number;
  background?: string;
  color?: string;
  showLabel?: boolean;
  labelFontSize?: number;
};

const radius = 45;
const circumference = 2 * Math.PI * radius;

export function CircularProgressBar(props: Props) {
  const theme = useContext(ThemeContext);
  const {
    value,
    background = theme.black500,
    color = theme.primary,
    showLabel = true,
    size = 24,
    labelFontSize = 14,
  } = props;

  const singlePercent = circumference / 100;
  const strokeDasharray = `${singlePercent * value}, ${circumference}`;

  return (
    <div className="d-flex align-items-center gap-8">
      {showLabel && (
        <BodyBold
          className="d-flex"
          style={{
            color: color,
            fontSize: `${labelFontSize}px`,
          }}
        >{`${value}%`}</BodyBold>
      )}
      <div
        className="progress-circle"
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <svg viewBox="0 0 120 120">
          <circle cx="60" cy="60" r={radius} stroke={background}></circle>
          <circle
            stroke={color}
            cx="60"
            cy="60"
            r={radius}
            strokeDasharray={strokeDasharray}
          ></circle>
        </svg>
      </div>
    </div>
  );
}
