import React, { useContext, useState } from "react";
import { Flex } from "../../layouts/flex/Flex";
import { LabelMini, LabelRegular } from "../../elements/typography/Typography";
import { ThemeContext } from "styled-components";
import {
  InputText,
  TextInputProps,
} from "../../elements/input/textInput/InputText";

type Props = TextInputProps & {
  label: string;
  optional?: boolean;
  validationFunction?: (value: string) => boolean;
  onValidated?: (isValid: boolean) => void;
  errorMessage?: string;
};

export const InputTextWithLabels = (props: Props) => {
  const {
    label,
    optional,
    errorMessage = "Invalid Input",
    onValidated,
    validationFunction,
    onChange,
    type = "text",
  } = props;
  const theme = useContext(ThemeContext);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState(errorMessage);

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var isValidateOk = true;
    if (
      type === "email" &&
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value)
    ) {
      isValidateOk = false;
      setErrorText("Invalid email address");
    } else if (!optional && !e.target.value) {
      isValidateOk = false;
      setErrorText("Required field cannot be empty");
    }
    if (validationFunction && !validationFunction(e.target.value)) {
      isValidateOk = false;
    }
    setIsError(!isValidateOk);
    onValidated && onValidated(isValidateOk);
    onChange && onChange(e);
  };

  return (
    <Flex
      column
      gap="4px"
      style={{
        width: "fit-content",
      }}
    >
      <Flex justify="between" align="center" gap="8px">
        <LabelRegular>{label}</LabelRegular>
        {optional && (
          <LabelRegular
            style={{
              color: theme.primary,
            }}
          >
            Optional
          </LabelRegular>
        )}
      </Flex>
      <InputText {...props} onChange={_onChange} isError={isError} />
      {isError && <LabelMini color={theme.redPrimary}>{errorText}</LabelMini>}
    </Flex>
  );
};
