import { Option } from "../components/elements/dropdowns/Dropdown";
import { FindingStatus } from "../types/Finding";

export const presentationModeKey = "presentationMode";

export const StatusMapping: Record<FindingStatus, string> = {
  presented: "Presented", // open
  in_progress: "In Progress", // open
  re_test: "Ready for Re-Test", // open
  fixed: "Fixed", // closed
  dismissed: "Dismissed", // closed
  acceptable_risk: "Acceptable Risk", // closed
};

export const SEVERITIES: { [key: string]: string } = {
  4: "Critical",
  3: "High",
  2: "Medium",
  1: "Low",
  0: "Info",
};

export enum FindingField {
  customer_jira_assignee = "Assignee",
  status = "Status",
}

export enum Insights {
  LowHangingFruits = "Low Hanging Fruits",
  QuickWin = "Quick Win",
  MoneyPits = "Money Pits",
  MustHave = "Must Have",
}

// This type defines the mapping of insights to their corresponding impact and mitigation complexities.
type InsightMapping = {
  [key in Insights]: {
    impacts: number[];
    mitigationComplexities: number[];
  };
};

// This object provides a mapping of each insight to its impact and mitigation complexities.
// it is used by the `fromInsight` and `toInsight` functions in `shared/helper.ts`.
export const InsightsMapping: InsightMapping = {
  [Insights.LowHangingFruits]: {
    impacts: [0, 1],
    mitigationComplexities: [0, 1],
  },
  [Insights.QuickWin]: {
    impacts: [2, 3, 4],
    mitigationComplexities: [0, 1],
  },
  [Insights.MoneyPits]: {
    impacts: [0, 1],
    mitigationComplexities: [2, 3, 4],
  },
  [Insights.MustHave]: {
    impacts: [2, 3, 4],
    mitigationComplexities: [2, 3, 4],
  },
};

export const IMPACTS: { [key: number]: string } = {
  3: "Extensive / Widespread",
  2: "Significant / Large",
  1: "Moderate / Limited",
  0: "Minor / Localized",
};

export const IMPACTS_REPORT_FORMAT: { [key: number]: string } = {
  3: "Critical",
  2: "High",
  1: "Medium",
  0: "Low",
};

export const MITIGATION: { [key: number]: string } = {
  4: "Very High",
  3: "High",
  2: "Medium",
  1: "Low",
  0: "None",
};

export const DAY_MS = 86400000;
export const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
export const MONTH_FROM_NOW = new Date(Date.now() - 30 * DAY_MS).toISOString();

export const DEFAULT_SLA_CONFIG = {
  critical: 7,
  high: 30,
  medium: 90,
  low: 180,
  info: 180,
};

export const SCREEN_MOBILE_WIDTH = 770;
export const SCREEN_VERY_SMALL_LAPTOP_WIDTH = 1040;
export const SCREEN_SMALL_LAPTOP_WIDTH = 1240;
export const SCREEN_MEDIUM_LAPTOP_WIDTH = 1440;
export const SCREEN_LAPTOP_WIDTH = 1600;
export const SCREEN_DESKTOP_WIDTH = 1790;
export const SCREEN_BIG_DESKTOP_WIDTH = 1920;

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

export const ROLE_OPTIONS = [
  "CEO",
  "COO",
  "CTO",
  "CISO",
  "R&D manager",
  "Engineering Manager",
  "Product Manager",
  "Engineer",
  "Researcher",
  "QA",
  "Consultant",
  "Other",
].map((role) => ({ label: role, value: role }));

export const environmentOptions: Option[] = [
  { label: "Development", value: "Development" },
  { label: "Testing", value: "Testing" },
  { label: "Staging", value: "Staging" },
  { label: "Production", value: "Production" },
];

export const assetsDistributionOptions = [
  { label: "Environment", value: "environment" },
  { label: "Product", value: "product_id" },
  { label: "Source", value: "source" },
  { label: "Priority", value: "priority" },
  { label: "Security Grade", value: "risk_score" },
  { label: "Asset Type", value: "type" },
  { label: "Technologies", value: "technologies" },
  { label: "Status code", value: "status_code" },
  { label: "Web server", value: "webserver" },
  { label: "Cdns Name", value: "cdn_name" },
];

export const assetsDistributionConditionsMap: { [key: string]: string } = {
  product_id: "is",
  source: "is",
  environment: "is",
  priority: "is",
  risk_score: "is",
  type: "is",
  technologies: "contains",
  status_code: "is",
  webserver: "contains",
  cdn_name: "contains",
};

export const defaultAssetDistribution = assetsDistributionOptions[0];

export const DAYS_BEFORE_LICENSE_EXPIRED = 20;
