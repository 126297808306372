import { ReactourStep } from "reactour";
import {
  BodyRegular,
  HeaderSecondary,
} from "../components/elements/typography/Typography";
import { Link } from "react-router-dom";
import { TextButton } from "../components/elements/button/text/TextButton";

// findings-timeframe

export const FindingsSteps = (theme: any): ReactourStep[] => {
  return [
    {
      selector: '[data-tut="findings-page"]',
      content: () => (
        <>
          <HeaderSecondary>Findings Page</HeaderSecondary>
          <BodyRegular>
            On this page you get a complete view of your security issues found
            by researchers and automated scannings
          </BodyRegular>
        </>
      ),
    },
    {
      selector: '[data-tut="findings-timeframe"]',
      stepInteraction: true,
      content: () => (
        <>
          <HeaderSecondary>Timeframe selector</HeaderSecondary>
          <BodyRegular>
            Here you can quickly filter your security issues by the time they
            discovered.
          </BodyRegular>
        </>
      ),
    },
    {
      selector: '[data-tut="findings-filters"]',
      content: () => (
        <>
          <HeaderSecondary>Findings Filters</HeaderSecondary>
          <BodyRegular>
            With this feature, users can create both simple and intricate data
            filters based on asset attributes or findings characteristics.
          </BodyRegular>
        </>
      ),
    },
    {
      selector: '[data-tut="findings-overview"]',
      content: () => (
        <>
          <HeaderSecondary>Findings Overview</HeaderSecondary>
          <BodyRegular>
            Summarizes open findings and categorizes them by severity.
          </BodyRegular>
        </>
      ),
    },
    {
      selector: '[data-tut="findings-status"]',
      content: () => (
        <>
          <HeaderSecondary>Findings Status</HeaderSecondary>
          <BodyRegular>
            Provides a high-level summary of the status of findings in each
            phase.
          </BodyRegular>
        </>
      ),
    },
    {
      selector: '[data-tut="findings-action"]',
      content: () => (
        <>
          <HeaderSecondary>Action Items</HeaderSecondary>
          <BodyRegular>
            Highlights immediate tasks, SLA breaches, or critical concerns.
          </BodyRegular>
        </>
      ),
    },
    {
      selector: '[data-tut="findings-export"]',
      content: () => (
        <>
          <HeaderSecondary>Export Findings</HeaderSecondary>
          <BodyRegular>
            Allows users to export selected or all findings in CSV format
          </BodyRegular>
        </>
      ),
    },
    {
      selector: '[data-tut="findings-table"]',
      position: "top",
      content: () => (
        <>
          <HeaderSecondary>Findings Table</HeaderSecondary>
          <BodyRegular>
            Displays a comprehensive list of identified issues, which can be
            filtered and edited as needed.
          </BodyRegular>
        </>
      ),
    },
    {
      selector: '[data-tut="findings-sliders"]',
      content: () => (
        <>
          <HeaderSecondary>View Finding</HeaderSecondary>
          <BodyRegular>
            Clicking on items in the table opens a sliding window with detailed
            attributes and information about the web asset
          </BodyRegular>
        </>
      ),
    },
    {
      selector: '[data-tut="findings-page"]',
      content: () => (
        <>
          <HeaderSecondary>Finding Details Page</HeaderSecondary>
          <BodyRegular>
            Delivers a detailed view of findings, complete with additional
            information and asset screenshots.
          </BodyRegular>
          <Link to="/assets?tour=on" style={{ textDecoration: "none" }}>
            <TextButton label="Go to Next Tour - Assets Page" />
          </Link>
        </>
      ),
    },
  ];
};
