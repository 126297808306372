import styled from "styled-components";
import {
  TypographyHeaderMain,
  TypographyHeaderSecondaryRegular,
  TypographyHeaderSub,
} from "../../typography/Typography";
import { StyleLinkButton } from "../link/LinkButton.style";
type ButtonProps = {
  selected: boolean;
  size: "small" | "medium" | "large";
};

const getTypography = (size: "small" | "medium" | "large") => {
  switch (size) {
    case "small":
      return TypographyHeaderSub;
    case "medium":
      return TypographyHeaderSecondaryRegular;
    case "large":
      return TypographyHeaderMain;
  }
};

export const StyleTabButton = styled(StyleLinkButton)<ButtonProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => getTypography(props.size)}
  color: ${(props) =>
    props.selected ? props.theme.textHeader : props.theme.black650};
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  transition: 0.2s;
  &:hover:enabled {
    font-weight: ${(props) => (props.selected ? 600 : 400)};
    color: ${(props) => props.theme.blue};
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${(props) => props.theme.black500};
  }
`;

export const IconWrapper = styled.div<ButtonProps>`
  display: flex;
  color: ${(props) =>
    props.selected ? props.theme.blue : "inherit"} !important;
  &:hover:enabled {
    color: inherit;
  }
`;
