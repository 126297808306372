import { ReactElement, useEffect, useState } from "react";
import { useToastStyles } from "./toastStyles";
import { Icon } from "../icon/Icon";
import { IconButton } from "../button/icon/IconButton";
import { Flex } from "../../layouts/flex/Flex";
import { TextButton } from "../button/text/TextButton";
import { useLocation } from "react-router";

export type AlertBannerProps = {
  type?: "success" | "warning" | "error" | "info";
  message: ReactElement;
  customIcon?: "success" | "warning" | "error" | "info";
  customBackground?: "success" | "warning" | "error" | "info";
  onDismiss?: () => void;
  onButtonClick?: () => void;
  buttonLabel?: string;
  isButtonDisabled?: boolean;
  showInPages?: string[];
  previewMode?: boolean;
  onRender?: () => void;
};

const AlertBanner = (props: AlertBannerProps) => {
  const {
    type,
    message,
    customIcon,
    customBackground,
    onDismiss,
    onButtonClick,
    buttonLabel,
    isButtonDisabled,
    showInPages = [],
    previewMode = false,
    onRender,
  } = props;

  const [isVisible, setIsVisible] = useState(true);
  const {
    borderColors,
    backgroundColors,
    iconColors,
    statusToIcon,
    buttonColors,
  } = useToastStyles();

  const backgroundColor = backgroundColors[type || customBackground || "info"];
  const iconName = statusToIcon[type || customIcon || "info"];
  const buttonColor = buttonColors[type || customBackground || "info"];
  const iconColor = iconColors[type || customBackground || "info"];
  const borderColor = borderColors[type || customBackground || "info"];

  const location = useLocation();
  const shouldIgnore =
    !!location.pathname &&
    !!showInPages.length &&
    !showInPages.includes(location.pathname);

  useEffect(() => {
    if (!shouldIgnore && !!onRender) {
      onRender();
    }
  }, [onRender, shouldIgnore]);

  if (
    !!location.pathname &&
    !!showInPages.length &&
    !showInPages.includes(location.pathname)
  )
    return null;

  return (
    <>
      {isVisible && (
        <Flex
          justify="between"
          align="center"
          padding="8px"
          data-name="alert-banner"
          style={{
            backgroundColor: backgroundColor,
            borderRadius: "8px",
            boxShadow: "0px 8px 40px rgba(123, 123, 123, 0.1)",
            alignSelf: "stretch",
            border: "1px solid " + borderColor + "",
            width: "100%",
          }}
        >
          <Flex align="center" gap="8px">
            <Flex>
              <Icon color={iconColor} name={iconName} size={32} />
            </Flex>
            {message}
          </Flex>
          <Flex align="center" gap="16px">
            {!!onButtonClick && (
              <div
                style={{
                  borderRadius: "2em",
                  border: "1px solid " + borderColor + "",
                }}
              >
                <TextButton
                  label={buttonLabel || ""}
                  onClick={() => onButtonClick && onButtonClick()}
                  color={buttonColor}
                  disabled={isButtonDisabled}
                />
              </div>
            )}
            {!!onDismiss && (
              <IconButton
                iconName="cancel"
                color={buttonColor}
                onClick={() => {
                  setIsVisible(false);
                  if (onDismiss) onDismiss();
                }}
                dataTestId="dismiss-button"
              />
            )}
            {!!previewMode && (
              <IconButton
                iconName="cancel"
                color={buttonColor}
                onClick={() => {}}
                dataTestId="preview-dismiss-button"
              />
            )}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default AlertBanner;
