import { Modal } from "../../../components/elements/modal/Modal";
import { Asset } from "../../../types/Asset";
import { Customer } from "../../../types/Customer";
import { AddAssetForm } from "./AddAssetForm";

type Props = {
  newAssetName?: string;
  customer?: Customer;
  onClose: () => void;
  onApply?: (asset: Asset) => void;
};

export const AddAssetModal = (props: Props) => {
  const { newAssetName, customer, onClose, onApply } = props;

  return (
    <Modal width="574px" header="Add New Asset" onClose={onClose}>
      <AddAssetForm
        newAssetName={newAssetName}
        customer={customer}
        onApply={(asset) => {
          onApply && onApply(asset);
          onClose();
        }}
      />
    </Modal>
  );
};
