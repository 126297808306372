import { useEffect, useState } from "react";
import AlertBanner from "../../components/elements/toastTypes/AlertBanner";
import { BodyRegular } from "../../components/elements/typography/Typography";
import { useApiContactSales } from "../../hooks/queries/contactSalesContext";
import { useLicense } from "../../licensing/LicenseManager";
import useToastContext from "../../hooks/toastHook";
import { IS_DEMO_ENV } from "../../hooks/queries/utils";
import { Flex } from "../../components/layouts/flex/Flex";

type LicenseAlertsProps = {
  onRender?: () => void;
};
export const LicenseAlerts = ({ onRender }: LicenseAlertsProps) => {
  const { expirationDetails, licenseType } = useLicense();
  const addToast = useToastContext();

  // Message Sales Team
  const {
    mutate: sendMessageToSales,
    data: contactSalesResponse,
    error: contactSalesError,
    reset: resetContactSalesStatus,
    status: contactSalesStatus,
  } = useApiContactSales();
  const messageSales = () => sendMessageToSales({}, {});

  const isContactSalesButtonDisabled =
    contactSalesStatus === "loading" ||
    contactSalesStatus === "error" ||
    contactSalesStatus === "success";

  const [toasted, setToasted] = useState(false);

  useEffect(() => {
    if (toasted) return;
    if (contactSalesStatus === "success") {
      addToast({ type: "info", message: contactSalesResponse.message });
      setToasted(true);
    }
    if (contactSalesStatus === "error") {
      addToast({ type: "error", message: contactSalesError.message });
      setToasted(true);
      resetContactSalesStatus();
      setTimeout(() => setToasted(false), 4000);
    }
  }, [
    contactSalesStatus,
    contactSalesResponse,
    contactSalesError,
    addToast,
    toasted,
    resetContactSalesStatus,
  ]);

  return (
    <>
      {/* DEMO LICENSE MESSAGE */}
      {IS_DEMO_ENV && licenseType === "demo" && (
        <AlertBanner
          message={
            <Flex column>
              <BodyRegular>Welcome to the Wasp Demo Environment!</BodyRegular>
              <BodyRegular>
                Please note that all information displayed here is mock data. If
                you're interested in upgrading your license, don't hesitate to
                contact us.
              </BodyRegular>
            </Flex>
          }
          customBackground={"info"}
          customIcon={"info"}
          buttonLabel="Contact Us"
          onButtonClick={messageSales}
          isButtonDisabled={isContactSalesButtonDisabled}
          onRender={onRender}
        />
      )}
      {/* EXPIRED LICENSE */}
      {!IS_DEMO_ENV && expirationDetails.isExpired && (
        <AlertBanner
          message={
            <BodyRegular>
              Your WASP license has expired, and access may be restricted soon.
              Please reach out to the WASP team to renew.
            </BodyRegular>
          }
          customBackground={"error"}
          customIcon={"warning"}
          buttonLabel="Contact Us"
          onButtonClick={messageSales}
          isButtonDisabled={isContactSalesButtonDisabled}
          onRender={onRender}
        />
      )}

      {/* LICENSE ABOUT TO EXPIRE */}
      {!IS_DEMO_ENV && expirationDetails.isExpiringSoon && (
        <AlertBanner
          message={
            <BodyRegular>
              Your WASP license will expire in {expirationDetails.daysDiff} days
              and you will be locked out. Please contact the WASP team to renew
              the license.
            </BodyRegular>
          }
          customBackground={"error"}
          customIcon={"warning"}
          showInPages={["/", "/dashboard"]}
          isButtonDisabled={isContactSalesButtonDisabled}
          buttonLabel="Contact Us"
          onButtonClick={messageSales}
          onRender={onRender}
        />
      )}
    </>
  );
};
