import { useState } from "react";
import { Icon } from "../../../../components/elements/icon/Icon";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import { Modal } from "../../../../components/elements/modal/Modal";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import {
  BodyRegular,
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { MainButton } from "../../../../components/elements/button/main/MainButton";
import { useApiUpdateCustomer } from "../../../../hooks/queries/customersContext";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { OktaDetails } from "../../../../types/Customer";
import useToastContext from "../../../../hooks/toastHook";

const defaultFormValues = {
  org_url: "",
  client_id: "",
  secret: "",
};

type Props = {
  onClose: () => void;
};

export const OktaIntegrationModal = (props: Props) => {
  const { onClose } = props;

  const addToast = useToastContext();

  const { data: me } = useApiMe();
  const { mutate: updateCustomer, isLoading } = useApiUpdateCustomer();

  const initFormValues = me?.customer.okta_info || defaultFormValues;
  const [formValues, setFormValues] = useState<OktaDetails>(initFormValues);

  const handleUpdateCustomer = () =>
    updateCustomer({
      customerData: { okta_info: formValues },
      customerId: me?.customer.id || 0,
      onSuccessCallback: () => {
        addToast({
          message: "Okta details saved successfully",
          type: "success",
        });
        onClose();
      },
      onErrorCallback: (err) =>
        addToast({
          message: `Failed to update Okta details - ${err}`,
          type: "error",
        }),
    });

  return (
    <Modal onClose={onClose} width="470px">
      <Flex column gap="24px" w100>
        <Flex align="center" gap="16px">
          <Icon name="okta" size={32} />
          <HeaderSecondary>Okta Integration</HeaderSecondary>
        </Flex>
        <SeparatorHorizontal />

        <BodyRegular>
          To integrate with Okta, and use it as the Identity Provider, you will
          need to install the{" "}
          <a
            href="https://www.okta.com/integrations/wasp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            WASP Okta application
          </a>{" "}
          from the Okta Marketplace.
        </BodyRegular>

        <Flex align="center" gap="8px" w100>
          <LabelRegular
            style={{
              width: "50px",
            }}
          >
            Org URL
          </LabelRegular>
          <InputText
            value={formValues.org_url}
            onChange={(e) =>
              setFormValues((prev) => ({ ...prev, org_url: e.target.value }))
            }
            width="100%"
          />
        </Flex>

        <Flex align="center" gap="8px" w100>
          <LabelRegular
            style={{
              width: "50px",
            }}
          >
            Client ID
          </LabelRegular>
          <InputText
            value={formValues.client_id}
            width="100%"
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                client_id: e.target.value,
              }))
            }
          />
        </Flex>

        <Flex align="center" gap="8px" w100>
          <LabelRegular
            style={{
              width: "50px",
            }}
          >
            Secret
          </LabelRegular>
          <InputText
            type="password"
            value={formValues.secret}
            width="100%"
            onChange={(e) =>
              setFormValues((prev) => ({ ...prev, secret: e.target.value }))
            }
          />
        </Flex>

        <Flex align="center" justify="end">
          <MainButton
            label="Submit"
            onClick={handleUpdateCustomer}
            inProgress={isLoading}
            disabled={
              !formValues.client_id || !formValues.org_url || !formValues.secret
            }
            size="medium"
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
