import React, { useContext, useState } from "react";
import { Box } from "../../../../components/elements/box/Box";
import { ThemeContext } from "styled-components";
import { Chart } from "regraph";
import { formatDataForReGraphDynamic } from "./RegraphUtils";
import { useApiReGraphItems } from "./context/ReGraphContext";
import { Loading } from "../../../../components/elements/loading/Loading";
import awsImg from "../../../../assets/icons/aws.svg";
import { Menu } from "../../../../components/elements/menu/Menu";
import { MenuItem } from "../../../../components/elements/menu/MenuItem";
import { LinkButton } from "../../../../components/elements/button/link/LinkButton";

export const AssetsReGraphDynamicSeq = () => {
  const theme = useContext(ThemeContext);
  var reGraphItems = {};
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [selectedGroup, setGroup] = useState<GroupKeys>("Environment");
  type GroupKeys = "Environment" | "Product" | "Risk" | "Coverage";
  type GroupValues =
    | "environment"
    | "product_id"
    | "risk_score"
    | "coverage_score";

  const groups: Record<GroupKeys, GroupValues> = {
    Environment: "environment",
    Product: "product_id",
    Risk: "risk_score",
    Coverage: "coverage_score",
  };
  const { data, isFetching } = useApiReGraphItems();
  const [openCombos, setOpenCombos] = useState<any>({});
  const [combine, setCombine] = useState<any>({
    level: 1,
    properties: [groups[selectedGroup]],
  });
  if (!isFetching) {
    reGraphItems = formatDataForReGraphDynamic(data);
  }

  const combineNodesHandler = ({ id, setStyle, combo }: any) => {
    const { type, environment, product_id, coverage_score, risk_score } = combo;
    var nodeImage = "";
    var text = "";
    var glyphs: any = [];
    if (type === "domain") {
      nodeImage = "/icons/asset-server.svg";
      text = "Domains";
    } else if (type === "s3_bucket") {
      nodeImage = awsImg;
      text = "S3 Buckets";
    } else {
      nodeImage = "/icons/product.svg";
      if (selectedGroup === "Environment") {
        text = environment;
      } else if (selectedGroup === "Product") {
        text = `Product: ${product_id}`;
      } else if (selectedGroup === "Risk") {
        text = `Risk: ${risk_score}`;
      } else if (selectedGroup === "Coverage") {
        text = `Coverage: ${coverage_score}`;
      }

      glyphs = [
        {
          position: "ne",
          border: {
            color: "#f15d5b",
          },
          color: "#f15d5b",
          label: {
            text: "!",
          },
        },
      ];
    }
    setStyle({
      glyphs: glyphs,
      tightness: 1,
      open: !!openCombos[id],
      color: "#DCDCDC",
      closedStyle: {
        glyphs: glyphs,
        label: {
          bold: true,
          color: "black",
          position: "s",
          text: text,
        },
        image: nodeImage,
      },
      label: {
        bold: true,
        color: "black",
        position: "s",
        text: text,
      },
      image: nodeImage,
    });
  };

  const combineLinksHandler: Chart.onCombineLinksHandler = ({
    setStyle,
  }: any) => {
    setStyle({
      lineStyle: "dotted",
      width: 2,
      flow: true,
    });
  };

  const doubleClickHandler = ({ id }: any) => {
    /* Toggle the value on the openCombos object,

      this value is used to set the open state of the combo.


      Also creates a new 'combine' object. This forces ReGraph to

      do a re-render and call 'onCombineNodes'

      so we can set the open value of the combo. */
    setOpenCombos((current: any) => ({ ...current, [id]: !current[id] }));
    setCombine((current: any) => ({ ...current }));
  };
  console.log(openCombos);

  return (
    <Box
      className="d-flex flex-column w-100"
      style={{
        position: "relative",
        height: `calc(100vh - 260px)`,
        cursor: "grab",
        backgroundColor: theme.white100,
      }}
    >
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <LinkButton
            style={{
              position: "absolute",
              top: "45px",
              left: "120px",
              zIndex: "200",
            }}
            label={"Group By"}
            size="medium"
            iconName="sort"
            onClick={() => setShowMenu(!showMenu)}
          />
          {showMenu && (
            <Menu
              style={{
                top: "60px",
                left: "105px",
              }}
            >
              <MenuItem
                label="Environment"
                onClick={() => {
                  setGroup("Environment");
                  setCombine({
                    level: 1,
                    properties: [groups["Environment"]],
                  });
                }}
                selected={selectedGroup === "Environment"}
              />
              <MenuItem
                label="Product"
                onClick={() => {
                  setGroup("Product");
                  setCombine({
                    level: 1,
                    properties: [groups["Product"]],
                  });
                }}
                selected={selectedGroup === "Product"}
              />
              <MenuItem
                label="Risk"
                onClick={() => {
                  setGroup("Risk");
                  setCombine({
                    level: 1,
                    properties: [groups["Risk"]],
                  });
                }}
                selected={selectedGroup === "Risk"}
              />
              <MenuItem
                label="Coverage"
                onClick={() => {
                  setGroup("Coverage");
                  setCombine({
                    level: 1,
                    properties: [groups["Coverage"]],
                  });
                }}
                selected={selectedGroup === "Coverage"}
              />
            </Menu>
          )}
          <Chart
            items={reGraphItems}
            combine={combine}
            layout={{
              tightness: 1,
              linkShape: "curved",
              name: "sequential",
              stretch: 2,
              // top: ["prod-4", "prod-3", "prod-5", "prod-7", "prod-6"],
              orientation: "down",
              level: "level",
            }}
            onCombineNodes={combineNodesHandler}
            onDoubleClick={doubleClickHandler}
            onCombineLinks={combineLinksHandler}
            options={{ logo: { image: "/favicon.ico", position: "ne" } }}
          />
        </>
      )}
    </Box>
  );
};
