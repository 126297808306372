// Credit: https://latteandcode.medium.com/react-toast-an-implementation-with-the-context-api-and-hooks-f52fa564e4a8

import React, { createContext, useState } from "react";
import { Toast } from "./Toast";

export type ToastProps = {
  type: "success" | "error" | "warning" | "info";
  message: string;
  durationMs?: number;
  withUndo?: boolean;
  onUndo?: () => void;
  onDismiss?: () => void;
  _id?: string;
  _timeout?: ReturnType<typeof setTimeout>;
};

const ToastsContext = createContext<(toast: ToastProps) => void>(() => {});
export default ToastsContext;

export const ToastContextProvider = ({ children }: any) => {
  const [activeToasts, setActiveToasts] = useState<ToastProps[]>([]);

  const addToast = (toast: ToastProps) => {
    toast._id = toast._id || Math.random().toString(36);
    toast._timeout = setTimeout(() => {
      toast.onDismiss && toast.onDismiss();
      setActiveToasts((toasts) => toasts.filter((t) => t._id !== toast._id));
    }, toast.durationMs || 5000);
    setActiveToasts((toasts) => [...toasts, toast]);
  };

  const handleOnDismissed = (toast: ToastProps) => {
    toast.onDismiss && toast.onDismiss();
    toast._timeout && clearTimeout(toast._timeout);
    setActiveToasts((toasts) => toasts.filter((t) => t._id !== toast._id));
  };

  const handleOnUndo = (toast: ToastProps) => {
    toast.onUndo && toast.onUndo();
    toast._timeout && clearTimeout(toast._timeout);
    setActiveToasts((toasts) => toasts.filter((t) => t._id !== toast._id));
  };

  return (
    <ToastsContext.Provider value={addToast}>
      {children}
      <div
        style={{
          position: "fixed",
          bottom: "16px",
          right: "16px",
          zIndex: "999",
        }}
      >
        {activeToasts.map((toast, index) => (
          <Toast
            {...toast}
            key={`toast-${toast?._id || 0 + index}`}
            onDismiss={() => handleOnDismissed(toast)}
            onUndo={() => handleOnUndo(toast)}
          />
        ))}
      </div>
    </ToastsContext.Provider>
  );
};
