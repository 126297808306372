import { Link } from "react-router-dom";
import { useApiMe } from "../../../hooks/queries/meContext";
import { useApiAccounts } from "../../../hooks/queries/accountsContext";
import { objectToBase64 } from "../../../shared/helper";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { MainButton } from "../../../components/elements/button/main/MainButton";

export const TryWaspButton = () => {
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const { data: accounts } = useApiAccounts();

  const accountsData =
    accounts?.map((a) => ({
      email: a.email,
      role: a.role,
      can_manage_customer: a.can_manage_customer,
    })) || [];

  const demoUserData =
    me && accountsData
      ? objectToBase64({
          customer_name: me.customer.name || "",
          accounts: accountsData,
        })
      : "";

  console.log(demoUserData);

  return (
    <Link
      to={`https://wasp.op-c.net/register?demo_user_data=${demoUserData}`}
      style={{ textDecoration: "none" }}
    >
      <MainButton
        label="Try Wasp for Free"
        style={{ color: theme.white100 }}
        size="medium"
      />
    </Link>
  );
};
