import { useContext } from "react";
import {
  BodyRegular,
  BodyRegularHover,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";

export const TermsAndPrivacyPolicy = () => {
  const theme = useContext(ThemeContext);

  return (
    <Flex w100 justify="center" align="center" gap="5px" column>
      <BodyRegular color={theme.black600} style={{ width: "210px" }}>
        By signing in, you agree to our
      </BodyRegular>
      <Flex gap="4px">
        <Link
          to="https://wasp.op-c.net/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: theme.black600 }}
        >
          <Flex align="center">
            <BodyRegularHover color={theme.black600}>Terms</BodyRegularHover>
          </Flex>
        </Link>
        <BodyRegular color={theme.black600}>&</BodyRegular>
        <Link
          to="https://wasp.op-c.net/wasp_privacy_policy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: theme.black600 }}
        >
          <Flex align="center">
            <BodyRegularHover color={theme.black600}>
              Privacy Policy
            </BodyRegularHover>
          </Flex>
        </Link>
      </Flex>
    </Flex>
  );
};
