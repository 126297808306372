import { Customer } from "./Customer";
import { NotificationSettings } from "./Notifications";

export const DefaultSLAPreBreachedNotificationSettings: SLAPreBreachedNotificationSettings =
  {
    enabled: false,
    critical: 1,
    high: 1,
    medium: 1,
    low: 0,
    info: 0,
  };

export type SLAPreBreachedNotificationSettings = {
  [index: string]: boolean | number | undefined;
  enabled: boolean;
  critical?: number;
  high?: number;
  medium?: number;
  low?: number;
  info?: number;
};

export interface Me {
  // synced to user serializer - not account model
  errmsg?: string;
  accept_terms: boolean;
  account_id: number;
  can_manage_customer: boolean;
  customer: Customer;
  email: string;
  email_settings?: NotificationSettings | null;
  in_app_settings?: NotificationSettings | null;
  first_name: string;
  is_staff: boolean;
  is_superuser: boolean;
  has_unseen_whats_new: boolean;
  last_login?: string | null;
  last_name: string;
  name: string;
  phone?: string | null;
  role?: string;
  avatar_url?: string;
  sla_pre_breached_notifications?: SLAPreBreachedNotificationSettings;
  products_allowed: number[];
  projects_allowed: number[];
}

export type ShortUser = {
  name: string;
  email: string;
  avatar_url: string;
};
