import { Flex } from "../../../components/layouts/flex/Flex";
import { SimpleCheckbox } from "../../../components/elements/checkbox/SimpleCheckbox";
import { ProjectFormProps } from "./ProjectScheduler";

interface Props extends ProjectFormProps {
  isDisabled?: boolean;
}

export const ReportsSelector = (props: Props) => {
  const { isDisabled, mainFormValues, setMainFormValues } = props;

  return (
    <Flex gap="32px">
      <SimpleCheckbox label="Full Report" isChecked={true} />
      <SimpleCheckbox
        label="Attestation Letter"
        dataTestId="attestation"
        onChange={
          !isDisabled
            ? () =>
                setMainFormValues((prev) => ({
                  ...prev,
                  test_settings: {
                    ...prev.test_settings,
                    reports_types: {
                      ...prev.test_settings.reports_types,
                      attestation:
                        !prev.test_settings.reports_types?.attestation,
                    },
                  },
                }))
            : undefined
        }
        isChecked={mainFormValues.test_settings.reports_types?.attestation}
      />
      <SimpleCheckbox
        label="Executive Report"
        onChange={
          !isDisabled
            ? () =>
                setMainFormValues((prev) => ({
                  ...prev,
                  test_settings: {
                    ...prev.test_settings,
                    reports_types: {
                      ...prev.test_settings.reports_types,
                      executive: !prev.test_settings.reports_types?.executive,
                    },
                  },
                }))
            : undefined
        }
        isChecked={mainFormValues.test_settings.reports_types?.executive}
      />
      <SimpleCheckbox
        label="Retest Report"
        onChange={
          !isDisabled
            ? () =>
                setMainFormValues((prev) => ({
                  ...prev,
                  test_settings: {
                    ...prev.test_settings,
                    reports_types: {
                      ...prev.test_settings.reports_types,
                      retest: !prev.test_settings.reports_types?.retest,
                    },
                  },
                }))
            : undefined
        }
        isChecked={mainFormValues.test_settings.reports_types?.retest}
      />
    </Flex>
  );
};
