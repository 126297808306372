import { FindingComment } from "./FindingComment";
import { ShortUser } from "./Me";

export type Action =
  | "FINDING_CREATED"
  | "FINDING_DELETED"
  | "FINDING_UPDATED"
  | "COMMENT_CREATED"
  | "SENT_TO_JIRA"
  | "BREACHED_SLA";

export type FindingLog = {
  id: number;
  finding: number;
  customer: number;
  comment_id?: number;
  action: Action;
  actor: ShortUser;
  field?: string;
  old_value?: string;
  new_value?: string;
  created_at: string;
  comment?: string | FindingComment;
  is_synced?: boolean;
};

export type FindingLogPage = {
  results: FindingLog[] | null | undefined;
  count: number | null | undefined;
  next: string | null | undefined;
  previous: string | null | undefined;
};

type UI = {
  icon: string;
  background: string;
  color: string;
  text: string;
};

export const actionUI: { [key in Action]: UI } = {
  FINDING_CREATED: {
    icon: "plus",
    color: "greenPrimary",
    background: "green50",
    text: "Created",
  },
  FINDING_DELETED: {
    icon: "trash",
    color: "redPrimary",
    background: "red50",
    text: "Deleted",
  },
  FINDING_UPDATED: {
    icon: "edit",
    color: "orangePrimary",
    background: "orange50",
    text: "Change",
  },
  COMMENT_CREATED: {
    icon: "comment",
    color: "lightBluePrimary",
    background: "lightBlue50",
    text: "New Comment",
  },
  SENT_TO_JIRA: {
    icon: "jira",
    color: "blue",
    background: "blue50",
    text: "Sent to Jira",
  },
  BREACHED_SLA: {
    icon: "clock",
    color: "redPrimary",
    background: "red50",
    text: "Breached SLA",
  },
};
