import React, { useMemo } from "react";
import {
  HeaderSubHover,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Box } from "../../../components/elements/box/Box";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { CtiItem } from "../../../types/CtiItem";
import { getDate } from "../../../shared/helper";
import { useApiProducts } from "../../../hooks/queries/productsContext";

type Props = {
  ctiItem: CtiItem;
  width: number;
};

export const CtiItemPost = (props: Props) => {
  const { ctiItem, width } = props;
  const { data: products } = useApiProducts({ "admin-mode": true });

  const ctiItemsProductsNames = useMemo(
    () =>
      products
        ?.filter((p) => ctiItem.affected_products.includes(p.id))
        .map((p) => p.name) || [],
    [products, ctiItem]
  );
  return (
    <Box style={{ width: `${width}%` }}>
      <Flex w100 column>
        <img
          src={ctiItem.image}
          alt="cyber-placeholder"
          height={"150px"}
          width={"400px"}
          style={{
            width: "100%",
            borderRadius: "8px 0 8px 8px",
            marginBottom: "8px",
            justifyContent: "center",
          }}
        />
        <HeaderSubHover
          className="d-flex flex-wrap "
          style={{ width: "inherit" }}
        >
          <a
            href={ctiItem.link}
            style={{
              textDecoration: "none",
              color: "inherit",
              width: "inherit",
            }}
            data-testid="cti-item-title"
            rel="noreferrer"
            target="_blank"
          >
            {ctiItem.title}
          </a>
        </HeaderSubHover>
        <Flex align="center" gap="8px">
          <LabelRegular data-testid="cti-item-date">
            {ctiItem.created_at && getDate(ctiItem.created_at)}
          </LabelRegular>
          <BadgesLine badges={ctiItemsProductsNames} noWrap={true} />
        </Flex>
      </Flex>
    </Box>
  );
};
