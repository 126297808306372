import { ReactNode, useContext, useEffect } from "react";
import { ThemeContext } from "styled-components";
import { MainButton } from "../../elements/button/main/MainButton";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../elements/typography/Typography";
import { Modal } from "../../elements/modal/Modal";
import { TextButton } from "../../elements/button/text/TextButton";
import { Flex } from "../../layouts/flex/Flex";
import { Illustration } from "../../elements/illustrations/Illustration";

export const ConfirmModal = ({
  body,
  onClose,
  onConfirm,
  isLoading,
  isSuccess,
  title,
  successMessage,
  confirmButtonText,
  cancelButtonText,
  mainButtonVariant = "primary",
}: {
  body?: ReactNode | string;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  title: string;
  successMessage: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  mainButtonVariant?: "primary" | "danger";
}) => {
  const theme = useContext(ThemeContext);

  useEffect(() => {
    isSuccess && setTimeout(() => onClose(), 3000);
  }, [isSuccess, onClose]);

  return (
    <Modal onClose={() => onClose()} width="473px" hideCloseIcon>
      <Flex
        column
        justify="center"
        align="center"
        gap="24px"
        data-testid="alert-modal"
      >
        {isSuccess ? (
          <div
            data-testid="alert-success"
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Illustration name="success" />
            <HeaderSecondary className="text-center">
              {successMessage}
            </HeaderSecondary>
          </div>
        ) : (
          <>
            <Illustration name="alert" />
            <HeaderSecondary className="text-center">{title}</HeaderSecondary>
            <div className="d-flex gap-4 text-center">
              <BodyRegular>{body}</BodyRegular>
            </div>
            <MainButton
              dataTestId="confirm-btn"
              label={confirmButtonText || "Confirm"}
              onClick={() => onConfirm()}
              style={{ width: "100%" }}
              inProgress={isLoading}
              variant={mainButtonVariant}
              size="medium"
            />
            <TextButton
              dataTestId="modal-cancel-btn"
              label={cancelButtonText || "Cancel"}
              onClick={() => onClose()}
              color={theme.black900}
            />
          </>
        )}
      </Flex>
    </Modal>
  );
};
