import React from "react";
import UserIcon from "../../../assets/images/user.svg";

type Props = {
  size?: number;
  imageSrc?: string | any;
};

export const Avatar = (props: Props) => {
  const { size = 32, imageSrc } = props;
  return (
    <img
      src={imageSrc || UserIcon}
      alt="avatar"
      width={size}
      height={size}
      style={{
        borderRadius: "50%",
      }}
    />
  );
};
