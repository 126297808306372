import {
  Customer,
  IRRELEVANT_CUSTOMER_LICENSES,
  PriorityTier,
} from "../../types/Customer";

export const getRelevantCustomers = (
  customers: Customer[],
  tier: PriorityTier | "all" = "all"
): Customer[] => {
  return customers.filter(
    (c) =>
      !IRRELEVANT_CUSTOMER_LICENSES.includes(c.license?.type || "") &&
      (tier === "all" || tier === c.priority_tier)
  );
};

export const getRelevantCustomersIds = (
  customers: Customer[],
  tier: PriorityTier | "all" = "all"
): number[] => {
  const relevantCustomers = getRelevantCustomers(customers, tier);
  return relevantCustomers.map((c) => c.id);
};
