import { useContext, useEffect, useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SimpleCheckbox } from "../../../components/elements/checkbox/SimpleCheckbox";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import {
  HeaderMain,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";
import { useApiRegister } from "../../../hooks/queries/demoContext";
import useToastContext from "../../../hooks/toastHook";
import { ThemeContext } from "styled-components";
import { TosModal } from "./TosModal";
import { IS_DEMO_ENV } from "../../../hooks/queries/utils";
import { FormError } from "../../../components/elements/FormsElements/FormError";
import { RequiredField } from "../../../components/elements/requiredField/RequiredField";
import { base64ToObject } from "../../../shared/helper";

import waspLogo from "../../../assets/images/Brand-logo.png";

type FormErrors = {
  email?: string;
  name?: string;
  company?: string;
  role?: string;
  country?: string;
};

export const AccountForm = () => {
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();

  // get demo user data from local storage
  const demoUserJson = localStorage.getItem("demoUserData") || "";
  const demoUserData = !!demoUserJson ? base64ToObject(demoUserJson) : null;

  const { data: me, refetch: refetchMe } = useApiMe();
  const { mutate: register, isSuccess, isLoading } = useApiRegister();

  const demoUserAccounts =
    me && !!demoUserData?.accounts?.length
      ? demoUserData.accounts.filter((a: any) => a.email !== me?.email) || []
      : [];

  const initFormValues = {
    email: me?.email || "",
    name: me?.name || "",
    company: !!demoUserData?.customer_name ? demoUserData.customer_name : "",
    role: !!demoUserData?.accounts?.length ? demoUserData.accounts[0].role : "",
    country: "",
    allow_updates: false,
    accept_terms: false,
    accounts: demoUserAccounts,
  };

  const [formValues, setFormValues] = useState(initFormValues);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [showTosModal, setShowTosModal] = useState(false);

  const disabled =
    !formValues.accept_terms || !formValues.company || !formValues.accept_terms;

  const validateForm = (): boolean => {
    // returns true if form is valid
    const pattern = /^[A-Za-z0-9.&\s]+$/;
    let tempFormErrors: FormErrors = {};
    if (!pattern.test(formValues.company))
      tempFormErrors.company =
        "Company name must contain english letters and numbers only";
    if (!formValues.company)
      tempFormErrors.company = "Customer name must be provided";

    if (formValues.role && !pattern.test(formValues.role))
      tempFormErrors.role =
        "Role must contain english letters and numbers only";

    if (formValues.country && !pattern.test(formValues.country))
      tempFormErrors.country =
        "Country name contain english letters and numbers only";

    setFormErrors(tempFormErrors);
    return !Object.keys(tempFormErrors).length;
  };

  const startDemoOrTrial = () => {
    if (!validateForm()) return;
    register(formValues, {
      onError: ({ message }: Error) => addToast({ message, type: "error" }),
      onSuccess: () => localStorage.removeItem("demo_usr_data"),
    });
  };

  useEffect(() => {
    // Set form values as we get the users email
    if (me?.email)
      setFormValues((prev) => ({
        ...prev,
        email: me.email,
        name: me.name,
      }));
  }, [me]);

  useEffect(() => {
    // If all OK after submit query returns - refresh me data and set tour on
    if (!isSuccess) return;
    refetchMe();
    localStorage.setItem("show_tour", "true");
  }, [isSuccess, refetchMe]);

  if (me?.customer && !!me.customer.name) window.location.replace("/");
  return (
    <Flex column justify="center" align="center" style={{ height: "100vh" }}>
      <img
        alt="wasp"
        src={waspLogo}
        style={{
          width: "100vw",
          position: "fixed",
          top: "-35vh",
          left: "-60vw",
          zIndex: 0,
        }}
      />
      {me?.email && (
        <Box style={{ zIndex: 200 }}>
          <Flex column gap="32px" style={{ width: "300px" }}>
            <HeaderMain style={{ zIndex: 200 }}>Create Account</HeaderMain>
            <Flex column gap="12px">
              <Flex column gap="8px">
                <LabelRegular>Email</LabelRegular>
                <InputText
                  width="320px"
                  value={formValues.email}
                  disabled
                  dataTestId="email-input"
                />
              </Flex>
              <Flex column gap="8px">
                <LabelRegular>Full Name</LabelRegular>
                <InputText
                  width="320px"
                  value={formValues.name}
                  disabled
                  dataTestId="name-input"
                />
              </Flex>
              <Flex column gap="8px">
                <Flex gap="4px">
                  <LabelRegular>Company</LabelRegular>
                  <RequiredField />
                </Flex>
                <InputText
                  width="320px"
                  value={formValues.company}
                  isError={!!formErrors.company}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      company: e.target.value,
                    }))
                  }
                  dataTestId="company-input"
                />
                <FormError errorMessage={formErrors.company} />
              </Flex>
              <Flex column gap="8px">
                <Flex justify="between">
                  <LabelRegular>Job Description</LabelRegular>
                  <LabelRegular color={theme.black600}>Optional</LabelRegular>
                </Flex>
                <InputText
                  value={formValues.role}
                  isError={!!formErrors.role}
                  onChange={(e) =>
                    setFormValues((prev) => ({ ...prev, role: e.target.value }))
                  }
                  width="320px"
                  dataTestId="role-input"
                />
                <FormError errorMessage={formErrors.role} />
              </Flex>
              <Flex column gap="8px">
                <Flex justify="between">
                  <LabelRegular>Country</LabelRegular>
                  <LabelRegular color={theme.black600}>Optional</LabelRegular>
                </Flex>
                <InputText
                  width="320px"
                  value={formValues.country}
                  isError={!!formErrors.country}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      country: e.target.value,
                    }))
                  }
                />
                <FormError errorMessage={formErrors.country} />
              </Flex>
              <Flex
                align="center"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setFormValues((prev) => ({
                    ...prev,
                    allow_updates: !prev.allow_updates,
                  }))
                }
              >
                <SimpleCheckbox
                  isChecked={formValues.allow_updates}
                  onChange={(state) =>
                    setFormValues((prev) => ({ ...prev, allow_updates: state }))
                  }
                />
                <LabelRegular>
                  Subscribe for updates from OP Innovate
                </LabelRegular>
              </Flex>

              <Flex gap="4px" align="center" style={{ cursor: "pointer" }}>
                <Flex
                  align="center"
                  onClick={() =>
                    setFormValues((prev) => ({
                      ...prev,
                      accept_terms: !prev.accept_terms,
                    }))
                  }
                >
                  <SimpleCheckbox
                    isChecked={formValues.accept_terms}
                    onChange={(state) =>
                      setFormValues((prev) => ({
                        ...prev,
                        accept_terms: state,
                      }))
                    }
                  />
                  <LabelRegular>I Agree to the</LabelRegular>
                </Flex>
                <LabelRegular
                  color={theme.primary}
                  onClick={() => setShowTosModal(true)}
                >
                  terms and conditions
                </LabelRegular>
                <RequiredField />
              </Flex>
              <MainButton
                label={IS_DEMO_ENV ? "Start Demo" : "Start Trial"}
                disabled={disabled}
                onClick={startDemoOrTrial}
                inProgress={isLoading}
                size="medium"
              />
            </Flex>
          </Flex>
        </Box>
      )}
      {showTosModal && (
        <TosModal
          onClose={() => setShowTosModal(false)}
          onAccept={() => {
            setFormValues((prev) => ({ ...prev, accept_terms: true }));
            setShowTosModal(false);
          }}
        />
      )}
    </Flex>
  );
};
