import React from "react";
import { IconProps } from "./WaspIcon";

export function InsightsIcon(props: IconProps) {
  const size = props.size || 32;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.666 2.73462V16C14.6667 16.0021 14.6672 16.0043 14.6673 16.0066C14.6687 16.1613 21.6727 28.0479 21.6727 28.0479C19.9002 28.8917 17.9624 29.3307 15.9993 29.3333C8.64735 29.3333 2.66602 23.3519 2.66602 16C2.66602 9.09862 7.93802 3.40662 14.666 2.73462ZM17.334 14.6666V2.73462C23.6233 3.36129 28.6393 8.37729 29.266 14.6666H17.334ZM29.266 17.3333C28.8847 21.152 26.878 24.4893 23.9593 26.672L18.3553 17.3333H29.266Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
