import { ReactNode, useContext } from "react";
import { Box } from "../../../components/elements/box/Box";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Icon } from "../../../components/elements/icon/Icon";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { IS_DEMO_ENV } from "../../../hooks/queries/utils";

export const IntegrationItem = ({
  iconName,
  platform,
  isConnected,
  body,
  onConnect,
  onDisconnect,
  onEdit,
  inProgress,
}: {
  platform: string;
  iconName: string;
  isConnected: boolean;
  onConnect: () => void;
  onDisconnect?: () => void;
  onEdit?: () => void;
  body?: ReactNode;
  inProgress?: boolean;
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Box
      style={{
        height: "350px",
        width: "331px",
        padding: "16px",
      }}
      className="d-flex flex-column justify-content-between gap-16"
    >
      <Flex column gap="16px">
        <Flex justify="between" align="center" gap="16px" w100>
          <Icon name={iconName} size={40} />
          {isConnected ? (
            <Flex align="center" gap="16px">
              {!!onDisconnect && (
                <Tooltip placement="bottom" content={"Disconnect integration"}>
                  <IconButton
                    onClick={onDisconnect}
                    iconName="remove"
                    color={theme.redPrimary}
                    dataTestId={`remove-${platform.toLowerCase()}-button`}
                  />
                </Tooltip>
              )}
              <SeparatorVertical height="16px" />
              <Tooltip placement="bottom" content={"Integration is connected"}>
                <Icon name="checkGreen" size={"32px"} />
              </Tooltip>
            </Flex>
          ) : (
            <Tooltip
              placement="bottom"
              content={"Integration is not connected"}
            >
              <Icon name={inProgress ? "spinner" : "checkGray"} size={"32px"} />
            </Tooltip>
          )}
        </Flex>

        <HeaderSecondary>{platform}</HeaderSecondary>
      </Flex>
      <Flex h100 column justify="start">
        {body}
      </Flex>
      {isConnected ? (
        <SecondaryButton
          label="Edit Connection"
          style={{ width: "100%" }}
          onClick={onEdit || (() => {})}
          size="small"
          disabled={!onEdit || IS_DEMO_ENV}
          dataTestId={`edit-${platform.toLowerCase()}-button`}
        />
      ) : (
        <MainButton
          label="Connect"
          onClick={() => onConnect()}
          style={{ width: "100%" }}
          size="small"
          inProgress={inProgress}
          dataTestId={`connect-${platform.toLowerCase()}-button`}
          disabled={IS_DEMO_ENV}
        />
      )}
    </Box>
  );
};
