import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { MenuButton } from "../../elements/button/menu/MenuButton";
import { useLocation } from "react-router-dom";
import { SettingsNav } from "./SettingsNav";

export const LeftNavBar = () => {
  const theme = useContext(ThemeContext);
  const location = useLocation();
  let currentPage = location.pathname.replace("/", "");
  if (!currentPage) currentPage = "dashboard";

  return (
    <>
      <div
        id="left-navbar"
        style={{
          width: "97px",
          position: "fixed",
          zIndex: 50,
          top: 0,
          left: 0,
          bottom: 0,
          height: "100%",
          paddingTop: "72px",
          overflowX: "hidden",
          backgroundColor: theme.bg2,
          borderRight: `1px solid ${theme.black500}`,
        }}
      >
        <div
          style={{
            height: "calc(100% - 130px)",
          }}
        >
          <MenuButton
            label="Dashboard"
            linkTo="/dashboard"
            selected={currentPage === "dashboard"}
            iconName="main"
          />
          <MenuButton
            label="Findings"
            linkTo="/findings"
            selected={currentPage === "findings"}
            iconName="findingsNav"
          />
          <MenuButton
            label="Assets"
            linkTo="/assets"
            selected={currentPage === "assets"}
            iconName="assets"
          />
          <MenuButton
            label="Projects"
            linkTo="/projects"
            selected={currentPage === "projects"}
            iconName="shieldSolid"
          />
          <MenuButton
            label="Insights"
            linkTo="/insights"
            selected={currentPage === "insights"}
            iconName="insights"
          />
          <MenuButton
            label="WASP AI"
            linkTo="/wasp-ai"
            selected={currentPage === "wasp-ai"}
            iconName="map"
          />
        </div>
      </div>
      <SettingsNav isOpen={currentPage.includes("settings")} />
    </>
  );
};
