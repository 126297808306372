import React, { useContext } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { LinkButton } from "../../../../components/elements/button/link/LinkButton";
import { MainButton } from "../../../../components/elements/button/main/MainButton";

type Props = {
  readyForNext: boolean;
  inProgress?: boolean;
  onNext: () => void;
  onPrev?: () => void;
  nextText?: string;
  width?: string;
};

export const JiraIntegrationModalFooter = (props: Props) => {
  const { onNext, onPrev, readyForNext, inProgress, nextText, width } = props;
  const theme = useContext(ThemeContext);
  return (
    <Flex
      align="center"
      justify="end"
      gap="16px"
      style={{
        width: width || "100%",
      }}
    >
      {!!onPrev && (
        <LinkButton
          label="Back"
          onClick={onPrev}
          color={theme.black900}
          dataTestId="back-button"
        />
      )}
      <MainButton
        label={nextText || "Continue"}
        onClick={onNext}
        disabled={!readyForNext}
        inProgress={inProgress}
        dataTestId="next-button"
        size="medium"
      />
    </Flex>
  );
};
