import React from "react";
import { StyleTextButton } from "./TextButton.style";

type Props = {
  label: string;
  size?: "small" | "medium" | "large";
  fontSize?: string;
  onClick?: () => void;
  disabled?: boolean;
  color?: string;
  dataTestId?: string;
  isTextTruncate?: boolean;
  style?: React.CSSProperties;
};

export const TextButton = (props: Props) => {
  const {
    label,
    size = "medium",
    onClick,
    disabled = false,
    color,
    isTextTruncate,
    fontSize,
    style,
  } = props;

  const sizes = {
    small: 0.75,
    medium: 1,
    large: 1.25,
  };

  return (
    <StyleTextButton
      className={isTextTruncate ? "text-truncate" : ""}
      onClick={!!onClick ? onClick : () => {}}
      disabled={disabled}
      size={sizes[size]}
      color={color}
      data-testid={props.dataTestId}
      fontSize={fontSize}
      style={style}
    >
      {label}
    </StyleTextButton>
  );
};
