import React from "react";
import { IconProps } from "./WaspIcon";

export function ChevronRightIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5538 11.9998C15.5538 12.0786 15.5383 12.1567 15.5081 12.2294C15.478 12.3022 15.4338 12.3684 15.378 12.4241L9.47178 18.3297C9.41628 18.3863 9.35011 18.4314 9.27708 18.4623C9.20405 18.4932 9.12562 18.5093 9.04633 18.5097C8.96704 18.5101 8.88845 18.4948 8.81511 18.4647C8.74178 18.4345 8.67515 18.3901 8.61908 18.334C8.56301 18.278 8.51861 18.2113 8.48845 18.138C8.45829 18.0647 8.44297 17.9861 8.44337 17.9068C8.44377 17.8275 8.45988 17.749 8.49078 17.676C8.52168 17.603 8.56675 17.5368 8.62338 17.4813L14.1054 11.9998L8.62335 6.51839C8.51272 6.4055 8.4511 6.25351 8.4519 6.09544C8.4527 5.93738 8.51584 5.78602 8.62761 5.67425C8.73938 5.56249 8.89074 5.49934 9.0488 5.49854C9.20687 5.49774 9.35886 5.55936 9.47175 5.66999L15.378 11.5756C15.4337 11.6313 15.478 11.6975 15.5081 11.7702C15.5383 11.843 15.5538 11.921 15.5538 11.9998Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
