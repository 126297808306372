import { useNavigate } from "react-router";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { Modal } from "../../../components/elements/modal/Modal";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  FindingsMergeOutput,
  useApiMergeFindings,
} from "../../../hooks/queries/findingContext";
import useToastContext from "../../../hooks/toastHook";
import { Finding } from "../../../types/Finding";
import { FindingCard } from "../findingsCards/FindingCard";
import { useApiSingleProject } from "../../../hooks/queries/projectsContext";

type Props = {
  onClose: () => void;
  findingToMergeInto: Finding;
  findingToMerge: Finding;
};

export const MergeConfirmModal = (props: Props) => {
  const { onClose, findingToMerge, findingToMergeInto } = props;
  const addToast = useToastContext();
  const navigate = useNavigate();
  const { mutate: mergeFindings, isLoading } = useApiMergeFindings();
  const { data: projectToMerge } = useApiSingleProject(
    !!findingToMerge.project,
    findingToMerge.project
  );
  const { data: projectToMergeInto } = useApiSingleProject(
    !!findingToMergeInto.project,
    findingToMergeInto.project
  );

  const handleMerge = () => {
    addToast({ message: `Merging findings...`, type: "info" });
    mergeFindings({
      findingsData: {
        finding_ids: [findingToMerge.id, findingToMergeInto.id],
        merge_to: findingToMergeInto.id,
      },
      onSuccessCallback: (mergeResult: FindingsMergeOutput) => {
        addToast({
          message: `Findings have been successfully merged!`,
          type: "success",
          durationMs: 10000,
        });
        navigate(`/finding-details/${findingToMergeInto.id}`);
        onClose();
      },
      onErrorCallback: (error: Error) => {
        addToast({ message: error.message, type: "error" });
        onClose();
      },
    });
  };

  return (
    <Modal onClose={onClose} header="Confirm Merge" width="620px">
      <Flex column gap="24px" align="center" w100>
        <Flex column gap="8px" align="center" w100>
          <Flex gap="4px">
            <BodyBold>Are you sure you want to merge </BodyBold>
            <BodyRegular>finding {findingToMerge.id}</BodyRegular>
          </Flex>
          <FindingCard
            finding={findingToMerge}
            // isSmall
            hasUnreadComments={false}
            onOpenProjectPane={() => {}}
            onSelectedFinding={() => {}}
            project={projectToMerge}
          />
          <Flex gap="4px">
            <BodyBold> into</BodyBold>
            <BodyRegular>finding {findingToMergeInto.id}</BodyRegular>
          </Flex>
          <FindingCard
            finding={findingToMergeInto}
            // isSmall
            hasUnreadComments={false}
            onOpenProjectPane={() => {}}
            onSelectedFinding={() => {}}
            project={projectToMergeInto}
          />
        </Flex>
        <Flex justify="end" w100>
          <MainButton
            label="Merge"
            onClick={() => handleMerge()}
            inProgress={isLoading}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
