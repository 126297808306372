import { useApiMe } from "../../hooks/queries/meContext";
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { LeftNavBar } from "./leftNavbar/LeftNavBar";
import { TopNavbar } from "./topNavbar/TopNavbar";
import { useHotJar, useMixpanel } from "../../hooks/trackingHooks";
import { WelcomeBanner } from "../composed/banner/WelcomeBanner";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";

export const ScreenLayout = () => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  const { data: me, isLoading, error } = useApiMe();

  useMixpanel(me);
  useHotJar(me);

  if (isLoading) return <WelcomeBanner />;

  if (error instanceof Error)
    return <div>An error has occurred: {error.message}</div>;

  return (
    <div
      id="screen-layout"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        backgroundColor: theme.background,
        height: "100%",
        width: "100vw",
        overflow: "auto",
      }}
    >
      <TopNavbar me={me} />
      {!isMobile && <LeftNavBar />}
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
};
