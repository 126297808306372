import { ReactNode, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { KPI } from "../../../components/composed/kpi/KPI";

type DistributionItemStyleProps = { isSelected?: boolean };
const DistributionItemStyle = styled.div<DistributionItemStyleProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 8px;
  border: 8px solid;
  border-color: transparent;
  background-color: ${(props) =>
    props.isSelected ? props.theme.blue100 : props.theme.bg2};
  &:hover {
    background-color: ${(props) => props.theme.blue100};
    border-color: ${(props) => props.theme.blue100};
  }
`;

export type DistributionChartItemData = {
  name: string;
  value: number;
  percentage: number;
};

type Props = {
  dataItemLabel: ReactNode;
  dataItem: DistributionChartItemData;
  onClick: (dataItemLabel: string) => void;
  isSelected?: boolean;
  idx: number;
};

export const DistributionItem = (props: Props) => {
  const { dataItemLabel, dataItem, onClick, isSelected, idx } = props;

  const theme = useContext(ThemeContext);

  const colors = [
    theme.blue800,
    theme.blue700,
    theme.blue600,
    theme.blue500,
    theme.blue300,
    theme.blue100,
  ];

  return (
    <DistributionItemStyle
      onClick={() => onClick(dataItem.name)}
      isSelected={isSelected}
    >
      <Tooltip
        isTextTruncate
        content={
          <Flex align="center" gap="4px">
            <BodyRegular>{dataItemLabel}</BodyRegular>:
            <BodyBold>{dataItem.value}</BodyBold>
          </Flex>
        }
      >
        <Flex column gap="4" w100>
          <KPI
            count={dataItem.value}
            size="small"
            style={{
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
            }}
          />
          <BodyRegular
            className="text-truncate"
            style={{ width: "160px" }}
            color={theme.textSub}
          >
            {dataItemLabel}
          </BodyRegular>
        </Flex>
      </Tooltip>

      <div
        style={{
          height: "8px",
          backgroundColor: colors[idx] || theme.blue50,
          borderRadius: "24px",
          width: "100%",
        }}
      />
    </DistributionItemStyle>
  );
};
