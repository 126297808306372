import React from "react";
import styled from "styled-components";

export type EntryProps = {
  element: JSX.Element;
  icon?: JSX.Element;
};

type Props = {
  entries: EntryProps[];
};

const TimelineEntry = styled.div`
  position: relative;
  border-radius: 4px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: -40px;
  top: 40px;
  padding: 2px;
  background-color: ${(props) => props.theme.bg2};
`;

const TimelineStyle = styled.div`
  position: relative;
  padding-left: 31px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &:before {
    content: "";
    position: absolute;
    left: 4px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: ${(props) => props.theme.black500};
  }
`;

export const Timeline = ({ entries }: Props) => {
  return (
    <TimelineStyle>
      {entries.map((entry, index) => (
        <TimelineEntry key={index}>
          {entry.element}
          {!!entry.icon && <IconWrapper>{entry.icon}</IconWrapper>}
        </TimelineEntry>
      ))}
    </TimelineStyle>
  );
};
