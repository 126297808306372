import styled from "styled-components";
import { TypographyBodyRegular } from "../../typography/Typography";
type ButtonProps = {
  size: "small" | "medium" | "large";
  color?: string;
  noPadding?: boolean;
};

const sizes = {
  small: 0.75,
  medium: 1,
  large: 1.25,
};

export const StyleLinkButton = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (props.noPadding ? "" : "6px 16px")};
  gap: 8px;
  ${TypographyBodyRegular}
  font-size: ${(props) => 14 * sizes[props.size]}px;
  border: none;
  color: ${(props) => props.color || props.theme.black900};
  box-shadow: unset;
  background-color: transparent;
  cursor: pointer;
  &:hover:enabled {
    color: ${(props) => props.theme.primary} !important;
  }
  &:disabled {
    color: ${(props) => props.theme.buttonDisabled};
    cursor: not-allowed;
  }
  &:active:enabled {
    color: ${(props) => props.theme.secondary};
  }
  &:focus {
    outline: none;
  }
`;
