import React from "react";
import { IconProps } from "./WaspIcon";

export function FolderIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.191 6.95912H13.575C13.2981 6.95832 13.0251 6.89399 12.777 6.77108C12.5289 6.64816 12.3124 6.46995 12.144 6.25016L11.6214 5.5652C11.3408 5.19894 10.9799 4.902 10.5664 4.6973C10.153 4.49259 9.69801 4.38558 9.23664 4.38452H5.80896C5.01356 4.38535 4.25098 4.70168 3.68855 5.26411C3.12612 5.82654 2.80979 6.58913 2.80896 7.38452V16.6154C2.80979 17.4108 3.12612 18.1734 3.68855 18.7358C4.25098 19.2982 5.01356 19.6146 5.80896 19.6154H18.191C18.9864 19.6146 19.749 19.2982 20.3114 18.7358C20.8739 18.1734 21.1902 17.4108 21.191 16.6154V9.95912C21.1902 9.16373 20.8739 8.40114 20.3114 7.83871C19.749 7.27628 18.9864 6.95995 18.191 6.95912ZM5.80896 5.58452H9.23676C9.5135 5.58532 9.78637 5.64955 10.0344 5.77229C10.2824 5.89502 10.499 6.073 10.6675 6.29252L11.1902 6.97748C11.4709 7.34365 11.8319 7.64056 12.2453 7.84535C12.6587 8.05014 13.1136 8.15736 13.575 8.15876H18.191C18.6683 8.1593 19.1258 8.34912 19.4632 8.68657C19.8007 9.02401 19.9905 9.48154 19.991 9.95876V10.2148H4.00896V7.38452C4.0095 6.9073 4.19932 6.44977 4.53676 6.11233C4.87421 5.77488 5.33174 5.58506 5.80896 5.58452ZM18.191 18.4154H5.80896C5.33174 18.4149 4.87421 18.225 4.53676 17.8876C4.19932 17.5501 4.0095 17.0926 4.00896 16.6154V11.4152H19.991V16.6154C19.9905 17.0926 19.8007 17.5501 19.4632 17.8876C19.1258 18.225 18.6683 18.4149 18.191 18.4154Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
