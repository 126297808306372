import { ReactourStep } from "reactour";
import {
  BodyRegular,
  HeaderSecondary,
} from "../components/elements/typography/Typography";
import { TextButton } from "../components/elements/button/text/TextButton";
import { Link } from "react-router-dom";

export const waspAISteps = (theme: any): ReactourStep[] => [
  {
    selector: "",
    content: () => (
      <>
        <HeaderSecondary>Welcome to WASP AI</HeaderSecondary>
        <BodyRegular>
          Welcome to your interactive assistant, WASP AI! Let's explore how you
          can communicate directly with your security systems.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="wasp-ai-input"]',
    content: () => (
      <>
        <HeaderSecondary>Starting a Conversation</HeaderSecondary>
        <BodyRegular>
          This is your main interface for interaction. You can type here to ask
          any security-related questions, such as details about vulnerabilities,
          risk assessments, or asset status.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="wasp-ai"]',
    content: () => (
      <>
        <HeaderSecondary>Get Instant Insights</HeaderSecondary>
        <BodyRegular>
          For example, you can try asking 'What is my current security posture?'
          to get an instant written summary of your security status.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: "",
    content: () => (
      <>
        <HeaderSecondary>You're good to go!</HeaderSecondary>
        <BodyRegular>
          You're all set to start interacting with WASP AI! Dive into your
          security details, and remember, our support team is here if you need
          further assistance.
        </BodyRegular>
        <Link to="/dashboard?tour=on" style={{ textDecoration: "none" }}>
          <TextButton label="Go to Tour Start - Dashboard Page" />
        </Link>
      </>
    ),
  },
];
