import { Modal } from "../../../components/elements/modal/Modal";

export type Props = {
  onClose: () => void;
};

export const PolicyModal = (props: Props) => {
  const { onClose } = props;

  return (
    <Modal onClose={onClose} header="Credits Expiration Policy" width="790px">
      Here Goes the Credits Expiration Policy
    </Modal>
  );
};
