import React, { useContext } from "react";
import { Icon } from "../../icon/Icon";
import { IconWrapper, StyleTabButton } from "./TabButton.style";
import { ThemeContext } from "styled-components";

type Props = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  selected: boolean;
  iconName: string;
  size?: "small" | "medium" | "large";
};

const getIconSize = (size: "small" | "medium" | "large") => {
  switch (size) {
    case "small":
      return 24;
    case "medium":
      return 32;
    case "large":
      return 48;
  }
};

export const TabButton = (props: Props) => {
  const {
    label,
    onClick,
    disabled = false,
    iconName,
    selected,
    size = "medium",
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <StyleTabButton
      onClick={onClick}
      disabled={disabled}
      selected={selected}
      size={size}
    >
      <>
        <IconWrapper selected={selected} size={size}>
          <Icon
            name={iconName}
            size={getIconSize(size)}
            color={selected ? theme.primary : theme.textSub}
          />
        </IconWrapper>
        {label}
      </>
    </StyleTabButton>
  );
};
