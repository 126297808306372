import { Node } from "@xyflow/react";
import { GraphGroupNode, GraphNode, Placement } from "./GraphTypes";
import { calculateAssetGrade } from "../../AssetUtils";
import { AssetGrade } from "../../../../types/Asset";
import {
  calculateGroupRadius,
  createNodesLayout,
  findPlacementForGroup,
  findPlacementSeverityGroup,
  getPositionOfNodeDragOutsideGroup,
} from "./NodeLayoutManager";
import { SetStateAction, useEffect } from "react";
import {
  DEFAULT_SHOW_LABELS,
  MAX_NODES_TO_DISPLAY_IN_GROUP,
} from "./constants";

export const MAP_ASSET_GRADE_RISK: Record<AssetGrade, string> = {
  A: "No Risk",
  B: "Low",
  C: "Medium",
  D: "Medium",
  E: "High",
  F: "Critical",
};

export const getNodeRiskColor = (
  riskScore: number,
  shouldColorByRisk: boolean,
  theme: any,
  isGroupByRisk: boolean,
  isFinding?: boolean
): string => {
  if (!shouldColorByRisk) {
    return theme.primary;
  }
  if (isFinding) {
    return theme[getGraphRiskColor(Number(riskScore))];
  }
  const assetGrade = calculateAssetGrade(riskScore);
  const risk = MAP_ASSET_GRADE_RISK[assetGrade];
  return {
    "No Risk": isGroupByRisk ? theme.graphRiskNoRisk : theme.primary,
    Low: theme.graphRiskLow,
    Medium: theme.graphRiskMedium,
    High: theme.graphRiskHigh,
    Critical: theme.graphRiskCritical,
  }[risk];
};

export const mapGraphNodeToFlowNode = (
  node: GraphNode,
  color: string,
  isExpand: boolean,
  showLabel: boolean
): Node => {
  return {
    id: node.id,
    data: {
      label: `${node.name}`,
      color: color,
      isGroup: false,
      risk: node.risk,
      showLabel: showLabel,
      expandX: node.x,
      expandY: node.y,
      isHidden: !isExpand,
      isDuplicated: node.isDuplicated,
      createdAt: node.createdAt,
    },
    type: "domain",
    position: {
      x: isExpand ? node.x : 14,
      y: isExpand ? node.y : 14,
    },
    parentId: node.groupId,
  };
};

export const calcGroupRisk = (group: GraphGroupNode) => {
  return group.nodes.reduce((acc, node) => {
    if (!acc || node.risk > acc) {
      return node.risk;
    }
    return acc;
  }, 0);
};

export const getGraphRiskBackgroundColor = (risk: number): string => {
  return (
    {
      0: "graphRiskNoRiskLight",
      1: "graphRiskLowLight",
      2: "graphRiskMediumLight",
      3: "graphRiskHighLight",
      4: "graphRiskCriticalLight",
    }[risk] || "blue50"
  );
};

export const getGraphRiskColor = (risk: number): string => {
  return (
    {
      0: "graphRiskNoRisk",
      1: "graphRiskLow",
      2: "graphRiskMedium",
      3: "graphRiskHigh",
      4: "graphRiskCritical",
    }[risk] || "blue50"
  );
};

export const handleNodeDrag = (
  nodes: Node[],
  setNodes: React.Dispatch<SetStateAction<Node[]>>,
  node: Node
) => {
  if (!!node.data?.isGroup || !!node.data?.isHidden) {
    return;
  }
  const groupNode = nodes.find((n) => n.id === node.parentId);
  if (!groupNode) {
    return;
  }
  const newPos = getPositionOfNodeDragOutsideGroup(
    groupNode.data.radius as number,
    node.position.x,
    node.position.y
  );
  if (newPos) {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === node.id) {
          // Update node position to stay within the boundary
          return {
            ...n,
            position: newPos,
          };
        }
        return n;
      })
    );
  }
};

export const calcOtherGroupPosition = (
  n: Node,
  currentGroupsPlacements: Placement[]
) => {
  const otherGroupPosition = n?.data?.isFinding
    ? findPlacementSeverityGroup(
        Number(n.data?.radius),
        currentGroupsPlacements,
        Number(n.data?.maxHeight),
        Number(n.data?.startY)
      )
    : findPlacementForGroup(Number(n.data?.radius), currentGroupsPlacements);
  currentGroupsPlacements.push({
    x: otherGroupPosition.x,
    y: otherGroupPosition.y,
    width: Number(n.data?.radius) * 2,
    height: Number(n.data?.radius) * 2,
  });
};

export const handleClickGroupNode = (
  groupId: string,
  groupNodes: GraphNode[],
  setNodes: React.Dispatch<SetStateAction<Node[]>>,
  forceExpand?: boolean,
  setGroupChangeCount?: React.Dispatch<SetStateAction<number>>
) => {
  setNodes((nodes) => {
    const currentGroup = nodes.find((n) => n.id === groupId);
    const isExpand =
      forceExpand === undefined ? !currentGroup?.data?.isExpand : forceExpand;

    const groupRadius = calculateGroupRadius(!isExpand ? 0 : groupNodes.length);

    if (isExpand) {
      createNodesLayout(groupRadius, groupNodes);
    }

    var currentGroupsPlacements: Placement[] = [
      {
        x: Number(currentGroup?.position?.x),
        y: Number(currentGroup?.position?.y),
        width: groupRadius * 2,
        height: groupRadius * 2,
      },
    ];

    !!setGroupChangeCount && setGroupChangeCount((count) => count + 1);

    return [
      ...nodes
        // case it's collapsed
        .filter((n) => n.parentId !== groupId)
        // update group's node properties
        .map((n) => {
          const isOtherGroup = !!n.data.isGroup && n.id !== groupId;
          if (isOtherGroup) {
            calcOtherGroupPosition(n, currentGroupsPlacements);
          }
          return {
            ...n,
            // case this group node
            ...(n.id === groupId && {
              data: {
                ...n.data,
                radius: groupRadius,
                isExpand,
              },
            }),
            // case other groups need to move to make space for the expanded group
            ...(isOtherGroup && {
              position: {
                x: currentGroupsPlacements.slice(-1)[0].x,
                y: currentGroupsPlacements.slice(-1)[0].y,
              },
            }),
          };
        }),
      ...(!isExpand
        ? []
        : groupNodes
            .sort((a, b) => b.risk - a.risk)
            .slice(0, MAX_NODES_TO_DISPLAY_IN_GROUP)
            .map((n) =>
              mapGraphNodeToFlowNode(n, "gray", true, DEFAULT_SHOW_LABELS)
            )),
    ];
  });
  // setTimeout(() => {
  //   reactFlowInstance?.current?.fitView();
  // }, 300);
};

export function useLabelOrColorStateChange(
  setNodes: React.Dispatch<SetStateAction<Node[]>>,
  showLabels: boolean,
  colorByRisk: boolean,
  showNew: boolean,
  isGroupByRisk: boolean,
  theme: any,
  counter?: number
) {
  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((n) => {
        return {
          ...n,
          data: {
            ...n.data,
            showLabel: showLabels,
            showNew: showNew,
            color: getNodeRiskColor(
              Number(n.data.risk),
              colorByRisk,
              theme,
              isGroupByRisk,
              !!n.data.isFinding
            ),
          },
        };
      })
    );
  }, [
    setNodes,
    showLabels,
    colorByRisk,
    isGroupByRisk,
    theme,
    counter,
    showNew,
  ]);
}
