import React, { useContext } from "react";
import { Badge } from "../../../../components/elements/badge/Badge";
import { ThemeContext } from "styled-components";

export type RiskScoreComponentType =
  | "amount_findings"
  | "critical_findings"
  | "breached_sla";

type Props = {
  componentType: RiskScoreComponentType;
  componentWeight: number;
};

export const RiskScoreComponentBadge = ({
  componentType,
  componentWeight,
}: Props) => {
  const theme = useContext(ThemeContext);

  const getColor = () => {
    switch (componentType) {
      case "amount_findings":
        return theme.yellow800;
      case "critical_findings":
        return theme.red800;
      case "breached_sla":
        return theme.orangePrimary;
    }
  };

  const getBackgroundColor = () => {
    switch (componentType) {
      case "amount_findings":
        return theme.yellow50;
      case "critical_findings":
        return theme.red50;
      case "breached_sla":
        return theme.orange50;
    }
  };

  return (
    <Badge
      color={getColor()}
      backgroundColor={getBackgroundColor()}
      style={{ width: "49px" }}
      className="text-center"
    >
      {(componentWeight * 100).toFixed()}%
    </Badge>
  );
};
