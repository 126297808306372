import { Dispatch, SetStateAction } from "react";
import {
  BodyRegular,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import { useParams } from "react-router";
import { useUpdateFindingInPlace } from "../../../../shared/formUtils";

type Props = {
  finding: Finding | undefined | null;
  isEditable?: boolean;
  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};
export const SummaryItem = (props: Props) => {
  const {
    finding,
    isEditable,
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const { id: updateFindingId } = useParams();
  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  return (
    <Flex column gap="24px">
      {!isEditable && !!finding?.summary && (
        <>
          <Flex column gap="8px">
            <LabelRegular>Finding Summary</LabelRegular>
            <BodyRegular className="d-flex flex-wrap">
              {finding?.summary}
            </BodyRegular>
          </Flex>
          <SeparatorHorizontal />
        </>
      )}

      {isEditable && (
        <>
          <Flex column gap="8px">
            <LabelRegular>Finding Summary</LabelRegular>
            <InputText
              placeholder="Insert one line summary..."
              width="100%"
              saveChangesMode
              value={editableUpdateFindingData?.summary || ""}
              initValue={finding?.summary || ""}
              queryStatus={changedField === "summary" ? queryStatus : undefined}
              disabled={queryStatus !== "idle"}
              onChange={(e) => {
                setEditableUpdateFinding &&
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    summary: e.target.value,
                  }));
                setCachedFindingData &&
                  setCachedFindingData({
                    summary: e.target.value,
                  });

                // let errors = { ...formErrors };
                // errors.summary = e.target.value === "" ? ["Title is required"] : [];
                // setFormErrors(errors);
              }}
              onKeyDown={() =>
                updateInPlace({ summary: editableUpdateFindingData?.summary })
              }
              onSave={() =>
                updateInPlace({ summary: editableUpdateFindingData?.summary })
              }
              onCancel={() => {
                setEditableUpdateFinding &&
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    summary: finding?.summary,
                  }));
                setCachedFindingData &&
                  setCachedFindingData({ summary: finding?.summary });
              }}
            />
          </Flex>
          <SeparatorHorizontal />
        </>
      )}
    </Flex>
  );
};
