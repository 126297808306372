import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  AlertBannerMessage,
  AlertBannerMessageWithReceivers,
} from "../../types/AlertBannerMessage";
import {
  createItem,
  deleteItem,
  getItems,
  getSingleItem,
  updateItem,
} from "./sdk";
import { invalidateApiQueries } from "./utils";

const key = "alert-banner-messages";
const custom_create_action = "create-with-receivers";
const ALERT_STALE_TIME = 1000 * 60 * 5; // 5 min, since alerts need to update frequently

interface AlertBannerMessageWithReceiversParams
  extends AlertBannerMessageWithReceivers {
  onSuccessCallback?: (data: AlertBannerMessage) => void;
  onErrorCallback?: (error: Error) => void;
}

interface AlertBannerMessageContext {
  alertBannerMessageId: number;
  alertBannerMessageData: AlertBannerMessage;
  onSuccessCallback?: (data: AlertBannerMessage) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiAlertBannerMessages = () =>
  useQuery<AlertBannerMessage[], Error>({
    queryKey: [key],
    placeholderData: [],
    refetchInterval: ALERT_STALE_TIME,
    queryFn: async (): Promise<AlertBannerMessage[]> => getItems(key),
  });

export const useApiSingleAlertBannerMessage = (id: number | undefined) =>
  useQuery<AlertBannerMessage | undefined, Error>({
    queryKey: [key, id],
    placeholderData: undefined,
    enabled: !!id,
    queryFn: async (): Promise<AlertBannerMessage | undefined> =>
      getSingleItem(key, id?.toString() || "").then((data) =>
        data ? (data as AlertBannerMessage) : undefined
      ),
  });
export const useApiUpdateAlertBannerMessage = () => {
  const queryClient = useQueryClient();
  return useMutation<AlertBannerMessage, Error, AlertBannerMessageContext>({
    mutationKey: [key],
    mutationFn: async ({
      alertBannerMessageId,
      alertBannerMessageData,
    }: AlertBannerMessageContext): Promise<AlertBannerMessage> =>
      await updateItem(key, alertBannerMessageId, alertBannerMessageData),
    onSuccess: (data: AlertBannerMessage, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiCreateAlertBannerMessage = () => {
  const queryClient = useQueryClient();
  return useMutation<
    AlertBannerMessage,
    Error,
    AlertBannerMessageWithReceiversParams
  >({
    mutationKey: [key, "create"],
    mutationFn: async (
      newAlert: AlertBannerMessageWithReceivers
    ): Promise<AlertBannerMessage> =>
      await createItem(key, newAlert, custom_create_action),
    onSuccess: (data: AlertBannerMessage, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiDeleteAlertBannerMessage = () => {
  const queryClient = useQueryClient();
  return useMutation<AlertBannerMessage, Error, AlertBannerMessageContext>({
    mutationKey: [key],
    mutationFn: async ({
      alertBannerMessageId,
      alertBannerMessageData,
    }: AlertBannerMessageContext): Promise<any> =>
      await deleteItem(key, alertBannerMessageData, alertBannerMessageId),
    onSuccess: (data: AlertBannerMessage, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};
