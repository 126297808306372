import { Dispatch, SetStateAction, useContext } from "react";
import { Box } from "../../../../components/elements/box/Box";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { Column, Table } from "../../../../components/elements/table/Table";
import AlertBanner from "../../../../components/elements/toastTypes/AlertBanner";
import {
  BodyRegular,
  HeaderMain,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  useApiAlertBannerMessages,
  useApiDeleteAlertBannerMessage,
} from "../../../../hooks/queries/alertBannerMessageContext";
import useToastContext from "../../../../hooks/toastHook";
import { getDate } from "../../../../shared/helper";
import { Icon } from "../../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import { useApiCustomers } from "../../../../hooks/queries/customersContext";
import { AlertBannerMessage } from "../../../../types/AlertBannerMessage";

type Props = {
  setShowDismissedUsers: Dispatch<SetStateAction<boolean>>;
  setSelectedAlert: Dispatch<SetStateAction<AlertBannerMessage | undefined>>;
};

export const AlertMessagesTable = (props: Props) => {
  const { setShowDismissedUsers, setSelectedAlert } = props;
  const { data: alertBannerMessages, refetch } = useApiAlertBannerMessages();
  const { mutate: deleteAlert } = useApiDeleteAlertBannerMessage();
  const { data: customers } = useApiCustomers();
  const addToast = useToastContext();
  const theme = useContext(ThemeContext);

  const onSelectedAlert = (alert: AlertBannerMessage) => {
    setSelectedAlert(alert);
    setShowDismissedUsers(true);
  };

  const getCustomerNames = (alertBannerMessage: AlertBannerMessage) => {
    const customerNames = customers
      ?.filter((c) => alertBannerMessage.customers?.includes(c.id))
      .map((c) => c.name);
    console.log(alertBannerMessage.customers);

    return customerNames?.join(", ");
  };

  const columns: Column[] = [
    {
      title: "Alert Preview",
      key: "alert",
      minWidth: "50%",
      maxWidth: "50%",
      cell: (row) => (
        <AlertBanner
          message={row.alert.message_content}
          type={row.alert.type}
          previewMode
        />
      ),
    },
    {
      title: "Dismissed By",
      key: "seen",
      centerCell: true,
      cell: (row) => (
        <BodyRegular className="text-center w-100">
          {row.alert.count_dismissed} / {row.alert.count_total_receivers}
        </BodyRegular>
      ),
    },
    {
      title: "Creator",
      key: "creator",
      centerCell: true,
      cell: (row) => (
        <BodyRegular className="text-center w-100">
          {row.alert.creator
            ? `${row.alert.creator["first_name"]} ${row.alert.creator["last_name"]}`
            : "N/A"}
        </BodyRegular>
      ),
    },
    {
      title: "Created At",
      key: "created",
      centerCell: true,
      cell: (row) => (
        <BodyRegular className="text-center w-100">
          {row.alert.created_at ? getDate(row.alert.created_at) : "N/A"}
        </BodyRegular>
      ),
    },
    {
      title: "Customers",
      key: "customers",
      minWidth: "20%",
      maxWidth: "20%",
      centerCell: true,
      cell: (row) => (
        <BodyRegular className="text-center w-100">
          {row.alert ? getCustomerNames(row.alert) : "N/A"}
        </BodyRegular>
      ),
    },
    {
      title: "Delete Alert",
      key: "delete",
      centerCell: true,
      cell: (row) => (
        <IconButton
          iconName="dismissed"
          size="medium"
          dataTestId={`alert-delete-${row.id}`}
          onClick={() => {
            deleteAlert({
              alertBannerMessageData: row.alert,
              alertBannerMessageId: row.alert.id,
              onSuccessCallback: () => {
                addToast({
                  type: "success",
                  message: "Alert message has been deleted",
                });
                refetch();
              },
              onErrorCallback: () =>
                addToast({
                  type: "error",
                  message: "Failed to delete alert message",
                }),
            });
          }}
        />
      ),
    },
    {
      title: "",
      key: "dismissed_users",
      isRequired: true,
      maxWidth: "50px",
      cell: (row) => (
        <IconButton
          iconName="chevronRight"
          size="small"
          onClick={() => {
            onSelectedAlert(row.alert);
          }}
        />
      ),
    },
  ];

  const rows = alertBannerMessages
    ? alertBannerMessages.map((alertBannerMessage, idx) => ({
        idx,
        alert: alertBannerMessage,
      }))
    : [];

  return (
    <>
      <Flex column gap="16px">
        <Box>
          <Table columns={columns} rows={rows} />
        </Box>
        {rows.length === 0 && (
          <Flex justify="center" gap="16px" padding="16px">
            <Icon name={"wasp"} color={theme.primary} />
            <HeaderMain>No Alerts Yet!</HeaderMain>
          </Flex>
        )}
      </Flex>
    </>
  );
};
