import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
  updateContext,
} from "./utils";
import { bitAfterNow, getQueryParams } from "../../shared/helper";
import {
  createItem,
  deleteItem,
  getPage,
  getSingleItem,
  updateItem,
} from "./sdk";
import { WhatsNew, WhatsNewPage, WhatsNewParams } from "../../types/WhatsNew";

const key = "whats-new";

// All possible attributes that can pass to API call

export interface WhatsNewContext {
  whatsNewId: number;
  whatsNewData: WhatsNewParams;
  onSuccessCallback?: (data?: WhatsNew) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiSingleWhatsNew = (
  enabled: boolean,
  id: number | undefined
) =>
  useQuery<WhatsNew | undefined, Error>({
    queryKey: [key, id],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async (): Promise<WhatsNew | undefined> =>
      getSingleItem(key, id?.toString() || "").then((data) =>
        data ? (data as WhatsNew) : undefined
      ),
  });

export const useApiWhatsNewPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useInfiniteQuery<WhatsNewPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<WhatsNewPage> =>
      getPage(key, { ...filters, page: pageParam }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiCreateWhatsNew = () => {
  const queryClient = useQueryClient();
  return useMutation<WhatsNew, Error, WhatsNewParams>({
    mutationKey: [key],
    mutationFn: async (whatsNew): Promise<WhatsNew> =>
      await createItem(key, whatsNew),
    onError: (error) => console.log(`rolling back optimistic update. ${error}`),
    onSuccess: (data, { onSuccessCallback }) => {
      updateContext({ data, queryKey: [key, { id: data?.id }], queryClient });
      onSuccessCallback && onSuccessCallback(data);
    },
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiUpdateWhatsNew = () => {
  // api/v1/findings-comments/:id/
  const queryClient = useQueryClient();
  return useMutation<WhatsNew, Error, WhatsNewContext>({
    mutationKey: [key, "update"],
    mutationFn: async ({ whatsNewData, whatsNewId }): Promise<WhatsNew> =>
      await updateItem(key, whatsNewId, whatsNewData),
    onSuccess: (data: WhatsNew, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
  });
};

export const useApiDeleteWhatsNew = () => {
  const queryClient = useQueryClient();
  return useMutation<undefined, Error, WhatsNewContext>({
    mutationKey: [key],
    mutationFn: async ({ whatsNewId }: WhatsNewContext): Promise<any> =>
      await deleteItem(key, {}, whatsNewId),
    // onSuccess: (data, variables) =>
    onSuccess: (data: undefined, { whatsNewId, onSuccessCallback }) => {
      removeItemFromContext({
        data,
        queryKey: [key, { id: whatsNewId }],
        queryClient,
      });
      onSuccessCallback && onSuccessCallback();
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};
