import React from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  LabelBold,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { RiskScoreHistory } from "../../../../types/RiskScoreHistory";
import { RiskScoreComponentBadge } from "./RiskScoreComponentBadge";

type Props = {
  latestRiskHistory: RiskScoreHistory | undefined;
};

export const RiskScoreTooltip = ({ latestRiskHistory }: Props) => {
  return (
    <Flex
      column
      gap="16px"
      style={{
        maxWidth: "238px",
        textTransform: "none",
      }}
    >
      <LabelBold>Risk Score Calculation</LabelBold>
      <LabelRegular>
        Weighted calculation based on vulnerability severity (low, medium, high,
        critical) and high/critical SLA violations, prioritizing severity.
      </LabelRegular>
      <SeparatorHorizontal />
      <Flex column gap="8px">
        <Flex gap="8px" justify="between" align="center">
          <LabelRegular>Critical issues</LabelRegular>
          <RiskScoreComponentBadge
            componentType="critical_findings"
            componentWeight={latestRiskHistory?.criticals_weight || 0}
          />
        </Flex>
        <Flex gap="8px" justify="between" align="center">
          <LabelRegular>Breached SLA</LabelRegular>
          <RiskScoreComponentBadge
            componentType="breached_sla"
            componentWeight={latestRiskHistory?.breached_sla_weight || 0}
          />
        </Flex>
        <Flex gap="8px" justify="between" align="center">
          <LabelRegular>Amount of issues</LabelRegular>
          <RiskScoreComponentBadge
            componentType="amount_findings"
            componentWeight={latestRiskHistory?.amount_findings_weight || 0}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
