import { Flex } from "../../../components/layouts/flex/Flex";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import { useApiScanners } from "../../../hooks/queries/scannersContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import { ScansFilters } from "./AsmManagement";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { LabelBold } from "../../../components/elements/typography/Typography";
import { capitalize } from "../../../shared/helper";
import { useState } from "react";

type Props = {
  filters: ScansFilters;
  setFilters: (filters: ScansFilters) => void;
  refetch: () => void;
};

export const ScanEventsFilters = (props: Props) => {
  const { filters, setFilters, refetch } = props;
  const { data: me } = useApiMe();
  const { data: customers } = useApiCustomers();
  const { data: scanners } = useApiScanners(me?.customer.id);

  const onChange = (column: string, value: string | number) => {
    const tempFilters = filters;
    tempFilters[column] = value;
    setFilters(tempFilters);
  };

  const statusOptions = [
    { value: "done,running,error,aborted,created", label: "All" },
    { value: "done", label: "Done" },
    { value: "running", label: "Running" },
    { value: "error", label: "Error" },
    { value: "aborted", label: "Aborted" },
    { value: "created", label: "Created" },
  ];

  const [selectedStatus, setSelectedStatus] = useState<Option>(
    statusOptions.find((opt) => opt.value === filters.customer_id) ||
      statusOptions[0]
  );

  const customerOptions: Option[] =
    customers?.map((c) => ({ label: c.name, value: c.id })) || [];

  const [selectedCustomer, setSelectedCustomer] = useState<Option>(
    customerOptions.find((opt) => opt.value === filters.customer_id) || {
      value: 0,
      label: "Select Customer",
    }
  );

  const scannersOptions =
    scanners?.map((s) => ({ label: capitalize(s.name), value: s.name })) || [];

  const [selectedScanner, setSelectedScanner] = useState<Option>(
    scannersOptions.find((opt) => opt.value === filters.scanner) || {
      value: 0,
      label: "Select Scanner",
    }
  );

  return (
    <Flex align="center" gap="16px">
      <LabelBold>Filters</LabelBold>
      <Dropdown
        value={selectedCustomer}
        placeholder="Select Customer"
        options={customerOptions}
        onChange={(opt) => {
          if (!opt) return;
          setSelectedCustomer({ label: opt.label, value: opt.value });
          onChange("customer_id", opt?.value || "");
        }}
        variant="border"
      />
      <Dropdown
        value={selectedScanner}
        placeholder="Select Scanner"
        options={scannersOptions}
        onChange={(opt) => {
          if (!opt) return;
          setSelectedScanner({ label: opt.label, value: opt.value });
          onChange("scanner", opt?.value || "");
        }}
        variant="border"
      />
      <Dropdown
        value={selectedStatus}
        placeholder="Select Status"
        options={statusOptions}
        onChange={(opt) => {
          if (!opt) return;
          setSelectedStatus({ label: opt.label, value: opt.value });
          onChange("status", opt?.value || "");
        }}
        variant="border"
      />
      <TextButton onClick={refetch} label="Apply Filter" />
    </Flex>
  );
};
