import React from "react";
import { Loading } from "../../../components/elements/loading/Loading";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";

type Props = {};

export const FindingCommentsSkeleton = (props: Props) => {
  return (
    <div className="d-flex flex-column gap-24">
      <div className="d-flex gap-8 align-items-center">
        <SkeletonLoading height="24px" width="24px" />
        <SkeletonLoading height="24px" width="200px" />
      </div>
      <div className="d-flex gap-8 align-items-center justify-content-between">
        <SkeletonLoading height="48px" width="48px" />
        <SkeletonLoading height="48px" width="calc(100% - 150px)" />
        <SkeletonLoading height="48px" width="48px" />
      </div>
      <Loading />
    </div>
  );
};
