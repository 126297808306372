import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Avatar } from "../../elements/avatar/Avatar";
import { Icon } from "../../elements/icon/Icon";
import { InputText } from "../../elements/input/textInput/InputText";
import { ToggleTheme } from "../../elements/toggleTheme/ToggleTheme";
import {
  BodyBold,
  HeaderSecondary,
} from "../../elements/typography/Typography";
import { AppContext } from "../../../App";
import { ProfileMenu } from "./ProfileMenu";
import { useNavigate } from "react-router";
import { Me } from "../../../types/Me";
import CustomerSelect from "./CustomerSelect";
import { useDebounceCallback, useScreenWidth } from "../../../hooks/utilsHooks";
import { useApiGlobalSearch } from "../../../hooks/queries/globalSearchContext";
import { GlobalSearchResultsMenu } from "./GlobalSearchResultsMenu";
import { emptyGlobalSearchData } from "../../../types/GlobalSearchData";
import { Flex } from "../flex/Flex";
import { SeparatorVertical } from "../../elements/separators/SeparatorVertical";
import { Mixpanel } from "../../../shared/mixpanel";
import { TryWaspButton } from "../../../features/welcome/register/TryWaspButton";
import { IS_DEMO_ENV } from "../../../hooks/queries/utils";
import { SCREEN_MOBILE_WIDTH } from "../../../shared/consts";
import { NotificationBellButton } from "../../../features/notifications/NotificationBellButton";
import { NotificationMenu } from "../../../features/notifications/NotificationMenu";
import { useApiNotificationsCountUnread } from "../../../features/notifications/context/notificationReceiverContext";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { WhatsNewModal } from "../../../features/admin/whatsNew/WhatsNewModal";

type Props = {
  me: Me | undefined;
};

export const TopNavbar = (props: Props) => {
  const { me } = props;
  const theme = useContext(ThemeContext);
  const isSuperuser = useIsSuperuser();
  const { toggleTheme } = useContext(AppContext);
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  const [searchText, setSearchText] = useState("");
  const { data: globalSearchData, isFetching } = useApiGlobalSearch(searchText);

  const showTourOnFirstLogin = localStorage.getItem("show_tour");

  const [showWhatsNewModal, setShowWhatsNewModal] = useState(
    (me?.has_unseen_whats_new && !showTourOnFirstLogin) || false
  );

  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showSearchMenu, setShowSearchMenu] = useState(false);

  const defaultTheme = window.localStorage.getItem("wasp-theme") || "light";
  const [isDarkTheme, setIsDarkTheme] = useState(defaultTheme === "dark");

  const [showNotificationMenu, setShowNotificationMenu] =
    useState<boolean>(false);
  const { data: notificationsCount } = useApiNotificationsCountUnread();

  const customerName = me?.customer?.name || "N/A";
  const userName = me?.name || "N/A";

  const handleSearch = useDebounceCallback((e) => {
    setSearchText(e.target.value);
    if (!!e.target.value)
      Mixpanel.track("Global Search", { query: e.target.value });
  }, 500);

  return (
    <div
      style={{
        width: "100%",
        height: "72px",
        display: "fixed",
        position: "fixed",
        zIndex: 100,
        left: 0,
        top: 0,
        backgroundColor: theme.navbar,
        padding: "16px 10px 16px 24px",
      }}
      id="top-navbar"
    >
      <Flex align="center" justify="between" gap="8px" h100>
        <Flex align="center" gap="24px">
          <Flex onClick={() => navigate("dashboard")} className="pointer">
            <Icon name="wasp" color={theme.topNavbarIconColor} size={54} />
          </Flex>
          {me && (!isMobile || isSuperuser) ? (
            <>
              {isSuperuser ? (
                <CustomerSelect me={me} />
              ) : (
                <BodyBold style={{ color: theme.white100 }}>
                  {customerName}
                </BodyBold>
              )}

              {!isMobile && (
                <Flex align="center" gap="24px">
                  <HeaderSecondary color={theme.white100}>
                    {me?.customer?.license?.type === "trial" && "Trial"}
                    {IS_DEMO_ENV && "Demo"}
                  </HeaderSecondary>
                  {IS_DEMO_ENV && <TryWaspButton />}
                </Flex>
              )}
            </>
          ) : (
            <></>
          )}
        </Flex>

        <Flex align="center" gap="24px">
          {!isMobile && (
            <>
              {/* WHATS NEW IN WASP */}
              <BodyBold
                style={{ color: theme.white100, cursor: "pointer" }}
                onClick={() => {
                  setShowWhatsNewModal(true);
                  Mixpanel.track("Whats New");
                }}
              >
                What's new
              </BodyBold>
              {showWhatsNewModal && (
                <WhatsNewModal onClose={() => setShowWhatsNewModal(false)} />
              )}

              {/* GLOBAL SEARCH  */}
              <Flex align="center" gap="24px" style={{ position: "relative" }}>
                <InputText
                  data-tut="global-search"
                  onChange={(e) => {
                    handleSearch(e);
                    setShowSearchMenu(!!e.target.value);
                  }}
                  placeholder="Search"
                  iconName="search"
                  inverted
                  width="290px"
                />
                {showSearchMenu && (
                  <GlobalSearchResultsMenu
                    globalSearchData={globalSearchData || emptyGlobalSearchData}
                    isFetching={isFetching}
                    onClose={() =>
                      showSearchMenu ? setShowSearchMenu(false) : {}
                    }
                  />
                )}
              </Flex>
              {/* NOTIFICATIONS */}
              <Flex column>
                <NotificationBellButton
                  onClick={() => {
                    Mixpanel.track("Notifications - Bell Clicked");
                    setShowNotificationMenu(!showNotificationMenu);
                  }}
                  countUnread={notificationsCount?.count_unread}
                />
                {showNotificationMenu && (
                  <NotificationMenu
                    onClose={() => setShowNotificationMenu(false)}
                    showNotificationMenu={showNotificationMenu}
                  />
                )}
              </Flex>

              <SeparatorVertical
                height="40px"
                style={{ color: theme.white30 }}
              />

              {/* THEME */}
              <Flex>
                <ToggleTheme
                  isDark={isDarkTheme}
                  onChange={(checked) => {
                    setIsDarkTheme(checked);
                    toggleTheme(checked ? "dark" : "light");
                  }}
                />
              </Flex>
            </>
          )}

          {/* PROFILE MENU */}

          <Flex
            align="center"
            gap="8px"
            w100
            className="pointer"
            data-testid="profile-menu-btn"
            onClick={() => setShowProfileMenu((state) => !state)}
            style={{
              position: "relative",
              width: "200px",
            }}
          >
            <Avatar imageSrc={me?.avatar_url} />
            <BodyBold style={{ color: theme.white100 }}>{userName}</BodyBold>
            <Icon
              name={showProfileMenu ? "chevronUp" : "chevronDown"}
              color={theme.white100}
              size={24}
            />
            {showProfileMenu && <ProfileMenu me={me} />}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};
