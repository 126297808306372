export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file); // Read the file as a data URL (base64)
    reader.onload = () => {
      const result = reader.result as string;
      // Extract the base64 part after the comma
      const base64String = result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const blobToFileAndDownload = (blob: Blob, fileName: string): void => {
  // Create a file from the blob
  const file = new File([blob], fileName);

  // Create a URL for the file and trigger download
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a); // Append the link to the document
  a.click(); // Trigger the click event to start download
  document.body.removeChild(a); // Remove the link after download
  URL.revokeObjectURL(url); // Revoke the object URL to free up memory
};

export const base64ToFileAndDownload = (
  base64: string,
  fileName: string
): void => {
  // Remove any extra data from the base64 string (e.g., data:image/png;base64, part)
  const base64Data = base64.replace(/^data:.+;base64,/, "");

  // Convert the base64 string to a binary string
  const byteString = atob(base64Data);

  // Create an array of 8-bit unsigned integers
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  // Create a blob from the array of bytes
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/octet-stream" });

  // Create a file from the blob
  const file = new File([blob], fileName);

  // Create a URL for the file and trigger download
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a); // Append the link to the document
  a.click(); // Trigger the click event to start download
  document.body.removeChild(a); // Remove the link after download
  URL.revokeObjectURL(url); // Revoke the object URL to free up memory
};
