import { useContext, useState } from "react";
import { Modal } from "../../../components/elements/modal/Modal";
import { ReportsSelector } from "./ReportsSelector";
import {
  ProjectRequestParams,
  useApiCreateProjectRequest,
} from "../../../hooks/queries/projectRequestsContext";
import { defaultProjectRequestFormValues } from "./ProjectScheduler";
import { ProjectRangeCalendar } from "./ProjectRangeCalendar";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  HeaderSecondary,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { TextArea } from "../../../components/elements/input/textArea/TextArea";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { Link } from "react-router-dom";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import { getIsoString } from "../../../shared/helper";
import { Mixpanel } from "../../../shared/mixpanel";
import { Illustration } from "../../../components/elements/illustrations/Illustration";

type Props = {
  onClose: () => void;
};

export const ExpressPtModal = (props: Props) => {
  const { onClose } = props;
  const theme = useContext(ThemeContext);
  const [formValues, setFormValues] = useState<ProjectRequestParams>(
    defaultProjectRequestFormValues
  );
  const [isSuccess, setIsSuccess] = useState(false);

  const { mutate: createRequest, isLoading } = useApiCreateProjectRequest();

  const applyForm = () => {
    Mixpanel.track("Submit button");
    createRequest({
      ...formValues,
      end_date: getIsoString(formValues.end_date),
      start_date: getIsoString(formValues.start_date),
      status: "pending",
      onSuccessCallback: () => setIsSuccess(true),
    });
  };

  return (
    <Modal
      width="760px"
      onClose={onClose}
      keepOpenOnOutsideClick={true}
      header={
        <Flex align="center" gap="8px">
          <Icon name="export" size={32} color={theme.primary} />
          <HeaderSecondary>Express Test</HeaderSecondary>
        </Flex>
      }
    >
      {isSuccess ? (
        <Flex column gap="24px" align="center">
          {/* <img src={SuccessSvg} alt="ok" width={414} /> */}
          <Illustration name="success" />
          <HeaderSecondary>
            Congratulations on scheduling a test!
          </HeaderSecondary>
          <MainButton label="OK" onClick={onClose} size="medium" />
        </Flex>
      ) : (
        <Flex column gap="24px">
          <Flex column gap="8px">
            <HeaderSubBold>Reports Types</HeaderSubBold>
            <ReportsSelector
              mainFormValues={formValues}
              setMainFormValues={setFormValues}
            />
          </Flex>
          <Flex column gap="8px">
            <HeaderSubBold>Schedule Dates</HeaderSubBold>
            <ProjectRangeCalendar
              mainFormValues={formValues}
              setMainFormValues={setFormValues}
            />
          </Flex>

          <Flex column gap="8px">
            <HeaderSubBold>PT Agenda</HeaderSubBold>
            <TextArea
              placeholder="Please write your PT agenda"
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  test_settings: {
                    ...prev.test_settings,
                    pt_agenda: e.target.value,
                  },
                }))
              }
              value={formValues.test_settings.pt_agenda}
              width="100%"
            />
          </Flex>

          <Flex column gap="8px">
            <HeaderSubBold>Add General Notes</HeaderSubBold>
            <TextArea
              placeholder="Feel free to provide our notes in this space"
              width="100%"
              value={formValues.test_settings.general_notes}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  test_settings: {
                    ...prev.test_settings,
                    general_notes: e.target.value,
                  },
                }))
              }
            />
          </Flex>
          <Flex justify="between">
            <Link
              to="/projects/scheduler"
              style={{ textDecoration: "none", width: "100%" }}
            >
              <TextButton
                label="Change to Custom Test"
                onClick={() => {
                  Mixpanel.track("Change to Custom Test button");
                }}
              />
            </Link>
            <MainButton
              label="Submit"
              onClick={applyForm}
              inProgress={isLoading}
              disabled={isLoading}
              size="medium"
            />
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};
