import { SeverityDropdown } from "../../../components/composed/severityDropdown/SeverityDropdown";
import { SimpleCheckbox } from "../../../components/elements/checkbox/SimpleCheckbox";
import { Column, Table } from "../../../components/elements/table/Table";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";
import {
  NotificationChannel,
  NotificationSettings,
} from "../../../types/Notifications";
import { SEVERITIES } from "../../../shared/consts";
import {
  BodyRegular,
  HeaderSub,
} from "../../../components/elements/typography/Typography";
import { Box } from "../../../components/elements/box/Box";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

type Props = {
  formValues: any;
  updateSettings: ({
    channel,
    values,
  }: {
    channel: NotificationChannel;
    values: NotificationSettings;
  }) => void;
};

export const FindingNotificationsSettingsTable = (props: Props) => {
  const { formValues, updateSettings } = props;
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();

  const handleChange = (
    value: boolean | number,
    field: string,
    channel: NotificationChannel
  ) => {
    const values = { ...formValues[channel], [field]: value };
    updateSettings({ channel, values });
  };

  const dropdownWidth = "120px";

  const columns: Column[] = [
    {
      title: "",
      key: "title",
      minWidth: "100px",
    },
    {
      title: "In App",
      key: "inApp",
      maxWidth: "360px",
      cell: (row) => (
        <Flex align="center" gap="16px">
          <SimpleCheckbox
            isDisabled={formValues.inApp.is_disabled}
            isChecked={row.inApp}
            onChange={(state) => handleChange(!state, row.alertType, "inApp")}
          />
          <Flex align="center" gap="8px" flexWrap>
            <BodyRegular style={!row.inApp ? { color: theme.textSub } : {}}>
              Minimum Severity
            </BodyRegular>
            <SeverityDropdown
              width={dropdownWidth}
              disabled={!row.inApp || formValues.inApp.is_disabled}
              onChange={(opt) =>
                opt?.value &&
                handleChange(
                  parseInt(`${opt.value}`),
                  row.alertType.replace("on_new_", "") + "_severity",
                  "inApp"
                )
              }
              value={{
                label: SEVERITIES[row.inAppSeverity],
                value: row.inAppSeverity,
              }}
            />
          </Flex>
        </Flex>
      ),
    },
    {
      title: "Email",
      key: "email",
      maxWidth: "360px",
      cell: (row) => (
        <Flex align="center" gap="16px">
          <SimpleCheckbox
            isDisabled={formValues.email.is_disabled}
            isChecked={row.email}
            onChange={(state) => handleChange(!state, row.alertType, "email")}
          />
          <Flex align="center" gap="8px" flexWrap>
            <BodyRegular style={!row.email ? { color: theme.textSub } : {}}>
              Minimum Severity
            </BodyRegular>
            <SeverityDropdown
              width={dropdownWidth}
              disabled={!row.email || formValues.email.is_disabled}
              onChange={(opt) =>
                opt?.value &&
                handleChange(
                  parseInt(`${opt.value}`),
                  row.alertType.replace("on_new_", "") + "_severity",
                  "email"
                )
              }
              value={{
                label: SEVERITIES[row.emailSeverity],
                value: row.emailSeverity,
              }}
            />
          </Flex>
        </Flex>
      ),
    },
  ];

  if (me?.can_manage_customer) {
    columns.push({
      title: "Slack",
      key: "slack",
      maxWidth: "360px",
      cell: (row) => (
        <Flex align="center" gap="16px">
          <SimpleCheckbox
            isDisabled={formValues.slack.is_disabled}
            isChecked={row.slack}
            onChange={(state) => handleChange(!state, row.alertType, "slack")}
          />
          <Flex align="center" gap="8px" flexWrap>
            <BodyRegular style={!row.slack ? { color: theme.textSub } : {}}>
              Minimum Severity
            </BodyRegular>
            <SeverityDropdown
              width={dropdownWidth}
              disabled={!row.slack || formValues.slack.is_disabled}
              onChange={(opt) =>
                opt?.value &&
                handleChange(
                  parseInt(`${opt.value}`),
                  row.alertType.replace("on_new_", "") + "_severity",
                  "slack"
                )
              }
              value={{
                label: SEVERITIES[row.slackSeverity],
                value: row.slackSeverity,
              }}
            />
          </Flex>
        </Flex>
      ),
    });
  }

  const rows: any[] = [
    {
      title: "PT finding was added by researcher",
      alertType: "on_new_finding",
      inApp: formValues.inApp.on_new_finding,
      email: formValues.email.on_new_finding,
      slack: formValues.slack.on_new_finding,
      inAppSeverity: formValues.inApp.finding_severity,
      emailSeverity: formValues.email.finding_severity,
      slackSeverity: formValues.slack.finding_severity,
    },
    {
      title: "Automated PT finding was added",
      alertType: "on_new_auto_finding",
      inApp: formValues.inApp.on_new_auto_finding,
      email: formValues.email.on_new_auto_finding,
      slack: formValues.slack.on_new_auto_finding,
      inAppSeverity: formValues.inApp.auto_finding_severity,
      emailSeverity: formValues.email.auto_finding_severity,
      slackSeverity: formValues.slack.auto_finding_severity,
    },
  ];

  return (
    <Box>
      <Flex column gap="12px">
        <HeaderSub>Notifications on New Findings</HeaderSub>
        <BodyRegular>
          You can set the minimum severity so you will not get notifications on
          findings below the selected severity
        </BodyRegular>
        <Table columns={columns} rows={rows} />
      </Flex>
    </Box>
  );
};
