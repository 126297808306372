import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { CircularProgressBar } from "../../../../components/elements/progressBar/CircularProgressBar";

type Props = {
  totalFindings: number | undefined;
  openFindings: number | undefined;
};

export const calculate_project_progress = (
  totalFindings: number | undefined,
  openFindings: number | undefined
): number => {
  const closedFindings: number =
    (totalFindings || 0) - (openFindings || 0) || 0;
  const singlePrecision = totalFindings ? 100 / totalFindings : 0;
  return Math.round(singlePrecision * closedFindings);
};

export const ProjectProgress = (props: Props) => {
  const theme = useContext(ThemeContext);
  const { totalFindings, openFindings } = props;
  const value = calculate_project_progress(totalFindings, openFindings);
  const color = value > 50 ? theme.primary : theme.redPrimary;
  const background = value > 50 ? theme.blue100 : theme.red50;
  return (
    <CircularProgressBar background={background} color={color} value={value} />
  );
};
