import React from "react";
import { getDate, getTime } from "../../shared/helper";
import { getDataFromThreadId } from "./utils";
import { SelectionTabButton } from "../../components/elements/button/selectionTab/SelectionTabButton";

type Props = {
  threadId: string;
  selected: boolean;
  onClick: () => void;
};

export const ChatItem = (props: Props) => {
  const { threadId, selected, onClick } = props;
  const { timestamp } = getDataFromThreadId(threadId);
  return (
    <SelectionTabButton
      label={`Chat from ${getDate(new Date(parseInt(timestamp)))} - ${getTime(new Date(parseInt(timestamp)))}`}
      onClick={() => onClick()}
      selected={selected}
    />
  );
};
