import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  HeaderSecondary,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { useApiFindings } from "../../../hooks/queries/findingContext";
import { useApiFindingsCommentsDeltaData } from "../../../hooks/queries/findingsCommentsContext";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { Finding } from "../../../types/Finding";
import { Project } from "../../../types/Project";
import { SCREEN_LAPTOP_WIDTH } from "../../../shared/consts";
import { FindingCardSkeleton } from "../findingsCards/FindingCardSkeleton";
import { FindingCard } from "../findingsCards/FindingCard";
import { getProject } from "../../../shared/findingsHelper";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { Mixpanel } from "../../../shared/mixpanel";
import { useApiFindingLogsLatestFindings } from "../../../hooks/queries/findingsLogsContext";
import { FindingLog } from "../../../types/FindingLog";

type Filters =
  | {
      page_size: number;
      products: number[];
    }
  | {
      page_size: number;
    };

type Props = {
  onSelectedFinding: (finding: Finding) => void;
  onOpenProjectPane: (project: Project) => void;
  selectedProduct?: number | "all";
  show?: boolean;
  setShow?: (show: boolean) => void;
};

export const LastFindings = (props: Props) => {
  const { selectedProduct, onSelectedFinding, onOpenProjectPane } = props;
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isBigLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;

  const defaultFilter = {
    page_size: 5,
  };
  const [findingsFilters, setFindingsFilter] = useState<Filters>(defaultFilter);

  useEffect(() => {
    setFindingsFilter(
      !!selectedProduct && selectedProduct !== "all"
        ? {
            ...defaultFilter,
            products: [selectedProduct],
          }
        : defaultFilter
    ); // eslint-disable-next-line
  }, [selectedProduct]);

  const { data: projects } = useApiProjects({
    with_findings_counts: true,
  });

  const { data: findings, isFetching } = useApiFindings(findingsFilters);

  const { data: findingsLogs, isFetching: isFetchingLogs } =
    useApiFindingLogsLatestFindings(
      { findings: findings?.map((f) => f.id) || [] },
      !!findings?.length
    );
  const { data: unreadComments } = useApiFindingsCommentsDeltaData();

  const getFindingLog = (finding: Finding): FindingLog | undefined => {
    return findingsLogs?.find((fl) => fl.finding === finding.id);
  };

  return (
    <>
      <Flex column gap="8px">
        <Flex>
          <Icon name="notification" size={32} color={theme.primary} />
          <HeaderSecondary className="ms-2">Latest Findings</HeaderSecondary>
        </Flex>
      </Flex>

      <Flex
        column
        gap="8px"
        w100
        padding="16px 0px"
        style={{
          height: "calc(100% - 120px)",
          overflowY: "auto",
        }}
        align="center"
      >
        {!isFetching && findings?.length === 0 && (
          <HeaderSubBold className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
            No recent activity
          </HeaderSubBold>
        )}
        {isFetching && !findings?.length ? (
          <FindingCardSkeleton />
        ) : (
          projects &&
          unreadComments &&
          findings?.map((finding) => (
            <FindingCard
              key={`finding-card-${finding.id}`}
              finding={finding}
              findingLog={getFindingLog(finding)}
              isFetchingLogs={isFetchingLogs}
              project={getProject(finding, projects)}
              hasUnreadComments={unreadComments.some(
                (c) => c.finding_id === finding.id
              )}
              onSelectedFinding={(f: Finding) => {
                onSelectedFinding(f);
                Mixpanel.track("LatestFindings - Open finding", {
                  finding_id: finding.id,
                });
              }}
              onOpenProjectPane={(project: Project) => {
                onOpenProjectPane(project);
                Mixpanel.track("LatestFindings - Open project pane", {
                  project_id: project.id,
                  finding_id: finding.id,
                });
              }}
              isSmall={isBigLaptop}
            />
          ))
        )}
      </Flex>
    </>
  );
};
