import React from "react";
import { IconProps } from "./WaspIcon";

export function InProgressIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9623 6.23276H14.8807V5.75996C14.8807 5.28257 14.691 4.82473 14.3535 4.48717C14.0159 4.1496 13.5581 3.95996 13.0807 3.95996H10.9207C10.4433 3.95996 9.98544 4.1496 9.64787 4.48717C9.3103 4.82473 9.12066 5.28257 9.12066 5.75996V6.23996H6.03906C5.24341 6.23996 4.48035 6.55603 3.91774 7.11864C3.35513 7.68125 3.03906 8.44431 3.03906 9.23996V17.04C3.03906 17.8356 3.35513 18.5987 3.91774 19.1613C4.48035 19.7239 5.24341 20.04 6.03906 20.04H17.9623C18.7579 20.04 19.521 19.7239 20.0836 19.1613C20.6462 18.5987 20.9623 17.8356 20.9623 17.04V9.23276C20.9623 8.43711 20.6462 7.67405 20.0836 7.11144C19.521 6.54883 18.7579 6.23276 17.9623 6.23276ZM10.3207 5.75996C10.3207 5.60083 10.3839 5.44822 10.4964 5.3357C10.6089 5.22317 10.7615 5.15996 10.9207 5.15996H13.0807C13.2398 5.15996 13.3924 5.22317 13.5049 5.3357C13.6174 5.44822 13.6807 5.60083 13.6807 5.75996V6.23996H10.3207V5.75996ZM6.03426 7.43996H17.9575C18.4349 7.43996 18.8927 7.6296 19.2303 7.96717C19.5678 8.30473 19.7575 8.76257 19.7575 9.23996V14.952H4.23906V9.23276C4.23906 8.75537 4.4287 8.29753 4.76627 7.95997C5.10384 7.6224 5.56167 7.43276 6.03906 7.43276L6.03426 7.43996ZM17.9623 18.84H6.03906C5.56167 18.84 5.10384 18.6503 4.76627 18.3128C4.4287 17.9752 4.23906 17.5173 4.23906 17.04V16.1412H11.4007V16.5192C11.4007 16.6783 11.4639 16.8309 11.5764 16.9434C11.6889 17.0559 11.8415 17.1192 12.0007 17.1192C12.1598 17.1192 12.3124 17.0559 12.4249 16.9434C12.5374 16.8309 12.6007 16.6783 12.6007 16.5192V16.1412H19.7623V17.04C19.7623 17.5173 19.5726 17.9752 19.2351 18.3128C18.8975 18.6503 18.4397 18.84 17.9623 18.84Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
