import styled, { keyframes } from "styled-components";

type Props = {
  width: string;
  height: string;
};

export const skeletonLoadingAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const SkeletonLoading = styled.div<Props>`
  background-color: ${(props) => props.theme.black500};
  border-radius: 8px;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  animation: ${skeletonLoadingAnimation} 1.5s ease-in-out infinite;
`;
