import { useState } from "react";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { SingleValue } from "react-select";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { objectsToOptions } from "../../../shared/formUtils";
import { Box } from "../../../components/elements/box/Box";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiSecurityBenchmark } from "../../../hooks/queries/findingContext";
import { BenchmarkMeter } from "./BenchmarkMeter";
import { Mixpanel } from "../../../shared/mixpanel";

const defaultProductOption = { label: "All Products", value: "all" };

export const RiskScoreBenchmark = () => {
  const [selectedProduct, setSelectedProduct] =
    useState<SingleValue<Option>>(defaultProductOption);

  const filters =
    selectedProduct?.value !== "all" ? { product: selectedProduct?.value } : {};

  const { data, isLoading, isRefetching } = useApiSecurityBenchmark(filters);

  const { data: products } = useApiProducts();

  const productsOptions = !!products?.length
    ? [defaultProductOption, ...objectsToOptions(products)]
    : [];

  const handleProductChange = (opt: SingleValue<Option>) => {
    setSelectedProduct(opt);
    Mixpanel.track("Insights - Security Benchmark - filter by product");
  };

  return (
    <Box>
      <Flex w100 column gap="48px" justify="center" style={{ padding: "24px" }}>
        <Flex justify="between">
          <HeaderSecondary>Security Benchmark</HeaderSecondary>
          <Dropdown
            variant="outline"
            value={selectedProduct}
            options={productsOptions}
            onChange={handleProductChange}
            queryStatus={isLoading || isRefetching ? "loading" : undefined}
          />
        </Flex>

        <BenchmarkMeter
          label="Risk Score"
          isPercent
          value={data?.risk_score?.current || 0}
          optimum={data?.risk_score?.avg || 0}
        />

        <BenchmarkMeter
          label="Protection Coverage"
          isPercent
          isPositiveTrend // fix the trend to reflect high score is good
          value={data?.coverage_score?.current || 0}
          optimum={data?.coverage_score?.avg || 0}
        />

        <BenchmarkMeter
          label="MTTR"
          isDays
          value={data?.mttr?.current || 0}
          optimum={data?.mttr?.avg || 0}
        />

        <BenchmarkMeter
          label="% of Open Findings"
          isPercent
          value={data?.open_findings_percent?.current || 0}
          optimum={data?.open_findings_percent?.avg || 0}
        />

        <BenchmarkMeter
          label="Average Finding Age"
          isDays
          value={data?.open_avg_age?.current || 0}
          optimum={data?.open_avg_age?.avg || 0}
        />
      </Flex>
    </Box>
  );
};
