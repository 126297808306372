import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { MITIGATION } from "../../../../shared/consts";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
};

export const MitigationComplexityItem = (props: Props) => {
  const { finding, noSeparator = false, width } = props;
  return (
    <Section
      title="Mitigation Complexity"
      noSeparator={noSeparator}
      width={width}
    >
      <BodyRegular className="text-truncate w-100">
        {MITIGATION[finding?.mitigation_complexity || 0]}
      </BodyRegular>
    </Section>
  );
};
