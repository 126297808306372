import Graph from "graphology";
import Sigma, { Camera } from "sigma";
import ForceSupervisor from "graphology-layout-force/worker";
import getNodeProgramImage from "sigma/rendering/webgl/programs/node.image";
import { Filter } from "../../../../types/AssetsView";
import { getItems } from "../../../../hooks/queries/sdk";
import { Asset, ThirdPartyConnections } from "../../../../types/Asset";
import { getDomain } from "tldts";
import { nodeType } from "./AssetGraphEngine";

export class AssetConnectionsGraphEngine {
  filters: Filter[] = [];
  waitingFilters: Filter[] | null;
  clickedNode: string | null;
  isDragging: boolean;
  isMoving: boolean;
  showCount: number;
  click: number;
  graph: any; //Graph;
  layout: ForceSupervisor;
  renderer: Sigma | null;
  camera: Camera | null;
  handleNodeSingleClick: (nodeId: number | string, nodeType: nodeType) => void;
  assets: Asset[];
  owners: string[];
  connectionSubDomains: string[];
  relations: string[][] = [];
  theme: any;
  type: string;

  constructor(
    handleNodeSingleClick: (
      nodeId: number | string,
      nodeType: nodeType
    ) => void,
    filters: Filter[]
  ) {
    this.type = "connections";
    this.filters = filters;
    this.waitingFilters = null;
    // State for drag'n'drop
    this.clickedNode = null;
    this.isDragging = false;
    this.isMoving = false;
    this.showCount = 0;
    this.owners = [];
    this.connectionSubDomains = [];
    // Handle double click...
    // credit: https://stackoverflow.com/questions/5497073/how-to-differentiate-single-click-event-and-double-click-event
    this.click = 0;

    this.graph = new Graph();
    // Create the spring layout and start it
    // Docs: https://graphology.github.io/standard-library/layout-force.html#settings
    this.layout = new ForceSupervisor(this.graph, {
      isNodeFixed: (_: any, attr: any) => attr.sticked || attr.highlighted,
      settings: {
        //         attraction ?number 0.0005: importance of the attraction force, that attracts each pair of connected nodes like elastics.
        //          repulsion ?number 0.1: importance of the repulsion force, that attracts each pair of nodes like magnets.
        //          gravity ?number 0.0001: importance of the gravity force, that attracts all nodes to the center.
        //          inertia ?number 0.6: percentage of a node vector displacement that is preserved at each step. 0 means no inertia, 1 means no friction.
        //          maxMove ?number 200: Maximum length a node can travel at each step, in pixel.
        // attraction: 0.00005,
        repulsion: 10, // Increased repulsion
        attraction: 0.00001, // Decreased attraction
        gravity: 0.00001, // Reduced gravity
        maxMove: 20, // Allow nodes to move farther per step
      },
    });
    this.renderer = null;
    this.camera = null;

    //react states vars
    this.handleNodeSingleClick = handleNodeSingleClick;
    this.assets = [];
    // this.layout.start();
  }

  stop() {
    console.log("Stop graph");
    this.layout.kill();
  }

  async startGraph(elementContainerId: string, theme: any) {
    // Retrieve the html document for sigma container
    var container = document.getElementById(elementContainerId);
    if (!container) return;
    container.innerHTML = "";
    // Create the sigma
    // All Settings: https://github.com/jacomyal/sigma.js/blob/339be9ed274fcfb881ddd3585974ea7be46ca7dd/src/settings.ts#L34-L82
    this.renderer = new Sigma(this.graph, container, {
      nodeProgramClasses: {
        image: getNodeProgramImage(),
      },
      renderEdgeLabels: true,
      allowInvalidContainer: true,
      labelColor: { color: theme?.name === "dark" ? "white" : "black" },
    });
    this.camera = this.renderer.getCamera();
    this.theme = theme;
    // will be called on filter changed function
    // this.initRootNodes();

    this.renderer.on("downNode", (e) => {
      this.handleNodeDown(e);
    });

    this.renderer.on("enterNode", ({ node }) => {
      this.handleHoverIn(node);
    });

    this.renderer.on("leaveNode", ({ node }) => {
      this.handleHoverOut(node);
    });

    this.renderer.getMouseCaptor().on("mousemovebody", (e) => {
      this.handleMouseMove(e);
    });

    this.renderer.getMouseCaptor().on("mouseup", (e) => {
      this.handleMouseUp(e);
    });

    this.renderer.getMouseCaptor().on("rightClick", (e) => {
      this.handleMouseUp(e);
    });

    // Disable the auto-scale at the first down interaction
    this.renderer.getMouseCaptor().on("mousedown", () => {
      if (!this.renderer?.getCustomBBox())
        this.renderer?.setCustomBBox(this.renderer?.getBBox());
    });
  }

  async init() {
    await this.initDataFromServer();
    this.initRootNodes();
    if (this.filters.length) {
      await this.applyFilters(this.filters);
    }
  }

  async initDataFromServer() {
    this.showWaitingIcon();
    await getItems("assets/graph", { type: "connections" }).then((data) => {
      this.assets = data.assets;
      console.log("GraphDataProvider is starting precessing data");
      console.log(
        "calculate the number of children and relations for each asset"
      );
      this.preprocessData();
      console.log("GraphDataProvider is ready");
    });
    this.hideWaitingIcon();
  }

  getRootDomain(url: string): string | null {
    return getDomain(url);
  }

  getCorpName(connection?: ThirdPartyConnections): string | null {
    return (
      connection?.tracker_corp || this.getRootDomain(connection?.domain || "")
    );
  }

  preprocessData() {
    this.owners = this.assets
      .map((asset) => asset.third_party_connections)
      .flat()
      .map((connection) => this.getCorpName(connection) || "");
    // remove duplicates
    this.owners = [...new Set(this.owners.filter((o) => o))];

    this.connectionSubDomains = this.assets
      .map((asset) => asset.third_party_connections)
      .flat()
      .map((connection) => connection?.domain || "");

    // remove duplicates
    this.connectionSubDomains = [
      ...new Set(this.connectionSubDomains.filter((s) => s)),
    ];

    this.assets.forEach((asset) =>
      asset.third_party_connections?.forEach((connection) => {
        if (this.getCorpName(connection)) {
          this.relations.push([
            `a${asset.id}`,
            `o${this.getCorpName(connection)}`,
          ]);

          this.relations.push([
            `o${this.getCorpName(connection)}`,
            `s${connection.domain}`,
          ]);
        }
      })
    );
  }

  async initRootNodes() {
    this.graph.clear();

    this.assets.forEach(async (asset) => {
      const nodeId = `a${asset.id}`;
      const totalCount = asset.third_party_connections?.length || 0;
      if (!this.graph.hasNode(nodeId)) {
        this.graph.addNode(nodeId, {
          x: Math.round(Math.random() * 200),
          y: Math.round(Math.random() * 200),
          size: this.assets.length > 10 ? 15 : 30,
          color: this.theme.black400,
          colorFixed: this.theme.black400,
          label: asset.name,
          type: "image",
          image: "/icons/asset-domain.svg",
          imageFixed: "/icons/asset-domain.svg",
          totalCount: totalCount,
        });
      }
    });

    this.owners.forEach(async (owner) => {
      const nodeId = `o${owner}`;
      if (!this.graph.hasNode(nodeId)) {
        this.graph.addNode(nodeId, {
          x: Math.round(Math.random() * 200),
          y: Math.round(Math.random() * 200),
          size: this.owners.length > 10 ? 20 : 40,
          color: this.theme.black400,
          colorFixed: this.theme.black400,
          label: owner,
          type: "image",
          image: "/icons/workplace.png",
          imageFixed: "/icons/workplace.png",
          totalCount: 0,
        });
      }
    });

    this.createEdges();

    this.layout.start();
  }

  showSubdomains(show: boolean) {
    if (show) {
      this.connectionSubDomains.forEach(async (subDomain) => {
        const nodeId = `s${subDomain}`;
        if (!this.graph.hasNode(nodeId)) {
          this.graph.addNode(nodeId, {
            x: Math.round(Math.random() * 200),
            y: Math.round(Math.random() * 200),
            size: this.connectionSubDomains.length > 10 ? 6 : 12,
            color: this.theme.black400,
            colorFixed: this.theme.black400,
            label: subDomain,
            type: "image",
            image: "/icons/sub-domain.png",
            imageFixed: "/icons/sub-domain.png",
            totalCount: 0,
          });
        }
      });
      this.createEdges();
    } else {
      this.connectionSubDomains.forEach(async (subDomain) => {
        const nodeId = `s${subDomain}`;
        if (this.graph.hasNode(nodeId)) {
          this.graph.dropNode(nodeId);
        }
      });
    }
  }

  createEdges() {
    this.relations.forEach(([source, target]) => {
      if (
        this.graph.hasNode(source) &&
        this.graph.hasNode(target) &&
        !this.edgeExists(source, target)
      ) {
        this.graph.addEdge(source, target, {
          size: 2,
          color: this.theme.Foreground80,
        });
      }
    });
  }

  getAssetsByCorp(corpName: string): Asset[] {
    return this.assets.filter((asset) =>
      asset.third_party_connections?.some(
        (connection) => this.getCorpName(connection) === corpName
      )
    );
  }

  onThemeChange(theme: any) {
    this.theme = theme;
    this.renderer?.setSetting("labelColor", {
      color: this.theme?.name === "dark" ? "white" : "black",
    });
  }

  initToolsButtons() {
    const fullScreenBtn = document.getElementById("full-screen-btn");
    const exitFullScreenBtn = document.getElementById("exit-full-screen-btn");
    const zoomInBtn = document.getElementById("zoom-in-btn");
    const zoomOutBtn = document.getElementById("zoom-out-btn");
    const zoomResetBtn = document.getElementById("zoom-reset-btn");
    if (!zoomInBtn || !zoomOutBtn || !zoomResetBtn) return;
    // Bind zoom manipulation buttons
    zoomInBtn.addEventListener("click", () => {
      this.camera?.animatedZoom({ duration: 600 });
    });
    zoomOutBtn.addEventListener("click", () => {
      this.camera?.animatedUnzoom({ duration: 600 });
    });
    zoomResetBtn.addEventListener("click", () => {
      this.camera?.animatedReset({ duration: 600 });
    });

    fullScreenBtn?.addEventListener("click", () => {
      document
        ?.getElementById("graph-component-container")
        ?.requestFullscreen()
        .catch(function (error) {
          // element could not enter fullscreen mode
          // error message
          console.log(error.message);
        });
    });
    exitFullScreenBtn?.addEventListener("click", () => {
      document.exitFullscreen().catch(function (error) {
        // element could not exit fullscreen mode
        // error message
        console.log(error.message);
      });
    });
  }

  handleHoverIn(node: string) {
    if (this.filters.length) return;
    this.renderer?.setSetting("labelColor", { color: "black" });
    var highlightedNodes = [node];
    this.graph.forEachNeighbor(node, (navigator: string) => {
      this.graph.setNodeAttribute(navigator, "highlighted", true);
      highlightedNodes.push(navigator);
    });
    this.graph.forEachNode((n: string) => {
      if (!highlightedNodes.includes(n)) {
        this.graph.setNodeAttribute(n, "color", this.theme.Foreground80);
        this.graph.setNodeAttribute(n, "image", null);
      }
    });
  }

  handleHoverOut(node: string) {
    if (this.filters.length) return;
    this.renderer?.setSetting("labelColor", {
      color: this.theme?.name === "dark" ? "white" : "black",
    });
    this.graph.forEachNeighbor(node, (navigator: string) => {
      this.graph.setNodeAttribute(navigator, "highlighted", false);
    });
    this.graph.forEachNode((n: string) => {
      this.graph.setNodeAttribute(
        n,
        "color",
        this.graph.getNodeAttribute(n, "colorFixed")
      );
      this.graph.setNodeAttribute(
        n,
        "image",
        this.graph.getNodeAttribute(n, "imageFixed")
      );
    });
  }

  // On mouse down on a node
  //  - we enable the drag mode
  //  - save in the dragged node in the state
  //  - highlight the node
  //  - disable the camera so its state is not updated
  handleNodeDown(e: any) {
    this.isDragging = true;
    this.clickedNode = e.node;
    e.preventSigmaDefault();
    this.graph.setNodeAttribute(this.clickedNode, "highlighted", true);
  }

  updateNodePosition(e: any) {
    // Get new position of node
    const pos = this.renderer?.viewportToGraph(e);
    this.graph.setNodeAttribute(this.clickedNode, "x", pos?.x || 0);
    this.graph.setNodeAttribute(this.clickedNode, "y", pos?.y || 0);
  }

  // On mouse move, if the drag mode is enabled, we change the position of the draggedNode
  handleMouseMove(e: any) {
    if (!this.isDragging || !this.clickedNode || !this.isLeftClick(e)) return;
    this.isMoving = true;

    this.updateNodePosition(e);
    // Prevent sigma to move camera:
    e.preventSigmaDefault();
    e.original.preventDefault();
    e.original.stopPropagation();
  }

  isRightClick(e: any) {
    return e.original?.button === 2;
  }

  isLeftClick(e: any) {
    return e.original?.button === 0;
  }

  showLabels(needToShow: boolean) {
    this.renderer?.setSetting("renderLabels", needToShow);
  }

  showWaitingIcon() {
    this.showCount++;
    console.log("show waiting icon", this.showCount);
    const loadingAssetsIcon = document.getElementById("loading-assets-icon");
    if (loadingAssetsIcon) {
      loadingAssetsIcon.style.display = "block";
    }
  }

  hideWaitingIcon() {
    this.showCount--;
    console.log("hide waiting icon", this.showCount);
    if (this.showCount > 0) {
      console.log("Do not hide during fetching");
      return;
    }
    const loadingAssetsIcon = document.getElementById("loading-assets-icon");
    if (loadingAssetsIcon) {
      loadingAssetsIcon.style.display = "none";
      this.finishFetching();
    }
  }

  finishFetching() {
    // finish all fetching
    // if filtersBacklog => call onFilterChange
    // this.setAssetsCount(this.graph.nodes().length);
    if (this.waitingFilters) {
      this.onFilterChange([...this.waitingFilters]);
      this.waitingFilters = null;
    }
  }

  // On mouse up, we reset the auto-scale and the dragging mode
  handleMouseUp(e: any) {
    e.preventSigmaDefault();
    const element = document.getElementById("node-menu");
    if (element?.style) {
      element.style.display = "none";
    }
    if (this.clickedNode) {
      this.graph.removeNodeAttribute(this.clickedNode, "highlighted");
      if (this.isLeftClick(e)) {
        this.updateNodePosition(e);
        this.graph.setNodeAttribute(this.clickedNode, "sticked", true);

        if (!this.isMoving) {
          this.handleSingleAndDoubleClick(this.clickedNode);
        }
      }
      if (this.isRightClick(e)) {
        this.handleSingleClick(this.clickedNode);
      }
    }

    this.isMoving = false;
    this.isDragging = false;
    this.clickedNode = null;
  }

  idFromNodeId(nodeId: string) {
    return parseInt(nodeId.substring(1));
  }

  getChildrenShowingCount(nodeId: string) {
    if (!nodeId.startsWith("a")) {
      return this.graph.neighbors(nodeId).length;
    }

    // case its asset remove the parent (can be only one parent)
    return this.graph.neighbors(nodeId).length - 1;
  }

  getSingleAsset(assetId: number): Asset | undefined {
    return this.assets.find((asset) => asset.id === assetId);
  }

  handleSingleAndDoubleClick(clickedNode: string) {
    this.click++;
    setTimeout(() => {
      if (this.click === 1) {
        this.click = 0;
        this.handleSingleClick(clickedNode);
      }
      this.click = 0;
    }, 300);
    if (this.click === 2) {
      this.click = 0;
      this.handleDoubleClick(clickedNode);
    }
  }

  handleSingleClick = (clickedNode: string) => {
    const element = document.getElementById("node-menu");
    if (element?.style) {
      element.style.display = "none";
    }
    const id = this.idFromNodeId(clickedNode);
    if (clickedNode.startsWith("a")) {
      this.handleNodeSingleClick(id, "asset");
    }
    if (clickedNode.startsWith("o")) {
      this.handleNodeSingleClick(clickedNode.substring(1), "owner");
    }
  };

  handleDoubleClick = (clickedNode: string) => {
    const element = document.getElementById("node-menu");
    if (element?.style) {
      element.style.display = "none";
    }
  };

  removeChildren(clickedNode: string) {
    const parentNode = this.graph.getNodeAttributes(clickedNode)?.parentNode;
    this.graph.neighbors(clickedNode).forEach((n: string) => {
      if (!clickedNode.startsWith("a") || n !== parentNode) {
        this.graph.dropNode(n);
      }
    });
  }

  edgeExists(node1: string, node2: string) {
    return !!this.graph.findEdge(
      (
        edge: any,
        attributes: any,
        source: string,
        target: string,
        sourceAttributes: any,
        targetAttributes: any
      ) =>
        (source === node1 && target === node2) ||
        (source === node2 && target === node1)
    );
  }

  onFilterChange = async (newFilters: Filter[]) => {
    const filtersHasChanged =
      JSON.stringify(this.filters) !== JSON.stringify(newFilters);
    this.filters = newFilters;
    // remove all nodes from graph - inside the initNodes function
    // get root assets (with updated filters)
    if (filtersHasChanged) {
      await this.applyFilters(newFilters);
    }
  };

  async applyFilters(filters: Filter[]) {
    // this.showWaitingIcon();
    // await this.graphDataProvider.updateAssetsFilters(filters);
    // this.hideWaitingIcon();
    // if (!this.graphDataProvider.hasFilters) {
    //   this.initRootNodes();
    // } else {
    //   this.expandAllAppliedFilters();
    //   this.filterAssets(this.graphDataProvider.filteredAssetsIds);
    // }
  }

  searchQuery(query: string) {
    if (query) {
      const lcQuery = query.toLowerCase();
      const suggestions = this.graph
        .nodes()
        .map((n: string) => ({
          id: n,
          label: this.graph.getNodeAttribute(n, "label"),
        }))
        .filter(({ label }: { [key: string]: string }) =>
          label.toLowerCase().includes(lcQuery)
        );

      const neighbors = suggestions
        .map((n: { id: string; label: string }) => this.graph.neighbors(n.id))
        .flat();
      this.renderer?.setSetting("nodeReducer", (node, data) => {
        const res = { ...data };
        if (
          !suggestions
            .map((n: { id: string; label: string }) => n.id)
            .includes(node) &&
          !neighbors.includes(node)
        ) {
          res.hidden = true;
        }
        return res;
      });
    } else {
      this.renderer?.setSetting("nodeReducer", (node, data) => {
        const res = { ...data };
        res.hidden = false;
        return res;
      });
    }
  }

  filterAssets(assetsIds: number[]) {
    if (assetsIds) {
      this.graph.forEachNode((node: string) => {
        this.graph.setNodeAttribute(node, "filtered", false);
        if (assetsIds.includes(this.idFromNodeId(node))) {
          this.graph.setNodeAttribute(node, "filtered", true);
        } else {
          this.graph.setNodeAttribute(node, "filtered", false);
          this.graph.setNodeAttribute(node, "color", this.theme.Foreground80);
          this.graph.setNodeAttribute(node, "image", null);
        }
      });
      // highlight neighbors of all highlighted nodes
      this.graph.forEachNode((node: string) => {
        if (this.graph.getNodeAttribute(node, "filtered")) {
          this.graph.forEachNeighbor(node, (navigator: string) => {
            this.graph.setNodeAttribute(
              navigator,
              "color",
              this.graph.getNodeAttribute(navigator, "colorFixed")
            );
            this.graph.setNodeAttribute(
              navigator,
              "image",
              this.graph.getNodeAttribute(navigator, "imageFixed")
            );
          });
        }
      });
    }
  }

  // drawGroup(positions: any[]) {
  //   console.log("positions", positions);
  //   // Functions to calculate centroid and radius
  //   function calculateCentroid(positions: any) {
  //     let x = 0,
  //       y = 0;
  //     positions.forEach((pos: any) => {
  //       x += pos.x;
  //       y += pos.y;
  //     });
  //     return { x: x / positions.length, y: y / positions.length };
  //   }

  //   function calculateRadius(positions: any) {
  //     const centroid = calculateCentroid(positions);
  //     let maxDist = 0;
  //     positions.forEach((pos: any) => {
  //       const dist = Math.sqrt(
  //         Math.pow(pos.x - centroid.x, 2) + Math.pow(pos.y - centroid.y, 2)
  //       );
  //       if (dist > maxDist) maxDist = dist;
  //     });
  //     return maxDist + 20; // Add some padding for the circle
  //   }

  //   console.log("this.renderer?.getCanvases()", this.renderer?.getCanvases());
  //   const mouseCanvas = this.renderer?.getCanvases()?.edgeLabels;

  //   if (mouseCanvas) {
  //     const ctx = mouseCanvas.getContext("2d");

  //     if (ctx) {
  //       // Clear previous drawings
  //       ctx.clearRect(0, 0, mouseCanvas.width, mouseCanvas.height);

  //       // Set the drawing style
  //       ctx.strokeStyle = "rgba(0, 0, 255, 0.5)";
  //       ctx.lineWidth = 2;
  //       ctx.fillStyle = "rgba(0, 0, 255, 0.1)";

  //       // Your logic for group center and radius
  //       const groupCenter = { x: 100, y: 100 }; // calculateCentroid(positions); // Example center
  //       const radius = 100; // calculateRadius(positions); // Example radius

  //       console.log("groupCenter", groupCenter);
  //       console.log("radius", radius);

  //       // Draw the circle
  //       ctx.beginPath();
  //       ctx.arc(groupCenter.x, groupCenter.y, radius, 0, 2 * Math.PI);
  //       ctx.fill();
  //       ctx.stroke();
  //     } else {
  //       console.error("Unable to get 2D context from the canvas.");
  //     }
  //   } else {
  //     console.error("Mouse canvas is not available.");
  //   }
  // }
}
