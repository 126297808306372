import React, { useId, useContext } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import styled, { ThemeContext } from "styled-components";

type Props = {
  children: JSX.Element[] | JSX.Element;
  content: JSX.Element[] | JSX.Element | string;
  placement?: "top" | "bottom" | "left" | "right";
  isTextTruncate?: boolean;
  isActive?: boolean;
  style?: React.CSSProperties;
};

type PrivateProps = {
  side: string;
};

const StyledTooltip = styled(ReactTooltip)<PrivateProps>`
  .react-tooltip-arrow {
    border-width: 0 0 0 0;
    border-color: ${(props) => props.theme.black500};
    border-style: solid;
    height: 16px;
    width: 16px;
    ${(props) => props.side}: -8px !important;
    transition: 0.4s;
  }
`;

export const Tooltip = (props: Props) => {
  const {
    children,
    content,
    placement = "top",
    isTextTruncate = false,
    isActive = true,
  } = props;

  const id = useId();
  const theme = useContext(ThemeContext);

  const getSide = (): string => {
    if (placement === "right") return "left";
    if (placement === "top") return "bottom";
    if (placement === "bottom") return "top";
    return "right";
  };

  return (
    <>
      <StyledTooltip
        side={getSide()}
        id={id}
        place={placement}
        opacity={1}
        border={`1px solid ${theme.black500}`}
        style={{
          padding: "16px",
          color: theme.textBody,
          background: theme.bg2,
          borderRadius: "8px",
          boxShadow: "0px 8px 40px rgba(123, 123, 123, 0.1)",
          zIndex: 3000,
          visibility: isActive ? "visible" : "hidden",
          ...props.style,
        }}
      >
        {content}
      </StyledTooltip>
      <div
        className={`d-inline-flex ${isTextTruncate && "text-truncate"}`}
        data-tooltip-id={id}
      >
        {children}
      </div>
    </>
  );
};
