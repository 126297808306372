import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Avatar } from "../../../components/elements/avatar/Avatar";
import { Box } from "../../../components/elements/box/Box";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { FormError } from "../../../components/elements/FormsElements/FormError";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { useApiMe, useApiUpdateMe } from "../../../hooks/queries/meContext";
import { useDebounceCallback } from "../../../hooks/utilsHooks";
import useToastContext from "../../../hooks/toastHook";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useTrackPage } from "../../../hooks/trackingHooks";

interface AccountFormValues {
  phone?: string;
  role?: string;
}

const MyProfile = () => {
  const { data: me, refetch } = useApiMe();
  useTrackPage("Settings - My Profile", undefined, me);
  const theme = useContext(ThemeContext);

  const addToast = useToastContext();

  const onSuccessCallback = () => {
    addToast({ message: "Profile updated successfully", type: "success" });
    refetch();
  };

  const onErrorCallback = (error: Error) =>
    addToast({ message: error.message, type: "error" });

  const { mutate: updateMe } = useApiUpdateMe({
    onErrorCallback,
    onSuccessCallback,
  });
  const debouncedUpdateMe = useDebounceCallback((meContext) =>
    updateMe(meContext)
  );

  const [formErrors, setFormErrors] = useState<AccountFormValues>({});
  const [formValues, setFormValues] = useState<AccountFormValues>({
    phone: me?.phone || "",
    role: me?.role,
  });

  const validatePhone = (phone: string): AccountFormValues => {
    let errors: AccountFormValues = {};
    if (phone.substring(0, 1) !== "+")
      errors.phone =
        "Phone number must start with + and international dial code";
    if (/[a-zA-Z]/.test(`${phone}`))
      errors.phone = "Phone number cannot contain letters";
    if (Object.keys(errors).length) setFormErrors(errors);
    return errors;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^/+0-9]/.test(e.target.value)) return;
    setFormValues({
      ...formValues,
      phone: e.target.value,
    });
    if (Object.keys(validatePhone(e.target.value)).length) return;
    setFormErrors({ ...formErrors, phone: "" });
    e.target.value.length >= 9 &&
      debouncedUpdateMe({
        meData: { phone: e.target.value },
      });
  };

  return (
    <Flex gap="16px" justify="between" style={{ height: "calc(100vh - 90px)" }}>
      <Flex column gap="24px" className="w-100">
        <Flex justify="between">
          <Flex align="center" style={{ height: "50px" }}>
            <HeaderSecondary
              style={{ paddingRight: "24px", color: theme.black900 }}
            >
              My Profile
            </HeaderSecondary>
          </Flex>
        </Flex>
        <Box style={{ width: "462px" }}>
          <Flex align="center" justify="center">
            <Avatar imageSrc={me?.avatar_url} size={160} />
          </Flex>
          <Flex column gap="24px" data-testid="form-body">
            <Flex column data-testid="name-field">
              <LabelRegular>User Name</LabelRegular>
              <InputText
                data-testid="name-input"
                color={theme.black800}
                onChange={() => {}}
                value={me?.name}
                disabled={true}
                width={"100%"}
              />
            </Flex>
            <Flex column data-testid="email-field">
              <LabelRegular>Email Address</LabelRegular>
              <InputText
                data-testid="email-input"
                color={theme.black800}
                onChange={(e) => {}}
                value={me?.email}
                disabled={true}
                width={"100%"}
              />
            </Flex>

            <Flex column data-testid="phone-field">
              <LabelRegular>Phone Number</LabelRegular>
              <InputText
                data-testid="phone-input"
                isError={!!formErrors.phone}
                color={theme.black800}
                onChange={handlePhoneChange}
                value={formValues.phone || ""}
                width={"100%"}
              />
              <FormError errorMessage={formErrors.phone} />
            </Flex>

            <Flex column data-testid="role-field">
              <LabelRegular>Role</LabelRegular>
              <InputText
                data-testid="role-input"
                color={theme.black800}
                onChange={() => {}}
                value={formValues.role || ""}
                width={"100%"}
                disabled
              />
              <FormError errorMessage={formErrors.role} />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default MyProfile;
