import { useApiMe } from "../../hooks/queries/meContext";
import { Me } from "../../types/Me";
import { getBaseConfig, waspQueryClient } from "../../hooks/queries/utils";
import { getCustomerNameUrlFormat } from "../../shared/helper";
import { IS_DEMO_ENV } from "../../hooks/queries/utils";
import { Mixpanel } from "../../shared/mixpanel";
export const LoginURL = "/login";

export const checkAuth = (
  me: Me | undefined,
  queryStatus: "error" | "success" | "loading"
): string => {
  if (queryStatus === "loading") return "";
  // Failed to get me
  if ((queryStatus === "success" && !me?.email) || queryStatus === "error") {
    console.log("Failed to get user");
    if (
      !window.location.href.includes(LoginURL) &&
      !window.location.href.includes("/register") &&
      !window.location.href.includes("/account")
    )
      return `${LoginURL}?next=${
        window.location.pathname + window.location.search
      }`;
  }

  const customerUrlFormat =
    me?.customer?.name && getCustomerNameUrlFormat(me.customer.name);

  // after register redirect to customer-name/account
  if (customerUrlFormat === "" && !window.location.href.includes("/account"))
    return `/account`;

  // Check user has customer and customer.name
  if (!customerUrlFormat) return "";

  // After registering to trial, user is redirected here until approved
  if (
    me?.customer?.license?.type === "trial" &&
    !me?.customer?.is_trial_approved &&
    !window.location.href.includes("/pending-trial")
  )
    return `/${customerUrlFormat}/pending-trial`;

  // Check if user accepted terms of service
  if (
    (!IS_DEMO_ENV || me.customer.primary_contact?.email !== me.email) &&
    !me?.accept_terms &&
    !window.location.href.includes("legal-stuff")
  )
    return `/${customerUrlFormat}/legal-stuff`;

  // All ok
  if (me?.accept_terms && !window.location.href.includes(customerUrlFormat))
    return `/${customerUrlFormat}`;
  return "";
};

export const handleLogout = () => {
  Mixpanel.reset();
  // Send a logout command to the server, reset user in the context and redirect to login.
  fetch("/api/v1/auth/logout/", { ...getBaseConfig("post") }).then(() => {
    waspQueryClient.clear();
    sessionStorage.clear();
    window.location.assign(LoginURL);
  });
};

const AuthProvider = () => {
  const { data: me, status: queryStatus } = useApiMe();
  const redirectPath = checkAuth(me, queryStatus);
  !!redirectPath && window.location.replace(redirectPath);
  return null;
};

export default AuthProvider;
