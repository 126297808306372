import React from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ChatSuggestion } from "./ChatSuggestion";

const SUGGESTIONS = [
  "Summarize my security posture",
  "What are my top 5 vulnerabilities?",
  "What asset is the most vulnerable?",
];

type Props = {
  sendUserMessage: (text: string) => void;
};

export const ChatSuggestions = (props: Props) => {
  const { sendUserMessage } = props;
  return (
    <Flex style={{ width: "70%" }} justify="center" gap="16px">
      {SUGGESTIONS.map((s) => (
        <ChatSuggestion suggestion={s} sendUserMessage={sendUserMessage} />
      ))}
    </Flex>
  );
};
