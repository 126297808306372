import React, { useContext, useRef } from "react";
import { RightPane } from "../../components/elements/rightPane/RightPane";
import { ThemeContext } from "styled-components";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  HeaderSecondary,
  LabelBold,
} from "../../components/elements/typography/Typography";
import { ChatItem } from "./ChatItem";
import { THREAD_ID_LOCAL_STORAGE_KEY } from "./utils";
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { ConversationsPage } from "./context/AIContext";
import { Loading } from "../../components/elements/loading/Loading";
import { useInfiniteScroll } from "../../hooks/utilsHooks";

type Props = {
  onClose: () => void;
  threadIds?: string[];
  onClickChatItem: (threadId: string) => void;
  isFetchingThreadIds: boolean;
  isRefetchingThreadIds: boolean;
  hasNextPageThreadIds: boolean | undefined;
  fetchNextPageThreadIds: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<ConversationsPage, Error>>;
  isFetchingNextPageThreadIds: boolean;
};

export const ChatsHistoryPane = (props: Props) => {
  const {
    onClose,
    threadIds,
    onClickChatItem,
    fetchNextPageThreadIds,
    hasNextPageThreadIds,
    isFetchingNextPageThreadIds,
    isFetchingThreadIds,
    isRefetchingThreadIds,
  } = props;
  const theme = useContext(ThemeContext);

  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(
    observerElemForFetchPage,
    !!hasNextPageThreadIds,
    fetchNextPageThreadIds
  );

  return (
    <RightPane
      onClose={onClose}
      isStaticPane
      cancelIcon={"frame"}
      iconColor={theme.primary}
      width="20%"
    >
      <Flex
        w100
        column
        gap="24px"
        style={{
          height: "90%",
        }}
      >
        <Flex w100 justify="start" style={{ paddingTop: "24px" }}>
          <HeaderSecondary>Your Chats</HeaderSecondary>
        </Flex>
        <Flex
          w100
          h100
          gap="8px"
          column
          style={{
            overflowY: "scroll",
            paddingRight: "8px",
          }}
        >
          {threadIds &&
            threadIds.map((t) => (
              <ChatItem
                threadId={t}
                selected={
                  t === localStorage.getItem(THREAD_ID_LOCAL_STORAGE_KEY)
                }
                onClick={() => onClickChatItem(t)}
              />
            ))}
          {threadIds && threadIds.length === 0 && (
            <Flex>
              <LabelBold>{"So empty here..."}</LabelBold>
            </Flex>
          )}
          {(!threadIds ||
            isFetchingNextPageThreadIds ||
            isFetchingThreadIds ||
            isRefetchingThreadIds) && <Loading />}
          {/* Observer element for fetching - Note: border ensures reliable observability */}
          <div
            className="d-flex"
            ref={observerElemForFetchPage}
            style={{ border: "1px solid transparent" }}
          />
        </Flex>
      </Flex>
    </RightPane>
  );
};
