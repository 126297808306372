import React, { useCallback, useContext, useEffect, useState } from "react";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSubBold,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import { JiraIntegrationModalFooter } from "./JiraIntegrationModalFooter";
import { FormError } from "../../../../components/elements/FormsElements/FormError";
import {
  useApiJiraCheckIntegration,
  useApiJiraUpdateIntegration,
} from "../../../../hooks/queries/jiraIntegrationContext";
import { CustomerJiraDetails } from "../../../../types/JiraIntegration";
import { Toast } from "../../../../components/elements/toastTypes/Toast";

type Props = {
  onNext: (jiraDetails: CustomerJiraDetails) => void;
};

const emptyJiraDetails: CustomerJiraDetails = {
  username: "",
  token: "",
  url: "",
};

export const AccountDetails = (props: Props) => {
  const { onNext } = props;
  const { data: me } = useApiMe();
  const theme = useContext(ThemeContext);
  const [readyForNext, setReadyForNext] = useState<boolean>(false);
  const [jiraDetails, setJiraDetails] = useState<CustomerJiraDetails>(
    me?.customer?.jira_details || emptyJiraDetails
  );

  const { mutate: checkJiraIntegration, isLoading: isCheckingIntegration } =
    useApiJiraUpdateIntegration();
  const { data: isJiraConnected } = useApiJiraCheckIntegration();
  const [isError, setIsError] = useState<boolean>(false);
  const isReady = useCallback((jiraDetails: CustomerJiraDetails) => {
    return (
      jiraDetails.username !== "" &&
      jiraDetails.token !== "" &&
      isUrlOk(jiraDetails?.url)
    );
  }, []);

  // this use effect is needed to update the jiraDetails state when the me query is done
  useEffect(() => {
    setJiraDetails(me?.customer?.jira_details || emptyJiraDetails);
    setReadyForNext(isReady(me?.customer?.jira_details || emptyJiraDetails));
  }, [me?.customer?.jira_details, isReady]);

  function isUrlOk(url: string | undefined): boolean {
    return url?.toLowerCase().startsWith("https://") || false;
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setJiraDetails({ ...jiraDetails, [field]: e.target.value });
    setReadyForNext(isReady({ ...jiraDetails, [field]: e.target.value }));
  };

  const setupIntegration = () => {
    setIsError(false);
    checkJiraIntegration({
      jiraDetails,
      onSuccessCallback() {
        onNext(jiraDetails);
      },
      onErrorCallback() {
        setIsError(true);
      },
    });
  };

  return (
    <Flex column gap="24px" justify="between" h100>
      <Flex column gap="24px" w100>
        <Flex column gap="16px">
          <HeaderSubBold>Account Details</HeaderSubBold>
          <BodyRegular style={{ color: theme.black700 }}>
            Setup JIRA Integration for Automated Security Findings Reporting. To
            initiate the process, please input your Account Email Address, API
            Key, and JIRA Workspace URL.
          </BodyRegular>
        </Flex>
        <Flex column gap="24px">
          <Flex column gap="4px">
            <LabelRegular style={{ color: theme.black700 }}>
              Account Email Address
            </LabelRegular>
            <InputText
              width={"424px"}
              value={jiraDetails.username}
              onChange={(e) => onChange(e, "username")}
              placeholder="Email Address"
              dataTestId="jira-email"
            />
          </Flex>
          <Flex column gap="4px">
            <LabelRegular style={{ color: theme.black700 }}>
              API Key
            </LabelRegular>
            <Flex align="center" justify="start" gap="8px">
              <InputText
                width={"424px"}
                value={jiraDetails.token || (isJiraConnected ? "••••••••" : "")}
                type={"password"}
                onChange={(e) => onChange(e, "token")}
                placeholder="API Key"
                dataTestId="jira-api-key"
              />
            </Flex>
          </Flex>
          <Flex column gap="4px">
            <LabelRegular style={{ color: theme.black700 }}>
              JIRA Workspace URL
            </LabelRegular>
            <InputText
              width={"424px"}
              value={jiraDetails.url}
              onChange={(e) => onChange(e, "url")}
              placeholder="https://"
              isError={!isUrlOk(jiraDetails?.url)}
              dataTestId="jira-url"
            />
            {!isUrlOk(jiraDetails?.url) && (
              <FormError errorMessage={'Address must starts with "https://"'} />
            )}
          </Flex>
          <Flex column style={{ width: "fit-content" }}>
            {isError && (
              <Toast
                type="error"
                message="Connection failed, please check your details and try again"
                onDismiss={() => setIsError(false)}
              />
            )}
            {isCheckingIntegration && (
              <Toast type="info" message="Checking integration..." />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex column gap="8px" w100>
        {isJiraConnected ? (
          <Flex align="center" gap="8px" w100>
            <JiraIntegrationModalFooter
              readyForNext={readyForNext}
              onNext={setupIntegration}
              inProgress={isCheckingIntegration}
              nextText="Update and Continue"
            />
            <JiraIntegrationModalFooter
              width="fit-content"
              readyForNext={readyForNext}
              onNext={() => onNext(jiraDetails)}
              inProgress={isCheckingIntegration}
              nextText="Continue"
            />
          </Flex>
        ) : (
          <JiraIntegrationModalFooter
            readyForNext={readyForNext}
            onNext={setupIntegration}
            inProgress={isCheckingIntegration}
            nextText="Connect and Continue"
          />
        )}
      </Flex>
    </Flex>
  );
};
