import { useNavigate } from "react-router";
import { useApiMe } from "../hooks/queries/meContext";
import { useEffect } from "react";
import { WelcomeBanner } from "../components/composed/banner/WelcomeBanner";

export const NoMatch = () => {
  const { data: me, isFetching } = useApiMe();
  const navigate = useNavigate();
  useEffect(() => {
    if (!me?.email && !isFetching) navigate("login");
  }, [me, isFetching, navigate]);

  return <WelcomeBanner />;
};
