import { PrimaryBadge } from "../../../../components/elements/badge/PrimaryBadge";
import { LabelRegular } from "../../../../components/elements/typography/Typography";
import { Finding } from "../../../../types/Finding";
import { light } from "../../../../shared/theme";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { useSearchParams } from "react-router-dom";
import { useApiProducts } from "../../../../hooks/queries/productsContext";

type Props = {
  finding: Finding | undefined | null;
  isReport?: boolean;
};

export const AffectedProductsList = (props: Props) => {
  const { finding, isReport = false } = props;

  const [searchParams] = useSearchParams();

  const params =
    searchParams.get("admin-mode") === "true"
      ? { "admin-mode": true, id: finding?.products }
      : { id: finding?.products };
  const enableQuery = !!finding?.products.length;
  const { data: products } = useApiProducts(params, enableQuery);

  return (
    <Flex column gap="8px">
      <LabelRegular color={isReport ? light.black700 : ""}>
        Affected Products
      </LabelRegular>
      <Flex flexWrap gap="8">
        {finding?.products?.map((product, index) => (
          <PrimaryBadge
            content={products?.find((p) => p.id === product)?.name || "N/A"}
            key={`product-${index}`}
          />
        ))}
      </Flex>
    </Flex>
  );
};
