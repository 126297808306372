import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../icon/Icon";
import { BodyRegular } from "../typography/Typography";
import { CheckboxContainer, CheckboxStyle, Checkmark } from "./Checkbox.style";
import { Flex } from "../../layouts/flex/Flex";

type Props = {
  label?: string;
  onChange?: (isChecked: boolean) => void;
  isDisabled?: boolean;
  isChecked: boolean;
  isLabelBefore?: boolean;
  dataTestId?: string;
  id?: string;
};

export const SimpleCheckbox = (props: Props) => {
  const {
    onChange = () => {},
    isDisabled,
    isChecked,
    label,
    isLabelBefore,
    dataTestId,
    id,
  } = props;
  const theme = useContext(ThemeContext);

  const cursor = !onChange ? "default" : isDisabled ? "not-allowed" : "pointer";

  return (
    <div className="d-flex align-items-center" style={{ cursor }}>
      {isLabelBefore && (
        <BodyRegular
          className="ms-2"
          onClick={() => onChange(isChecked)}
          data-testid={dataTestId}
        >
          {label}
        </BodyRegular>
      )}

      <CheckboxContainer style={{ cursor }}>
        <CheckboxStyle
          id={id}
          type="checkbox"
          data-testid="checkbox"
          checked={isChecked}
          disabled={isDisabled}
          onChange={() => onChange(isChecked)}
        />
        <Checkmark
          className="checkmark"
          style={{
            backgroundColor: isChecked
              ? isDisabled
                ? theme.buttonDisabled
                : theme.primary
              : "transparent",
            borderColor: isDisabled ? theme.buttonDisabled : theme.primary,
          }}
        />
        {isChecked && (
          <Flex style={{ position: "absolute" }}>
            <Icon name={isChecked ? "check" : "midCheck"} color="white" />
          </Flex>
        )}
      </CheckboxContainer>
      {!isLabelBefore && (
        <BodyRegular
          className="ms-2"
          onClick={() => onChange(isChecked)}
          data-testid={dataTestId}
        >
          {label}
        </BodyRegular>
      )}
    </div>
  );
};
