import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ProjectFormProps } from "./ProjectScheduler";
import { Box } from "../../../components/elements/box/Box";
import { Icon } from "../../../components/elements/icon/Icon";
import { CSSProperties, ReactNode, useContext } from "react";
import { ThemeContext } from "styled-components";
import { getDateTime } from "../../../shared/helper";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { ReportsTypes } from "../../../types/Project";
import { calcEffortEstimation } from "./calc_effort_estimation";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_LAPTOP_WIDTH } from "../../../shared/consts";
import { useApiAssetsCount } from "../../../hooks/queries/assetsContext";
import { Mixpanel } from "../../../shared/mixpanel";

const reportsDisplayNames = {
  full: "Full Report",
  attestation: "Attestation Letter",
  executive: "Executive Report",
  retest: "Retest Report",
};

export const ProjectSummary = (props: ProjectFormProps) => {
  Mixpanel.track("Schedule a Test - Summary");
  const { mainFormValues } = props;
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const screenWidthIsLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;
  const { data: assetsCount } = useApiAssetsCount({});
  const includedAssetsCount = mainFormValues.include_all_assets
    ? assetsCount?.total
    : mainFormValues.included_assets.length || 0;

  const GrayBox = ({
    children,
    style = {},
  }: {
    children: ReactNode;
    style?: CSSProperties;
  }) => (
    <Box
      style={{ minHeight: "106px", backgroundColor: theme.black300, ...style }}
    >
      <Flex gap="32px" align="center" h100>
        {children}
      </Flex>
    </Box>
  );

  const BoxCounter = ({
    title,
    iconName,
    value,
  }: {
    title: string;
    iconName?: string;
    value: ReactNode;
  }) => (
    <Flex column gap="8px" h100>
      <LabelRegular>{title}</LabelRegular>
      <Flex gap="24px">
        <Flex gap="8px">
          {iconName && <Icon name={iconName} size={32} color={theme.primary} />}
          {value}
        </Flex>
      </Flex>
    </Flex>
  );

  const reportsTypes: string[] = Object.keys(
    mainFormValues.test_settings.reports_types
  ).filter(
    (key) =>
      mainFormValues.test_settings.reports_types[key as keyof ReportsTypes]
  );

  return (
    <Flex
      column
      w100
      style={{ height: "calc(92vh - 230px)", overflowY: "hidden" }}
    >
      <Flex column w100 gap="32px" style={{ overflowY: "auto" }}>
        <HeaderSecondary>Test Scheduling Summary</HeaderSecondary>

        <Box>
          <Flex column w100 gap="24px">
            <Flex align="center" gap="8px">
              <Icon name="re_test" color={theme.primary} size={32} />
              <HeaderSubBold>PT Agenda</HeaderSubBold>
            </Flex>
            <Flex gap="32px" justify="between">
              <GrayBox style={{ minWidth: "420px" }}>
                <BoxCounter
                  title="Assets to be Tested"
                  iconName="assetsOutline"
                  value={
                    <HeaderSecondary>
                      {`${includedAssetsCount} Assets`}
                    </HeaderSecondary>
                  }
                />
                <SeparatorVertical height="32px" />
                <BoxCounter
                  title="Estimated duration"
                  iconName="sla"
                  value={
                    <HeaderSecondary>
                      {calcEffortEstimation(
                        mainFormValues.assets_configs,
                        includedAssetsCount
                      )}{" "}
                      days
                    </HeaderSecondary>
                  }
                />
              </GrayBox>

              <GrayBox style={{ minWidth: "450px" }}>
                <BoxCounter
                  title="Start Date"
                  iconName="calender"
                  value={
                    <HeaderSecondary>
                      {mainFormValues.start_date
                        ? getDateTime(mainFormValues.start_date)
                        : "N/A"}
                    </HeaderSecondary>
                  }
                />
                <SeparatorVertical height="32px" />
                <BoxCounter
                  title="End Date"
                  iconName="calender"
                  value={
                    <HeaderSecondary>
                      {mainFormValues.end_date
                        ? getDateTime(mainFormValues.end_date)
                        : "N/A"}
                    </HeaderSecondary>
                  }
                />
              </GrayBox>

              <GrayBox
                style={{ minWidth: !screenWidthIsLaptop ? "420px" : "100px" }}
              >
                <BoxCounter
                  title="Report Types"
                  value={
                    <BadgesLine
                      badges={reportsTypes.map(
                        (r) => reportsDisplayNames[r as keyof ReportsTypes]
                      )}
                    />
                  }
                />
              </GrayBox>
            </Flex>
            <Flex column gap="8px">
              <LabelRegular>PT Agenda</LabelRegular>
              <Box>
                <Flex flexWrap>{mainFormValues.test_settings.pt_agenda}</Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>

        <Box>
          <Flex column w100 gap="24px">
            <Flex align="center" gap="8px">
              <Icon name="frame" color={theme.primary} size={32} />
              <HeaderSubBold>Compliance Support</HeaderSubBold>
            </Flex>
            <Flex gap="16px">
              <BadgesLine
                badges={mainFormValues.test_settings.compliance_types}
              />
              <SeparatorVertical height="16px" />
              <BodyRegular>
                {mainFormValues.test_settings.compliance_note}
              </BodyRegular>
            </Flex>
          </Flex>
        </Box>

        <Box>
          <Flex column w100 gap="24px">
            <Flex align="center" gap="8px">
              <Icon name="edit" color={theme.primary} size={32} />
              <HeaderSubBold>General Notes</HeaderSubBold>
            </Flex>
            <Box>
              <Flex flexWrap>{mainFormValues.test_settings.general_notes}</Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};
