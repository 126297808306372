import { IFindingsLegend } from "../FindingsLegend";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { SingleValue } from "react-select";
import { Option } from "../../../../components/elements/dropdowns/Dropdown";
import { useApiFindingsOverTimeStatistics } from "../../../../hooks/queries/findingContext";
import { Filters } from "../../Findings";
import { FindingsOverTimeStatsItem } from "../../../../types/Finding";
import {
  LineChart,
  LineChartDataset,
} from "../../../../components/composed/charts/LineChart";
import {
  completeMissingDays,
  completeMissingMonths,
} from "./FindingsOverTimeHelper";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { LoadingLine } from "../../../../components/elements/loading/LoadingLine";

const getLabel = (s: FindingsOverTimeStatsItem) =>
  `${s.year}-${s.month}${s.day ? `-${s.day}` : ""}`;

type Props = {
  legend: IFindingsLegend;
  timeframe: SingleValue<Option>;
  filters: Filters;
};
const FindingsOverTime = (props: Props) => {
  const { legend, timeframe, filters } = props;
  const theme = useContext(ThemeContext);

  const { data: stats, status: queryStatus } =
    useApiFindingsOverTimeStatistics(filters);

  const sortedStats =
    timeframe?.value === 30
      ? stats && completeMissingDays(stats)
      : stats && completeMissingMonths(stats, timeframe);

  // console.log(sortedStats);

  const labels = Array.from(new Set(sortedStats?.map((s) => getLabel(s))));

  const datasets: LineChartDataset[] = !!stats?.length
    ? [
        {
          label: "Total",
          data: legend.total
            ? labels
                .map((label) =>
                  sortedStats
                    ?.filter((stats) => label === getLabel(stats))
                    .reduce((a, c) => ({
                      count: a.count + c.count,
                      month: a.month,
                      year: a.year,
                      day: a.day,
                      overall_risk: 9,
                    }))
                )
                .map((stats) => stats?.count || 0)
            : [],
          borderColor: theme.primary,
        },
        {
          label: "Critical",
          data: legend.critical
            ? labels.map(
                (l) =>
                  sortedStats?.find(
                    (s) => s.overall_risk === 4 && l === getLabel(s)
                  )?.count || 0
              )
            : [],
          borderColor: theme.redPrimary,
        },
        {
          label: "High",
          data: legend.high
            ? labels.map(
                (l) =>
                  sortedStats?.find(
                    (s) => s.overall_risk === 3 && l === getLabel(s)
                  )?.count || 0
              )
            : [],
          borderColor: theme.orangePrimary,
        },
        {
          label: "Medium",
          data: legend.medium
            ? labels.map(
                (l) =>
                  sortedStats?.find(
                    (s) => s.overall_risk === 2 && l === getLabel(s)
                  )?.count || 0
              )
            : [],
          borderColor: theme.yellowPrimary,
        },
        {
          label: "Low",
          data: legend.low
            ? labels.map(
                (l) =>
                  sortedStats?.find(
                    (s) => s.overall_risk === 1 && l === getLabel(s)
                  )?.count || 0
              )
            : [],
          borderColor: theme.lightBluePrimary,
        },
        {
          label: "Info",
          data: legend.info
            ? labels.map(
                (l) =>
                  sortedStats?.find(
                    (s) => s.overall_risk === 0 && l === getLabel(s)
                  )?.count || 0
              )
            : [],
          borderColor: theme.gray600,
        },
      ]
    : [];

  return (
    <Flex column>
      {queryStatus === "loading" && (
        <LoadingLine width="100%" height="4px" style={{ marginTop: "14px" }} />
      )}
      <LineChart
        datasets={datasets}
        labels={labels}
        height={304}
        dataTestId="over-time-chart"
      />
    </Flex>
  );
};

export default FindingsOverTime;
