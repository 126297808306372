import React, { useContext } from "react";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { ScanIdentifierCard } from "./ScanIdentifierCard";

type Props = {
  onClose: () => void;
};

export const ScanIdentifiersPane = (props: Props) => {
  const { onClose } = props;
  const theme = useContext(ThemeContext);
  return (
    <RightPane onClose={onClose} width="462px">
      <Flex column gap="24px" h100 style={{ overflow: "scroll" }}>
        <Flex column padding="24px 24px 0 24px">
          <LabelRegular style={{ color: theme.black600 }}>
            Scanning Policy
          </LabelRegular>
          <HeaderSecondary>Scanners Management</HeaderSecondary>
        </Flex>
        <SeparatorHorizontal />
        <Flex align="center" gap="8px" padding="0 24px">
          <LabelRegular style={{ color: theme.black700 }}>
            Last Updated
          </LabelRegular>
          <BodyRegular style={{ color: theme.black800 }}>
            Mar 25, 2024
          </BodyRegular>
        </Flex>
        <SeparatorHorizontal />
        <Flex column padding="0 24px" gap="16px">
          <HeaderSubBold>Overview</HeaderSubBold>
          <BodyRegular style={{ color: theme.black800 }}>
            As part of its attack surface management, WASP runs scans on your
            assets. This scanning activity could trigger alerts in your
            organizational monitoring applications. In order to designates these
            alerts as false positives, we provide information that helps you
            identify our friendly scanning and differentiate it from the
            potentially malicious activity of unfriendly adversaries.
          </BodyRegular>
        </Flex>
        <SeparatorHorizontal />
        <Flex column padding="0 24px" gap="16px">
          <HeaderSubBold>Source IP addresses</HeaderSubBold>
          <BadgesLine
            badgeMaxWidth="100%"
            badges={[
              "3.73.239.182",
              "18.224.205.189",
              "18.224.187.124",
              "62.0.66.176",
            ]}
          />
        </Flex>
        <SeparatorHorizontal />
        <Flex column padding="0 24px" gap="16px">
          <HeaderSubBold>Additional identifiers</HeaderSubBold>
          <ScanIdentifierCard
            title="User-Agent"
            text={`Mozilla/5.0 (X11; Ubuntu; Linux x86_64) AppleWebKit/537.36 (KHTML,
          like Gecko) Chrome/99.0.4844.74 Safari/537.36 WASP`}
          />
          {/* <ScanIdentifierCard title="HTTP Headers" text={"TBD"} /> */}
        </Flex>
      </Flex>
    </RightPane>
  );
};
