import { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  getFilterCondition,
  getFilterLabel,
  getFilterValue,
} from "./FiltersUtils";
import { Filter } from "../../../types/AssetsView";
import { Badge } from "../../../components/elements/badge/Badge";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import {
  LabelBold,
  LabelMedium,
} from "../../../components/elements/typography/Typography";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";

type Props = {
  filter: Filter;
  onRemove: () => void;
};

export const FilterBadge = (props: Props) => {
  const { filter, onRemove } = props;
  const { data: products } = useApiProducts();
  const theme = useContext(ThemeContext);
  const label = getFilterLabel(filter);
  const condition = getFilterCondition(filter);
  const value = getFilterValue(filter, products || []);

  return (
    <Badge
      className="d-flex align-items-center gap-8"
      style={{
        padding: "4px 16px",
        borderRadius: "39px",
        height: "32px",
        background: theme.blue100,
        maxWidth: "380px",
      }}
    >
      <Flex className="text-truncate">
        {!!label && (
          <LabelMedium
            className="me-2"
            style={{ color: theme.blue700 }}
          >{`${label} ${condition}:`}</LabelMedium>
        )}
        <Tooltip isTextTruncate content={value} isActive={value?.length > 27}>
          <LabelBold className="text-truncate" style={{ color: theme.blue800 }}>
            {value}
          </LabelBold>
        </Tooltip>
      </Flex>
      <IconButton
        iconName="cancel"
        size="small"
        color={theme.blue700}
        onClick={onRemove}
        dataTestId={`filter-badge-remove-${filter.value}`}
      />
    </Badge>
  );
};
