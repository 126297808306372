import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SingleValue } from "react-select";
import { Filters } from "../Findings";

export const findingsTimeOptions: Option[] = [
  { label: "Last 24 Hours", value: "1" },
  { label: "Last 7 Days", value: "7" },
  { label: "Last 30 Days", value: "30" },
  { label: "Last 6 Months", value: "180" },
  { label: "Last Year", value: "365" },
  { label: "All times", value: "0" },
];

type Props = {
  filters: Filters;
  timeframe: SingleValue<Option>;
  handleTimeframeChange: (option: SingleValue<Option>) => void;
  handleCustomDate: () => void;
};

export const Timeframe = (props: Props) => {
  const { handleTimeframeChange, handleCustomDate, timeframe, filters } = props;

  const isCustomDate =
    !!filters.created_at_after || !!filters.created_at_before;

  const onChange = (option: SingleValue<Option>) => {
    if (option?.value === "custom") handleCustomDate();
    else handleTimeframeChange(option);
  };

  return (
    <Flex align="center" gap="12px">
      <div style={{ paddingLeft: "14px" }} data-tut="findings-timeframe">
        <Dropdown
          onChange={onChange}
          options={[
            ...findingsTimeOptions,
            { label: "Custom", value: "custom" },
          ]}
          placeholder={isCustomDate ? "Custom" : timeframe?.label}
          dataTestId="timeframe-dropdown"
          isMenuPositionFixed
        />
      </div>
    </Flex>
  );
};
