import React, { useContext } from "react";
import { Badge } from "../../elements/badge/Badge";
import { ThemeContext } from "styled-components";
import { Flex } from "../../layouts/flex/Flex";
import { IconButton } from "../../elements/button/icon/IconButton";
import { LabelMedium } from "../../elements/typography/Typography";

type Props = {
  label: string;
  onClickCancel?: () => void;
  onClickDownload?: () => void;
};

export const AttachmentTag = (props: Props) => {
  const { label, onClickCancel, onClickDownload } = props;
  const theme = useContext(ThemeContext);
  return (
    <Badge
      className="d-flex align-items-center"
      style={{
        marginRight: "8px",
        color: theme.blue800,
        backgroundColor: theme.blue500,
      }}
    >
      <Flex gap="8px" align="center">
        {onClickDownload && (
          <IconButton
            iconName={"download"}
            size="very-small"
            color={theme.blue800}
            onClick={onClickDownload}
          />
        )}
        <LabelMedium>{label}</LabelMedium>
        {onClickCancel && (
          <IconButton
            iconName={"cancel"}
            size="very-small"
            color={theme.blue800}
            onClick={onClickCancel}
          />
        )}
      </Flex>
    </Badge>
  );
};
