import { IconProps } from "./WaspIcon";

export function CheckIcon(props: IconProps) {
  const size = props.size || 10;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 6"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.07691 0.912669C9.06518 0.659347 8.95333 0.421055 8.76594 0.25019C8.67323 0.165403 8.5647 0.0997481 8.44656 0.0569906C8.32843 0.0142331 8.20302 -0.00478744 8.07752 0.00102081C7.95202 0.00682906 7.8289 0.0373523 7.71522 0.0908383C7.60154 0.144324 7.49954 0.219721 7.41506 0.312709L4.31469 3.71181C4.2069 3.83884 4.07091 3.93892 3.91758 4.00408C3.76425 4.06924 3.59782 4.09767 3.43155 4.08711C3.26564 4.08361 3.10252 4.04366 2.95377 3.97009C2.80502 3.89652 2.67427 3.79113 2.5708 3.66139L1.69792 2.58593C1.53822 2.38909 1.30687 2.26375 1.05476 2.23749C0.802645 2.21123 0.550428 2.28619 0.353588 2.44589C0.156748 2.60559 0.0314094 2.83694 0.0051468 3.08905C-0.0211158 3.34116 0.0538487 3.59338 0.213548 3.79022L1.08551 4.86473C1.36285 5.20767 1.71112 5.48653 2.10642 5.68217C2.50171 5.8778 2.93465 5.98557 3.37553 5.99809L3.46516 6C3.8912 6.00016 4.31256 5.9113 4.70225 5.73913C5.09194 5.56695 5.44136 5.31525 5.72812 5.00017L8.82848 1.60105C8.99928 1.4136 9.08864 1.16599 9.07691 0.912669Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
