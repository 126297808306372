import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Finding } from "../../../types/Finding";
import { numberToWord } from "../../../shared/helper";
import { light } from "../../../shared/theme";

type Props = {
  activityType: string;
  findings: Finding[];
  isRetest?: boolean;
};

export const ExecutiveSummary = ({
  activityType,
  findings,
  isRetest,
}: Props) => {
  const { data: me } = useApiMe();

  const numOfCritical = findings.filter(
    (finding) => finding.overall_risk === 4
  ).length;
  const numOfHigh = findings.filter(
    (finding) => finding.overall_risk === 3
  ).length;
  const numOfMedium = findings.filter(
    (finding) => finding.overall_risk === 2
  ).length;
  const numOfLow = findings.filter(
    (finding) => finding.overall_risk === 1
  ).length;

  return (
    <Flex column w100 gap="8px" className="mt-5 fully-justify">
      <HeaderSecondary color={light.blue800}>Executive Summary</HeaderSecondary>
      <BodyRegular color={light.black800}>
        {me?.customer?.name || "Customer"}, engaged OP Innovate to perform{" "}
        {activityType.toLowerCase()}. OP Innovate conducted a penetration test
        over several days and assessed the security risk level of{" "}
        {me?.customer?.name || "Customer"} assets using the methodology defined
        below. The Red Team found{" "}
        {!!numOfCritical && (
          <BodyRegular>
            {`${numberToWord(numOfCritical)} `}
            <strong style={{ color: light.redPrimary }}>critical</strong>,{" "}
          </BodyRegular>
        )}
        {!!numOfHigh && (
          <BodyRegular>
            {`${numberToWord(numOfHigh)} `}
            <strong style={{ color: light.orangePrimary }}>high</strong>,{" "}
          </BodyRegular>
        )}
        {!!numOfMedium && (
          <BodyRegular>
            {`${numberToWord(numOfMedium)} `}
            <strong style={{ color: light.yellowPrimary }}>medium</strong>,{" "}
          </BodyRegular>
        )}
        {!!numOfLow && (
          <BodyRegular>
            {`${numberToWord(numOfLow)} `}
            <strong style={{ color: light.lightBluePrimary }}>low</strong>{" "}
          </BodyRegular>
        )}
        {!numOfCritical && !numOfHigh && !numOfMedium && !numOfLow && (
          <BodyRegular>{`Zero `}</BodyRegular>
        )}
        risk vulnerabilities that may pose a risk to the applications.
      </BodyRegular>

      <BodyRegular color={light.black800}>
        The Red Team also concluded that noticeable efforts have been made by{" "}
        {me?.customer?.name || "Customer"} to maintain the application’s
        security posture and make further recommendations for mitigation
        purposes.
      </BodyRegular>
      {isRetest && (
        <BodyBold color={light.textBody}>
          This report represents confirmation of mitigation put into place by{" "}
          {me?.customer?.name || "Customer"}, by way of retesting the original
          findings.
        </BodyBold>
      )}
    </Flex>
  );
};
