import React, { useContext, useEffect, useMemo, useState } from "react";
import { Flex } from "../../components/layouts/flex/Flex";
import { Box } from "../../components/elements/box/Box";
import { IconButton } from "../../components/elements/button/icon/IconButton";
import { chatMessage } from "./chat/chatMessage";
import {
  useApiWaspAIAskQuestion,
  useApiWaspAIConversations,
  useApiWaspAISingleConversation,
} from "./context/AIContext";
import {
  BodyBold,
  BodyMedium,
} from "../../components/elements/typography/Typography";
import { useApiMe } from "../../hooks/queries/meContext";
import { useTrackPage } from "../../hooks/trackingHooks";
import AlertBanner from "../../components/elements/toastTypes/AlertBanner";
import useToastContext from "../../hooks/toastHook";
import { waspAISteps } from "../../tours/WaspAITourSteps";
import { ThemeContext } from "styled-components";
import { Tours } from "../../tours/Tours";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { useNavigate } from "react-router";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";
import {
  THREAD_ID_LOCAL_STORAGE_KEY,
  formatMessagesForApi,
  getOrCreateThreadId,
} from "./utils";
import { ChatsHistoryPane } from "./ChatsHistoryPane";
import { WaspAIInput } from "./chat/WaspAIInput";
import { WaspAIChatWindow } from "./chat/WaspAIChatWindow";
import { Mixpanel } from "../../shared/mixpanel";

type Props = {
  mode?: "Full Screen" | "Sidebar";
  finding_id?: number;
};

export const WaspAI = (props: Props) => {
  const addToast = useToastContext();
  const navigate = useNavigate();
  const { data: me } = useApiMe();
  const isSuperuser = useIsSuperuser();
  const theme = useContext(ThemeContext);
  const { mode = "Full Screen", finding_id } = props;
  const { mutate: sendQuestionToWaspAI, isLoading: isLoadingAnswerFromAI } =
    useApiWaspAIAskQuestion();
  const {
    data: threadIds,
    isFetching: isFetchingThreadIds,
    isRefetching: isRefetchingThreadIds,
    hasNextPage: hasNextPageThreadIds,
    fetchNextPage: fetchNextPageThreadIds,
    isFetchingNextPage: isFetchingNextPageThreadIds,
    refetch: refetchThreadIds,
  } = useApiWaspAIConversations();
  const {
    data: conversation,
    isFetching: isFetchingConversation,
    isRefetching: isRefetchingConversation,
    hasNextPage: hasNextPageConversation,
    fetchNextPage: fetchNextPageConversation,
    isFetchingNextPage: isFetchingNextPageConversation,
    refetch: refetchConversation,
  } = useApiWaspAISingleConversation(getOrCreateThreadId(me));

  const pastConversation = useMemo(
    () => conversation?.pages.map((page) => page.results || []).flat() || [],
    [conversation]
  );

  const flatThreadIds = useMemo(
    () => threadIds?.pages.map((page) => page.results || []).flat() || [],
    [threadIds]
  );

  // eslint-disable-next-line no-lone-blocks
  {
    /* This might appear to be an anti-pattern,
    but it actually optimizes performance by reducing one
    query for each user-sent message. It ensures that
    messages are only re-fetched once, when the user initially
    loads the WASP AI page */
  }
  useEffect(() => {
    refetchConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userInput, setInput] = useState<string>("");
  const [showChats, setShowChats] = useState<boolean>(true);
  const [latestUserMessage, setLatestMessage] = useState<string>("");
  const [chatMessages, setMessages] = useState<chatMessage[]>([]);
  useTrackPage("WASP AI", undefined, me);

  const sendUserMessage = (text: string) => {
    if (text === "") {
      addToast({
        message: "Please compose a message before sending.",
        type: "warning",
      });
      return;
    }
    Mixpanel.track("WASP AI - Message sent");
    setLatestMessage(text);
    setInput("");

    const threadId = getOrCreateThreadId(me);

    sendQuestionToWaspAI({
      messages: formatMessagesForApi([
        ...chatMessages,
        { text: text, role: "user" },
      ]),
      finding_id: finding_id,
      thread_id: threadId,
      onSuccessCallback(response) {
        // check if this was the first message sent in this chat
        if (
          chatMessages.length === 0 &&
          (pastConversation ? pastConversation.length === 0 : true)
        ) {
          // if so, add the new chat to the chats sidebar
          refetchThreadIds();
        }
        setMessages((prev) => [
          ...prev,
          { text: text, role: "user" },
          { text: response.answer, role: "assistant" },
        ]);
      },
      onErrorCallback(error) {
        setMessages((prev) => [
          ...prev,
          { text: text, role: "user" },
          {
            text: "Something is wrong, please try again later",
            role: "assistant",
          },
        ]);
      },
    });
  };

  const createNewChat = () => {
    // clear cache from most recent thread id
    localStorage.removeItem(THREAD_ID_LOCAL_STORAGE_KEY);
    // reset messages
    setMessages([]);
    // create new chat and refetch
    refetchConversation();
  };

  const onClickChatItem = (threadId: string) => {
    // select new thread id as the current thread
    localStorage.setItem(THREAD_ID_LOCAL_STORAGE_KEY, threadId);
    // reset messages
    setMessages([]);
    // create new chat and refetch
    refetchConversation();
  };
  console.log();
  return (
    <Flex column gap="8px">
      <Flex gap="8px" align="center">
        <AlertBanner
          message={
            <>
              <BodyBold>Notice: </BodyBold>
              <BodyMedium>
                WASP AI is an experimental feature in BETA. It is still under
                development and may change. Your feedback is appreciated as we
                continue to improve. Thank you!
              </BodyMedium>
            </>
          }
        />
        {isSuperuser && (
          <MainButton
            iconName="frame"
            label="Conversations"
            size="medium"
            onClick={() => navigate("/wasp-ai-conversations")}
          />
        )}
      </Flex>
      <Tours steps={waspAISteps(theme)} />
      <div className="w-100 h-100" data-tut="wasp-ai">
        <Box
          style={{
            height: "calc(100vh - 130px)",
            minHeight: "calc(100vh - 130px)",
            marginBottom: "16px",
          }}
        >
          <Flex w100 h100>
            {showChats && (
              <ChatsHistoryPane
                onClose={() => setShowChats(false)}
                threadIds={flatThreadIds as string[]}
                onClickChatItem={onClickChatItem}
                fetchNextPageThreadIds={fetchNextPageThreadIds}
                hasNextPageThreadIds={hasNextPageThreadIds}
                isFetchingNextPageThreadIds={isFetchingNextPageThreadIds}
                isFetchingThreadIds={isFetchingThreadIds}
                isRefetchingThreadIds={isRefetchingThreadIds}
              />
            )}

            <IconButton
              disabled={false}
              label={!showChats ? "Open chats sidebar" : ""}
              iconName={"frame"}
              onClick={() => {
                if (!showChats) setShowChats(true);
              }}
              style={{
                transition: "all 0.4s",
                opacity: showChats ? 0 : 1,
                cursor: !showChats ? "pointer" : "default",
              }}
              size="medium"
            />

            <Flex w100 h100 column>
              <WaspAIChatWindow
                mode={mode}
                chatMessages={chatMessages}
                pastConversation={pastConversation}
                latestUserMessage={latestUserMessage}
                fetchNextPageConversation={fetchNextPageConversation}
                isRefetchingConversation={isRefetchingConversation}
                hasNextPageConversation={hasNextPageConversation}
                isFetchingConversation={isFetchingConversation}
                isFetchingNextPageConversation={isFetchingNextPageConversation}
                isLoadingAnswerFromAI={isLoadingAnswerFromAI}
              />
              <WaspAIInput
                createNewChat={createNewChat}
                mode={mode}
                sendUserMessage={sendUserMessage}
                userInput={userInput}
                setInput={setInput}
                isLoadingAnswerFromAI={isLoadingAnswerFromAI}
                didChatStart={
                  chatMessages.length + pastConversation.length > 0 ||
                  isLoadingAnswerFromAI
                }
              />
            </Flex>
          </Flex>
        </Box>
      </div>
    </Flex>
  );
};
