import { useQuery } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "./utils";
import { getItems, createItem } from "./sdk";

import { bitAfterNow } from "../../shared/helper";
import { Filter } from "../../types/AssetsView";
import {
  GraphAsset,
  GraphFinding,
  GraphRelation,
} from "../../features/assets/graph/sigmaGraph/GraphDataProvider";

const graphFetchKey = "assets/graph";
const filterKey = "assets/filter_graph";

export type GraphData = {
  assets: GraphAsset[];
  findings: GraphFinding[];
  relations: GraphRelation[];
};

function getAssetsGraph(): Promise<GraphData> {
  return getItems(graphFetchKey).then((data) => {
    return {
      assets: data.assets,
      findings: data.findings,
      relations: data.relations,
    };
  });
}

function filterAssetsGraph(filters: Filter[]): Promise<number[]> {
  return createItem(filterKey, { filters }).then((data) => {
    return data as number[];
  });
}

export const useApiAssetsGraphFilter = (filters: Filter[]) => {
  return useQuery<number[], Error>({
    queryKey: [filterKey, filters],
    keepPreviousData: true,
    placeholderData: [],
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: () => filterAssetsGraph(filters),
  });
};

export const useApiAssetsGraphData = () => {
  return useQuery<GraphData, Error>({
    queryKey: [graphFetchKey],
    keepPreviousData: true,
    placeholderData: {
      assets: [],
      findings: [],
      relations: [],
    },
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: getAssetsGraph,
  });
};
