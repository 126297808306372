import { IconProps } from "./WaspIcon";

export function ShieldSolid(props: IconProps) {
  const size = props.size || 16;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4055 6.86262L12.4541 3.37605C12.3332 3.31498 12.1996 3.28302 12.0641 3.28272C11.9286 3.28241 11.795 3.31378 11.6738 3.3743L4.67522 6.86087C4.41628 6.99162 4.23754 7.24178 4.20092 7.52942C4.18959 7.61397 3.20263 16.0201 11.6476 20.611C11.7774 20.6818 11.9232 20.7183 12.071 20.7169C12.2189 20.7155 12.364 20.6764 12.4925 20.6032C20.607 16.0192 19.9156 7.90074 19.8825 7.55731C19.8682 7.41066 19.8169 7.27002 19.7335 7.14853C19.6501 7.02704 19.5373 6.92867 19.4055 6.86262Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
