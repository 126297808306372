import React, { useContext } from "react";
import { DoughnutChart } from "../../../../components/composed/charts/DoughnutChart";
import { ThemeContext } from "styled-components";
import { RiskScoreHistory } from "../../../../types/RiskScoreHistory";

type Props = {
  latestRiskHistory: RiskScoreHistory | undefined;
  isLoadingData: boolean;
  size?: number;
  labelNumberSize?: number;
  labelTextSize?: number;
  top?: string;
  labelText?: string;
};

export const RiskScoreDoughnutChart = ({
  latestRiskHistory,
  isLoadingData,
  size,
  labelNumberSize,
  labelTextSize,
  top,
  labelText,
}: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <DoughnutChart
      labelNumber={latestRiskHistory?.risk_score || 0}
      labelText={labelText || "Total Score"}
      isLoadingData={isLoadingData}
      labels={["Amount of Issues", "Critical Issues", "Breached SLA"]}
      datasets={[
        {
          data: [
            (latestRiskHistory?.amount_findings_weight || 0) * 100,
            (latestRiskHistory?.criticals_weight || 0) * 100,
            (latestRiskHistory?.breached_sla_weight || 0) * 100,
          ],
          backgroundColor: [
            theme.yellowPrimary,
            theme.redPrimary,
            theme.orangePrimary,
          ],
        },
      ]}
      size={size}
      labelNumberSize={labelNumberSize}
      labelTextSize={labelTextSize}
      top={top}
    />
  );
};
