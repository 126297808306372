import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { ThemeContext } from "styled-components";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { Checkbox, CheckboxState } from "../checkbox/Checkbox";
import { Icon } from "../icon/Icon";
import { Loading } from "../loading/Loading";
import { ScrollToTop } from "../scrollToTop/ScrollToTop";
import { SeparatorHorizontal } from "../separators/SeparatorHorizontal";
import { BodyRegular, LabelRegular } from "../typography/Typography";
import "./Table.css";
import { LoadingLine } from "../loading/LoadingLine";
import { SimpleCheckbox } from "../checkbox/SimpleCheckbox";

export type Column = {
  title: string;
  titleComponent?: () => ReactNode;
  key: string;
  sortable?: boolean;
  cell?: (row: any) => React.ReactNode;
  iconName?: string;
  onTitleClick?: Function;
  isHidden?: boolean;
  isRequired?: boolean;
  maxWidth?: string;
  minWidth?: string;
  centerCell?: boolean;
  overflow?: "visible" | "hidden" | "scroll" | "auto" | "initial" | "inherit";
};

type Props = {
  columns: Column[];
  rows: any[];
  onSort?: (column: Column, order: "asc" | "desc") => void;
  minWidth?: number;
  hasBackToTopButton?: boolean;
  hasScrollPagination?: boolean;
  onScrollPagination?: () => void;
  onShowBackToTopButton?: (status: boolean) => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  bodyMaxHeight?: string;
  defaultSortKey?: string;
  defaultSortOrder?: "asc" | "desc";
  isSelectable?: boolean;
  isStriped?: boolean;
  onSelectedRowsChange?: Dispatch<SetStateAction<any | null>>;
  selectedRows?: any[];
  onRowSelect?: (row: any, state: CheckboxState) => void;
  onSelectAll?: (rowsId: number[], state: CheckboxState) => void;
  isSelectAll?: boolean;
  setIsSelectAll?: Dispatch<SetStateAction<any | null>>;
  isEditMode?: boolean;
  size?: "very-small" | "small" | "medium";
  isLoading?: boolean;
  rowHeight?: string;
  isRowClickable?: boolean;
};

type RowProps = {
  isSelected?: boolean;
  isStriped?: boolean;
  idx: number;
  gap: number;
  rowHeight: string;
};

const TableRow = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => props.rowHeight};
  padding: ${(props) => props.gap * 2}px 0;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isStriped && props.idx % 2 !== 0
      ? props.theme.black300
      : props.isSelected
        ? props.theme.blue50
        : "transparent"};
  &:hover {
    background-color: ${(props) => props.theme.blue100};
  }
`;

export const Table = (props: Props) => {
  const {
    columns,
    rows,
    onSort,
    minWidth,
    bodyMaxHeight,
    hasBackToTopButton,
    hasScrollPagination,
    onScrollPagination,
    onShowBackToTopButton,
    hasNextPage,
    isFetchingNextPage,
    defaultSortKey,
    defaultSortOrder,
    isSelectable,
    isStriped,
    selectedRows,
    onSelectedRowsChange,
    onRowSelect,
    onSelectAll,
    isSelectAll,
    setIsSelectAll,
    isEditMode,
    size = "medium",
    isLoading,
    rowHeight,
    isRowClickable,
  } = props;

  const theme = useContext(ThemeContext);
  const gap = size === "small" ? 16 : size === "very-small" ? 12 : 24;

  const [rowsUsed, setRowsUsed] = useState<any[]>(rows);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">(
    defaultSortOrder || "asc"
  );
  const [sortKey, setSortKey] = useState<string>(defaultSortKey || "");
  const [selectAllCheckboxState, setSelectAllCheckboxState] =
    useState<CheckboxState>(getSelectAllState(selectedRows || []));
  const [_selectedRows, set_SelectedRows] = useState<string[]>(
    // selectedRows?.map((r) => r?.id) ||
    []
  );

  useEffect(() => {
    if (!selectedRows) return;
    set_SelectedRows(selectedRows.map((r) => r?.id));
  }, [selectedRows]);

  useEffect(() => {
    setSelectAllCheckboxState(isSelectAll ? "checked" : "unchecked");
  }, [isSelectAll]);

  useEffect(() => {
    setRowsUsed(rows);
  }, [rows]);

  const onScroll = () => {
    onScrollPagination && !isFetchingNextPage && onScrollPagination();
  };

  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(observerElemForFetchPage, !!hasNextPage, onScroll);

  function getSelectAllState(selectedRows: any[]): CheckboxState {
    if (isSelectAll) return "checked";
    return selectedRows.length === 0
      ? "unchecked"
      : selectedRows.length === rows.length
        ? "checked"
        : "unchecked"; //"mid";
  }

  const sortString = (a: string, b: string, order: "asc" | "desc") => {
    return order === "asc" ? a?.localeCompare(b) : b?.localeCompare(a);
  };

  const sortNumber = (a: number, b: number, order: "asc" | "desc") => {
    if (order === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };

  const sortDate = (a: Date, b: Date, order: "asc" | "desc") => {
    if (!a || !b) return 0;
    if (order === "asc") {
      return a.getTime() - b.getTime();
    } else {
      return b.getTime() - a.getTime();
    }
  };

  const handleSort = (column: Column) => {
    if (!!onSort) {
      onSort(column, sortOrder);
    } else {
      // use default sort by type (date, number or string)
      setRowsUsed(() => {
        const sortedRows = rowsUsed.sort((a, b) =>
          a[column.key] instanceof Date
            ? sortDate(a[column.key], b[column.key], sortOrder)
            : typeof a[column.key] === "number"
              ? sortNumber(a[column.key], b[column.key], sortOrder)
              : sortString(a[column.key], b[column.key], sortOrder)
        );
        return sortedRows;
      });
    }
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    setSortKey(column.key);
  };

  const handleRowSelection = (row: any, state: CheckboxState) => {
    var temp = [..._selectedRows];
    if (state === "checked") {
      temp.push(row.id);
    } else {
      temp = temp.filter((r) => r !== row.id);
    }
    set_SelectedRows(temp);
    if (!!onSelectedRowsChange) {
      onSelectedRowsChange(rows.filter((r) => temp.includes(r.id)));
    }
    // !!setIsSelectAll && setIsSelectAll(temp.length === rows.length);
    setSelectAllCheckboxState(getSelectAllState(temp));
    onRowSelect && onRowSelect(row, state);
  };

  const handleSelectAllStateChange = (state: CheckboxState) => {
    const rowsId = rows.map((r) => r.id);
    setSelectAllCheckboxState(state);
    if (state === "checked") {
      // If checked only the items currently displayed will be selected
      set_SelectedRows(rowsId);
      !!onSelectedRowsChange && onSelectedRowsChange(rows);
    } else if (state === "unchecked") {
      set_SelectedRows([]);
      !!setIsSelectAll && setIsSelectAll(false);
      !!onSelectedRowsChange && onSelectedRowsChange([]);
    }
    !!onSelectAll && onSelectAll(rowsId, state);
  };

  if (isSelectable && rows.some((r) => !r?.id)) {
    throw new Error(
      "Table: rows must have a id property when isSelectable is true"
    );
  }

  return (
    <div
      className="d-flex flex-column h-100 scroll-to-top-target"
      style={{ minWidth: minWidth ? `${minWidth}px` : "unset" }}
    >
      {/* Table Head */}
      <div
        data-testid="table-head"
        className="table-head"
        style={{
          position: "sticky",
          top: "-18px",
          zIndex: isEditMode ? "" : "10",
          paddingTop: size !== "medium" ? `${gap}px` : "0",
          backgroundColor: theme.bg2,
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          {isSelectable && (
            <Checkbox
              dataTestId="select-all"
              key={`select-all-${selectAllCheckboxState}`}
              onChange={(state) => handleSelectAllStateChange(state)}
              state={selectAllCheckboxState}
            />
          )}

          {columns.map((column, i) =>
            !column.isHidden || !!column.isRequired ? (
              <div
                key={`${i}-${column.key}`}
                className={
                  "d-flex gap-8 align-items-center column-cell px-1" +
                  (column.centerCell ? " justify-content-center" : "")
                }
                style={{
                  width: "100%",
                  maxWidth: column.maxWidth || "unset",
                  minWidth: column.minWidth || "unset",
                  cursor: column.sortable ? "pointer" : "unset",
                }}
                onClick={() => column.sortable && handleSort(column)}
              >
                {column.titleComponent ? (
                  column.titleComponent()
                ) : (
                  <>
                    {column.iconName && (
                      <span
                        data-testid={column.iconName}
                        onClick={() =>
                          column.onTitleClick && column.onTitleClick()
                        }
                        className={"pointer"}
                      >
                        <Icon
                          name={column.iconName}
                          color={theme.primary}
                          size={24}
                        />
                      </span>
                    )}

                    <LabelRegular
                      style={{
                        color: theme.black700,
                        fontWeight: column.key === sortKey ? 700 : 400,
                        fontSize: size === "very-small" ? "11px" : "",
                      }}
                    >
                      {column.title}
                    </LabelRegular>
                  </>
                )}
                {column.sortable && (
                  <div
                    className="d-flex pointer sort-wrapper"
                    style={sortKey === column.key ? { opacity: 1 } : {}}
                    data-testid={`sort-btn-${column.key}`}
                  >
                    <div className="d-flex flex-column">
                      <Icon
                        name="chevronUp"
                        size={12}
                        color={
                          sortKey === column.key && sortOrder === "asc"
                            ? theme.black700
                            : theme.black500
                        }
                      />
                      <Icon
                        name="chevronDown"
                        size={12}
                        color={
                          sortKey === column.key && sortOrder === "desc"
                            ? theme.black700
                            : theme.black500
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null
          )}
        </div>
        {isLoading ? (
          <LoadingLine
            width="100%"
            height="4px"
            style={{ marginTop: "14px" }}
          />
        ) : (
          <SeparatorHorizontal style={{ marginTop: "16px" }} />
        )}
      </div>

      {/* Table Body */}

      <div
        data-testid="table-body"
        className={`d-flex flex-column`}
        style={{ height: bodyMaxHeight || "100%", overflowY: "auto" }}
        key={`rows-${rows.length}-selected-${selectAllCheckboxState}`}
      >
        {rowsUsed.map((row, i) => (
          <div
            data-testid={`${i}-table-row`}
            className={`d-flex flex-column `}
            key={`${i}-table-row`}
          >
            <TableRow
              isSelected={isSelectAll || _selectedRows.includes(row?.id)}
              isStriped={isStriped}
              gap={gap}
              idx={i}
              rowHeight={rowHeight || "48px"}
              onClick={() =>
                isRowClickable &&
                handleRowSelection(
                  row,
                  !_selectedRows?.includes(row.id) ? "checked" : "unchecked"
                )
              }
            >
              {isSelectable && (
                <SimpleCheckbox
                  dataTestId={`${i}-table-row-checkbox`}
                  key={`${i}-table-row-checkbox`}
                  onChange={(state) =>
                    !isRowClickable &&
                    handleRowSelection(row, !state ? "checked" : "unchecked")
                  }
                  isChecked={isSelectAll || _selectedRows.includes(row.id)}
                />
              )}

              {/* If first row - make it observer to render ScrollToTop */}
              {i === 0 && hasBackToTopButton && (
                <ScrollToTop
                  onIntersect={(status) =>
                    onShowBackToTopButton && onShowBackToTopButton(status)
                  }
                />
              )}

              {/* If last row - make it observer to call for next page */}
              {i === rowsUsed.length - 1 && hasScrollPagination && (
                <div ref={observerElemForFetchPage}></div>
              )}

              {columns.map((column, j) =>
                !column.isHidden || !!column.isRequired ? (
                  <BodyRegular
                    key={`${i}-${j}-table-cell`}
                    data-testid={`${i}-${j}-table-cell`}
                    className={
                      "d-flex px-1" +
                      (column.centerCell ? " justify-content-center" : "")
                    }
                    style={{
                      width: "100%",
                      maxWidth: column.maxWidth || "unset",
                      minWidth: column.minWidth || "unset",
                      overflow: column.overflow || "auto",
                    }}
                  >
                    {column.cell ? column.cell(row) : row[column.key]}
                  </BodyRegular>
                ) : null
              )}
            </TableRow>
            {size === "medium" && i < rowsUsed.length - 1 && (
              <SeparatorHorizontal color={isStriped ? "transparent" : ""} />
            )}
          </div>
        ))}
        <span data-testid="infinite-scroll-loading">
          {isFetchingNextPage && <Loading />}
        </span>
      </div>
    </div>
  );
};
