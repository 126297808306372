import { IconProps } from "./WaspIcon";

export function PlayIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3328 9.37771L10.7297 5.15143C10.273 4.89754 9.75797 4.76732 9.23552 4.77366C8.71306 4.78 8.20132 4.92268 7.75096 5.18758C7.30059 5.45248 6.92722 5.83041 6.66781 6.28395C6.40839 6.7375 6.27193 7.25093 6.27193 7.77343V16.2262C6.27021 16.7494 6.4056 17.2639 6.6646 17.7184C6.9236 18.1729 7.29717 18.5517 7.7481 18.8169C8.19904 19.0822 8.7116 19.2246 9.23472 19.2301C9.75785 19.2356 10.2733 19.1039 10.7297 18.8482L18.3328 14.6219C18.8002 14.3621 19.1897 13.9819 19.4608 13.5209C19.732 13.0599 19.875 12.5348 19.875 11.9999C19.875 11.4651 19.732 10.94 19.4608 10.479C19.1897 10.018 18.8002 9.6376 18.3328 9.37771ZM17.7492 13.573L10.1461 17.7994C9.87214 17.9517 9.56316 18.0297 9.24975 18.0258C8.93635 18.022 8.62938 17.9363 8.35923 17.7774C8.08908 17.6185 7.86511 17.3918 7.70949 17.1197C7.55386 16.8476 7.47197 16.5397 7.47193 16.2262V7.77343C7.4709 7.45959 7.55211 7.15095 7.70748 6.87826C7.86284 6.60557 8.08694 6.37834 8.35745 6.21921C8.62795 6.06007 8.93543 5.97458 9.24926 5.97125C9.56308 5.96792 9.8723 6.04687 10.1461 6.20023L17.7492 10.4266C18.0297 10.5826 18.2634 10.8106 18.4261 11.0872C18.5888 11.3639 18.6745 11.6789 18.6745 11.9998C18.6745 12.3207 18.5888 12.6358 18.4261 12.9124C18.2634 13.189 18.0297 13.4171 17.7492 13.573Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
