import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderMain,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ThemeContext } from "styled-components";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { WhatToScan } from "./WhatToScan";
import { Schedule } from "./Schedule";
import { ProjectConfigurations } from "./Configurations";
import { Asset } from "../../../types/Asset";
import { dateObjToDateString, getIsoString } from "../../../shared/helper";
import { Box } from "../../../components/elements/box/Box";
import { FormStages } from "../../../components/elements/formStages/FormStages";
import { TestSettings } from "./TestSettings";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { useNavigate, useParams } from "react-router";
import { ProjectSummary } from "./Summary";
import { Modal } from "../../../components/elements/modal/Modal";
import {
  ProjectRequestParams,
  useApiCreateProjectRequest,
  useApiDeleteProjectRequest,
  useApiProjectRequests,
  useApiUpdateProjectRequest,
} from "../../../hooks/queries/projectRequestsContext";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { ShareModal } from "./ShareModal";
import { useApiAssetsGetById } from "../../../hooks/queries/assetsContext";
import { ProjectRequestStatus } from "../../../types/Project";
import { WarningModal } from "../../../components/composed/warningModal/WarningModal";
import useToastContext from "../../../hooks/toastHook";
import { CreateProjectModal } from "./CreateProjectModal";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Mixpanel } from "../../../shared/mixpanel";
import { Illustration } from "../../../components/elements/illustrations/Illustration";

export const defaultProjectRequestFormValues: ProjectRequestParams = {
  status: "draft",
  include_all_assets: false,
  included_assets: [],
  assets_configs: {},
  test_settings: {
    is_urgent: false,
    pt_agenda: "",
    reports_types: {
      full: true,
      attestation: false,
      executive: false,
      retest: false,
    },
    compliance_support: false,
    compliance_types: [],
    compliance_note: "",
    is_whitelisted: true,
    general_notes: "",
  },
  start_date: dateObjToDateString(new Date()),
  end_date: dateObjToDateString(new Date()),
};

export type ProjectFormProps = {
  mainFormValues: ProjectRequestParams;
  setMainFormValues: Dispatch<SetStateAction<ProjectRequestParams>>;
};

export const ProjectScheduler = () => {
  const theme = useContext(ThemeContext);
  const { id: requestId } = useParams();
  const isSuperUser = useIsSuperuser();
  const navigate = useNavigate();
  const addToast = useToastContext();
  const { data: me } = useApiMe();

  useTrackPage("project_scheduler", undefined, me);

  const { data: projectRequests } = useApiProjectRequests();
  const { mutate: createRequest, isLoading } = useApiCreateProjectRequest();
  const { mutate: updateRequest } = useApiUpdateProjectRequest();
  const {
    mutate: deleteRequest,
    isLoading: isDeleting,
    isSuccess: isDeleted,
  } = useApiDeleteProjectRequest();

  const [currentStage, setCurrentStage] = useState(1);

  const editRequest =
    requestId && !!projectRequests?.length
      ? projectRequests?.find((pr) => pr.id === parseInt(requestId))
      : null;

  const { data: assets } = useApiAssetsGetById(
    editRequest?.included_assets || []
  );

  const initialFormValues: ProjectRequestParams = useMemo(
    () =>
      editRequest
        ? {
            status: editRequest.status,
            included_assets: editRequest.included_assets,
            include_all_assets: !!editRequest.include_all_assets,
            assets_configs: editRequest.assets_configs,
            test_settings: editRequest.test_settings,
            start_date: editRequest.start_date,
            end_date: editRequest.end_date,
          }
        : defaultProjectRequestFormValues,
    [editRequest]
  );

  const [formValues, setFormValues] = useState<ProjectRequestParams>(
    defaultProjectRequestFormValues
  );

  useEffect(() => {
    setFormValues(initialFormValues);
  }, [initialFormValues]);

  const [showAddAssetsModal, setShowAddAssetsModal] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showDraftModal, setShowDraftModal] = useState<boolean>(false);
  const [showCreateProjectModal, setShowCreateProjectModal] =
    useState<boolean>(false);
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);

  const openAddAssetsModal = () => {
    setShowAddAssetsModal(true);
    setSelectedAssets(assets || []);
  };

  const cancelShowAddAssetsModal = () => {
    setShowAddAssetsModal(false);
    setFormValues((prev) => ({
      ...prev,
      included_assets: selectedAssets.map((a) => a.id),
    }));
  };

  const onCreateProjectRequest = (status: string) => {
    if (status === "pending") setShowShareModal(true);
    if (status === "draft") setShowDraftModal(true);
  };

  const ApplyForm = (status: ProjectRequestStatus) => {
    const request: ProjectRequestParams = {
      ...formValues,
      status: status,
      end_date: getIsoString(formValues.end_date),
      start_date: getIsoString(formValues.start_date),
      onSuccessCallback: () => onCreateProjectRequest(status),
      onErrorCallback: (error) =>
        addToast({ message: error.message, type: "error" }),
    };
    editRequest
      ? updateRequest({
          projectRequestData: request,
          projectRequestId: parseInt(`${requestId}`),
        })
      : createRequest(request);
  };

  const showDeleteButton =
    editRequest && (formValues.status === "draft" || isSuperUser);

  const handleDeleteRequest = () => {
    editRequest &&
      deleteRequest(editRequest, {
        onError: () =>
          addToast({
            type: "error",
            message: "Failed to delete project request",
          }),
        onSuccess: () => {
          addToast({
            type: "success",
            message: "Project request has been deleted successfully",
          });
          navigate("/projects");
        },
      });
  };

  // Raise browser alert before refresh
  useEffect(() => {
    const unloadCallback = (e: any) => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <>
      <Flex column w100>
        <Flex gap="16px" justify="between" h100 w100>
          <Flex column h100 w100 justify="between">
            <Flex justify="between" w100>
              <Flex align="center" gap="24px">
                <HeaderMain
                  style={{ paddingRight: "24px", color: theme.black900 }}
                >
                  Schedule a Test
                </HeaderMain>
              </Flex>
              <FormStages
                stages={[
                  "What to Scan",
                  "Configurations",
                  "Test Settings",
                  "Schedule",
                  "Summary",
                ]}
                currentStage={currentStage}
                setCurrentStage={setCurrentStage}
              />
              <IconButton iconName="cancel" onClick={() => navigate(-1)} />
            </Flex>
            <SeparatorHorizontal style={{ marginBottom: "24px" }} />
            <Box style={{ height: "calc(100vh - 240px)" }}>
              {currentStage === 1 && (
                <WhatToScan
                  isSelectAll={!!formValues.include_all_assets}
                  setIsSelectAll={(state) =>
                    setFormValues((prev) => ({
                      ...prev,
                      include_all_assets: state,
                    }))
                  }
                  includedAssetsIds={formValues.included_assets}
                  setIncludedAssetsIds={(ids) =>
                    setFormValues((prev) => ({ ...prev, included_assets: ids }))
                  }
                />
              )}
              {currentStage === 2 && (
                <ProjectConfigurations
                  openAddAssetsModal={openAddAssetsModal}
                  mainFormValues={formValues}
                  setMainFormValues={setFormValues}
                />
              )}
              {currentStage === 3 && (
                <TestSettings
                  mainFormValues={formValues}
                  setMainFormValues={setFormValues}
                />
              )}
              {currentStage === 4 && (
                <Schedule
                  mainFormValues={formValues}
                  setMainFormValues={setFormValues}
                />
              )}

              {currentStage === 5 && (
                <ProjectSummary
                  mainFormValues={formValues}
                  setMainFormValues={setFormValues}
                />
              )}
            </Box>
            <Flex
              w100
              align="center"
              justify="between"
              gap="8px"
              style={{ marginTop: "24px" }}
            >
              <TextButton
                label="Change To Express Test"
                onClick={() => setShowWarningModal(true)}
              />

              {isSuperUser && formValues.status === "pending" && (
                <SecondaryButton
                  variant="admin"
                  label="Set Status In-Review"
                  onClick={() => ApplyForm("in_review")}
                  size="medium"
                />
              )}
              {isSuperUser && formValues.status === "in_review" && (
                <SecondaryButton
                  variant="admin"
                  label="Create Project"
                  onClick={() => setShowCreateProjectModal(true)}
                  size="medium"
                />
              )}

              {showDeleteButton && (
                <SecondaryButton
                  label="Delete Project Request"
                  iconName="remove"
                  variant="danger"
                  onClick={() => setShowDeleteModal(true)}
                  size="medium"
                />
              )}

              <Flex align="center" gap="16px">
                {currentStage === 5 && formValues.status === "draft" && (
                  <TextButton
                    label={editRequest ? "Update Draft" : "Save Draft"}
                    dataTestId="save-draft-btn"
                    onClick={() => ApplyForm("draft")}
                    disabled={isLoading}
                    color={theme.black900}
                  />
                )}
                <SecondaryButton
                  label="Back"
                  onClick={() => {
                    Mixpanel.track("Back button click");
                    setCurrentStage((p) => p - 1);
                  }}
                  disabled={currentStage === 1 || isLoading}
                  size="medium"
                />
                <MainButton
                  label={currentStage === 5 ? "Submit" : "Next"}
                  dataTestId="next-btn"
                  onClick={() => {
                    if (currentStage === 5) {
                      Mixpanel.track("Schedule a Test - Submit");
                      ApplyForm("pending");
                    } else {
                      setCurrentStage((p) => p + 1);
                    }
                  }}
                  inProgress={isLoading}
                  disabled={
                    isLoading ||
                    (currentStage === 5 && formValues.status !== "draft")
                  }
                  size="medium"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {showAddAssetsModal && (
        <Modal
          width="90%"
          header="Add Assets"
          onClose={cancelShowAddAssetsModal}
        >
          <Flex column gap="24px">
            <WhatToScan
              includedAssetsIds={formValues.included_assets}
              isSelectAll={!!formValues.include_all_assets}
              setIsSelectAll={(state) =>
                setFormValues((prev) => ({
                  ...prev,
                  include_all_assets: state,
                }))
              }
              setIncludedAssetsIds={(ids) =>
                setFormValues((prev) => ({ ...prev, included_assets: ids }))
              }
            />
            <Flex justify="end" gap="8px">
              <SecondaryButton
                label="Cancel"
                variant="primary"
                onClick={cancelShowAddAssetsModal}
                size="medium"
              />
              <MainButton
                dataTestId="apply-assets-btn"
                label="Apply"
                onClick={() => setShowAddAssetsModal(false)}
                size="medium"
              />
            </Flex>
          </Flex>
        </Modal>
      )}
      {showShareModal && (
        <ShareModal
          close={() => {
            setShowShareModal(false);
            navigate("/projects?project_requests=true");
          }}
          assetsCount={formValues.included_assets.length || 0}
        />
      )}
      {showWarningModal && (
        <WarningModal
          header="Change to Express Test ?"
          body="Please notice that all the data you've previously entered will be lost"
          onApprove={() => navigate("/projects?express=true")}
          onClose={() => setShowWarningModal(false)}
        />
      )}
      {showDraftModal && (
        <Modal width="414px" onClose={() => setShowDraftModal(false)}>
          <Flex column w100 gap="24px" align="center">
            <Illustration name="success" />
            <HeaderSecondary>Draft Saved Successfully</HeaderSecondary>
            <BodyRegular style={{ textAlign: "center" }}>
              You can always see your drafts in the pending projects panel, in
              the Projects page
            </BodyRegular>
            <MainButton
              label="Great, thanks"
              onClick={() => setShowDraftModal(false)}
              style={{ width: "100%" }}
              size="medium"
            />
            <TextButton
              label="Go to Projects Page"
              onClick={() => navigate("/projects")}
              color={theme.black900}
            />
          </Flex>
        </Modal>
      )}
      {showCreateProjectModal && (
        <CreateProjectModal
          onClose={() => setShowCreateProjectModal(false)}
          formValues={formValues}
          setFormValues={setFormValues}
          applyForm={ApplyForm}
          projectRequestId={parseInt(`${requestId}`)}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onConfirm={handleDeleteRequest}
          onClose={() => setShowDeleteModal(false)}
          isLoading={isDeleting}
          isSuccess={isDeleted}
          itemName="project request"
          itemType="project request"
        />
      )}
    </>
  );
};
