import React, { useContext, useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { SEVERITIES } from "../../../shared/consts";
import { ThemeContext } from "styled-components";
import RiskLegendItem from "../../../components/elements/legend/RiskLegendItem";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Switch } from "../../../components/elements/switch/Switch";
import useToastContext from "../../../hooks/toastHook";
import { useApiMe } from "../../../hooks/queries/meContext";
import { useDebounceCallback } from "../../../hooks/utilsHooks";
import {
  AccountContext,
  useApiUpdateAccount,
} from "../../../hooks/queries/accountsContext";
import {
  DefaultSLAPreBreachedNotificationSettings,
  SLAPreBreachedNotificationSettings,
} from "../../../types/Me";

type Props = {
  width?: string;
};

export const SlaPreBreached = (props: Props) => {
  const { width } = props;
  const addToast = useToastContext();
  const { data: me, refetch } = useApiMe();
  const theme = useContext(ThemeContext);
  const { mutate } = useApiUpdateAccount();
  const updatePolicy = useDebounceCallback((accountContext: AccountContext) =>
    mutate({
      ...accountContext,
      onSuccessCallback: () => {
        addToast({
          message: "Pre-Breach Notification Settings updated successfully",
          type: "success",
        });
        refetch();
      },
      onErrorCallback: (error: Error) =>
        addToast({ message: error.message, type: "error" }),
    })
  );

  const [slaPreBreachedNotifications, setSlaPreBreachedNotifications] =
    useState<SLAPreBreachedNotificationSettings>(
      me?.sla_pre_breached_notifications ||
        DefaultSLAPreBreachedNotificationSettings
    );

  const onChangeHandler = (
    key: keyof SLAPreBreachedNotificationSettings,
    value: number | boolean
  ) => {
    if (!me) return;
    let newPreBreachedSettings: SLAPreBreachedNotificationSettings = {
      ...slaPreBreachedNotifications,
    };
    newPreBreachedSettings[key] = value;
    // Optimistic state update
    setSlaPreBreachedNotifications(newPreBreachedSettings);
    // Debounced API call
    updatePolicy({
      accountId: me?.account_id,
      accountData: { sla_pre_breached_notifications: newPreBreachedSettings },
    });
  };

  return (
    <Box style={{ width: width || "inherit" }}>
      <Flex column gap="24px">
        <Flex justify="between" align="center" gap="8px">
          <Flex align="center" gap="8px">
            <Icon name="notification" color={theme.primary} size={32} />
            <HeaderSubBold>Pre-Breach Notification Settings</HeaderSubBold>
          </Flex>
          <Switch
            checked={slaPreBreachedNotifications.enabled}
            onChange={(checked) => onChangeHandler("enabled", checked)}
            dataTestId="pre-breach-enabled-switch"
          />
        </Flex>
        <SeparatorHorizontal />
        {Object.values(SEVERITIES)
          .reverse()
          .map((severity) => {
            const severityKey =
              severity.toLowerCase() as keyof SLAPreBreachedNotificationSettings;
            return (
              <Flex
                column
                gap="24px"
                key={`severity-key-${severityKey}-${slaPreBreachedNotifications.enabled}`}
              >
                <Flex justify="between" align="center" gap="8px">
                  <RiskLegendItem label={severityKey.toString()} size={14} />
                  <Flex align="center" gap="24px">
                    <InputText
                      type="number"
                      dataTestId={`${severityKey}-input`}
                      value={`${slaPreBreachedNotifications[severityKey]}`}
                      onChange={(e) => {
                        onChangeHandler(severityKey, parseInt(e.target.value));
                      }}
                      className="text-center"
                      width={"160px"}
                    />
                    <BodyRegular>Days Before</BodyRegular>
                    <SeparatorVertical height="16px" />
                    <Switch
                      dataTestId={`${severityKey}-switch`}
                      checked={
                        slaPreBreachedNotifications.enabled &&
                        parseInt(
                          slaPreBreachedNotifications[
                            severityKey
                          ]?.toString() || "0"
                        ) > 0
                      }
                      onChange={(check) =>
                        onChangeHandler(severityKey, check ? 1 : 0)
                      }
                    />
                  </Flex>
                </Flex>
                {severity !== "Info" && <SeparatorHorizontal />}
              </Flex>
            );
          })}
      </Flex>
    </Box>
  );
};
