import React, { useContext, useState } from "react";
import { Box } from "../../components/elements/box/Box";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  AnalysisResult,
  GetCategoriesWithGoodies,
  getCategoriesWithIssues,
  getCategoryName,
  QuickScanGrade,
} from "./QuickScanResultsBL";
import { ThemeContext } from "styled-components";
import {
  HeaderSubBold,
  KPIHuge,
  LabelMedium,
} from "../../components/elements/typography/Typography";
import { Icon } from "../../components/elements/icon/Icon";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";
import { Illustration } from "../../components/elements/illustrations/Illustration";
import { QuickScanContactUsModal } from "./QuickScanContactUsModal";

type Props = {
  analysisResult: AnalysisResult;
};

export const QuickScanResultsViewSummary = ({ analysisResult }: Props) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const getGradeName = () => {
    switch (analysisResult.grade) {
      case "A":
        return "Excellent";
      case "B":
        return "Good";
      case "C":
        return "Fair";
      case "D":
        return "Poor";
      case "F":
        return "Bad";
      default:
        return "Unknown";
    }
  };

  const categoriesWithIssues = getCategoriesWithIssues(analysisResult);

  const categoriesWithGoodies = GetCategoriesWithGoodies(analysisResult);

  const getGradeColor = (grade: QuickScanGrade): string => {
    if (grade === "A") return "greenPrimary";
    if (grade === "B") return "blue700";
    if (grade === "C") return "yellowPrimary";
    if (grade === "D") return "orangePrimary";
    if (grade === "F") return "redPrimary";
    return "redPrimary";
  };

  return (
    <Box
      style={{
        width: isMobile ? "99%" : "70%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {showContactUsModal && (
        <QuickScanContactUsModal onClose={() => setShowContactUsModal(false)} />
      )}
      <Flex
        column={isMobile}
        justify="between"
        gap="16px"
        align={isMobile ? "start" : "center"}
      >
        <Flex align="center" gap="8px">
          <Icon
            name="shield"
            size="24px"
            color={theme[getGradeColor(analysisResult.grade)]}
          />
          <HeaderSubBold>Security Grade</HeaderSubBold>
          {/* <Icon name="info" size="16px" color={theme.black600} /> // until we have a tooltip */}
        </Flex>
        <Flex align="center" gap="8px">
          <SecondaryButton
            label="Contact Us"
            size="small"
            onClick={() => setShowContactUsModal(true)}
          />
          {analysisResult.grade !== "A" && (
            <MainButton
              label="Improve your grade"
              size="small"
              onClick={() => window.location.assign("/register")}
            />
          )}
        </Flex>
      </Flex>
      <Flex column={isMobile} justify="start" gap={isMobile ? "8px" : "48px"}>
        <Flex justify="center">
          <Flex
            column
            justify="center"
            align="center"
            style={{
              border: `14px solid ${
                theme[getGradeColor(analysisResult.grade)]
              }`,
              borderRadius: "50%",
              width: "120px",
              height: "120px",
            }}
          >
            <KPIHuge
              style={{
                lineHeight: "42px",
              }}
            >
              {analysisResult.grade}
            </KPIHuge>
            <LabelMedium>{getGradeName()}</LabelMedium>
          </Flex>
        </Flex>
        <Flex column gap="8px">
          {Object.entries(categoriesWithIssues).map(([category, count]) => (
            <Flex key={`issue-${category}`} align="center" gap="8px">
              <Icon name="securityStatus" size="24px" color={theme.red600} />
              <LabelMedium className="text-capitalize">{`${
                count === 1 ? "One" : count
              } ${getCategoryName(category, count)} issue${
                count > 1 ? "s" : ""
              }`}</LabelMedium>
            </Flex>
          ))}
        </Flex>
        <Flex column gap="8px">
          {Object.entries(categoriesWithGoodies).map(([category, count]) => (
            <Flex key={`goodie-${category}`} align="center" gap="8px">
              <Icon name="checkGreen" size="24px" color={theme.greenPrimary} />
              <LabelMedium className="text-capitalize">{`${
                count === 1 ? "One" : count
              } secure ${getCategoryName(category, count)} test pass${
                count > 1 ? "es" : ""
              }`}</LabelMedium>
            </Flex>
          ))}
        </Flex>
        {!isMobile && (
          <Illustration
            name={
              analysisResult.grade === "A"
                ? "success"
                : analysisResult.grade === "D" || analysisResult.grade === "F"
                ? "error"
                : "education"
            }
            style={{
              height: "140px",
            }}
          />
        )}
      </Flex>
    </Box>
  );
};
