import { Column, Table } from "../../../components/elements/table/Table";
import { useApiWhatsNewPaging } from "../../../hooks/queries/whatsNewContext";
import { WhatsNew } from "../../../types/WhatsNew";
import { Filters } from "../../findings/Findings";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_LAPTOP_WIDTH } from "../../../shared/consts";
import {
  BodyRegular,
  BodyRegularHover,
} from "../../../components/elements/typography/Typography";
import { getDate } from "../../../shared/helper";
import { IconButton } from "../../../components/elements/button/icon/IconButton";

export type Props = {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  setItemOnDisplay: (item: WhatsNew) => void;
};

export const WhatsNewTable = (props: Props) => {
  const { filters, setFilters, setItemOnDisplay } = props;

  const {
    data: whatsNewPages,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useApiWhatsNewPaging({ ...filters, page_size: 10, admin: true });

  const whatsNewList: WhatsNew[] =
    whatsNewPages?.pages.map((page) => page.results || []).flat() || [];

  const screenWidth = useScreenWidth();
  const screenWidthIsLaptop = screenWidth < SCREEN_LAPTOP_WIDTH + 100;

  const columns: Column[] = [
    {
      title: "ID",
      key: "id",
      maxWidth: "70px",
      sortable: true,
    },
    {
      title: "Feature Name",
      key: "feature_name",
      isRequired: true,
      sortable: true,
      minWidth: screenWidthIsLaptop ? "150px" : "272px",
      cell: (row) => (
        <BodyRegularHover
          onClick={() => setItemOnDisplay(row as WhatsNew)}
          className="text-truncate pointer"
        >
          {row.feature_name}
        </BodyRegularHover>
      ),
    },
    {
      title: "Published at",
      key: "published_at",
      maxWidth: "120px",
      sortable: true,
      cell: (row) => (
        <BodyRegular>
          {row.published_at ? getDate(row.published_at) : "N/A"}
        </BodyRegular>
      ),
    },
    {
      title: "Created at",
      key: "created_at",
      maxWidth: "120px",
      sortable: true,
      cell: (row) => (
        <BodyRegular>
          {row.created_at ? getDate(row.created_at) : "N/A"}
        </BodyRegular>
      ),
    },
    {
      title: "",
      key: "",
      maxWidth: "50px",
      cell: (row) => (
        <IconButton
          iconName="chevronRight"
          size="small"
          dataTestId={`open-finding-${row.id}`}
          onClick={() => setItemOnDisplay(row as WhatsNew)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rows={whatsNewList || []}
      minWidth={560}
      hasScrollPagination
      isFetchingNextPage={isFetchingNextPage}
      onScrollPagination={fetchNextPage}
      hasNextPage={hasNextPage}
      defaultSortKey="id"
      defaultSortOrder="desc"
      onSort={(column, order) =>
        setFilters({
          ...filters,
          ordering: order === "desc" ? `-${column.key}` : column.key,
        })
      }
      isLoading={isFetching}
    />
  );
};
