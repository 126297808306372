import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  invalidateApiQueries,
  removeItemFromContext,
  updateContext,
  DEFAULT_STALE_TIME,
} from "./utils";
import {
  getSingleItem,
  getItems,
  createItem,
  updateItem,
  deleteItem,
} from "./sdk";
import { Project } from "../../types/Project";
import { bitAfterNow } from "../../shared/helper";

const key = "projects";

// All possible attributes that can pass to API call
export interface ProjectParams {
  name: string;
  start: string;
  end: string;
  project_request?: number;
  status?: string;
  assets: number[];
  customer: number;
  opinnovate_poc: {
    name: string;
    email: string;
    role: string;
  };
  opinnovate_poc_avatar_url?: string;
  jira_project?: string;
  onSuccessCallback?: (project: Project) => void;
  onErrorCallback?: (error: Error) => void;
}

interface ReportRequest {
  type: string;
}

interface ReportRequestContext {
  reportData: ReportRequest;
}

export interface ProjectContext {
  projectId: number;
  projectData: ProjectParams;
}

export const useApiSingleProject = (enabled: boolean, id: number | undefined) =>
  useQuery<Project | undefined, Error>({
    queryKey: [key, id],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async (): Promise<Project | undefined> =>
      getSingleItem(key, id?.toString() || "").then((data) =>
        data ? (data as Project) : undefined
      ),
  });

export const useApiProjects = (filters?: { [key: string]: any }) =>
  useQuery<Project[], Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    placeholderData: [],
    enabled: true,
    queryFn: async (): Promise<Project[]> => getItems(key, filters),
  });

export const useApiCreateProject = () => {
  const queryClient = useQueryClient();
  return useMutation<Project, Error, ProjectParams>({
    mutationKey: [key],
    mutationFn: async (newProject): Promise<Project> =>
      await createItem(key, newProject),
    onError: (error: Error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSuccess: (data, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
      updateContext({ data, queryKey: [key, { id: data?.id }], queryClient });
    },
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiUpdateProject = () => {
  const queryClient = useQueryClient();
  return useMutation<Project, Error, ProjectContext>({
    mutationKey: [key],
    mutationFn: async ({
      projectId,
      projectData,
    }: ProjectContext): Promise<Project> =>
      await updateItem(key, projectId, projectData),
    onSuccess: (data: Project, context) => {
      context.projectData?.onSuccessCallback &&
        context.projectData.onSuccessCallback(data);
      updateContext({ data, queryKey: [key], queryClient });
    },
    onError: (error, context) =>
      context.projectData?.onErrorCallback &&
      context.projectData.onErrorCallback(error),
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries(["assets"], queryClient);
    },
  });
};

export const useApiReportRequest = ({
  projectId,
  onSuccessCallback,
  onErrorCallback,
}: {
  projectId: number;
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: Error) => void;
}) => {
  return useMutation<ReportRequest, Error, ReportRequestContext>({
    mutationKey: [key, projectId],
    mutationFn: async ({
      reportData,
    }: ReportRequestContext): Promise<ReportRequest> =>
      await createItem(`${key}/${projectId}/create_report_request`, reportData),
    onSuccess: () => onSuccessCallback && onSuccessCallback(),
    onError: (error) => onErrorCallback && onErrorCallback(error),
  });
};

export const useApiDeleteProject = () => {
  const queryClient = useQueryClient();
  return useMutation<Project, Error, Project>({
    mutationKey: [key],
    mutationFn: async (project): Promise<any> =>
      await deleteItem(key, project, project.id),
    onSuccess: (data, variables) => {
      removeItemFromContext({
        data,
        queryKey: [key, { id: variables.id }],
        queryClient,
      });
    },
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};
