import React from "react";
import { IconProps } from "./WaspIcon";

export function PriorityLowIcon(props: IconProps) {
  const size = props.size || 16;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="transparent" />
      <path d="M3.5 10L8 5.5L12.5 10" stroke="#FDC500" strokeWidth="1.125" />
    </svg>
  );
}
