import React, { useContext } from "react";
import { useApiMe } from "../../../hooks/queries/meContext";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Project } from "../../../types/Project";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { Finding } from "../../../types/Finding";
import { isClosedStatus, numberToWord } from "../../../shared/helper";
import moment from "moment";
import { getRiskColor } from "../../../shared/findingsHelper";

type Props = {
  project: Project;
  findings: Finding[];
};

export const AttestationExecutiveSummary = ({ project, findings }: Props) => {
  const { data: me } = useApiMe();
  const { data: products } = useApiProducts({
    id: project.products.join(","),
  });
  const theme = useContext(ThemeContext);

  const customerName = me?.customer.name || "N/A";

  const closedFindings = findings.filter((finding) =>
    isClosedStatus(finding.status)
  ).length;
  const totalFindings = findings.length;

  const numOfCritical = findings.filter(
    (finding) => finding.overall_risk === 4
  ).length;
  const numOfHigh = findings.filter(
    (finding) => finding.overall_risk === 3
  ).length;
  const numOfMedium = findings.filter(
    (finding) => finding.overall_risk === 2
  ).length;
  const numOfLow = findings.filter(
    (finding) => finding.overall_risk === 1
  ).length;

  var text = "each";
  if (totalFindings > closedFindings) {
    // #number_of_fixed_vulnerabilities_out_of_X
    text = `${closedFindings} out of ${totalFindings}`;
  }

  const projectInDays = moment(
    new Date(project.end || "").getTime() -
      new Date(project.start || "").getTime()
  ).days();

  return (
    <Flex column w100 gap="8px" className="mt-5 fully-justify">
      <HeaderSecondary color={theme.blue800}>Executive Summary</HeaderSecondary>
      <BodyRegular color={theme.black800}>
        {`${customerName}, engaged OP Innovate to perform Penetration Testing on their applications: ${(
          products || []
        )
          ?.map((p) => p.name)
          .join(
            ", "
          )}. During the testing window, OP Innovate’s research team evaluated the security weaknesses and their potential impact on the confidentiality, integrity and availability of ${customerName}’s systems, their interaction with the system components, and the data stored within. OP Innovate’s research methodology draws on market leading frameworks such as `}
        <a href="https://owasp.org/www-project-api-security/">
          OWASP Top 10 for APIs
        </a>
        {", "}
        <a href="https://www.sans.org/top25-software-errors">SANS top 25</a>
        {" and "}
        <a href="https://attack.mitre.org/">MITRE ATT&CK</a>.
      </BodyRegular>

      <BodyRegular>
        <BodyRegular color={theme.black800}>
          {`OP Innovate conducted the testing over a period of ${projectInDays} days and assessed the security risk level of ${customerName} assets using their well defined methodology. The research team found `}
        </BodyRegular>

        <BodyRegular color={theme.black800}>
          {!!numOfCritical && (
            <BodyRegular>
              {`${numberToWord(numOfCritical)} `}
              <strong style={{ color: theme[getRiskColor(4)] }}>
                critical
              </strong>
              ,{" "}
            </BodyRegular>
          )}
          {!!numOfHigh && (
            <BodyRegular>
              {`${numberToWord(numOfHigh)} `}
              <strong style={{ color: theme[getRiskColor(3)] }}>
                high
              </strong>,{" "}
            </BodyRegular>
          )}
          {!!numOfMedium && (
            <BodyRegular>
              {`${numberToWord(numOfMedium)} `}
              <strong style={{ color: theme[getRiskColor(2)] }}>
                medium
              </strong>,{" "}
            </BodyRegular>
          )}
          {!!numOfLow && (
            <BodyRegular>
              {`${numberToWord(numOfLow)} `}
              <strong style={{ color: theme[getRiskColor(1)] }}>
                low
              </strong>{" "}
            </BodyRegular>
          )}
          {!numOfCritical && !numOfHigh && !numOfMedium && !numOfLow && (
            <BodyRegular>{`Zero `}</BodyRegular>
          )}

          {`risk ${
            totalFindings === 1 ? "vulnerability" : "vulnerabilities"
          } that could pose a danger to the applications in
          scope.`}
        </BodyRegular>

        <BodyRegular color={theme.black800}>
          {` In each case, a ${customerName} application and / or customers could be adversely affected. Furthermore, the research team also concluded that noticeable efforts have been made by ${customerName} to maintain the application’s security posture and make further recommendations for mitigation purposes.`}{" "}
        </BodyRegular>
      </BodyRegular>

      <BodyBold color={theme.black800}>Remediation of Vulnerabilities</BodyBold>

      <BodyRegular color={theme.black800}>
        {`OP Innovate is pleased to confirm that ${customerName} promptly addressed ${text} of the vulnerabilities found in the testing sprint and implemented appropriate corrective measures. The resolution of these vulnerabilities was confirmed by OP Innovate’s research team during a retest.`}
      </BodyRegular>
      <BodyRegular color={theme.black800}>
        We commend the company for their proactive approach in resolving the
        issues and their commitment to ensuring the highest standards of quality
        and integrity.
      </BodyRegular>
    </Flex>
  );
};
