import { useContext } from "react";
import { LabelRegular } from "../typography/Typography";
import { ThemeContext } from "styled-components";

export const RequiredField = () => {
  const theme = useContext(ThemeContext);
  return (
    <LabelRegular color={theme.redPrimary} style={{ padding: "0 2px" }}>
      *
    </LabelRegular>
  );
};

export const OptionalField = () => {
  const theme = useContext(ThemeContext);

  return (
    <LabelRegular color={theme.black600} style={{ padding: "0 4px" }}>
      Optional
    </LabelRegular>
  );
};
