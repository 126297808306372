import { useEffect, useState } from "react";

/**
 * Custom hook for persisting state to local storage with namespacing.
 *
 * @param key - The unique key under which to store the data.
 * @param initialValue - The initial value of the state.
 * @param isOverrideLocalStorage - A boolean indicating whether to use the value from
 *                                 `initialValue` as the initial state, overriding the
 *                                 local storage.
 * @returns A tuple of [stateful value, function to update it, function to delete it].
 *
 * The value will be stored in local storage under 'key'.
 * If 'key' exists in local storage, it will be used as the initial value.
 * The hook automatically updates local storage whenever the state changes.
 * The delete function removes the key from local storage and resets the state.
 */
export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
  isOverrideLocalStorage: boolean = false
) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item && !isOverrideLocalStorage
        ? (JSON.parse(item) as T)
        : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const [isSizeLimitExceeded, setSizeLimitExceeded] = useState<boolean>(false);

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
      setSizeLimitExceeded(false); // If setItem passed without errors, then no size limit was not exceeded
    } catch (error: any) {
      console.log(error);
      if (isQuotaExceededError(error)) {
        setSizeLimitExceeded(true);
      } else {
        setSizeLimitExceeded(false);
      }
    }
  }, [storedValue, key]);

  const deleteValue = () => {
    try {
      window.localStorage.removeItem(key);
      setStoredValue(initialValue);
    } catch (error) {
      console.log(error);
    }
  };

  const isQuotaExceededError = (e: { message: string; code: number }) => {
    const safariErrMsg = "The quota has been exceeded";
    const fireFoxErrMsg = "The quota has been exceeded";
    const chromeErrMsg = "exceeded the quota";

    return (
      e &&
      e.code === 22 &&
      (e.message.includes(safariErrMsg) ||
        e.message.includes(fireFoxErrMsg) ||
        e.message.includes(chromeErrMsg))
    );
  };

  return [
    storedValue,
    setStoredValue,
    deleteValue,
    isSizeLimitExceeded,
  ] as const;
};
