import { IconProps } from "./WaspIcon";

export function Envelope(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5081 5.85031H7.49196C6.69742 5.85113 5.93566 6.15957 5.37384 6.70797C4.81201 7.25637 4.49601 7.99992 4.49518 8.77547V15.2245C4.49601 16.0001 4.81201 16.7436 5.37384 17.292C5.93566 17.8404 6.69742 18.1489 7.49196 18.1497H16.5081C17.3026 18.1489 18.0643 17.8404 18.6262 17.292C19.188 16.7436 19.504 16.0001 19.5048 15.2245V8.77547C19.504 7.99992 19.188 7.25637 18.6262 6.70797C18.0643 6.15957 17.3026 5.85113 16.5081 5.85031ZM18.3061 15.2245C18.3056 15.6899 18.116 16.136 17.7789 16.465C17.4418 16.794 16.9848 16.9791 16.5081 16.9796H7.49196C7.01524 16.9791 6.5582 16.794 6.22111 16.465C5.88402 16.136 5.69441 15.6899 5.69389 15.2245V8.77547C5.69441 8.31014 5.88402 7.86402 6.22111 7.53499C6.5582 7.20596 7.01524 7.02088 7.49196 7.02037H16.5081C16.9848 7.02088 17.4418 7.20596 17.7789 7.53499C18.116 7.86402 18.3056 8.31014 18.3061 8.77547V15.2245ZM16.9623 9.87583C17.0136 9.93404 17.0528 10.0016 17.0774 10.0745C17.102 10.1475 17.1117 10.2245 17.1058 10.3011C17.0999 10.3777 17.0786 10.4525 17.0432 10.521C17.0077 10.5896 16.9587 10.6507 16.899 10.7008L13.1718 13.8259C12.8466 14.1008 12.4308 14.2521 12.0006 14.2521C11.5704 14.2521 11.1546 14.1008 10.8294 13.8259L7.10098 10.7008C6.98095 10.5994 6.907 10.4557 6.89532 10.3012C6.88364 10.1467 6.93518 9.99399 7.03866 9.87651C7.14213 9.75902 7.2891 9.68637 7.44735 9.67446C7.60561 9.66256 7.76225 9.71237 7.88294 9.81299L11.6102 12.9381C11.7185 13.0299 11.8572 13.0804 12.0006 13.0804C12.144 13.0804 12.2826 13.0299 12.391 12.9381L16.1171 9.81299C16.2377 9.71206 16.3945 9.662 16.553 9.67378C16.7114 9.68556 16.8586 9.75823 16.9623 9.87583Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
