import { useContext, useRef, useState } from "react";
import { Menu } from "../../components/elements/menu/Menu";
import { Flex } from "../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { LinkButton } from "../../components/elements/button/link/LinkButton";
import { NotificationItem } from "./sub-components/NotificationItem";
import { Dropdown, Option } from "../../components/elements/dropdowns/Dropdown";
import {
  useApiNotificationMarkAllRead,
  useApiNotificationReceiversPaging,
  useApiNotificationsCountUnread,
} from "./context/notificationReceiverContext";
import { NotificationItemSkeleton } from "./sub-components/NotificationItemSkeleton";
import { BodyBold } from "../../components/elements/typography/Typography";
import { NotificationReceiver } from "../../types/Notifications";
import useToastContext from "../../hooks/toastHook";
import { SingleValue } from "react-select";
import { Backdrop } from "../../components/elements/rightPane/RightPane";
import { useInfiniteScroll } from "../../hooks/utilsHooks";
import { Loading } from "../../components/elements/loading/Loading";
import { Mixpanel } from "../../shared/mixpanel";
import { useApiMe } from "../../hooks/queries/meContext";
import { useIsPresentationMode } from "../../hooks/useIsPresentationMode";

type Props = {
  showNotificationMenu: boolean;
  onClose: () => void;
};
export const NotificationMenu = (props: Props) => {
  const { showNotificationMenu, onClose } = props;
  const theme = useContext(ThemeContext);
  const isPresentationMode = useIsPresentationMode();
  const { data: me } = useApiMe();
  const [notificationFilterOption, setNotificationFilterOption] = useState<
    SingleValue<Option>
  >({
    label: "All Notifications",
    value: "All",
  });
  const addToast = useToastContext();

  const {
    data: notificationReceivers,
    isLoading: isLoadingNotificationsReceivers,
    isFetchingNextPage: isFetchingNotificationReceiversNextPage,
    hasNextPage: hasNotificationReceiversNextPage,
    fetchNextPage: fetchNotificationReceiversNextPage,
    refetch: refetchNotifications,
  } = useApiNotificationReceiversPaging();

  let pagedNotificationReceivers =
    notificationReceivers?.pages?.map((page) => page?.results || []).flat() ||
    [];

  // Filter notifications by customer for superusers
  if (isPresentationMode)
    pagedNotificationReceivers = pagedNotificationReceivers.filter(
      (n) => n.notification_event.customer === me?.customer.id
    );

  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(
    observerElemForFetchPage,
    !!hasNotificationReceiversNextPage,
    fetchNotificationReceiversNextPage
  );

  const { refetch: refetchNotificationsCount } =
    useApiNotificationsCountUnread();
  const { mutate: MarkAllRead } = useApiNotificationMarkAllRead();

  const sortNotifications = (
    notificationReceivers: NotificationReceiver[]
  ): NotificationReceiver[] => {
    return notificationReceivers.sort((a, b) => {
      // Sort by is_read status first (false values come first)
      if (
        a.is_read === b.is_read &&
        a.notification_event.created_at &&
        b.notification_event.created_at
      ) {
        // If is_read status is the same, sort by created_at date
        const dateA = new Date(a.notification_event.created_at);
        const dateB = new Date(b.notification_event.created_at);
        return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
      }
      return a.is_read ? 1 : -1;
    });
  };

  const filterNotifications = (
    notificationReceivers: NotificationReceiver[],
    notificationFilter: string
  ): NotificationReceiver[] => {
    if (notificationFilter === "All") return notificationReceivers;

    return notificationReceivers.filter(
      (notification) =>
        notification.notification_event.event_type === notificationFilter
    );
  };

  const notificationsToShow = sortNotifications(
    filterNotifications(
      pagedNotificationReceivers,
      notificationFilterOption?.value.toString() || "All"
    )
  );

  return (
    <>
      {showNotificationMenu && (
        <>
          <Backdrop
            onClick={onClose}
            blurEnabled={false}
            transparent
          ></Backdrop>
          <Flex style={{ top: "18px", right: "226px" }} position="relative">
            <Menu pointer minWidth="475px" style={{ maxHeight: "645px" }}>
              <Flex
                padding="0px 8px 8px 0px"
                justify="between"
                w100
                align="center"
              >
                <Dropdown
                  onChange={(op) => {
                    Mixpanel.track("Notifications - Filter Changed", {
                      filter: op?.value,
                    });
                    setNotificationFilterOption(op);
                  }}
                  value={notificationFilterOption}
                  width="145px"
                  options={[
                    { label: "All Notifications", value: "All" },
                    { label: "Created Finding", value: "FINDING_CREATED" },
                    { label: "Deleted Finding", value: "FINDING_DELETED" },
                    { label: "Changed Finding", value: "FINDING_UPDATED" },
                    { label: "New Comment", value: "COMMENT_CREATED" },
                    { label: "Comment Mention", value: "COMMENT_MENTION" },
                    { label: "Sent to Jira", value: "SENT_TO_JIRA" },
                    { label: "Breached SLA", value: "BREACHED_SLA" },
                  ]}
                />

                <LinkButton
                  label={"Mark all as read"}
                  onClick={() => {
                    Mixpanel.track("Notifications - Mark All as Read");
                    onClose();
                    MarkAllRead({
                      onSuccessCallback: () => {
                        refetchNotifications();
                        refetchNotificationsCount();
                        addToast({
                          type: "success",
                          message: "Marked all notifications as read.",
                        });
                      },
                      onErrorCallback: (error) =>
                        addToast({
                          type: "error",
                          message: `Failed to mark all notifications as read: ${error}`,
                        }),
                    });
                  }}
                  iconName="export"
                  size="medium"
                />
              </Flex>
              <Flex
                column
                gap="8px"
                w100
                style={{
                  overflowY: "scroll",
                  marginRight: "8px",
                  paddingBottom: "16px",
                }}
              >
                {isLoadingNotificationsReceivers ? (
                  <>
                    <NotificationItemSkeleton />
                    <NotificationItemSkeleton />
                  </>
                ) : notificationsToShow.length === 0 ? (
                  <Flex
                    column
                    align="center"
                    style={{
                      minWidth: "443px",
                      minHeight: "119px",
                      backgroundColor: `${theme.bg2}`,
                      borderTop: `1px solid ${theme.black500}`,
                      justifyContent: "center",
                    }}
                  >
                    <BodyBold>No recent activity</BodyBold>
                  </Flex>
                ) : (
                  notificationsToShow.map((notificationReceiver) => (
                    <NotificationItem
                      key={notificationReceiver.id}
                      notificationReceiver={notificationReceiver}
                      onClose={onClose}
                    />
                  ))
                )}
                <div className="d-flex" ref={observerElemForFetchPage} />
                {isFetchingNotificationReceiversNextPage && <Loading />}
              </Flex>
            </Menu>
          </Flex>
        </>
      )}
    </>
  );
};
