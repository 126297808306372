import { Menu, MenuBackground } from "../../../components/elements/menu/Menu";
import { MenuItem } from "../../../components/elements/menu/MenuItem";
import { BASE_API_URL } from "../../../hooks/queries/utils";
import { Project } from "../../../types/Project";

type Props = {
  project: Project | null | undefined;
  onClose: () => void;
};

export const ReportsMenu = (props: Props) => {
  const { project, onClose } = props;

  return (
    <>
      <MenuBackground onClick={onClose} />
      <Menu style={{ top: "40px" }}>
        {project?.reports?.map((report, i) => (
          <MenuItem
            key={`report-${i}`}
            label={report.title}
            onClick={() => {
              window.open(
                `${BASE_API_URL}/projects/${project.id}/report/${report.filename}`,
                "_blank"
              );
              onClose();
            }}
          />
        )) || <></>}
      </Menu>
    </>
  );
};
