import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../../components/elements/icon/Icon";
import {
  HeaderSecondary,
  HeaderSubBold,
} from "../../../../components/elements/typography/Typography";
import { getDateTime } from "../../../../shared/helper";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { Flex } from "../../../../components/layouts/flex/Flex";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  headerStyle: "secondary" | "sub";
  width?: string;
};

export const DateItem = (props: Props) => {
  const { finding, noSeparator = false, headerStyle, width } = props;
  const theme = useContext(ThemeContext);
  var date_string = "N/A";
  if (finding?.date_found || finding?.created_at) {
    date_string = getDateTime(finding?.date_found || finding?.created_at);
  }

  return (
    <Section
      title="Date Opened"
      noSeparator={noSeparator}
      headerStyle={headerStyle === "secondary" ? "body" : "label"}
      width={width}
    >
      <Icon name="calender" size={32} color={theme.primary} />
      <Flex align="center">
        {headerStyle === "secondary" ? (
          <HeaderSecondary
            className="text-truncate w-100"
            style={{ color: theme.black700 }}
            title={date_string}
          >
            {date_string}
          </HeaderSecondary>
        ) : (
          <></>
        )}
        {headerStyle === "sub" ? (
          <HeaderSubBold
            className="text-truncate w-100"
            style={{ color: theme.black700 }}
            title={date_string}
          >
            {date_string}
          </HeaderSubBold>
        ) : (
          <></>
        )}
      </Flex>
    </Section>
  );
};
