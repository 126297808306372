import { useContext, useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";
import { useApiScanEventsPaging } from "../../../hooks/queries/scansContext";
import { ThemeContext } from "styled-components";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { Box } from "../../../components/elements/box/Box";
import { TableSkeleton } from "../../../components/elements/table/TableSkeleton";
import { Column, Table } from "../../../components/elements/table/Table";
import moment from "moment";
import { useApiScanners } from "../../../hooks/queries/scannersContext";
import { Icon, IconWarperProps } from "../../../components/elements/icon/Icon";
import { ScanEvent } from "../../../types/ScanEvent";
import { Scanner } from "../../../types/Scanner";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { useDebounceCallback } from "../../../hooks/utilsHooks";
import { NotFoundBanner } from "../../../components/composed/banner/NotFoundBanner";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { ScanResultsPane } from "./ScanResultsPane";
import { Mixpanel } from "../../../shared/mixpanel";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { getDateTime, getTime } from "../../../shared/helper";

export const getAwsLogsLink = (
  awsArn: string | undefined,
  scanner: Scanner | undefined
): string => {
  if (!awsArn || !scanner) {
    return "";
  }
  const arnId = awsArn?.split("/").pop();
  return `https://us-east-2.console.aws.amazon.com/cloudwatch/home?region=us-east-2#logsV2:log-groups/log-group/$252Fecs$252F${scanner.aws_task_name}/log-events/ecs$252F${scanner.aws_container_name}$252F${arnId}`;
};

export const getIconProps = (row: any, theme: any): IconWarperProps => {
  switch ((row as ScanEvent)?.status) {
    case "running":
      return {
        name: "in_progress",
        color: theme.primary,
      };
    case "aborted":
      return {
        name: "dismissed",
        color: theme.redPrimary,
      };
    case "error":
      return {
        name: "dismissed",
        color: theme.redPrimary,
      };
    case "created":
      return {
        name: "add",
        color: theme.primary,
      };
    case "skipped":
      return {
        name: "activeIssues",
        color: theme.yellowPrimary,
      };
    case "done":
      return {
        name: "fixed",
        color: theme.greenPrimary,
      };
    default:
      return {
        name: "fixed",
        color: theme.greenPrimary,
      };
  }
};

type Props = {};
export const ScanHistory = (props: Props) => {
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const isSuperuser = useIsSuperuser();

  const [scanOnDisplay, setScanOnDisplay] = useState<string>("");

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    status: "done,running,error,aborted,created",
  });
  const {
    data: scanEvents,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useApiScanEventsPaging(
    {
      ...filters,
      customer_id: me?.customer?.id,
    },
    !!me?.customer?.id
  );
  const { data: scanners, isFetching: isFetchingScanners } = useApiScanners(
    me?.customer?.id
  );

  const updateSearch = useDebounceCallback((searchText: string) =>
    setFilters((prev) => ({ ...prev, search: searchText }))
  );

  const getScanEvents = () => {
    return scanEvents?.pages?.map((page) => page?.results || []).flat() || [];
  };

  const columns: Column[] = [
    {
      key: "id",
      title: "Scan ID",
      cell: (row) => <BodyRegular>{row.id}</BodyRegular>,
    },
    {
      key: "scanner",
      title: "Scanner Name",
      cell: (row) =>
        scanners?.find((scanner) => scanner.name === row?.scanner)
          ?.display_name || row.scanner,
    },
    {
      key: "status",
      title: "Status",
      cell: (row) => (
        <Flex align="center" gap="8px">
          <Icon {...getIconProps(row, theme)} size={24} />
          <BodyRegular>{row?.status}</BodyRegular>
        </Flex>
      ),
    },
    {
      key: "created_at",
      title: "Start Time",
      cell: (row) => {
        let dateStr = row.created_at;
        dateStr = dateStr.includes("Z") ? dateStr : `${dateStr}Z`;
        let dateObj = new Date(dateStr);

        return (
          <Tooltip
            content={
              <Flex>
                {getDateTime(dateObj)} at {getTime(dateObj)}
              </Flex>
            }
            isTextTruncate
          >
            <BodyRegular>{moment(dateObj).fromNow()}</BodyRegular>
          </Tooltip>
        );
      },
    },
    {
      key: "aws_task_id",
      title: "AWS ID",
      isHidden: !isSuperuser,
      cell: (row) => (
        <Tooltip content={row?.aws_task_id} isTextTruncate>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="d-block text-truncate"
            href={getAwsLogsLink(
              row?.aws_task_id,
              scanners?.find((scanner) => scanner.name === row?.scanner)
            )}
          >
            {row?.aws_task_id}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      key: "",
      maxWidth: "50px",
      cell: (row) => (
        <IconButton
          iconName="chevronRight"
          onClick={() => setScanOnDisplay(row.id)}
          dataTestId={`open-scan-${row.id}`}
        />
      ),
    },
  ];

  return (
    <>
      <Flex column gap="16px" style={{ width: "calc(100% - 380px)" }}>
        <Flex justify="between" gap="24px" align="center" w100>
          <HeaderSubBold>Scan History</HeaderSubBold>
          <Flex>
            <InputText
              iconName="search"
              width={"265px"}
              placeholder="Search"
              onChange={(e) => {
                Mixpanel.track("Scanning Policy - Search used");
                updateSearch(e.target.value);
              }}
              dataTestId="scan-history-search-input"
              isClearable
              onClear={() => updateSearch("")}
              style={{ background: theme.bg2 }}
            />
          </Flex>
        </Flex>
        <Box
          style={{
            padding: "24px",
          }}
        >
          {!getScanEvents()?.length && !isFetching && !isFetchingScanners ? (
            <Flex padding="24px" align="center" justify="center">
              <NotFoundBanner
                title="No results found"
                text={
                  "Sorry, we couldn't find scans that match your search, or you don't have any scans yet."
                }
              />
            </Flex>
          ) : !getScanEvents()?.length && (isFetching || isFetchingScanners) ? (
            <TableSkeleton />
          ) : (
            <Table
              rows={getScanEvents() || []}
              columns={columns}
              onSort={(column, direction) =>
                setFilters({
                  ...filters,
                  ordering: `${direction === "desc" ? "-" : ""}${column.key}`,
                })
              }
              hasScrollPagination
              onScrollPagination={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isFetching || isFetchingScanners}
            />
          )}
        </Box>
      </Flex>
      {!!scanOnDisplay && (
        <ScanResultsPane
          onClose={() => setScanOnDisplay("")}
          scan={getScanEvents().find((s) => s.id === scanOnDisplay)}
        />
      )}
    </>
  );
};
