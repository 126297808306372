import React from "react";
import styled from "styled-components";
import { Flex, fixCSSNames } from "../../layouts/flex/Flex";

type MenuProps = {
  children: JSX.Element[] | JSX.Element;
  minWidth?: string;
  ref?: React.RefObject<HTMLInputElement>;
  pointer?: boolean;
  style?: React.CSSProperties;
  justify?: "center" | "start" | "end" | "between" | "around" | "evenly";
};

type MenuBackgroundProps = {
  blurEnabled?: boolean;
  transparent?: boolean;
  isScrollable?: boolean;
  onClick?: () => void;
};

export const MenuBackgroundStyle = styled.div<MenuBackgroundProps>`
  position: ${(props) => (props.isScrollable ? "absolute" : "fixed")};
  top: ${(props) => (props.isScrollable ? "-100vh" : "0")};
  bottom: 0;
  left: ${(props) => (props.isScrollable ? "-100vw" : "0")};
  right: ${(props) => (props.isScrollable ? "-100vw" : "0")};

  z-index: 120;
  background: transparent;
  cursor: default;
`;

export const MenuStyle = styled.div<MenuProps>`
  position: absolute;
  z-index: 990;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "213px")};
  background-color: ${(props) => props.theme.bg2};
  border: 1px solid ${(props) => props.theme.black500};
  border-radius: 8px;
  padding: 16px;
  gap: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => fixCSSNames(props.justify) || "center"};
  align-items: flex-start;
  box-shadow: ${(props) => `0px 8px 40px ${props.theme.lightCardShadow}`};
`;

type PointerProps = {
  minWidth?: string;
};

export const MenuPointer = styled.div<PointerProps>`
  width: 16px;
  height: 16px;
  position: relative;
  background-color: ${(props) => props.theme.bg2};
  border: 1px solid ${(props) => props.theme.black500};
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: center;
  top: 50%;
  left: ${(props) =>
    `calc(${props.minWidth} / 2 + 8px)`}; // Center horizontally
`;

export const MenuBackground = (props: MenuBackgroundProps) => {
  const { blurEnabled, transparent, isScrollable, onClick } = props;
  return (
    <MenuBackgroundStyle
      onClick={onClick}
      blurEnabled={blurEnabled}
      transparent={transparent}
      isScrollable={isScrollable}
    />
  );
};

export const Menu = (props: MenuProps) => {
  const {
    style,
    pointer = false,
    minWidth = "213px",
    children,
    ref,
    justify,
  } = props;
  return (
    <Flex column style={{ minWidth: minWidth }} position="absolute">
      {pointer && <MenuPointer minWidth={minWidth} />}
      <MenuStyle minWidth={minWidth} ref={ref} style={style} justify={justify}>
        {children}
      </MenuStyle>
    </Flex>
  );
};
