import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { SettingsContext } from "./lexical/context/SettingsContext";
import { SharedAutocompleteContext } from "./lexical/context/SharedAutocompleteContext";
import { SharedHistoryContext } from "./lexical/context/SharedHistoryContext";
import Editor from "./lexical/Editor";
import PlaygroundNodes from "./lexical/nodes/PlaygroundNodes";
import { TableContext } from "./lexical/plugins/TablePlugin";
import "./RichTextEditor.css";
import PlaygroundEditorTheme from "./lexical/themes/PlaygroundEditorTheme";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $generateHtmlFromNodes } from "@lexical/html";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { useDebounce } from "./lexical/plugins/CodeActionMenuPlugin/utils";
import { IMAGE } from "./lexical/plugins/MarkdownTransformers";
import { $generateNodesFromDOM } from "@lexical/html";
import { $createParagraphNode, $getRoot, $insertNodes } from "lexical";
import { ClearEditorPlugin } from "./lexical/plugins/ClearEditorPlugin";
import WaspMentionsPlugin from "./WaspMentionPlugin";
import { WaspMentionNode } from "./WaspMentionNode";
export function RichTextEditor(props) {
    const { label, required, onChange, value, valueTrigger, clearTrigger, placeholderText, theme, users, } = props;
    const initialConfig = {
        namespace: "Playground",
        nodes: [...PlaygroundNodes, WaspMentionNode],
        onError: (error) => {
            throw error;
        },
        theme: PlaygroundEditorTheme,
        editorState: undefined,
    };
    function markdownToJira(mdText) {
        let jiraText = mdText;
        // Headers
        jiraText = jiraText.replace(/### /g, "h3. ");
        jiraText = jiraText.replace(/## /g, "h2. ");
        jiraText = jiraText.replace(/# /g, "h1. ");
        // A bulleted list
        jiraText = jiraText.replace(/\* /g, "- ");
        // A numbered list
        jiraText = jiraText.replace(/1. /g, "# ");
        // Images (This has to be done before links, since links also use similar syntax)
        jiraText = jiraText.replace(/!\[(.*?)\]\((.*?)\)/g, "!$2!");
        // Links
        jiraText = jiraText.replace(/\[(.*?)\]\((.*?)\)/g, "[$1|$2]");
        // Bold
        jiraText = jiraText.replace(/\*\*/g, "*");
        // Italics
        jiraText = jiraText.replace(/_/g, "_");
        // Code
        jiraText = jiraText.replace(/```(\w+)/g, "{code:$1}");
        jiraText = jiraText.replace(/```/g, "{code}");
        // Inline Code
        jiraText = jiraText.replace(/`([^`]+)`/g, "{{$1}}");
        // Strike-through
        jiraText = jiraText.replace(/~~/g, "-");
        // Quotes
        jiraText = jiraText.replace(/> /g, "bq. ");
        return jiraText;
    }
    const updateData = useDebounce((editor) => {
        editor.update(() => {
            const htmlString = $generateHtmlFromNodes(editor, null);
            const markdownString = $convertToMarkdownString([...TRANSFORMERS, IMAGE]);
            onChange(htmlString, markdownToJira(markdownString));
        });
    }, 1000);
    const loadInitialValue = (editor, value) => {
        editor.update(() => {
            if (!value)
                return;
            const parser = new DOMParser();
            const dom = parser.parseFromString(value, "text/html");
            // Once you have the DOM instance it's easy to generate LexicalNodes.
            const nodes = $generateNodesFromDOM(editor, dom)
                .map((node) => {
                if (node.getType() === "text") {
                    if (node.getTextContent().trim() === "") {
                        return null;
                    }
                    else {
                        return $createParagraphNode().append(node);
                    }
                }
                if (node.getType() === "linebreak") {
                    return null;
                }
                return node;
            })
                .filter((node) => node !== null);
            // Select the root
            $getRoot().select();
            // Insert them at a selection.
            // @ts-ignore
            $insertNodes(nodes);
        });
    };
    return (_jsx(SettingsContext, { children: _jsx(LexicalComposer, { initialConfig: initialConfig, children: _jsx(SharedHistoryContext, { children: _jsx(TableContext, { children: _jsx(SharedAutocompleteContext, { children: _jsxs("div", { className: `d-flex flex-column  w-100 form-group ${required ? "required" : ""}`, style: {
                                overflow: "auto",
                            }, children: [_jsx("label", { style: {}, className: "control-label", children: label }), _jsxs("div", { className: "editor-shell w-100", style: theme
                                        ? { color: theme.textBody, borderColor: theme.black500 }
                                        : {}, children: [_jsx(OnChangePlugin, { ignoreSelectionChange: true, onChange: (editorState, editor) => {
                                                if (!valueTrigger || !valueTrigger.current) {
                                                    // if valueTrigger undefined, null or false
                                                    updateData(editor);
                                                }
                                                else {
                                                    // Set initial value for editor
                                                    valueTrigger ? (valueTrigger.current = false) : null;
                                                    loadInitialValue(editor, value);
                                                }
                                            } }), _jsx(ClearEditorPlugin, { clearTrigger: clearTrigger }), _jsx(Editor, { placeholderText: placeholderText }), !!users && (_jsx(WaspMentionsPlugin, { theme: theme, users: users }))] })] }) }) }) }) }) }));
}
