import { BASE_API_URL } from "../hooks/queries/utils";

function _buildImagePath(findingId: number): string {
  return `${BASE_API_URL}/findings/${findingId}/images/?image_name=`;
}

function _getIndicesOf(
  searchStr: string,
  str: string,
  caseSensitive: boolean
): number[] {
  const searchStrLen = searchStr.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0;
  let index = -1;

  const indices = [];

  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
}

export function JiraHtmlImageFixer(
  htmlBody: string,
  findingId: number
): string {
  let searchStr = `<p><span class="error">No usable issue stored in the context, unable to resolve filename `;
  let terminatorStr = `</span></p>`;

  // <p><span class=\"error\">No usable issue stored in the context, unable to resolve filename 'image-20230413-084926.png'</span></p>
  // if no image in html - do nothing
  if (!htmlBody.includes(searchStr)) {
    return htmlBody;
  }
  var indices = _getIndicesOf(searchStr, htmlBody, true);
  var newHtml = htmlBody;

  const getImageNameFromBadHtml = (badHtml: string): string => {
    let imageName = badHtml.slice(searchStr.length);
    // remove the last part and get the image
    return imageName
      .slice(0, imageName.indexOf(terminatorStr))
      .replace(/&#39;/g, "")
      .replace(/'/g, "");
  };

  indices.forEach((startIndex) => {
    let endIndex =
      htmlBody.slice(startIndex).indexOf(terminatorStr) + terminatorStr.length;
    let badImageErrorHtml = htmlBody.substring(
      startIndex,
      startIndex + endIndex
    );
    let imageName = getImageNameFromBadHtml(badImageErrorHtml);
    let goodImageHtml = ` <img alt="comment-attach-${startIndex}" class="col-12 p-2 wasp-image-big"
                           src="${_buildImagePath(findingId)}${imageName}" />`;
    newHtml = newHtml.replace(badImageErrorHtml, goodImageHtml);
  });
  return newHtml;
}

export function addClassToWaspHtmlImages(htmlWasp: string) {
  const regex = /<img/gi;
  const subst = `<img class="wasp-image-big"`;
  return htmlWasp.replace(regex, subst);
}
