import { IconProps } from "./WaspIcon";

export const SlaIcon = (props: IconProps) => {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6039 3.99609H9.39609C7.9644 3.99765 6.5918 4.56708 5.57944 5.57944C4.56708 6.5918 3.99765 7.9644 3.99609 9.39609V14.6039C3.99765 16.0356 4.56708 17.4082 5.57944 18.4206C6.5918 19.4329 7.9644 20.0023 9.39609 20.0039H14.6039C16.0356 20.0023 17.4082 19.4329 18.4206 18.4206C19.4329 17.4082 20.0023 16.0356 20.0039 14.6039V9.39609C20.0023 7.9644 19.4329 6.5918 18.4206 5.57944C17.4082 4.56708 16.0356 3.99765 14.6039 3.99609ZM18.8039 14.6039C18.8026 15.7174 18.3596 16.7849 17.5723 17.5723C16.7849 18.3596 15.7174 18.8026 14.6039 18.8039H9.39609C8.2826 18.8026 7.21509 18.3596 6.42773 17.5723C5.64037 16.7849 5.19744 15.7174 5.19609 14.6039V9.39609C5.19744 8.2826 5.64037 7.21509 6.42773 6.42773C7.21509 5.64037 8.2826 5.19744 9.39609 5.19609H14.6039C15.7174 5.19744 16.7849 5.64037 17.5723 6.42773C18.3596 7.21509 18.8026 8.2826 18.8039 9.39609V14.6039ZM13.5387 11.141C13.5771 11.2098 13.6017 11.2854 13.6109 11.3636C13.6201 11.4419 13.6138 11.5212 13.5924 11.597C13.5709 11.6728 13.5348 11.7436 13.4859 11.8054C13.4371 11.8672 13.3766 11.9188 13.3078 11.9572L12.6 12.3525V13.1619C12.6 13.321 12.5368 13.4737 12.4243 13.5862C12.3117 13.6987 12.1591 13.7619 12 13.7619C11.8409 13.7619 11.6883 13.6987 11.5757 13.5862C11.4632 13.4737 11.4 13.321 11.4 13.1619V13.0226L9.9996 13.8047C9.86081 13.8806 9.69768 13.8986 9.54568 13.8548C9.39367 13.8111 9.26509 13.7091 9.18788 13.571C9.11066 13.433 9.09107 13.27 9.13335 13.1176C9.17563 12.9652 9.27637 12.8356 9.41366 12.7571L11.4 11.6478V7.4508C11.4 7.29167 11.4632 7.13906 11.5757 7.02653C11.6883 6.91401 11.8409 6.8508 12 6.8508C12.1591 6.8508 12.3117 6.91401 12.4243 7.02653C12.5368 7.13906 12.6 7.29167 12.6 7.4508V10.9776L12.7219 10.9096C12.8609 10.8321 13.025 10.813 13.1781 10.8564C13.3312 10.8998 13.4609 11.0022 13.5387 11.141Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
};
