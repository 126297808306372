import { useCallback } from "react";
import { HiddenSSOButton } from "../login/HiddenSSOButton";
import { MICROSOFT_CLIENT_ID } from "../login/LoginSSO";
import { useSearchParams } from "react-router-dom";
import { useApiMe } from "../../../hooks/queries/meContext";
import { IS_DEMO_ENV } from "../../../hooks/queries/utils";

import MicrosoftLogin from "react-microsoft-login";
import GoogleButton from "react-google-button";
import MicrosoftIcon from "../../../assets/icons/microsoft.svg";
import GoogleIcon from "../../../assets/icons/google.svg";
import { Flex } from "../../../components/layouts/flex/Flex";

export const RegisterSSO = () => {
  const themeType = localStorage.getItem("wasp-theme");
  const [searchParams] = useSearchParams();
  const originalUrl = searchParams.get("next");
  const { data: me, isSuccess } = useApiMe();

  if (isSuccess) {
    const afterLoginRoute = `/account`;
    if (me?.customer) window.location.replace(afterLoginRoute);
  }

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");
    const params = {
      response_type: "code",
      client_id:
        "682747812624-9rmgt1hb5g46uh1r4ms9ie3krjq715ss.apps.googleusercontent.com",
      redirect_uri:
        `${window.location.protocol || "http:"}//` +
        `${window.location.host}/${"api/v1/auth/register/google/"}`,
      prompt: "select_account",
      access_type: "offline",
      state: originalUrl || "",
      scope,
    };
    const urlParams = new URLSearchParams(params).toString();
    window.location.replace(`${googleAuthUrl}?${urlParams}`);
  }, [originalUrl]);

  // Save url params in localStorage for using in account registration form
  if (!IS_DEMO_ENV) {
    const demoUserData = searchParams.get("demo_user_data");
    // console.log( demoUserData )
    if (demoUserData) localStorage.setItem("demoUserData", demoUserData);
  }

  return (
    <Flex column>
      <HiddenSSOButton
        title="Sign Up with Google"
        background="transparent"
        color={themeType !== "dark" ? "black" : "white"}
        onClick={() => document.getElementById("google-sso")?.click()}
        icon={GoogleIcon}
      >
        <GoogleButton
          id="google-sso"
          type="light"
          onClick={openGoogleLoginPage}
          style={{
            zIndex: -1,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            opacity: 0,
          }}
        />
      </HiddenSSOButton>

      <HiddenSSOButton
        title="Sign Up with Microsoft"
        background="transparent"
        color={themeType !== "dark" ? "black" : "white"}
        onClick={() => {
          document.getElementById("microsoft-sso")?.click();
        }}
        icon={MicrosoftIcon}
      >
        <MicrosoftLogin
          redirectUri={""}
          clientId={MICROSOFT_CLIENT_ID}
          buttonTheme="light"
          authCallback={() => {}}
          useLocalStorageCache={false}
          // @ts-ignore
          children={
            <div
              id="microsoft-sso"
              style={{
                opacity: "0",
                zIndex: "0",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
            ></div>
          }
        />
      </HiddenSSOButton>
    </Flex>
  );
};
