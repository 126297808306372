import { useContext } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";

export const NotificationItemSkeleton = () => {
  const theme = useContext(ThemeContext);

  return (
    <Flex
      column
      style={{
        minWidth: "443px",
        minHeight: "119px",
        backgroundColor: theme.bg1,
        borderTop: `1px solid ${theme.black500}`,
      }}
    >
      <SkeletonLoading
        width="6px"
        height="6px"
        style={{ position: "relative", borderRadius: "50%", top: "10px" }}
      />

      <Flex column padding="8px" gap="4px">
        <Flex justify="between">
          <SkeletonLoading width="115px" height="16px" />
          <SkeletonLoading width="115px" height="16px" />
        </Flex>
        <Flex gap="8px" column>
          <SkeletonLoading width="135px" height="16px" />
          <SkeletonLoading width="100%" height="48px" />
        </Flex>
      </Flex>
    </Flex>
  );
};
