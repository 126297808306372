import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {};

export const FindingShortCardSkeleton = (props: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <Box
      className="d-flex w-100 "
      style={{
        gap: "12px",
        padding: "0px",
        borderRadius: "8px",
        height: "82px",
        overflowX: "clip",
      }}
    >
      <div // Vertical Status Color Line
        style={{
          width: "4px",
          height: "inherit",
          background: theme.black500,
        }}
      ></div>

      <Flex
        align="center"
        justify="between"
        w100
        style={{ height: "inherit", overflowX: "hidden" }}
      >
        <Flex column className="w-50">
          <SkeletonLoading width="260px" height="24px" />
          <Flex style={{ marginTop: "8px", color: theme.black600 }}>
            <SkeletonLoading width="260px" height="16px" />
            <SkeletonLoading width="260px" height="16px" />
          </Flex>
        </Flex>

        <Flex align="center" justify="end" gap="8px" className="w-50">
          <SkeletonLoading width="100px" height="18px" />
          <SeparatorVertical style={{ height: "22px" }} />

          <SkeletonLoading width="4px" height="4px" />
          <IconButton iconName="chevronRight" disabled onClick={() => {}} />
        </Flex>
      </Flex>
    </Box>
  );
};
