import React, { useContext } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSubBold,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { TextButton } from "../../../../components/elements/button/text/TextButton";
import { Link } from "react-router-dom";
import { RiskScoreDoughnutChart } from "../riskScore/RiskScoreDoughnutChart";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { Badge } from "../../../../components/elements/badge/Badge";
import { useApiRiskScoreHistory } from "../../../../hooks/queries/riskScoreHistoryContext";
import { ThemeContext } from "styled-components";
import { ITimeframeOption } from "../../../findings/findingsCharts/TimeframeDropdown";
import { RiskScoreTrend } from "./RiskScoreTrend";
import { RiskScoreComponentLegend } from "./RiskScoreComponentLegend";

type Props = {
  timeframe: ITimeframeOption;
};

export const RiskScoreOverview = ({ timeframe }: Props) => {
  const theme = useContext(ThemeContext);
  const { data: riskHistoryItems, isFetching } = useApiRiskScoreHistory({
    page_size: 365,
    ordering: "-created_at",
  });

  const getRiskScoreItemBeforeNow = () => {
    const beforeMe = new Date();
    beforeMe.setTime(Date.now() - timeframe.value);
    var prev = riskHistoryItems?.find(
      (item) => new Date(item.created_at) < beforeMe
    );
    if (!prev) {
      // get the last item
      prev = riskHistoryItems?.[riskHistoryItems?.length - 1];
    }
    return prev;
  };

  const getNewestHistoryItem = () => {
    return riskHistoryItems?.[0];
  };
  const latestScore = getNewestHistoryItem();
  const oldestScore = getRiskScoreItemBeforeNow();
  const scoreDelta =
    (latestScore?.risk_score || 0) - (oldestScore?.risk_score || 0);
  const slaDelta =
    (latestScore?.breached_sla_weight || 0) -
    (oldestScore?.breached_sla_weight || 0);
  const findingsDelta =
    (latestScore?.amount_findings_weight || 0) -
    (oldestScore?.amount_findings_weight || 0);
  const criticalDelta =
    (latestScore?.criticals_weight || 0) - (oldestScore?.criticals_weight || 0);

  return (
    <Flex column gap="16px">
      <Flex align="center" gap="24px" justify="between">
        <HeaderSubBold>Overview</HeaderSubBold>
        <Link
          to={`/insights`}
          style={{ textDecoration: "none" }}
          className="text-truncate"
        >
          <TextButton label="Go To Insights" />
        </Link>
      </Flex>
      <LabelRegular>
        Weighted calculation based on vulnerability severity (low, medium, high,
        critical) and high/critical SLA violations, prioritizing severity.
      </LabelRegular>
      <Flex align="center" gap="32px" justify="between" h100>
        <RiskScoreDoughnutChart
          latestRiskHistory={latestScore}
          isLoadingData={isFetching && !scoreDelta}
          size={170}
          labelNumberSize={48}
          labelTextSize={15}
          top={"55px"}
        />
        <Flex column w100 gap="24px">
          <Flex
            column
            justify="between"
            w100
            style={{
              overflow: "hidden",
            }}
            gap="16px"
          >
            <RiskScoreComponentLegend
              componentWeight={latestScore?.criticals_weight || 0}
              scoreDelta={criticalDelta}
              componentType="critical_findings"
            />
            <RiskScoreComponentLegend
              componentWeight={latestScore?.breached_sla_weight || 0}
              scoreDelta={slaDelta}
              componentType="breached_sla"
            />
            <RiskScoreComponentLegend
              componentWeight={latestScore?.amount_findings_weight || 0}
              scoreDelta={findingsDelta}
              componentType="amount_findings"
            />
          </Flex>
          {isFetching && !scoreDelta ? (
            <SkeletonLoading height="26px" width="100%" />
          ) : (
            <Badge
              className="d-flex align-items-center justify-content-between w-100"
              backgroundColor={theme.black300}
              data-testid={scoreDelta > 0 ? "trend-down" : "trend-up"}
              style={{
                padding: "8px 16px",
                height: "auto",
                width: "100%",
              }}
            >
              <BodyRegular
                className="text-truncate"
                title={timeframe.label}
                color={theme.black800}
              >
                {timeframe.label}
              </BodyRegular>
              <RiskScoreTrend scoreDelta={scoreDelta} />
            </Badge>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
