import { useContext, useRef, useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Section } from "../../../components/elements/section/Section";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { Icon } from "../../../components/elements/icon/Icon";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { useApiUpdateAsset } from "../../../hooks/queries/assetsContext";
import { Asset } from "../../../types/Asset";
import { Loading } from "../../../components/elements/loading/Loading";
import useToastContext from "../../../hooks/toastHook";
import { Mixpanel } from "../../../shared/mixpanel";

type Props = {
  asset?: Asset;
};

const JSON_FILE = "application/json";
const YAML_FILE = "application/x-yaml";

export const AssetSchema = (props: Props) => {
  const { asset } = props;
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const [isSchemaSaved, setSchemaSaved] = useState<boolean>(
    asset ? !!asset.is_schema_saved : false
  );
  const { mutate: updateAsset, isLoading } = useApiUpdateAsset();

  const inputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    if (inputRef.current) inputRef.current.click();
  };
  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (![JSON_FILE, YAML_FILE].includes(file.type)) {
      addToast({
        message: `Failed to upload schema. Is this a valid JSON or YAML file?`,
        type: "error",
      });
      return;
    }
    const fileType = file.type === JSON_FILE ? "json" : "yaml";
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result as string;
      if (asset)
        updateAsset({
          assetId: asset.id,
          assetData: {
            schema_content:
              fileType === "json" ? JSON.stringify(content) : content,
          },
          onSuccessCallback() {
            setSchemaSaved(true);
            addToast({
              message: "Successfully uploaded schema.",
              type: "success",
            });
            Mixpanel.track("Asset - Added schema");
          },
          onErrorCallback(error) {
            addToast({
              message: `Failed to upload schema. Error: ${error}`,
              type: "error",
            });
          },
        });
    };

    reader.onerror = (error) => {
      addToast({
        message: `Failed to upload schema. Error: ${error}`,
        type: "error",
      });
    };

    reader.readAsText(file); // Read the file as a text string
  };

  const deleteSchema = () => {
    if (asset)
      updateAsset({
        assetId: asset.id,
        assetData: { schema_content: "" },
        onSuccessCallback(asset) {
          setSchemaSaved(false);
          addToast({
            message: "Successfully deleted schema.",
            type: "success",
          });
        },
        onErrorCallback(error) {
          addToast({
            message: `Failed to delete schema. Error: ${error}`,
            type: "error",
          });
        },
      });
  };

  return (
    <Box>
      <Section title="API Schema (OpenAPI/Swagger)" noSeparator>
        <Flex gap="8px" w100>
          {isSchemaSaved ? (
            <Flex align="center" gap="8px" w100 justify="between">
              <Flex align="center" gap="8px">
                <Icon name="check" color={theme.greenPrimary} size={24} />
                <LabelRegular>Saved</LabelRegular>
              </Flex>
              <Flex>
                <IconButton
                  iconName="remove"
                  color={theme.redPrimary}
                  size="small"
                  label="Delete schema"
                  onClick={() => {
                    deleteSchema();
                  }}
                />
              </Flex>
            </Flex>
          ) : (
            <>
              <LinkButton
                iconName="add"
                label={"Upload Schema - YAML or JSON file"}
                onClick={handleButtonClick}
              />
              <input
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the file input
              />
              {isLoading && <Loading noTitle />}
            </>
          )}
        </Flex>
      </Section>
    </Box>
  );
};
