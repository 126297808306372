import React from "react";
import { IconProps } from "./WaspIcon";

export function ChevronUpIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3365 15.1319C18.224 15.2444 18.0714 15.3076 17.9123 15.3076C17.7532 15.3076 17.6006 15.2444 17.4881 15.1319L12.4232 10.0671C12.3107 9.95458 12.1581 9.89139 11.999 9.89139C11.8399 9.89139 11.6873 9.95458 11.5748 10.0671L6.50999 15.1319C6.39683 15.2412 6.24527 15.3017 6.08795 15.3003C5.93063 15.2989 5.78014 15.2358 5.6689 15.1246C5.55766 15.0133 5.49455 14.8629 5.49319 14.7055C5.49182 14.5482 5.5523 14.3967 5.66159 14.2835L10.727 9.21866C11.0647 8.8815 11.5224 8.69214 11.9996 8.69214C12.4768 8.69214 12.9345 8.8815 13.2722 9.21866L18.3371 14.2835C18.4495 14.3961 18.5126 14.5487 18.5124 14.7078C18.5123 14.8669 18.449 15.0195 18.3365 15.1319Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
