import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";

const TermsOfService = ({ withPadding = false }) => {
  return (
    <Flex column gap="16px" style={{ padding: withPadding ? "20px" : "none" }}>
      <HeaderSecondary className="text-center d-block">
        WASP TERMS & CONDITIONS
      </HeaderSecondary>
      <BodyRegular>
        OP Innovate is happy to offer you to experience with our WASP, subject
        to these WASP Terms & Conditions (“Conditions”). Effective as of date
        hereof (“Effective Date”), these Conditions, are made and entered into
        between OP Innovate (the “Company”) and you (“Customer”).
      </BodyRegular>
      <BodyRegular>
        Purpose. These Conditions are entered into for the purpose of enabling
        Customer to access and use, (subject to the pricing and preconditions
        stipulated in the applicable work order provided to Customer beforehand
        (the "Work Order")), the Company’s products and services offered via the
        Company's WASP Platform for a term as defined in the Work Order (the
        “Services”), to serve Customer’s cyber security needs in an environment
        provided by Customer (the "Environment" and the “Purpose” respectively).
        Company may, but is not obligated to inform Customer, in advance, upon
        any test that it may conduct, and which could have any effect on
        Customer's Environment.Customer acknowledges that in no event shall
        Company be liable for any damages to Customer's Environment, including
        but not limited to damages resulting from the fact that Customer's
        environment is not sufficiently protected and/or continuously backed-up,
        resulting from the Services.In no event shall Customer make use, or
        allow others to make use, of the Services or the Deliverables (as
        defined below), to support production or other decisions, or in
        environments where failure may lead to bodily harm, loss of data or
        damage to property.These Conditions do not obligate Customer to
        purchase, or Company to sell, any products or services whatsoever,
        irrespective of the results of the Customer’s use of the Services.
        Customer may be required to provide information or other materials
        related to Customer's Environment (the "Materials"), as reasonably
        requested by Company for the Purpose. Customer warrants that it is the
        rightful owner of the Materials or has been granted the right to make
        the Materials available to Company, including without derogating from
        the foregoing the addresses and access to the Environment and any other
        information used by Company in order to provide the Services.
      </BodyRegular>
      <BodyRegular>
        Deliverables; Restrictions. Customer shall use the Services and any
        services, products, documents, materials and information provided by
        Company only for the Purpose and shall not allow others to make any use
        thereof and receive via the platform certain analyzations and reports
        (“Deliverables”). With respect to Services and Deliverables, Customer
        shall not, nor shall it allow others to, distribute, transfer, or
        export, make copies, modify, reverse-engineer, attempt to disable, limit
        or circumvent any security or activation or de-activation features,
        decrypt, decompile, disassemble or otherwise attempt to derive source
        code, adapt, translate, or create derivative works, to extract portions
        of files for use in other applications, make use for any illegal or
        immoral purpose or in violation of any third-party rights, or remove,
        obscure, or alter any copyright notices, trademarks, or other
        proprietary rights notices. If the Services include penetration testing
        services, Company’s penetration testing terms and conditions apply,
        except that in case of any inconsistencies with these Conditions, these
        Conditions prevails.
      </BodyRegular>
      <BodyRegular>
        Intellectual Property Rights; Feedback. Company retains all right, title
        and interest in and to the Services and any other services, products or
        property of Company, including without limitation any and all data,
        reports, communications, material, deliverables, work product, software,
        hardware, information, innovations, inventions (including corresponding
        invention disclosures, patent applications and issued patents, including
        all foreign counterparts, divisions, continuations, continuations-
        in-part, reissues, reexaminations, or supplemental patent certificates
        thereof or therefor), know-how, presentations, lectures, or discoveries
        (whether or not patentable or copyrightable), and all enhancements,
        improvements and derivatives thereof, and Customer receives no rights
        thereto except the limited, revocable, non-sublicensable, non-exclusive
        right to use the Services for the Purpose. Any comments,
        recommendations, observations, or other feedback (collectively,
        “Feedback”) by Customer on Company’s products, services, Deliverables or
        other property will belong solely to the Company and the Company will be
        free to use, disclose, incorporate to its products and services,
        reproduce, license or otherwise distribute and exploit the Feedback as
        it sees fit, without obligation to pay royalties or other consideration
        therefor and without restriction of any kind on account of intellectual
        property rights or otherwise. For avoidance of doubt, the ownership
      </BodyRegular>
      <BodyRegular>
        Term and Termination. The term of these Conditions and the license
        granted herein will commence on the Effective Date and continue for a
        period of 12 months, unless earlier terminated in accordance herewith.
        The Company may terminate these Conditions at any time by a written
        notice to the Customer, such termination will become effective
        immediately. The Company’s ownership of Services, the Customer's
        ownership of Deliverables, and the provisions of Sections 2, 3, 4, 5, 6,
        7, 8, and 9 will survive any termination or expiration of these
        Conditions.
      </BodyRegular>
      <BodyRegular>
        Exclusion of Warranties. THE SERVICES AND THE DELIVERABLES ARE PROVIDED
        “AS IS” WITH ALL FAULTS. COMPANY MAKES NO WARRANTIES, EXPRESS, IMPLIED,
        STATUTORY OR OTHERWISE, INCLUDING THE IMPLIED WARRANTIES OF
        AVAILABILITY, 2MERCHANTABILITY, SUPPORT, FITNESS FOR A PARTICULAR
        PURPOSE AND NONINFRINGEMENT, ALL OF WHICH ARE HEREBY EXPRESSLY
        DISCLAIMED.
      </BodyRegular>
      <BodyRegular>
        Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO
        EVENT WILL COMPANY OR ANY REPRESENTATIVE THEREOF BE LIABLE IN CONNECTION
        WITH THESE CONDITIONS TOWARDS ANY PARTY FOR ANY DAMAGES, INCLUDING LOSS
        OF DATA, LOSS OF SYSTEM AVAILABILITY, LOSS OF COMPUTER RUN TIME, LOST
        PROFITS, OR SPECIAL, INCIDENTAL, CONSEQUENTIAL, DIRECT OR INDIRECT
        DAMAGES HOWEVER CAUSED AND WHETHER BASED IN CONTRACT, TORT (INCLUDING
        NEGLIGENCE) OR ANY OTHER THEORY OF LIABILITY. THIS LIMITATION WILL APPLY
        EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
        PARTIES ACKNOWLEDGE THAT THIS IS A REASONABLE ALLOCATION OF RISK GIVEN
        THE CIRCUMSTANCES OF THESE CONDITIONS. CUSTOMER AGREES THAT ITS
        INSURANCES, WITHOUT SUBROGATION AGAINST COMPANY OR ITS REPRESENTATIVES,
        ARE ITS SOLE RECOURSE HEREUNDER. ANY CLAIM AGAINST COMPANY MUST BE MADE
        WITHIN ONE YEAR AFTER THE CAUSE OF ACTION AROSE.
      </BodyRegular>
      <BodyRegular>
        Remedies. Customer hereby warrants to fully indemnify Company for all
        damages and expenses resulting from Client's breach of its
        representations, warranties, covenants and Conditions set forth herein.
        Each party agrees that the obligations provided herein are necessary and
        reasonable in order to protect other party and its business, and each
        party expressly agrees that monetary damages would be inadequate to
        compensate the other party for any breach of representations,
        warranties, covenants and Conditions set forth herein. Accordingly, each
        party agrees and acknowledges that any such violation or threatened
        violation of the foregoing by it will cause irreparable injury to the
        other party and that, without prejudice to any other remedies that may
        be available, in law, in equity or otherwise, the other party will be
        entitled to obtain injunctive relief against the breach or threatened
        breach of these representations, warranties, covenants and Conditions or
        the continuation of any such breach by it without bond in any court
        having jurisdiction.
      </BodyRegular>
      <BodyRegular>
        Miscellaneous. The Customer may not assign or sublicense or otherwise
        transfer the rights or licenses granted hereunder, by Conditions or by
        operation of law, without the prior written consent of the Company.
        These Conditions is the entire Conditions between the parties relating
        to the subject matter hereof, supersede and govern over any
        contradicting terms or documents, and may only be modified in a written
        instrument signed by both parties addressing these Conditions
        specifically. If any provision or clause of these Conditions is held by
        a competent court as invalid or unenforceable, the remainder of these
        Conditions will continue in full force and effect and such invalid or
        unenforceable clause will be interpreted in a manner that is closest to
        the parties’ original intention while being valid and enforceable.
        Notices will be given in writing. These Conditions is governed by the
        laws of the State of Israel without reference to conflicts of law
        principles. Any dispute arising out of these Conditions shall be brought
        exclusively in the courts located in Tel Aviv-Yafo, Israel. The United
        Nations Convention on Contracts for the International Sale of Goods does
        not apply.
      </BodyRegular>
      <BodyRegular>
        Modification of Conditions. Company reserves the right, at any time in
        its sole discretion, to amend or replace any part of these Conditions
        and any document referred to herein, or any part thereof, at any time
        without prior notice. You agree that Company may notify you of any
        updated or new Conditions by posting notice on the Services. All such
        changes are effective immediately when Company posts them, or such later
        date as may be specified in the notice of updated Conditions. Your
        continued use of the Services after such notice confirms your consent to
        and acceptance of such amendment. The most current version of these
        Conditions will govern your use and access of the Services, including
        without limitation any content made available on or through the
        Services. If you object to any such changes, your sole recourse is to
        cease accessing the Services.
      </BodyRegular>
    </Flex>
  );
};

export default TermsOfService;
