import { useMemo } from "react";
import { Modal } from "../../../components/elements/modal/Modal";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiWhatsNewPaging } from "../../../hooks/queries/whatsNewContext";
import { WhatsNew, WhatsNewParams } from "../../../types/WhatsNew";
import { WhatsNewItem } from "./WhatsNewItem";
import { useApiUpdateAccount } from "../../../hooks/queries/accountsContext";
import { useApiMe } from "../../../hooks/queries/meContext";

type Props = {
  onClose: () => void;
  whatsNewPreview?: WhatsNewParams;
};

export const WhatsNewModal = (props: Props) => {
  const { onClose, whatsNewPreview } = props;
  const { data: me } = useApiMe();
  const { mutate: updateAccount } = useApiUpdateAccount();
  const { data: whatsNewPages } = useApiWhatsNewPaging(
    { ordering: "-published_at" },
    !whatsNewPreview
  );

  const whatsNewList: WhatsNew[] = useMemo(
    () => whatsNewPages?.pages.map((page) => page.results || []).flat() || [],
    [whatsNewPages]
  );

  const handleClose = () => {
    if (!whatsNewPreview)
      updateAccount({
        accountData: { has_unseen_whats_new: false },
        accountId: me?.account_id || 0,
      });
    onClose();
  };

  return (
    <Modal
      header="What's new in WASP"
      onClose={handleClose}
      width="700px"
      height="90vh"
    >
      <Flex column gap="24px" style={{ overflowY: "scroll" }}>
        {whatsNewPreview ? (
          <WhatsNewItem whatsNewPreview={whatsNewPreview} />
        ) : (
          whatsNewList.map((item) => (
            <WhatsNewItem whatsNew={item} key={item.id} />
          ))
        )}
      </Flex>
    </Modal>
  );
};
