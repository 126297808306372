import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { Avatar } from "../../../components/elements/avatar/Avatar";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { useApiFindingLogsPaging } from "../../../hooks/queries/findingsLogsContext";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { Loading } from "../../../components/elements/loading/Loading";
import { useApiCreateFindingComment } from "../../../hooks/queries/findingsCommentsContext";
import { FindingCommentsSkeleton } from "./FindingCommentsSkeleton";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import { CommentInput } from "./CommentInput";
import { Finding } from "../../../types/Finding";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { FindingComments } from "./FindingComments";
import { FindingLog } from "../../../types/FindingLog";
import { FindingLogStatusUpdatedCard } from "./FindingLogStatusUpdatedCard";
import { CommentCard } from "./CommentCard";
import { FindingComment } from "../../../types/FindingComment";
import { FindingLogCard } from "./FindingLogCard";
import { FindingLogUpdatedCard } from "./FindingLogUpdatedCard";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { addClassToWaspHtmlImages } from "../../../shared/parser";
import { RichTextEditorWASP } from "../../../components/elements/richTextEditor/RichTextEditor";
import { ShortUser } from "../../../types/Me";
import { handleFindingBase64Images } from "../../admin/research/ImageUtils";
import { useApiCreateFindingImages } from "../../../hooks/queries/findingContext";
import { FindingLogAffectedAssetsUpdatedCard } from "./FindingLogAffectedAssetsUpdatedCard";

export type SortType = "newest" | "oldest";
type FilterType = "comments" | "status" | "all";

const FilterOptions = [
  {
    label: "Comments",
    value: "comments",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "All History",
    value: "all",
  },
];

export const SortOptions = [
  {
    label: "Newest",
    value: "newest",
  },
  {
    label: "Oldest",
    value: "oldest",
  },
];

type Props = {
  finding?: Finding;
  isAdminMode?: boolean;
};

export const FindingHistory = (props: Props) => {
  const { finding, isAdminMode } = props;
  const theme = useContext(ThemeContext);

  const { data: me } = useApiMe();
  const { mutate: createComment, isLoading } = useApiCreateFindingComment();
  const { mutate: createImage } = useApiCreateFindingImages();

  const [sortedMode, setSortedMode] = useState<SortType>("newest");
  const [filteredMode, setFilteredMode] = useState<
    "comments" | "status" | "all"
  >("comments");

  const {
    data: logs,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useApiFindingLogsPaging(
    {
      finding: finding?.id,
      ordering: sortedMode === "newest" ? "-created_at" : "created_at",
      action: filteredMode === "status" ? "FINDING_UPDATED" : "",
      field: filteredMode === "status" ? "status" : "",
      ...(isAdminMode ? { "admin-mode": isAdminMode } : {}),
    },
    !!finding?.id && filteredMode !== "comments"
  );

  const getHistory = (): FindingLog[] => {
    return logs?.pages.map((page) => page?.results || []).flat() || [];
  };

  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(
    observerElemForFetchPage,
    hasNextPage || false,
    fetchNextPage
  );

  const initialValueTriggerRTE = useRef(true);
  const [commentMessage, setCommentMessage] = useState("");
  const [commentMd, setCommentMd] = useState("");
  const [showRichText, setShowRichText] = useState(false);

  const storageKey = `commentOnFinding${finding?.id}`;
  const cachedComment = localStorage.getItem(storageKey) || "";

  useEffect(() => {
    setShowRichText(!!cachedComment);
    if (!finding || !cachedComment || commentMessage) return;
    initialValueTriggerRTE.current = true;
    setCommentMessage(cachedComment);
  }, [finding, cachedComment, commentMessage]);

  const sendComment = () => {
    if (!finding?.id || !commentMessage) return;

    handleFindingBase64Images(
      finding?.id,
      commentMessage,
      commentMd,
      createImage
    ).then(
      ({
        html: updateHtml,
        markdown: updatedMarkdown,
        attachments: commentAttachments,
      }) => {
        // After we've uploaded the images, we can send the comment
        // Fix the images style in the html
        updateHtml = addClassToWaspHtmlImages(updateHtml);
        createComment({
          finding_id: finding?.id,
          comment_html: updateHtml,
          comment_markdown: updatedMarkdown,
          attachments: commentAttachments,
        });
        setCommentMessage("");
        localStorage.removeItem(storageKey);
      }
    );
  };

  const opAssignee: ShortUser = {
    name: finding?.op_jira_assignee?.display_name || "",
    email: finding?.op_jira_assignee?.email || "",
    avatar_url: finding?.op_jira_assignee?.avatar_url || "",
  };

  if ((isFetching && !isFetchingNextPage) || !finding?.id) {
    return <FindingCommentsSkeleton />;
  }

  return (
    <Flex column gap="24px">
      <Flex column gap="0px">
        <Flex align="center" gap="12px">
          <Avatar imageSrc={me?.avatar_url} />

          {!isLoading && showRichText && (
            <RichTextEditorWASP
              allowMentions
              optionalMentionUsers={[opAssignee]}
              required={true}
              label=""
              value={commentMessage}
              valueTrigger={initialValueTriggerRTE}
              onChange={(html: string, markdown: string) => {
                setCommentMessage(html);
                setCommentMd(markdown);
                if (!!markdown) localStorage.setItem(storageKey, markdown);
                else localStorage.removeItem(storageKey);
              }}
              placeholderText={"Enter your comment..."}
            />
          )}
          {!isLoading && !showRichText && (
            <CommentInput onFocus={() => setShowRichText(true)} />
          )}
        </Flex>
        {showRichText && (
          <Flex style={{ paddingLeft: "24px" }}>
            <LinkButton
              label="Cancel"
              iconName="cancel"
              onClick={() => setShowRichText(false)}
              dataTestId="finding-comment-cancel"
            />
            <LinkButton
              label="Send"
              onClick={sendComment}
              dataTestId="finding-comment-send"
              disabled={!commentMd}
              inProgress={isLoading}
              iconName="send"
            />
          </Flex>
        )}
      </Flex>
      <SeparatorHorizontal />

      <Flex align="center" justify="between">
        <Flex align="center">
          <LabelRegular style={{ color: theme.black600 }}>
            Filters:
          </LabelRegular>
          <Dropdown
            size="small"
            onChange={(option) => setFilteredMode(option?.value as FilterType)}
            options={FilterOptions}
            value={FilterOptions.find((o) => o.value === filteredMode)}
            dataTestId="finding-history-filter"
          />
        </Flex>
        <Flex align="center">
          <LabelRegular style={{ color: theme.black600 }}>Sort:</LabelRegular>
          <Dropdown
            size="small"
            onChange={(option) => setSortedMode(option?.value as SortType)}
            options={SortOptions}
            value={SortOptions.find((o) => o.value === sortedMode)}
            dataTestId="finding-history-sort"
          />
        </Flex>
      </Flex>

      <Flex column style={{ overflow: "scroll", height: "inherit" }}>
        {filteredMode === "comments" ? (
          <FindingComments
            finding={finding}
            sort={sortedMode}
            isAdminMode={isAdminMode}
          />
        ) : (
          <Flex column gap="24px">
            {getHistory().map((findingLog, i) => {
              switch (findingLog.action) {
                case "FINDING_UPDATED":
                  if (findingLog.field === "affected_assets")
                    return (
                      <FindingLogAffectedAssetsUpdatedCard
                        findingLog={findingLog}
                        key={`finding-log-${i}`}
                      />
                    );
                  if (findingLog.field === "status")
                    return (
                      <FindingLogStatusUpdatedCard
                        findingLog={findingLog}
                        key={`finding-log-${i}`}
                      />
                    );
                  return (
                    <FindingLogUpdatedCard
                      findingLog={findingLog}
                      key={`finding-log-${i}`}
                    />
                  );
                case "COMMENT_CREATED":
                  if (!(findingLog.comment as FindingComment)?.id) return null;
                  return (
                    <CommentCard
                      comment={findingLog.comment as FindingComment}
                      key={`finding-log-${i}`}
                    />
                  );
                default:
                  return (
                    <FindingLogCard
                      findingLog={findingLog}
                      key={`finding-log-${i}`}
                    />
                  );
              }
            })}
            <Flex
              justify="center"
              className="loader"
              ref={observerElemForFetchPage}
            >
              {isFetchingNextPage ? (
                <Flex column justify="center">
                  <Loading />
                </Flex>
              ) : (
                ""
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
