import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
  HeaderSubHover,
} from "../../../components/elements/typography/Typography";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { ProjectFormProps } from "./ProjectScheduler";
import { ProjectRangeCalendar } from "./ProjectRangeCalendar";
import { TextArea } from "../../../components/elements/input/textArea/TextArea";
import { useContext, useState } from "react";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { ThemeContext } from "styled-components";
import { SimpleCheckbox } from "../../../components/elements/checkbox/SimpleCheckbox";
import { Mixpanel } from "../../../shared/mixpanel";

export const Schedule = (props: ProjectFormProps) => {
  Mixpanel.track("Schedule a Test - Schedule");
  const { mainFormValues, setMainFormValues } = props;
  const theme = useContext(ThemeContext);
  const [showScheduler, setShowScheduler] = useState(false);

  return (
    <Flex w100>
      <Flex column gap="24px" style={{ width: "30%" }}>
        <HeaderSecondary>Schedule Test</HeaderSecondary>
        <BodyRegular>
          Please choose the desired range for the penetration test. Our
          representative will review and confirm the available dates with you
          within 3 business days
        </BodyRegular>
        <ProjectRangeCalendar
          mainFormValues={mainFormValues}
          setMainFormValues={setMainFormValues}
        />
        <SimpleCheckbox
          label="Is Urgent"
          onChange={() =>
            setMainFormValues((prev) => ({
              ...prev,
              test_settings: {
                ...prev.test_settings,
                is_urgent: !mainFormValues.test_settings.is_urgent,
              },
            }))
          }
          isChecked={mainFormValues.test_settings.is_urgent}
        />
        <HeaderSubBold>PT Agenda</HeaderSubBold>
        <TextArea
          placeholder="Please write your PT agenda"
          onChange={(e) =>
            setMainFormValues((prev) => ({
              ...prev,
              test_settings: {
                ...prev.test_settings,
                pt_agenda: e.target.value,
              },
            }))
          }
          value={mainFormValues.test_settings.pt_agenda}
          width="100%"
        />
      </Flex>
      <SeparatorVertical height="65vh" style={{ marginInline: "64px" }} />
      <Flex
        column
        gap="24px"
        w100
        style={{ padding: "32px", overflowY: "hidden" }}
      >
        <Flex justify="between">
          <HeaderSecondary>
            Schedule a call with account manager
          </HeaderSecondary>
          {showScheduler && (
            <IconButton
              iconName="cancel"
              onClick={() => setShowScheduler((prev) => !prev)}
            />
          )}
        </Flex>
        {!showScheduler ? (
          <Flex gap="8px">
            <HeaderSubBold>
              If you prefer, you can proactively schedule a session with our
              representative. To view our availability,
              <HeaderSubHover
                onClick={() => setShowScheduler((prev) => !prev)}
                style={{ color: theme.primary }}
              >
                please click here
              </HeaderSubHover>
            </HeaderSubBold>
          </Flex>
        ) : (
          <>
            <HeaderSubBold>
              Choose an available time slot from the calendar below
            </HeaderSubBold>
            <iframe
              id="google-calendar"
              title="google-calendar"
              allowFullScreen
              style={{
                height: "calc(100vh - 400px)",
                filter:
                  theme.name === "dark"
                    ? "invert(1) hue-rotate(180deg)"
                    : "none",
              }}
              src="https://calendar.app.google/vT7rwsetv7Za8BpE8"
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
