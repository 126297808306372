import React from "react";
import {
  useApiAlertBannerDismissed,
  useApiAlertBannerReceivers,
} from "../../hooks/queries/alertBannerReceiverContext";
import AlertBanner from "../../components/elements/toastTypes/AlertBanner";
import { useApiAlertBannerMessages } from "../../hooks/queries/alertBannerMessageContext";
import { BodyRegular } from "../../components/elements/typography/Typography";

type SystemAlertsProps = {
  onRender?: () => void;
};

export const SystemAlerts = ({ onRender }: SystemAlertsProps) => {
  const { data: alertReceivers, refetch: refetchReceivers } =
    useApiAlertBannerReceivers();
  const { data: alertMessages, refetch: refetchMessages } =
    useApiAlertBannerMessages();
  const { mutate: dismissAlert } = useApiAlertBannerDismissed();

  return (
    <>
      {alertReceivers &&
        alertMessages &&
        alertReceivers
          .filter((alert) => !alert.is_dismissed)
          .map((alertReceiver, idx) => (
            <AlertBanner
              key={idx}
              type={
                alertMessages.find(
                  (alertMessage) =>
                    alertMessage.id === alertReceiver.alert_banner_message
                )?.type
              }
              message={
                <BodyRegular>
                  {
                    alertMessages.find(
                      (alertMessage) =>
                        alertMessage.id === alertReceiver.alert_banner_message
                    )?.message_content
                  }
                </BodyRegular>
              }
              onDismiss={() =>
                dismissAlert({
                  alertBannerReceiverId: alertReceiver.id
                    ? alertReceiver.id
                    : -1,
                  alertBannerReceiverData: { is_dismissed: true },
                  onSuccessCallback: () => {
                    refetchReceivers();
                    refetchMessages();
                  },
                })
              }
              showInPages={["/", "/dashboard"]}
              onRender={onRender}
            />
          ))}
    </>
  );
};
