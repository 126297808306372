import React from "react";
import { Box } from "../../../components/elements/box/Box";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";

export const BottomBoxSkeleton = () => {
  return (
    <Box className="d-flex flex-column gap-24">
      <div className="d-flex flex-column gap-16">
        <SkeletonLoading height="30px" width="120px" />
        <SkeletonLoading height="18px" width="90%" />
        <SkeletonLoading height="18px" width="88%" />
        <SkeletonLoading height="18px" width="95%" />
      </div>
      <div className="d-flex flex-column gap-16">
        <SkeletonLoading height="30px" width="120px" />
        <SkeletonLoading height="18px" width="70%" />
        <SkeletonLoading height="150px" width="150px" />
        <SkeletonLoading height="18px" width="98%" />
        <SkeletonLoading height="18px" width="90%" />
        <SkeletonLoading height="18px" width="92%" />
      </div>
      <div className="d-flex flex-column gap-16">
        <SkeletonLoading height="30px" width="120px" />
        <SkeletonLoading height="18px" width="220px" />
        <SkeletonLoading height="18px" width="420px" />
        <SkeletonLoading height="18px" width="320px" />
      </div>
    </Box>
  );
};
