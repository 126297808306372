import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASE_API_URL, getBaseConfig, invalidateApiQueries } from "./utils";
import { createItem, get } from "./sdk";

const key = "automation";

export interface AutomationContext {
  onSuccessCallback?: (ok: boolean) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiGetJWTAutomation = () =>
  useQuery<string, Error>({
    queryKey: [key],
    keepPreviousData: true,
    placeholderData: "",
    enabled: true,
    queryFn: async (): Promise<string> =>
      get(key)
        .then((res) => res.json())
        .then((res) => {
          return res.token;
        }),
  });

export const useApiCreateAutomation = () => {
  const queryClient = useQueryClient();
  return useMutation<boolean, Error, AutomationContext>({
    mutationKey: [key],
    mutationFn: async (): Promise<boolean> => await createItem(key, {}),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(true),
    onSettled: () => invalidateApiQueries([key], queryClient),

    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiDeleteAutomation = () => {
  const queryClient = useQueryClient();
  return useMutation<boolean, Error, AutomationContext>({
    mutationKey: [key],
    mutationFn: async (): Promise<any> =>
      await fetch(`${BASE_API_URL}/${key}`, {
        ...getBaseConfig("delete"),
      }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(true),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};
