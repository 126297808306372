import { Table } from "../../../components/elements/table/Table";
import { Asset } from "../../../types/Asset";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Dispatch, SetStateAction, useContext } from "react";
import { Icon } from "../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { ProjectRequestParams } from "../../../hooks/queries/projectRequestsContext";
import { CheckboxState } from "../../../components/elements/checkbox/Checkbox";

type Props = {
  assets: Asset[];
  mainFormValues: ProjectRequestParams;
  configuredAssets: Asset[];
  setConfiguredAssets: Dispatch<SetStateAction<Asset[]>>;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
  onSelectAll: (rowsId: number[], state: CheckboxState) => void;
  isSelectAll: boolean;
};

export const AssetsConfTable = (props: Props) => {
  const {
    assets,
    configuredAssets,
    setConfiguredAssets,
    mainFormValues,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    onSelectAll,
    isSelectAll,
  } = props;

  const theme = useContext(ThemeContext);

  const { data: products } = useApiProducts();

  const columns = [
    {
      title: "Asset Name",
      key: "name",
      minWidth: "200px",
      sortable: true,
      cell: (row: Asset) => (
        <Tooltip content={row.name} isTextTruncate>
          <BodyRegular className="text-truncate">{row.name}</BodyRegular>
        </Tooltip>
      ),
    },
    {
      title: "Product",
      key: "product",
      sortable: true,
      cell: (row: Asset) => {
        const prodName = products?.find((p) => p.id === row.product)?.name;
        return (
          <Flex w100 justify="center">
            <Tooltip content={prodName || ""} isTextTruncate>
              <BodyRegular className="text-truncate">{prodName}</BodyRegular>
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      title: "Environment",
      key: "environment",
      sortable: true,
      cell: (row: Asset) => (
        <Flex w100 justify="center">
          <BodyRegular>{row.environment}</BodyRegular>
        </Flex>
      ),
    },
    {
      title: "Configured",
      key: "configured",
      maxWidth: "80px",
      sortable: true,
      cell: (row: Asset) => (
        <Flex w100 justify="center">
          {Object.keys(mainFormValues.assets_configs)?.includes("0") || // ID 0 = configure all assets
          Object.keys(mainFormValues.assets_configs)?.includes(`${row.id}`) ? (
            <BodyRegular data-testid={`${row.id}-configured`}>
              <Icon name="fixed" color={theme.greenPrimary} />
            </BodyRegular>
          ) : (
            <BodyRegular>-</BodyRegular>
          )}
        </Flex>
      ),
    },
  ];

  console.log(configuredAssets);

  return (
    <Table
      isSelectable
      rows={assets}
      columns={columns}
      selectedRows={configuredAssets}
      onSelectedRowsChange={setConfiguredAssets}
      onSelectAll={onSelectAll}
      isSelectAll={isSelectAll}
      hasScrollPagination
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      onScrollPagination={fetchNextPage}
      onSort={(col, ord) => {
        if (col.key === "configured")
          assets.sort((a) =>
            Object.keys(mainFormValues.assets_configs)?.includes(`${a.id}`)
              ? ord === "asc"
                ? 1
                : -1
              : ord === "asc"
              ? -1
              : 1
          );
      }}
    />
  );
};
