import React, { ChangeEvent, useContext, useRef, useState } from "react";
import { LinkButton } from "../link/LinkButton";
import { Loading } from "../../loading/Loading";
import { Flex } from "../../../layouts/flex/Flex";
import { IconButton } from "../icon/IconButton";
import { ThemeContext } from "styled-components";
import useToastContext from "../../../../hooks/toastHook";
import { fileTypeFromBlob } from "file-type";

type Props = {
  label: string;
  validFileTypes?: string[];
  onDelete: () => void;
  onClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  isExist?: boolean;
  fileName?: string;
  width?: number;
};

export const UploadButton = (props: Props) => {
  const addToast = useToastContext();
  const theme = useContext(ThemeContext);
  const {
    label,
    validFileTypes,
    onClick,
    onDelete,
    isExist = false,
    fileName = "",
    width = 100,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isUploadSuccess, setUploadSuccess] = useState<boolean>(isExist);
  const [uploadedFileName, setFileName] = useState<string>(fileName);
  const handleButtonClick = () => {
    if (inputRef.current && !isUploadSuccess) inputRef.current.click();
  };

  const validateFileTypes = async (file: File): Promise<boolean> => {
    /**
     * Validates the file type of a given file using both browser-detected MIME type
     * and the MIME type determined from the file content via `fileTypeFromBlob`.
     *
     * Note: Since IPA file type aren't easily detected we use both methods of
     * detection.
     **/
    let fileType = await fileTypeFromBlob(file);
    return validFileTypes
      ? validFileTypes.includes(file.type) ||
          validFileTypes.includes(fileType?.mime || "")
      : true;
  };

  const handleOnClick = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!onClick) return;

    if (event.target.files) {
      // validate file
      if (!(await validateFileTypes(event.target.files[0]))) {
        addToast({
          type: "error",
          message: `Incorrect type, did you upload a valid file?`,
        });
        return;
      }
      // if valid, show file name
      setFileName(event.target.files[0].name);
    }

    try {
      setLoading(true);
      await onClick(event);
      setLoading(false);
      setUploadSuccess(true);
    } catch (error) {
      addToast({ type: "error", message: `An error occurred: ${error}` });
    }
  };

  const handleOnDelete = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
    setUploadSuccess(false);
    setFileName("");
  };

  return (
    <Flex justify="between" style={{ width: `${width}%` }}>
      <Flex>
        <LinkButton
          iconName={isUploadSuccess ? "checkGreen" : "add"}
          label={isUploadSuccess ? uploadedFileName : label}
          onClick={handleButtonClick}
        />
        <input
          type="file"
          ref={inputRef}
          onChange={handleOnClick}
          style={{ display: "none" }} // Hide the file input
        />
        {isLoading && <Loading noTitle />}
      </Flex>
      {isUploadSuccess && (
        <IconButton
          iconName="remove"
          color={theme.redPrimary}
          size="small"
          label="Delete file"
          onClick={handleOnDelete}
        />
      )}
    </Flex>
  );
};
