import { Modal } from "../../../components/elements/modal/Modal";
import { Flex } from "../../../components/layouts/flex/Flex";
import TermsOfService from "../login/TermsOfService";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";

type Props = {
  onClose: () => void;
  onAccept: () => void;
};

export const TosModal = (props: Props) => {
  const { onClose, onAccept } = props;
  return (
    <Modal
      height="85vh"
      width="750px"
      header="Terms and Conditions"
      onClose={onClose}
      style={{ overflowX: "scroll", padding: "24px" }}
    >
      <TermsOfService />
      <Flex w100 justify="around">
        <SecondaryButton label="Cancel" onClick={onClose} size="medium" />
        <MainButton label="I Agree" onClick={onAccept} size="medium" />
      </Flex>
    </Modal>
  );
};
