import React, { useContext, useMemo } from "react";
import { useApiFindings } from "../../../../hooks/queries/findingContext";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { GraphRiskColors } from "./GraphTypes";
import { ThemeContext } from "styled-components";
import { SEVERITIES } from "../../../../shared/consts";
import { OPEN_STATUSES } from "../../../../types/Finding";
import { BodyBold } from "../../../../components/elements/typography/Typography";

type Props = {
  findingsIds: number[];
};

export const AssetFindingsDistributions = ({ findingsIds }: Props) => {
  const theme = useContext(ThemeContext);
  const { data: findings, isFetching } = useApiFindings({
    id: findingsIds.join(","),
    status: OPEN_STATUSES.join(","),
  });

  const distributeData: Record<number, number> = useMemo(() => {
    if (!findings) return [];
    const groupFindingsByRisk = findings.reduce(
      (acc, curr) => {
        if (!acc[curr.overall_risk]) {
          acc[curr.overall_risk] = 1;
        } else {
          acc[curr.overall_risk] += 1;
        }
        return acc;
      },
      {} as { [key: number]: number }
    );

    return groupFindingsByRisk;
  }, [findings]);

  const totalOpenFindings = findings?.length || 0;

  if (isFetching) {
    return <SkeletonLoading height="14px" width="100%" />;
  }
  if (findings?.length === 0) {
    return (
      <Flex justify="end">
        <BodyBold>All findings are closed</BodyBold>
      </Flex>
    );
  }

  return (
    <Flex align="center" w100 gap="16px">
      <Flex align="center" w100 style={{ height: "14px", borderRadius: "8px" }}>
        {Object.entries(distributeData)
          .reverse()
          .map(([overall_risk, count], i) => (
            <Flex
              key={i}
              title={`${count} ${SEVERITIES[overall_risk]} findings`}
              style={{
                backgroundColor:
                  theme[
                    GraphRiskColors[
                      overall_risk as unknown as keyof typeof GraphRiskColors
                    ]
                  ],
                width: `${(count / totalOpenFindings) * 100}%`,
                height: "14px",
                borderRadius:
                  i === 0 && i === Object.keys(distributeData).length - 1
                    ? "7px"
                    : i === 0
                      ? "7px 0 0 7px"
                      : i === Object.keys(distributeData).length - 1
                        ? "0 7px 7px 0"
                        : "0",
              }}
            ></Flex>
          ))}
      </Flex>
      <BodyBold>{totalOpenFindings}</BodyBold>
    </Flex>
  );
};
