import { useInfiniteQuery } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "./utils";
import { bitAfterNow, getQueryParams } from "../../shared/helper";
import { getPage } from "./sdk";
import { AssetLogsPage } from "../../types/AssetLog";

const key = "assets-logs";

export const useApiAssetLogPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useInfiniteQuery<AssetLogsPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<AssetLogsPage> =>
      getPage(key, { ...filters, page: pageParam }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });
