import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { FilterColumnType, getOptionsPerColumn } from "../FiltersUtils";

type Props = {
  filterColumn: FilterColumnType;
  onChange: (value: string) => void;
  filterValue: string;
};

export const FilterOptions = (props: Props) => {
  const { filterColumn, onChange, filterValue } = props;
  const { data: products } = useApiProducts();
  const options = getOptionsPerColumn(products || [])[filterColumn.value];

  return (
    <Dropdown
      variant="border"
      size="medium"
      value={options.find((c) => c.value.toString() === filterValue)}
      dataTestId="filter-option-value-dropdown"
      width="100%"
      onChange={(option) => onChange(option?.value.toString() || "")}
      placeholder="Select value"
      options={options}
    />
  );
};
