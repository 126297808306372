import styled from "styled-components";

type Props = {
  height?: string;
};

export const SeparatorVertical = styled.div<Props>`
  border-left: 1px solid
    ${(props) => props.style?.color || props.theme.separation};
  height: ${(props) => props.height || "100%"};
  width: 1px;
`;
