import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "./Badge";
import { Tooltip } from "../tooltip/Tooltip";
import { Flex } from "../../layouts/flex/Flex";
import { LabelMedium } from "../typography/Typography";

type Props = {
  tags: string[];
  amountOfTagsToShow: number;
  color?: string;
  backgroundColor?: string;
};

/*
  This component is used to display a compact version of the badges.
  It will show the first 3 badges and then a tooltip with the rest of the badges.
  The amount of badges to show can be passed as a prop.
*/
export const BadgesCompact = (props: Props) => {
  const { tags, amountOfTagsToShow, color, backgroundColor } = props;
  const theme = useContext(ThemeContext);

  return (
    <Tooltip
      placement="bottom"
      content={
        <Flex
          column
          gap="6px"
          style={{ maxHeight: "400px", overflow: "scroll" }}
        >
          {tags
            .slice(amountOfTagsToShow, tags.length)
            .map((tag: string, index: number) => (
              <Badge
                className="d-flex"
                key={`badge-tooltip-${index}`}
                style={{
                  maxWidth: "250px",
                  color,
                  backgroundColor,
                }}
              >
                <LabelMedium className="text-truncate">{tag}</LabelMedium>
              </Badge>
            ))}
        </Flex>
      }
    >
      <Badge
        data-testid="tags-compact-btn"
        style={{
          color: color || theme.blue700,
          backgroundColor: backgroundColor || theme.white100,
          border: `1px solid ${theme.blue100}`,
        }}
      >
        +{tags.length - amountOfTagsToShow}
      </Badge>
    </Tooltip>
  );
};
