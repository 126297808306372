import { QueryClient, QueryKey } from "@tanstack/react-query";
import { bitAfterNow } from "../../shared/helper";
import BlueGrayAvatar from "../../assets/images/avatars/BlueGrayAvatar.svg";
import BlueLightAvatar from "../../assets/images/avatars/BlueLightAvatar.svg";
import BluePrimaryAvatar from "../../assets/images/avatars/BluePrimaryAvatar.svg";
import OrangeAvatar from "../../assets/images/avatars/OrangeAvatar.svg";
import PinkAvatar from "../../assets/images/avatars/PinkAvatar.svg";
import YellowAvatar from "../../assets/images/avatars/YellowAvatar.svg";

export const DEFAULT_PAGE_SIZE = 5000;
export const DEFAULT_STALE_TIME = 60000 * 10; // 10 minutes

export const IS_DEVELOPMENT_MODE =
  document.location.host.startsWith("localhost");
export const IS_TESTING_ENV = window.location.hostname === "wasp-test";
export const IS_PRODUCTION_ENV = window.location.hostname === "wasp.op-c.net";

export const IS_DEMO_ENV =
  window.location.hostname === "wasp-demo.op-c.net" ||
  process.env.REACT_APP_ENVIRONMENT === "demo";
// For running demo mode on localhost add to frontend/.env REACT_APP_ENVIRONMENT=demo

export const BASE_API_URL = "/api/v1";

// Queries Base Configurations Helper //
export const getBaseConfig = (method: string): RequestInit => {
  // get csrf from cookie

  const csrf = getCookie("csrftoken");
  return {
    method,
    credentials: "include", // send cookie with JWT
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrf,
    },
  };
};

// Context Management Helpers //

export const invalidateApiQueries = (
  queryKey: QueryKey,
  queryClient: QueryClient
): void => {
  queryClient.invalidateQueries({ queryKey });
}; // compares and updates the cached context data with query responses

export const waspQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      initialDataUpdatedAt: bitAfterNow(),
      cacheTime: DEFAULT_STALE_TIME,
      staleTime: DEFAULT_STALE_TIME,
      refetchInterval: DEFAULT_STALE_TIME,
    },
  },
});

interface QueryContext {
  data?: any;
  queryKey: QueryKey;
  queryClient: QueryClient;
}

export const updateContext = ({
  data,
  queryKey,
  queryClient,
}: QueryContext): void => {
  queryClient.setQueryData(queryKey, (oldData: any) =>
    oldData ? { ...oldData, ...data } : oldData
  );
};

export const removeItemFromContext = ({
  data,
  queryKey,
  queryClient,
}: QueryContext): void => {
  queryClient.setQueryData(queryKey, (oldData: any) =>
    oldData?.filter((item: any) => item.id !== data.id)
  );
};

export function getRandomAvatar(): string {
  const avatars = [
    BlueGrayAvatar,
    BlueLightAvatar,
    BluePrimaryAvatar,
    OrangeAvatar,
    PinkAvatar,
    YellowAvatar,
  ];
  // return random item from avatars list
  return avatars[Math.floor(Math.random() * avatars.length)];
}

export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
