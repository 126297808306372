import React, { useContext, useEffect, useState } from "react";
import { Box } from "../../../../components/elements/box/Box";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import {
  HeaderSubBold,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Switch } from "../../../../components/elements/switch/Switch";
import {
  Dropdown,
  Option,
} from "../../../../components/elements/dropdowns/Dropdown";
import {
  useApiJiraProjectLabels,
  useApiJiraProjectWorkflow,
} from "../../../../hooks/queries/jiraIntegrationContext";
import { Checkbox } from "../../../../components/elements/checkbox/Checkbox";
import {
  JiraField,
  JiraProject,
  JiraProjectMapping,
} from "../../../../types/JiraIntegration";
import { STATUSES } from "../../../../types/Finding";
import { StatusMap } from "./StatusMap";
import { StatusMapsSkeleton } from "./StatusMapsSkeleton";

type Props = {
  jiraProjectMapping: JiraProjectMapping;
  onChange: (jiraProjectMapping: JiraProjectMapping) => void;
  jiraProject: JiraProject;
};

export const JiraProjectMappingSetup = (props: Props) => {
  const { jiraProjectMapping, onChange, jiraProject } = props;
  const [_jiraProjectMapping, set_JiraProjectMapping] =
    useState<JiraProjectMapping>({
      ...jiraProjectMapping,
      project_key: jiraProject.key,
      project_name: jiraProject.name,
    });

  useEffect(() => {
    var tempJiraProjectMapping = {
      ...jiraProjectMapping,
      project_key: jiraProject.key,
      project_name: jiraProject.name,
    };
    if (!tempJiraProjectMapping.default_label) {
      tempJiraProjectMapping.default_label = "WASP";
    }
    set_JiraProjectMapping(tempJiraProjectMapping);
  }, [jiraProjectMapping, jiraProject]);

  const theme = useContext(ThemeContext);
  const issueTypeOptions: Option[] =
    jiraProject?.issue_types?.map((issueType) => ({
      label: issueType.name,
      value: issueType.id,
    })) || [];

  const { data: jiraLabels, isFetching: isFetchingLabels } =
    useApiJiraProjectLabels(jiraProject.key);
  const { data: jiraWorkflow, isFetching: isFetchingWorkflow } =
    useApiJiraProjectWorkflow(
      jiraProject.key,
      _jiraProjectMapping.issue_type?.id
    );
  const handleOnChange = (
    fieldName: string,
    value: string | boolean | JiraField
  ) => {
    const newMapping = { ..._jiraProjectMapping, [fieldName]: value };
    set_JiraProjectMapping(newMapping);
    onChange(newMapping);
  };

  return (
    <Box
      style={{
        padding: 0,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "534px",
      }}
      key={_jiraProjectMapping.project_key}
    >
      <Flex padding="24px" justify="between" align="center" w100>
        <HeaderSubBold style={{ color: theme.black900 }}>
          {jiraProject.name}
        </HeaderSubBold>
        <Switch
          checked={_jiraProjectMapping?.enabled}
          onChange={(check) => handleOnChange("enabled", check)}
          dataTestId="jira-project-mapping-switch"
        />
      </Flex>
      <SeparatorHorizontal />
      <Flex
        column
        padding="24px"
        w100
        h100
        gap="16px"
        style={{
          opacity: _jiraProjectMapping?.enabled ? 1 : 0.3,
          cursor: _jiraProjectMapping?.enabled ? "auto" : "not-allowed",
          overflowY: "scroll",
        }}
      >
        <Flex gap="24px" align="center" justify="between" w100>
          <Flex column w100 gap="4px">
            <LabelRegular style={{ color: theme.black700 }}>
              Issue Type
            </LabelRegular>
            <Dropdown
              dataTestId="jira-project-mapping-issue-type-dropdown"
              disabled={!_jiraProjectMapping?.enabled}
              size="medium"
              width="100%"
              variant="border"
              options={issueTypeOptions}
              value={issueTypeOptions.find(
                (o) => o.value === _jiraProjectMapping?.issue_type?.id
              )}
              onChange={(option) => {
                handleOnChange("issue_type", {
                  id: option?.value,
                  name: option?.label,
                } as JiraField);
              }}
              placeholder="Select Issue Type"
            />
          </Flex>
          <Flex column w100 gap="4px">
            <Flex justify="between" align="center" w100>
              <LabelRegular style={{ color: theme.black700 }}>
                Created Tickets Label
              </LabelRegular>
              <LabelRegular style={{ color: theme.blue600 }}>
                Optional
              </LabelRegular>
            </Flex>
            <Dropdown
              creatable
              dataTestId="jira-project-mapping-label-dropdown"
              disabled={!_jiraProjectMapping?.enabled}
              size="medium"
              width="100%"
              variant="border"
              placeholder="Create or Select Default Label"
              clearable
              options={jiraLabels?.map((label) => ({
                label,
                value: label,
              }))}
              value={
                _jiraProjectMapping.default_label
                  ? {
                      label: _jiraProjectMapping.default_label,
                      value: _jiraProjectMapping.default_label,
                    }
                  : undefined
              }
              onChange={(option) =>
                handleOnChange("default_label", option?.value.toString() || "")
              }
            />
          </Flex>
        </Flex>
        <Flex gap="8px" align="center">
          <Checkbox
            disabled={!_jiraProjectMapping?.enabled}
            label="Send tickets to this board automatically"
            state={_jiraProjectMapping.auto_send ? "checked" : "unchecked"}
            onChange={(state) =>
              handleOnChange("auto_send", state === "checked")
            }
            dataTestId="jira-project-mapping-auto-send-checkbox"
          />
        </Flex>
        <SeparatorHorizontal />
        {isFetchingLabels || isFetchingWorkflow ? (
          <StatusMapsSkeleton />
        ) : (
          <Flex
            column
            gap="24px"
            h100
            className="pe-2"
            style={{ overflowY: "scroll" }}
          >
            {STATUSES.map((status) => (
              <StatusMap
                key={`status-map-${status}`}
                waspStatus={status}
                jiraStatus={
                  !!_jiraProjectMapping[status]?.id
                    ? _jiraProjectMapping[status]
                    : undefined
                }
                allStatuses={jiraWorkflow || []}
                onChange={(jiraStatus) => handleOnChange(status, jiraStatus)}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
