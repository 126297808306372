import React, { useContext } from "react";
import { Icon } from "../../icon/Icon";
import { StyleMenuButton } from "./MenuButton.style";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { BodyRegular } from "../../typography/Typography";

type Props = {
  label: string;
  disabled?: boolean;
  selected: boolean;
  iconName: string;
  linkTo: string;
};

export const MenuButton = (props: Props) => {
  const { label, disabled = false, iconName, selected, linkTo } = props;
  const theme = useContext(ThemeContext);

  const color = disabled
    ? theme.buttonDisabled
    : selected
      ? theme.blue
      : theme.buttonGray;

  return (
    <Link to={linkTo} style={{ textDecoration: "none" }}>
      <StyleMenuButton
        disabled={disabled}
        selected={selected}
        size={"medium"}
        data-testid={`menu-button-${label}`}
      >
        <Icon name={iconName} size={24} color={color} />
        <BodyRegular
          style={{
            color: "inherit",
            fontWeight: "inherit",
          }}
        >
          {label}
        </BodyRegular>
      </StyleMenuButton>
    </Link>
  );
};
