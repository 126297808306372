import React from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";

export const StatusMapsSkeleton = () => {
  return (
    <Flex
      column
      gap="16px"
      h100
      className="pe-2"
      style={{ overflowY: "scroll" }}
    >
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <Flex align="center" gap="12px" key={`status-map-skeleton-${i}`}>
          <SkeletonLoading width="32px" height="32px" />
          <SkeletonLoading width="60%" height="45px" />
          <SkeletonLoading width="40%" height="45px" />
        </Flex>
      ))}
    </Flex>
  );
};
