import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { suggestions } from "./suggestions";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { AssetsViewProps, Filter } from "../../../types/AssetsView";
import { useSearchParams } from "react-router-dom";
import { toBase64AssetsView } from "../../../shared/helper";
import { SimpleCheckbox } from "../../../components/elements/checkbox/SimpleCheckbox";
import { HoverBox } from "../../../components/elements/box/Box";

export type SuggestedFilterProps = {
  idx: number;
  title: string;
  subTitle?: string;
  filters: Filter[];
};

type Props = {
  assetsView: AssetsViewProps;
  setAssetsView: Dispatch<SetStateAction<AssetsViewProps>>;
};

export const SuggestedFilters = (props: Props) => {
  const { assetsView, setAssetsView } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const [suggestionsState, setSuggestionsState] = useState<number | null>(null);

  const handleAssetsViewChange = (assetViewProps: AssetsViewProps) => {
    const viewDecoded = toBase64AssetsView(assetViewProps);
    searchParams.set("view", viewDecoded);
    setSearchParams(searchParams);
    setAssetsView(assetViewProps);
  };

  const handleSuggestionClick = (idx: number) => {
    let tmpFilters: Filter | Filter[] = [];
    let tmpIdx = null;
    if (suggestionsState !== idx) {
      tmpFilters = suggestions[idx].filters;
      tmpIdx = idx;
    }
    handleAssetsViewChange({ ...assetsView, filters: tmpFilters });
    setSuggestionsState(tmpIdx);
  };

  useEffect(() => {
    if (assetsView?.filters.length === 0) setSuggestionsState(null);
  }, [assetsView]);

  const SuggestedFilter = (sFilter: SuggestedFilterProps) => {
    return (
      <HoverBox style={{ cursor: "pointer" }}>
        <Flex onClick={() => handleSuggestionClick(sFilter.idx)}>
          <SimpleCheckbox
            isChecked={suggestionsState === sFilter.idx}
            onChange={() => {}}
          />
          <Flex column>
            <BodyBold>{sFilter.title}</BodyBold>
            {sFilter.subTitle && (
              <LabelRegular>{sFilter.subTitle}</LabelRegular>
            )}
          </Flex>
        </Flex>
      </HoverBox>
    );
  };

  return (
    <Flex gap="12px" w100 column>
      <HeaderSubBold>Suggest PT Scope By </HeaderSubBold>

      {suggestions.map((s) => (
        <SuggestedFilter
          title={s.title}
          subTitle={s.subTitle}
          filters={s.filters}
          idx={s.idx}
          key={s.idx}
        />
      ))}
    </Flex>
  );
};
