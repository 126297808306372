import { useContext } from "react";
import styled, { CSSProperties, ThemeContext } from "styled-components";
import { Icon } from "../../icon/Icon";
import { TypographyLabelMedium } from "../../typography/Typography";
import "../buttonsAnimation.css";

type SecondaryButtonVariant = "primary" | "danger" | "admin" | "white";
type ButtonProps = {
  label: string;
  size?: "small" | "medium";
  onClick: () => void;
  inProgress?: boolean;
  disabled?: boolean;
  iconName?: string;
  dataTestId?: string;
  style?: CSSProperties;
  variant?: SecondaryButtonVariant;
};

const getVariantStyles = (variant: SecondaryButtonVariant, theme: any) => {
  switch (variant) {
    case "danger":
      return {
        color: theme.redPrimary,
        borderColor: theme.redPrimary,
        hoverBackgroundColor: theme.redPrimaryOpacity20,
        activeColor: theme.red800,
        activeBorderColor: theme.red800,
      };
    case "admin":
      return {
        color: theme.blue800,
        borderColor: theme.blue800,
        hoverBackgroundColor: theme.blue100,
        activeColor: theme.yellowPrimary,
        activeBorderColor: theme.secondary,
      };
    case "white":
      return {
        color: theme.white100,
        borderColor: theme.white100,
        hoverBackgroundColor: theme.blue700,
        activeColor: theme.blue800,
        activeBorderColor: theme.blue800,
      };
    default:
      return {
        color: theme.primary,
        borderColor: theme.primary,
        hoverBackgroundColor: theme.blue100,
        activeColor: theme.secondary,
        activeBorderColor: theme.secondary,
      };
  }
};

const SecondaryButtonStyle = styled.button<ButtonProps>`
  ${TypographyLabelMedium}
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.size === "medium" ? "12px 24px" : "8px 16px")};
  gap: 8px;
  background-color: transparent;
  color: ${(props) =>
    getVariantStyles(props.variant || "primary", props.theme).color};
  border: 1px solid
    ${(props) =>
      getVariantStyles(props.variant || "primary", props.theme).borderColor};
  border-radius: 24px;
  &:hover:enabled {
    background-color: ${(props) =>
      getVariantStyles(props.variant || "primary", props.theme)
        .hoverBackgroundColor};
  }

  &:active:enabled {
    color: ${(props) =>
      getVariantStyles(props.variant || "primary", props.theme).activeColor};
    border: 1px solid
      ${(props) =>
        getVariantStyles(props.variant || "primary", props.theme)
          .activeBorderColor};
  }

  &:disabled {
    color: ${(props) => props.theme.black600};
    border: 1px solid ${(props) => props.theme.black600};
  }

  &:focus {
    outline: none;
  }
`;

export const SecondaryButton = (props: ButtonProps) => {
  const {
    label,
    size = "small",
    onClick,
    inProgress = false,
    disabled = false,
    iconName,
    style,
    variant = "primary",
  } = props;
  const theme = useContext(ThemeContext);
  return (
    <SecondaryButtonStyle
      className={
        inProgress
          ? `loading-animation ${
              theme.name === "dark" ? "loading-animation-dark" : ""
            }`
          : ""
      }
      onClick={onClick}
      disabled={disabled || inProgress}
      size={size}
      data-testid={props.dataTestId}
      label={label}
      style={style}
      variant={variant}
    >
      <>
        {!inProgress && !!iconName && (
          <Icon
            color={disabled ? theme.black600 : "inherit"}
            name={iconName}
            size={24}
          />
        )}
        {label}
      </>
    </SecondaryButtonStyle>
  );
};
