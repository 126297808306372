import { Icon } from "../icon/Icon";
import { BodyBold } from "../typography/Typography";

type Props = {
  noTitle?: boolean;
};
export const Loading = (props: Props) => {
  const { noTitle } = props;
  return (
    <div
      className="d-flex align-items-center justify-content-center gap-8 p-1"
      data-testid="loading"
    >
      <Icon name="spinner" size={24} />
      {!noTitle && <BodyBold>Loading...</BodyBold>}
    </div>
  );
};
