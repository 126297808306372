import { useState } from "react";
import { SearchBar } from "../../../components/composed/searchBar/SearchBar";
import { Box } from "../../../components/elements/box/Box";
import { HeaderMain } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { WhatsNewTable } from "./WhatsNewTable";
import { Filters } from "../../findings/Findings";
import { WhatsNewEditor } from "./WhatsNewEditor";
import { WhatsNew } from "../../../types/WhatsNew";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { useDebounceCallback } from "../../../hooks/utilsHooks";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";

export const WhatsNewControlPanel = () => {
  const isSuperuser = useIsSuperuser();
  const [filters, setFilters] = useState<Filters>({});
  const [showEditor, setShowEditor] = useState(false);
  const [itemOnDisplay, setItemOnDisplay] = useState<WhatsNew | undefined>();

  const onCloseEditor = () => {
    // Remove item from local storage if no change from saved
    if (
      itemOnDisplay &&
      itemOnDisplay.html ===
        localStorage.getItem(`whats_new_item_html${itemOnDisplay.id}`)
    )
      localStorage.removeItem(`whats_new_item_html${itemOnDisplay.id}`);
    setItemOnDisplay(undefined);
    setShowEditor(false);
  };

  const handleSearch = useDebounceCallback((search: string) => {
    setFilters((prev) => ({ ...prev, search }));
  }, 500);
  if (!isSuperuser) return null;
  return (
    <Flex style={{ height: "calc(100vh - 90px)" }}>
      <Flex column gap="12px" w100>
        <Flex justify="between">
          <HeaderMain>Features Updates</HeaderMain>
          <Flex align="center" gap="16px">
            <SearchBar onChange={handleSearch} />
            <MainButton
              iconName="plus"
              label="Present New Feature"
              onClick={() => setShowEditor(true)}
            />
          </Flex>
        </Flex>

        <Box>
          <Flex column>
            <WhatsNewTable
              filters={filters}
              setFilters={(filters) => setFilters(filters)}
              setItemOnDisplay={(item) => setItemOnDisplay(item)}
            />
          </Flex>
        </Box>
      </Flex>

      {(itemOnDisplay || showEditor) && (
        <RightPane onClose={onCloseEditor} width="700px">
          <WhatsNewEditor whatsNew={itemOnDisplay} onClose={onCloseEditor} />
        </RightPane>
      )}
    </Flex>
  );
};
