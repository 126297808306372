import { useEffect, useState } from "react";
import { Modal } from "../../../../components/elements/modal/Modal";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import { HeaderSecondary } from "../../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { AccountDetails } from "./AccountDetails";
import { ProjectsSetup } from "./ProjectsSetup";
import {
  CustomerJiraDetails,
  JiraProjectMapping,
} from "../../../../types/JiraIntegration";
import { IntegrationOverview } from "./IntegrationOverview";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { FormStages } from "../../../../components/elements/formStages/FormStages";
import { Mixpanel } from "../../../../shared/mixpanel";

type Props = {
  onClose: () => void;
};

export const JiraIntegrationModal = (props: Props) => {
  const { onClose } = props;
  const [stage, setStage] = useState<number>(1);
  const { data: me } = useApiMe();
  const [jiraDetails, setJiraDetails] =
    useState<CustomerJiraDetails | null>(null);
  const [jiraProjectsMapping, setJiraProjectsMapping] = useState<
    JiraProjectMapping[]
  >(me?.customer?.jira_projects_mapping || []);

  // this use effect is needed to update the jiraDetails state when the me query is done
  useEffect(() => {
    setJiraProjectsMapping(me?.customer?.jira_projects_mapping || []);
  }, [me?.customer?.jira_projects_mapping]);

  return (
    <Modal onClose={onClose} width="1088px" keepOpenOnOutsideClick>
      <Flex
        column
        gap="24px"
        style={{
          maxHeight: "calc(100vh - 150px)",
          height: "824px",
          overflow: "auto",
        }}
        justify="between"
      >
        <Flex column gap="24px" w100>
          <Flex
            justify="between"
            align="center"
            gap="16px"
            className="pt-1"
            w100
          >
            <Flex align="center" gap="16px">
              <Icon name="jira" size={32} />
              <HeaderSecondary>Jira Integration</HeaderSecondary>
            </Flex>
            <FormStages
              stages={["Account Details", "Projects Setup", "Overview"]}
              currentStage={stage}
            />
          </Flex>
          <SeparatorHorizontal />
        </Flex>
        {stage === 1 && (
          <AccountDetails
            onNext={(customerJiraDetails) => {
              Mixpanel.track("Integrate Jira - Account details filled");
              setJiraDetails(customerJiraDetails);
              setStage(2);
            }}
          />
        )}
        {stage === 2 && (
          <ProjectsSetup
            jiraProjectsMapping={jiraProjectsMapping}
            onNext={(jiraProjectsMapping) => {
              Mixpanel.track("Integrate Jira - Project setup done");
              setJiraProjectsMapping(jiraProjectsMapping);
              setStage(3);
            }}
            onPrev={() => setStage(1)}
          />
        )}
        {stage === 3 && !!jiraDetails && (
          <IntegrationOverview
            jiraProjectsMapping={jiraProjectsMapping.filter((p) => p.enabled)}
            jiraDetails={jiraDetails}
            onPrev={() => setStage(2)}
            onNext={() => {
              Mixpanel.track("Integrate Jira - Overview done");
              onClose();
            }}
          />
        )}
      </Flex>
    </Modal>
  );
};
