import { Dispatch, SetStateAction, useContext } from "react";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import {
  BodyRegular,
  BodyRegularHover,
} from "../../../../components/elements/typography/Typography";
import { Project } from "../../../../types/Project";
import { Section } from "../../../../components/elements/section/Section";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { useApiProjects } from "../../../../hooks/queries/projectsContext";
import { getProject } from "../../../../shared/findingsHelper";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { useParams } from "react-router";
import {
  objectsToOptions,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";

type Props = {
  isAutomated?: boolean;
  isEditable?: boolean;
  project?: Project | null;
  noSeparator?: boolean;
  width?: string;
  onClickProject?: (project: Project) => void;

  finding: Finding | undefined | null;
  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const DiscoverOnItem = (props: Props) => {
  const {
    project: prefetchedProject,
    isEditable,
    noSeparator = false,
    isAutomated = false,
    onClickProject,

    finding,
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const theme = useContext(ThemeContext);

  const { data: projects } = useApiProjects();
  const project = finding ? getProject(finding, projects) : prefetchedProject;

  const { id: updateFindingId } = useParams();
  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  return (
    <Section title="Discovered on" noSeparator={noSeparator}>
      {!isEditable ? (
        <Flex
          w100
          style={{ overflow: "hidden" }}
          onClick={() => project && onClickProject && onClickProject(project)}
        >
          <Tooltip
            isTextTruncate
            placement="bottom"
            content={
              isAutomated || project?.type?.toLowerCase() === "asm"
                ? "ASM"
                : project?.name || "N/A"
            }
          >
            {isAutomated || project?.type?.toLowerCase() === "asm" ? (
              <Flex gap="8px">
                <Icon name="wasp" size={24} color={theme.primary} />
                <BodyRegular>Automated</BodyRegular>
              </Flex>
            ) : !!onClickProject ? (
              <BodyRegularHover className="text-truncate">
                {project?.name || "N/A"}
              </BodyRegularHover>
            ) : (
              <BodyRegular className="text-truncate">
                {project?.name || "N/A"}
              </BodyRegular>
            )}
          </Tooltip>
        </Flex>
      ) : (
        <Dropdown
          searchable
          placeholder="Select project"
          size="medium"
          disabled={
            !editableUpdateFindingData?.customer || queryStatus !== "idle"
          }
          options={
            projects
              ? objectsToOptions(
                  projects?.filter(
                    (p) => p.customer === editableUpdateFindingData?.customer
                  ) || []
                )
              : []
          }
          value={{ value: `${project?.id}`, label: `${project?.name}` }}
          queryStatus={changedField === "project" ? queryStatus : undefined}
          onChange={(opt) => {
            if (opt?.value) {
              setEditableUpdateFinding &&
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  project: Number(opt.value),
                }));
              updateInPlace({ project: Number(opt.value) });
              setCachedFindingData &&
                setCachedFindingData({ project: Number(opt.value) });
            }
          }}
        />
      )}
    </Section>
  );
};
