import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import ColumnsEditor, {
  getDefaultColumnsState,
} from "../../../components/elements/table/ColumnsEditor";
import { Column, Table } from "../../../components/elements/table/Table";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import {
  useApiAssetsTags,
  useApiAssetsWithViewPaging,
  useApiUpdateAsset,
} from "../../../hooks/queries/assetsContext";
import { getDate } from "../../../shared/helper";
import { Asset, AssetDomainProperties, AssetEdit } from "../../../types/Asset";
import { AssetGradeBadge } from "./AssetGradeBadge";
import { AssetPane } from "../assetsPane/AssetPane";
import { FindingsItem } from "./FindingsItem";
import { AssetCoverage } from "./AssetCoverage";
import {
  convertAssetToEdit,
  getPriorityLabel,
  priorityOptions,
} from "../AssetUtils";
import { Filter } from "../../../types/AssetsView";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useMutationWithDelay } from "../../../hooks/utilsHooks";
import useToastContext from "../../../hooks/toastHook";
import { environmentOptions } from "../../../shared/consts";
import { TagsLine } from "../../../components/composed/tagsLine/TagsLine";
import { NotFoundBanner } from "../../../components/composed/banner/NotFoundBanner";
import { useSearchParams } from "react-router-dom";
import { TableSkeleton } from "../../../components/elements/table/TableSkeleton";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { AssetCoverageIndicator } from "../assetsPane/AssetCoverageScoreProgressBar";
import { ThemeContext } from "styled-components";
import { CheckboxState } from "../../../components/elements/checkbox/Checkbox";
import { AssetVerificationIndicator } from "../assetVerification/AssetVerificationIndicator";
import { Flex } from "../../../components/layouts/flex/Flex";

const editableColumns = {
  Name: true,
  Product: true,
  Type: false,
  Source: false,
  "Discovered On": false,
  Findings: false,
  Priority: true,
  Environment: true,
  "Security Grade": false,
  "IP Addresses": false,
  "Coverage Score": false,
  Coverage: true,
  Tags: false,
};

export type Props = {
  filters: Filter[];
  isEditable?: boolean;
  isSelectable?: boolean;
  selectedAssets?: Asset[];
  selectedAssetsIds?: number[];
  onRowSelect?: (row: Asset, state: CheckboxState) => void;
  onSelectAll?: (rowsId: number[], state: CheckboxState) => void;
  setTotalAssets?: (total: number) => void;
  isSelectAll?: boolean;
  setIsSelectAll?: (isSelectAll: boolean) => void;
  hasBackToTopButton?: boolean;
  stateStorageKey?: string;
  isRowClickable?: boolean;
  setShowVerifyAssetModal?: Dispatch<SetStateAction<number>>;
};

export const AssetsTable = (props: Props) => {
  const {
    filters,
    isEditable = false,
    isSelectable = false,
    selectedAssets = [],
    selectedAssetsIds = [],
    setTotalAssets,
    isSelectAll,
    setIsSelectAll,
    onRowSelect,
    onSelectAll,
    hasBackToTopButton,
    stateStorageKey = "assetsTableColumnsState",
    isRowClickable = false,
    setShowVerifyAssetModal,
  } = props;

  const maxTableColumns = stateStorageKey === "what_to_scan" ? 6 : 10;
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view");
  const assetId = searchParams.get("assetId");
  const [showAssetsPane, setShowAssetsPane] = useState<boolean>(
    !!assetId || false
  );

  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null); // Asset on display on right pane
  const [showColumnsEditorPane, setShowColumnsEditorPane] =
    useState<boolean>(false);
  const [ordering, setOrdering] = useState<string>("created_at");

  const {
    data: assets,
    isFetching: isAssetsFetching,
    isRefetching: isAssetsRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useApiAssetsWithViewPaging(filters, ordering, false);

  const { data: allTags } = useApiAssetsTags(isEditable);
  const { data: products } = useApiProducts();
  const { mutate: updateAsset } = useApiUpdateAsset();
  const { runMutation } = useMutationWithDelay(3000);

  const assetsMapped = useMemo(
    () =>
      assets?.pages
        .map((page) => page.results || [])
        .flat()
        .map((asset) => ({
          ...asset,
          isDead:
            asset.type === "domain" &&
            (asset.properties as AssetDomainProperties)?.status_code === 0,
        })) || [],
    [assets]
  );

  const onSelectedAsset = (asset: Asset) => {
    setSearchParams({
      ...searchParams,
      view: view || "",
      assetId: `${asset.id}`,
    });
    setShowAssetsPane(true);
  };

  const onCloseAssetPane = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.delete("assetId");
      newParams.delete("commentId");
      return newParams;
    });
    setSelectedAsset(null);
    setShowAssetsPane(false);
  };

  useEffect(() => {
    if (!!setTotalAssets && !!assets?.pages) {
      setTotalAssets(assets.pages[0].count || 0);
    }
  }, [assets, setTotalAssets]);

  useEffect(() => {
    // Open asset pane if assetId added on url params while on assets page
    !!assetId && setShowAssetsPane(true);
  }, [assetId]);

  const notSelectedOption: Option = {
    label: "Not selected",
    value: -1,
  };

  const productsOptions: Option[] =
    products?.map((p) => ({
      value: p.id,
      label: p.name,
    })) || [];

  const updateSingleAsset = (
    asset: Asset,
    key: string,
    value: string | number | string[] | boolean
  ) => {
    var updatedAsset: AssetEdit = convertAssetToEdit({
      ...asset,
      [key]: value,
    });
    if (key === "product") {
      updatedAsset.product = parseInt(value.toString());
    }
    runMutation(
      () =>
        updateAsset({
          assetId: asset.id,
          assetData: { ...updatedAsset },
          onSuccessCallback: () => {
            addToast({
              message: `Asset "${asset.name}" updated successfully `,
              type: "success",
            });
          },
          onErrorCallback: () => {
            addToast({
              message: `Failed to update asset "${asset.name}"`,
              type: "error",
            });
          },
        }),
      `Updating asset "${asset.name}"`
    );
  };

  const columns: Column[] = [
    {
      title: "Name",
      key: "name",
      maxWidth: "350px",
      cell: (row) => (
        <Flex align="center" gap="8px" className="text-truncate">
          {!row.is_verified && row.type === "domain" && (
            <Flex
              style={{
                width: "20px",
              }}
            >
              <Tooltip content={"Domain is unverified!"} isTextTruncate>
                <AssetVerificationIndicator
                  setShowVerifyAssetModal={setShowVerifyAssetModal}
                  asset={assetsMapped?.find((a) => a.id === row.id)}
                  variant="table"
                />
              </Tooltip>
            </Flex>
          )}

          <Tooltip content={row.name} isTextTruncate>
            <BodyRegular
              className="text-truncate"
              color={row.isDead ? theme.black600 : theme.black800}
            >
              {row.name}
            </BodyRegular>
          </Tooltip>
        </Flex>
      ),
      sortable: true,
      isRequired: true,
    },
    {
      title: "Product",
      key: "product",
      maxWidth: isEditable ? "100%" : "250px",
      cell: (row) => {
        const product = products?.find((p) => p.id === row.product);
        return isEditable ? (
          <div
            className="d-flex align-items-center w-100 pe-1"
            data-testid={`${row.name}-product-cell`}
          >
            <Dropdown
              isCompact={true}
              size="medium"
              width="100%"
              variant="border"
              dataTestId={`${row.name}-product-dropdown`}
              isMenuPositionFixed={true}
              options={productsOptions}
              value={
                productsOptions.find((po) => po.value === row.product) ||
                notSelectedOption
              }
              onChange={(option) =>
                !!option?.value &&
                updateSingleAsset(row, "product", option.value)
              }
            />
          </div>
        ) : (
          <Tooltip content={product?.name || "N/A"} isTextTruncate>
            <BodyRegular
              className="text-truncate"
              color={row.isDead ? theme.black600 : theme.black800}
            >
              {product?.name || "N/A"}
            </BodyRegular>
          </Tooltip>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      sortable: true,
      maxWidth: "140px",
      cell: (row) => (
        <BodyRegular color={row.isDead ? theme.black600 : theme.black800}>
          {row.type || "N/A"}
        </BodyRegular>
      ),
    },
    {
      title: "Source",
      key: "source",
      sortable: true,
      maxWidth: "140px",
      cell: (row) => (
        <BodyRegular color={row.isDead ? theme.black600 : theme.black800}>
          {row.source || "N/A"}
        </BodyRegular>
      ),
    },
    {
      title: "Discovered On",
      key: "created_at",
      sortable: true,
      maxWidth: "140px",
      cell: (row) => (
        <BodyRegular color={row.isDead ? theme.black600 : theme.black800}>
          {row.created_at ? getDate(row.created_at) : "N/A"}
        </BodyRegular>
      ),
    },
    {
      title: "Findings",
      key: "related_findings",
      maxWidth: "80px",
      centerCell: true,
      cell: (row) => (
        <FindingsItem
          color={row.isDead ? theme.black600 : theme.black800}
          relatedFindings={row.related_findings || []}
        />
      ),
    },
    {
      title: "Priority",
      key: "priority",
      sortable: true,
      maxWidth: isEditable ? "100%" : "150px",
      centerCell: true,
      cell: (row) =>
        isEditable ? (
          <div className="d-flex align-items-center w-100 pe-1">
            <Dropdown
              isCompact={true}
              size="medium"
              width="100%"
              variant="border"
              dataTestId="priority-dropdown"
              isMenuPositionFixed={true}
              options={priorityOptions}
              value={
                priorityOptions.find((p) => p.value === row.priority) ||
                notSelectedOption
              }
              onChange={(option) =>
                !!option?.value &&
                updateSingleAsset(row, "priority", option.value)
              }
            />
          </div>
        ) : (
          <BodyRegular color={row.isDead ? theme.black600 : theme.black800}>
            {getPriorityLabel(row.priority)}
          </BodyRegular>
        ),
    },
    {
      title: "Environment",
      key: "environment",
      maxWidth: isEditable ? "100%" : "180px",
      cell: (row) =>
        isEditable ? (
          <div className="d-flex align-items-center w-100 pe-1">
            <Dropdown
              isCompact={true}
              size="medium"
              width="100%"
              variant="border"
              dataTestId="environment-dropdown"
              isMenuPositionFixed={true}
              options={environmentOptions}
              value={
                environmentOptions.find((p) => p.value === row.environment) ||
                notSelectedOption
              }
              onChange={(option) =>
                !!option?.value &&
                updateSingleAsset(row, "environment", option.value)
              }
            />
          </div>
        ) : (
          <BodyRegular color={row.isDead ? theme.black600 : theme.black800}>
            {row.environment}
          </BodyRegular>
        ),
    },
    {
      title: "Security Grade",
      key: "risk_score",
      maxWidth: "130px",
      sortable: true,
      centerCell: true,
      cell: (row) => (
        <AssetGradeBadge
          riskScore={row.risk_score}
          color={row.isDead ? theme.black600 : undefined}
          backgroundColor={row.isDead ? theme.black400 : undefined}
        />
      ),
    },
    {
      title: "IP Addresses",
      key: "ip",
      minWidth: "120px",
      cell: (row) => (
        <BadgesLine
          badges={row.properties?.ip || []}
          variant="primary"
          noWrap={true}
          gap={4}
        />
      ),
    },
    {
      title: "Coverage Score",
      key: "coverage_score",
      centerCell: true,
      cell: (row) => {
        return (
          <AssetCoverageIndicator
            asset={row}
            color={row.isDead ? theme.black600 : undefined}
            backgroundColor={row.isDead ? theme.black400 : undefined}
          />
        );
      },
    },
    {
      title: "Coverage",
      key: "coverage",
      centerCell: true,
      maxWidth: isEditable ? "100%" : "135px",
      cell: (row) => {
        return (
          <AssetCoverage
            asset={row}
            isEditable={isEditable}
            onCoverageChange={(property, value) =>
              updateSingleAsset(row, property, value)
            }
            isDead={row.isDead}
          />
        );
      },
    },
    // {
    //   title: "Verification",
    //   key: "is_verified",
    //   cell: (row) => (
    //     <AssetVerificationIndicator
    //       setShowVerifyAssetModal={setShowVerifyAssetModal}
    //       asset={assetsMapped?.find((a) => a.id === row.id)}
    //     />
    //   )
    // },
    {
      title: "Tags",
      key: "tags",
      cell: (row) => {
        return isEditable ? (
          <TagsLine
            selectedTags={row.tags || []}
            isEditable={true}
            allTags={[...row.tags, ...allTags]}
            onTagRemove={(tag: string) =>
              updateSingleAsset(
                row,
                "tags",
                row.tags.filter((t: string) => t !== tag)
              )
            }
            onTagAdd={(tag: string) => {
              updateSingleAsset(row, "tags", [...row.tags, tag]);
            }}
            noWrap={true}
          />
        ) : (
          <BadgesLine
            badges={row.tags || []}
            variant={row.isDead ? "dead" : "secondary"}
            noWrap={true}
            gap={4}
          />
        );
      },
    },
    {
      title: "",
      key: "",
      iconName: !isEditable ? "write" : "",
      onTitleClick: () => !isEditable && setShowColumnsEditorPane(true),
      maxWidth: "50px",
      isRequired: true,
      cell: (row) => (
        <span data-tut="assets-sliders">
          <IconButton
            iconName="chevronRight"
            size="small"
            dataTestId={`asset-table-open-pane-btn-${row.id}`}
            onClick={() => onSelectedAsset(row)}
            color={row.isDead ? theme.black600 : undefined}
          />
        </span>
      ),
    },
  ];

  // Handle columns state editing
  const storedStateStr = localStorage.getItem(stateStorageKey) || "";
  const savedColumnsState = isEditable
    ? editableColumns // On edit mode - show editable columns
    : storedStateStr
      ? JSON.parse(storedStateStr)
      : null;

  columns?.forEach((col) =>
    savedColumnsState
      ? (col.isHidden = !savedColumnsState[col.title])
      : (col.isHidden = false)
  );

  if (!localStorage.getItem(stateStorageKey))
    localStorage.setItem(
      stateStorageKey,
      JSON.stringify(getDefaultColumnsState(true, columns, maxTableColumns))
    );

  if (isAssetsFetching && !isFetchingNextPage && !isAssetsRefetching) {
    return (
      <div className="h-100">
        <TableSkeleton />
      </div>
    );
  }

  return (
    <div className="h-100" style={{ paddingBottom: "48px" }}>
      {showAssetsPane && (
        <AssetPane
          onClose={onCloseAssetPane}
          assetId={selectedAsset?.id || parseInt(`${assetId}` || "0")}
          setShowVerifyAssetModal={setShowVerifyAssetModal}
        />
      )}

      {showColumnsEditorPane && (
        <ColumnsEditor
          onClose={() => setShowColumnsEditorPane(false)}
          columns={columns}
          stateStorageKey={stateStorageKey}
          maxTableColumns={maxTableColumns}
        />
      )}

      {assetsMapped && assetsMapped.length === 0 && !isAssetsFetching ? (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 mt-2">
          <NotFoundBanner
            title="No Assets Found for the selected Filters!"
            text="Sorry, we couldn't find assets with the filters applied. Please try changing the filters."
          />
        </div>
      ) : (
        <Table
          columns={columns}
          rows={assetsMapped}
          minWidth={560}
          hasBackToTopButton={hasBackToTopButton}
          hasScrollPagination
          isFetchingNextPage={isFetchingNextPage}
          onScrollPagination={fetchNextPage}
          hasNextPage={hasNextPage}
          defaultSortKey="created_at"
          defaultSortOrder="desc"
          onSort={(column, order) =>
            setOrdering(order === "desc" ? `-${column.key}` : column.key)
          }
          isSelectable={isEditable || isSelectable}
          selectedRows={
            selectedAssets.length
              ? selectedAssets
              : selectedAssetsIds.map((id) => ({ id }))
          }
          onRowSelect={onRowSelect}
          onSelectAll={onSelectAll}
          isSelectAll={isSelectAll}
          setIsSelectAll={setIsSelectAll}
          isEditMode={isEditable}
          isRowClickable={isRowClickable}
          isLoading={isAssetsFetching || isFetchingNextPage}
        />
      )}
    </div>
  );
};
