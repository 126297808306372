import { CSSProperties, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Icon } from "../../components/elements/icon/Icon";
import {
  LabelRegular,
  TypographyLabelMedium,
} from "../../components/elements/typography/Typography";

type ButtonProps = {
  iconName?: string;
  size?: "very-small" | "small" | "medium";
  onClick?: () => void;
  inProgress?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  color?: string;
  id?: string;
  label?: string;
  style?: CSSProperties;
};

const IconButtonStyle = styled.button<ButtonProps>`
  ${TypographyLabelMedium}
  box-sizing: border-box;
  display: inline-block;
  align-items: center;
  justify-content: center;
  height: ${(props) =>
    props.size === "medium" ? 40 : props.size === "small" ? 32 : 24}px;
  width: ${(props) =>
    props.size === "medium" ? 40 : props.size === "small" ? 32 : 24}px;
  padding: 4px;
  background-color: transparent;
  transition: 0.4s;
  border: 0px;
  border-radius: 50%;
  .icon {
    color: ${(props) => props.theme.white100};
  }
  &:hover:enabled {
    background-color: ${(props) => props.theme.blue50};
    .icon {
      color: ${(props) => props.theme.primary};
    }
  }
  &:focus {
    background-color: ${(props) => props.theme.blue50};
    .icon {
      color: ${(props) => props.theme.primary};
    }
  }
`;

const UnreadIndicatorStyle = styled.div`
  ${TypographyLabelMedium}
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.redPrimary};
  color: ${(props) => props.theme.white100};
  border: 0px;
  border-radius: 50%;
  position: absolute;
  left: -20px;
`;

const UnreadIndicator = (props: { unreadNotifications?: number }) => {
  const { unreadNotifications } = props;
  const theme = useContext(ThemeContext);
  return (
    <>
      {unreadNotifications && unreadNotifications !== 0 ? (
        <div style={{ position: "relative" }}>
          <UnreadIndicatorStyle>
            <LabelRegular color={theme.white100}>
              {unreadNotifications}
            </LabelRegular>
          </UnreadIndicatorStyle>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

type Props = {
  onClick?: () => void;
  countUnread?: number;
};

export const NotificationBellButton = (props: Props) => {
  const { onClick, countUnread } = props;
  const isPresentationMode = !!localStorage.getItem("presentationMode");

  return (
    <div style={{ display: "inline-flex" }}>
      <IconButtonStyle
        onClick={onClick}
        disabled={false}
        size={"medium"}
        data-testid={"notification-bell"}
        iconName={"notification"}
        id={"notification-bell"}
      >
        <Icon className="icon" name={"notification"} size={32} />
      </IconButtonStyle>
      {!isPresentationMode && (
        <UnreadIndicator unreadNotifications={countUnread} />
      )}
    </div>
  );
};
