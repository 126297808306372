import { useState } from "react";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { Box } from "../../../components/elements/box/Box";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { useApiMe } from "../../../hooks/queries/meContext";
import { EffortEstimationSettings } from "../../../types/Customer";
import { useApiUpdateCustomer } from "../../../hooks/queries/customersContext";
import useToastContext from "../../../hooks/toastHook";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { Mixpanel } from "../../../shared/mixpanel";

const defaultConfiguration = {
  asset: 1,
  login: 0.5,
  sso: 0.5,
  signup: 0.5,
  password_reset: 0.5,
  users_mgmt: 0.5,
  file_upload: 0.5,
};

export const EffortEstimation = () => {
  const { data: me } = useApiMe();
  useTrackPage("Settings - Effort Estimation", undefined, me);
  const addToast = useToastContext();
  const isSuperuser = useIsSuperuser();
  const { mutate: updateCustomer } = useApiUpdateCustomer();
  const [formValues, setFormValues] = useState<EffortEstimationSettings>(
    me?.customer.effort_estimation_settings || defaultConfiguration
  );

  const notableMechanisms = [
    { displayName: "Asset", key: "asset" },
    { displayName: "Login", key: "login" },
    { displayName: "SSO", key: "sso" },
    { displayName: "Sign Up", key: "signup" },
    { displayName: "Password Reset", key: "password_reset" },
    { displayName: "Users Management", key: "users_mgmt" },
    { displayName: "File Upload", key: "file_upload" },
  ];

  const handleChange = (key: string, value: string) => {
    const newValues = { ...formValues };
    newValues[key] = parseFloat(value);
    setFormValues(newValues);
  };

  const handleSave = () => {
    me?.customer &&
      updateCustomer({
        customerData: { effort_estimation_settings: formValues },
        customerId: me.customer.id,
        onSuccessCallback: () =>
          addToast({
            type: "success",
            message: "Customer updated successfully",
          }),
        onErrorCallback: (err) =>
          addToast({
            type: "error",
            message: `Failed to update customer - ${err}`,
          }),
      });
  };

  if (!isSuperuser) return null;
  return (
    <Flex
      column
      justify="between"
      gap="16px"
      style={{ height: "calc(100vh - 90px)" }}
    >
      <Box style={{ width: "320px" }}>
        <Flex gap="16px" justify="between">
          <Flex column gap="16px" className="w-100">
            <Flex align="center" style={{ height: "50px" }}>
              <HeaderSecondary style={{ paddingRight: "24px" }}>
                Effort Estimation
              </HeaderSecondary>
            </Flex>

            {notableMechanisms.map((field) => (
              <Flex column gap="32px" key={field.key}>
                <Flex align="center" justify="between">
                  <BodyRegular>{field.displayName}</BodyRegular>
                  <Flex align="center" gap="8px">
                    <InputText
                      type="number"
                      dataTestId={`${field.key}-input`}
                      value={`${formValues[field.key]}`}
                      onChange={(e) => {
                        Mixpanel.track("Changed Effort Estimation", {
                          field_changed: field.displayName,
                          new_value: e.target.value,
                        });
                        handleChange(field.key, e.target.value);
                      }}
                      className="text-center"
                      width={"100px"}
                    />
                    <BodyRegular>Days</BodyRegular>
                  </Flex>
                </Flex>
                <SeparatorHorizontal />
              </Flex>
            ))}
            <Flex justify="end">
              <MainButton
                label="Save"
                onClick={() => {
                  Mixpanel.track("Save clicked");
                  handleSave();
                }}
                size="medium"
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
