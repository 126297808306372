import React, { useContext, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Box } from "../../../components/elements/box/Box";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import { useApiAsmAnalytics } from "../../../hooks/queries/findingContext";
import { getRelevantCustomers } from "../utils";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { customersPriorityTierOptions } from "../../../shared/formUtils";
import { PriorityTier } from "../../../types/Customer";

export const PendingValidFindings = () => {
  const theme = useContext(ThemeContext);
  const { data: customers } = useApiCustomers();
  const { data: asmAnalytics } = useApiAsmAnalytics();
  const [selectedTier, setTier] = useState<PriorityTier | "all">("all");
  const calcDatasetByType = (
    countType: "pending_count" | "valid_count"
  ): number[] => {
    const dataset: number[] = [];
    if (!asmAnalytics || !customers) return dataset;

    for (const customer of getRelevantCustomers(customers, selectedTier)) {
      const currentItem = asmAnalytics.current_data.find(
        (a) => a.customer_id === customer.id
      );
      dataset.push(currentItem ? currentItem[countType] : 0);
    }

    return dataset;
  };

  const calcPendingDataset = (): number[] => calcDatasetByType("pending_count");
  const calcValidDataset = (): number[] => calcDatasetByType("valid_count");

  return (
    <Box>
      <Flex column w100>
        <Flex justify="center" w100>
          <HeaderSecondary>Pending vs. Valid Findings</HeaderSecondary>
        </Flex>
        <Flex>
          <Dropdown
            searchable
            placeholder="Select tier"
            onChange={(opt) => {
              if (opt?.value) {
                setTier(opt.value as PriorityTier);
              }
            }}
            value={
              customersPriorityTierOptions.filter(
                (t) => t.value === selectedTier
              )[0]
            }
            options={customersPriorityTierOptions}
          />
        </Flex>
        <Flex
          style={{
            paddingTop: "30px",
            paddingRight: "50px",
            paddingBottom: "50px",
            paddingLeft: "50px",
          }}
        >
          <Bar
            data={{
              labels: [
                ...(getRelevantCustomers(customers || [], selectedTier)
                  ?.sort((a, b) => a.id - b.id)
                  .map((c) => c.name) || []),
              ],
              datasets: [
                {
                  label: "Pending",
                  data: calcPendingDataset(),
                  backgroundColor: theme.yellowPrimary,
                },
                {
                  label: "Valid",
                  data: calcValidDataset(),
                  backgroundColor: theme.greenPrimary,
                },
              ],
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
