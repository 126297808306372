import React, { useContext, useState } from "react";
import {
  CustomerJiraDetails,
  JiraProjectMapping,
} from "../../../../types/JiraIntegration";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { JiraIntegrationModalFooter } from "./JiraIntegrationModalFooter";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import {
  BodyMedium,
  HeaderSubBold,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";
import { SelectionTabButton } from "../../../../components/elements/button/selectionTab/SelectionTabButton";
import { MappingOverview } from "./MappingOverview";
import { useApiUpdateCustomer } from "../../../../hooks/queries/customersContext";
import useToastContext from "../../../../hooks/toastHook";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { Mixpanel } from "../../../../shared/mixpanel";

type Props = {
  jiraProjectsMapping: JiraProjectMapping[];
  jiraDetails: CustomerJiraDetails;
  onPrev: () => void;
  onNext: () => void;
};

export const IntegrationOverview = (props: Props) => {
  const { jiraProjectsMapping, jiraDetails, onPrev, onNext } = props;
  const theme = useContext(ThemeContext);
  const [selectedProject, setSelectedProject] = useState<JiraProjectMapping>(
    jiraProjectsMapping[0]
  );
  const { data: me, refetch } = useApiMe();

  const addToast = useToastContext();

  const { mutate: updateCustomer, isLoading } = useApiUpdateCustomer();

  const onFinish = () => {
    Mixpanel.track("Integrate Jira - Finish");
    updateCustomer({
      customerId: me?.customer.id || -1,
      customerData: {
        jira_projects_mapping: jiraProjectsMapping,
      },
      onSuccessCallback: () => {
        addToast({
          message: "Jira integration has been successfully set up!",
          type: "success",
        });
        refetch();
        onNext();
      },
      onErrorCallback: (error: Error) =>
        addToast({ message: error.message, type: "error" }),
    });
  };

  const DetailBox = ({ title, text }: { title: string; text: string }) => (
    <Flex column gap="8px" w100 style={{ overflow: "clip" }}>
      <LabelRegular color={theme.black700}>{title}</LabelRegular>
      <BodyMedium className="text-truncate w-100" color={theme.black800}>
        {text}
      </BodyMedium>
    </Flex>
  );

  return (
    <Flex column h100 w100 justify="between">
      <Flex column gap="24px">
        <Flex column gap="16px">
          <HeaderSubBold color={theme.black900}>Account Details</HeaderSubBold>
          <Flex align="center" gap="24px" justify="between">
            <DetailBox
              title="Account Email Address"
              text={jiraDetails.username}
            />
            <SeparatorVertical height="24px" />
            <DetailBox
              title="API Key"
              text={
                !!jiraDetails.token
                  ? jiraDetails.token.substring(0, 4) + "*****"
                  : "*****"
              }
            />
            <SeparatorVertical height="24px" />
            <DetailBox title="Jira Workspace URL" text={jiraDetails.url} />
          </Flex>
        </Flex>
        <SeparatorHorizontal />
        <Flex column gap="16px">
          <HeaderSubBold color={theme.black900}>Projects Setup</HeaderSubBold>
          <Flex gap="24px">
            <Flex column gap="16px" style={{ width: "280px" }}>
              {jiraProjectsMapping.map((project, index) => (
                <SelectionTabButton
                  key={`project-overview-${index}`}
                  label={project.project_name}
                  automatic={project.auto_send}
                  isActive
                  selected={
                    selectedProject?.project_key === project.project_key
                  }
                  onClick={() => {
                    setSelectedProject(project);
                  }}
                />
              ))}
            </Flex>
            <Flex style={{ width: "calc(100% - 280px)" }}>
              <MappingOverview mapping={selectedProject} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <JiraIntegrationModalFooter
        onPrev={onPrev}
        readyForNext={true}
        nextText="Finish"
        onNext={onFinish}
        inProgress={isLoading}
      />
    </Flex>
  );
};
