import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { FilterBadge } from "./FilterBadge";
import { LabelMedium } from "../../../components/elements/typography/Typography";
import { Filter } from "../../../types/AssetsView";

type Props = {
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
};

export const AssetsFiltersBadges = (props: Props) => {
  const { filters, setFilters } = props;
  const theme = useContext(ThemeContext);

  const handleRemoveFilter = (filter: Filter) =>
    setFilters(filters.filter((f) => f !== filter));

  return (
    <>
      {filters.map((filter: Filter, index: number) => (
        <div
          className="d-inline-flex align-items-center gap-8"
          key={`filter-badge-${index}-${filter.order}`}
        >
          {["and", "or"].includes(
            (filter.next_condition || "").toLowerCase()
          ) &&
            index > 0 && (
              <LabelMedium
                style={{ color: theme.blue800, textTransform: "uppercase" }}
              >
                {filter.next_condition}
              </LabelMedium>
            )}
          <FilterBadge
            filter={filter}
            onRemove={() => handleRemoveFilter(filter)}
          />
        </div>
      ))}
    </>
  );
};
