import React from "react";
import { IconProps } from "./WaspIcon";

export function FindingsNavIcon(props: IconProps) {
  const size = props.size || 32;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8967 22.9268C12.0207 23.5508 12.5673 24.0001 13.2033 24.0001H13.2066C14.7164 24.0001 17.2858 24.0001 18.7953 23.9974C19.4287 23.9974 19.9753 23.5508 20.0993 22.9294C20.1167 22.8401 20.1367 22.7534 20.158 22.6667C20.51 21.2323 21.4061 20.1437 22.3552 18.9906C22.6466 18.6367 22.9429 18.2767 23.23 17.8987C23.7354 17.2801 24.1633 16.5961 24.498 15.8601C24.9153 14.9734 25.2167 13.9507 25.306 12.7121C25.3233 12.4774 25.3327 12.2401 25.3327 12.0001C25.3327 6.84941 21.15 2.66675 15.9993 2.66675C10.8487 2.66675 6.66602 6.84941 6.66602 12.0001C6.66602 12.2401 6.67535 12.4774 6.69269 12.7121C6.78202 13.9494 7.08336 14.9721 7.50069 15.8601C7.83536 16.5961 8.26334 17.2801 8.76868 17.8987L8.78735 17.9228C9.00869 18.2121 9.23802 18.4974 9.46869 18.7787L9.84068 19.2308C10.5393 20.0828 11.2354 20.9694 11.6354 22.0094C11.714 22.2161 11.7807 22.4267 11.8353 22.6401C11.836 22.6447 11.837 22.6491 11.838 22.6534C11.839 22.6577 11.84 22.6621 11.8407 22.6667C11.862 22.7534 11.882 22.8401 11.8967 22.9268ZM18.6673 28.0001C18.6673 27.2641 18.07 26.6667 17.334 26.6667H14.6673C13.9313 26.6667 13.334 27.2641 13.334 28.0001C13.334 28.7361 13.9313 29.3334 14.6673 29.3334H17.334C18.07 29.3334 18.6673 28.7361 18.6673 28.0001Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
