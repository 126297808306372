import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { StyleTextArea } from "../../../components/elements/input/textArea/TextArea.style";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  onFocus: () => void;
};

export const CommentInput = ({ onFocus }: Props) => {
  return (
    <Flex w100 align="center" gap="4px">
      <StyleTextArea
        className="text-truncate"
        placeholder="Enter your comment"
        width="100%"
        data-testid="comment-input"
        style={{ overflow: "hidden", minWidth: "160px" }}
        rows={1}
        onFocus={onFocus}
      />
      <IconButton
        iconName="send"
        size="small"
        dataTestId="comment-send"
        disabled
      />
    </Flex>
  );
};
