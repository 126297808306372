import React from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Box } from "../../../components/elements/box/Box";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";

export const ScannerManagementSkeleton = () => {
  return (
    <Flex column gap="16px" style={{ width: "331px" }}>
      {[1, 2, 3, 4, 5, 6].map((idx) => (
        <Box
          key={`scanner-skeleton-${idx}`}
          className="d-flex flex-column justify-content-center gap-16"
          style={{ padding: "16px" }}
        >
          <SkeletonLoading height="22px" width="110px" />

          <SeparatorHorizontal />
          <Flex justify="between" gap="24px" align="center" w100>
            <SkeletonLoading height="18px" width="150px" />
            <SkeletonLoading height="18px" width="50px" />
          </Flex>
          <SkeletonLoading height="36px" width="100%" />
        </Box>
      ))}
    </Flex>
  );
};
