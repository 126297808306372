import { useContext, useState } from "react";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { ScannerManagement } from "./ScannerManagement";
import { ScanHistory } from "./ScanHistory";
import { ConfirmModal } from "../../../components/composed/confirmModal/ConfirmModal";
import {
  useApiScanEventsPaging,
  useApiStopScan,
} from "../../../hooks/queries/scansContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import useToastContext from "../../../hooks/toastHook";
import { ScanIdentifiersPane } from "./ScanIdentifiersPane";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { Mixpanel } from "../../../shared/mixpanel";
import { useApiCreateCustomerLog } from "../../../hooks/queries/customerLogsContext";

export const ScanningPolicy = () => {
  const { data: me } = useApiMe();
  useTrackPage("Settings - Scanning Policy", undefined, me);
  const theme = useContext(ThemeContext);

  const { data: scanEvents } = useApiScanEventsPaging(
    {
      customer_id: me?.customer?.id,
      status: "running",
    },
    !!me?.customer?.id
  );

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showIdentifiersPane, setShowIdentifiersPane] =
    useState<boolean>(false);

  const getScanEvents = () => {
    return scanEvents?.pages?.map((page) => page?.results || []).flat() || [];
  };

  const {
    mutate: stopScanMutation,
    isLoading: isStopping,
    isSuccess: isStopped,
    reset: resetDelete,
  } = useApiStopScan();

  const { mutate: createCustomerLogMutation } = useApiCreateCustomerLog();

  const addToast = useToastContext();

  const stopAllScans = () => {
    stopScanMutation({
      customerId: me?.customer.id || -1,
      username: me?.email,
      customerName: me?.customer.name || "",
      onSuccessCallback: () => {
        addToast({
          type: "success",
          message: `All scans has stopped successfully`,
        });
        createCustomerLogMutation({
          action: "ALL_SCANS_STOPPED",
        });
      },
      onErrorCallback: () =>
        addToast({
          type: "error",
          message: `Failed to stop all scans`,
        }),
    });
  };

  if (!me?.can_manage_customer) return null;
  return (
    <Flex
      column
      w100
      gap="24px"
      className="mb-2"
      style={{ height: "calc(100vh - 90px)" }}
    >
      <Flex justify="between" align="center">
        <Flex align="center" gap="16px">
          <HeaderSecondary>Scanning Policy</HeaderSecondary>
          <Tooltip
            placement="bottom"
            content="Manage Scanners & View scan's history: Easily configure and manage your scanners' activity, as well as access the log of past scans"
          >
            <IconButton
              iconName="info"
              size="small"
              color={theme.primary}
              onClick={() => setShowIdentifiersPane(true)}
              dataTestId="scanning-policy-info-button"
            />
          </Tooltip>
        </Flex>
        <MainButton
          iconName="alarm"
          label="Stop All Scans"
          onClick={() => {
            Mixpanel.track("Stop All Scans - clicked");
            setShowConfirmModal(true);
          }}
          size="small"
          variant="danger"
          disabled={!getScanEvents().length}
          dataTestId="stop-all-scans-button"
        />
      </Flex>
      <SeparatorHorizontal />
      <Flex gap="24px" w100>
        <ScannerManagement />
        <SeparatorVertical height="inherit" />
        <ScanHistory />
      </Flex>
      {showConfirmModal && (
        <ConfirmModal
          title={`Stop all scans`}
          body={
            <BodyRegular>
              Proceeding with action will result in stopping all
              vulnerabilities, scanners, and assets enumeration utilities. This
              action is reversible.
            </BodyRegular>
          }
          onConfirm={stopAllScans}
          onClose={() => {
            setShowConfirmModal(false);
            resetDelete();
          }}
          isLoading={isStopping}
          isSuccess={isStopped}
          successMessage={`All scans stopped!`}
          confirmButtonText="Stop All Scans"
          cancelButtonText="Keep running"
          mainButtonVariant="danger"
        />
      )}
      {showIdentifiersPane && (
        <ScanIdentifiersPane onClose={() => setShowIdentifiersPane(false)} />
      )}
    </Flex>
  );
};
