import { $applyNodeReplacement, TextNode, } from "lexical";
function convertMentionElement(domNode) {
    const textContent = domNode.textContent;
    const email = domNode.getAttribute("data-email");
    if (textContent !== null && email != null) {
        const node = $createWaspMentionNode(textContent, email);
        return {
            node,
        };
    }
    return null;
}
const mentionStyle = "background-color: rgba(24, 119, 232, 0.2)";
export class WaspMentionNode extends TextNode {
    static getType() {
        return "mention";
    }
    static clone(node) {
        return new WaspMentionNode(node.__mention, node.__text, node.__key, node.__email);
    }
    static importJSON(serializedNode) {
        const node = $createWaspMentionNode(serializedNode.mentionName, serializedNode.email);
        node.setTextContent(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }
    constructor(mentionName, text, key, email) {
        super(text !== null && text !== void 0 ? text : mentionName, key);
        this.__mention = mentionName;
        this.__email = email || "";
        this.__text = text || mentionName;
    }
    exportJSON() {
        return Object.assign(Object.assign({}, super.exportJSON()), { mentionName: this.__mention, email: this.__email, type: "mention", version: 1 });
    }
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.style.cssText = mentionStyle;
        dom.className = "mention";
        dom.setAttribute("data-email", this.__email);
        dom.setAttribute("data-lexical-mention", "true");
        return dom;
    }
    exportDOM() {
        const element = document.createElement("span");
        element.setAttribute("data-lexical-mention", "true");
        element.setAttribute("data-email", this.__email);
        element.textContent = this.__text;
        return { element };
    }
    static importDOM() {
        return {
            span: (domNode) => {
                if (!domNode.hasAttribute("data-lexical-mention")) {
                    return null;
                }
                return {
                    conversion: convertMentionElement,
                    priority: 1,
                };
            },
        };
    }
    isTextEntity() {
        return true;
    }
    canInsertTextBefore() {
        return false;
    }
    canInsertTextAfter() {
        return false;
    }
}
export function $createWaspMentionNode(mentionName, email) {
    const mentionNode = new WaspMentionNode(mentionName, mentionName, undefined, email);
    mentionNode.setMode("segmented").toggleDirectionless();
    return $applyNodeReplacement(mentionNode);
}
export function $isWaspMentionNode(node) {
    return node instanceof WaspMentionNode;
}
