export type CtiItem = {
  affected_products: number[];
  customers: number[];
  id?: number;
  image: string;
  link: string;
  title: string;
  updated_at?: string;
  created_at?: string;
};

export const emptyCtiItem: CtiItem = {
  affected_products: [],
  customers: [],
  image: "",
  link: "",
  title: "",
};
