import { useContext, useEffect, useState } from "react";
import { HeaderMain } from "../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { ProjectPane } from "./projectPane/ProjectPane";
import { Project } from "../../types/Project";
import { Flex } from "../../components/layouts/flex/Flex";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { ProjectsTable } from "./ProjectsTable";
import { SeparatorVertical } from "../../components/elements/separators/SeparatorVertical";
import { SearchBar } from "../../components/composed/searchBar/SearchBar";
import { useApiProjects } from "../../hooks/queries/projectsContext";
import { RequestsPane } from "./RequestsPane";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";
import { useApiProjectRequests } from "../../hooks/queries/projectRequestsContext";
import { RequestsModal } from "./RequestsModal";
import { ExpressPtModal } from "./projectScheduler/ExpressPtModal";
import { useSearchParams } from "react-router-dom";
import { Box } from "../../components/elements/box/Box";
import { Tours } from "../../tours/Tours";
import { projectsSteps } from "../../tours/ProjectsTourSteps";
import { AlertsBanners } from "../alertsBanners/AlertsBanners";
import { Mixpanel } from "../../shared/mixpanel";
import { useApiMe } from "../../hooks/queries/meContext";
import { useTrackPage } from "../../hooks/trackingHooks";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";
import { AssetPane } from "../assets/assetsPane/AssetPane";

export const Projects = () => {
  const theme = useContext(ThemeContext);
  const isSuperuser = useIsSuperuser();

  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const assetId = searchParams.get("assetId") || "0";
  const expressTest = searchParams.get("express");
  const showProjectRequests = searchParams.get("project_requests");

  const { data: me } = useApiMe();
  useTrackPage("Projects", undefined, me);

  const [showRequestsPane, setShowRequestsPane] =
    useState(!!showProjectRequests);
  const [showProjectPane, setShowProjectPane] = useState(!!projectId || false);
  const [showCreateProjectPane, setShowCreateProjectPane] = useState(false);
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showExpressPtModal, setShowExpressPtModal] = useState(!!expressTest);
  const [showAssetPane, setShowAssetPane] = useState<number>(parseInt(assetId));

  const [project, setProject] = useState<Project | null>(null);
  const [searchWord, setSearchWord] = useState<string>("");

  const { data: projects, isFetching: isFetchingProjects } = useApiProjects({
    with_findings_counts: true,
    with_poc_avatar: true,
  });
  const { data: projectRequests } = useApiProjectRequests();

  const pendingRequestsCount =
    projectRequests?.filter((pr) => ["pending", "draft"].includes(pr.status))
      ?.length || 0;

  const filteredProjects = searchWord
    ? projects?.filter((p) =>
        p.name.toLowerCase().includes(searchWord.toLowerCase())
      ) || []
    : projects || [];

  const onOpenProjectPane = (project: Project) => {
    setShowProjectPane(true);
    setProject(project);
    setSearchParams({ ...searchParams, projectId: `${project.id}` });
  };

  const onCloseProjectPane = () => {
    setShowProjectPane(false);
    setShowCreateProjectPane(false);
    setProject(null);
  };

  const switchToExpressTest = () => {
    setShowRequestsModal(false);
    setShowExpressPtModal(true);
  };
  useEffect(() => {
    let pid = searchParams.get("projectId");
    if (pid) {
      setShowProjectPane(true);
      setShowAssetPane(0);
    }
    let aid = searchParams.get("assetId");
    if (aid) {
      setShowAssetPane(parseInt(aid));
      setShowProjectPane(false);
    }
  }, [searchParams]);

  return (
    <Flex
      align="start"
      justify="between"
      style={{ height: "calc(100vh - 90px)" }}
    >
      <Tours steps={projectsSteps(theme)} />
      <Flex column gap="12px" className=" w-100">
        <AlertsBanners />
        <Flex justify="between">
          <HeaderMain data-testid="assets-title">Projects</HeaderMain>

          <Flex align="center" gap="16px">
            <SearchBar setSearchWord={setSearchWord} />
            <SeparatorVertical height="16px" />
            <SecondaryButton
              onClick={() => {
                Mixpanel.track("Pending projects click");
                setShowRequestsPane(true);
              }}
              label={`Pending Projects ${pendingRequestsCount}`}
              iconName="re_test"
              size="medium"
            />
            <Flex align="center" data-tut="pt-scheduler">
              <MainButton
                label="Schedule a Test"
                iconName="penetrationTest"
                onClick={() => {
                  Mixpanel.track("Schedule a Test");
                  setShowRequestsModal(true);
                }}
                size="medium"
              />
            </Flex>
            {(isSuperuser ||
              (me?.customer.is_multi_tenant && me?.can_manage_customer)) && (
              <Flex align="center" data-tut="crate-project">
                <MainButton
                  label="Create Project"
                  iconName="plus"
                  onClick={() => {
                    Mixpanel.track("Create a project");
                    setShowCreateProjectPane(true);
                  }}
                  size="medium"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Box data-tut="projects-table">
          <ProjectsTable
            isFetchingProjects={isFetchingProjects}
            projects={filteredProjects}
            onOpenProjectPane={onOpenProjectPane}
          />
        </Box>
      </Flex>

      {showProjectPane && (
        <ProjectPane
          project={project}
          onClose={onCloseProjectPane}
          setShowAssetPane={(id) => setShowAssetPane(id)}
        />
      )}

      {showCreateProjectPane && (
        <ProjectPane isCreateMode onClose={onCloseProjectPane} />
      )}

      {showRequestsPane && (
        <RequestsPane onClose={() => setShowRequestsPane(false)} />
      )}

      {showRequestsModal && (
        <RequestsModal
          onClose={() => setShowRequestsModal(false)}
          switchToExpressTest={switchToExpressTest}
        />
      )}

      {showExpressPtModal && (
        <ExpressPtModal
          onClose={() => {
            setShowExpressPtModal(false);
            setSearchParams({ ...searchParams, express: "" });
          }}
        />
      )}

      {showAssetPane && (
        <AssetPane
          assetId={showAssetPane}
          onClose={() => setShowAssetPane(0)}
        />
      )}
    </Flex>
  );
};
