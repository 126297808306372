import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";

export type HiddenSSOButtonProps = {
  children: any;
  title: any;
  icon: any;
  onClick: any;
  color: any;
  background: any;
};

export const HiddenSSOButton = (props: HiddenSSOButtonProps) => {
  const { children, title, icon, onClick, color, background } = props;
  const theme = useContext(ThemeContext);

  return (
    <Flex
      w100
      onClick={() => onClick()}
      align="center"
      justify="center"
      style={{
        width: "320px",
        height: "56px",
        zIndex: "10",
        background: background,
        color: color,
        border: `1px solid ${theme.black500}`,
        borderRadius: "8px",
        alignItems: "center",
        cursor: "pointer",
        padding: "16px",
        margin: "8px",
        position: "relative",
      }}
    >
      <Flex
        align="center"
        gap="8px"
        style={{ position: "absolute", left: "25%" }}
      >
        <img alt="" src={icon} height="24px" width="24px" />
        <BodyRegular color={theme.black800}>{title}</BodyRegular>
        {children}
      </Flex>
    </Flex>
  );
};
