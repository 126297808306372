import { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Product } from "../../../types/Product";
import { SingleValue } from "react-select";

type Props = {
  selectedProduct: number | "all";
  products: Product[];
  onChange: (selectedProduct: number | "all") => void;
};

export const ProductsFilter = (props: Props) => {
  const { products, onChange, selectedProduct } = props;
  const theme = useContext(ThemeContext);

  var options = products.map((product) => ({
    value: product.id.toString(),
    label: product.name,
  }));
  options.unshift({ value: "all", label: "All Products" });

  const onChangeHandler = (option: SingleValue<Option>) =>
    onChange(
      option?.value === "all" ? "all" : parseInt(option?.value.toString() || "")
    );

  return (
    <span
      style={{
        paddingLeft: "14px",
        borderLeft: `1px solid ${theme.black500}`,
      }}
    >
      <Dropdown
        onChange={(option) => onChangeHandler(option)}
        options={options}
        placeholder="All Products"
        value={options.find(
          (option) => option.value.toString() === selectedProduct.toString()
        )}
      />
    </span>
  );
};
