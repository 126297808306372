import RiskLegendItem from "../../../components/elements/legend/RiskLegendItem";
import { DEFAULT_SLA_CONFIG } from "../../../shared/consts";
import { useApiMe } from "../../../hooks/queries/meContext";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { SlaConfig } from "../../../types/Customer";
import { useContext, useState } from "react";
import {
  useApiUpdateCustomer,
  CustomerContext,
} from "../../../hooks/queries/customersContext";
import { useDebounceCallback } from "../../../hooks/utilsHooks";
import useToastContext from "../../../hooks/toastHook";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { ThemeContext } from "styled-components";
import { Mixpanel } from "../../../shared/mixpanel";

type Props = {
  width?: string;
};

export const SlaThreshold = (props: Props) => {
  const { width } = props;
  const addToast = useToastContext();
  const { data: me, refetch } = useApiMe();
  const theme = useContext(ThemeContext);
  const { mutate } = useApiUpdateCustomer();
  const updatePolicy = useDebounceCallback(
    (customerContext: CustomerContext) => {
      Mixpanel.track("SLA Policy edit");
      mutate({
        ...customerContext,
        onSuccessCallback: () => {
          addToast({
            message: "SLA policy updated successfully",
            type: "success",
          });
          refetch();
        },
        onErrorCallback: (error: Error) =>
          addToast({ message: error.message, type: "error" }),
      });
    }
  );

  const [policy, setPolicy] = useState(
    me?.customer.sla_config || DEFAULT_SLA_CONFIG
  );

  const onChangeHandler = (riskLevel: string, value: number) => {
    if (!me) return;
    let newPolicy = { ...policy };
    newPolicy[riskLevel as keyof SlaConfig] = value;
    // Optimistic state update
    setPolicy((prev) => ({ ...prev, ...newPolicy }));
    // Debounced API call
    updatePolicy({
      customerId: me?.customer.id,
      customerData: { sla_config: newPolicy },
    });
  };

  return (
    <Box style={{ width: width || "inherit" }}>
      <Flex column gap="24px">
        <Flex justify="between" align="center" gap="8px">
          <Flex align="center" gap="8px">
            <Icon name="sla" color={theme.primary} size={32} />
            <HeaderSubBold>SLA Breach Threshold</HeaderSubBold>
          </Flex>
        </Flex>
        <SeparatorHorizontal />
        {Object.keys(policy).map((severity) => (
          <Flex column gap="24px" key={`severity-key-${severity}`}>
            <Flex justify="between" align="center" gap="8px">
              <RiskLegendItem label={severity} size={14} />
              <Flex align="center" gap="24px">
                <InputText
                  type="number"
                  dataTestId={`${severity}-input`}
                  value={`${policy[severity as keyof SlaConfig]}`}
                  onChange={(e) => {
                    onChangeHandler(severity, parseInt(e.target.value));
                  }}
                  width="160px"
                  style={{
                    textAlign: "center",
                  }}
                />
                {/* me-5 should be removed in the future */}
                <BodyRegular className="me-5">Days</BodyRegular>
              </Flex>
            </Flex>
            {severity !== "info" && <SeparatorHorizontal />}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};
