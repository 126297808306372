import { MainButton } from "../../../components/elements/button/main/MainButton";
import {
  HeaderMain,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";

import waspLogo from "../../../assets/images/Brand-logo.png";

type Props = {
  onSuccess: () => void;
};

export const LetsGetStarted = (props: Props) => {
  const { onSuccess } = props;
  return (
    <Flex
      w100
      column
      justify="center"
      gap="32px"
      align="center"
      style={{ height: "600px" }}
    >
      <img src={waspLogo} alt="" width="120px" />
      <HeaderMain style={{ fontSize: "48px" }}>
        Let's get started with WASP
      </HeaderMain>
      <Flex column gap="8px" align="center">
        <HeaderSecondary>
          Our goal is to streamline the initial configuration
        </HeaderSecondary>
        <HeaderSecondary>
          process for your wasps, making it effortlessly simple.
        </HeaderSecondary>
      </Flex>
      <MainButton label="Let's Get Started" onClick={onSuccess} size="medium" />
    </Flex>
  );
};
