import { Modal } from "../../../components/elements/modal/Modal";
import { Column, Table } from "../../../components/elements/table/Table";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiCreditPacks } from "../../../hooks/queries/creditPacksContext";
import { getDateTime } from "../../../shared/helper";
import { CreditPack } from "../../../types/CreditPack";

export type Props = {
  onClose: () => void;
};

export const CreditPacksModal = (props: Props) => {
  const { onClose } = props;
  const { data: creditPacks } = useApiCreditPacks();

  const columns: Column[] = [
    {
      title: "Purchase Date",
      key: "created_at",
      sortable: true,
      maxWidth: "100px",
      cell: (row: CreditPack) => (
        <Flex w100>
          <BodyRegular>{getDateTime(row.created_at)}</BodyRegular>
        </Flex>
      ),
    },
    {
      title: "Status",
      key: "status",
      sortable: true,
      maxWidth: "100px",
      cell: (row: CreditPack) => (
        <Flex w100>
          <BodyRegular>{row.status}</BodyRegular>
        </Flex>
      ),
    },
    {
      title: "Amount Purchase",
      key: "amount",
      sortable: true,
      maxWidth: "100px",
      cell: (row: CreditPack) => (
        <Flex w100 justify="center">
          <BodyRegular>{row.purchased_amount}</BodyRegular>
        </Flex>
      ),
    },
    {
      title: "Current Package balance",
      key: "current_pack_balance",
      sortable: true,
      maxWidth: "100px",
      cell: (row: CreditPack) => (
        <Flex w100 justify="center">
          <BodyRegular>{row.current_pack_balance}</BodyRegular>
        </Flex>
      ),
    },
    {
      title: "Expiration Date",
      key: "expires_at",
      sortable: true,
      maxWidth: "100px",
      cell: (row: CreditPack) => (
        <Flex w100 justify="center">
          <BodyRegular>
            {row.expires_at && getDateTime(row.expires_at)}
          </BodyRegular>
        </Flex>
      ),
    },
  ];

  return (
    <Modal onClose={onClose} header="Credits Package" width="790px">
      <Table rows={creditPacks || []} columns={columns || []} />
    </Modal>
  );
};
