function reverse(s) {
  return s.split("").reverse().join("");
}

export const calcGraphNodeSize = (weight) => {
  // base  size is 20
  var resSize = 12;
  const maxSize = 35;
  // Increase the node by the value of a digit
  for (var i = 0; i < weight.toString().length; i++) {
    let multiplier = parseInt(reverse(weight.toString()).charAt(i)) + 1;
    resSize += multiplier * 2 * (i + 1);
  }
  return Math.min(resSize, maxSize);
};

export const getAssetGraphIcon = (asset, relatedFindings) => {
  let icon = "/icons/";
  if (asset.type === "domain") {
    icon += "asset-domain";
  } else {
    icon += "asset-server";
  }

  if (relatedFindings?.length) {
    icon += "-red";
  }
  //   icon += "-solid";
  //   if (!asset.children_count && !relatedFindings?.length) {
  //     icon += "-gray";
  //   }
  return `${icon}.svg`;
};
