import {
  GraphAsset,
  GraphFinding,
  GraphRelation,
} from "../sigmaGraph/GraphDataProvider";
import { GraphGroupNode, GraphNode } from "./GraphTypes";
import { calcSectionHeight } from "./NodeLayoutManager";
import { SEVERITIES } from "../../../../shared/consts";
import { getGraphRiskColor } from "./GraphHelper";
import { Node } from "@xyflow/react";
import { getLetterByIndex } from "../../../../shared/helper";

export const setVulnerabilitiesGroupNodes = (
  screenHeight: number,
  screenWidth: number,
  findings: GraphFinding[],
  findingsIds: number[],
  groups: GraphGroupNode[],
  theme: any
): Node[] => {
  const graphFindingsNodes = findings.filter((finding) =>
    findingsIds.includes(finding.id)
  );

  const eachSeverityAmounts = graphFindingsNodes?.reduce(
    (acc, finding) => {
      if (!acc[finding.overall_risk]) {
        acc[finding.overall_risk] = 0;
      }
      acc[finding.overall_risk] += 1;
      return acc;
    },
    {} as Record<number, number>
  );

  const eachSeverityHeight = Object.keys(eachSeverityAmounts).reduce(
    (acc, key) => {
      acc[Number(key)] = calcSectionHeight(
        screenHeight - 320,
        graphFindingsNodes?.length || 0,
        eachSeverityAmounts[Number(key)]
      );
      return acc;
    },
    {} as Record<number, number>
  );

  // the height the severity the lowest the start Y
  const eachSeverityStartY = Object.keys(eachSeverityHeight).reduce(
    (acc, key) => {
      acc[Number(key)] = Object.keys(eachSeverityHeight).reduce((acc2, k) => {
        if (Number(k) > Number(key)) {
          acc2 += eachSeverityHeight[Number(k)];
        }
        return acc2;
      }, 0);
      return acc;
    },
    {} as Record<number, number>
  );

  groups.forEach((group) => {
    const currentFinding = graphFindingsNodes?.find(
      (f) => f.id === Number(group.id)
    );
    group.maxHeight = eachSeverityHeight[currentFinding?.overall_risk || 0];
    group.startY = eachSeverityStartY[currentFinding?.overall_risk || 0];
  });

  const legendNodes = Object.keys(eachSeverityAmounts).map((severity) => ({
    id: `legend-${severity}`,
    data: {
      label: SEVERITIES[Number(severity)],
      legendColor: theme[getGraphRiskColor(Number(severity))],
    },
    type: "legend",
    position: {
      x: 16,
      y:
        eachSeverityStartY[Number(severity)] +
        eachSeverityHeight[Number(severity)] / 2,
    },
    draggable: false,
    focusable: false,
  }));
  const smallestSeverity = Math.min(
    ...Object.keys(eachSeverityAmounts).map(Number)
  );
  const sectionNodes = Object.keys(eachSeverityAmounts)
    .filter((severity) => Number(severity) !== smallestSeverity)

    .map((severity) => ({
      id: `section-${severity}`,
      data: {
        width: `${screenWidth - 320}px`,
      },
      type: "section",
      position: {
        x: 0,
        y:
          eachSeverityStartY[Number(severity)] +
          eachSeverityHeight[Number(severity)],
      },
      draggable: false,
      focusable: false,
    }));

  return [...legendNodes, ...sectionNodes];
};

export const groupByVulnerabilities = (
  assets: GraphAsset[],
  relations: GraphRelation[]
): Record<string, GraphNode[]> => {
  return assets.reduce(
    (acc, asset) => {
      asset.related_findings =
        relations
          .filter((r) => r.asset_id === asset.id)
          .map((r) => r.finding_id) || [];
      const graphAsset = {
        risk: asset.risk_score,
        x: 0,
        y: 0,
        name: asset.name,
      };
      if ((asset.related_findings || []).length === 0) {
        return acc;
      }

      asset.related_findings?.forEach((findingId, i) => {
        if (!acc[findingId]) {
          acc[findingId] = [];
        }

        acc[findingId].push({
          ...graphAsset,
          id: `${getLetterByIndex("a", i)}${asset.id}`,
          groupId: findingId.toString(),
          isDuplicated: (asset.related_findings || []).length > 1,
          createdAt: asset.created_at,
        });
      });

      return acc;
    },
    {} as Record<string, GraphNode[]>
  );
};
