import { useContext } from "react";
import { FunnelGraph } from "@trutoo/funnel-graph";
import { ThemeContext } from "styled-components";
import { LabelMini } from "../../../../components/elements/typography/Typography";

const FindingFunnelCounters = ({
  graph,
  values,
}: {
  graph: FunnelGraph | null;
  values: number[][] | undefined;
}) => {
  const theme = useContext(ThemeContext);
  const riskColors = [
    theme.redPrimary,
    theme.orangePrimary,
    theme.yellowPrimary,
    theme.lightBluePrimary,
    theme.gray600,
  ];
  const crossAxisPoints = graph?.getCrossAxisPoints();
  const layerPercentages = graph?.getLayerPercentages();
  if (!values?.length || !crossAxisPoints?.length) return null;
  return (
    <div className="d-flex w-100" style={{ position: "absolute", top: 0 }}>
      {values?.map((layerValues, layerIdx) => (
        <div key={`col${layerIdx}`} className="w-25">
          {layerValues.map(
            (subLabelValue, subLabelsIdx) =>
              layerPercentages &&
              // Hide counter if less than 10%
              layerPercentages[layerIdx][subLabelsIdx] > 10 && (
                <div
                  key={`percentage-tag=${subLabelsIdx}`}
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    position: "absolute",
                    top: `${crossAxisPoints[subLabelsIdx][layerIdx] - 2}px`,
                    border: `2px solid ${riskColors[subLabelsIdx]}`,
                    height: "24px",
                    width: "48px",
                    fontSize: "12px",
                    borderRadius: "1em",
                    backgroundColor: theme.bg2,
                  }}
                >
                  <LabelMini>{subLabelValue}</LabelMini>
                </div>
              )
          )}
        </div>
      ))}
    </div>
  );
};

export default FindingFunnelCounters;
