/*
useApiNotificationReceivers() - Getting all the notification receivers for the current user. Will be used when a user wants to see all his/hers notifications.
useApiNotificationMarkRead() - Updating the notification to “read” from “unread”.
useApiNotificationsCountUnread() - Gets the number of unread notifications, for the current user.
*/

import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createItem,
  getItems,
  getPage,
  patchItem,
} from "../../../hooks/queries/sdk";
import {
  NotificationReceiver,
  NotificationReceiverPage,
} from "../../../types/Notifications";
import { invalidateApiQueries } from "../../../hooks/queries/utils";
import { bitAfterNow, getQueryParams } from "../../../shared/helper";

const key = "notification-receivers";
const NOTIFICATION_STALE_TIME = 1000 * 30; // 30 seconds, since notifications need to update frequently

interface NotificationReceiverMarkedReadParams {
  is_read: boolean;
}

interface CountUnreadContext {
  count_unread: number;
}

interface NotificationReceiverMarkReadContext {
  notificationReceiverId: number;
  notificationReceiverData: NotificationReceiverMarkedReadParams;
  onSuccessCallback?: (data: NotificationReceiver) => void;
  onErrorCallback?: (error: Error) => void;
}

interface NotificationReceiverMarkAllReadContext {
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: Error) => void;
}

interface ResponseDetail {
  detail: string;
}

export const useApiNotificationReceivers = () =>
  useQuery<NotificationReceiver[], Error>({
    queryKey: [key],
    placeholderData: [],
    staleTime: NOTIFICATION_STALE_TIME,
    refetchInterval: 30 * 1000,
    queryFn: async (): Promise<NotificationReceiver[]> => getItems(key),
  });

export const useApiNotificationReceiversPaging = () =>
  useInfiniteQuery<NotificationReceiverPage, Error>({
    queryKey: [key],
    keepPreviousData: true,
    staleTime: NOTIFICATION_STALE_TIME,
    refetchInterval: NOTIFICATION_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    queryFn: async ({ pageParam = 1 }): Promise<NotificationReceiverPage> =>
      getPage(key, {
        page: pageParam,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiNotificationMarkRead = () => {
  const queryClient = useQueryClient();
  return useMutation<
    NotificationReceiver,
    Error,
    NotificationReceiverMarkReadContext
  >({
    mutationKey: [key, "MarkRead"],
    mutationFn: async ({
      notificationReceiverId,
      notificationReceiverData,
    }: NotificationReceiverMarkReadContext): Promise<NotificationReceiver> =>
      await patchItem(key, notificationReceiverId, notificationReceiverData),
    onSuccess: (data: NotificationReceiver, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () => invalidateApiQueries([key, "MarkRead"], queryClient),
  });
};

export const useApiNotificationMarkAllRead = () => {
  const queryClient = useQueryClient();
  return useMutation<
    ResponseDetail,
    Error,
    NotificationReceiverMarkAllReadContext
  >({
    mutationKey: [key, "MarkAllRead"],
    mutationFn: async (): Promise<ResponseDetail> =>
      await createItem(key, {}, "mark-all-read"),
    onSuccess: (data: ResponseDetail, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback();
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () => invalidateApiQueries([key, "MarkAllRead"], queryClient),
  });
};

export const useApiNotificationsCountUnread = () =>
  useQuery<CountUnreadContext, Error>({
    queryKey: [key, "CountUnread"],
    placeholderData: { count_unread: 0 },
    staleTime: NOTIFICATION_STALE_TIME,
    refetchInterval: 30 * 1000,
    queryFn: async (): Promise<CountUnreadContext> =>
      getItems(key, {}, "count-unread"),
  });
