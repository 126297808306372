import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../icon/Icon";
import { StyleMainButton } from "./MainButton.style";
import "../buttonsAnimation.css";

type Props = {
  label: string;
  size?: "small" | "medium" | "large";
  onClick?: () => void;
  inProgress?: boolean;
  disabled?: boolean;
  iconName?: string;
  dataTestId?: string;
  style?: any;
  variant?: "primary" | "danger" | "secondary";
};

export const MainButton = (props: Props) => {
  const {
    label,
    size = "small",
    onClick,
    inProgress = false,
    disabled = false,
    iconName,
    style,
    variant = "primary",
  } = props;
  const theme = useContext(ThemeContext);

  return (
    <StyleMainButton
      onClick={onClick}
      disabled={disabled || inProgress}
      size={size}
      data-testid={props.dataTestId}
      style={style}
      className={
        inProgress
          ? `loading-animation ${
              theme.name === "dark" ? "loading-animation-dark" : ""
            }`
          : ""
      }
      variant={variant}
    >
      <>
        {!!iconName && (
          <Icon
            color={disabled ? theme.black600 : theme.white100}
            name={iconName}
            size={24}
          />
        )}
        {label}
      </>
    </StyleMainButton>
  );
};
