import React from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";

export const GraphAssetGlanceModalSkeleton = () => {
  return (
    <Flex column gap="16px">
      <SkeletonLoading width="100%" height="24px" />
      <SeparatorHorizontal />
      <SkeletonLoading width="100%" height="40px" />
      <SkeletonLoading width="100%" height="16px" />
      <SkeletonLoading width="100%" height="16px" />
      <SkeletonLoading width="100%" height="16px" />
      <SkeletonLoading width="100%" height="16px" />
      <SkeletonLoading width="100%" height="16px" />
      <Flex justify="between">
        <SkeletonLoading width="100px" height="40px" />
        <SkeletonLoading width="100px" height="40px" />
      </Flex>
    </Flex>
  );
};
