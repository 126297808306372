import styled from "styled-components";
import { TypographyBodyRegular } from "../../typography/Typography";
import { Flex } from "../../../layouts/flex/Flex";

type BreadcrumbProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  color?: string;
  width?: string;
};

const BreadcrumbButtonStyle = styled.button<BreadcrumbProps>`
  ${TypographyBodyRegular}
  display: flex;
  align-items: start;
  box-sizing: border-box;
  padding: 2px 0px;
  background-color: transparent;
  border: 0px;
  border-radius: 50%;
  transition: 0.2s;
  color: ${(props) => props.color || props.theme.blue600};
  font-weight: 400;
  &:hover:enabled {
    color: ${(props) => props.theme.blue700};
    font-weight: 500;
  }
  &:active:enabled {
    color: ${(props) => props.theme.blue800};
  }
  &:disabled {
    color: ${(props) => props.theme.textSub};
  }
  &:focus {
    outline: none;
    color: ${(props) => props.theme.blue700};
    font-weight: 500;
  }
`;

export const BreadcrumbButton = (props: BreadcrumbProps) => {
  const { onClick, disabled = false, label, width } = props;
  return (
    <Flex align="center" style={{width}}>
    <BreadcrumbButtonStyle onClick={onClick} disabled={disabled} label={label}>
      {label}
    </BreadcrumbButtonStyle>
    </Flex>
  );
};
