import React, { useEffect, useState } from "react";
import { ChatMessage } from "./ChatMessage";

type Props = {
  latestUserMessage: string;
};

export const LOADING_MESSAGES = [
  "Thinking...",
  "Running diagnostics… ",
  "Evaluating risks…",
  "Crunching numbers, catching threats… please hold tight!",
  "Running our AI magic… your secure world is just seconds away.",
];

export const LoadingChatMessage = (props: Props) => {
  const { latestUserMessage } = props;
  const [loadingMessage, setLoadingMessage] = useState<string>("Thinking...");

  const getRandomWaitTime = (): number => {
    const min = 3000; // 3 secs
    const max = 5000; // 5 secs
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const index = LOADING_MESSAGES.findIndex((m) => m === loadingMessage);
      setLoadingMessage(
        index <= LOADING_MESSAGES.length - 2
          ? LOADING_MESSAGES[index + 1]
          : LOADING_MESSAGES[0]
      );
    }, getRandomWaitTime());

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [loadingMessage]);

  return (
    <>
      <ChatMessage
        chatMessage={{
          text: latestUserMessage,
          role: "user",
        }}
      />
      <ChatMessage
        chatMessage={{
          text: loadingMessage,
          role: "assistant",
        }}
      />
    </>
  );
};
