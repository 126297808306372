import { useRef, useState } from "react";
import { Menu } from "../../components/elements/menu/Menu";
import { Flex } from "../../components/layouts/flex/Flex";
import { Link } from "react-router-dom";
import { LinkButton } from "../../components/elements/button/link/LinkButton";
import { objectToBase64 } from "../../shared/helper";
import { Filters } from "./Findings";
import { exportFindingsTable } from "../../hooks/queries/findingContext";
import { convertToServerFilters } from "./filters/FindingsFilterBL";
import useToastContext from "../../hooks/toastHook";
import { useOutsideTrigger } from "../../hooks/utilsHooks";
import { Finding } from "../../types/Finding";
import { Mixpanel } from "../../shared/mixpanel";

type Props = {
  filters: Filters;
  selectedFindings: Finding[];
  isSelectAll: boolean;
};

export const ExportList = (props: Props) => {
  const { filters, isSelectAll, selectedFindings } = props;
  const wrapperRef = useRef(null);
  const addToast = useToastContext();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  useOutsideTrigger(wrapperRef, () => setShowMenu(false));

  const onExportTable = ({
    exportSelected = false,
  }: {
    exportSelected?: boolean;
  }) => {
    const filtersCopy = { ...filters };

    if (exportSelected)
      filtersCopy.id = selectedFindings.map((f) => f.id).join(",");

    exportFindingsTable(convertToServerFilters(filtersCopy, false))
      .then((blob) => {
        if (!blob) {
          addToast({ type: "error", message: "Error exporting table" });
          return;
        }
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `findings_table.csv`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      })
      .finally(() => setShowMenu(false));
  };

  const getPdfUrlParams = () => {
    if (isSelectAll) return `filters=${objectToBase64(filters)}`;
    if (!!selectedFindings.length)
      return `ids=${selectedFindings.map((f) => f.id).join(",")}`;
    return ``;
  };

  return (
    <Flex style={{ position: "relative" }} data-tut="findings-export">
      <Flex style={{ width: "140px" }}>
        <LinkButton
          dataTestId="findings-export"
          label="Export"
          iconName="export"
          onClick={() => setShowMenu((state) => !state)}
        />
      </Flex>
      {showMenu && (
        <Menu
          ref={wrapperRef}
          style={{ position: "absolute", top: "30px", width: "230px" }}
        >
          <Flex column>
            {isSelectAll || !!selectedFindings.length ? (
              <>
                <div>
                  <Link
                    to={`/findings/pdf?${getPdfUrlParams()}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                    target="_blank"
                  >
                    <LinkButton
                      label="Export Selected as PDF"
                      onClick={() => {
                        Mixpanel.track("Export Findings Table", {
                          type: "PDF",
                        });
                        setShowMenu(false);
                      }}
                    />
                  </Link>
                </div>
                <div>
                  <LinkButton
                    label="Export Selected as CSV"
                    onClick={() => {
                      Mixpanel.track("Export Findings Table", { type: "CSV" });
                      onExportTable({ exportSelected: true });
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div data-tut="findings-export-pdf">
                  <Link
                    to={`/findings/pdf?filters=${objectToBase64(filters)}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                    target="_blank"
                  >
                    <LinkButton
                      label="Export List as PDF"
                      onClick={() => {
                        Mixpanel.track("Export Findings Table", {
                          type: "PDF",
                        });
                        setShowMenu(false);
                      }}
                    />
                  </Link>
                </div>
                <div data-tut="findings-export-csv">
                  <LinkButton
                    label="Export List as CSV"
                    onClick={() => {
                      Mixpanel.track("Export Findings Table", { type: "CSV" });
                      onExportTable({});
                    }}
                  />
                </div>
              </>
            )}
          </Flex>
        </Menu>
      )}
    </Flex>
  );
};
