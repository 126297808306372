import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { $createCodeNode, $isCodeNode, CODE_LANGUAGE_FRIENDLY_NAME_MAP, CODE_LANGUAGE_MAP, getLanguageFriendlyName, } from "@lexical/code";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isListNode, INSERT_CHECK_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode, REMOVE_LIST_COMMAND, } from "@lexical/list";
// import { INSERT_EMBED_COMMAND } from "@lexical/react/LexicalAutoEmbedPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import { $createHeadingNode, $createQuoteNode, $isHeadingNode, $isQuoteNode, } from "@lexical/rich-text";
import { $getSelectionStyleValueForProperty, $isParentElementRTL, $patchStyleText, $setBlocksType, } from "@lexical/selection";
import { $isTableNode } from "@lexical/table";
import { $findMatchingParent, $getNearestBlockElementAncestorOrThrow, $getNearestNodeOfType, mergeRegister, } from "@lexical/utils";
import { $createParagraphNode, $getNodeByKey, 
// $getRoot,
$getSelection, $isRangeSelection, $isRootOrShadowRoot, $isTextNode, CAN_REDO_COMMAND, CAN_UNDO_COMMAND, COMMAND_PRIORITY_CRITICAL, COMMAND_PRIORITY_NORMAL, DEPRECATED_$isGridSelection, FORMAT_TEXT_COMMAND, KEY_MODIFIER_COMMAND, SELECTION_CHANGE_COMMAND, } from "lexical";
import { useCallback, useEffect, useState } from "react";
import useModal from "../../hooks/useModal";
// import catTypingGif from "../../images/cat-typing.gif";
// import { $createStickyNode } from "../../nodes/StickyNode";
import DropDown, { DropDownItem } from "../../ui/DropDown";
// import DropdownColorPicker from "../../ui/DropdownColorPicker";
import { getSelectedNode } from "../../utils/getSelectedNode";
import { sanitizeUrl } from "../../utils/url";
// import { EmbedConfigs } from "../AutoEmbedPlugin";
// import { INSERT_COLLAPSIBLE_COMMAND } from "../CollapsiblePlugin";
// import { InsertEquationDialog } from "../EquationsPlugin";
// import { INSERT_EXCALIDRAW_COMMAND } from "../ExcalidrawPlugin";
import { 
// INSERT_IMAGE_COMMAND,
InsertImageDialog,
// InsertImagePayload,
 } from "../ImagesPlugin";
// import { InsertPollDialog } from "../PollPlugin";
import { InsertTableDialog } from "../TablePlugin";
import { IS_APPLE } from "../../shared/environment";
const blockTypeToBlockName = {
    bullet: "Bulleted List",
    check: "Check List",
    code: "Code Block",
    h1: "Heading 1",
    h2: "Heading 2",
    h3: "Heading 3",
    h4: "Heading 4",
    h5: "Heading 5",
    h6: "Heading 6",
    number: "Numbered List",
    paragraph: "Normal",
    quote: "Quote",
};
const rootTypeToRootName = {
    root: "Root",
    table: "Table",
};
function getCodeLanguageOptions() {
    const options = [];
    for (const [lang, friendlyName] of Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP)) {
        options.push([lang, friendlyName]);
    }
    return options;
}
const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions();
const FONT_FAMILY_OPTIONS = [
    ["Arial", "Arial"],
    ["Courier New", "Courier New"],
    ["Georgia", "Georgia"],
    ["Times New Roman", "Times New Roman"],
    ["Trebuchet MS", "Trebuchet MS"],
    ["Verdana", "Verdana"],
];
const FONT_SIZE_OPTIONS = [
    ["10px", "10px"],
    ["11px", "11px"],
    ["12px", "12px"],
    ["13px", "13px"],
    ["14px", "14px"],
    ["15px", "15px"],
    ["16px", "16px"],
    ["17px", "17px"],
    ["18px", "18px"],
    ["19px", "19px"],
    ["20px", "20px"],
];
function dropDownActiveClass(active) {
    if (active)
        return "active dropdown-item-active";
    else
        return "";
}
function BlockFormatDropDown({ editor, blockType, rootType, disabled = false, }) {
    const formatParagraph = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection) ||
                DEPRECATED_$isGridSelection(selection)) {
                $setBlocksType(selection, () => $createParagraphNode());
            }
        });
    };
    const formatHeading = (headingSize) => {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection) ||
                    DEPRECATED_$isGridSelection(selection)) {
                    $setBlocksType(selection, () => $createHeadingNode(headingSize));
                }
            });
        }
    };
    const formatBulletList = () => {
        if (blockType !== "bullet") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        }
        else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };
    const formatCheckList = () => {
        if (blockType !== "check") {
            editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
        }
        else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };
    const formatNumberedList = () => {
        if (blockType !== "number") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        }
        else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };
    const formatQuote = () => {
        if (blockType !== "quote") {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection) ||
                    DEPRECATED_$isGridSelection(selection)) {
                    $setBlocksType(selection, () => $createQuoteNode());
                }
            });
        }
    };
    const formatCode = () => {
        if (blockType !== "code") {
            editor.update(() => {
                let selection = $getSelection();
                if ($isRangeSelection(selection) ||
                    DEPRECATED_$isGridSelection(selection)) {
                    if (selection.isCollapsed()) {
                        $setBlocksType(selection, () => $createCodeNode());
                    }
                    else {
                        const textContent = selection.getTextContent();
                        const codeNode = $createCodeNode();
                        selection.insertNodes([codeNode]);
                        selection = $getSelection();
                        if ($isRangeSelection(selection))
                            selection.insertRawText(textContent);
                    }
                }
            });
        }
    };
    return (_jsxs(DropDown, { disabled: disabled, buttonClassName: "toolbar-item block-controls", buttonIconClassName: "icon block-type " + blockType, buttonLabel: blockTypeToBlockName[blockType], buttonAriaLabel: "Formatting options for text style", children: [_jsxs(DropDownItem, { className: "item " + dropDownActiveClass(blockType === "paragraph"), onClick: formatParagraph, children: [_jsx("i", { className: "icon paragraph" }), _jsx("span", { className: "text", children: "Normal" })] }), _jsxs(DropDownItem, { className: "item " + dropDownActiveClass(blockType === "h1"), onClick: () => formatHeading("h1"), children: [_jsx("i", { className: "icon h1" }), _jsx("span", { className: "text", children: "Heading 1" })] }), _jsxs(DropDownItem, { className: "item " + dropDownActiveClass(blockType === "h2"), onClick: () => formatHeading("h2"), children: [_jsx("i", { className: "icon h2" }), _jsx("span", { className: "text", children: "Heading 2" })] }), _jsxs(DropDownItem, { className: "item " + dropDownActiveClass(blockType === "h3"), onClick: () => formatHeading("h3"), children: [_jsx("i", { className: "icon h3" }), _jsx("span", { className: "text", children: "Heading 3" })] }), _jsxs(DropDownItem, { className: "item " + dropDownActiveClass(blockType === "bullet"), onClick: formatBulletList, children: [_jsx("i", { className: "icon bullet-list" }), _jsx("span", { className: "text", children: "Bullet List" })] }), _jsxs(DropDownItem, { className: "item " + dropDownActiveClass(blockType === "number"), onClick: formatNumberedList, children: [_jsx("i", { className: "icon numbered-list" }), _jsx("span", { className: "text", children: "Numbered List" })] }), _jsxs(DropDownItem, { className: "item " + dropDownActiveClass(blockType === "code"), onClick: formatCode, children: [_jsx("i", { className: "icon code" }), _jsx("span", { className: "text", children: "Code Block" })] })] }));
}
function Divider() {
    return _jsx("div", { className: "divider" });
}
function FontDropDown({ editor, value, customStyle, disabled = false, }) {
    const handleClick = useCallback((option) => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $patchStyleText(selection, {
                    [customStyle]: option,
                });
            }
        });
    }, [editor, customStyle]);
    const buttonAriaLabel = customStyle === "font-family"
        ? "Formatting options for font family"
        : "Formatting options for font size";
    return (_jsx(DropDown, { disabled: disabled, buttonClassName: "toolbar-item " + customStyle, buttonLabel: value, buttonIconClassName: customStyle === "font-family" ? "icon block-type font-family" : "", buttonAriaLabel: buttonAriaLabel, children: (customStyle === "font-family"
            ? FONT_FAMILY_OPTIONS
            : FONT_SIZE_OPTIONS).map(([option, text]) => (_jsx(DropDownItem, { className: `item ${dropDownActiveClass(value === option)} ${customStyle === "font-size" ? "fontsize-item" : ""}`, onClick: () => handleClick(option), children: _jsx("span", { className: "text", children: text }) }, option))) }));
}
export default function ToolbarPlugin() {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [blockType, setBlockType] = useState("paragraph");
    const [rootType, setRootType] = useState("root");
    const [selectedElementKey, setSelectedElementKey] = useState(null);
    const [fontSize, setFontSize] = useState("15px");
    // const [fontColor, setFontColor] = useState<string>("#000");
    // const [bgColor, setBgColor] = useState<string>("#fff");
    const [fontFamily, setFontFamily] = useState("Arial");
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isSubscript, setIsSubscript] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);
    const [isCode, setIsCode] = useState(false);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [modal, showModal] = useModal();
    const [isRTL, setIsRTL] = useState(false);
    const [codeLanguage, setCodeLanguage] = useState("");
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());
    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element = anchorNode.getKey() === "root"
                ? anchorNode
                : $findMatchingParent(anchorNode, (e) => {
                    const parent = e.getParent();
                    return parent !== null && $isRootOrShadowRoot(parent);
                });
            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }
            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);
            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsSubscript(selection.hasFormat("subscript"));
            setIsSuperscript(selection.hasFormat("superscript"));
            setIsCode(selection.hasFormat("code"));
            setIsRTL($isParentElementRTL(selection));
            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            }
            else {
                setIsLink(false);
            }
            const tableNode = $findMatchingParent(node, $isTableNode);
            if ($isTableNode(tableNode)) {
                setRootType("table");
            }
            else {
                setRootType("root");
            }
            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(type);
                }
                else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type);
                    }
                    if ($isCodeNode(element)) {
                        const language = element.getLanguage();
                        setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : "");
                        return;
                    }
                }
            }
            // Handle buttons
            setFontSize($getSelectionStyleValueForProperty(selection, "font-size", "15px"));
            // setFontColor(
            //   $getSelectionStyleValueForProperty(selection, "color", "#000")
            // );
            // setBgColor(
            //   $getSelectionStyleValueForProperty(
            //     selection,
            //     "background-color",
            //     "#fff"
            //   )
            // );
            setFontFamily($getSelectionStyleValueForProperty(selection, "font-family", "Arial"));
        }
    }, [activeEditor]);
    useEffect(() => {
        return editor.registerCommand(SELECTION_CHANGE_COMMAND, (_payload, newEditor) => {
            $updateToolbar();
            setActiveEditor(newEditor);
            return false;
        }, COMMAND_PRIORITY_CRITICAL);
    }, [editor, $updateToolbar]);
    useEffect(() => {
        return mergeRegister(editor.registerEditableListener((editable) => {
            setIsEditable(editable);
        }), activeEditor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                $updateToolbar();
            });
        }), activeEditor.registerCommand(CAN_UNDO_COMMAND, (payload) => {
            setCanUndo(payload);
            return false;
        }, COMMAND_PRIORITY_CRITICAL), activeEditor.registerCommand(CAN_REDO_COMMAND, (payload) => {
            setCanRedo(payload);
            return false;
        }, COMMAND_PRIORITY_CRITICAL));
    }, [$updateToolbar, activeEditor, editor]);
    useEffect(() => {
        return activeEditor.registerCommand(KEY_MODIFIER_COMMAND, (payload) => {
            const event = payload;
            const { code, ctrlKey, metaKey } = event;
            if (code === "KeyK" && (ctrlKey || metaKey)) {
                event.preventDefault();
                return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"));
            }
            return false;
        }, COMMAND_PRIORITY_NORMAL);
    }, [activeEditor, isLink]);
    // const applyStyleText = useCallback(
    //   (styles: Record<string, string>) => {
    //     activeEditor.update(() => {
    //       const selection = $getSelection();
    //       if ($isRangeSelection(selection)) {
    //         $patchStyleText(selection, styles);
    //       }
    //     });
    //   },
    //   [activeEditor]
    // );
    const clearFormatting = useCallback(() => {
        activeEditor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const anchor = selection.anchor;
                const focus = selection.focus;
                const nodes = selection.getNodes();
                if (anchor.key === focus.key && anchor.offset === focus.offset) {
                    return;
                }
                nodes.forEach((node, idx) => {
                    // We split the first and last node by the selection
                    // So that we don't format unselected text inside those nodes
                    if ($isTextNode(node)) {
                        if (idx === 0 && anchor.offset !== 0) {
                            node = node.splitText(anchor.offset)[1] || node;
                        }
                        if (idx === nodes.length - 1) {
                            node = node.splitText(focus.offset)[0] || node;
                        }
                        if (node.__style !== "") {
                            node.setStyle("");
                        }
                        if (node.__format !== 0) {
                            node.setFormat(0);
                            $getNearestBlockElementAncestorOrThrow(node).setFormat("");
                        }
                    }
                    else if ($isHeadingNode(node) || $isQuoteNode(node)) {
                        node.replace($createParagraphNode(), true);
                    }
                    else if ($isDecoratorBlockNode(node)) {
                        node.setFormat("");
                    }
                });
            }
        });
    }, [activeEditor]);
    // const onFontColorSelect = useCallback(
    //   (value: string) => {
    //     applyStyleText({ color: value });
    //   },
    //   [applyStyleText]
    // );
    // const onBgColorSelect = useCallback(
    //   (value: string) => {
    //     applyStyleText({ "background-color": value });
    //   },
    //   [applyStyleText]
    // );
    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"));
        }
        else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);
    const onCodeLanguageSelect = useCallback((value) => {
        activeEditor.update(() => {
            if (selectedElementKey !== null) {
                const node = $getNodeByKey(selectedElementKey);
                if ($isCodeNode(node)) {
                    node.setLanguage(value);
                }
            }
        });
    }, [activeEditor, selectedElementKey]);
    // const insertGifOnClick = (payload: InsertImagePayload) => {
    //   activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    // };
    const theme = localStorage.getItem("wasp-theme");
    return (_jsxs("div", { className: "toolbar", style: {
            filter: theme === "dark" ? "invert(1) hue-rotate(180deg)" : "none",
        }, children: [blockType in blockTypeToBlockName && activeEditor === editor && (_jsxs(_Fragment, { children: [_jsx(BlockFormatDropDown, { disabled: !isEditable, blockType: blockType, rootType: rootType, editor: editor }), _jsx(Divider, {})] })), blockType === "code" ? (_jsx(DropDown, { disabled: !isEditable, buttonClassName: "toolbar-item code-language", buttonLabel: getLanguageFriendlyName(codeLanguage), buttonAriaLabel: "Select language", children: CODE_LANGUAGE_OPTIONS.map(([value, name]) => {
                    return (_jsx(DropDownItem, { className: `item ${dropDownActiveClass(value === codeLanguage)}`, onClick: () => onCodeLanguageSelect(value), children: _jsx("span", { className: "text", children: name }) }, value));
                }) })) : (_jsxs(_Fragment, { children: [_jsx("button", { disabled: !isEditable, onClick: () => {
                            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                        }, className: "toolbar-item spaced " + (isBold ? "active" : ""), title: IS_APPLE ? "Bold (⌘B)" : "Bold (Ctrl+B)", type: "button", "aria-label": `Format text as bold. Shortcut: ${IS_APPLE ? "⌘B" : "Ctrl+B"}`, children: _jsx("i", { className: "format bold" }) }), _jsx("button", { disabled: !isEditable, onClick: () => {
                            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                        }, className: "toolbar-item spaced " + (isItalic ? "active" : ""), title: IS_APPLE ? "Italic (⌘I)" : "Italic (Ctrl+I)", type: "button", "aria-label": `Format text as italics. Shortcut: ${IS_APPLE ? "⌘I" : "Ctrl+I"}`, children: _jsx("i", { className: "format italic" }) }), _jsx("button", { disabled: !isEditable, onClick: () => {
                            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                        }, className: "toolbar-item spaced " + (isUnderline ? "active" : ""), title: IS_APPLE ? "Underline (⌘U)" : "Underline (Ctrl+U)", type: "button", "aria-label": `Format text to underlined. Shortcut: ${IS_APPLE ? "⌘U" : "Ctrl+U"}`, children: _jsx("i", { className: "format underline" }) }), _jsx("button", { disabled: !isEditable, onClick: insertLink, className: "toolbar-item spaced " + (isLink ? "active" : ""), "aria-label": "Insert link", title: "Insert link", type: "button", children: _jsx("i", { className: "format link" }) }), _jsx(Divider, {}), rootType === "table" && (_jsxs(_Fragment, { children: [_jsx(DropDown, { disabled: !isEditable, buttonClassName: "toolbar-item spaced", buttonLabel: "Table", buttonAriaLabel: "Open table toolkit", buttonIconClassName: "icon table secondary", children: _jsx(DropDownItem, { onClick: () => {
                                        /**/
                                    }, className: "item", children: _jsx("span", { className: "text", children: "TODO" }) }) }), _jsx(Divider, {})] })), _jsxs(DropDown, { disabled: !isEditable, buttonClassName: "toolbar-item spaced", buttonLabel: "" // Insert
                        , buttonAriaLabel: "Insert specialized editor node", buttonIconClassName: "icon plus", children: [_jsxs(DropDownItem, { onClick: () => {
                                    activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
                                }, className: "item", children: [_jsx("i", { className: "icon horizontal-rule" }), _jsx("span", { className: "text", children: "Horizontal Rule" })] }), _jsxs(DropDownItem, { onClick: () => {
                                    showModal("Insert Image", (onClose) => (_jsx(InsertImageDialog, { activeEditor: activeEditor, onClose: onClose })));
                                }, className: "item", children: [_jsx("i", { className: "icon image" }), _jsx("span", { className: "text", children: "Image" })] }), _jsxs(DropDownItem, { onClick: () => {
                                    showModal("Insert Table", (onClose) => (_jsx(InsertTableDialog, { activeEditor: activeEditor, onClose: onClose })));
                                }, className: "item", children: [_jsx("i", { className: "icon table" }), _jsx("span", { className: "text", children: "Table" })] })] })] })), modal] }));
}
