import { Dispatch, SetStateAction, useContext } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { ScanEvent } from "../../../types/ScanEvent";
import { Box } from "../../../components/elements/box/Box";
import { Icon } from "../../../components/elements/icon/Icon";
import { useApiAssetsWithViewPaging } from "../../../hooks/queries/assetsContext";
import { Filter } from "../../../types/AssetsView";
import { AssetItem, ScanTimes } from "./ScanResultsPane";

type Props = {
  scan: ScanEvent | undefined;
  setAssetOnDisplay: Dispatch<SetStateAction<number>>;
};

export const ScanResultsPaneAssets = (props: Props) => {
  const { scan, setAssetOnDisplay } = props;
  const theme = useContext(ThemeContext);

  const filters: Filter[] = [
    {
      column: "scan_id",
      condition: "is",
      next_condition: undefined,
      order: 0,
      value: `${scan?.id}`,
    },
  ];

  const { data: assetsPages } = useApiAssetsWithViewPaging(
    filters,
    "created_at",
    false
  );
  const scannedAssets = assetsPages?.pages
    .map((page) => page.results || [])
    .flat();

  return (
    <>
      <Box>
        <ScanTimes scan={scan} />
      </Box>
      <SeparatorHorizontal />
      <Flex gap="8px">
        <Icon name="assetsOutline" color={theme.primary} size={32} />
        <HeaderSecondary>Discovered Assets</HeaderSecondary>
      </Flex>
      <SeparatorHorizontal />
      {scannedAssets?.map((asset) => (
        <AssetItem
          asset={asset}
          key={asset.id}
          setAssetOnDisplay={setAssetOnDisplay}
        />
      ))}
    </>
  );
};
