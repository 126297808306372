import React from "react";
import { IconProps } from "./WaspIcon";

export function ChevronLeftIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.44618 11.9999C8.44617 11.9211 8.46169 11.8431 8.49186 11.7703C8.52202 11.6975 8.56623 11.6314 8.62197 11.5757L14.5282 5.67004C14.5837 5.61341 14.6499 5.56834 14.7229 5.53744C14.7959 5.50654 14.8744 5.49042 14.9537 5.49002C15.033 5.48962 15.1116 5.50495 15.1849 5.53511C15.2582 5.56527 15.3249 5.60966 15.3809 5.66573C15.437 5.7218 15.4814 5.78843 15.5116 5.86177C15.5417 5.9351 15.557 6.01369 15.5566 6.09299C15.5562 6.17228 15.5401 6.25071 15.5092 6.32374C15.4783 6.39676 15.4332 6.46294 15.3766 6.51844L9.89462 11.9999L15.3767 17.4814C15.4873 17.5943 15.5489 17.7462 15.5481 17.9043C15.5473 18.0624 15.4842 18.2137 15.3724 18.3255C15.2606 18.4373 15.1093 18.5004 14.9512 18.5012C14.7931 18.502 14.6411 18.4404 14.5283 18.3298L8.62201 12.4241C8.56627 12.3684 8.52205 12.3023 8.49188 12.2295C8.46171 12.1567 8.44618 12.0787 8.44618 11.9999Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
