import { useState } from "react";
import { useApiMe } from "../../../hooks/queries/meContext";
import { BASE_API_URL, getBaseConfig } from "../../../hooks/queries/utils";
import TermsOfService from "./TermsOfService";
import { Loading } from "../../../components/elements/loading/Loading";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SimpleCheckbox } from "../../../components/elements/checkbox/SimpleCheckbox";
import { Flex } from "../../../components/layouts/flex/Flex";
import { WaspLogo } from "./WaspLogo";
import { HeaderMain } from "../../../components/elements/typography/Typography";
import { Box } from "../../../components/elements/box/Box";
import { PoweredByOP } from "./PoweredByOP";

const LegalStuff = () => {
  const { data: me, isSuccess } = useApiMe();
  const [isAcceptLegalTerms, setIsAcceptLegalTerms] = useState(false);
  const [sendInProcess, setSendInProcess] = useState(false);

  const handleSubmit = () => {
    if (!isAcceptLegalTerms) console.log(`Cannot continue without acceptance`);
    setSendInProcess(true);

    fetch(`${BASE_API_URL}/users/accept_legal_terms/`, {
      ...getBaseConfig("post"),
      body: JSON.stringify({ accept: isAcceptLegalTerms }),
    })
      // fetch(`users/accept_legal_terms/`, { accept: isAcceptLegalTerms })
      .then((data) => {
        setSendInProcess(false);
        if (isSuccess && data.ok && me.customer) {
          window.location.replace(
            `/${me.customer.name.replace(" ", "-").toLowerCase()}`
          );
          localStorage.setItem("show_tour", "true");
        } else console.log(`Failed to update legal terms acceptance`);
      })
      .catch((e) => {
        console.error(e);
        setSendInProcess(false);
      });
  };

  return (
    <Flex style={{ width: "100vw", height: "100vh" }} justify="around">
      <Flex column justify="around">
        <Box style={{ minWidth: "420px", maxWidth: "1050px", height: "650px" }}>
          <WaspLogo />

          <Flex w100 align="center" justify="center">
            <HeaderMain>Legal Terms </HeaderMain>
          </Flex>

          <div
            className="col-12  my-5 p-2 text-left"
            style={{
              overflowY: "auto",
              maxHeight: "200px",
              fontSize: "12px",
            }}
          >
            <TermsOfService />
          </div>
          <Flex w100 justify="center">
            <Flex column className="text-center">
              {!me?.accept_terms ? (
                <>
                  <SimpleCheckbox
                    id="legal-terms-checkbox"
                    label="I Accept the Legal Terms"
                    onChange={() => {
                      setIsAcceptLegalTerms((prev) => !prev);
                    }}
                    isChecked={isAcceptLegalTerms}
                  />
                  {sendInProcess && <Loading />}
                  <Flex justify="center" w100 className="my-3">
                    <MainButton
                      onClick={handleSubmit}
                      disabled={!isAcceptLegalTerms}
                      label="Continue"
                      inProgress={sendInProcess}
                      size="medium"
                    />
                  </Flex>
                </>
              ) : (
                <a href="/">Back to Wasp</a>
              )}

              <PoweredByOP />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default LegalStuff;
