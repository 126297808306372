import { useState } from "react";
import { FilterLine } from "../../../components/composed/filterLine/FilterLine";
import { AssetsViewProps, Filter } from "../../../types/AssetsView";
import { AssetsFiltersPane, STORAGE_KEY } from "./AssetsFiltersPane";
import { AssetsFiltersBadges } from "./AssetsFiltersBadges";
import { emptyAssetsViewProps } from "./FiltersUtils";
import { Mixpanel } from "../../../shared/mixpanel";
import { toBase64AssetsView } from "../../../shared/helper";
import { useSearchParams } from "react-router-dom";

type Props = {
  assetsView: AssetsViewProps;
  setAssetsView?: (assetsView: AssetsViewProps) => void;
};

export const AssetsFilterLine = (props: Props) => {
  const { assetsView, setAssetsView } = props;
  const [showFiltersPanel, setShowFiltersPanel] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleAssetsViewChange = (assetViewProps: AssetsViewProps) => {
    if (setAssetsView) setAssetsView(assetViewProps);
    else {
      const viewDecoded = toBase64AssetsView(assetViewProps);
      searchParams.set("view", viewDecoded);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <FilterLine
        filtersBadges={
          <AssetsFiltersBadges
            filters={assetsView.filters}
            setFilters={(filters: Filter[]) =>
              handleAssetsViewChange({ ...assetsView, filters: filters })
            }
          />
        }
        hasBadges={assetsView.filters.length > 0}
        onShowFiltersPanel={() => {
          setShowFiltersPanel(true);
          Mixpanel.track("Assets Filter Line - Open Filters Panel");
        }}
        onRemoveAllFilters={() => handleAssetsViewChange(emptyAssetsViewProps)}
        showSaveAsFilterButton={true}
        onSaveAsFilter={() => {
          localStorage.setItem(STORAGE_KEY, "true");
          setShowFiltersPanel(true);
          Mixpanel.track("Assets Filter Line - Open Filters Panel");
        }}
        isAssetsFilter={true}
      />
      {showFiltersPanel && (
        <AssetsFiltersPane
          assetsView={assetsView}
          setAssetsView={handleAssetsViewChange}
          onClose={() => setShowFiltersPanel(false)}
        />
      )}
    </>
  );
};
