import React, { useContext, useState } from "react";
import { Box } from "../../../../components/elements/box/Box";
import { ThemeContext } from "styled-components";
import { Chart } from "regraph";
import { formatDataForReGraphCombos } from "./RegraphUtils";
import { useApiReGraphItems } from "./context/ReGraphContext";
import { Loading } from "../../../../components/elements/loading/Loading";
import awsImg from "../../../../assets/icons/aws.svg";

export const AssetsReGraphCombos = () => {
  const theme = useContext(ThemeContext);
  var reGraphItems = {};
  const { data, isFetching } = useApiReGraphItems();
  const [openCombos, setOpenCombos] = useState<any>({});
  const [combine, setCombine] = useState<any>({
    level: 3,
    properties: ["type", "product_id"],
  });
  if (!isFetching) {
    reGraphItems = formatDataForReGraphCombos(data);
  }

  const combineNodesHandler = ({ id, setStyle, combo }: any) => {
    const { type } = combo;
    var nodeImage = "";
    var text = "";
    var glyphs: any = [];
    if (type === "domain") {
      nodeImage = "/icons/asset-server.svg";
      text = "Domains";
    } else if (type === "s3_bucket") {
      nodeImage = awsImg;
      text = "S3 Buckets";
    } else {
      nodeImage = "/icons/product.svg";
      text = "Product";
      glyphs = [
        {
          position: "ne",
          border: {
            color: "#f15d5b",
          },
          color: "#f15d5b",
          label: {
            text: "!",
          },
        },
      ];
    }
    setStyle({
      glyphs: glyphs,
      tightness: 1,
      open: !!openCombos[id],
      color: "#DCDCDC",
      closedStyle: {
        glyphs: glyphs,
        label: {
          bold: true,
          color: "black",
          position: "s",
          text: text,
        },
        image: nodeImage,
      },
      label: {
        bold: true,
        color: "black",
        position: "s",
        text: text,
      },
      image: nodeImage,
    });
  };

  const combineLinksHandler: Chart.onCombineLinksHandler = ({
    setStyle,
  }: any) => {
    setStyle({
      lineStyle: "dotted",
      width: 2,
      flow: true,
    });
  };

  const doubleClickHandler = ({ id }: any) => {
    /* Toggle the value on the openCombos object,

      this value is used to set the open state of the combo.


      Also creates a new 'combine' object. This forces ReGraph to

      do a re-render and call 'onCombineNodes'

      so we can set the open value of the combo. */
    setOpenCombos((current: any) => ({ ...current, [id]: !current[id] }));
    setCombine((current: any) => ({ ...current }));
  };
  console.log(openCombos);

  return (
    <Box
      className="d-flex flex-column w-100"
      style={{
        position: "relative",
        height: `calc(100vh - 260px)`,
        cursor: "grab",
        backgroundColor: theme.white100,
      }}
    >
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <Chart
            items={reGraphItems}
            combine={combine}
            layout={{ tightness: 1, linkShape: "curved" }}
            onCombineNodes={combineNodesHandler}
            onDoubleClick={doubleClickHandler}
            onCombineLinks={combineLinksHandler}
            options={{ logo: { image: "/favicon.ico", position: "ne" } }}
          />
        </>
      )}
    </Box>
  );
};
