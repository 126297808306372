import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { IMPACTS, MITIGATION } from "../../../../shared/consts";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { light } from "../../../../shared/theme";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import {
  getOptionFromKeyValuePairs,
  mapToOptions,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";
import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router";

type Props = {
  finding: Finding | undefined | null;
  isEditable?: boolean;
  noSeparator?: boolean;
  isMitigationValues?: boolean;
  isReport?: boolean;
  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const ImpactItem = (props: Props) => {
  const {
    finding,
    isEditable,
    noSeparator = false,
    isMitigationValues,
    isReport = false,
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const { id: updateFindingId } = useParams();
  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  const values = isMitigationValues ? MITIGATION : IMPACTS;
  const findingImpact = finding?.impact || 0;

  return (
    <Section title="Impact" noSeparator={noSeparator} isReport={isReport}>
      {!isEditable ? (
        <BodyRegular
          className="text-truncate w-100"
          style={{ color: isReport ? light.black800 : "" }}
        >
          {values[isMitigationValues ? findingImpact + 1 : findingImpact]}
        </BodyRegular>
      ) : (
        <Dropdown
          searchable
          variant="outline"
          size="medium"
          value={getOptionFromKeyValuePairs(
            IMPACTS,
            editableUpdateFindingData?.impact || 0
          )}
          options={mapToOptions(IMPACTS)}
          onChange={(selectedOption: any) => {
            setEditableUpdateFinding &&
              setEditableUpdateFinding((prev) => ({
                ...prev,
                impact: Number(selectedOption.value),
              }));
            updateInPlace({
              impact: Number(selectedOption.value),
            });
            setCachedFindingData &&
              setCachedFindingData({
                impact: Number(selectedOption.value),
              });
          }}
          queryStatus={changedField === "impact" ? queryStatus : undefined}
          disabled={queryStatus !== "idle"}
        />
      )}
    </Section>
  );
};
