import { SimpleCheckbox } from "../../../components/elements/checkbox/SimpleCheckbox";
import { Column, Table } from "../../../components/elements/table/Table";
import {
  NotificationChannel,
  NotificationSettings,
} from "../../../types/Notifications";
import { Box } from "../../../components/elements/box/Box";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSub,
} from "../../../components/elements/typography/Typography";

type Props = {
  formValues: any;
  updateSettings: ({
    channel,
    values,
  }: {
    channel: NotificationChannel;
    values: NotificationSettings;
  }) => void;
};

export const AutomatedReportsSettingsTable = (props: Props) => {
  const { formValues, updateSettings } = props;
  const { data: me } = useApiMe();

  const handleChange = (value: boolean | number, field: string) => {
    const values = { ...formValues.email, [field]: value };
    updateSettings({ channel: "email", values });
  };

  let columns: Column[] = [
    {
      title: "",
      key: "title",
      minWidth: "100px",
    },
    { title: "", key: "", maxWidth: "360px" },
    {
      title: "Email",
      key: "email",
      maxWidth: "360px",
      cell: (row) =>
        row.email !== null && (
          <SimpleCheckbox
            isChecked={row.email}
            onChange={(state) => handleChange(!state, row.alertType)}
          />
        ),
    },
  ];

  if (me?.can_manage_customer)
    columns.push({ title: "", key: "", maxWidth: "360px" });

  const rows: any[] = [
    {
      title: "Weekly security status reports",
      alertType: "weekly",
      inApp: null,
      email: formValues.email.weekly,
      slack: null,
    },
    {
      title: "Monthly security status reports",
      alertType: "monthly",
      inApp: null,
      email: formValues.email.monthly,
      slack: null,
    },
  ];

  return (
    <Box>
      <Flex column gap="12px">
        <HeaderSub>Scheduled Reports</HeaderSub>
        <BodyRegular>
          Receive weekly and monthly updates on your security posture
        </BodyRegular>
        <Table columns={columns} rows={rows} />
      </Flex>
    </Box>
  );
};
