import { NodeDomain, NodeGroup, NodeLegend, NodeSection } from "./GraphNodes";

export type GraphNode = {
  id: string;
  x: number;
  y: number;
  groupId: string;
  risk: number;
  name: string;
  isDuplicated?: boolean;
  createdAt: string;
};

export type GraphGroupNode = {
  id: string;
  nodes: GraphNode[];
  center: { x: number; y: number };
  radius: number;
  isExpand: boolean;
  maxHeight?: number;
  startY?: number;
};

export type NodeType = "assetsGroup" | "domain" | "legend" | "section";

export type Placement = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export const GraphRiskColors = {
  0: "graphRiskNoRisk",
  1: "graphRiskLow",
  2: "graphRiskMedium",
  3: "graphRiskHigh",
  4: "graphRiskCritical",
};

export type GraphSection = {
  height: number;
  startY: number;
};

export const NodeTypes: Record<NodeType, (props: any) => JSX.Element> = {
  assetsGroup: NodeGroup,
  domain: NodeDomain,
  legend: NodeLegend,
  section: NodeSection,
};
