import { Dispatch, SetStateAction, useContext } from "react";
import { Flex } from "../../layouts/flex/Flex";
import {
  BodyMedium,
  BodyRegular,
  HeaderSubBold,
} from "../typography/Typography";
import { ThemeContext } from "styled-components";
import { Ellipse } from "../ellipse/Ellipse";
import { SeparatorHorizontal } from "../separators/SeparatorHorizontal";

type FormStage = {
  active: boolean;
  number: number;
  name: string;
};

type Props = {
  currentStage: number;
  stages: string[];
  setCurrentStage?: Dispatch<SetStateAction<number>>;
};

export const FormStages = (props: Props) => {
  const { currentStage, stages, setCurrentStage } = props;

  const theme = useContext(ThemeContext);

  const StageItem = (stage: FormStage) => {
    return (
      <Flex align="center" gap="8px">
        <HeaderSubBold
          style={{ color: stage.active ? theme.blue600 : theme.blue500 }}
        >
          {stage.number}
        </HeaderSubBold>
        {stage.active ? (
          <BodyMedium
            style={{ color: stage.active ? theme.black800 : theme.black600 }}
          >
            {stage.name}
          </BodyMedium>
        ) : (
          <BodyRegular
            onClick={() => setCurrentStage && setCurrentStage(stage.number)}
            style={{
              color: stage.active ? theme.black800 : theme.black600,
              cursor: !!setCurrentStage ? "pointer" : "default",
            }}
          >
            {stage.name}
          </BodyRegular>
        )}
      </Flex>
    );
  };

  const ThreeDots = ({ active }: { active: boolean }) => (
    <Flex align="center" gap="8px">
      <Ellipse size={2} color={active ? theme.blue600 : theme.black500} />
      <Ellipse size={2} color={active ? theme.blue600 : theme.black500} />
      <Ellipse size={2} color={active ? theme.blue600 : theme.black500} />
    </Flex>
  );

  return (
    <Flex align="center" gap="24px" className="me-5">
      {stages?.map((stage, idx) => (
        <Flex align="center" gap="24px" key={`stage-${idx + 1}`}>
          <StageItem
            active={currentStage === idx + 1}
            number={idx + 1}
            name={stage}
          />
          {idx + 1 !== stages.length ? (
            currentStage > idx + 1 ? (
              <SeparatorHorizontal
                style={{
                  width: "22px",
                  height: "1px",
                  borderColor: theme.blue500,
                }}
              />
            ) : (
              <ThreeDots active={currentStage === idx + 1} />
            )
          ) : null}
        </Flex>
      ))}
    </Flex>
  );
};
