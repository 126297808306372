import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";
import {
  BodyRegular,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { getDate } from "../../../../shared/helper";
import { Flex } from "../../../../components/layouts/flex/Flex";

type Props = {
  projectStart: string;
  projectEnd: string;
  withSeparator?: boolean;
  stretch?: boolean;
};

export const ProjectTimes = (props: Props) => {
  const {
    projectStart,
    projectEnd,
    withSeparator = false,
    stretch = false,
  } = props;

  const theme = useContext(ThemeContext);
  const TimeElement = (props: {
    title: string;
    text: string;
    iconName: string;
  }) => {
    const { text, title, iconName } = props;
    return (
      <Flex column gap="8px" w100={stretch}>
        <LabelRegular style={{ color: theme.black600 }}>{title}</LabelRegular>
        <Flex align="center" gap="8px">
          <Icon name={iconName} color={theme.primary} />
          <BodyRegular>{text}</BodyRegular>
        </Flex>
      </Flex>
    );
  };

  const duration =
    projectEnd && projectStart
      ? (new Date(projectEnd).getTime() - new Date(projectStart).getTime()) /
          (1000 * 3600 * 24) +
          " days" || "N/A"
      : "N/A";

  return (
    <Flex
      gap="24px"
      justify="between"
      style={{
        flexGrow: 1,
      }}
    >
      <TimeElement
        title="Started On"
        text={getDate(new Date(projectStart)) || "N/A"}
        iconName="projectStart"
      />
      {withSeparator && <SeparatorVertical style={{ minHeight: "50px" }} />}
      <TimeElement
        title="Finished On"
        text={projectEnd ? getDate(new Date(projectEnd)) || "N/A" : "N/A"}
        iconName="fixed"
      />
      {withSeparator && <SeparatorVertical style={{ minHeight: "50px" }} />}
      <TimeElement title="Duration" text={duration} iconName="sla" />
    </Flex>
  );
};
