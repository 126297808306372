import React from "react";
import { IconProps } from "./WaspIcon";

export function InsightsOutlineIcon(props: IconProps) {
  const size = props.size || 32;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.505 8.92915C24.4924 8.91216 24.4885 8.89164 24.4743 8.87544C24.4575 8.85624 24.4343 8.84809 24.4161 8.83081C24.2641 8.65894 24.1183 8.48361 23.954 8.31918C22.9159 7.28001 21.683 6.45577 20.3259 5.89366C18.9689 5.33154 17.5143 5.04258 16.0454 5.04331L16.0305 5.04028L16.015 5.0434C13.4577 5.04451 10.9776 5.92019 8.98656 7.52507C6.9955 9.12995 5.61319 11.3675 5.0691 13.8663C4.52501 16.3651 4.85187 18.9748 5.9954 21.2622C7.13894 23.5496 9.03038 25.3772 11.3557 26.4414C13.6811 27.5057 16.3005 27.7427 18.7791 27.1131C21.2577 26.4835 23.4465 25.0251 24.982 22.9801C26.5175 20.935 27.3075 18.4264 27.2207 15.8705C27.134 13.3147 26.1757 10.8653 24.505 8.92915H24.505ZM23.3311 22.4119L17.2022 16.283L23.7418 10.5767C25.0118 12.3092 25.6618 14.4187 25.5873 16.5655C25.5127 18.7123 24.718 20.7716 23.3311 22.4119ZM16.8306 6.71841C19.036 6.88766 21.1137 7.82003 22.7061 9.35521L16.8306 14.482V6.71841ZM9.23837 23.0348C7.4403 21.2316 6.43058 18.7891 6.43058 16.2426C6.43058 13.6962 7.4403 11.2536 9.23837 9.45044C10.847 7.85575 12.9717 6.88705 15.2306 6.71841V16.2426C15.229 16.3919 15.276 16.5376 15.3644 16.6579C15.3823 16.6964 15.4033 16.7334 15.4274 16.7684C15.4336 16.7754 15.4426 16.7776 15.4489 16.7843C15.4556 16.7915 15.458 16.8013 15.4649 16.8083L22.1998 23.5432C20.3684 25.0976 18.0203 25.9079 15.62 25.8138C13.2198 25.7196 10.9423 24.7279 9.23837 23.0348Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
