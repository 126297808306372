import { Icon } from "../../elements/icon/Icon";
import { Tooltip } from "../../elements/tooltip/Tooltip";

type Props = {
  content?: string | JSX.Element | JSX.Element[];
};

export const InfoTooltip = (props: Props) => {
  const { content } = props;
  return (
    <Tooltip content={content || ""}>
      <Icon name="info" size={24} />
    </Tooltip>
  );
};
