import React, { useContext } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import { BodySemiBold } from "../../../../components/elements/typography/Typography";

type Props = {
  scoreDelta: number;
};

export const RiskScoreTrend = ({ scoreDelta }: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <Flex align="center" gap="4px">
      {!!scoreDelta && (
        <Icon
          name={scoreDelta > 0 ? "trendDown" : "trendUp"}
          color={scoreDelta > 0 ? theme.redPrimary : theme.greenPrimary}
          size={24}
        />
      )}

      <BodySemiBold color={theme.black800} className="me-1">
        {scoreDelta > 0 ? "+" : ""}
        {scoreDelta.toString().substring(0, 3)}%
      </BodySemiBold>
    </Flex>
  );
};
