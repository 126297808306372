import { useContext, useRef } from "react";
import {
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { Project } from "../../../types/Project";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { ProjectProgress } from "../../dashboard/bottomBlock/projectList/ProjectProgress";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { useApiFindingsPaging } from "../../../hooks/queries/findingContext";
import { Loading } from "../../../components/elements/loading/Loading";
import { FindingShortCard } from "../../findings/findingsCards/FindingShortCard";
import { FindingShortCardSkeleton } from "../../findings/findingsCards/FindingShortCardSkeleton";
import { useApiFindingsCommentsDeltaData } from "../../../hooks/queries/findingsCommentsContext";

const FindingCountElement = (props: { count: number; title: string }) => {
  const { count, title } = props;
  return (
    <Flex justify="center" align="center" gap="8px">
      <HeaderSubBold>{count}</HeaderSubBold>
      <LabelRegular>{title}</LabelRegular>
    </Flex>
  );
};

const FindingSeverityElement = (props: { count: number; title: string }) => {
  const { count, title } = props;
  const theme = useContext(ThemeContext);
  return (
    <Flex
      justify="center"
      align="center"
      gap="8px"
      className="col mx-2"
      style={{
        height: "56px",
        backgroundColor: theme.blue50,
        borderRadius: "8px",
      }}
    >
      <HeaderSubBold style={{ color: theme.blue }}>{count}</HeaderSubBold>
      <LabelRegular>{title}</LabelRegular>
    </Flex>
  );
};

type Props = {
  projectOnDisplay: Project | undefined;
};

export const ProjectsFindings = (props: Props) => {
  const { projectOnDisplay } = props;

  const { data: unreadComments } = useApiFindingsCommentsDeltaData();

  const {
    data: findings,
    isFetchingNextPage: isFetchingFindings,
    hasNextPage: hasFindingsNextPage,
    fetchNextPage: fetchFindingsNextPage,
  } = useApiFindingsPaging(
    { project: projectOnDisplay?.id, page_size: 25 },
    !!projectOnDisplay
  );

  const pagedFindings =
    findings?.pages?.map((page) => page?.results || []).flat() || [];
  const observerElemForFetchFindings = useRef(null);

  useInfiniteScroll(
    observerElemForFetchFindings,
    !!hasFindingsNextPage,
    fetchFindingsNextPage
  );

  return (
    <Flex column gap="16px">
      <Flex justify="end" align="center" gap="16px">
        <FindingCountElement
          count={projectOnDisplay?.findings_counts?.total || 0}
          title="Findings"
        />
        <SeparatorVertical style={{ height: "22px" }} />
        <FindingCountElement
          count={projectOnDisplay?.findings_counts?.open || 0}
          title="Open"
        />
        <SeparatorVertical style={{ height: "22px" }} />
        <FindingCountElement
          count={
            (projectOnDisplay?.findings_counts?.total || 0) -
              (projectOnDisplay?.findings_counts?.open || 0) || 0
          }
          title="Solved"
        />
        <SeparatorVertical style={{ height: "22px" }} />

        <ProjectProgress
          totalFindings={projectOnDisplay?.findings_counts?.total}
          openFindings={projectOnDisplay?.findings_counts?.open}
        />
      </Flex>

      <Flex justify="between">
        <FindingSeverityElement
          count={projectOnDisplay?.findings_counts?.criticals || 0}
          title="Critical"
        />
        <FindingSeverityElement
          count={projectOnDisplay?.findings_counts?.highs || 0}
          title="High"
        />
        <FindingSeverityElement
          count={projectOnDisplay?.findings_counts?.mediums || 0}
          title="Medium"
        />
        <FindingSeverityElement
          count={projectOnDisplay?.findings_counts?.lows || 0}
          title="Low"
        />
        <FindingSeverityElement
          count={projectOnDisplay?.findings_counts?.infos || 0}
          title="Informative"
        />
      </Flex>

      <Flex
        column
        gap="16px"
        className="pe-1"
        style={{
          maxHeight: "calc(100vh - 320px)",
          overflowY: "scroll",
        }}
      >
        {isFetchingFindings && <Loading />}
        {pagedFindings &&
          pagedFindings.map((finding) => (
            <FindingShortCard
              key={`finding-pane-${finding.id}`}
              finding={finding}
              hasUnreadComments={
                !!unreadComments?.some((c) => c.finding_id === finding.id)
              }
            />
          ))}
        {!pagedFindings.length
          ? Array.from({
              length: projectOnDisplay?.findings_counts?.total || 1,
            }).map((i, idx) => <FindingShortCardSkeleton key={idx} />)
          : null}
        <Flex ref={observerElemForFetchFindings} />
      </Flex>
    </Flex>
  );
};
