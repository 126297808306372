import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { TabButton } from "../../../components/elements/button/tab/TabButton";
import { Finding } from "../../../types/Finding";
import { Project } from "../../../types/Project";
import { FindingsTable } from "../../findings/findingsTable/FindingsTable";
import { ProductsTable } from "./productsTable/ProductsTable";
import { ProjectList } from "./projectList/ProjectList";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Mixpanel } from "../../../shared/mixpanel";
import { FindingsFiltersPane } from "../../findings/filters/FindingsFiltersPane";
import { Tab } from "../Dashboard";

type Props = {
  onSelectedFinding: (finding: Finding) => void;
  onOpenProjectPane: (project: Project) => void;
  selectedTab: Tab;
  setSelectedTab: Dispatch<SetStateAction<Tab>>;
  selectedProduct: number | "all";
  bannersHeight?: number;
};

export const BottomBlockContainer = (props: Props) => {
  const {
    selectedProduct,
    selectedTab,
    setSelectedTab,
    onSelectedFinding,
    onOpenProjectPane,
    bannersHeight,
  } = props;

  // const [selectedTab, setSelectedTab] = useState<Tab>("products");
  const [showFiltersPane, setShowFiltersPane] = useState<boolean>(false);
  const defaultFilter = {
    ordering: "-overall_risk",
    is_open: true,
  };
  const [filters, setFilters] = useState<{ [key: string]: any }>(defaultFilter);

  useEffect(() => {
    setFilters(
      selectedProduct && selectedProduct !== "all"
        ? {
            ...defaultFilter,
            products: [selectedProduct],
          }
        : defaultFilter
    ); // eslint-disable-next-line
  }, [selectedProduct]);

  const setTab = (tab: Tab) => {
    setSelectedTab(tab);
    Mixpanel.track(`Dashboard - ${tab} tab`);
  };

  return (
    <Box
      className="d-flex flex-column w-100"
      style={{
        minHeight: "300px",
        position: "relative",
        height: `calc(100vh - 825px - ${bannersHeight || 0}px)`,
      }}
      data-tut="dashboard-bottom-blocks"
    >
      <Flex gap="32px">
        <TabButton
          label="Products"
          iconName="products"
          selected={selectedTab === "products"}
          onClick={() => {
            setTab("products");
          }}
        />

        <TabButton
          label="Open Findings"
          iconName="activeIssues"
          selected={selectedTab === "activeIssues"}
          onClick={() => setTab("activeIssues")}
        />
        <TabButton
          label="Projects"
          iconName="projects"
          selected={selectedTab === "projects"}
          onClick={() => setTab("projects")}
        />
      </Flex>
      {selectedTab === "products" && (
        <div style={{ overflowY: "auto" }}>
          <ProductsTable size="small" selectedProduct={selectedProduct} />
        </div>
      )}
      {selectedTab === "activeIssues" && (
        <div style={{ overflowY: "auto" }}>
          <FindingsTable
            setIsFiltersPaneOpen={setShowFiltersPane}
            filters={filters}
            setFilters={setFilters}
            showFiltersButton
            onSelectedFinding={onSelectedFinding}
            disabledColumns={[
              "source",
              "comments_count",
              "assigned_to",
              "scanner_name",
            ]}
            size={"small"}
          />
        </div>
      )}
      {selectedTab === "projects" && (
        <ProjectList
          onOpenProjectPane={onOpenProjectPane}
          selectedProduct={selectedProduct}
        />
      )}
      {showFiltersPane && (
        <FindingsFiltersPane
          onClose={() => setShowFiltersPane(false)}
          filters={filters}
          setFilters={setFilters}
          activeOnly={true}
        />
      )}
    </Box>
  );
};
