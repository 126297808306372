import React, { useContext } from "react";
import {
  LineChart,
  LineChartDataset,
} from "../../components/composed/charts/LineChart";
import { useApiRiskScoreHistory } from "../../hooks/queries/riskScoreHistoryContext";
import { ThemeContext } from "styled-components";
import { SkeletonLoading } from "../../components/elements/loading/SkeletonLoading";
import { Box } from "../../components/elements/box/Box";
import { Flex } from "../../components/layouts/flex/Flex";
import { HeaderSubBold } from "../../components/elements/typography/Typography";
import { getRiskColor } from "../../shared/findingsHelper";

const DAY_IN_SECONDS = 60 * 60 * 24;

export const AverageFindingAgeChart = () => {
  const theme = useContext(ThemeContext);
  const { data: riskHistoryItems, isFetching } = useApiRiskScoreHistory({
    page_size: 2000,
    ordering: "-created_at",
  });

  const filteredRiskHistoryItems = (riskHistoryItems || [])
    .filter((item) => item.open_findings_age_metrics !== null)
    .reverse();

  const datasets: LineChartDataset[] = [
    {
      label: "Total Average Finding Age",
      data: filteredRiskHistoryItems.map(
        (item) =>
          ((item.open_findings_age_metrics
            ?.open_finding_age_per_risk_critical || 0) +
            (item.open_findings_age_metrics
              ?.open_finding_age_per_risk_critical || 0) +
            (item.open_findings_age_metrics?.open_finding_age_per_risk_medium ||
              0) +
            (item.open_findings_age_metrics?.open_finding_age_per_risk_low ||
              0) +
            (item.open_findings_age_metrics?.open_finding_age_per_risk_info ||
              0)) /
          5 /
          DAY_IN_SECONDS
      ),
      borderColor: theme[getRiskColor(4)],
    },
    // {
    //   label: "Critical Issues",
    //   data:
    //     riskHistoryItems?.map(
    //       (item) =>
    //         (item.mttr_metrics?.mttr_per_risk_critical || 0) / DAY_IN_SECONDS
    //     ) || [],
    //   borderColor: theme[getRiskColor(4)],
    // },
    // {
    //   label: "High Issues",
    //   data:
    //     riskHistoryItems?.map(
    //       (item) =>
    //         (item.mttr_metrics?.mttr_per_risk_high || 0) / DAY_IN_SECONDS
    //     ) || [],
    //   borderColor: theme[getRiskColor(3)],
    // },
    // {
    //   label: "Medium Issues",
    //   data:
    //     riskHistoryItems?.map(
    //       (item) =>
    //         (item.mttr_metrics?.mttr_per_risk_medium || 0) / DAY_IN_SECONDS
    //     ) || [],
    //   borderColor: theme[getRiskColor(2)],
    // },
    // {
    //   label: "Low Issues",
    //   data:
    //     riskHistoryItems?.map(
    //       (item) => (item.mttr_metrics?.mttr_per_risk_low || 0) / DAY_IN_SECONDS
    //     ) || [],
    //   borderColor: theme[getRiskColor(1)],
    // },
  ];

  return (
    <Box>
      <Flex column gap="24px">
        <HeaderSubBold>Average Open Findings Age over time</HeaderSubBold>
        {isFetching ? (
          <SkeletonLoading width="100%" height="223px" />
        ) : (
          <LineChart
            datasets={datasets}
            labels={filteredRiskHistoryItems.map((item) =>
              new Date(item.created_at).toLocaleDateString()
            )}
            height={223}
            dataTestId="over-time-risk-score-chart"
            tooltipLabelCallback={(context) =>
              `Average Open Finding Age: ${Math.floor(context.parsed.y)} days`
            }
          />
        )}
      </Flex>
    </Box>
  );
};
