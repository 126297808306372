import React, { useContext } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { HoverBox } from "../../../components/elements/box/Box";
import { LabelMedium } from "../../../components/elements/typography/Typography";
import { DataIcon } from "../../../assets/icons/dataIcon";
import { ThemeContext } from "styled-components";

type Props = {
  suggestion: string;
  sendUserMessage: (text: string) => void;
};
export const ChatSuggestion = (props: Props) => {
  const { suggestion, sendUserMessage } = props;
  const theme = useContext(ThemeContext);

  return (
    <Flex>
      <HoverBox
        onClick={() => {
          sendUserMessage(suggestion);
        }}
        style={{ cursor: "pointer" }}
      >
        <Flex w100 gap="8px" align="center">
          <DataIcon size={"32px"} color={theme.primary} />
          <LabelMedium>{suggestion}</LabelMedium>
        </Flex>
      </HoverBox>
    </Flex>
  );
};
