import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { DEFAULT_STALE_TIME, invalidateApiQueries } from "./utils";
import { bitAfterNow, getQueryParams } from "../../shared/helper";
import { createItem, getPage } from "./sdk";
import {
  CustomerLog,
  CustomerLogAction,
  CustomerLogsPage,
} from "../../types/CustomerLog";

const key = "customers-logs";

export interface CustomerLogContext {
  scanner?: string;
  action: CustomerLogAction;
  onSuccessCallback?: (scanRunParams: CustomerLog) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiCustomerLogPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useInfiniteQuery<CustomerLogsPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<CustomerLogsPage> =>
      getPage(key, { ...filters, page: pageParam }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiCreateCustomerLog = () => {
  const queryClient = useQueryClient();
  return useMutation<CustomerLog, Error, CustomerLogContext>({
    mutationKey: [key],
    mutationFn: async (logInputData): Promise<CustomerLog> =>
      await createItem(key, {
        scanner: logInputData.scanner,
        action: logInputData.action,
      }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),

    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};
