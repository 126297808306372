import { SingleValue } from "react-select";
import { Dropdown, Option } from "../../elements/dropdowns/Dropdown";
import { useEffect, useMemo, useState } from "react";
import { Account } from "../../../types/Account";
import { useApiAccountsPaging } from "../../../hooks/queries/accountsContext";

type Props = {
  onSelect: (user: Account | undefined) => void;
  selected?: Account[];
  placeholder?: string;
  valueByEmail?: string;
  size?: "medium" | "large" | "small";
  isClearable?: boolean;
};

export const AccountsDropdown = (props: Props) => {
  const {
    onSelect,
    selected,
    placeholder = "",
    valueByEmail,
    size,
    isClearable = false,
  } = props;

  const [searchWord, setSearchWord] = useState("");

  const { data: accountsPages } = useApiAccountsPaging({ search: searchWord });

  const accounts = useMemo(
    (): Account[] =>
      accountsPages?.pages.map((page) => page.results || []).flat() || [],
    [accountsPages]
  );

  const [options, setOptions] = useState<Option[]>(
    accounts?.map((a) => ({
      label: `${a.user.first_name} ${a.user.last_name}`,
      value: `${a.email}`,
    }))
  );

  useEffect(() => {
    // Remove selected accounts from options
    setOptions(
      accounts
        .filter((a) => !selected?.map((a) => a.id)?.includes(a.id))
        .map((a) => ({
          label: a.user.name || a.email,
          value: a.email,
        }))
    );
  }, [selected, accounts]);

  const onChange = (opt: SingleValue<Option>) => {
    setSelectedAccountOption(opt);
    const account = accounts?.find((u) => u.email === opt?.value);
    onSelect(account);
  };

  const getOptionByEmail = (email: string) =>
    options?.filter((opt) => opt.value === email)[0];

  const [selectedAccountOption, setSelectedAccountOption] = useState<
    SingleValue<Option>
  >(valueByEmail ? getOptionByEmail(valueByEmail) : null);

  return (
    <Dropdown
      placeholder={placeholder || "Select User"}
      size={size}
      variant="border"
      value={selectedAccountOption}
      options={options || []}
      onChange={onChange}
      onInputChange={(newValue) => setSearchWord(newValue)}
      searchable
      clearable={isClearable}
    />
  );
};
