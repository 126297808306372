import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
  updateContext,
} from "./utils";
import { createItem, deleteItem, getItems, getPage, updateItem } from "./sdk";
import {
  CommentDelta,
  FindingComment,
  FindingCommentsPage,
} from "../../types/FindingComment";
import { bitAfterNow, getQueryParams } from "../../shared/helper";

const key = "findings-comments";
const deltaKey = "findings-comments/delta/";

// All possible attributes that can pass to API call
interface FindingCommentParams {}

export interface FindingCommentContext {
  findingCommentId: number;
  findingCommentData: FindingCommentParams;
}

interface UpdateFindingCommentContext {
  commentData: FindingCommentContext;
  onSuccessCallback?: (data: FindingComment) => void;
  onErrorCallback?: (error: Error) => void;
}

interface DeleteFindingCommentContext {
  commentId: number;
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiFindingCommentsPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useInfiniteQuery<FindingCommentsPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<FindingCommentsPage> =>
      getPage(key, { ...filters, page: pageParam }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiFindingsCommentsDeltaData = () => {
  return useQuery<CommentDelta[], Error>({
    queryKey: [deltaKey],
    keepPreviousData: true,
    placeholderData: [],
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<CommentDelta[]> => getItems(deltaKey),
  });
};

export const useApiCreateFindingComment = () => {
  const queryClient = useQueryClient();
  return useMutation<FindingComment, Error, FindingCommentParams>({
    mutationKey: [key],
    mutationFn: async (newFindingComment): Promise<FindingComment> =>
      await createItem(key, newFindingComment),
    onError: (error) => console.log(`rolling back optimistic update. ${error}`),
    onSuccess: (data) =>
      updateContext({ data, queryKey: [key, { id: data?.id }], queryClient }),
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries(["findings-logs"], queryClient);
    },
  });
};

export const useApiUpdateFindingComment = () => {
  // api/v1/findings-comments/:id/
  const queryClient = useQueryClient();
  return useMutation<FindingComment, Error, UpdateFindingCommentContext>({
    mutationKey: [key, "update"],
    mutationFn: async ({ commentData }): Promise<FindingComment> =>
      await updateItem(
        key,
        commentData.findingCommentId,
        commentData.findingCommentData
      ),
    onSuccess: (data: FindingComment, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiDeleteFindingComment = () => {
  const queryClient = useQueryClient();
  return useMutation<undefined, Error, DeleteFindingCommentContext>({
    mutationKey: [key],
    mutationFn: async ({
      commentId,
    }: DeleteFindingCommentContext): Promise<any> =>
      await deleteItem(key, {}, commentId),
    // onSuccess: (data, variables) =>
    onSuccess: (data: undefined, { commentId, onSuccessCallback }) => {
      removeItemFromContext({
        data,
        queryKey: [key, { id: commentId }],
        queryClient,
      });
      onSuccessCallback && onSuccessCallback();
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};
