import { Dispatch, SetStateAction, useContext } from "react";
import { Column, Table } from "../../../components/elements/table/Table";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { useApiTransactions } from "../../../hooks/queries/transactionsContext";
import { getDateTime } from "../../../shared/helper";
import { ThemeContext } from "styled-components";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useApiCreditPacks } from "../../../hooks/queries/creditPacksContext";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";

interface Row {
  activity: string;
  amount: number;
  products: string[];
  created_at: string;
  balance: number;
  description: string;
  type: "credit_pack" | "transaction";
}

type Props = {
  setEditTransaction: Dispatch<SetStateAction<number>>;
  setEditCreditPack: Dispatch<SetStateAction<number>>;
};

export const TransactionsTable = (props: Props) => {
  const { setEditTransaction, setEditCreditPack } = props;
  const theme = useContext(ThemeContext);
  const isSuperuser = useIsSuperuser();

  const { data: transactions } = useApiTransactions();
  const { data: creditsPacks } = useApiCreditPacks();
  const { data: products } = useApiProducts();

  const transactionRows: Row[] =
    transactions?.map((tr) => ({
      type: "transaction",
      id: `${tr.id}`,
      activity: tr.activity,
      amount: -tr.credits_amount,
      balance: tr.customer_balance,
      created_at: tr.created_at,
      description: tr.description,
      products:
        products
          ?.filter((p) => tr.products.includes(p.id))
          .map((p) => p.name) || [],
    })) || [];

  const creditsPacksRows: Row[] =
    creditsPacks?.map((cp) => ({
      type: "credit_pack",
      products: [],
      description:
        cp.status === "expired" || cp.current_pack_balance === 0
          ? "Package has expired"
          : `package will expire on ${getDateTime(cp.expires_at)}`,
      activity: "credit package purchased",
      amount: cp.purchased_amount,
      balance: cp.customer_balance,
      created_at: cp.created_at,
    })) || [];

  const rows = [...transactionRows, ...creditsPacksRows].sort((a, b) =>
    a.created_at < b.created_at ? 1 : -1
  );

  let columns: Column[] = [
    {
      title: "Date",
      key: "created_at",
      sortable: true,
      maxWidth: "100px",
      cell: (row: Row) => (
        <BodyRegular>{getDateTime(row.created_at)}</BodyRegular>
      ),
    },
    {
      title: "Amount",
      key: "amount",
      sortable: true,
      maxWidth: "100px",
      cell: (row: Row) => (
        <BodyRegular
          color={row.amount > 0 ? theme.greenPrimary : theme.redPrimary}
        >
          {row.amount > 0 ? "+" : ""}
          {row.amount}
        </BodyRegular>
      ),
    },
    {
      title: "Balance",
      key: "balance",
      sortable: true,
      maxWidth: "100px",
      cell: (row: Row) => <BodyRegular>{row.balance}</BodyRegular>,
    },
    {
      title: "Activity",
      key: "activity",
      sortable: true,
      maxWidth: "200px",
      cell: (row: Row) => <BodyRegular>{row.activity}</BodyRegular>,
    },
    {
      title: "Products",
      key: "productName",
      sortable: true,
      maxWidth: "160px",
      cell: (row: Row) => <BadgesLine badges={row.products} noWrap />,
    },
    {
      title: "Notes",
      key: "notes",
      sortable: true,
      maxWidth: "300px",
      cell: (row: Row) => (
        <BodyRegular className="text-truncate" title={row.description}>
          {row.description}
        </BodyRegular>
      ),
    },
  ];

  if (isSuperuser)
    columns = [
      ...columns,
      {
        title: "",
        key: "",
        maxWidth: "50px",
        cell: (row) => (
          <IconButton
            iconName="chevronRight"
            size="small"
            dataTestId={`open-finding-${row.id}`}
            onClick={() =>
              row.type === "transaction"
                ? setEditTransaction(parseInt(`${row.id}`))
                : setEditCreditPack(parseInt(`${row.id}`))
            }
          />
        ),
      },
    ];

  return <Table rows={rows || []} columns={columns || []} />;
};
