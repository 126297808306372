import { useContext, useState } from "react";
import { PrimaryBadge } from "../../../components/elements/badge/PrimaryBadge";
import { Box } from "../../../components/elements/box/Box";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiFindings } from "../../../hooks/queries/findingContext";
import { Finding } from "../../../types/Finding";
import { ThemeContext } from "styled-components";
import { FindingPane } from "../findingPane/FindingPane";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { MergeConfirmModal } from "./MergeConfirmModal";

export const RelatedFindings = ({ finding }: { finding?: Finding }) => {
  const theme = useContext(ThemeContext);

  const [findingOnPane, setFindingOnPane] = useState<Finding | undefined>();
  const [findingToMerge, setFindingToMerge] = useState<Finding | undefined>();
  const [showMergeMenu, setShowMergeMenu] = useState<boolean>(false);

  const { data: findings } = useApiFindings(
    {
      title: finding?.title,
      status: [
        "presented",
        "in_progress",
        "re_test",
        "fixed",
        "acceptable_risk",
      ],
    },
    !!finding?.title // let query wait for finding
  );

  const relatedFindings = findings?.filter((f) => f.id !== finding?.id);

  return (
    <>
      {!!relatedFindings?.length && (
        <Box>
          <Flex column gap="8px">
            <Flex gap="8px" align="center" style={{ position: "relative" }}>
              <HeaderSubBold style={{ color: theme.black700 }}>
                Related Findings
              </HeaderSubBold>
              <SeparatorVertical height="24px" style={{ marginLeft: "24px" }} />
              <LinkButton
                iconName="merge"
                label="Merge"
                onClick={() =>
                  relatedFindings.length > 1
                    ? setShowMergeMenu(true)
                    : setFindingToMerge(relatedFindings[0])
                }
              />
              {showMergeMenu && (
                <>
                  <SeparatorVertical
                    height="24px"
                    style={{ marginRight: "16px" }}
                  />
                  <BodyRegular>Select Finding to Merge</BodyRegular>
                </>
              )}
            </Flex>

            <Flex flexWrap gap="8px">
              {relatedFindings?.map((f) => (
                <span
                  onClick={() =>
                    showMergeMenu ? setFindingToMerge(f) : setFindingOnPane(f)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <PrimaryBadge content={`${f.id} - ${f.title}`} key={f.id} />
                </span>
              ))}
            </Flex>
          </Flex>
        </Box>
      )}

      {findingOnPane && (
        <FindingPane
          findingId={findingOnPane.id}
          onClose={() => {
            setFindingOnPane(undefined);
            setShowMergeMenu(false);
          }}
        />
      )}

      {findingToMerge && finding && (
        <MergeConfirmModal
          onClose={() => setFindingToMerge(undefined)}
          findingToMerge={findingToMerge}
          findingToMergeInto={finding}
        />
      )}
    </>
  );
};
