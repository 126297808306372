import React from "react";
import styled from "styled-components";
import { TypographyLabelRegular } from "../typography/Typography";

type Props = {
  title: string;
  children: JSX.Element[] | JSX.Element;
};

const Title = styled.div`
  ${TypographyLabelRegular}
  color: ${(props) => props.theme.textSub};
  padding: 8px 0;
  text-transform: capitalize;
`;

export const MenuCategory = (props: Props) => {
  const { title, children } = props;

  return (
    <div>
      <Title>{title}</Title>
      {children}
    </div>
  );
};
