import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyMedium,
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { ProjectFormProps } from "./ProjectScheduler";
import { useEffect, useMemo, useState } from "react";
import { Asset } from "../../../types/Asset";
import { AssetsConfigForm } from "./ConfigForm";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { AssetsConfTable } from "./AssetsConfTable";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  useApiAssetsCount,
  useApiAssetsPaging,
} from "../../../hooks/queries/assetsContext";
import { CheckboxState } from "../../../components/elements/checkbox/Checkbox";
import { Mixpanel } from "../../../shared/mixpanel";
import { TextButton } from "../../../components/elements/button/text/TextButton";

interface Props extends ProjectFormProps {
  openAddAssetsModal: () => void;
}

export const ProjectConfigurations = (props: Props) => {
  Mixpanel.track("Schedule a Test - Project Configurations");
  const { openAddAssetsModal, mainFormValues, setMainFormValues } = props;
  const [configuredAssets, setConfiguredAssets] = useState<Asset[]>([]);
  const [searchWord, setSearchWord] = useState<string>("");
  const [configureAllAssets, setConfigureAllAssets] = useState<boolean>(false);

  const queryParams = mainFormValues.include_all_assets
    ? {}
    : { id: mainFormValues.included_assets };

  const { data: assetsCount } = useApiAssetsCount({});

  const {
    data: includedAssetsPages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useApiAssetsPaging(queryParams, true);

  const includedAssets: Asset[] = useMemo(
    () =>
      includedAssetsPages?.pages.map((page) => page.results || []).flat() || [],
    [includedAssetsPages]
  );

  const filteredIncludedAssets = !!searchWord
    ? includedAssets?.filter((asset) => asset.name.includes(searchWord))
    : includedAssets;

  const totalIncluded = mainFormValues.include_all_assets
    ? assetsCount?.total
    : mainFormValues?.included_assets?.length || 0;

  const currentlyConfiguredCount = configureAllAssets
    ? totalIncluded
    : configuredAssets?.length || 0;

  useEffect(() => {
    if (configureAllAssets) return;
    // Jump to next un-configured asset on list
    const nextAsset = filteredIncludedAssets?.find(
      (a) => !Object.keys(mainFormValues.assets_configs).includes(`${a.id}`)
    );
    nextAsset && setConfiguredAssets([nextAsset]);
  }, [
    mainFormValues.assets_configs,
    filteredIncludedAssets,
    configureAllAssets,
  ]);

  const onSelectAll = (rowsId: number[], state: CheckboxState) => {
    if (state !== "checked") setConfigureAllAssets(false);
  };
  // const onSelectAll = (rowsId: number[], state: CheckboxState) => {
  //   setIncludedAssetsIds(state === "checked" ? rowsId : []);
  //   if (state !== "checked") setIsSelectAll(false);
  // };
  return (
    <>
      <Flex w100 style={{ height: "calc(92vh - 230px)", overflowY: "hidden" }}>
        <Flex
          column
          gap="24px"
          style={{ width: "1000px", overflowY: "hidden" }}
        >
          <HeaderSecondary>Configure your assets</HeaderSecondary>

          <Flex column>
            <Flex align="center" justify="between">
              <Flex gap="8px">
                <BodyMedium>Total Configured</BodyMedium>
                <BodyRegular>
                  {currentlyConfiguredCount}/{totalIncluded}
                </BodyRegular>
              </Flex>
              <Flex gap="8px">
                <InputText
                  value={searchWord}
                  onChange={(e) => setSearchWord(e.target.value)}
                  dataTestId="assets-search-bar"
                  placeholder="Search"
                  iconName="search"
                />
                <Tooltip content="Add / Remove Assets">
                  <IconButton iconName="edit" onClick={openAddAssetsModal} />
                </Tooltip>
              </Flex>
            </Flex>
            {configureAllAssets ? (
              <TextButton
                label="Clear Selection"
                fontSize="14px"
                onClick={() => {
                  setConfigureAllAssets(false);
                  setConfiguredAssets([]);
                }}
              />
            ) : (
              <TextButton
                fontSize="14px"
                onClick={() => setConfigureAllAssets(true)}
                label={`Select All ${assetsCount?.total} Assets`}
              />
            )}
          </Flex>
          <Flex
            column
            gap="12px"
            style={{ overflowY: "auto", paddingRight: "12px" }}
          >
            <AssetsConfTable
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              assets={filteredIncludedAssets || []}
              mainFormValues={mainFormValues}
              configuredAssets={configuredAssets}
              setConfiguredAssets={setConfiguredAssets}
              onSelectAll={onSelectAll}
              isSelectAll={configureAllAssets}
            />
          </Flex>
        </Flex>

        <SeparatorVertical height="65vh" style={{ marginInline: "32px" }} />

        <AssetsConfigForm
          currentlyConfiguredCount={currentlyConfiguredCount}
          configuredAssets={configuredAssets}
          mainFormValues={mainFormValues}
          setMainFormValues={setMainFormValues}
          configureAllAssets={configureAllAssets}
        />
      </Flex>
    </>
  );
};
