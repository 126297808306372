import { useContext } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Icon } from "../../../components/elements/icon/Icon";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Asset, AssetDomainProperties } from "../../../types/Asset";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Badge } from "../../../components/elements/badge/Badge";
import { BoolBadge } from "../../../components/elements/badge/BoolBadge";

type Props = {
  asset: Asset | undefined;
};

export const AssetCertificate = (props: Props) => {
  const { asset } = props;
  const theme = useContext(ThemeContext);

  const assetProperties = (asset?.properties as AssetDomainProperties) || {};

  if (asset?.type !== "domain") {
    return null;
  }

  return (
    <>
      <Flex align="center">
        <Icon name="certificate" size={32} color={theme.primary} />
        <HeaderSecondary>Certificate Data</HeaderSecondary>
      </Flex>
      <Box>
        <Flex w100>
          <Flex column w100 gap="24px">
            <Flex w100 gap="16px">
              <div style={{ width: "120px" }}>Mismatched:</div>
              <div>
                <BoolBadge state={assetProperties?.certificate?.mismatched} />
              </div>
            </Flex>
            <Flex w100 gap="16px">
              <div style={{ width: "120px" }}>Expired:</div>
              <div>
                <BoolBadge state={assetProperties?.certificate?.expired} />
              </div>
            </Flex>
            <Flex w100 gap="16px">
              <div style={{ width: "120px" }}>Self Signed:</div>
              <div>
                <BoolBadge state={assetProperties?.certificate?.self_signed} />
              </div>
            </Flex>
            <Flex w100 gap="16px">
              <div style={{ width: "120px" }}>Deprecated Tls:</div>
              <div>
                <BoolBadge
                  state={assetProperties?.certificate?.deprecated_tls}
                />
              </div>
            </Flex>
            <Flex w100 gap="16px">
              <div style={{ width: "120px" }}>Subject DN:</div>
              <div>{assetProperties?.certificate?.subject_dn}</div>
            </Flex>
          </Flex>
          <SeparatorVertical style={{ height: "204px", marginRight: "24px" }} />
          <Flex column w100 gap="24px">
            <Flex w100 gap="16px">
              <div style={{ width: "120px" }}>Subject CN:</div>
              <div>{assetProperties?.certificate?.subject_cn}</div>
            </Flex>
            <Flex gap="8px">
              <div style={{ width: "120px" }}>Subject AN:</div>
              <Flex flexWrap gap="8px">
                {assetProperties?.certificate?.subject_an?.map((an: string) => (
                  <Badge
                    style={{
                      backgroundColor: theme.blue100,
                      color: theme.blue700,
                    }}
                  >
                    {an}
                  </Badge>
                ))}
              </Flex>
            </Flex>
            <Flex>
              <div style={{ width: "120px" }}>Issuer CN:</div>
              <div>{assetProperties?.certificate?.issuer_cn}</div>
            </Flex>
            <Flex>
              <div style={{ width: "120px" }}>Issuer DN:</div>
              <div>{assetProperties?.certificate?.issuer_dn}</div>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
