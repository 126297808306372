import React from "react";
import { IconProps } from "./WaspIcon";

export function TrendDownIcon(props: IconProps) {
  const size = props.size || 16;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      transform="scale(1,-1)"
    >
      <g clipPath="url(#clip0_229_1452)">
        <path
          d="M18.365 8.56298C18.5894 8.76192 18.7618 9.00217 18.87 9.26674C18.9782 9.53132 19.0197 9.81376 18.9914 10.0941L18.7603 12.3859C18.741 12.5777 18.6416 12.7562 18.4816 12.8864C18.3215 13.0165 18.1125 13.0888 17.8955 13.0892C17.8683 13.0892 17.8433 13.0892 17.8156 13.0862C17.5859 13.0678 17.3739 12.9693 17.2261 12.8122C17.0783 12.6552 17.0068 12.4525 17.0273 12.2486L17.1839 10.7038L14.6647 12.9399C14.175 13.3736 13.5114 13.6172 12.8195 13.6172C12.1276 13.6172 11.464 13.3736 10.9744 12.9399L10.3781 12.4123C10.215 12.2677 9.99384 12.1863 9.76321 12.1862C9.53259 12.1861 9.31132 12.2671 9.14793 12.4115L6.49561 14.7643C6.41536 14.8381 6.31937 14.8969 6.21324 14.9373C6.10711 14.9778 5.99296 14.9991 5.87746 15C5.76195 15.0009 5.6474 14.9813 5.5405 14.9425C5.43359 14.9037 5.33646 14.8464 5.25479 14.7739C5.17311 14.7014 5.10852 14.6152 5.06478 14.5204C5.02104 14.4255 4.99903 14.3238 5.00003 14.2213C5.00104 14.1188 5.02503 14.0175 5.07062 13.9233C5.11621 13.8292 5.18249 13.744 5.26557 13.6728L7.91702 11.3198C8.4142 10.8988 9.07498 10.664 9.76216 10.664C10.4493 10.664 11.1101 10.8988 11.6073 11.3198L12.2037 11.8475C12.3669 11.9921 12.588 12.0734 12.8185 12.0736C13.0491 12.0737 13.2704 11.9927 13.4337 11.8483L15.9525 9.6127L14.2127 9.75026C13.9844 9.76264 13.7594 9.69696 13.5841 9.5667C13.4087 9.43645 13.2962 9.25152 13.2697 9.04994C13.2595 8.9489 13.2719 8.84707 13.3061 8.75028C13.3403 8.65349 13.3957 8.56363 13.4691 8.48584C13.5425 8.40805 13.6324 8.34386 13.7337 8.29693C13.8351 8.25001 13.9459 8.22127 14.0597 8.21236L16.6405 8.00809C16.9563 7.98222 17.2746 8.01855 17.5727 8.11447C17.8708 8.21039 18.1413 8.36354 18.365 8.56298Z"
          fill={props.color || "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_229_1452">
          <rect
            width={size}
            height={size}
            fill={props.color || "currentColor"}
            transform="translate(4.99609 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
