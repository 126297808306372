import { SuggestedFilterProps } from "./SuggestedFilters";

export const suggestions: SuggestedFilterProps[] = [
  {
    idx: 0,
    title: "Never tested assets",
    subTitle: "some more text",
    filters: [
      {
        column: "pt_status",
        condition: "is",
        value: "None",
        order: 0,
        next_condition: undefined,
      },
    ],
  },
  {
    idx: 1,
    title: "Assets with expired PT",
    subTitle: "some more text",
    filters: [
      {
        column: "last_pt_date",
        condition: "before",
        value: "182",
        order: 0,
        next_condition: undefined,
      },
    ],
  },
];
