import { useRef, useState } from "react";

import { useApiFindingCommentsPaging } from "../../../hooks/queries/findingsCommentsContext";
import { FindingComment } from "../../../types/FindingComment";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { Loading } from "../../../components/elements/loading/Loading";
import { ImageModal } from "../../../components/composed/imageModal/ImageModal";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Finding } from "../../../types/Finding";
import { SortType } from "./FindingHistory";
import { CommentCard } from "./CommentCard";

type Props = {
  finding?: Finding;
  sort?: SortType;
  isAdminMode?: boolean;
};

export const FindingComments = ({
  finding,
  sort = "newest",
  isAdminMode = false,
}: Props) => {
  const {
    data: comments,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useApiFindingCommentsPaging(
    {
      finding: finding?.id,
      ordering: sort === "newest" ? "-created_at" : "created_at",
      ...(isAdminMode ? { "admin-mode": isAdminMode } : {}),
    },
    !!finding?.id
  );

  const observerElemForFetchPage = useRef(null);
  const [showImageModal, setShowImageModal] = useState(false);

  const [clickedSource, setClickedSource] = useState<string | undefined>(
    undefined
  );

  useInfiniteScroll(
    observerElemForFetchPage,
    hasNextPage || false,
    fetchNextPage
  );

  const getComments = (): FindingComment[] => {
    return comments?.pages.map((page) => page?.results || []).flat() || [];
  };

  const registerOnClickImageEvents = () => {
    const elements = document.getElementsByClassName(
      "wasp-image-big"
    ) as HTMLCollectionOf<HTMLImageElement>;
    if (elements.length > 0) {
      Array.from(elements).forEach((elm) => {
        elm.onclick = () => {
          setClickedSource(elm.src);
          setShowImageModal(true);
        };
      });
    }
  };

  setTimeout(registerOnClickImageEvents, 1000);

  return (
    <Flex
      column
      gap="24px"
      style={
        {
          // height: "calc(100vh - 420px)",
          // overflow: "scroll",
        }
      }
    >
      {showImageModal && (
        <ImageModal
          onClose={() => setShowImageModal(false)}
          image={clickedSource || ""}
        />
      )}
      {getComments().map((comment, i) => (
        <CommentCard
          key={`comment-card-${i}`}
          comment={comment}
          finding={finding}
        />
      ))}
      <Flex justify="center" className="loader" ref={observerElemForFetchPage}>
        {isFetchingNextPage ? (
          <Flex column justify="center">
            <Loading />
          </Flex>
        ) : (
          ""
        )}
      </Flex>
    </Flex>
  );
};
