import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { Icon } from "../../../components/elements/icon/Icon";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { useApiCtiItems } from "../../../hooks/queries/ctiItemsContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import ImgCyberPlaceholder from "../../../assets/images/cyber-placeholder.png";
import { CtiItem } from "../../../types/CtiItem";
import { Mixpanel } from "../../../shared/mixpanel";
import { CtiItemPost } from "../../admin/cti/CtiItemPost";
import { useApiMe } from "../../../hooks/queries/meContext";

type Props = {};

const defaultCtiItems: CtiItem[] = [
  {
    id: 1,
    customers: [],
    title: "COMING SOON",
    image: ImgCyberPlaceholder,
    link: "",
    affected_products: [],
    created_at: "",
    updated_at: "",
  },
];

export const IntelFeed = (props: Props) => {
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const { data: ctiItemsData } = useApiCtiItems({ customer: me?.customer.id });
  const [itemIdx, setItemIdx] = useState<number>(1);
  const [ctiItems, setCtiItems] = useState<CtiItem[]>(defaultCtiItems);
  useEffect(() => {
    if (!!ctiItemsData?.length) {
      setCtiItems(ctiItemsData);
    }
  }, [ctiItemsData]);
  const hasPrevPage = itemIdx > 1;
  const hasNextPage = itemIdx < parseInt(`${ctiItems?.length}`);

  return (
    <Box
      className="d-flex flex-column justify-content-start align-items-center gap-16"
      style={{ width: "100%", padding: "24px" }}
      data-tut="intel-feed"
    >
      <Flex justify="between" align="center" gap="8px" w100>
        <Flex gap="8px" align="center" style={{ width: "calc(100% - 64px)" }}>
          <Icon name="news" color={theme.primary} size={32} />
          <HeaderSecondary
            className="text-truncate"
            title="Threats Intelligence Feed"
          >
            Threat Intelligence Feed
          </HeaderSecondary>
        </Flex>
        <Flex align="center">
          <IconButton
            iconName="chevronLeft"
            color={theme.primary}
            size="small"
            onClick={() => {
              Mixpanel.track("IntelFeed Click");
              hasPrevPage && setItemIdx((prev) => prev - 1);
            }}
            disabled={!hasPrevPage}
            dataTestId="prev-item"
          />
          <IconButton
            iconName="chevronRight"
            color={theme.primary}
            size="small"
            onClick={() => {
              Mixpanel.track("IntelFeed Click");
              hasNextPage && setItemIdx((prev) => prev + 1);
            }}
            disabled={!hasNextPage}
            dataTestId="next-item"
          />
        </Flex>
      </Flex>
      <CtiItemPost ctiItem={ctiItems[itemIdx - 1]} width={100} />
    </Box>
  );
};
