import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { InputText } from "../input/textInput/InputText";
import { Flex } from "../../layouts/flex/Flex";
import "./dualSlider.css";
import { LabelRegular } from "../typography/Typography";

type Props = {
  min?: number;
  max?: number;
  setMin: (value: number) => void;
  setMax: (value: number) => void;
  minRange?: number;
  maxRange?: number;
  minDataTestId?: string;
  maxDataTestId?: string;
};

export const DualRangeSlider = (props: Props) => {
  const {
    min,
    max,
    setMin,
    setMax,
    minRange = 0,
    maxRange = 10,
    minDataTestId = "range-min",
    maxDataTestId = "range-max",
  } = props;

  const theme = useContext(ThemeContext);

  const minValue = min || minRange;
  const maxValue = max || maxRange;

  const setMinValue = (e: any) => {
    const value = parseInt(e.target.value);
    if (value <= maxValue && value >= minRange) setMin(e.target.value);
  };

  const setMaxValue = (e: any) => {
    const value = parseInt(e.target.value);
    if (value >= minValue && value <= maxRange) setMax(e.target.value);
  };

  return (
    <Flex column w100 style={{ padding: "12px" }}>
      <div
        style={{
          position: "relative",
          minHeight: "50px",
          filter:
            theme.name === "dark" ? "invert(1) hue-rotate(180deg)" : "none",
        }}
      >
        <div style={{ position: "relative", height: "7px" }}>
          <span
            className="range-selected"
            style={{
              height: "100%",
              left: `${minValue * (100 / maxRange)}%`,
              right: `${(maxRange - maxValue) * (100 / maxRange)}%`,
              position: "absolute",
              top: "-2.5px",
              backgroundColor: theme.primary,
            }}
          />
          <input
            id="fromSlider"
            type="range"
            value={minValue}
            min={minRange}
            max={maxRange}
            onChange={setMinValue}
          />
          <input
            id="toSlider"
            type="range"
            value={maxValue}
            min={minRange}
            max={maxRange}
            onChange={setMaxValue}
          />
        </div>
      </div>
      <Flex w100 gap="16px">
        <Flex column gap="8px">
          <LabelRegular>Minimum Score</LabelRegular>
          <InputText
            value={`${minValue}`}
            onChange={setMinValue}
            width="100%"
            dataTestId={minDataTestId}
          />
        </Flex>
        <Flex column gap="8px">
          <LabelRegular>Maximum Score</LabelRegular>
          <InputText
            value={`${maxValue}`}
            onChange={setMaxValue}
            width="100%"
            dataTestId={maxDataTestId}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
