import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyMedium,
  HeaderMain,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Project } from "../../../types/Project";
import { useApiMe } from "../../../hooks/queries/meContext";
import { getActivityType } from "../../../shared/helper";
import { light } from "../../../shared/theme";

type Props = {
  project?: Project;
  reportType:
    | "Full Report"
    | "Retest Report"
    | "Executive Summary Report"
    | "Attestation Letter";
};

export const FirstPage = ({ project, reportType }: Props) => {
  const { data: me } = useApiMe();

  const dateStringWithoutDay = (date: Date) => {
    return date.toDateString().replace(/^\w+ /, "");
  };

  return (
    <>
      <Flex
        column
        gap="8px"
        justify="center"
        align="center"
        w100
        className="fully-justify"
      >
        <HeaderMain color={light.textHeader} className="text-center">
          {project?.name || "Project name"}
        </HeaderMain>
        <HeaderSecondary color={light.blue800} className="text-center">
          {getActivityType(project?.type)}
        </HeaderSecondary>
        <HeaderSecondary color={light.grayBlue800} className="text-center">
          {reportType}
        </HeaderSecondary>
        {me?.customer.logo_file && (
          <img
            alt="customer-brand-logo"
            src={`/api/v1/customers/${me?.customer.id}/logo`}
            style={{
              height: "200px",
            }}
          />
        )}
      </Flex>
      <Flex
        column
        gap="8px"
        justify="start"
        align="start"
        w100
        className="mt-5"
        style={{ pageBreakAfter: "always" }}
      >
        <Flex align="center" gap="8px">
          <BodyMedium color={light.textBody}>{`Period:`}</BodyMedium>
          <BodyBold color={light.textBody}>{`${dateStringWithoutDay(
            new Date(project?.start || "")
          )} - ${dateStringWithoutDay(
            new Date(project?.end || "")
          )}`}</BodyBold>
        </Flex>
        <Flex align="center" gap="8px">
          <BodyMedium color={light.textBody}>{`Date of report:`}</BodyMedium>
          <BodyBold color={light.textBody}>
            {/* https://stackoverflow.com/questions/48384163/javascript-remove-day-name-from-date */}
            {dateStringWithoutDay(new Date())}
          </BodyBold>
        </Flex>
      </Flex>
    </>
  );
};
