import React, { useContext, useState } from "react";
import { Box } from "../../components/elements/box/Box";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  HeaderSubBold,
  LabelRegular,
} from "../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Table } from "../../components/elements/table/Table";
import { QuickScanResult } from "../../types/QuickScanResult";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";
import { IconButton } from "../../components/elements/button/icon/IconButton";

type Props = {
  scanResult: QuickScanResult;
};

export const QuickScanResultsRawCookies = ({ scanResult }: Props) => {
  const theme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  return (
    <Box
      style={{
        width: isMobile ? "99%" : "70%",
      }}
    >
      <Flex
        column
        h100
        w100
        style={{
          maxHeight: "600px",
          overflow: "auto",
          backgroundColor: theme.white100,
        }}
      >
        <Flex align="center" gap="8px">
          <IconButton
            iconName={isOpen ? "chevronDown" : "chevronRight"}
            color={theme.primary}
            onClick={() => setIsOpen(!isOpen)}
            size="small"
          />
          <HeaderSubBold>Raw Cookies</HeaderSubBold>
        </Flex>
        {isOpen && (
          <Table
            isStriped
            columns={[
              {
                key: "name",
                title: "Name",
              },
              {
                key: "value",
                title: "Value",
                cell: (row) => (
                  <LabelRegular className="text-truncate" title={row.value}>
                    {row.value}
                  </LabelRegular>
                ),
              },
            ]}
            rows={Object.entries(scanResult.cookies).map(([key, value]) => ({
              name: key,
              value,
            }))}
            size="very-small"
          />
        )}
      </Flex>
    </Box>
  );
};
