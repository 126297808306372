import { Dispatch, SetStateAction, useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  HeaderSecondary,
  HeaderSubBold,
} from "../../../../components/elements/typography/Typography";
import { SEVERITIES } from "../../../../shared/consts";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { getRiskColor } from "../../../../shared/findingsHelper";
import { Option } from "../../../../components/elements/dropdowns/Dropdown";
import { SingleValue } from "react-select";
import { SeverityDropdown } from "../../../../components/composed/severityDropdown/SeverityDropdown";
import {
  getOptionFromKeyValuePairs,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";
import { useParams } from "react-router";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  headerStyle: "secondary" | "sub";
  width?: string;
  isEditable?: boolean;
  editedFinding?: AdminFindingEdit;
  onChange?: (opt: SingleValue<Option>) => void;
  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const SeverityItem = (props: Props) => {
  const {
    finding,
    noSeparator = false,
    headerStyle,
    width,
    isEditable = false,
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const theme = useContext(ThemeContext);
  const severity_string = SEVERITIES[finding?.overall_risk || 0];

  const { id: updateFindingId } = useParams();
  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  return (
    <Section
      title="Severity"
      noSeparator={noSeparator}
      headerStyle={headerStyle === "secondary" ? "body" : "label"}
      width={width || "100%"}
    >
      {!isEditable ? (
        <>
          <div
            style={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor:
                theme[getRiskColor(finding?.overall_risk || 0) || "black600"],
            }}
          />
          {headerStyle === "secondary" ? (
            <HeaderSecondary style={{ color: theme.black700 }}>
              {severity_string}
            </HeaderSecondary>
          ) : (
            <></>
          )}
          {headerStyle === "sub" ? (
            <HeaderSubBold style={{ color: theme.black700 }}>
              {severity_string}
            </HeaderSubBold>
          ) : (
            <></>
          )}
        </>
      ) : (
        <SeverityDropdown
          variant="outline"
          size="large"
          placeholder="Select risk level"
          dataTestId="overall_risk"
          onChange={(opt) => {
            if (opt?.value) {
              setEditableUpdateFinding &&
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  overall_risk: Number(opt.value),
                }));
              updateInPlace({ overall_risk: Number(opt.value) });
              setCachedFindingData &&
                setCachedFindingData({ overall_risk: Number(opt.value) });
            }
          }}
          value={getOptionFromKeyValuePairs(
            SEVERITIES,
            editableUpdateFindingData?.overall_risk || 0
          )}
          disabled={queryStatus !== "idle"}
          queryStatus={
            changedField === "overall_risk" ? queryStatus : undefined
          }
        />
      )}
    </Section>
  );
};
