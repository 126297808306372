import { RightPane } from "../../components/elements/rightPane/RightPane";
import { Flex } from "../../components/layouts/flex/Flex";
import { HeaderSecondary } from "../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../components/elements/separators/SeparatorHorizontal";
import { useApiProjectRequests } from "../../hooks/queries/projectRequestsContext";
import { RequestListItem } from "./RequestListItem";

type Props = {
  onClose: () => void;
};

export const RequestsPane = (props: Props) => {
  const { onClose } = props;
  const { data: projectsRequests } = useApiProjectRequests();
  return (
    <RightPane onClose={onClose} width="895px">
      <Flex column gap="24px" style={{ padding: "24px" }}>
        <HeaderSecondary>Projects Requests</HeaderSecondary>
        <SeparatorHorizontal />
        {projectsRequests?.map((pr) => (
          <RequestListItem key={pr.id} projectRequest={pr} />
        ))}
      </Flex>
    </RightPane>
  );
};
