import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../../../components/elements/badge/Badge";
import { FilterContext } from "./FindingsFilterLine";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import {
  LabelBold,
  LabelMedium,
} from "../../../../components/elements/typography/Typography";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";

const FilterBadge = ({
  filterKey,
  label,
  value,
  removeFilter,
}: {
  filterKey: string;
  label?: string;
  value?: string;
  removeFilter?: (filterContext: FilterContext) => void;
}) => {
  const theme = useContext(ThemeContext);

  const filterKeyDisplay = filterKey.replace(/_/g, " ").replace("is ", "");

  const displayValue = `${label}`.replace("_", " ");

  return label ? (
    <Badge
      className="d-flex align-items-center gap-8"
      style={{
        alignItems: "center",
        background: theme.blue100,
        padding: "4px 16px",
        borderRadius: "39px",
        height: "32px",
        maxWidth: "380px",
      }}
    >
      <LabelMedium
        style={{
          textTransform: "capitalize",
          color: theme.blue700,
        }}
      >
        {`${filterKeyDisplay}: `}
      </LabelMedium>
      <Tooltip
        isTextTruncate
        content={displayValue}
        isActive={displayValue?.length > 40}
      >
        <LabelBold
          className="text-truncate text-capitalize"
          style={{ color: theme.blue800 }}
        >
          {displayValue}
        </LabelBold>
      </Tooltip>

      {!!removeFilter && (
        <IconButton
          iconName="cancel"
          size="small"
          color={theme.blue700}
          onClick={() => removeFilter({ filterKey, value })}
          dataTestId={`${label}-cancel`}
        />
      )}
    </Badge>
  ) : null;
};

export default FilterBadge;
