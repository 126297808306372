import React, { useContext } from "react";
import EmptyStateImageDark from "../../../assets/images/EmptyStateDark.png";
import AlertStateImageDark from "../../../assets/images/AlertDark.png";
import ErrorStateImageDark from "../../../assets/images/WarningDark.png";
import SuccessStateImageDark from "../../../assets/images/SuccessDark.png";
import DeleteStateImageDark from "../../../assets/images/DeleteDark.png";
import SearchStateImageDark from "../../../assets/images/SearchDark.png";
import EmptyStateImage from "../../../assets/images/EmptyStateLight.png";
import AlertStateImage from "../../../assets/images/AlertLight.png";
import ErrorStateImage from "../../../assets/images/WarningLight.png";
import SuccessStateImage from "../../../assets/images/SuccessLight.png";
import DeleteStateImage from "../../../assets/images/DeleteLight.png";
import SearchStateImage from "../../../assets/images/SearchLight.png";
import EducationStateImageDark from "../../../assets/images/EducationDark.png";
import EducationStateImage from "../../../assets/images/EducationLight.png";
import WIPIllustration from "../../../assets/images/WIPIllustration.png";
import LockIllustration from "../../../assets/images/LockIllustration.png";
import DemoIllustration from "../../../assets/images/DemoIllustrations.png";
import { ThemeContext } from "styled-components";

export type Props = {
  name:
    | "empty"
    | "alert"
    | "error"
    | "success"
    | "delete"
    | "search"
    | "education"
    | "wip"
    | "lock"
    | "demo";
  style?: React.CSSProperties;
};

export const Illustration = ({ name, style }: Props) => {
  const theme = useContext(ThemeContext);
  const getImageSource = () => {
    if (theme.name === "dark") {
      switch (name) {
        case "empty":
          return EmptyStateImageDark;
        case "alert":
          return AlertStateImageDark;
        case "error":
          return ErrorStateImageDark;
        case "success":
          return SuccessStateImageDark;
        case "delete":
          return DeleteStateImageDark;
        case "search":
          return SearchStateImageDark;
        case "education":
          return EducationStateImageDark;
        case "wip":
          return WIPIllustration;
        case "lock":
          return LockIllustration;
        case "demo":
          return DemoIllustration;
      }
    }
    switch (name) {
      case "empty":
        return EmptyStateImage;
      case "alert":
        return AlertStateImage;
      case "error":
        return ErrorStateImage;
      case "success":
        return SuccessStateImage;
      case "delete":
        return DeleteStateImage;
      case "search":
        return SearchStateImage;
      case "education":
        return EducationStateImage;
      case "wip":
        return WIPIllustration;
      case "lock":
        return LockIllustration;
      case "demo":
        return DemoIllustration;
      default:
        return EmptyStateImage;
    }
  };
  return (
    <img src={getImageSource()} alt={`illustration-${name}`} style={style} />
  );
};
