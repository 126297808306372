import { useContext } from "react";
import OpGif from "../../../assets/images/OP-Logo-3-once.gif";
import {
  HeaderMain,
  HeaderSecondary,
} from "../../elements/typography/Typography";
import { Flex } from "../../layouts/flex/Flex";
import { ThemeContext } from "styled-components";

export const WelcomeBanner = () => {
  const theme = useContext(ThemeContext);

  const gifFilter = theme.name === "dark" ? "invert(1) hue-rotate(180deg)" : "";

  return (
    <Flex
      column
      w100
      align="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <HeaderMain>WASP</HeaderMain>
      <HeaderMain style={{ textAlign: "center" }}>
        Web Application Security Platform
      </HeaderMain>
      <div style={{ margin: "40px" }} />
      <HeaderSecondary>Powered by</HeaderSecondary>
      <img
        src={OpGif}
        alt="op-innovate"
        data-testid="logo-img"
        style={{ filter: gifFilter }}
      />
    </Flex>
  );
};
