import React from "react";
import { IconProps } from "./WaspIcon";

export function ZoomOutIcon(props: IconProps) {
  const size = props.size || 32;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7384 11.1173C13.7384 11.2764 13.6752 11.429 13.5627 11.5415C13.4502 11.6541 13.2976 11.7173 13.1384 11.7173H9.096C8.93687 11.7173 8.78426 11.6541 8.67173 11.5415C8.55921 11.429 8.496 11.2764 8.496 11.1173C8.496 10.9582 8.55921 10.8055 8.67173 10.693C8.78426 10.5805 8.93687 10.5173 9.096 10.5173H13.1389C13.298 10.5174 13.4505 10.5807 13.5629 10.6932C13.6753 10.8057 13.7384 10.9582 13.7384 11.1173ZM18.617 18.6173C18.5045 18.7298 18.3519 18.793 18.1928 18.793C18.0337 18.793 17.881 18.7298 17.7685 18.6173L14.8285 15.6773C13.7851 16.5437 12.4732 17.0208 11.117 17.027C9.9482 17.027 8.80561 16.6804 7.83375 16.0311C6.8619 15.3817 6.10443 14.4587 5.65713 13.3788C5.20984 12.299 5.0928 11.1107 5.32083 9.96434C5.54886 8.81796 6.11171 7.76495 6.93821 6.93845C7.7647 6.11196 8.81772 5.54911 9.9641 5.32108C11.1105 5.09305 12.2987 5.21008 13.3786 5.65738C14.4585 6.10467 15.3815 6.86214 16.0308 7.834C16.6802 8.80585 17.0268 9.94844 17.0268 11.1173C17.0208 12.4736 16.5438 13.7857 15.6774 14.8292L18.6174 17.7692C18.7297 17.8818 18.7928 18.0343 18.7927 18.1933C18.7926 18.3523 18.7295 18.5048 18.617 18.6173ZM11.117 15.827C12.0485 15.827 12.9591 15.5508 13.7336 15.0333C14.5082 14.5158 15.1118 13.7802 15.4683 12.9196C15.8248 12.059 15.918 11.1121 15.7363 10.1985C15.5546 9.28485 15.106 8.44565 14.4473 7.78698C13.7887 7.12831 12.9495 6.67975 12.0359 6.49802C11.1223 6.31629 10.1753 6.40956 9.31469 6.76603C8.45409 7.1225 7.71853 7.72616 7.20102 8.50068C6.6835 9.2752 6.40728 10.1858 6.40728 11.1173C6.40874 12.3659 6.90541 13.563 7.78835 14.446C8.67128 15.3289 9.86838 15.8256 11.117 15.827Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
