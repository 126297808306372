import React, { Dispatch, SetStateAction } from "react";
import { RightPane } from "../../../../components/elements/rightPane/RightPane";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import {
  HeaderSecondary,
  HeaderSubBold,
} from "../../../../components/elements/typography/Typography";
import { useApiAlertBannerReceivers } from "../../../../hooks/queries/alertBannerReceiverContext";
import { AlertBannerMessage } from "../../../../types/AlertBannerMessage";
import { DismissedUser } from "./DismissedUser";

type Props = {
  setShowDismissedUsers: Dispatch<SetStateAction<boolean>>;
  selectedAlert: AlertBannerMessage | undefined;
};

export const DismissedPane = (props: Props) => {
  const { setShowDismissedUsers, selectedAlert } = props;
  const { data: alertBannerReceivers } = useApiAlertBannerReceivers({
    "admin-mode": true,
  });

  const alertBannerReceiversDismissed = alertBannerReceivers
    ? alertBannerReceivers.filter(
        (a) => a.alert_banner_message === selectedAlert?.id && a.is_dismissed
      )
    : [];
  return (
    <RightPane onClose={() => setShowDismissedUsers(false)} width="25%">
      <Flex column w100 h100 style={{ overflowY: "scroll" }}>
        <Flex column w100>
          <Flex
            column
            w100
            align="center"
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
            }}
          >
            <HeaderSecondary>Dismissed by Users</HeaderSecondary>
          </Flex>
          <Flex
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "10px",
            }}
          >
            <SeparatorHorizontal />
          </Flex>
          <Flex column>
            {alertBannerReceiversDismissed.length === 0 ? (
              <Flex column gap="8px" align="center" padding="24px">
                <HeaderSubBold>No one dismissed the alert!</HeaderSubBold>
              </Flex>
            ) : (
              alertBannerReceiversDismissed?.map((a) => (
                <DismissedUser alertBannerReceiver={a} />
              ))
            )}
          </Flex>
        </Flex>
      </Flex>
    </RightPane>
  );
};
