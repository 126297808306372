import { SetStateAction, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  HeaderSecondary,
  KPIHuge,
  KPISmall,
  LabelRegular,
  LabelRegularHover,
} from "../../../components/elements/typography/Typography";
import { DefaultFindingsCounts, FindingsCounts } from "../../../types/Finding";
import { FindingDoughnutChart } from "../findingsCharts/FindingDoughnutChart";
import { TrendUpGreenIcon } from "../../../components/elements/icon/TrendUpGreenIcon";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_LAPTOP_WIDTH } from "../../../shared/consts";
import { TopBox, TopBoxTitle } from "../FindingsStyles";
import { useNavigate } from "react-router";
import { Flex } from "../../../components/layouts/flex/Flex";
import { objectToBase64 } from "../../../shared/helper";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";

const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
const fixedInLastSevenDaysFilters = {
  status_updated_at__gt: sevenDaysAgo,
  status: "fixed",
};

const FindingsOverview = ({
  findingsCounts,
  isFetching,
  setFilters,
}: {
  findingsCounts: FindingsCounts | undefined;
  isFetching?: boolean;
  setFilters: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
}) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isLaptopScreen = screenWidth < SCREEN_LAPTOP_WIDTH;

  const TotalCounter = () => (
    <Flex
      column
      justify="center"
      align="center"
      style={{
        width: "100%",
        overflow: "auto",
        maxWidth: "100px",
      }}
    >
      {isFetching ? (
        <SkeletonLoading height="48px" width="48px" />
      ) : (
        <KPIHuge
          className=" text-truncate w-100"
          data-testid="total-issues-counter"
          title={findingsCounts?.total.toString() || "0"}
        >
          {findingsCounts?.total}
        </KPIHuge>
      )}

      <LabelRegular
        style={{ color: theme.black700 }}
        className="text-truncate w-100"
        title="Total Findings"
      >
        Total Findings
      </LabelRegular>
    </Flex>
  );

  const FixedCounter = () => {
    const navigate = useNavigate();

    const { data: lastSevenDaysCounts } = useApiFindingsCounts({
      status_updated_at__gt: sevenDaysAgo,
    });

    const fixedCount = lastSevenDaysCounts?.fixed || 0;

    return (
      <Flex
        column
        style={{ fontSize: "12px", width: "100%", overflow: "auto" }}
        justify="evenly"
      >
        <KPISmall
          className="d-flex align-items-center gap-8 text-truncate w-100"
          title={`${fixedCount}`}
          style={{ color: theme.greenPrimary }}
        >
          {fixedCount}
          <TrendUpGreenIcon />
        </KPISmall>
        <Flex>
          {fixedCount ? (
            <LabelRegularHover
              onClick={() => {
                navigate(
                  `/findings?filters=${objectToBase64(fixedInLastSevenDaysFilters)}`
                );
                setFilters({ status_updated_at__gt: "1", status: "fixed" });
              }}
              className="text-truncate w-100"
              title="Findings Fixed"
            >
              Findings Fixed
            </LabelRegularHover>
          ) : (
            <LabelRegular
              className="text-truncate w-100"
              title="Findings Fixed"
            >
              Findings Fixed
            </LabelRegular>
          )}
        </Flex>
        <LabelRegular
          className="text-truncate w-100"
          style={{ color: theme.black600 }}
          title="In last 7 days"
        >
          In last 7 days
        </LabelRegular>
      </Flex>
    );
  };

  return (
    <TopBox style={{ gap: "16px" }}>
      <TopBoxTitle>
        <Icon name="findings" size={32} color={theme.primary} />
        <HeaderSecondary>Findings Overview</HeaderSecondary>
      </TopBoxTitle>

      <Flex justify="between" w100 gap="16px">
        <TotalCounter />
        <SeparatorVertical />
        <Flex
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <FindingDoughnutChart
            findingsCounts={findingsCounts || DefaultFindingsCounts}
            hideLegendText={isLaptopScreen}
            isLoading={isFetching}
          />
        </Flex>
        <SeparatorVertical />
        {isFetching ? (
          <SkeletonLoading height="85px" width="160px" />
        ) : (
          <FixedCounter />
        )}
      </Flex>
    </TopBox>
  );
};

export default FindingsOverview;
