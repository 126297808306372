import { useContext } from "react";
import { ToggleThemeStyle, SliderThemeStyle } from "./ToggleTheme.style";
import { ThemeContext } from "styled-components";
import { Icon } from "../icon/Icon";
import { Mixpanel } from "../../../shared/mixpanel";

type Props = {
  isDark: boolean;
  onChange: (checked: boolean) => void;
};

export function ToggleTheme(props: Props) {
  const { isDark: checked, onChange } = props;
  const theme = useContext(ThemeContext);

  return (
    <ToggleThemeStyle>
      <input
        type="checkbox"
        role="button"
        aria-expanded="false"
        style={{ display: "none" }}
        checked={checked}
        onChange={(e) => {
          Mixpanel.track(
            `Dark Mode - changed to ${e.target.checked ? "dark" : "light"}`
          );
          onChange(e.target.checked);
        }}
      />
      <SliderThemeStyle checked={checked} />
      <span
        style={{
          position: "absolute",
          top: "3px",
          left: "5px",
          color: checked ? theme.blue500 : theme.blue,
          opacity: checked ? 0.5 : 1,
          cursor: "pointer",
        }}
      >
        <Icon name="light" color="white" size={23} />
      </span>
      <span
        style={{
          position: "absolute",
          top: "3px",
          right: "5px",
          color: checked ? theme.blue : theme.blue500,
          opacity: checked ? 1 : 0.5,
          cursor: "pointer",
        }}
      >
        <Icon name="dark" color="white" size={23} />
      </span>
    </ToggleThemeStyle>
  );
}
