import { useEffect, useMemo, useRef, useState } from "react";
import {
  useApiAssetsCount,
  useApiAssetsWithViewPaging,
  useApiUpdateAsset,
  useApiUpdateAssetsBulk,
} from "../../../hooks/queries/assetsContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  BodySemiBold,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import { Asset } from "../../../types/Asset";
import useToastContext from "../../../hooks/toastHook";
import { Loading } from "../../../components/elements/loading/Loading";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  useDebounceCallback,
  useInfiniteScroll,
} from "../../../hooks/utilsHooks";
import { useLicense } from "../../../licensing/LicenseManager";
import { SwitchBox } from "../../../components/elements/switchBox/SwitchBox";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { Filter } from "../../../types/AssetsView";
import { Illustration } from "../../../components/elements/illustrations/Illustration";

const maxEnabledAssets = 5;

const asmEnabledFilter = {
  column: "is_asm_enabled",
  value: "Enabled",
  condition: "is",
  order: 0,
  next_condition: "and",
};

const verifiedFilter = {
  column: "is_verified",
  value: "true",
  condition: "is",
  order: 0,
  next_condition: "and",
};

export const SelectAssets = () => {
  const addToast = useToastContext();
  const { licenseType } = useLicense();

  const [assetsFilters, setAssetsFilters] = useState<Filter[]>([]);

  const {
    data: assetsPages,
    isLoading: isLoadingAssets,
    isFetchingNextPage,
    hasNextPage: hasAssetsNextPage,
    fetchNextPage: fetchAssetsNextPage,
  } = useApiAssetsWithViewPaging(assetsFilters, "-created_at", false);

  const { data: assetsCount } = useApiAssetsCount({}, assetsFilters);

  const assets = useMemo(
    () => assetsPages?.pages.map((page) => page.results || []).flat() || [],
    [assetsPages]
  );

  const handleSearch = useDebounceCallback((newValue: string) => {
    setAssetsFilters(
      newValue
        ? [
            {
              column: "name",
              value: newValue,
              condition: "contains",
              order: 0,
              next_condition: "and",
            },
          ]
        : []
    );
  });

  const showSelected = () => setAssetsFilters([asmEnabledFilter]);

  const observerElemForFetchPage = useRef(null);

  useInfiniteScroll(
    observerElemForFetchPage,
    !!hasAssetsNextPage,
    fetchAssetsNextPage
  );

  const {
    mutate: updateAsset,
    isLoading: updatingAsset,
    variables: updateVariables,
    reset: updateReset,
  } = useApiUpdateAsset();

  const { mutate: bulkUpdateAssets } = useApiUpdateAssetsBulk();
  const updateAllVerifiedAssets = (state: boolean) => {
    bulkUpdateAssets({
      assetsData: {
        all: true,
        filters: [verifiedFilter],
        updates: { is_asm_enabled: state },
      },
      onErrorCallback: (err) =>
        addToast({ type: "error", message: err.message }),
    });
  };

  // counting the enabled assets to limit 5 enabled assets on trial mode
  const [enabledAssets, setEnabledAssets] = useState<number>(0);
  useEffect(() => {
    if (!assets?.length) return;
    setEnabledAssets(assets.filter((a) => a.is_asm_enabled)?.length);
  }, [assets]);

  const onChange = (asset: Asset, isActive: boolean) => {
    if (updatingAsset) return;
    if (enabledAssets === 5 && isActive && licenseType === "trial") {
      addToast({
        type: "error",
        message: "Cannot scan more than 5 assets on trial",
      });
      return;
    }
    updateAsset({
      assetId: asset.id,
      assetData: { is_asm_enabled: isActive },
      onSuccessCallback: () => {
        // update the enabled assets counter
        setEnabledAssets((prev) => (isActive ? prev + 1 : prev - 1));
        setTimeout(updateReset, 1000);
      },
      onErrorCallback: (err, updatedAsset) => {
        updateReset();
        addToast({
          type: "error",
          message: `Failed to ${
            updatedAsset?.is_asm_enabled ? "Enabled" : "Disabled"
          } scans for ${updatedAsset?.name} - ${err.message}`,
        });
      },
    });
  };

  const getTooltipText = (asset: Asset): string => {
    if (!asset.is_verified) return "Unverified assets cannot be scanned";
    if (
      enabledAssets >= maxEnabledAssets &&
      !asset.is_asm_enabled &&
      licenseType === "trial"
    )
      return "Cannot enable scans for more than 5 assets on trial mode";
    if (asset.is_asm_enabled) return `Disable ASM scans for ${asset.name}`;
    if (!asset.is_asm_enabled) return `Enable ASM scans for ${asset.name}`;
    return "";
  };

  return (
    <>
      <HeaderMain>Select Assets to Scan</HeaderMain>
      {licenseType === "trial" && (
        <BodyRegular>
          Please Select up to five assets that you want to scan
        </BodyRegular>
      )}
      <Flex w100 justify="between" align="center" style={{ width: "1326px" }}>
        <Flex gap="12px" align="center">
          <BodySemiBold>
            {enabledAssets} /{" "}
            {licenseType === "trial" ? "5" : assetsCount?.total} Assets Selected
          </BodySemiBold>
          <Flex style={{ width: "140px" }}>
            {!!assetsFilters[0] &&
            assetsFilters[0].column === "is_asm_enabled" ? (
              <TextButton
                label="Show All Assets"
                onClick={() => setAssetsFilters([])}
              />
            ) : (
              <TextButton
                label="Show Selected"
                onClick={showSelected}
                disabled={!enabledAssets}
              />
            )}
          </Flex>
          {licenseType !== "trial" && (
            <Flex style={{ width: "100px" }}>
              <TextButton
                label="Select All"
                onClick={() => updateAllVerifiedAssets(true)}
              />
            </Flex>
          )}
          <Flex style={{ width: "100px" }}>
            <TextButton
              label="Clear"
              onClick={() => updateAllVerifiedAssets(false)}
              disabled={!enabledAssets}
            />
          </Flex>
        </Flex>
        <InputText
          dataTestId="assets-search-bar"
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search"
          iconName="search"
          isClearable
          onClear={() => handleSearch(null)}
        />
      </Flex>
      {!!assetsFilters[0] &&
        assetsFilters[0].column === "name" &&
        !assets.length && (
          <>
            <Illustration name="empty" />
            <BodySemiBold>No matching asset found</BodySemiBold>
          </>
        )}
      {(isFetchingNextPage || isLoadingAssets) && <Loading />}
      <Flex
        flexWrap
        w100
        gap="12px"
        style={{ height: "360px", overflowY: "scroll" }}
      >
        {assets?.map((asset) => (
          <Flex gap="8px" align="center">
            <Tooltip content={getTooltipText(asset)}>
              <SwitchBox
                width="440px"
                state={asset.is_asm_enabled}
                label={asset.name}
                onChange={(state) => onChange(asset, !state)}
                inProgress={updateVariables?.assetId === asset.id}
                disabled={
                  updateVariables?.assetId === asset.id || !asset.is_verified
                }
              />
            </Tooltip>
          </Flex>
        ))}
        <div className="d-flex" ref={observerElemForFetchPage} />
      </Flex>
    </>
  );
};
