import { Box } from "../../../components/elements/box/Box";
import { Section } from "../../../components/elements/section/Section";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Asset, AssetCloudProperties } from "../../../types/Asset";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  asset: Asset | undefined;
};

export const AssetCloudDetails = (props: Props) => {
  const { asset } = props;

  const assetProperties = (asset?.properties as AssetCloudProperties) || {};

  if (asset?.type !== "cloud") {
    return null;
  }

  return (
    <Flex column gap="16px">
      <Box>
        <Flex column gap="24px">
          <Flex align="center" justify="around" className="mt-2">
            <Section title="Cloud Provider">
              <BodyRegular>
                {assetProperties?.cloud_provider || "N/A"}
              </BodyRegular>
            </Section>
            <Section title="Region" noSeparator>
              <BodyRegular>{assetProperties?.region || "N/A"}</BodyRegular>
            </Section>
          </Flex>

          <Flex align="center" justify="around" className="mt-2">
            <Section title="Resource Group">
              <Flex align="center" gap="8px">
                <BodyRegular>
                  {assetProperties?.resource_group || "N/A"}
                </BodyRegular>
              </Flex>
            </Section>

            <Section title="Resource Name">
              <Flex align="center" gap="8px">
                <BodyRegular
                  className="text-truncate"
                  style={{
                    maxWidth: "250px",
                  }}
                  title={assetProperties?.resource_name || ""}
                >
                  {assetProperties?.resource_name || "N/A"}
                </BodyRegular>
              </Flex>
            </Section>

            <Section title="Service">
              <Flex align="center" gap="8px">
                <BodyRegular>{assetProperties?.service || "N/A"}</BodyRegular>
              </Flex>
            </Section>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
