import React from "react";
import { Flex } from "../../components/layouts/flex/Flex";
import { HeaderMain } from "../../components/elements/typography/Typography";

import { AsmFindingsOvertime } from "./sub-components/AsmFindingsOvertime";
import { PendingFindingsWidget } from "./sub-components/PendingFindingsWidget";
import { ValidFindingsWidget } from "./sub-components/ValidFindingsWidget";
import { PendingValidFindings } from "./sub-components/PendingValidFindings";
import { FindingsCurrentBreakdown } from "./sub-components/FindingsCurrentBreakdown";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";

export const AsmAnalytics = () => {
  const isSuperUser = useIsSuperuser();
  if (!isSuperUser) {
    return (
      <Flex w100 h100>
        <HeaderMain>
          Access Denied: You do not have the necessary permissions to view this
          page.
        </HeaderMain>
      </Flex>
    );
  }
  return (
    <Flex column gap="16px" style={{ paddingBottom: "8px" }}>
      <HeaderMain>ASM Analytics</HeaderMain>
      <AsmFindingsOvertime />
      <Flex gap="16px">
        <PendingFindingsWidget />
        <ValidFindingsWidget />
      </Flex>
      <PendingValidFindings />
      <FindingsCurrentBreakdown />
    </Flex>
  );
};
