import { useRef, useState } from "react";
import { Modal } from "../../../components/elements/modal/Modal";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { Box } from "../../../components/elements/box/Box";
import { TagsLine } from "../../../components/composed/tagsLine/TagsLine";
import {
  handleFileUpload,
  isListCommaSeparated,
  textToList,
} from "./ImportAssetsHelper";
import { useApiImportAssets } from "../../../hooks/queries/assetsContext";
import useToastContext from "../../../hooks/toastHook";
import { Asset } from "../../../types/Asset";
import { VerifyImportedAssets } from "./VerifyImportedAssets";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";

type Props = {
  onClose: () => void;
};

export const ImportAssetsModal = (props: Props) => {
  const { onClose } = props;

  const addToast = useToastContext();

  const { mutate: importAssets, isLoading } = useApiImportAssets();

  const [domainsList, setDomainsList] = useState<string[]>([]);

  const [savedAssets, setSavedAssets] = useState<Asset[]>([]);
  const stage = !savedAssets.length ? "import" : "verify";

  const fileInputRef = useRef(null);

  const openFileUpload = () => {
    // @ts-ignore
    fileInputRef.current.click();
  }; // Trigger the click event on the input element

  const fileUploadCallBack = (e: ProgressEvent<FileReader>) => {
    const currentList = domainsList.join(",");
    let newList = `${e.target?.result}`;
    if (isListCommaSeparated(newList)) newList = newList.replaceAll("\n", ",");
    const content = `${currentList}, ${newList}`;
    setDomainsList(textToList(content).filter((s) => !!s));
  };

  const pasteFromClipboard = async () => {
    const currentList = domainsList.join(",");
    let newList = await navigator.clipboard.readText();
    if (isListCommaSeparated(newList)) newList = newList.replaceAll("\n", ",");
    const content = `${currentList}, ${newList}`;
    setDomainsList(textToList(content).filter((s) => !!s));
  };

  const processDomainList = () => {
    importAssets({
      domain_names: domainsList,
      onErrorCallback: (err) =>
        addToast({
          message: `Failed to import assets | ${err.message}`,
          type: "error",
        }),
      onSuccessCallback: (assets) => {
        setSavedAssets(assets);
        addToast({
          message: `Assets created successfully`,
          type: "success",
        });
      },
    });
  };

  return (
    <Modal
      onClose={onClose}
      width="749px"
      header="Import Assets"
      iconName="upload"
    >
      {stage === "verify" ? (
        <VerifyImportedAssets
          assets={savedAssets}
          onClose={onClose}
          isNewAssets
        />
      ) : (
        <Flex column gap="24px" w100>
          <Flex column gap="8px">
            <BodyBold>Upload a .txt file</BodyBold>
            <BodyRegular>
              Please upload a .txt file containing a list of domain names.
            </BodyRegular>
            <BodyRegular>
              Ensure the domain names are comma-separated. For example:
              example.com, sub.example.com
            </BodyRegular>
          </Flex>
          <Flex>
            <BodyRegular style={{ alignSelf: "center" }}>
              <input
                type="file"
                onChange={(e) => handleFileUpload(e, fileUploadCallBack)}
                accept=".txt"
                ref={fileInputRef}
                style={{ display: "none" }}
                data-testid="file-upload"
              />
              <MainButton
                label={
                  !!domainsList.length ? "Select Another File" : "Select File"
                }
                iconName="upload"
                onClick={openFileUpload}
                size="medium"
              />
            </BodyRegular>
          </Flex>

          <Flex column gap="8px">
            <Flex w100 align="center" justify="end">
              <TextButton
                label="Paste from Clipboard"
                onClick={pasteFromClipboard}
              />
              <SeparatorVertical height="24px" />
              <TextButton
                label="Clear All"
                onClick={() => setDomainsList([])}
                disabled={!domainsList.length}
              />
            </Flex>
            <Box style={{ maxHeight: "240px", overflowX: "auto" }}>
              <Flex>
                <TagsLine
                  placeholder="Enter domain name"
                  selectedTags={domainsList}
                  isEditable
                  onTagAdd={(tag) => setDomainsList((prev) => [...prev, tag])}
                  onTagRemove={(tag) =>
                    setDomainsList((prev) =>
                      prev.filter((domain) => domain !== tag)
                    )
                  }
                />
              </Flex>
            </Box>
          </Flex>

          <Flex gap="8px" align="center" justify="end">
            <SecondaryButton label="Cancel" onClick={onClose} size="medium" />
            <MainButton
              label="Create Assets"
              disabled={!domainsList.length}
              onClick={processDomainList}
              inProgress={isLoading}
              size="medium"
            />
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};
