import mixpanel from "mixpanel-browser";
import { IS_DEMO_ENV, IS_PRODUCTION_ENV } from "../hooks/queries/utils";

if (IS_PRODUCTION_ENV || IS_DEMO_ENV) {
  mixpanel.init("bc6866ce81c31c9006f43237bfd49e4b");
} else {
  console.log("mixpanel initialized");
  // This will initialize mixpanel in debug mode and will not send any data to the server.
  mixpanel.init("placeholder_token", { debug: true, verbose: true });
}

export const Mixpanel = mixpanel;
