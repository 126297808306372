import Graph from "graphology";
import Sigma, { Camera } from "sigma";
import ForceSupervisor from "graphology-layout-force/worker";
import getNodeProgramImage from "sigma/rendering/webgl/programs/node.image";
import { calcGraphNodeSize, getAssetGraphIcon } from "./utils";
import { getRiskColor } from "../../../../shared/findingsHelper";
import { SEVERITIES } from "../../../../shared/consts";
import { GraphAsset, GraphDataProvider } from "./GraphDataProvider";
import { Product } from "../../../../types/Product";
import { Filter } from "../../../../types/AssetsView";

export type nodeType = "product" | "asset" | "vulnerability" | "owner";

export class AssetGraphEngine {
  filters: Filter[] = [];
  waitingFilters: Filter[] | null;
  clickedNode: string | null;
  isDragging: boolean;
  isMoving: boolean;
  showCount: number;
  click: number;
  graph: any; //Graph;
  layout: ForceSupervisor;
  renderer: Sigma | null;
  camera: Camera | null;
  products: Product[];
  handleNodeSingleClick: (nodeId: number, nodeType: nodeType) => void;
  expandingNodes: string[];
  assets: GraphAsset[];
  theme: any;
  graphDataProvider: GraphDataProvider;
  type: string;

  constructor(
    products: Product[],
    handleNodeSingleClick: (nodeId: number, nodeType: nodeType) => void,
    filters: Filter[]
  ) {
    this.type = "risk";
    this.filters = filters;
    this.waitingFilters = null;
    // State for drag'n'drop
    this.clickedNode = null;
    this.isDragging = false;
    this.isMoving = false;
    this.showCount = 0;
    // Handle double click...
    // credit: https://stackoverflow.com/questions/5497073/how-to-differentiate-single-click-event-and-double-click-event
    this.click = 0;

    this.graph = new Graph();
    this.graphDataProvider = new GraphDataProvider();
    // Create the spring layout and start it
    // Docs: https://graphology.github.io/standard-library/layout-force.html#settings
    this.layout = new ForceSupervisor(this.graph, {
      isNodeFixed: (_: any, attr: any) => attr.sticked || attr.highlighted,
      settings: {
        //         attraction ?number 0.0005: importance of the attraction force, that attracts each pair of connected nodes like elastics.
        //          repulsion ?number 0.1: importance of the repulsion force, that attracts each pair of nodes like magnets.
        //          gravity ?number 0.0001: importance of the gravity force, that attracts all nodes to the center.
        //          inertia ?number 0.6: percentage of a node vector displacement that is preserved at each step. 0 means no inertia, 1 means no friction.
        //          maxMove ?number 200: Maximum length a node can travel at each step, in pixel.
        // attraction: 0.00005,
        maxMove: 1,
      },
    });
    this.renderer = null;
    this.camera = null;

    //react states vars
    this.products = products;
    this.handleNodeSingleClick = handleNodeSingleClick;
    this.expandingNodes = [];
    this.assets = [];
    // this.layout.start();
  }

  stop() {
    console.log("Stop graph");
    this.layout.kill();
  }

  async startGraph(elementContainerId: string, theme: any) {
    // Retrieve the html document for sigma container
    var container = document.getElementById(elementContainerId);
    if (!container) return;
    container.innerHTML = "";
    // Create the sigma
    // All Settings: https://github.com/jacomyal/sigma.js/blob/339be9ed274fcfb881ddd3585974ea7be46ca7dd/src/settings.ts#L34-L82
    this.renderer = new Sigma(this.graph, container, {
      nodeProgramClasses: {
        image: getNodeProgramImage(),
      },
      renderEdgeLabels: true,
      allowInvalidContainer: true,
      labelColor: { color: theme?.name === "dark" ? "white" : "black" },
    });
    this.camera = this.renderer.getCamera();
    this.theme = theme;
    // will be called on filter changed function
    // this.initRootNodes();

    this.renderer.on("downNode", (e) => {
      this.handleNodeDown(e);
    });

    this.renderer.on("enterNode", ({ node }) => {
      this.handleHoverIn(node);
    });

    this.renderer.on("leaveNode", ({ node }) => {
      this.handleHoverOut(node);
    });

    this.renderer.getMouseCaptor().on("mousemovebody", (e) => {
      this.handleMouseMove(e);
    });

    this.renderer.getMouseCaptor().on("mouseup", (e) => {
      this.handleMouseUp(e);
    });

    this.renderer.getMouseCaptor().on("rightClick", (e) => {
      this.handleMouseUp(e);
    });

    // Disable the auto-scale at the first down interaction
    this.renderer.getMouseCaptor().on("mousedown", () => {
      if (!this.renderer?.getCustomBBox())
        this.renderer?.setCustomBBox(this.renderer?.getBBox());
    });
  }

  async init() {
    await this.initDataFromServer();
    this.initRootNodes();
    if (this.filters.length) {
      await this.applyFilters(this.filters);
    }
  }

  async initDataFromServer() {
    this.showWaitingIcon();
    await this.graphDataProvider.init();
    this.hideWaitingIcon();
  }

  async initRootNodes() {
    this.graph.clear();
    const rootAssets = this.graphDataProvider.getRootAssets();
    this.initProductsNodes(rootAssets);

    rootAssets.forEach(async (asset) => {
      let updatedAsset = { ...asset };
      updatedAsset.related_findings_objects = this.getRelatedFindings(
        updatedAsset.related_findings || []
      );
      updatedAsset.related_findings =
        updatedAsset.related_findings_objects?.map((finding) => finding.id) ||
        [];
      this.assets.push(updatedAsset);
      const nodeId = `a${updatedAsset.id}`;
      const parentNode = `p${asset.product_id}`;
      const totalCount =
        (updatedAsset.children_count || 0) +
        (updatedAsset.related_findings?.length || 0);
      if (!this.graph.hasNode(nodeId)) {
        this.graph.addNode(nodeId, {
          x: Math.round(Math.random() * 200),
          y: Math.round(Math.random() * 200),
          size: calcGraphNodeSize(totalCount || 0),
          color: this.theme.black400,
          colorFixed: this.theme.black400,
          label: updatedAsset.name,
          type: "image",
          parentNode: parentNode,
          image: getAssetGraphIcon(updatedAsset, updatedAsset.related_findings),
          imageFixed: getAssetGraphIcon(
            updatedAsset,
            updatedAsset.related_findings
          ),
          totalCount: totalCount,
        });
      }

      this.addEdges(nodeId, parentNode);

      // add children nodes
      if (totalCount) this.handleDoubleClick(nodeId);
    });
    this.layout.start();
  }

  onThemeChange(theme: any) {
    this.theme = theme;
    this.renderer?.setSetting("labelColor", {
      color: this.theme?.name === "dark" ? "white" : "black",
    });
  }

  initProductsNodes(assets: GraphAsset[]) {
    this.products.forEach((product) => {
      const productAssets = assets.filter((a) => a.product_id === product.id);
      if (productAssets.length === 0) {
        return;
      }
      const nodeId = `p${product.id}`;
      this.graph.addNode(nodeId, {
        x: Math.round(Math.random()),
        y: Math.round(Math.random()),
        size: Math.max(calcGraphNodeSize(productAssets.length), 30),
        color: this.theme.blue600,
        colorFixed: this.theme.blue600,
        label: product.name,
        type: "image",
        parentNode: null,
        image: "/icons/product.svg",
        imageFixed: "/icons/product.svg",
      });
    });
  }

  getAssetsByCorp(corpName: string) {}

  initToolsButtons() {
    const fullScreenBtn = document.getElementById("full-screen-btn");
    const exitFullScreenBtn = document.getElementById("exit-full-screen-btn");
    const zoomInBtn = document.getElementById("zoom-in-btn");
    const zoomOutBtn = document.getElementById("zoom-out-btn");
    const zoomResetBtn = document.getElementById("zoom-reset-btn");
    if (!zoomInBtn || !zoomOutBtn || !zoomResetBtn) return;
    // Bind zoom manipulation buttons
    zoomInBtn.addEventListener("click", () => {
      this.camera?.animatedZoom({ duration: 600 });
    });
    zoomOutBtn.addEventListener("click", () => {
      this.camera?.animatedUnzoom({ duration: 600 });
    });
    zoomResetBtn.addEventListener("click", () => {
      this.camera?.animatedReset({ duration: 600 });
    });

    fullScreenBtn?.addEventListener("click", () => {
      document
        ?.getElementById("graph-component-container")
        ?.requestFullscreen()
        .catch(function (error) {
          // element could not enter fullscreen mode
          // error message
          console.log(error.message);
        });
    });
    exitFullScreenBtn?.addEventListener("click", () => {
      document.exitFullscreen().catch(function (error) {
        // element could not exit fullscreen mode
        // error message
        console.log(error.message);
      });
    });
  }

  expandAssetFindings(asset: GraphAsset) {
    if (asset?.related_findings?.length && this.graph.hasNode(`a${asset.id}`)) {
      this.addRelatedFindings(`a${asset.id}`);
    }
  }

  handleHoverIn(node: string) {
    if (this.graphDataProvider.hasFilters) return;
    this.renderer?.setSetting("labelColor", { color: "black" });
    var highlightedNodes = [node];
    this.graph.forEachNeighbor(node, (navigator: string) => {
      this.graph.setNodeAttribute(navigator, "highlighted", true);
      highlightedNodes.push(navigator);
    });
    this.graph.forEachNode((n: string) => {
      if (!highlightedNodes.includes(n)) {
        this.graph.setNodeAttribute(n, "color", this.theme.Foreground80);
        this.graph.setNodeAttribute(n, "image", null);
      }
    });
  }

  handleHoverOut(node: string) {
    if (this.graphDataProvider.hasFilters) return;
    this.renderer?.setSetting("labelColor", {
      color: this.theme?.name === "dark" ? "white" : "black",
    });
    this.graph.forEachNeighbor(node, (navigator: string) => {
      this.graph.setNodeAttribute(navigator, "highlighted", false);
    });
    this.graph.forEachNode((n: string) => {
      this.graph.setNodeAttribute(
        n,
        "color",
        this.graph.getNodeAttribute(n, "colorFixed")
      );
      this.graph.setNodeAttribute(
        n,
        "image",
        this.graph.getNodeAttribute(n, "imageFixed")
      );
    });
  }

  getRelatedFindings(relatedFindingsIds: number[]) {
    if (relatedFindingsIds.length > 0) {
      return this.graphDataProvider.getRelatedFindings(relatedFindingsIds);
    }
    return [];
  }

  // On mouse down on a node
  //  - we enable the drag mode
  //  - save in the dragged node in the state
  //  - highlight the node
  //  - disable the camera so its state is not updated
  handleNodeDown(e: any) {
    this.isDragging = true;
    this.clickedNode = e.node;
    e.preventSigmaDefault();
    this.graph.setNodeAttribute(this.clickedNode, "highlighted", true);
  }

  updateNodePosition(e: any) {
    // Get new position of node
    const pos = this.renderer?.viewportToGraph(e);
    this.graph.setNodeAttribute(this.clickedNode, "x", pos?.x || 0);
    this.graph.setNodeAttribute(this.clickedNode, "y", pos?.y || 0);
  }

  // On mouse move, if the drag mode is enabled, we change the position of the draggedNode
  handleMouseMove(e: any) {
    if (!this.isDragging || !this.clickedNode || !this.isLeftClick(e)) return;
    this.isMoving = true;

    this.updateNodePosition(e);
    // Prevent sigma to move camera:
    e.preventSigmaDefault();
    e.original.preventDefault();
    e.original.stopPropagation();
  }

  isRightClick(e: any) {
    return e.original?.button === 2;
  }

  isLeftClick(e: any) {
    return e.original?.button === 0;
  }

  showLabels(needToShow: boolean) {
    this.renderer?.setSetting("renderLabels", needToShow);
  }

  showWaitingIcon() {
    this.showCount++;
    console.log("show waiting icon", this.showCount);
    const loadingAssetsIcon = document.getElementById("loading-assets-icon");
    if (loadingAssetsIcon) {
      loadingAssetsIcon.style.display = "block";
    }
  }

  showSubdomains(show: boolean) {}

  hideWaitingIcon() {
    this.showCount--;
    console.log("hide waiting icon", this.showCount);
    if (this.showCount > 0) {
      console.log("Do not hide during fetching");
      return;
    }
    const loadingAssetsIcon = document.getElementById("loading-assets-icon");
    if (loadingAssetsIcon) {
      loadingAssetsIcon.style.display = "none";
      this.finishFetching();
    }
  }

  finishFetching() {
    // finish all fetching
    // if filtersBacklog => call onFilterChange
    // this.setAssetsCount(this.graph.nodes().length);
    if (this.waitingFilters) {
      this.onFilterChange([...this.waitingFilters]);
      this.waitingFilters = null;
    }
  }

  // On mouse up, we reset the auto-scale and the dragging mode
  handleMouseUp(e: any) {
    e.preventSigmaDefault();
    const element = document.getElementById("node-menu");
    if (element?.style) {
      element.style.display = "none";
    }
    if (this.clickedNode) {
      this.graph.removeNodeAttribute(this.clickedNode, "highlighted");
      if (this.isLeftClick(e)) {
        this.updateNodePosition(e);
        this.graph.setNodeAttribute(this.clickedNode, "sticked", true);

        if (!this.isMoving) {
          this.handleSingleAndDoubleClick(this.clickedNode);
        }
      }

      if (this.isRightClick(e)) {
        this.handleNodeRightClick(e);
      }
    }

    this.isMoving = false;
    this.isDragging = false;
    this.clickedNode = null;
  }

  addRelatedAssets(clickedNode: string): void {
    const page = this.graph.getNodeAttribute(clickedNode, "page") || 1;
    const assetsPage = this.graphDataProvider.getChildrenFilteredAssetsPage(
      this.idFromNodeId(clickedNode),
      page || 1
    );
    this.graph.setNodeAttribute(clickedNode, "page", page + 1);
    if (assetsPage && assetsPage?.length && this.graph.hasNode(clickedNode)) {
      // if all assets exists fetch next page
      if (assetsPage.every((asset) => this.graph.hasNode(`a${asset.id}`))) {
        return this.addRelatedAssets(clickedNode);
      }
      this.graph.setNodeAttribute(clickedNode, "sticked", true);
      assetsPage.forEach((asset) => {
        this.addAssetNodesToRoot(asset, clickedNode);
      });
    }
  }

  addAffectedAssets(clickedNode: string) {
    const page = this.graph.getNodeAttribute(clickedNode, "page") || 1;
    const finding = this.graphDataProvider.getFinding(
      this.idFromNodeId(clickedNode)
    );
    if (!finding) return;
    const assetsPage = this.graphDataProvider.getFindingAffectedAssetsPage(
      finding.affected_assets || [],
      page || 1
    );
    if (!assetsPage) return;
    this.graph.setNodeAttribute(clickedNode, "page", page + 1);
    assetsPage.forEach((asset) => {
      this.addAssetNodesToRoot(asset, clickedNode);
    });
  }

  addAssetNodesToRoot(asset: GraphAsset, relatedNode?: string) {
    const existsNode = this.graph.findNode((n: string) => n === `a${asset.id}`);
    if (existsNode) {
      return;
    } else {
      let updatedAsset = { ...asset };
      let relatedFindings = this.getRelatedFindings(
        asset.related_findings || []
      );
      updatedAsset.related_findings_objects = relatedFindings
        ? [...relatedFindings]
        : [];
      updatedAsset.related_findings = relatedFindings?.map(
        (finding) => finding.id
      );
      this.assets.push(updatedAsset);
      const parentAssetNode = updatedAsset.parent_asset
        ? `a${updatedAsset.parent_asset}`
        : `p${updatedAsset.product_id}`;
      const nodeId = `a${updatedAsset.id}`;
      const totalCount =
        (updatedAsset.children_count || 0) +
        (updatedAsset.related_findings?.length || 0);
      this.graph.addNode(nodeId, {
        x: Math.round(Math.random() * 200),
        y: Math.round(Math.random() * 200),
        size: calcGraphNodeSize(totalCount || 0),
        color: this.theme.black400,
        colorFixed: this.theme.black400,
        label: updatedAsset.name,
        type: "image",
        parentNode: parentAssetNode,
        image: getAssetGraphIcon(updatedAsset, updatedAsset.related_findings),
        imageFixed: getAssetGraphIcon(
          updatedAsset,
          updatedAsset.related_findings
        ),
        totalCount: totalCount,
      });

      this.expandAssetFindings(asset);

      if (!!relatedNode && this.graph.hasNode(relatedNode)) {
        this.addEdges(
          nodeId,
          relatedNode,
          updatedAsset.related_findings,
          parentAssetNode
        );
      }

      // if parent not exists add him
      if (
        parentAssetNode &&
        !this.graph.hasNode(parentAssetNode) &&
        parentAssetNode.startsWith("a")
      ) {
        const asset = this.graphDataProvider.getAsset(
          this.idFromNodeId(parentAssetNode)
        );
        if (asset) {
          this.addAssetNodesToRoot(asset, nodeId);
          this.assets.push(asset);
        }
      }

      // if parent exists but without edge to this new node
      if (
        parentAssetNode &&
        this.graph.hasNode(parentAssetNode) &&
        !this.edgeExists(nodeId, parentAssetNode)
      ) {
        this.addEdges(nodeId, parentAssetNode);
      }

      // case is root asset - add product node if not exists
      if (!parentAssetNode || parentAssetNode.startsWith("p")) {
        if (!this.graph.hasNode(`p${updatedAsset.product_id}`)) {
          this.initProductsNodes([updatedAsset]);
        }
        if (!this.edgeExists(`p${updatedAsset.product_id}`, nodeId)) {
          this.addEdges(nodeId, `p${updatedAsset.product_id}`);
        }
      }
    }
  }

  idFromNodeId(nodeId: string) {
    return parseInt(nodeId.substring(1));
  }

  getChildrenShowingCount(nodeId: string) {
    if (!nodeId.startsWith("a")) {
      return this.graph.neighbors(nodeId).length;
    }

    // case its asset remove the parent (can be only one parent)
    return this.graph.neighbors(nodeId).length - 1;
  }

  getSingleAsset(assetId: number) {
    var asset = this.assets.find((asset) => asset.id === assetId);
    if (asset) {
      return asset;
    }
    asset = this.graphDataProvider.getAsset(assetId);
    if (asset) {
      this.assets.push(asset);
      return asset;
    }
  }

  addRelatedFindings(clickedNode: string) {
    const clickedAssetId = this.idFromNodeId(clickedNode);
    const asset = this.getSingleAsset(clickedAssetId);
    if (!asset) return;
    let relatedFindings = this.getRelatedFindings(
      asset?.related_findings || []
    );
    if (!relatedFindings?.length) return;
    relatedFindings.forEach((finding) => {
      const nodeId = `v${finding.id}`;
      if (this.graph.hasNode(nodeId)) {
        return;
      } else {
        this.graph.addNode(nodeId, {
          x: Math.round(Math.random() * 10),
          y: Math.round(Math.random() * 10),
          size: calcGraphNodeSize(finding?.affected_assets?.length || 0),
          color: this.theme[getRiskColor(finding.overall_risk)],
          colorFixed: this.theme[getRiskColor(finding.overall_risk)],
          parentNode: clickedNode,
          label: finding.title,
          type: "image",
          image: `/icons/finding-${SEVERITIES[
            finding.overall_risk
          ].toLowerCase()}.svg`,
          imageFixed: `/icons/finding-${SEVERITIES[
            finding.overall_risk
          ].toLowerCase()}.svg`,
          totalCount: finding.affected_assets?.length || 0,
        });
        this.addEdges(nodeId, clickedNode, finding.affected_assets);
      }
    });
  }

  handleSingleAndDoubleClick(clickedNode: string) {
    this.click++;
    setTimeout(() => {
      if (this.click === 1) {
        this.click = 0;
        this.handleSingleClick(clickedNode);
      }
      this.click = 0;
    }, 300);
    if (this.click === 2) {
      this.click = 0;
      this.handleDoubleClick(clickedNode);
    }
  }

  handleSingleClick = (clickedNode: string) => {
    const element = document.getElementById("node-menu");
    if (element?.style) {
      element.style.display = "none";
    }
    const id = this.idFromNodeId(clickedNode);
    if (clickedNode.startsWith("a")) {
      this.handleNodeSingleClick(id, "asset");
    } else if (clickedNode.startsWith("p")) {
      this.handleNodeSingleClick(id, "product");
    } else if (clickedNode.startsWith("v")) {
      this.handleNodeSingleClick(id, "vulnerability");
    }
  };

  handleDoubleClick = (clickedNode: string) => {
    const element = document.getElementById("node-menu");
    if (element?.style) {
      element.style.display = "none";
    }
    if (clickedNode.startsWith("p")) return;
    const nodeAttributes = this.graph.getNodeAttributes(clickedNode);
    if (this.expandingNodes.includes(clickedNode)) {
      this.expandingNodes = this.expandingNodes.filter(
        (n) => n !== clickedNode
      );
    } else {
      this.expandingNodes.push(clickedNode);
    }

    const hasMore =
      nodeAttributes?.totalCount > this.getChildrenShowingCount(clickedNode);

    if (hasMore && clickedNode.startsWith("a")) {
      this.addRelatedAssets(clickedNode);
      this.addRelatedFindings(clickedNode);
    }

    if (hasMore && clickedNode.startsWith("v")) {
      this.addAffectedAssets(clickedNode);
    }

    // open findings only for the first expand
    if (hasMore && this.getChildrenShowingCount(clickedNode) <= 0) {
      this.addRelatedFindings(clickedNode);
    }
    if (!hasMore) {
      // set attribute page to 0
      this.graph.setNodeAttribute(clickedNode, "page", 0);
      this.removeChildren(clickedNode);
    }
  };

  removeChildren(clickedNode: string) {
    const parentNode = this.graph.getNodeAttributes(clickedNode)?.parentNode;
    this.graph.neighbors(clickedNode).forEach((n: string) => {
      if (!clickedNode.startsWith("a") || n !== parentNode) {
        this.graph.dropNode(n);
      }
    });
  }

  addEdges(
    newNode: string,
    clickedNode: string,
    relatedIds?: number[],
    parentNode?: string
  ) {
    if (!this.edgeExists(newNode, clickedNode)) {
      this.graph.addEdge(newNode, clickedNode, {
        size: 2,
        color: this.theme.Foreground80,
      });
    }

    // find and connect all nodes related to the new node
    this.graph.forEachNode((n: string) => {
      if (
        !n.startsWith("p") && // ignore products
        !this.edgeExists(newNode, n) && // ignore if edge already exists
        relatedIds?.includes(this.idFromNodeId(n)) // ignore if not in the related list
      ) {
        this.graph.addEdge(newNode, n, {
          size: 2,
          color: this.theme.Foreground80,
        });
      }
    });

    // Add edge to parent node if it not exists
    if (
      parentNode &&
      !this.edgeExists(newNode, parentNode) &&
      this.graph.hasNode(parentNode)
    ) {
      this.graph.addEdge(newNode, parentNode, {
        size: 2,
        color: this.theme.Foreground80,
      });
    }

    // find and connect all nodes children to the new node
    this.graph.forEachNode((n: string) =>
      this.graph.getNodeAttributes(n)?.parentNode === newNode &&
      !this.edgeExists(n, newNode)
        ? this.graph.addEdge(newNode, n, {
            size: 2,
            color: this.theme.Foreground80,
          })
        : null
    );
  }

  edgeExists(node1: string, node2: string) {
    return !!this.graph.findEdge(
      (
        edge: any,
        attributes: any,
        source: string,
        target: string,
        sourceAttributes: any,
        targetAttributes: any
      ) =>
        (source === node1 && target === node2) ||
        (source === node2 && target === node1)
    );
  }

  handleNodeRightClick = (e: any) => {
    const posX = e.original.clientX;
    const posY = e.original.clientY;
    const nodeMenu = document.getElementById("node-menu");
    if (nodeMenu) {
      nodeMenu.style.display = "block";
      nodeMenu.style.top = posY + 5 + "px";
      nodeMenu.style.left = posX + "px";
    }
    const hideBtn = document.getElementById("node-hide-from-graph-btn");
    const expandBtn = document.getElementById("node-expand-children-btn");
    const expandBtnTitle = document.getElementById(
      "node-expand-children-btn-title"
    );
    const viewDetailsBtn = document.getElementById("node-view-details-btn");
    hideBtn?.setAttribute("selected_node", this.clickedNode || "");
    expandBtn?.setAttribute("selected_node", this.clickedNode || "");
    viewDetailsBtn?.setAttribute("selected_node", this.clickedNode || "");

    if (hideBtn) {
      hideBtn.onclick = (e: any) => {
        const existsNode = this.graph.findNode(
          (n: string) => n === e.currentTarget.getAttribute("selected_node")
        );
        if (existsNode) {
          this.graph.dropNode(existsNode);
          const menuElement = document.getElementById("node-menu");
          if (menuElement?.style) {
            menuElement.style.display = "none";
          }
        }
      };
    }
    const thisNodeAttributes = this.graph.getNodeAttributes(this.clickedNode);
    const currentChildren = this.getChildrenShowingCount(
      this.clickedNode || ""
    );
    const hasMore = thisNodeAttributes?.totalCount > currentChildren;

    if (expandBtn?.style && expandBtnTitle?.style) {
      (expandBtn as HTMLButtonElement).disabled =
        (this.clickedNode || "").startsWith("p") ||
        this.filters?.length ||
        thisNodeAttributes?.isFetching ||
        (!hasMore && currentChildren <= 0);
      if (!!(expandBtn as HTMLButtonElement).disabled) {
        expandBtnTitle.classList.add("disabled");
        expandBtn.classList.add("disabled");
      } else {
        expandBtnTitle.classList.remove("disabled");
        expandBtn.classList.remove("disabled");
      }
    }

    const neighborsName = this.clickedNode?.startsWith("v")
      ? "assets"
      : "children";

    if (expandBtnTitle) {
      if (this.filters?.length) {
        expandBtnTitle.innerText = `Expand ${neighborsName}`;
      } else if (!hasMore && currentChildren > 0) {
        expandBtnTitle.innerText = `Collapse ${neighborsName}`;
      } else if (hasMore && currentChildren > 0) {
        expandBtnTitle.innerText = `Expand more ${neighborsName} (${currentChildren}/${thisNodeAttributes?.totalCount})`;
      } else if ((expandBtn as HTMLButtonElement).disabled) {
        expandBtnTitle.innerText = `Expand ${neighborsName}`;
      } else {
        expandBtnTitle.innerText = `Expand ${neighborsName} (${
          thisNodeAttributes?.totalCount || 0
        })`;
      }
    }

    if (expandBtn) {
      expandBtn.onclick = (e: any) =>
        this.handleDoubleClick(
          e.currentTarget?.getAttribute("selected_node") || ""
        );
    }
    if (viewDetailsBtn) {
      viewDetailsBtn.onclick = (e: any): void =>
        this.handleSingleClick(
          e.currentTarget.getAttribute("selected_node") || ""
        );
    }
  };

  onFilterChange = async (newFilters: Filter[]) => {
    const filtersHasChanged =
      JSON.stringify(this.filters) !== JSON.stringify(newFilters);
    this.filters = newFilters;
    // remove all nodes from graph - inside the initNodes function
    // get root assets (with updated filters)
    if (filtersHasChanged) {
      await this.applyFilters(newFilters);
    }
  };

  async applyFilters(filters: Filter[]) {
    this.showWaitingIcon();
    await this.graphDataProvider.updateAssetsFilters(filters);
    this.hideWaitingIcon();
    if (!this.graphDataProvider.hasFilters) {
      this.initRootNodes();
    } else {
      this.expandAllAppliedFilters();
      this.filterAssets(this.graphDataProvider.filteredAssetsIds);
    }
  }

  expandAllAppliedFilters() {
    if (!this.graphDataProvider.hasFilters) return;
    this.graphDataProvider.filteredAssetsIds.forEach((assetId) => {
      if (!this.graph.hasNode(`a${assetId}`)) {
        const asset = this.graphDataProvider.getAsset(assetId);
        !!asset && this.addAssetNodesToRoot(asset);
      }
    });
  }

  searchQuery(query: string) {
    if (query) {
      const lcQuery = query.toLowerCase();
      const suggestions = this.graph
        .nodes()
        .map((n: string) => ({
          id: n,
          label: this.graph.getNodeAttribute(n, "label"),
        }))
        .filter(({ label }: { [key: string]: string }) =>
          label.toLowerCase().includes(lcQuery)
        );

      const neighbors = suggestions
        .map((n: { id: string; label: string }) => this.graph.neighbors(n.id))
        .flat();
      this.renderer?.setSetting("nodeReducer", (node, data) => {
        const res = { ...data };
        if (
          !suggestions
            .map((n: { id: string; label: string }) => n.id)
            .includes(node) &&
          !neighbors.includes(node)
        ) {
          res.hidden = true;
        }
        return res;
      });
    } else {
      this.renderer?.setSetting("nodeReducer", (node, data) => {
        const res = { ...data };
        res.hidden = false;
        return res;
      });
    }
  }

  filterAssets(assetsIds: number[]) {
    if (assetsIds) {
      this.graph.forEachNode((node: string) => {
        this.graph.setNodeAttribute(node, "filtered", false);
        if (assetsIds.includes(this.idFromNodeId(node))) {
          this.graph.setNodeAttribute(node, "filtered", true);
        } else {
          this.graph.setNodeAttribute(node, "filtered", false);
          this.graph.setNodeAttribute(node, "color", this.theme.Foreground80);
          this.graph.setNodeAttribute(node, "image", null);
        }
      });
      // highlight neighbors of all highlighted nodes
      this.graph.forEachNode((node: string) => {
        if (this.graph.getNodeAttribute(node, "filtered")) {
          this.graph.forEachNeighbor(node, (navigator: string) => {
            this.graph.setNodeAttribute(
              navigator,
              "color",
              this.graph.getNodeAttribute(navigator, "colorFixed")
            );
            this.graph.setNodeAttribute(
              navigator,
              "image",
              this.graph.getNodeAttribute(navigator, "imageFixed")
            );
          });
        }
      });
    }
  }
}
