import styled from "styled-components";

type TextAreaProps = {
  isError?: boolean;
  width?: string;
};

export const StyleTextArea = styled.textarea<TextAreaProps>`
  font-size: 14px;
  light-height: 21px;
  width: ${(props) => props.width || "inherit"};
  color: ${(props) =>
    props.isError
      ? props.theme.redPrimary
      : props.color || props.theme.black600};
  border: ${(props) =>
    props.isError
      ? `1px solid ${props.theme.redPrimary}`
      : `1px solid ${props.theme.black500}`};
  border-radius: 8px;
  padding: 8px 16px;
  background-color: inherit;
  &:hover:enabled {
    border-color: ${(props) => props.theme.primary};
  }
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.primary};

    color: ${(props) => props.theme.black900};
  }
  &:disabled {
    background-color: ${(props) => props.theme.black400};
  }
  &::placeholder {
    color: ${(props) => props.theme.black600};
  }
`;

export const StyleCodeTextArea = styled.textarea<TextAreaProps>`
  font-size: 14px;
  font-family: Courier;
  font-weight: 400;
  light-height: 21px;
  width: ${(props) => props.width || "inherit"};
  color: ${(props) =>
    props.isError
      ? props.theme.redPrimary
      : props.color || props.theme.black600};
  border: ${(props) =>
    props.isError
      ? `1px solid ${props.theme.redPrimary}`
      : `1px solid ${props.theme.black500}`};
  border-radius: 8px;
  padding: 8px 16px;
  &:hover:enabled {
    border-color: ${(props) => props.theme.primary};
  }
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.primary};

    color: ${(props) => props.theme.black900};
  }
  &:disabled {
    background-color: ${(props) => props.theme.black300};
  }
  &::placeholder {
    color: ${(props) => props.theme.black600};
  }
  background-color: ${(props) => props.theme.black300};
`;
