import React, { useContext } from "react";
import { Badge } from "./Badge";
import { ThemeContext } from "styled-components";

type Props = {
  state: boolean;
};

export const BoolBadge = (props: Props) => {
  const { state } = props;
  const theme = useContext(ThemeContext);

  return (
    <Badge
      style={{
        backgroundColor: state ? theme.green50 : theme.red50,
        color: state ? theme.green800 : theme.red800,
      }}
    >
      {state ? "True" : "False"}
    </Badge>
  );
};
