import { useNavigate } from "react-router";
import { BreadcrumbButton } from "../../../components/elements/button/breadcrumb/BreadcrumbButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Finding } from "../../../types/Finding";
import { ThemeContext } from "styled-components";
import { useContext } from "react";

export const FindingDetailsBreadCrumbs = ({
  finding,
  backUrl,
}: {
  finding?: Finding;
  backUrl: string;
}) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  return (
    <Flex align="center" gap="8px">
      <BreadcrumbButton
        onClick={() => navigate(backUrl)}
        label={"Findings"}
        width="65px"
      />
      <Icon name="chevronRight" size={16} color={theme.black600} />
      <BodyRegular data-testid="ticket-num">Ticket #{finding?.id}</BodyRegular>
    </Flex>
  );
};
