import { Box } from "../../components/elements/box/Box";
import { MultiSelect } from "../../components/elements/dropdowns/MultiSelect";
import { Modal } from "../../components/elements/modal/Modal";
import {
  BodyRegular,
  HeaderSecondary,
  LabelRegular,
} from "../../components/elements/typography/Typography";
import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { useApiProducts } from "../../hooks/queries/productsContext";
import { useApiProjects } from "../../hooks/queries/projectsContext";
import { MultiValue } from "react-select";
import { Option } from "../../components/elements/dropdowns/Dropdown";
import { ReportsTable } from "./ReportsTable";
import { Report, ReportType } from "../../types/Project";
import { Icon } from "../../components/elements/icon/Icon";
import { Illustration } from "../../components/elements/illustrations/Illustration";
import { useScreenWidth } from "../../hooks/utilsHooks";
import {
  SCREEN_BIG_DESKTOP_WIDTH,
  SCREEN_VERY_SMALL_LAPTOP_WIDTH,
} from "../../shared/consts";

export interface ReportItem extends Report {
  projectId: number;
  projectName: string;
  productsId: number[];
  estimatedTime: string;
}

const EmptyState = () => {
  return (
    <div className="text-center">
      <Illustration name="empty" />
      <HeaderSecondary>No Reports Were Selected</HeaderSecondary>
      <BodyRegular>
        Please fill in the fields above and generate reports
      </BodyRegular>
    </div>
  );
};

export const reportTypes: ReportType[] = [
  "Full Report",
  "Executive Report",
  "Retest Report",
  "Attestation letter",
  "other",
];

export const ReportsGenerator = ({ onClose }: { onClose: () => void }) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isVerySmallLaptop = screenWidth < SCREEN_VERY_SMALL_LAPTOP_WIDTH;
  const isVeryBigDesktop = screenWidth > SCREEN_BIG_DESKTOP_WIDTH;

  const {
    data: products,
    isLoading: isLoadingProducts,
    refetch: refetchProducts,
  } = useApiProducts();

  const {
    data: projects,
    isLoading: isLoadingProjects,
    refetch: refetchProjects,
  } = useApiProjects();

  const isLoading = isLoadingProducts || isLoadingProjects;

  const refetch = () => {
    refetchProducts();
    refetchProjects();
  };

  const [selectedTypes, setSelectedTypes] = useState<MultiValue<Option>>();
  const [selectedProducts, setSelectedProducts] =
    useState<MultiValue<Option>>();
  const [selectedProjects, setSelectedProjects] =
    useState<MultiValue<Option>>();

  const [closeMenus, setCloseMenus] = useState<boolean>(false);

  const types = selectedTypes?.map((p) => `${p.value}`) || [];
  const productsId = selectedProducts?.map((p) => parseInt(`${p.value}`)) || [];
  const projectsId = selectedProjects?.map((p) => parseInt(`${p.value}`)) || [];

  const isEmpty = !isLoading && !projects;

  const filteredProjects = projects?.filter(
    (project) =>
      (projectsId.length === 0 || projectsId.includes(project.id)) &&
      (productsId.length === 0 || productsId.includes(project.products[0]))
  );

  const filteredReportTypes = reportTypes.filter(
    (reportType) => types.length === 0 || types.includes(reportType)
  );

  let existingReportsFiltered: ReportItem[] = [];
  filteredProjects?.forEach((project) =>
    project.reports?.forEach((report) => {
      if (types.length === 0 || types.includes(report.type))
        existingReportsFiltered = [
          ...existingReportsFiltered,
          {
            ...report,
            status: report.status ? report.status : "done",
            projectId: project.id,
            projectName: project.name,
            productsId: project.products,
            estimatedTime: "1 day",
          },
        ];
    })
  );

  let nonExistingReports: ReportItem[] = [];
  filteredProjects?.forEach((project) => {
    const existingReportTypes = project.reports.map((report) => report.type);
    filteredReportTypes.forEach((reportType) => {
      if (reportType !== "other" && !existingReportTypes.includes(reportType))
        nonExistingReports = [
          ...nonExistingReports,
          {
            filename: "",
            title: "",
            type: reportType,
            status: "not-exist",
            requested_at: "",
            created_at: "",
            projectId: project.id,
            projectName: project.name,
            productsId: project.products,
            estimatedTime: "1 day",
          },
        ];
    });
  });

  const ModalHeader = () => (
    <div
      className="d-flex align-items-center gap-8"
      style={{ marginBottom: "12px" }}
      onClick={() => setCloseMenus(true)}
    >
      <Icon name="export" color={theme.primary} size={32} />
      <HeaderSecondary>Reports Generator</HeaderSecondary>
    </div>
  );

  return (
    <Modal
      keepOpenOnOutsideClick
      onClose={() => onClose()}
      header={<ModalHeader />}
      width={isVerySmallLaptop ? "98%" : isVeryBigDesktop ? "50%" : "90%"}
    >
      <div data-testid="reports-generator" style={{ height: "660px" }}>
        <div className="d-flex flex-column gap-24">
          <div className="d-flex w-100 gap-24">
            <div className="w-100">
              <LabelRegular>Report Type</LabelRegular>
              <div className="w-100" style={{ marginTop: "8px" }}>
                <MultiSelect
                  placeholder="Select Report Types"
                  itemsNameSingle="type"
                  itemsNameMulti="types"
                  options={reportTypes?.map((v) => ({ label: v, value: v }))}
                  selected={selectedTypes}
                  setSelected={setSelectedTypes}
                  closeMenu={closeMenus}
                  onOpenMenu={() => setCloseMenus(false)}
                  dataTestId="select-types"
                />
              </div>
            </div>
            <div className="w-100">
              <LabelRegular>Products</LabelRegular>
              <div className="w-100" style={{ marginTop: "8px" }}>
                <MultiSelect
                  placeholder="Select Products"
                  itemsNameSingle="product"
                  itemsNameMulti="products"
                  selected={selectedProducts}
                  setSelected={setSelectedProducts}
                  options={
                    products?.map((p) => ({ value: p.id, label: p.name })) || []
                  }
                  closeMenu={closeMenus}
                  onOpenMenu={() => setCloseMenus(false)}
                  dataTestId="select-products"
                />
              </div>
            </div>
            <div className="w-100">
              <LabelRegular>Projects</LabelRegular>
              <div className="w-100" style={{ marginTop: "8px" }}>
                <MultiSelect
                  placeholder="Select Projects"
                  itemsNameSingle="project"
                  itemsNameMulti="projects"
                  selected={selectedProjects}
                  setSelected={setSelectedProjects}
                  options={
                    projects
                      ?.filter(
                        (p) =>
                          productsId.length === 0 ||
                          productsId.includes(p.products[0])
                      )
                      .map((p) => ({ value: p.id, label: p.name })) || []
                  }
                  closeMenu={closeMenus}
                  onOpenMenu={() => setCloseMenus(false)}
                  dataTestId="select-projects"
                />
              </div>
            </div>
          </div>

          <Box style={{ height: "492px" }} onClick={() => setCloseMenus(true)}>
            {isEmpty ? (
              <div className="w-100 d-flex align-items-center justify-content-center">
                <EmptyState />
              </div>
            ) : (
              <ReportsTable
                reports={[...existingReportsFiltered, ...nonExistingReports]}
                refetchReports={refetch}
              />
            )}
          </Box>
        </div>
      </div>
    </Modal>
  );
};
