import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import {
  Checkbox,
  CheckboxState,
} from "../../../components/elements/checkbox/Checkbox";
import { Loading } from "../../../components/elements/loading/Loading";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { Flex } from "../../../components/layouts/flex/Flex";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Filters } from "../Findings";
import { OptionWithCheck } from "../../../components/elements/dropdowns/Dropdown";

type Props = {
  title: string;
  options: OptionWithCheck[];
  onChange: (optionValues: string[]) => void;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetching?: boolean;
  children?: ReactNode;
  newFilters: Filters;
  isSingleOption?: boolean;
};

export const LastFindingsFiltersCategory = (props: Props) => {
  const {
    title,
    options,
    onChange,
    hasNextPage,
    fetchNextPage,
    isFetching,
    children,
    newFilters,
    isSingleOption = false,
  } = props;

  const theme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(true);

  const [selected, setSelected] = useState<string[]>(
    options.filter((x) => x.checked).map((x) => x.value.toString())
  );

  // Handle reset from main issues filters pane
  useEffect(() => {
    if (Object.keys(newFilters).length === 0) setSelected([]);
  }, [newFilters]);

  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(
    observerElemForFetchPage,
    hasNextPage || false,
    fetchNextPage || console.log
  );

  const handleOnChange = (state: CheckboxState, optionValue: string) => {
    var tempSelected = [...selected];
    if (state === "checked") {
      if (isSingleOption) tempSelected = [];
      tempSelected = [...tempSelected, optionValue];
    } else {
      tempSelected = tempSelected.filter((x) => x !== optionValue);
    }
    onChange(tempSelected);
    setSelected(tempSelected);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <Flex
          w100
          align="center"
          justify="between"
          style={{ padding: "24px 0", color: theme.black600 }}
        >
          <LabelRegular>{title}</LabelRegular>
          {selected.length > 0 && (
            <TextButton
              label="Reset"
              onClick={() => {
                setSelected([]);
                onChange([]);
              }}
            />
          )}
          <IconButton
            iconName="chevronDown"
            color={theme.black600}
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </Flex>
      </div>
      {children ? (
        isOpen && children
      ) : (
        <div
          style={{ maxHeight: isOpen ? "200px" : "0px", overflowY: "scroll" }}
        >
          {options?.map((option) => (
            <div
              key={`category-${title}-${option.value}-${selected.length}`}
              className="d-flex align-items-center"
            >
              <Checkbox
                key={`${selected}-${option.value}`}
                hasMidCheck={false}
                onChange={(state) =>
                  handleOnChange(state, option.value.toString())
                }
                state={
                  selected.includes(option.value.toString())
                    ? "checked"
                    : "unchecked"
                }
                label={option.label}
                dataTestId={`${option.label}-checkbox`}
              />
            </div>
          ))}
          <div
            className="loader d-flex justify-content-center"
            ref={observerElemForFetchPage}
          >
            {isFetching ? <Loading /> : ""}
          </div>
        </div>
      )}
      <SeparatorHorizontal style={{ marginTop: "24px" }} />
    </div>
  );
};
