import React, { useState } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { LabelRegular } from "../../../../components/elements/typography/Typography";
import { RequiredField } from "../../../../components/elements/requiredField/RequiredField";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import {
  objectsToOptions,
  productsToOptionsWithCustomers,
} from "../../../../shared/formUtils";
import { TagBadge } from "../../../../components/composed/tagsLine/TagBadge";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import { MainButton } from "../../../../components/elements/button/main/MainButton";
import useToastContext from "../../../../hooks/toastHook";
import { CtiItem } from "../../../../types/CtiItem";
import { useApiCustomers } from "../../../../hooks/queries/customersContext";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { useApiUpdateCtiItem } from "../../../../hooks/queries/ctiItemsContext";

type Props = {
  selectedCtiItem: CtiItem;
};

export const CtiEditTab = (props: Props) => {
  const { selectedCtiItem } = props;
  const addToast = useToastContext();
  const { data: customers } = useApiCustomers();
  const { data: products } = useApiProducts({ "admin-mode": true });
  const { mutate: updateCtiItem, isLoading } = useApiUpdateCtiItem();

  const [updatedCtiItem, setUpdatedCtiItem] =
    useState<CtiItem>(selectedCtiItem);

  const updatePost = () => {
    updateCtiItem({
      ...updatedCtiItem,
      onSuccessCallback() {
        addToast({
          type: "success",
          message: "Updated CTI Post!",
        });
      },
      onErrorCallback(error) {
        addToast({
          type: "error",
          message: `Failed to update CTI Post, Error: ${error.message}`,
        });
      },
    });
  };
  return (
    <Flex h100 w100 column gap="8px" padding="16px">
      <Flex column gap="8px">
        <Flex>
          <LabelRegular>Customers</LabelRegular>
          <RequiredField />
        </Flex>
        <Dropdown
          searchable
          placeholder="Select customers"
          options={customers ? objectsToOptions(customers) : []}
          onChange={(op) => {
            if (op)
              setUpdatedCtiItem({
                ...updatedCtiItem,
                customers: [...updatedCtiItem.customers, op.value as number],
              });
          }}
          variant="border"
          size="medium"
        />
        {updatedCtiItem.customers &&
          updatedCtiItem.customers.map?.((customer) => (
            <TagBadge
              key={customer}
              option={{
                label: customers?.find((f) => f.id === customer)?.name || "",
                value: customer,
              }}
              onDeleteOption={(op) => {
                if (op)
                  setUpdatedCtiItem({
                    ...updatedCtiItem,
                    customers: updatedCtiItem.customers.filter(
                      (customer) => op.value !== customer
                    ),
                  });
              }}
              isEditable={true}
            />
          ))}
      </Flex>
      <Flex column gap="8px">
        <Flex>
          <LabelRegular>Products</LabelRegular>
          <RequiredField />
        </Flex>
        <Dropdown
          searchable
          placeholder="Select products"
          disabled={updatedCtiItem.customers.length === 0}
          options={
            products
              ? productsToOptionsWithCustomers(
                  products.filter((p) =>
                    updatedCtiItem.customers.includes(p.customer)
                  ),
                  customers || []
                )
              : []
          }
          onChange={(op) => {
            if (op)
              setUpdatedCtiItem({
                ...updatedCtiItem,
                affected_products: [
                  ...updatedCtiItem.affected_products,
                  op.value as number,
                ],
              });
          }}
          variant="border"
          size="medium"
        />
        {updatedCtiItem.affected_products &&
          updatedCtiItem.affected_products.map?.((product) => (
            <TagBadge
              key={product}
              option={{
                label: products?.find((p) => p.id === product)?.name || "",
                value: product,
              }}
              onDeleteOption={(op) => {
                if (op)
                  setUpdatedCtiItem({
                    ...updatedCtiItem,
                    affected_products: updatedCtiItem.affected_products.filter(
                      (product) => op.value !== product
                    ),
                  });
              }}
              isEditable={true}
            />
          ))}
      </Flex>

      <Flex>
        <LabelRegular>Title</LabelRegular>
        <RequiredField />
      </Flex>
      <InputText
        width={"100%"}
        onChange={(event) => {
          setUpdatedCtiItem({
            ...updatedCtiItem,
            title: event.target.value,
          });
        }}
        value={updatedCtiItem.title}
      />
      <Flex>
        <LabelRegular>Link to Article</LabelRegular>
        <RequiredField />
      </Flex>
      <InputText
        width={"100%"}
        onChange={(event) => {
          setUpdatedCtiItem({
            ...updatedCtiItem,
            link: event.target.value,
          });
        }}
        value={updatedCtiItem.link}
      />
      <Flex>
        <LabelRegular>Link to Image</LabelRegular>
        <RequiredField />
      </Flex>
      <InputText
        width={"100%"}
        onChange={(event) => {
          setUpdatedCtiItem({
            ...updatedCtiItem,
            image: event.target.value,
          });
        }}
        value={updatedCtiItem.image}
      />
      <MainButton
        variant="primary"
        label="Update Post"
        style={{ marginTop: "8px" }}
        inProgress={isLoading}
        onClick={updatePost}
      />
    </Flex>
  );
};
