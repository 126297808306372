import React, { useContext } from "react";
import { FindingLog } from "../../../types/FindingLog";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { StatusLegend } from "../../../components/composed/status/StatusLegend";
import { FindingStatus } from "../../../types/Finding";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { CardHeader } from "./CardHeader";

type Props = {
  findingLog: FindingLog;
};

export function FindingLogStatusUpdatedCard({ findingLog }: Props) {
  const theme = useContext(ThemeContext);
  return (
    <Box>
      <Flex column gap="16px">
        <CardHeader
          userName={findingLog.actor.name}
          avatarUrl={findingLog.actor.avatar_url}
          createdAt={findingLog.created_at}
        />
        <Flex align="center" gap="1px" flexWrap>
          <LabelRegular
            color={theme.black600}
          >{`${findingLog.field} Changed From`}</LabelRegular>
          <StatusLegend status={findingLog.old_value as FindingStatus} />
          <LabelRegular color={theme.black600} className="ms-1">
            To
          </LabelRegular>
          <StatusLegend status={findingLog.new_value as FindingStatus} />
        </Flex>
        {!!findingLog.comment && <SeparatorHorizontal />}
        {!!findingLog.comment && (
          <BodyRegular>{findingLog.comment as string}</BodyRegular>
        )}
      </Flex>
    </Box>
  );
}
