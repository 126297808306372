import React from "react";
import { Finding } from "../../../types/Finding";
import { PushFindingJira } from "./pushFindingJira/PushFindingJira";
import { useApiMe } from "../../../hooks/queries/meContext";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { WorkatoSyncButton } from "./workatoSync/WorkatoSyncButton";

type Props = {
  finding: Finding;
};

export const FindingSync = ({ finding }: Props) => {
  const { data: me } = useApiMe();

  const hasMappingProjects =
    (me?.customer?.jira_projects_mapping?.filter((p) => p.enabled)?.length ||
      0) > 0;

  if (me?.customer?.is_workato_connected && finding.external_id) {
    return (
      <BodyRegular
        className="text-truncate"
        title={`Has integration to external system, ticket ID: ${finding.external_id}`}
      >
        {finding.external_id}
      </BodyRegular>
    );
  }

  if (
    me?.customer?.is_workato_connected &&
    !finding.customer_jira_issue_key &&
    !hasMappingProjects
  ) {
    return <WorkatoSyncButton findingId={finding.id} />;
  }

  return (
    <PushFindingJira
      jiraIssueKey={finding?.customer_jira_issue_key || undefined}
      customerJiraUrl={me?.customer?.jira_details?.url}
      isDisabled={!!finding.customer_jira_issue_key || !hasMappingProjects}
      finding={finding}
    />
  );
};
