import React from "react";
import { Box } from "../../../../components/elements/box/Box";
import {
  HeaderSubBold,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { AlertBannerReceiver } from "../../../../types/AlertBannerMessage";
import { useApiSingleAlertBannerMessage } from "../../../../hooks/queries/alertBannerMessageContext";
import { getDate } from "../../../../shared/helper";
import { useApiCustomers } from "../../../../hooks/queries/customersContext";

type Props = {
  alertBannerReceiver: AlertBannerReceiver;
};

export const DismissedUser = (props: Props) => {
  const { alertBannerReceiver } = props;
  const { data: alertBannerMessage } = useApiSingleAlertBannerMessage(
    alertBannerReceiver.alert_banner_message
  );
  const { data: customers } = useApiCustomers();
  const customerName = customers?.find(
    (c) => c.id === alertBannerReceiver.customer
  )?.name;
  return (
    <Flex column w100 padding="16px">
      <Box>
        <Flex column gap="8px">
          <HeaderSubBold>
            {alertBannerMessage?.creator
              ? `${alertBannerMessage.creator["first_name"]} ${alertBannerMessage.creator["last_name"]}`
              : "N/A"}
          </HeaderSubBold>
          <Flex justify="between">
            <LabelRegular>{customerName}</LabelRegular>
            <LabelRegular>
              {alertBannerReceiver.dismissed_date
                ? getDate(alertBannerReceiver.dismissed_date)
                : "N/A"}
            </LabelRegular>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
