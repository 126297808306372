import { FilterDatePicker } from "./FilterDatePicker";
import { FilterColumnType } from "../FiltersUtils";
import { FilterOptions } from "./FilterOptions";
import { FilterTextInput } from "./FilterTextInput";

type Props = {
  column: FilterColumnType | undefined;
  onChange: (value: string) => void;
  filterValue: string;
};

export const FilterValue = (props: Props) => {
  const { column, onChange, filterValue } = props;
  return (
    <div className="w-100">
      {column?.type === "date" && (
        <FilterDatePicker filterValue={filterValue} onChange={onChange} />
      )}
      {column?.type.startsWith("options") && (
        <FilterOptions
          filterColumn={column}
          onChange={onChange}
          filterValue={filterValue}
        />
      )}

      {["string", "number", "list", "is_and_not"].includes(
        column?.type || ""
      ) && (
        <FilterTextInput
          filterValue={filterValue}
          onChange={onChange}
          type={
            ["number", "is_and_not"].includes(column?.type || "string")
              ? "number"
              : "text"
          }
        />
      )}
    </div>
  );
};
