import { CSSProperties, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../icon/Icon";
import { StyleLinkButton } from "./LinkButton.style";
import { BodyRegular } from "../../typography/Typography";

type Props = {
  label: string;
  iconName?: string;
  iconColor?: string;
  size?: "small" | "medium" | "large";
  onClick?: () => void;
  inProgress?: boolean;
  disabled?: boolean;
  color?: string;
  dataTestId?: string;
  noPadding?: boolean;
  style?: CSSProperties;
};

export const LinkButton = (props: Props) => {
  const {
    label,
    size = "medium",
    onClick,
    inProgress = false,
    disabled = false,
    iconName,
    iconColor,
    color,
    noPadding,
    style,
  } = props;
  const theme = useContext(ThemeContext);

  return (
    <StyleLinkButton
      onClick={onClick}
      disabled={disabled || inProgress}
      size={size}
      data-testid={props.dataTestId}
      noPadding={noPadding}
      style={style}
      color={color}
    >
      {!!iconName && (
        <>
          {!inProgress ? (
            <Icon
              name={iconName}
              size={24}
              color={
                disabled ? theme.textSub : iconColor || color || theme.primary
              }
            />
          ) : (
            <Icon name={"spinner"} size={24} color={color || theme.textSub} />
          )}
        </>
      )}
      <BodyRegular
        style={{
          color: disabled ? theme.textSub : "inherit",
          fontWeight: "inherit",
        }}
      >
        {label}
      </BodyRegular>
    </StyleLinkButton>
  );
};
