import { FindingLog } from "../../../types/FindingLog";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { CardHeader } from "./CardHeader";
import { useApiAssetsGetById } from "../../../hooks/queries/assetsContext";
import { useSearchParams } from "react-router-dom";

type Props = {
  findingLog: FindingLog;
};

export function FindingLogAffectedAssetsUpdatedCard({ findingLog }: Props) {
  const [searchParams] = useSearchParams();

  const params =
    searchParams.get("mode") === "edit" ? { "admin-mode": true } : {};

  const newVal: number[] = JSON.parse(`${findingLog.new_value}`);
  const oldVal: number[] = JSON.parse(`${findingLog.old_value}`);
  const addedAssetIds = newVal.filter((id) => !oldVal.includes(id));
  const { data: addedAssets } = useApiAssetsGetById(
    addedAssetIds,
    params,
    !!addedAssetIds.length
  );

  if (!addedAssets?.length) return null;

  return (
    <Box>
      <Flex column gap="16px">
        <CardHeader
          userName={findingLog.actor.name}
          avatarUrl={findingLog.actor.avatar_url}
          createdAt={findingLog.created_at}
        />
        {!!addedAssets?.length && (
          <Flex gap="1px" column>
            <LabelRegular>New affected assets identified:</LabelRegular>
            <Flex column>
              {addedAssets.map((a) => (
                <LabelBold>{a.name}</LabelBold>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
