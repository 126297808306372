import { useSearchParams } from "react-router-dom";
import { OnboardingAlert } from "./OnboardingAlert";
import { UnverifiedAssetsAlert } from "./UnverifiedAssetsAlert";
import { LicenseAlerts } from "./LicenseAlerts";
import { IS_DEMO_ENV } from "../../hooks/queries/utils";
import { SystemAlerts } from "./SystemAlerts";

type AlertsBannersProps = {
  onRender?: () => void;
};
export const AlertsBanners = ({ onRender }: AlertsBannersProps) => {
  // Turn off alerts if tour is on, because it causes UI issues
  const [searchParams] = useSearchParams();
  const isTourOn = searchParams.get("tour");
  if (!!isTourOn) return null;

  return (
    <>
      {/* ALL LICENSE ISSUES */}
      <LicenseAlerts onRender={onRender} />

      {/* ONBOARDING NOT 100% DONE */}
      {!IS_DEMO_ENV && <OnboardingAlert onRender={onRender} />}

      {/* UNVERIFIED ASSETS */}
      <UnverifiedAssetsAlert onRender={onRender} />

      {/* MESSAGES SENT FROM SALES OR WASP TEAM*/}
      <SystemAlerts onRender={onRender} />
    </>
  );
};
