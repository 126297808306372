import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../../components/elements/icon/Icon";
import {
  HeaderSecondary,
  HeaderSubBold,
} from "../../../../components/elements/typography/Typography";
import { calculateDaysGapFromNow } from "../../../../shared/helper";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { getSLATitle } from "../../../../shared/findingsHelper";
import { Flex } from "../../../../components/layouts/flex/Flex";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  headerStyle: "secondary" | "sub";
  width?: string;
};

export const SlaItem = (props: Props) => {
  const { finding, noSeparator = false, headerStyle, width } = props;
  const theme = useContext(ThemeContext);

  const sla_days = finding?.sla_expires_at
    ? calculateDaysGapFromNow(new Date(finding.sla_expires_at))
    : null;

  const doneStatuses = ["fixed", "acceptable_risk", "dismissed"];
  const sla_title = doneStatuses.includes(`${finding?.status}`)
    ? "Done"
    : getSLATitle(sla_days);

  return (
    <Section
      title="SLA"
      noSeparator={noSeparator}
      headerStyle={headerStyle === "secondary" ? "body" : "label"}
      width={width}
    >
      <Icon name="sla" size={32} color={theme.primary} />
      <Flex align="center">
        {headerStyle === "secondary" ? (
          <HeaderSecondary
            className="text-truncate w-100"
            style={{ color: theme.black700 }}
          >
            {sla_title}
          </HeaderSecondary>
        ) : (
          <></>
        )}
        {headerStyle === "sub" ? (
          <HeaderSubBold
            className="text-truncate w-100"
            style={{ color: theme.black700 }}
          >
            {sla_title}
          </HeaderSubBold>
        ) : (
          <></>
        )}
      </Flex>
    </Section>
  );
};
