import { Assignee } from "../../../../components/composed/assignee/Assignee";
import { AdminFindingEdit, Finding, JiraUser } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { SuperUsersDropdown } from "../../../../components/composed/dropdowns/SuperUsersDropdown";
import { OpUser } from "../../../../hooks/queries/usersContext";
import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router";
import { useUpdateFindingInPlace } from "../../../../shared/formUtils";

type Props = {
  finding: Finding | undefined | null;
  isEditable?: boolean;
  noSeparator?: boolean;
  width?: string;

  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const IssuedByItem = (props: Props) => {
  const {
    finding,
    noSeparator = false,
    width,
    isEditable,
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const { id: updateFindingId } = useParams();
  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  const onAssigneeChangedHandler = (opUser: OpUser) => {
    var jiraUser: JiraUser = {
      jira_id: "",
      display_name: opUser.first_name + " " + opUser.last_name,
      email: opUser.email,
      avatar_url: opUser.avatar_url,
    };
    setEditableUpdateFinding &&
      setEditableUpdateFinding((prev) => ({
        ...prev,
        op_jira_assignee: jiraUser,
      }));
    updateInPlace({ op_jira_assignee: jiraUser });
    setCachedFindingData &&
      setCachedFindingData({ op_jira_assignee: jiraUser });
  };

  return (
    <Section title="Issued By" noSeparator={noSeparator} width={width}>
      {!isEditable ? (
        <Assignee
          name={finding?.op_jira_assignee?.display_name}
          imageSrc={finding?.op_jira_assignee?.avatar_url}
        />
      ) : (
        <SuperUsersDropdown
          placeholder="Select an option"
          valueByEmail={editableUpdateFindingData?.op_jira_assignee?.email}
          size="medium"
          onSelect={(opUser) => opUser && onAssigneeChangedHandler(opUser)}
          variant="outline"
          queryStatus={
            changedField === "op_jira_assignee" ? queryStatus : undefined
          }
          disabled={queryStatus !== "idle"}
        />
      )}
    </Section>
  );
};
