import { useState } from "react";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { Modal } from "../../../components/elements/modal/Modal";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import {
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { useApiMe } from "../../../hooks/queries/meContext";
import { stringsListToSelectOptions } from "../../../shared/helper";
import { Flex } from "../../../components/layouts/flex/Flex";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import {
  useApiCreateTransaction,
  useApiDeleteTransaction,
  useApiUpdateTransaction,
} from "../../../hooks/queries/transactionsContext";
import { Transaction, TransactionParams } from "../../../types/Transaction";
import useToastContext from "../../../hooks/toastHook";
import { MultiSelect } from "../../../components/elements/dropdowns/MultiSelect";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";

const activityOptions = [
  "penetration test",
  "incident response",
  "research",
  "code review",
];

type Props = {
  onClose: () => void;
  editTransaction: Transaction | undefined;
};

export const AddTransactionModal = (props: Props) => {
  const { onClose, editTransaction } = props;

  const addToast = useToastContext();

  const { data: products } = useApiProducts();
  const { data: me } = useApiMe();
  const { mutate: createTransaction } = useApiCreateTransaction();
  const { mutate: updateTransaction } = useApiUpdateTransaction();
  const { mutate: deleteTransaction } = useApiDeleteTransaction();

  const defaultFormValues: TransactionParams = {
    products: [],
    activity: "",
    description: "",
    credits_amount: 0,
    customer: me?.customer.id,
  };

  const initFormValues = !!editTransaction
    ? {
        products: editTransaction.products,
        activity: editTransaction.activity,
        description: editTransaction.description,
        credits_amount: editTransaction.credits_amount,
        customer: me?.customer.id,
      }
    : defaultFormValues;

  const [formValues, setFormValues] =
    useState<TransactionParams>(initFormValues);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [closeMenus, setCloseMenus] = useState<boolean>(false);

  const submitForm = () => {
    if (showConfirmDelete && !!editTransaction)
      deleteTransaction({
        transactionData: formValues,
        transactionId: editTransaction.id,
        onErrorCallback: (err) => {
          addToast({
            message: `Failed to delete credit pack - ${err}`,
            type: "error",
          });
        },
        onSuccessCallback: () => {
          addToast({
            message: "Credit pack deleted successfully",
            type: "success",
          });
          onClose();
        },
      });
    else if (showConfirmation && !!editTransaction)
      updateTransaction({
        transactionData: formValues,
        transactionId: editTransaction.id,
        onErrorCallback: (err) => {
          addToast({
            message: `Failed to update transaction - ${err}`,
            type: "error",
          });
        },
        onSuccessCallback: () => {
          addToast({
            message: "Transaction updated successfully",
            type: "success",
          });
          onClose();
        },
      });
    else
      createTransaction({
        ...formValues,
        onErrorCallback: (err) => {
          addToast({
            message: `Failed to create transaction - ${err}`,
            type: "error",
          });
        },
        onSuccessCallback: () => {
          addToast({
            message: "Transaction created successfully",
            type: "success",
          });
          onClose();
        },
      });
  };
  const disabled =
    !formValues.activity || !formValues.credits_amount || showConfirmation;
  const header = `${editTransaction ? "Edit" : "Add"} Transaction for ${
    me?.customer.name
  }`;

  return (
    <Modal onClose={onClose} header={header} width="500px">
      <Flex column gap="24px" onClick={() => setCloseMenus(true)}>
        <Flex column gap="8px">
          <LabelBold>Activity</LabelBold>
          <Dropdown
            value={
              formValues.activity
                ? { label: formValues.activity, value: formValues.activity }
                : { label: "Select an Activity", value: "" }
            }
            onChange={(opt) =>
              setFormValues((prev) => ({ ...prev, activity: `${opt?.value}` }))
            }
            options={stringsListToSelectOptions(activityOptions)}
            variant="border"
            width="100%"
            disabled={showConfirmation}
          />
        </Flex>
        <Flex column gap="8px">
          <Flex>
            <LabelBold>Cost</LabelBold>
            <LabelRegular>
              {" "}
              - Amount of credits this activity costs
            </LabelRegular>
          </Flex>
          <InputText
            type="number"
            value={`${formValues.credits_amount}`}
            onChange={(e) =>
              parseInt(`${e.target.value}`) > 0 &&
              setFormValues((prev) => ({
                ...prev,
                credits_amount: parseInt(`${e.target.value}`),
              }))
            }
            width="742px"
            disabled={showConfirmation}
          />
        </Flex>
        <Flex column gap="8px">
          <LabelBold>Product</LabelBold>
          <MultiSelect
            placeholder="Select Products"
            selected={formValues.products?.map((prodId) => ({
              label: products?.find((p) => p.id === prodId)?.name || "",
              value: prodId,
            }))}
            onSelect={(opts) =>
              setFormValues((prev) => ({
                ...prev,
                products: opts.map((o) => parseInt(`${o.value}`)),
              }))
            }
            options={products?.map((p) => ({ value: p.id, label: p.name }))}
            clearable
            variant="border"
            width="100%"
            disabled={showConfirmation}
            isBadged
            onOpenMenu={() => setCloseMenus(false)}
            closeMenu={closeMenus}
          />
        </Flex>
        <Flex column gap="8px">
          <Flex justify="between">
            <LabelBold>Note</LabelBold>
            <LabelRegular>Optional</LabelRegular>
          </Flex>

          <InputText
            value={formValues.description}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
            width={`${500 - 48}px`}
          />
        </Flex>

        <Flex gap="8px" justify="end">
          <SecondaryButton
            disabled={showConfirmation}
            label="Cancel"
            onClick={onClose}
            size="medium"
          />
          {editTransaction && (
            <SecondaryButton
              variant="danger"
              label="Delete Transaction"
              onClick={() => {
                setShowConfirmDelete(true);
              }}
              size="medium"
            />
          )}
          <MainButton
            disabled={disabled}
            label={editTransaction ? "Save Changes" : "Add"}
            onClick={() => setShowConfirmation(true)}
            size="medium"
          />
        </Flex>

        {showConfirmation && (
          <Flex column gap="12px">
            <Flex w100 justify="center">
              <LabelBold>Please confirm the transaction</LabelBold>
            </Flex>
            <Flex gap="8px">
              <LabelBold>Activity: </LabelBold>
              <LabelRegular>{formValues.activity}</LabelRegular>
            </Flex>
            <Flex gap="8px">
              <LabelBold>Cost: </LabelBold>
              <LabelRegular>{formValues.credits_amount}</LabelRegular>
            </Flex>
            <Flex gap="8px">
              <LabelBold>Products: </LabelBold>
              <BadgesLine
                noWrap
                badges={
                  products
                    ?.filter((p) => formValues.products?.includes(p.id))
                    ?.map((p) => p.name) || []
                }
              />
            </Flex>
            <Flex gap="8px">
              <LabelBold>Note: </LabelBold>
              <LabelRegular>{formValues.description}</LabelRegular>
            </Flex>
            <SecondaryButton
              label="Edit Transaction"
              onClick={() => setShowConfirmation(false)}
              size="medium"
            />
            <MainButton
              label="Confirm Transaction"
              onClick={submitForm}
              size="medium"
            />
          </Flex>
        )}
        {showConfirmDelete && (
          <Flex column gap="12px">
            <Flex w100 justify="center">
              <LabelBold>Please confirm deleting transaction</LabelBold>
            </Flex>
            <SecondaryButton
              variant="danger"
              label="Confirm Delete Transaction"
              onClick={submitForm}
              size="medium"
            />
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
