import { Flex } from "../../../components/layouts/flex/Flex";
import {
  HeaderSecondary,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { Finding } from "../../../types/Finding";
import { Chart } from "react-chartjs-2";
import { Chart as BaseChart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { IMPACTS_REPORT_FORMAT, SEVERITIES } from "../../../shared/consts";
import { BubbleDataPoint } from "chart.js";
import { light } from "../../../shared/theme";

type Props = {
  findings: Finding[];
};

const BUBBLE_RADIUS_SCALE = 10;
BaseChart.register(...registerables);

export const ResultsAtGlance = ({ findings }: Props) => {
  const rmax = findings.length;
  const data = findings.map((finding) => {
    return {
      x: finding.exploitability,
      y: finding.impact,
      r:
        findings.filter(
          (f) =>
            f.exploitability === finding.exploitability &&
            f.impact === finding.impact
        ).length * BUBBLE_RADIUS_SCALE,
      rmax: rmax * BUBBLE_RADIUS_SCALE,
    };
  });

  return (
    <Flex
      column
      w100
      gap="8px"
      className="mt-5"
      style={{ pageBreakAfter: "always" }}
    >
      <HeaderSecondary color={light.blue800} style={{ textTransform: "none" }}>
        Results at a glance
      </HeaderSecondary>
      <Flex column gap="16px" justify="center" align="center" w100>
        <HeaderSubBold>Heatmap</HeaderSubBold>
        <Chart
          id="bubble-chart"
          type="bubble"
          height={170}
          plugins={[ChartDataLabels]}
          options={{
            layout: {
              padding: {
                top: 60,
                bottom: 10,
                left: 10,
                right: 60,
              },
            },

            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    var value = context.dataset.data[
                      context.dataIndex
                    ] as BubbleDataPoint;

                    const num_of_findings = value.r / BUBBLE_RADIUS_SCALE;
                    let label =
                      " " +
                      num_of_findings.toString() +
                      " finding" +
                      (num_of_findings > 1 ? "s" : "");

                    return label;
                  },
                },
              },
              datalabels: {
                borderRadius: 10,
                color: "white",
                anchor: "center",
                align: "center",
                font: {
                  weight: "bold",
                },
                formatter: function (value) {
                  return Math.round(value.r / BUBBLE_RADIUS_SCALE);
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Exploitability",
                  color: "black",
                },
                ticks: {
                  callback: function (value, index, ticks) {
                    return SEVERITIES[parseInt(value.toString())];
                  },
                  stepSize: 1,
                  color: "black",
                },
                suggestedMin: 0,
                suggestedMax: 4,
              },
              y: {
                title: {
                  display: true,
                  text: "Impact",
                  color: "black",
                },
                suggestedMin: 0,
                suggestedMax: 3,
                ticks: {
                  color: "black",
                  stepSize: 1,

                  callback: function (value, index, ticks) {
                    return IMPACTS_REPORT_FORMAT[parseInt(value.toString())];
                  },
                },
              },
            },
          }}
          data={{
            datasets: [
              {
                data: data,
                backgroundColor: "black",
              },
            ],
          }}
        />
        <HeaderSubBold className="mt-4">Overall Risk Analysis</HeaderSubBold>

        <Chart
          type="bar"
          height={120}
          options={{
            // @ts-ignore
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                title: {
                  display: true,
                  text: "Number of Findings",
                  color: "black",
                },
                ticks: {
                  stepSize: 1,
                },
                min: 0,
              },
            },
          }}
          data={{
            labels: Object.values(SEVERITIES),

            datasets: [
              {
                type: "bar",
                data: [
                  findings.filter((finding) => finding.overall_risk === 0)
                    .length,
                  findings.filter((finding) => finding.overall_risk === 1)
                    .length,
                  findings.filter((finding) => finding.overall_risk === 2)
                    .length,
                  findings.filter((finding) => finding.overall_risk === 3)
                    .length,
                  findings.filter((finding) => finding.overall_risk === 4)
                    .length,
                ],
                backgroundColor: [
                  light.grayBluePrimary,
                  light.lightBluePrimary,
                  light.yellowPrimary,
                  light.orangePrimary,
                  light.redPrimary,
                ],
              },
            ],
          }}
        />
      </Flex>
    </Flex>
  );
};
