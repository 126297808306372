import React, { useState } from "react";
import { useParams } from "react-router";

import { useApiSingleFinding } from "../../hooks/queries/findingContext";
import { PageLayout } from "./generator/PageLayout";
import { WelcomeBanner } from "../../components/composed/banner/WelcomeBanner";

import { Flex } from "../../components/layouts/flex/Flex";
import { BodyBold } from "../../components/elements/typography/Typography";
import { useCountDown, usePrint, useWaitLoading } from "./generator/hooks";
import { Modal } from "../../components/elements/modal/Modal";
import { FindingPage } from "./fullReport/FindingPage";

type Props = {};
const WAITING_TIME = 3; //seconds
export const SingleFindingReport = (props: Props) => {
  const { id: findingId } = useParams();

  const { data: finding, isFetching } = useApiSingleFinding(
    parseInt(findingId || "0")
  );

  const [countdown, setCountdown] = useState<number>(WAITING_TIME);
  const [loadingData, setLoadingData] = useState(true);

  const isPageLoaded = Boolean(finding && !isFetching);
  useWaitLoading(WAITING_TIME, () => setLoadingData(false), isPageLoaded);

  const isReadyToPrint = !!(!loadingData && finding);
  usePrint(isReadyToPrint, `Finding-${finding?.id}`);
  useCountDown(setCountdown);

  if (isFetching) {
    return <WelcomeBanner />;
  }

  return (
    <PageLayout>
      {loadingData && (
        <Modal onClose={() => setLoadingData(false)} hideCloseIcon>
          <Flex align="center" gap="6px">
            <BodyBold>{`Generating PDF report in ${
              countdown > 0 ? countdown : "just a sec"
            }...`}</BodyBold>
          </Flex>
        </Modal>
      )}
      {finding && <FindingPage finding={finding} index={0} />}
    </PageLayout>
  );
};
