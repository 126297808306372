import { useContext } from "react";
import { SingleValue } from "react-select";
import { ThemeContext } from "styled-components";
import { useApiSwitchCustomer } from "../../../hooks/queries/accountsContext";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import { Me } from "../../../types/Me";
import { Dropdown, Option } from "../../elements/dropdowns/Dropdown";
import useToastContext from "../../../hooks/toastHook";

const CustomerSelect = ({ me }: { me: Me }) => {
  const theme = useContext(ThemeContext);
  const { data: customers } = useApiCustomers();
  const { mutate: mutateAccount } = useApiSwitchCustomer();
  const addToast = useToastContext();
  const switchCustomer = async (customerOption: SingleValue<Option>) => {
    const customerId = customerOption?.value.toString();
    mutateAccount({
      accountId: me.account_id,
      customerId: parseInt(customerId || ""),
      onSuccessCallback: () => {
        addToast({
          message: `You now belong to "${customerOption?.label}" organization, Mazel Tov Abal'e 🥳`,
          type: "success",
        });
        setTimeout(() => window.location.reload(), 1000);
      },
      onErrorCallback: (error: Error) =>
        addToast({ message: error.message, type: "error" }),
    });
  };

  const onChangeHandler = (option: SingleValue<Option>) =>
    switchCustomer(option);

  return (
    <div>
      <Dropdown
        searchable
        onChange={onChangeHandler}
        options={customers
          ?.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
          .filter((c) => c.license?.type !== "trial" || c.is_trial_approved) // Filter out unapproved trial customers
          .map((c) => ({ value: c.id, label: c.name }))}
        value={{ label: me?.customer.name, value: me?.customer.id }}
        color={theme.white100}
        styles={{
          singleValue: (base: any) => ({
            ...base,
            color: theme.white100,
          }),
        }}
      />
    </div>
  );
};

export default CustomerSelect;
