import { Dispatch, SetStateAction, useState } from "react";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { Modal } from "../../../components/elements/modal/Modal";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ProjectRequestStatus } from "../../../types/Project";
import {
  ProjectParams,
  useApiCreateProject,
} from "../../../hooks/queries/projectsContext";
import { ProjectRequestParams } from "../../../hooks/queries/projectRequestsContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import useToastContext from "../../../hooks/toastHook";
import { useNavigate } from "react-router";
import { ProjectRangeCalendar } from "./ProjectRangeCalendar";
import { getIsoString } from "../../../shared/helper";

type Props = {
  applyForm: (status: ProjectRequestStatus) => void;
  onClose: () => void;
  projectRequestId: number;
  formValues: ProjectRequestParams;
  setFormValues: Dispatch<SetStateAction<ProjectRequestParams>>;
};

export const CreateProjectModal = (props: Props) => {
  const { onClose, formValues, applyForm, projectRequestId, setFormValues } =
    props;
  const addToast = useToastContext();
  const navigate = useNavigate();
  const { mutate: createProject, isLoading } = useApiCreateProject();
  const { data: me } = useApiMe();

  const [projectName, setProjectName] = useState<string>("");
  const [opJiraKey, setOpJiraKey] = useState<string>("");

  const createProjectFromRequest = () => {
    let tempProject: ProjectParams = {
      name: projectName,
      start: getIsoString(formValues.start_date),
      end: getIsoString(formValues.end_date),
      project_request: projectRequestId,
      status: "created",
      assets: formValues.included_assets,
      customer: me?.customer.id || 0,
      opinnovate_poc: {
        name: me?.name || "",
        email: me?.email || "",
        role: me?.role || "",
      },
      jira_project: opJiraKey,
      onErrorCallback: (error) =>
        addToast({ type: "error", message: error.message }),
      onSuccessCallback: () => {
        applyForm("done");
        addToast({
          type: "success",
          message: `Project ${projectName} successfully created`,
        });
        navigate("/projects");
      },
    };
    createProject(tempProject);
  };

  return (
    <Modal width="414px" onClose={onClose}>
      <Flex column w100 gap="24px">
        <HeaderSecondary>Create a new Project</HeaderSecondary>
        <Flex column gap="8px">
          <BodyRegular>Please provide a project name</BodyRegular>
          <InputText
            onChange={(e) => setProjectName(e.target.value)}
            value={projectName}
          />
        </Flex>

        <Flex column gap="8px">
          <BodyRegular>Please enter scan Dates</BodyRegular>
          <ProjectRangeCalendar
            mainFormValues={formValues}
            setMainFormValues={setFormValues}
          />
        </Flex>

        <Flex column gap="8px">
          <BodyRegular>Please enter OP jira project key</BodyRegular>
          <InputText
            onChange={(e) => setOpJiraKey(e.target.value)}
            value={opJiraKey}
          />
        </Flex>

        <MainButton
          label="Create Project"
          onClick={createProjectFromRequest}
          style={{ width: "100%" }}
          disabled={!projectName || isLoading}
          inProgress={isLoading}
          size="medium"
        />
        <TextButton label="cancel" onClick={onClose} />
      </Flex>
    </Modal>
  );
};
