import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { FindingsCounts } from "../../../types/Finding";
import { Link } from "react-router-dom";
import { Flex } from "../../../components/layouts/flex/Flex";
import { objectToBase64 } from "../../../shared/helper";
import { findingsDefaultFilters } from "../Findings";
import { DoughnutChart } from "../../../components/composed/charts/DoughnutChart";
import { Mixpanel } from "../../../shared/mixpanel";

type Props = {
  findingsCounts: FindingsCounts;
  includeInfo?: boolean;
  hideLegendText?: boolean;
  isLoading?: boolean;
  selectedProduct?: number | "all";
};

const LegendLabel = styled(LabelRegular)`
  cursor: pointer;
  transition: 0.2s;
  color: ${(props) => props.theme.gray600};
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;

export const FindingDoughnutChart = (props: Props) => {
  const {
    findingsCounts,
    includeInfo = false,
    hideLegendText,
    isLoading,
    selectedProduct,
  } = props;

  const theme = useContext(ThemeContext);

  const getFindingsLink = (overall_risk: string) =>
    selectedProduct
      ? `/findings?filters=${objectToBase64({
          ...findingsDefaultFilters,
          overall_risk,
          product: selectedProduct,
        })}`
      : `/findings?filters=${objectToBase64({
          ...findingsDefaultFilters,
          overall_risk,
        })}`;

  var labels = ["Critical", "High", "Medium", "Low"];
  var datasets = [
    {
      data: [
        findingsCounts.open_criticals,
        findingsCounts.open_highs,
        findingsCounts.open_mediums,
        findingsCounts.open_lows,
      ],
      backgroundColor: [
        theme.blue,
        theme.blue500,
        theme.blue300,
        theme.blue100,
      ],
    },
  ];

  if (includeInfo) {
    labels.push("Info");
    datasets[0].data.push(findingsCounts.open_infos || 0);
    datasets[0].backgroundColor.push(theme.blue50);
  }

  const LegendLine = ({
    color,
    text,
    count,
    onClick,
    linkTo,
  }: {
    color: string;
    text: string;
    count: number;
    onClick: () => void;
    linkTo: string;
  }) => (
    <Flex align="center" gap="8px" className="text-truncate">
      <span
        style={{
          width: "8px",
          height: "8px",
          background: color,
          borderRadius: "50%",
        }}
      />
      <LabelRegular>{count}</LabelRegular>
      <LegendLabel className="text-truncate">
        <Link
          to={linkTo}
          onClick={onClick}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <LabelRegular color={theme.textSub}>
            {!hideLegendText && text}
          </LabelRegular>
        </Link>
      </LegendLabel>
    </Flex>
  );

  return (
    <Flex justify="center" align="center" gap="8px">
      <DoughnutChart
        labelNumber={findingsCounts.open}
        labelText="Open Issues"
        isLoadingData={isLoading}
        labels={labels}
        datasets={datasets}
        size={100}
        labelNumberLineHeight="36px"
      />

      <Flex column gap="4px" data-testid="legend-container">
        <LegendLine
          color={theme.blue}
          text="Critical"
          count={findingsCounts.open_criticals}
          onClick={() => {
            Mixpanel.track("Findings Overview", { severity: "Critical" });
          }}
          linkTo={getFindingsLink("4")}
        />
        <LegendLine
          color={theme.blue500}
          text="High"
          count={findingsCounts.open_highs}
          onClick={() => {
            Mixpanel.track("Findings Overview", { severity: "High" });
          }}
          linkTo={getFindingsLink("3")}
        />
        <LegendLine
          color={theme.blue300}
          text="Medium"
          count={findingsCounts.open_mediums}
          onClick={() => {}}
          linkTo={getFindingsLink("2")}
        />
        <LegendLine
          color={theme.blue100}
          text="Low"
          count={findingsCounts.open_lows}
          onClick={() => {
            Mixpanel.track("Findings Overview", { severity: "Low" });
          }}
          linkTo={getFindingsLink("1")}
        />
        {includeInfo && (
          <LegendLine
            color={theme.blue50}
            text="Info"
            count={findingsCounts.open_infos || 0}
            onClick={() => {
              Mixpanel.track("Findings Overview", { severity: "Info" });
            }}
            linkTo={getFindingsLink("0")}
          />
        )}
      </Flex>
    </Flex>
  );
};
