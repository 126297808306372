import React, { useContext } from "react";
import { Box } from "../../../../components/elements/box/Box";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { HeaderSubBold } from "../../../../components/elements/typography/Typography";
import { ITimeframeOption } from "../../../findings/findingsCharts/TimeframeDropdown";
import {
  LineChart,
  LineChartDataset,
} from "../../../../components/composed/charts/LineChart";
import { useApiRiskScoreHistory } from "../../../../hooks/queries/riskScoreHistoryContext";
import { ThemeContext } from "styled-components";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { DAY_MS } from "../../../../shared/consts";

type Props = {
  timeframe: ITimeframeOption;
};

export const RiskScoreOvertime = ({ timeframe }: Props) => {
  const theme = useContext(ThemeContext);
  const { data: riskHistoryItems, isFetching } = useApiRiskScoreHistory({
    page_size: 365,
    ordering: "-created_at",
  });
  const eventsToUse =
    riskHistoryItems
      ?.filter(
        (item) =>
          new Date(item.created_at) > new Date(Date.now() - timeframe.value)
      )
      ?.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) =>
            timeframe.value > 170 * DAY_MS // 6 months
              ? new Date(t.created_at).getMonth() ===
                new Date(item.created_at).getMonth()
              : new Date(t.created_at).toDateString() ===
                new Date(item.created_at).toDateString()
          )
      )
      .reverse() || [];

  const datasets: LineChartDataset[] = [
    {
      label: "Risk Score",
      data: eventsToUse?.map((item) => item.risk_score) || [],
      borderColor: theme.black800,
    },
    {
      label: "Critical Issues",
      data: eventsToUse?.map((item) => item.critical_amount) || [],
      borderColor: theme.redPrimary,
    },
    {
      label: "Breached SLA",
      data: eventsToUse?.map((item) => item.breached_sla_amount) || [],
      borderColor: theme.orangePrimary,
    },
    {
      label: "Amount of Findings",
      data: eventsToUse?.map((item) => item.findings_total_amount) || [],
      borderColor: theme.yellowPrimary,
    },
  ];

  return (
    <Box>
      <Flex column gap="24px">
        <HeaderSubBold>Risk Score Overtime</HeaderSubBold>
        {isFetching ? (
          <SkeletonLoading width="100%" height="223px" />
        ) : (
          <LineChart
            datasets={datasets}
            labels={
              eventsToUse?.map((item) =>
                timeframe.value > 170 * DAY_MS // 6 months
                  ? new Date(item.created_at).toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "long",
                    })
                  : new Date(item.created_at).toLocaleDateString()
              ) || []
            }
            height={223}
            dataTestId="over-time-risk-score-chart"
          />
        )}
      </Flex>
    </Box>
  );
};
