import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  LabelMedium,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import {
  calculateAssetGrade,
  getAssetGradeTooltipContent,
  getGradeBackground,
  getGradeColor,
} from "../AssetUtils";
import { Flex } from "../../../components/layouts/flex/Flex";
import { AssetGrade } from "../../../types/Asset";

type Props = {
  riskScore?: number;
  assetGrade?: AssetGrade;
  color?: string;
  backgroundColor?: string;
  isTooltipActive?: boolean;
};

export const AssetGradeBadge = (props: Props) => {
  const {
    riskScore,
    assetGrade,
    color,
    backgroundColor,
    isTooltipActive = true,
  } = props;

  const theme = useContext(ThemeContext);
  const grade = riskScore ? calculateAssetGrade(riskScore) : assetGrade || "A";
  const tooltipContent = getAssetGradeTooltipContent(grade);

  return (
    <Flex>
      <Tooltip
        isActive={isTooltipActive}
        content={
          <LabelRegular
            style={{
              display: "flex",
              width: "350px",
              textTransform: "initial",
            }}
          >
            {isTooltipActive ? tooltipContent : ""}
          </LabelRegular>
        }
      >
        <Flex
          align="center"
          justify="center"
          style={{
            width: "56px",
            height: "26px",
            borderRadius: "16px",
            backgroundColor:
              backgroundColor || theme[getGradeBackground(grade)],
          }}
        >
          <LabelMedium color={color || theme[getGradeColor(grade)]}>
            {grade}
          </LabelMedium>
        </Flex>
      </Tooltip>
    </Flex>
  );
};
