import styled from "styled-components";

type SliderProps = {
  checked: boolean;
};

export const ToggleThemeStyle = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 32px;
`;

export const SliderThemeStyle = styled.span<SliderProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 0;
  background-color: ${(props) => props.theme.dark};
  transition: all 0.4s linear;
  border-radius: 24px;
  &:before {
    transition: all 0.4s linear;
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    margin-left: ${(props) => (props.checked ? "26px" : "0")};
    bottom: 4px;
    background-color: ${(props) => props.theme.primary};
    border-radius: 24px;
    box-shadow: ${(props) => `0px 2px 5px ${props.theme.lightCardShadow}`};
  }
`;
