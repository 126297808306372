import React from "react";
import { ThirdPartyConnections } from "../../../types/Asset";
import { NotFoundBanner } from "../../../components/composed/banner/NotFoundBanner";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { AssetDomainGroupConnections } from "./AssetDomainGroupConnections";

type Props = {
  connections?: ThirdPartyConnections[];
};

export const AssetConnections = ({ connections }: Props) => {
  const groupConnectionsByDomain =
    connections?.reduce(
      (acc, connection) => {
        if (!acc[connection.domain]) {
          acc[connection.domain] = [];
        }
        acc[connection.domain].push(connection);
        return acc;
      },
      {} as Record<string, ThirdPartyConnections[]>
    ) || [];

  if (groupConnectionsByDomain.length === 0) {
    return (
      <Box className="d-flex justify-content-center">
        <NotFoundBanner
          title="No connections found"
          text="If Asset has links to other third-party systems, they will appear here"
        />
      </Box>
    );
  }

  return (
    <Flex column gap="8px">
      <SeparatorHorizontal />
      <HeaderSecondary>Third Party Connections</HeaderSecondary>

      {Object.entries(groupConnectionsByDomain).map(([domain, connections]) => (
        <AssetDomainGroupConnections
          key={`domain-card-s${domain}`}
          domain={domain}
          connections={connections}
        />
      ))}
    </Flex>
  );
};
