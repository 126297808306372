import React from "react";

export type IconProps = {
  color?: string;
  size?: string | number;
};

export function WaspIcon(props: IconProps) {
  const size = props.size || 54;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 54 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5923 13.8449L32.5469 24.2349C35.8361 27.5006 41.1648 27.5006 44.4493 24.2349L50.4074 18.3238L44.4539 12.4128C41.1648 9.14709 35.8361 9.14709 32.5515 12.4128M22.1559 2L37.5817 17.7492M15.0264 17.4572L26.52 29.5941M33.9434 2.14596L28.5596 7.3455M19.6891 25.0422C16.8318 27.8791 16.8318 32.4812 19.6891 35.3181L26.4189 42L33.1487 35.3181C36.006 32.4812 36.006 27.8791 33.1487 25.0422M20.8559 24.0616C17.5668 27.3273 12.238 27.3273 8.95349 24.0616L3 18.1505L8.95349 12.2395C12.2426 8.97377 17.5713 8.97377 20.8559 12.2395L23.0792 14.2782"
        stroke={props.color || "currentColor"}
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
