import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useApiFindings } from "../../../hooks/queries/findingContext";
import { calcCweStats } from "./cwePieChartCalc";
import { cweMap } from "./cweMap";
import { Icon } from "../../../components/elements/icon/Icon";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  DoughnutControllerChartOptions,
} from "chart.js";
import { LegendItem } from "../../../components/elements/legend/LegendItem";
import { legendContainerStyle } from "../Insights";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { NoDataAvailable } from "../NoDataAvailable";
import { SingleValue } from "react-select";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { findingsTimeOptions } from "../../findings/filters/Timeframe";
import { getTooltip } from "../../../components/composed/charts/externalTooltip";
import { Mixpanel } from "../../../shared/mixpanel";
import { pieChartSkeletonData } from "../../../components/composed/charts/DoughnutChart";

ChartJS.register(ArcElement, Tooltip, Legend);

type CweData = {
  keys?: number[];
  labels?: string[];
  dataset?: number[];
};

const colors = [
  "#073052",
  "#00437A",
  "#30628D",
  "#246ED3",
  "#3285FB",
  "#65A3FC",
  "#98C2FD",
  "#C1DAFE",
  "#EAF3FF",
  "#F7FAFE",
];

type DoughnutOptions = _DeepPartialObject<
  CoreChartOptions<"doughnut"> &
    ElementChartOptions<"doughnut"> &
    PluginChartOptions<"doughnut"> &
    DatasetChartOptions<"doughnut"> &
    ScaleChartOptions<"bar"> &
    DoughnutControllerChartOptions
>;

export const CwePieChart = () => {
  const theme = useContext(ThemeContext);
  const chartRef = useRef(null);

  const [timeframe, setTimeframe] = useState<SingleValue<Option>>(
    findingsTimeOptions[5]
  );

  const {
    data: findings,
    isRefetching,
    isFetching,
  } = useApiFindings({
    page_size: 1000,
    is_pending: false,
    is_false_positive: false,
    timeframe: timeframe?.value,
    cwe_id__gt: 0,
  });

  const [cweData, setCweData] = useState<CweData>({});
  const [legend, setLegend] = useState<boolean[]>([]);

  const legendClickHandler = (e: any, label: string, idx: number) => {
    const newLegend = [...legend];
    newLegend[idx] = !newLegend[idx];
    setLegend(newLegend);
    //@ts-ignore
    chartRef.current.toggleDataVisibility(idx);
  };

  const filterCweChart = useCallback(() => {
    if (!findings || !chartRef || isRefetching) return;
    // console.log("Filtering finding by CWE");
    const cweStats = calcCweStats(findings);
    const cweKeys: string[] = Object.keys(cweStats)
      .sort((a, b) => cweStats[b] - cweStats[a])
      .slice(0, 10);
    setCweData({
      keys: cweKeys.map((cweId) => parseInt(cweId)),
      labels: cweKeys.map((cweId) => cweMap[parseInt(cweId)]),
      dataset: cweKeys.map((cweId) => cweStats[cweId]),
    });
    setLegend(cweKeys.map(() => true));
  }, [findings, chartRef, isRefetching]);

  // When findings ready - filter CWEs
  useEffect(() => {
    if (!!findings) filterCweChart();
  }, [findings, filterCweChart]);

  const doughnutOptions: DoughnutOptions = {
    responsive: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        mode: "point",
        external: (context: any) =>
          findings?.length &&
          getTooltip(context, theme, "doughnut", findings?.length),
      },
    },
    layout: { padding: 32 },
    cutout: 90,
  };

  const data = {
    labels: cweData.labels,
    datasets: [
      {
        label: "Findings",
        data: cweData.dataset,
        backgroundColor: colors,
        borderColor: colors,
      },
    ],
  };

  return (
    <Flex column gap="12px" h100 w100>
      <Flex align="center" gap="8px">
        <Icon name="presented" size={24} color={theme.primary} />
        <HeaderSecondary>Most Frequently Seen CWEs</HeaderSecondary>
        <span style={{ marginLeft: "auto" }}>
          <Dropdown
            onChange={(option) => {
              Mixpanel.track("Most Frequently Seen CWEs - Timeframe selected", {
                selected: option?.label,
              });
              setTimeframe(option);
            }}
            options={findingsTimeOptions}
            placeholder={timeframe?.label}
            dataTestId="timeframe-dropdown"
          />
        </span>
      </Flex>
      <Flex>
        <Flex column gap="8px" style={legendContainerStyle} w100>
          {cweData.labels?.map((label, idx) => (
            <LegendItem
              label={label}
              key={idx}
              onClick={(e) => {
                Mixpanel.track("Most Frequently Seen CWEs - selected CWE", {
                  selected: label,
                });
                legendClickHandler(e, label, idx);
              }}
              color={colors[idx]}
              isOn={legend[idx]}
            />
          ))}
        </Flex>
        <Flex
          w100
          justify="center"
          align="start"
          style={{ marginLeft: "auto", position: "relative" }}
        >
          <Doughnut
            ref={chartRef}
            data={
              cweData.dataset?.length
                ? data
                : pieChartSkeletonData(theme.black500)
            }
            width={303}
            height={303}
            options={doughnutOptions}
          />
          {!cweData.dataset?.length && !isFetching && !isRefetching && (
            <NoDataAvailable />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
