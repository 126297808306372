import { useContext } from "react";
import { Icon } from "../../elements/icon/Icon";
import { LabelRegular } from "../../elements/typography/Typography";
import { Badge } from "../../elements/badge/Badge";
import { ThemeContext } from "styled-components";

export const PausedBadge = () => {
  const theme = useContext(ThemeContext);
  return (
    <Badge
      as="div"
      backgroundColor={theme.red50}
      color={theme.red800}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
      }}
    >
      <Icon name="activeIssues" size={18} />
      <LabelRegular
        style={{
          color: theme.red800,
        }}
      >
        Paused
      </LabelRegular>
    </Badge>
  );
};
