import { FindingsTable } from "../../../findings/findingsTable/FindingsTable";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { HeaderSubBold } from "../../../../components/elements/typography/Typography";
import { Link } from "react-router-dom";
import { TextButton } from "../../../../components/elements/button/text/TextButton";

export const TopRiskyIssues = () => {
  return (
    <Flex column gap="16px" w100>
      <Flex w100 align="center" justify="between">
        <HeaderSubBold>Top Risky Issues</HeaderSubBold>
        <Link
          to={`/findings`}
          style={{ textDecoration: "none" }}
          className="text-truncate"
        >
          <TextButton label="Findings" />
        </Link>
      </Flex>
      <FindingsTable
        disableSorting
        setIsFiltersPaneOpen={() => {}}
        filters={{
          ordering: "-overall_risk",
          is_open: true,
        }}
        setFilters={() => {}}
        onSelectedFinding={() => {}}
        disabledColumns={[
          "insight",
          "source",
          "customer_jira_issue_key",
          "status",
          "comments_count",
          "assigned_to",
          "scanner_name",
          "sla_expires_at",
        ]}
        size={"small"}
      />
    </Flex>
  );
};
