import { ChangeEvent } from "react";
import { Flex } from "../../layouts/flex/Flex";
import { LabelRegular } from "../typography/Typography";
import { DatePicker } from "./DatePicker";

type Props = {
  start: string;
  end: string;
  onChangeStart: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeEnd: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const RangeDatePicker = (props: Props) => {
  const { start, end, onChangeStart, onChangeEnd } = props;
  return (
    <Flex gap="16px">
      <Flex column>
        <LabelRegular>Start Date</LabelRegular>
        <DatePicker value={start || ""} onChange={onChangeStart} />
      </Flex>
      <Flex column>
        <LabelRegular>End Date</LabelRegular>
        <DatePicker value={end || ""} onChange={onChangeEnd} />
      </Flex>
    </Flex>
  );
};
