import React from "react";
import { IconProps } from "./WaspIcon";

export function MidCheckIcon(props: IconProps) {
  const size = props.size || 8;
  return (
    <svg
      width={size}
      height="2"
      viewBox="0 0 8 2"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="8" height="2" rx="1" fill={props.color || "currentColor"} />
    </svg>
  );
}
