import { CSSProperties } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Finding } from "../../../types/Finding";
import { Column, Table } from "../../../components/elements/table/Table";
import {
  IMPACTS_REPORT_FORMAT,
  MITIGATION,
  SEVERITIES,
} from "../../../shared/consts";
import { getRiskColor, getStatusName } from "../../../shared/findingsHelper";
import { Box } from "../../../components/elements/box/Box";
import { light } from "../../../shared/theme";

type Props = {
  findings: Finding[];
  includeStatus?: boolean;
};

export const SummaryOfResults = ({ findings, includeStatus }: Props) => {
  const cellStyle: CSSProperties = {
    fontSize: "9px",
    lineHeight: "11px",
  };

  const columns: Column[] = [
    {
      title: "#",
      key: "idx",
      maxWidth: "20px",
      cell: (row) => <BodyRegular style={cellStyle}>{row.idx + 1}</BodyRegular>,
    },
    {
      title: "Title",
      key: "title",
      minWidth: "180px",
      cell: (row) => (
        <BodyRegular
          className="truncate-7-rows"
          style={{
            ...cellStyle,
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          title={row.title}
        >
          {row.title}
        </BodyRegular>
      ),
    },
    {
      title: "Summary",
      key: "summary",
      minWidth: "180px",
      cell: (row) => (
        <BodyRegular
          className="truncate-7-rows"
          style={{
            ...cellStyle,
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          title={row.summary}
        >
          {row.summary}
        </BodyRegular>
      ),
    },
    {
      title: "Impact",
      key: "impact",

      centerCell: true,
      cell: (row) => (
        <BodyRegular
          style={cellStyle}
          //@ts-ignore
          color={light[getRiskColor(row.impact + 1)]}
        >
          {IMPACTS_REPORT_FORMAT[row.impact]}
        </BodyRegular>
      ),
    },
    {
      title: "Exploitability",
      key: "exploitability",

      centerCell: true,
      cell: (row) => (
        <BodyRegular
          style={cellStyle}
          //@ts-ignore
          color={light[getRiskColor(row.exploitability)]}
        >
          {MITIGATION[row.exploitability]}
        </BodyRegular>
      ),
    },
    {
      title: "Risk level",
      key: "overall_risk",

      centerCell: true,
      cell: (row) => (
        <BodyRegular
          style={cellStyle}
          //@ts-ignore
          color={light[getRiskColor(row.overall_risk)]}
        >
          {SEVERITIES[row.overall_risk]}
        </BodyRegular>
      ),
    },
    {
      title: "Status",
      key: "status",
      centerCell: true,
      isHidden: !includeStatus,
      cell: (row) => (
        <BodyRegular
          style={cellStyle}
          className="text-center"
          color={row.status === "fixed" ? light.greenPrimary : light.black800}
        >
          {getStatusName(row.status)}
        </BodyRegular>
      ),
    },
  ];

  const breakToTables = (findings: Finding[]): Finding[][] => {
    const tables: Finding[][] = [];
    let table: Finding[] = [];
    findings.forEach((finding, idx) => {
      // first page can contain 7 findings
      if (idx < 7) {
        table.push(finding);
      } // next pages can contain 10 findings
      else if (idx === 7 || (idx - 7) % 9 === 0) {
        tables.push(table);
        table = [];
        table.push(finding);
      } else {
        table.push(finding);
      }
    });
    if (table.length) {
      tables.push(table);
    }
    return tables;
  };

  const calcMarginTop = (idx: number) => {
    if (idx === 0) return "0px";
    if (idx > 0) return "130px";
    if (idx > 1) return "120px";
  };

  return (
    <Flex
      column
      w100
      gap="8px"
      className="mt-5"
      style={{ pageBreakAfter: "always" }}
    >
      <HeaderSecondary
        color={light.blue800}
        style={{
          textTransform: "none",
        }}
      >
        Summary of Results
      </HeaderSecondary>
      <BodyRegular className="fully-justify" color={light.black800}>
        The risk level was determined by two key values of{" "}
        <strong>exploitability</strong> and <strong>impact</strong>;
        <ul>
          <li>Exploitability - the likelihood of threat exploitation.</li>
          <li>
            Impact - the potential damage that can be done to the company’s
            assets if the threat were to be exploited.
          </li>
        </ul>
      </BodyRegular>
      <BodyRegular className="fully-justify" color={light.black800}>
        Finding according to risk level - the table below displays each finding
        identified by the Red Team at each risk level.
      </BodyRegular>
      {breakToTables(
        findings.map((f, idx) => ({
          ...f,
          idx,
        }))
      ).map((table, idx) => (
        <Box
          key={`table-${idx}`}
          className={`box`}
          style={{
            marginTop: calcMarginTop(idx),
            padding: "24px 0px",
          }}
        >
          <Table
            columns={columns}
            rows={table}
            size="very-small"
            isStriped
            rowHeight="86px"
          />
        </Box>
      ))}
    </Flex>
  );
};
