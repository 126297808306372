import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { createItem, getPage } from "../../../hooks/queries/sdk";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
} from "../../../hooks/queries/utils";
import { WaspAIAnswer, WaspAIAnswerParams } from "./types";
import { bitAfterNow, getQueryParams } from "../../../shared/helper";

const key = "wasp-ai";
const keyConversations = `${key}-conversations`;

export type ConversationMessage = {
  sender: "assistant" | "tool" | "user";
  content: string;
};

type Conversation = ConversationMessage[];

export type ConversationInfo = {
  thread_id: string;
  customer_name: string;
  account_name: string;
  avatar: string;
  created_at: string;
  conversation: Conversation;
};

type ConversationsAdminMode = ConversationInfo[];

type Conversations = ConversationsAdminMode | Conversation | string[];

export type ConversationsPage = {
  results: Conversations | null | undefined;
  count: number | null | undefined;
  next: string | null | undefined;
  previous: string | null | undefined;
};

export const useApiWaspAIAskQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation<WaspAIAnswer, Error, WaspAIAnswerParams>({
    mutationKey: [key],
    mutationFn: async (newQuestion): Promise<WaspAIAnswer> =>
      await createItem(key, newQuestion),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiWaspAIConversations = (
  adminMode?: boolean,
  threadId?: string
) => {
  var params: { [key: string]: string } = {
    "admin-mode": adminMode ? "true" : "false",
  };
  if (threadId) {
    params["thread_id"] = threadId;
  }
  return useInfiniteQuery<ConversationsPage, Error>({
    queryKey: [keyConversations, adminMode, threadId],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async ({ pageParam = 1 }): Promise<ConversationsPage> =>
      getPage(keyConversations, { ...params, page: pageParam }).then((resp) => {
        var page = { ...resp };
        return page;
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });
};

export const useApiWaspAISingleConversation = (threadId?: string) => {
  return useInfiniteQuery<ConversationsPage, Error>({
    queryKey: [keyConversations, threadId],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: !!threadId,
    queryFn: async ({ pageParam = 1 }): Promise<ConversationsPage> =>
      getPage(keyConversations, { thread_id: threadId, page: pageParam }).then(
        (resp) => {
          var page = { ...resp };
          return page;
        }
      ),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });
};
