import React from "react";
import { IconProps } from "./WaspIcon";

export function PriorityVeryHighIcon(props: IconProps) {
  const size = props.size || 16;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="transparent" />
      <path
        d="M3.5 8.00195L8 3.50195L12.5 8.00195"
        stroke="#A30909"
        strokeWidth="1.125"
      />
      <path
        d="M3.5 12.502L8 8.00195L12.5 12.502"
        stroke="#A30909"
        strokeWidth="1.125"
      />
    </svg>
  );
}
