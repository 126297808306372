import { ReactNode } from "react";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Flex } from "../../../components/layouts/flex/Flex";
import { TextButton } from "../../../components/elements/button/text/TextButton";

type Props = {
  children?: ReactNode;
  onClose: () => void;
};

export const OnboardingFooter = (props: Props) => {
  const { children } = props;

  return (
    <Flex
      column
      w100
      gap="24px"
      style={{ position: "absolute", bottom: "120px", zIndex: "100" }}
    >
      <SeparatorHorizontal />
      <Flex justify="between" style={{ width: "1350px" }}>
        <TextButton
          label="Explore WASP"
          onClick={() => {
            localStorage.setItem("show_tour", "true");
            window.location.replace("/dashboard?tour=on");
          }}
        />
        {children ? children : null}
      </Flex>
    </Flex>
  );
};
