import React from "react";
import { Loading } from "../../../components/elements/loading/Loading";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";
import { FindingDoughnutChart } from "../../findings/findingsCharts/FindingDoughnutChart";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  relatedFindings: number[];
};

export const FindingChartTooltipContent = (props: Props) => {
  const { relatedFindings } = props;

  const { data: findingsCounts, isFetching } = useApiFindingsCounts({
    id: relatedFindings.slice(0, 100),
  });

  const closedFindings =
    (findingsCounts?.total || 0) - (findingsCounts?.open || 0);

  return !isFetching && findingsCounts ? (
    <Flex column gap="8px">
      {(findingsCounts?.open || 0) > 0 && (
        <FindingDoughnutChart findingsCounts={findingsCounts} includeInfo />
      )}
      {closedFindings > 0 && (
        <BodyRegular>{`${closedFindings} Closed finding${
          closedFindings > 1 ? "s" : ""
        }`}</BodyRegular>
      )}
    </Flex>
  ) : (
    <Loading />
  );
};
