import { SetStateAction, useContext, useEffect, useState } from "react";
import Select, { GroupBase, components, MultiValue } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { ThemeContext } from "styled-components";
import { Icon } from "../icon/Icon";
import { dropdownStyles } from "./DropdownStyles";
import { SimpleCheckbox } from "../checkbox/SimpleCheckbox";
import { Option } from "./Dropdown";
import { BodyRegular } from "../typography/Typography";
import { Flex } from "../../layouts/flex/Flex";

type Props = {
  selected: MultiValue<Option> | undefined;
  setSelected?: React.Dispatch<SetStateAction<MultiValue<Option> | undefined>>;
  onSelect?: (options: MultiValue<Option>) => void;
  options?: Option[];
  width?: string;
  variant?: "border" | "outline";
  size?: "medium" | "large";
  placeholder?: string;
  disabled?: boolean;
  searchable?: boolean;
  clearable?: boolean;
  isError?: boolean;
  isBadged?: boolean;
  color?: string;
  styles?: { [key: string]: Function };
  iconName?: string;
  dataTestId?: string;
  customComponents?:
    | Partial<SelectComponents<Option, false, GroupBase<Option>>>
    | undefined;
  isMenuPositionFixed?: boolean;
  isCustomable?: boolean;
  backgroundColor?: string;
  itemsNameSingle?: string;
  itemsNameMulti?: string;
  closeMenu?: boolean;
  onOpenMenu?: () => void;
  onMenuScrollToBottom?: () => void;
};

export function MultiSelect(props: Props) {
  const {
    selected,
    setSelected,
    onSelect,
    color,
    size,
    options = [],
    itemsNameSingle = "item",
    itemsNameMulti = "items",
    placeholder,
    disabled = false,
    searchable = false,
    isError = false,
    variant = "border",
    dataTestId,
    width,
    isMenuPositionFixed,
    backgroundColor,
    closeMenu = false,
    isBadged = false,
    onOpenMenu,
    onMenuScrollToBottom = () => {},
  } = props;

  const theme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    closeMenu && setIsOpen(false);
  }, [closeMenu]);

  const onSelectHandler = (options: MultiValue<Option>) => {
    setSelected && setSelected(options);
    onSelect && onSelect(options);
  };

  const CheckboxOption = (props: any) => (
    <components.Option {...props}>
      <span
        className="d-flex align-items-center text-capitalize"
        data-testid={`${props.label}-option`}
      >
        <SimpleCheckbox isChecked={props.isSelected} onChange={() => {}} />
        {props.label}
      </span>
    </components.Option>
  );

  const MultiValueContainer = (props: any) => (
    <components.MultiValueContainer {...props}>
      <div></div>
    </components.MultiValueContainer>
  );

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <span
        data-testid={`${dataTestId}-indicator`}
        style={{ marginRight: "16px" }}
      >
        <Icon name="chevronDown" color="inherit" size="24px" />
      </span>
    </components.DropdownIndicator>
  );

  const Control = (props: any) => (
    <components.Control {...props}>
      <Flex
        gap="8px"
        align="center"
        className="d-flex align-items-center text-capitalize"
        style={{ padding: "8px 6px" }} // additional padding to get to 16px 16px
      >
        {!isBadged && selected?.length && selected?.length > 0 ? (
          <BodyRegular>
            {`${selected?.length} `}
            {`${selected?.length > 1 ? itemsNameMulti : itemsNameSingle}`}
            {" Selected"}
          </BodyRegular>
        ) : null}
        {props.children}
      </Flex>
    </components.Control>
  );

  const dropDownStyles = {
    ...dropdownStyles({
      backgroundColor,
      color,
      disabled,
      isError,
      isOpen,
      variant,
      size,
      theme,
    }),
    clearIndicator: (base: any, state: any) => ({
      ...base,
      position: "absolute",
      right: "32px",
    }),
    multiValue: (base: any, state: any) => ({
      ...base,
      backgroundColor: theme.blue100,
      borderRadius: "2rem",
      paddingInline: "6px",
    }),
    multiValueLabel: (base: any, state: any) => ({
      ...base,
      color: theme.textBody,
    }),
    multiValueRemove: (base: any, state: any) => ({
      ...base,
      backgroundColor: theme.blue100,
      color: theme.textBody,
      borderRadius: "50%",
    }),
  };

  const customComponents = isBadged
    ? {
        Control,
        Option: CheckboxOption,
        DropdownIndicator,
      }
    : {
        Control,
        DropdownIndicator,
        MultiValueContainer,
        Option: CheckboxOption,
      };

  return (
    <div style={{ width: width ? width : "inherit" }}>
      <Select
        id={dataTestId}
        placeholder={placeholder}
        isMulti={true}
        isClearable={true}
        isDisabled={disabled}
        isSearchable={searchable}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        options={options || []}
        onChange={onSelectHandler}
        value={selected}
        menuIsOpen={isOpen}
        onMenuOpen={() => {
          setIsOpen(true);
          onOpenMenu && onOpenMenu();
        }}
        onMenuClose={() => setIsOpen(false)}
        styles={dropDownStyles}
        components={customComponents}
        menuPosition={isMenuPositionFixed ? "fixed" : undefined}
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
    </div>
  );
}
