import { useEffect, useState } from "react";
// credit: https://infeng.github.io/react-viewer/
import Viewer from "react-viewer";
import { ImageDecorator } from "react-viewer/lib/ViewerProps";

type Props = {
  image: string;
  onClose: () => void;
};

export const ImageModal = (props: Props) => {
  const { image, onClose } = props;

  const [visible, setVisible] = useState(true);
  const [images, setImages] = useState<ImageDecorator[]>([]);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    // Load all images on current page
    if (images.length) return;
    const elements = document.getElementsByClassName("wasp-image-big");
    if (!elements.length) return;
    let imagesList: ImageDecorator[] = [];
    Array.from(elements).forEach((elm) => {
      if (elm instanceof HTMLImageElement)
        imagesList.push({ src: elm.src, alt: "" });
    });
    setImages(imagesList);

    // Set selected image as active index
    setActiveIndex(imagesList.findIndex((i) => i.src === image));
  }, [image, images]);

  return (
    <Viewer
      onClose={() => {
        setVisible(false);
        onClose();
      }}
      rotatable={false}
      images={images}
      activeIndex={activeIndex}
      visible={visible}
      onMaskClick={onClose}
      changeable
      showTotal
    />
  );
};
