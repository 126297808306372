import { useNavigate } from "react-router";
import { Box } from "../../../components/elements/box/Box";
import {
  HeaderMain,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";
import { useEffect } from "react";

import waspLogo from "../../../assets/images/Brand-logo.png";
import { Illustration } from "../../../components/elements/illustrations/Illustration";

export const PendingTrial = () => {
  const navigate = useNavigate();
  const { data: me } = useApiMe();

  useEffect(() => {
    if (me?.customer.is_trial_approved) navigate("/");
  }, [me, navigate]);

  return (
    <Flex column justify="center" align="center" style={{ height: "100vh" }}>
      <img
        alt="wasp"
        src={waspLogo}
        style={{
          width: "100vw",
          position: "fixed",
          top: "-35vh",
          left: "-60vw",
          zIndex: 0,
        }}
      />
      <Box style={{ zIndex: 200 }}>
        <Flex column gap="32px" align="center">
          <HeaderMain>Thank you for exploring WASP!</HeaderMain>
          <Illustration name="alert" />
          <HeaderSubBold>
            Your request for the trial version is currently being processed.
          </HeaderSubBold>
          <HeaderSubBold>We'll keep you informed at {me?.email}</HeaderSubBold>
        </Flex>
      </Box>
    </Flex>
  );
};
