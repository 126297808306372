import { useState } from "react";
import { TextArea } from "../../../components/elements/input/textArea/TextArea";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  BodyMedium,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ComplianceSupport } from "./ComplianceSupport";
import { ProjectFormProps } from "./ProjectScheduler";
import { ReportsSelector } from "./ReportsSelector";
import { Whitelist } from "./Whitelist";
import { Mixpanel } from "../../../shared/mixpanel";

export const TestSettings = (props: ProjectFormProps) => {
  Mixpanel.track("Schedule a Test - What to scan");
  const { mainFormValues, setMainFormValues } = props;
  const [closeMenus, setCloseMenus] = useState<boolean>(false);

  return (
    <Flex
      w100
      style={{ height: "calc(92vh - 230px)", overflowY: "hidden" }}
      onClick={() => setCloseMenus(true)}
    >
      <Flex column gap="24px" style={{ width: "30%", overflowY: "hidden" }}>
        <HeaderSecondary>Test Settings</HeaderSecondary>
      </Flex>
      <SeparatorVertical height="65vh" style={{ marginInline: "32px" }} />

      <Flex
        column
        w100
        style={{
          height: "calc(90vh - 240px)",
          overflowY: "auto",
          paddingRight: "32px",
        }}
      >
        <Flex column gap="8px" style={{ padding: "32px" }}>
          <BodyMedium>Select Reports types</BodyMedium>
          <ReportsSelector
            mainFormValues={mainFormValues}
            setMainFormValues={setMainFormValues}
          />
        </Flex>

        <SeparatorHorizontal />

        <ComplianceSupport
          mainFormValues={mainFormValues}
          setMainFormValues={setMainFormValues}
          closeMenus={closeMenus}
          setCloseMenus={setCloseMenus}
        />

        <SeparatorHorizontal />

        <Whitelist
          mainFormValues={mainFormValues}
          setMainFormValues={setMainFormValues}
        />

        <SeparatorHorizontal />

        <Flex w100 align="center" justify="between">
          <Flex w100 column gap="8px" style={{ padding: "32px" }}>
            <BodyMedium>Add General Notes</BodyMedium>
            <TextArea
              placeholder="Feel free to provide our notes in this space"
              width="100%"
              value={mainFormValues.test_settings.general_notes}
              onChange={(e) =>
                setMainFormValues((prev) => ({
                  ...prev,
                  test_settings: {
                    ...prev.test_settings,
                    general_notes: e.target.value,
                  },
                }))
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
