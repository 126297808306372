import { useContext, useRef, useState } from "react";
import { CSSProperties, ThemeContext } from "styled-components";
import {
  FindingsMergeInput,
  FindingsMergeOutput,
  useApiMergeFindings,
} from "../../../hooks/queries/findingContext";
import useToastContext from "../../../hooks/toastHook";
import { Icon } from "../../../components/elements/icon/Icon";
import { Menu } from "../../../components/elements/menu/Menu";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { useOutsideTrigger } from "../../../hooks/utilsHooks";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Backdrop } from "../../../components/elements/rightPane/RightPane";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { Finding } from "../../../types/Finding";
import { Checkbox } from "../../../components/elements/checkbox/Checkbox";
import { InputText } from "../../../components/elements/input/textInput/InputText";

type Props = {
  close: () => void;
  findings: Finding[];
  customStyle?: CSSProperties;
};

export const MergeFindingsMenu = (props: Props) => {
  const { close, findings, customStyle } = props;
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const wrapperRef = useRef(null);
  useOutsideTrigger(wrapperRef, close);

  const { mutate: mergeFindings, isLoading } = useApiMergeFindings();
  const findingIds = findings.map((f) => f.id);

  const [findingsMergeData, setFindingsMergeData] =
    useState<FindingsMergeInput>({
      finding_ids: findingIds,
      is_pending: true,
    });

  const handleMerge = () => {
    addToast({
      message: `Merging findings...`,
      type: "info",
    });

    mergeFindings({
      findingsData: findingsMergeData,
      onSuccessCallback: (mergeResult: FindingsMergeOutput) => {
        addToast({
          message: `Findings have been successfully merged!`,
          type: "success",
          durationMs: 10000,
        });
        close();
      },
      onErrorCallback: (error: Error) => {
        addToast({ message: error.message, type: "error" });
        close();
      },
    });
  };

  return (
    <>
      <Backdrop
        transparent
        blurEnabled={false}
        onClick={close}
        style={{
          position: "absolute",
          left: "-100vw",
          top: "-100vh",
          bottom: "-100vh",
          right: "-100vw",
        }}
      />
      <Menu
        data-testid="merge-findings-menu"
        ref={wrapperRef}
        style={{
          width: "320px",
          zIndex: 200,
          ...customStyle,
        }}
      >
        <Flex column w100 gap="8px">
          <LabelRegular
            className="d-flex w-100"
            style={{ color: theme.black900 }}
          >
            Merge Findings
            <span className="ms-auto pointer" onClick={close}>
              <Icon name="cancel" size={16} color={theme.primary} />
            </span>
          </LabelRegular>
          <SeparatorHorizontal style={{ marginBlock: "8px" }} />

          <Flex column gap="16px">
            <Flex column gap="4px">
              <LabelRegular>New title:</LabelRegular>
              <InputText
                placeholder="Enter title"
                value={findings[0].title}
                onChange={(e) => {
                  setFindingsMergeData({
                    ...findingsMergeData,
                    new_title: e.target.value,
                  });
                }}
              />
            </Flex>
            <Checkbox
              state={findingsMergeData.is_pending ? "checked" : "unchecked"}
              onChange={() => {
                setFindingsMergeData({
                  ...findingsMergeData,
                  is_pending: !findingsMergeData.is_pending,
                });
              }}
              label="Mark as pending"
            />
          </Flex>

          <MainButton
            label="Merge"
            dataTestId="submit-btn"
            size="small"
            style={{ width: "83px", alignSelf: "flex-end" }}
            inProgress={isLoading}
            onClick={handleMerge}
          />
        </Flex>
      </Menu>
    </>
  );
};
