import { useContext } from "react";
import { DoughnutChart } from "../../../components/composed/charts/DoughnutChart";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  useApiAssetsCount,
  useApiAssetsRiskDistribution,
} from "../../../hooks/queries/assetsContext";
import { Filter } from "../../../types/AssetsView";
import { ThemeContext } from "styled-components";
import { LegendItem } from "../../../components/elements/legend/LegendItem";
import { Mixpanel } from "../../../shared/mixpanel";
import { useSearchParams } from "react-router-dom";
import { toBase64AssetsView } from "../../../shared/helper";
import { getGradeFilters } from "../AssetUtils";
import { AssetGrade } from "../../../types/Asset";

type Props = {
  filters: Filter[];
};

export const AssetsRiskPieChart = (props: Props) => {
  const { filters } = props;
  const theme = useContext(ThemeContext);
  const [, setSearchParams] = useSearchParams();

  const { data: riskDistribution, isFetching: isFetchingDist } =
    useApiAssetsRiskDistribution(
      filters.filter((f) => f.column !== "risk_score")
    );

  const { data: assetsCounts, isFetching: isFetchingCounts } =
    useApiAssetsCount({}, filters);

  const labels = [
    "Critical Risk",
    "High Risk",
    "Medium Risk",
    "Low Risk",
    "No Risk",
  ];

  const datasets = [
    {
      data: riskDistribution
        ? [
            riskDistribution.f,
            riskDistribution.e,
            riskDistribution.d,
            riskDistribution.c + riskDistribution.b,
            riskDistribution.a,
          ]
        : [],
      backgroundColor: [
        theme.blue700,
        theme.blue600,
        theme.blue500,
        theme.blue300,
        theme.blue100,
      ],
    },
  ];

  const onLegendClick = (grade: AssetGrade, maxGrade?: AssetGrade) => {
    Mixpanel.track("Asset Pie-chart click", { Selected: grade });
    setSearchParams((prev) => ({
      ...prev,
      view: toBase64AssetsView({
        name: "",
        filters: [
          ...filters.filter((f) => f.column !== "risk_score"),
          ...getGradeFilters(grade, maxGrade),
        ],
      }),
    }));
  };

  return (
    <Flex gap="24px" w100 h100>
      <Flex column justify="center">
        <DoughnutChart
          datasets={datasets}
          labelNumber={assetsCounts?.total || 0}
          labelText="Total Assets"
          labels={labels}
          labelNumberSize={
            assetsCounts?.total && assetsCounts.total > 1000 ? 18 : 24
          }
          labelNumberLineHeight="42px"
          size={112}
          isLoadingData={isFetchingCounts || isFetchingDist}
        />
      </Flex>
      <Flex column>
        <LegendItem
          color={theme.blue800}
          label="Critical Risk"
          labelSize={12}
          labelColor={theme.textSub}
          value={riskDistribution?.f}
          onClick={() => onLegendClick("F")}
        />
        <LegendItem
          color={theme.blue700}
          label="High Risk"
          labelSize={12}
          labelColor={theme.textSub}
          value={riskDistribution?.e}
          onClick={() => onLegendClick("E")}
        />
        <LegendItem
          color={theme.blue600}
          label="Medium Risk"
          labelSize={12}
          labelColor={theme.textSub}
          value={riskDistribution?.d}
          onClick={() => onLegendClick("D")}
        />
        <LegendItem
          color={theme.blue500}
          label="Low Risk"
          labelSize={12}
          labelColor={theme.textSub}
          value={
            riskDistribution?.c ? riskDistribution.c + riskDistribution.b : 0
          }
          onClick={() => onLegendClick("B", "C")}
        />
        <LegendItem
          color={theme.blue100}
          label="No Risk"
          labelSize={12}
          labelColor={theme.textSub}
          value={riskDistribution?.a}
          onClick={() => onLegendClick("A")}
        />
      </Flex>
    </Flex>
  );
};
