import { useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { Checkbox } from "../../../components/elements/checkbox/Checkbox";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { AssetsView, AssetsViewProps, Filter } from "../../../types/AssetsView";
import { FilterAttribute } from "./FilterAttribute";
import { emptyFilter, isFilterEmpty } from "./FiltersUtils";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  defaultAssetsView: AssetsViewProps | AssetsView;
  setAssetsView: (assetsView: AssetsViewProps) => void;
  isEdit?: boolean;
  saveAsNewFilter?: boolean;
  setSaveAsNewFilter?: (saveAsNewFilter: boolean) => void;
};

export const CreateEditFilterPane = (props: Props) => {
  const {
    setAssetsView,
    defaultAssetsView,
    isEdit = false,
    saveAsNewFilter = false,
    setSaveAsNewFilter,
  } = props;
  const [filterName, setFilterName] = useState<string>(defaultAssetsView.name);
  const [newFilters, setNewFilters] = useState<Filter[]>(
    defaultAssetsView.filters
  );
  const [showAddButton, setShowAddButton] = useState<boolean>(
    !isLastFilterEmpty(newFilters)
  );

  const handleNameChange = (name: string) => {
    setFilterName(name);
    setAssetsView({ name: name, filters: newFilters });
  };

  const handleFilterChange = (filter: Filter, index: number) => {
    const tempNewFilters = [...newFilters];
    tempNewFilters[index] = filter;
    setNewFilters(tempNewFilters);
    setShowAddButton(!isLastFilterEmpty(tempNewFilters));
    setAssetsView({ name: filterName, filters: tempNewFilters });
  };

  const handleRemoveFilter = (index: number) => {
    var tempNewFilters = [...newFilters];
    tempNewFilters.splice(index, 1);
    setNewFilters(tempNewFilters);
    setShowAddButton(!isLastFilterEmpty(tempNewFilters));
    setAssetsView({ name: filterName, filters: tempNewFilters });
  };

  function isLastFilterEmpty(filters: Filter[]): boolean {
    return !filters.length || isFilterEmpty(filters[filters.length - 1]);
  }

  return (
    <Flex column>
      <Flex column gap="16px" style={{ padding: "0 24px 24px 24px" }}>
        <div className="d-flex flex-column gap-4">
          <LabelRegular>Filter Name</LabelRegular>
          <InputText
            placeholder="Filter Name"
            value={filterName}
            onChange={(e) => handleNameChange(e.target.value)}
            width="100%"
            dataTestId="filter-name-input"
          />
        </div>
        {isEdit && (
          <Checkbox
            label="Save as new Filter"
            disabled={defaultAssetsView.name === filterName}
            state={saveAsNewFilter ? "checked" : "unchecked"}
            onChange={(state) =>
              !!setSaveAsNewFilter && setSaveAsNewFilter(state === "checked")
            }
          />
        )}
      </Flex>
      <SeparatorHorizontal />
      <Flex
        column
        gap="24px"
        style={{
          padding: "24px",
          height: "calc(100vh - 264px)",
          overflowY: "auto",
          paddingBottom: "400px",
        }}
      >
        <Flex column gap="16px">
          <LabelRegular>All assets where</LabelRegular>
          <Box
            className="d-flex flex-column gap-16"
            style={{ borderRadius: "4px" }}
          >
            {!newFilters.length ? (
              <FilterAttribute
                onChange={(filter) => handleFilterChange(filter, 0)}
                filter={emptyFilter}
                onRemove={() => handleRemoveFilter(0)}
                hasNextCondition={false}
              />
            ) : (
              newFilters.map((filter, i) => (
                <FilterAttribute
                  key={`filter-${i}`}
                  filter={filter}
                  onChange={(filter) => handleFilterChange(filter, i)}
                  onRemove={() => handleRemoveFilter(i)}
                  hasNextCondition={i > 0}
                />
              ))
            )}
            {showAddButton && (
              <>
                <SeparatorHorizontal />
                <LinkButton
                  label="Add Attribute"
                  dataTestId="add-attribute-button"
                  onClick={() => {
                    setNewFilters([...newFilters, emptyFilter]);
                    setShowAddButton(false);
                  }}
                  iconName="plus"
                />
              </>
            )}
          </Box>
        </Flex>
        <Tooltip content={"Feature in progress..."} placement="bottom">
          <SecondaryButton
            label="Add Group"
            size="medium"
            onClick={() => setNewFilters([...newFilters, emptyFilter])}
            inProgress={false}
            disabled={true}
            iconName="folder"
            style={{ width: "100%" }}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
