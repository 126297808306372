import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { IntegrationItem } from "./IntegrationItem";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import { JiraIntegrationModal } from "./jira/JiraIntegrationModal";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { Mixpanel } from "../../../shared/mixpanel";
import { useApiJiraCheckIntegration } from "../../../hooks/queries/jiraIntegrationContext";
import { Customer } from "../../../types/Customer";
import { useApiUpdateCustomer } from "../../../hooks/queries/customersContext";
import useToastContext from "../../../hooks/toastHook";
import {
  DeleteModal,
  DeleteModalProps,
} from "../../../components/composed/deleteModal/DeleteModal";
import { CloudflareIntegrationModal } from "./cloudflare/CloudflareIntegrationModal";
import { Route53Integration } from "./awsRoute53/Route53Integration";
import { OktaIntegrationModal } from "./okta/OktaIntegration";

const Integrations = () => {
  const { data: me, refetch } = useApiMe();
  useTrackPage("Settings - Integrations", undefined, me);
  const theme = useContext(ThemeContext);
  const [showJiraModal, setShowJiraModal] = useState(false);
  const [showCloudflareModal, setShowCloudflareModal] = useState(false);
  const [showAwsModal, setShowAwsModal] = useState(false);
  const [showOktaModal, setShowOktaModal] = useState(false);
  const [deleteModalProps, setDeleteModalProps] =
    useState<DeleteModalProps | null>(null);

  const {
    data: isJiraConnected,
    isFetching: isCheckingIntegration,
    refetch: refetchCheckJiraConnection,
  } = useApiJiraCheckIntegration();
  const addToast = useToastContext();
  const {
    mutate: updateCustomer,
    isLoading: isUpdatingIntegration,
    isSuccess: isDisconnectingSuccess,
  } = useApiUpdateCustomer();

  const slackDetailsOk = (customer: Customer) => {
    return !!customer?.is_slack_installed;
  };

  const disconnectJira = () => {
    setDeleteModalProps({
      itemName: "Jira Integration",
      itemType: "Integration",
      body: (
        <BodyRegular className="w-100 text-center">
          Are you sure you want to disconnect Jira integration, this action will
          delete all your Jira integration data
        </BodyRegular>
      ),
      onConfirm: () => {
        Mixpanel.track("Disconnect Jira integration");
        updateCustomer({
          customerId: me?.customer.id || -1,
          customerData: {
            jira_projects_mapping: [],
            jira_details: {
              username: "",
              token: "",
              url: "",
            },
          },
          onSuccessCallback: () => {
            addToast({
              message: "Jira integration has been successfully disconnected!",
              type: "success",
            });
            refetch();
            setDeleteModalProps(null);
            refetchCheckJiraConnection();
          },
          onErrorCallback: (error: Error) =>
            addToast({ message: error.message, type: "error" }),
        });
      },
      isLoading: isUpdatingIntegration,
      onClose: () => setDeleteModalProps(null),
      isSuccess: isDisconnectingSuccess,
    });
  };

  const disconnectSlack = () => {
    setDeleteModalProps({
      itemName: "Slack Integration",
      itemType: "Integration",
      body: (
        <BodyRegular className="w-100 text-center">
          Are you sure you want to disconnect Slack integration, this action
          will delete all your Slack integration data
        </BodyRegular>
      ),
      onConfirm: () => {
        Mixpanel.track("Disconnect Slack");
        updateCustomer({
          customerId: me?.customer.id || -1,
          customerData: {
            is_slack_installed: false,
            slack_details: {
              bot_id: "",
              channel_id: "",
              enterprise_id: "",
              team_id: "",
            },
          },
          onSuccessCallback: () => {
            addToast({
              message: "Slack integration has been successfully disconnected!",
              type: "success",
            });
            refetch();
            setDeleteModalProps(null);
          },
          onErrorCallback: (error: Error) =>
            addToast({ message: error.message, type: "error" }),
        });
      },
      isLoading: isUpdatingIntegration,
      onClose: () => setDeleteModalProps(null),
      isSuccess: isDisconnectingSuccess,
    });
  };

  const handleDisconnectCloudflare = () =>
    setDeleteModalProps({
      itemName: "Cloudflare Integration",
      itemType: "Integration",
      body: (
        <BodyRegular className="w-100 text-center">
          Are you sure you want to disconnect Cloudflare integration, this
          action will delete all your Cloudflare integration data
        </BodyRegular>
      ),
      onConfirm: () => {
        Mixpanel.track("Disconnect Cloudflare");
        updateCustomer({
          customerData: {
            cloudflare_details: { api_key: "", enabled: false },
          },
          customerId: me?.customer.id || 0,
          onSuccessCallback: () => {
            addToast({
              message: "Cloudflare is disconnected",
              type: "success",
            });
            refetch();
            setDeleteModalProps(null);
          },
          onErrorCallback: (err) =>
            addToast({
              message: `Failed to disconnect cloudflare - ${err}`,
              type: "error",
            }),
        });
      },
      isLoading: isUpdatingIntegration,
      onClose: () => setDeleteModalProps(null),
      isSuccess: isDisconnectingSuccess,
    });

  const handleDisconnectAws = () =>
    setDeleteModalProps({
      itemName: "Route53 Integration",
      itemType: "Integration",
      body: (
        <BodyRegular className="w-100 text-center">
          Are you sure you want to disconnect Route53 integration, this action
          will delete all your Route53 integration data
        </BodyRegular>
      ),
      onConfirm: () => {
        Mixpanel.track("Disconnect Route53");
        updateCustomer({
          customerData: {
            aws_details: {
              access_key_id: "",
              secret_access_key: "",
              region_name: "",
              enabled: false,
            },
          },
          customerId: me?.customer.id || 0,
          onSuccessCallback: () => {
            addToast({ message: "AWS is disconnected", type: "success" });
            refetch();
            setDeleteModalProps(null);
          },
          onErrorCallback: (err) =>
            addToast({
              message: `Failed to disconnect AWS - ${err}`,
              type: "error",
            }),
        });
      },
      isLoading: isUpdatingIntegration,
      onClose: () => setDeleteModalProps(null),
      isSuccess: isDisconnectingSuccess,
    });

  const handleDisconnectOkta = () =>
    updateCustomer({
      customerData: { okta_info: { org_url: "", client_id: "", secret: "" } },
      customerId: me?.customer.id || 0,
      onSuccessCallback: () =>
        addToast({ message: "Okta is disconnected", type: "success" }),
      onErrorCallback: (err) =>
        addToast({
          message: `Failed to disconnect Okta - ${err}`,
          type: "error",
        }),
    });

  return (
    <Flex gap="16px" justify="between" style={{ height: "calc(100vh - 90px)" }}>
      <Flex column gap="16px" className="w-100">
        <Flex justify="between">
          <Flex align="center" style={{ height: "50px" }}>
            <HeaderSecondary
              style={{ paddingRight: "24px", color: theme.black900 }}
            >
              Integrations
            </HeaderSecondary>
          </Flex>
        </Flex>
        <Flex flexWrap gap="24px">
          <IntegrationItem
            platform="Slack"
            isConnected={!!me?.customer && slackDetailsOk(me.customer)}
            iconName="slack"
            body={
              <BodyRegular>
                Integrate Slack for real-time notifications on security
                findings, helping you stay on top of critical issues and keep
                our organization secure
              </BodyRegular>
            }
            onConnect={() => {
              Mixpanel.track("Integrate Slack");
              window.open(
                `${window.location.origin}/slack/install?customer=${me?.customer?.id}`
              );
            }}
            onDisconnect={disconnectSlack}
          />
          <IntegrationItem
            platform="Jira"
            isConnected={!!isJiraConnected}
            body={
              <Flex column>
                <BodyRegular>
                  Integrate with JIRA to automate vulnerability tracking and
                  push findings to different boards and projects.
                </BodyRegular>
                <BodyRegular>
                  Enable bi-directional communication between researchers and
                  developers for efficient collaboration and faster remediation
                  of security issues
                </BodyRegular>
              </Flex>
            }
            iconName="jira"
            onConnect={() => {
              Mixpanel.track("Integrate Jira - Open Modal");
              setShowJiraModal(true);
            }}
            onEdit={() => setShowJiraModal(true)}
            onDisconnect={disconnectJira}
            inProgress={isCheckingIntegration}
          />

          <IntegrationItem
            iconName="cloudflare"
            platform="Cloudflare"
            body={
              <Flex column>
                <BodyRegular>
                  This Cloudflare integration obtains real-time insights into
                  exposed domains, providing a consolidated understanding of the
                  attack surface and associated risks
                </BodyRegular>
              </Flex>
            }
            isConnected={!!me?.customer.cloudflare_details?.enabled}
            onConnect={() => setShowCloudflareModal(true)}
            onEdit={() => setShowCloudflareModal(true)}
            onDisconnect={() => handleDisconnectCloudflare()}
          />

          <IntegrationItem
            iconName="aws"
            platform="AWS"
            body={
              <Flex column>
                <BodyRegular style={{ textTransform: "none" }}>
                  This integration aims to gather comprehensive data on AWS
                  assets, ensuring a centralized viewpoint on exposed resources
                  and potential vulnerabilities.
                </BodyRegular>
              </Flex>
            }
            isConnected={!!me?.customer.aws_details?.enabled}
            onConnect={() => setShowAwsModal(true)}
            onEdit={() => setShowAwsModal(true)}
            onDisconnect={() => handleDisconnectAws()}
          />
          <IntegrationItem
            iconName="okta"
            platform="Okta"
            body={
              <Flex column>
                <BodyRegular>
                  Enable a seamless and secure Single Sign-On (SSO) experience
                  by integrating your Okta account with WASP. Enjoy effortless
                  access and centralized control over user authentication.
                </BodyRegular>
              </Flex>
            }
            isConnected={
              !!me?.customer.okta_info?.org_url &&
              !!me?.customer.okta_info?.client_id
            }
            onConnect={() => setShowOktaModal(true)}
            onEdit={() => setShowOktaModal(true)}
            onDisconnect={() => handleDisconnectOkta()}
          />
        </Flex>
      </Flex>
      {showJiraModal && (
        <JiraIntegrationModal
          onClose={() => {
            Mixpanel.track("Integrate Jira - Close Modal");
            setShowJiraModal(false);
          }}
        />
      )}
      {showCloudflareModal && (
        <CloudflareIntegrationModal
          onClose={() => setShowCloudflareModal(false)}
        />
      )}
      {showAwsModal && (
        <Route53Integration onClose={() => setShowAwsModal(false)} />
      )}
      {showOktaModal && (
        <OktaIntegrationModal onClose={() => setShowOktaModal(false)} />
      )}
      {!!deleteModalProps && <DeleteModal {...deleteModalProps} />}
    </Flex>
  );
};

export default Integrations;
