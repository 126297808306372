import { Flex } from "../../components/layouts/flex/Flex";
import { Box } from "../../components/elements/box/Box";
import { HeaderSecondary } from "../../components/elements/typography/Typography";

export const FindingsExpired = () => {
  return (
    <Flex w100 style={{ height: "calc(100vh - 228px)" }}>
      <Box>
        <HeaderSecondary style={{ padding: "24px" }}>
          We noticed that your account's license has expired. To ensure the
          security of sensitive data on the WASP platform, access to certain
          features has been removed.
        </HeaderSecondary>
        <HeaderSecondary style={{ padding: "24px" }}>
          To regain access and continue benefiting from our comprehensive
          security services, please renew your license as soon as possible.
        </HeaderSecondary>
        <HeaderSecondary style={{ padding: "24px" }}>
          For any assistance or queries regarding license renewal, please
          contact our support team at sales@op-c.net
        </HeaderSecondary>
      </Box>
    </Flex>
  );
};
