import { Box } from "../../components/elements/box/Box";
import { ProjectRequest } from "../../types/Project";
import { SeparatorVertical } from "../../components/elements/separators/SeparatorVertical";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  BodyRegular,
  LabelRegular,
} from "../../components/elements/typography/Typography";
import { IconButton } from "../../components/elements/button/icon/IconButton";
import { getDateTime } from "../../shared/helper";
import { Avatar } from "../../components/elements/avatar/Avatar";
import { useApiAccounts } from "../../hooks/queries/accountsContext";
import { Link } from "react-router-dom";
import { Icon } from "../../components/elements/icon/Icon";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

type Props = {
  projectRequest: ProjectRequest;
};

export const RequestListItem = (props: Props) => {
  const { projectRequest } = props;
  const { data: accounts } = useApiAccounts();
  const user = accounts?.find(
    (a) => a.email === projectRequest.user_email
  )?.user;
  const theme = useContext(ThemeContext);

  const requestStatuses = {
    draft: { iconName: "edit", color: theme.blue300, displayName: "Draft" },
    pending: {
      iconName: "re_test",
      color: theme.primary,
      displayName: "Pending",
    },
    in_review: {
      iconName: "presented",
      color: theme.primary,
      displayName: "In Review",
    },
    done: {
      iconName: "fixed",
      color: theme.greenPrimary,
      displayName: "Completed",
    },
  };

  return (
    <Box>
      <Flex justify="between" align="center" gap="24px">
        <Flex column gap="8px">
          <LabelRegular>Requested By</LabelRegular>
          <Flex align="center" gap="8px">
            <Avatar imageSrc={user?.avatar_url} />
            <LabelRegular>{user?.name}</LabelRegular>
          </Flex>
        </Flex>

        <Flex column gap="8px">
          <LabelRegular>At</LabelRegular>
          <BodyRegular>{getDateTime(projectRequest.created_at)}</BodyRegular>
        </Flex>

        <SeparatorVertical height="64px" />

        <Flex column gap="8px">
          <LabelRegular>status</LabelRegular>
          <Flex gap="8px" align="center">
            <Icon
              name={requestStatuses[projectRequest.status].iconName}
              color={requestStatuses[projectRequest.status].color}
              size={24}
            />
            <BodyRegular>
              {requestStatuses[projectRequest.status].displayName}
            </BodyRegular>
          </Flex>
        </Flex>

        <SeparatorVertical height="64px" />
        <Flex column gap="8px">
          <LabelRegular>Starts on</LabelRegular>
          <Flex gap="8px" align="center">
            <Icon name="projectStart" size={24} color={theme.primary} />
            <BodyRegular>{getDateTime(projectRequest.start_date)}</BodyRegular>
          </Flex>
        </Flex>
        <Flex column gap="8px">
          <LabelRegular>Ends on</LabelRegular>
          <Flex gap="8px" align="center">
            <Icon name="fixed" size={24} color={theme.primary} />
            <BodyRegular>{getDateTime(projectRequest.end_date)}</BodyRegular>
          </Flex>
        </Flex>

        <SeparatorVertical height="64px" />

        <Link to={`/projects/scheduler/${projectRequest.id}`}>
          <IconButton iconName="chevronRight" />
        </Link>
      </Flex>
    </Box>
  );
};
