import { Box } from "../../../../components/elements/box/Box";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { Flex } from "../../../../components/layouts/flex/Flex";

type Props = {};

export const ProjectListSkeleton = (props: Props) => {
  const Card = () => (
    <Box
      className="d-flex gap-16 p-3 align-items-center"
      style={{ height: "98px", width: "100%" }}
    >
      <SkeletonLoading width="25%" height="85%" />
      <SkeletonLoading width="25%" height="85%" />
      <SkeletonLoading width="25%" height="85%" />
      <SkeletonLoading width="25%" height="85%" />
    </Box>
  );

  return (
    <Flex column gap="16px">
      <Card />
      <Card />
    </Flex>
  );
};
