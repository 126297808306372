import React, { useContext, useMemo, useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SendCtiItemModal } from "./SendCtiItemModal";
import { Box } from "../../../components/elements/box/Box";
import { Column, Table } from "../../../components/elements/table/Table";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { CtiItemPane } from "./CtiItemPane";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import {
  useApiCtiItemsPaging,
  useApiDeleteCtiItem,
} from "../../../hooks/queries/ctiItemsContext";
import { TableSkeleton } from "../../../components/elements/table/TableSkeleton";
import { CtiItem } from "../../../types/CtiItem";
import { CtiItemPost } from "./CtiItemPost";
import { getDate } from "../../../shared/helper";
import useToastContext from "../../../hooks/toastHook";

export const CtiManagement = () => {
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const [showCtiItemPane, setShowCtiItemPane] = useState<boolean>(false);
  const [showSendPostModal, setShowSendPostModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [ctiItemToShow, setCtiItemToShow] = useState<CtiItem | null>(null);
  const [ctiItemToDelete, setCtiItemToDelete] = useState<CtiItem | null>(null);
  const {
    data: ctiItems,
    isFetching: isCtiItemFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status: ctiItemsFetchingStatus,
  } = useApiCtiItemsPaging({ "admin-mode": true });
  const {
    mutate: deleteCtiItem,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    reset: resetDelete,
  } = useApiDeleteCtiItem();

  const ctiItemsMapped = useMemo(
    () => ctiItems?.pages.map((page) => page.results || []).flat() || [],
    [ctiItems]
  );

  const columns: Column[] = [
    {
      title: "Post Preview",
      key: "post",
      minWidth: "55%",
      maxWidth: "55%",

      centerCell: true,
      cell: (row: CtiItem) => <CtiItemPost ctiItem={row} width={50} />,
    },
    {
      title: "Num. of Customers",
      key: "customers",
      minWidth: "15%",
      maxWidth: "15%",
      centerCell: true,
      cell: (row) => (
        <BodyRegular className="text-center w-100">
          {row.customers.length}
        </BodyRegular>
      ),
    },
    {
      title: "Sent In",
      key: "date",
      minWidth: "15%",
      maxWidth: "15%",
      centerCell: true,
      cell: (row) => (
        <BodyRegular className="text-center w-100">
          {getDate(row.created_at)}
        </BodyRegular>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      minWidth: "15%",
      maxWidth: "15%",
      centerCell: true,
      cell: (row) => (
        <Flex gap="8px" align="center">
          <IconButton
            iconName={"remove"}
            color={theme.redPrimary}
            label="Remove post"
            onClick={() => {
              setCtiItemToDelete(row);
              setShowDeleteModal(true);
            }}
          />
          <IconButton
            iconName={"chevronRight"}
            size="small"
            label="See more"
            onClick={() => {
              setCtiItemToShow(row);
              setShowCtiItemPane(true);
            }}
          />
        </Flex>
      ),
    },
  ];
  const rows: CtiItem[] = ctiItemsMapped;

  return (
    <Flex column gap="16px" style={{ marginBottom: "16px" }}>
      <Flex justify="between">
        <HeaderMain>CTI Management</HeaderMain>
        <MainButton
          label={"Send CTI Post"}
          size="small"
          iconName="add"
          onClick={() => {
            setShowSendPostModal(true);
          }}
        />
      </Flex>
      {showSendPostModal && (
        <SendCtiItemModal showModal={setShowSendPostModal} />
      )}
      {ctiItemsFetchingStatus === "loading" ? (
        <TableSkeleton />
      ) : (
        <Box>
          <Table
            columns={columns}
            rows={rows}
            rowHeight="300px"
            isFetchingNextPage={isFetchingNextPage}
            onScrollPagination={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isCtiItemFetching}
          />
        </Box>
      )}
      {showCtiItemPane && ctiItemToShow && (
        <CtiItemPane
          onClose={() => setShowCtiItemPane(false)}
          selectedCtiItem={ctiItemToShow}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          itemName={"Post"}
          itemType={""}
          onClose={() => {
            isDeleted && setCtiItemToDelete(null);
            setShowDeleteModal(false);
            resetDelete();
          }}
          onConfirm={() => {
            if (ctiItemToDelete)
              deleteCtiItem({
                ...ctiItemToDelete,
                onSuccessCallback() {},
                onErrorCallback(error) {
                  addToast({
                    type: "error",
                    message: `Failed to delete CTI post. Error: ${error.message}`,
                  });
                },
              });
          }}
          isLoading={isDeleting}
          isSuccess={isDeleted}
        />
      )}
    </Flex>
  );
};
