import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Icon } from "../../icon/Icon";
import { SeparatorVertical } from "../../separators/SeparatorVertical";
import { TypographyLabelMedium } from "../../typography/Typography";
import { Flex } from "../../../layouts/flex/Flex";

export type InnerButtonProp = {
  onClick: () => void;
  iconName: string;
  disabled?: boolean;
  dataTestId?: string;
  id?: string;
};

type Props = {
  buttonsProps: InnerButtonProp[];
  style?: any;
  dataTestid?: string;
  totalPages?: number;
  currentPage?: number;
};

type InnerButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  dataTestId?: string;
};

const ButtonGroupStyle = styled.div`
  ${TypographyLabelMedium}
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  background-color: transparent;
  color: ${(props) => props.theme.primary};
  border: 1px solid ${(props) => props.theme.primary};
  border-radius: 24px;
  &:disabled {
    border: 1px solid ${(props) => props.theme.black600};
  }
  &:focus {
    outline: none;
  }
`;

const InnerButtonStyle = styled.button<InnerButtonProps>`
  background-color: transparent;
  color: ${(props) => props.theme.primary};
  border: 1px solid transparent;
  border-radius: 24px;
  padding: 0;
  height: 32px;
  width: 48px;
  &:hover:enabled {
    background-color: ${(props) => props.theme.blue100};
  }
  &:active:enabled {
    color: ${(props) => props.theme.secondary};
  }
  &:disabled {
    color: ${(props) => props.theme.black600};
  }
`;
export const GroupButton = (props: Props) => {
  const { buttonsProps, dataTestid, style } = props;
  const theme = useContext(ThemeContext);
  return (
    <ButtonGroupStyle data-testid={dataTestid} style={style}>
      {buttonsProps.map((buttonProp, index) => {
        return (
          <Flex key={`button-group-${index}`} align="center" gap="4px">
            <InnerButtonStyle
              onClick={buttonProp.onClick}
              disabled={buttonProp.disabled}
              data-testid={buttonProp.dataTestId}
              id={buttonProp.id}
            >
              <Icon name={buttonProp.iconName} size={24} />
            </InnerButtonStyle>

            {index < buttonsProps.length - 1 && (
              <SeparatorVertical
                style={{
                  height: "16px",
                  borderColor: buttonProp.disabled
                    ? theme.black600
                    : theme.primary,
                }}
              />
            )}
          </Flex>
        );
      })}
    </ButtonGroupStyle>
  );
};

export const GroupNavButton = (props: Props) => {
  const { buttonsProps, dataTestid, style, totalPages, currentPage } = props;
  return (
    <ButtonGroupStyle data-testid={dataTestid} style={style}>
      <Flex key={`button-group-0`} align="center" gap="4px">
        {buttonsProps[0] && (
          <InnerButtonStyle
            onClick={buttonsProps[0]?.onClick}
            disabled={buttonsProps[0]?.disabled}
            data-testid={buttonsProps[0].dataTestId}
            id={buttonsProps[0].id}
          >
            <Icon name={buttonsProps[0].iconName} size={24} />
          </InnerButtonStyle>
        )}
      </Flex>

      {totalPages && currentPage && (
        <Flex>
          {currentPage} of {totalPages}
        </Flex>
      )}

      <Flex key={`button-group-1`} align="center" gap="4px">
        {buttonsProps[1] && (
          <InnerButtonStyle
            onClick={buttonsProps[1]?.onClick}
            disabled={buttonsProps[1]?.disabled}
            data-testid={buttonsProps[1].dataTestId}
            id={buttonsProps[1].id}
          >
            <Icon name={buttonsProps[1].iconName} size={24} />
          </InnerButtonStyle>
        )}
      </Flex>
    </ButtonGroupStyle>
  );
};
