import styled, { ThemeContext } from "styled-components";
import { Flex } from "../../layouts/flex/Flex";
import { ReactNode, useContext } from "react";

type Props = {
  color?: string;
  top?: string;
  left?: string;
  opacity?: number;
  transform?: string;
  width?: string;
  height?: string;
  children?: ReactNode;
};

const HexagonContainer = styled.div<Props>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  opacity: ${(props) => props.opacity};
  width: ${(props) => props.width || "200px"};
  height: ${(props) => props.height || "160px"};
  background-color: ${(props) => props.color};
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transform: ${(props) => props.transform || ""};
`;

export const Hexagon = (props: Props) => {
  const {
    children,
    color,
    opacity = 1,
    top = "50%",
    left = "50%",
    width,
    height,
    transform,
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <Flex style={{ position: "relative" }}>
      <HexagonContainer
        top={top}
        left={left}
        color={color || theme.gray500}
        opacity={opacity}
        width={width}
        height={height}
        transform={transform}
      >
        {children && children}
      </HexagonContainer>
    </Flex>
  );
};
