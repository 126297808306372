import React, { useContext, useState } from "react";
import { Issue } from "./QuickScanResultsBL";
import { Flex } from "../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../components/elements/button/icon/IconButton";
import {
  BodyMedium,
  LabelRegular,
} from "../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../components/elements/separators/SeparatorHorizontal";

type Props = {
  issue: Issue;
};

export const QuickScanResultsViewDetailsIssue = ({ issue }: Props) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const theme = useContext(ThemeContext);
  return (
    <Flex column gap="8px">
      <Flex align="center" gap="8px">
        <IconButton
          iconName={isDetailsOpen ? "chevronDown" : "chevronRight"}
          color={theme.primary}
          onClick={() => setIsDetailsOpen(!isDetailsOpen)}
          size="small"
        />

        <Flex column>
          <BodyMedium>{issue.title}</BodyMedium>
          <LabelRegular>{issue.description}</LabelRegular>
        </Flex>
      </Flex>
      {isDetailsOpen && (
        <>
          <SeparatorHorizontal />
          {issue.details}
          {issue.mitigation}
          <SeparatorHorizontal />
        </>
      )}
    </Flex>
  );
};
