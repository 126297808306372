export type GlobalSearchDataItem = {
  id: number;
  name: string;
};
export interface GlobalSearchData {
  products: GlobalSearchDataItem[];
  projects: GlobalSearchDataItem[];
  assets: GlobalSearchDataItem[];
  findings: GlobalSearchDataItem[];
}

export const emptyGlobalSearchData = {
  products: [],
  projects: [],
  assets: [],
  findings: [],
};
