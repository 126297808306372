import { Illustration } from "../../../components/elements/illustrations/Illustration";
import {
  BodyRegular,
  BodyRegularHover,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  warningType:
    | "verification"
    | "failed_get_scans"
    | "failed_run_scan"
    | "success";
  runScan?: () => void;
};

export const AssetsScanWarning = (props: Props) => {
  const { warningType, runScan } = props;
  return (
    <Flex
      column
      gap="32px"
      align="center"
      justify="center"
      style={{ width: "1400px", height: "500px", position: "relative" }}
    >
      <Illustration name={warningType === "success" ? "success" : "error"} />
      {warningType === "verification" && (
        <>
          <HeaderMain>No verified domain found</HeaderMain>
          <BodyRegular>
            Please go back to stage 2 and verify your domain ownership
          </BodyRegular>
        </>
      )}
      {warningType === "failed_run_scan" && (
        <>
          <HeaderMain>
            An error has occurred while scanning for sub-domains
          </HeaderMain>
          <BodyRegularHover onClick={() => runScan && runScan()}>
            Please try again
          </BodyRegularHover>
        </>
      )}
      {warningType === "failed_get_scans" && (
        <>
          <HeaderMain>
            An error has occurred while connecting to scanner
          </HeaderMain>
          <BodyRegular>Please try again in a while</BodyRegular>
        </>
      )}
      {warningType === "success" && (
        <>
          <HeaderMain>Congratulations</HeaderMain>
          <BodyRegular>You have completed all the steps</BodyRegular>
        </>
      )}
    </Flex>
  );
};
