import { ChangeEvent } from "react";

export const isListCommaSeparated = (listString: string): boolean => {
  return !!listString.indexOf(",");
};

export const textToList = (str: string): string[] =>
  str
    .replaceAll("\n", "")
    .split(",")
    .filter((s) => !!s)
    .map((s) => s.trim())
    .filter((s) => validateDomainName(s) || validateIP(s));

export const handleFileUpload = (
  event: ChangeEvent<HTMLInputElement>,
  callback: (e: ProgressEvent<FileReader>) => void
): void => {
  const fileList = event.target.files;
  if (fileList && fileList.length > 0) {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      callback(e);
    };
    reader.readAsText(fileList[0]);
  }
};

export const validateDomainName = (domainName: string): boolean => {
  const domainRegex =
    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,}$/;
  return domainRegex.test(domainName);
  // GS: this could be improved with some extracting tld library
};

export const validateIP = (ip: string): boolean => {
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipRegex.test(ip);
};
