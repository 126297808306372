import { IconProps } from "./WaspIcon";

export const TicketsIcon = (props: IconProps) => {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.5"
        y="4.5"
        width="15"
        height="15"
        rx="5.25"
        fill="none"
        stroke={props.color || "currentColor"}
        strokeWidth="1.5"
      />
      <path
        d="M12.5993 8.38662V13.7696C12.5993 13.9287 12.5361 14.0813 12.4236 14.1938C12.311 14.3064 12.1584 14.3696 11.9993 14.3696C11.8402 14.3696 11.6875 14.3064 11.575 14.1938C11.4625 14.0813 11.3993 13.9287 11.3993 13.7696V8.38662C11.3993 8.22749 11.4625 8.07488 11.575 7.96236C11.6875 7.84984 11.8402 7.78662 11.9993 7.78662C12.1584 7.78662 12.311 7.84984 12.4236 7.96236C12.5361 8.07488 12.5993 8.22749 12.5993 8.38662ZM12.5993 15.64C12.5993 15.7587 12.5641 15.8747 12.4982 15.9734C12.4322 16.072 12.3385 16.1489 12.2289 16.1943C12.1193 16.2398 11.9986 16.2516 11.8822 16.2285C11.7658 16.2053 11.6589 16.1482 11.575 16.0643C11.4911 15.9804 11.434 15.8735 11.4108 15.7571C11.3877 15.6407 11.3996 15.52 11.445 15.4104C11.4904 15.3008 11.5673 15.2071 11.6659 15.1411C11.7646 15.0752 11.8806 15.04 11.9993 15.04C12.1584 15.04 12.311 15.1032 12.4236 15.2158C12.5361 15.3283 12.5993 15.4809 12.5993 15.64Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
};
