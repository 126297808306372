import styled from "styled-components";
import { TypographyLabelRegular } from "../typography/Typography";

interface iToggleStyle {
  width: string;
}

type SliderProps = {
  checked: boolean;
  disabled: boolean;
};

export const ToggleStyle = styled.label<iToggleStyle>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.width ? props.width : "480px")};
  height: 34px;
  font-size: 12px;
  padding: 4px;
`;

export const SliderStyle = styled.span<SliderProps>`
  position: absolute;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 0;
  background-color: ${(props) =>
    props.disabled ? props.theme.bg2 : props.theme.shadowBlueLight};
  transition: all 0.4s linear;
  border-radius: 1.4em;
  ${(props) => props.disabled && `border: 1px solid ${props.theme.bg2};`}
  &:before {
    transition: all 0.4s linear;
    position: absolute;
    content: "";
    height: 26px;
    width: 50%;
    left: ${(props) => (props.checked ? "6px" : "calc(50% - 6px)")};
    bottom: 4px;
    background-color: ${(props) =>
      props.disabled ? props.theme.black400 : props.theme.bg2};
    border-radius: 1em;
    box-shadow: ${(props) => `0px 1px 5px ${props.theme.grayShadow}`};
  }
`;

export const SliderWithIconStyle = styled(SliderStyle)`
  background-color: ${(props) => props.theme.bg2};
  border-radius: 24px;
  border: 1px solid
    ${(props) => (props.disabled ? props.theme.black400 : props.theme.black500)};
  &:before {
    height: 32px;
    width: 50%;
    left: ${(props) => (props.checked ? "4px" : "calc(50% - 4px)")};
    bottom: 4px;
    background-color: ${(props) =>
      props.disabled ? props.theme.black400 : props.theme.blue100};
    border-radius: 24px;
    pointer-events: none;
  }
`;

export const ToggleTextWrapperStyle = styled.div<SliderProps>`
  ${TypographyLabelRegular}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: ${(props) => (props.checked && !props.disabled ? 600 : 400)};
  color: ${(props) =>
    props.disabled
      ? props.theme.black600
      : props.checked
      ? props.theme.toggleOn
      : props.theme.toggleOff};
`;
