import React from "react";
import { IconProps } from "./WaspIcon";

export function SendIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8183 3.80519C18.5446 3.63064 18.2288 3.53335 17.9043 3.52363C17.5798 3.51392 17.2587 3.59213 16.9751 3.74999L5.27387 10.2552C5.00113 10.4066 4.77244 10.6263 4.61021 10.8928C4.44797 11.1593 4.35778 11.4633 4.34846 11.7751C4.33914 12.0869 4.41102 12.3958 4.55706 12.6715C4.70309 12.9472 4.91826 13.1801 5.18147 13.3476L8.68427 15.5784C8.48565 16.0769 8.45813 16.6272 8.60603 17.143C8.75393 17.6588 9.06888 18.1109 9.50147 18.4284C9.97211 18.6859 10.5149 18.78 11.0447 18.696C11.5746 18.6119 12.0616 18.3545 12.4295 17.964L15.9323 20.196C16.1952 20.3629 16.497 20.4587 16.808 20.4741C17.1191 20.4895 17.4288 20.4239 17.7069 20.2837C17.985 20.1436 18.222 19.9337 18.3947 19.6745C18.5674 19.4154 18.67 19.1158 18.6923 18.8052L19.6439 5.45039C19.6671 5.12725 19.6026 4.80384 19.4573 4.51428C19.312 4.22472 19.0912 3.97974 18.8183 3.80519ZM5.82707 12.3348C5.7393 12.279 5.66752 12.2014 5.61877 12.1096C5.57003 12.0177 5.54599 11.9148 5.54901 11.8109C5.55204 11.7069 5.58202 11.6056 5.63603 11.5167C5.69003 11.4278 5.7662 11.3545 5.85707 11.304L17.4251 4.87319L9.77627 16.8792C9.71528 16.4389 9.77889 15.9904 9.95987 15.5844C10.0213 15.4524 10.0327 15.3026 9.99186 15.1628C9.95101 15.023 9.86074 14.9029 9.73787 14.8248L5.82707 12.3348ZM17.4983 18.72C17.4909 18.8238 17.4567 18.9238 17.399 19.0104C17.3414 19.0969 17.2622 19.167 17.1692 19.2137C17.0763 19.2604 16.9728 19.2822 16.8689 19.2768C16.765 19.2715 16.6643 19.2392 16.5767 19.1832L12.6671 16.6932C12.5716 16.6317 12.4603 16.5992 12.3467 16.5996C12.2589 16.5993 12.1722 16.6183 12.0926 16.6552C12.0129 16.6921 11.9424 16.7461 11.8859 16.8132C11.5929 17.1459 11.2138 17.3913 10.7903 17.5224L18.4391 5.52239L17.4983 18.72Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
