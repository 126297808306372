import { Flex } from "../../components/layouts/flex/Flex";
import { RiskScoreCard } from "./topBlocks/riskScore/RiskScoreCard";
import ActionItems from "../findings/topBlocks/ActionItems";
import { useApiFindingsCounts } from "../../hooks/queries/findingContext";
import { Box } from "../../components/elements/box/Box";
import { LastFindings } from "../findings/latestFindings/LastFindings";
import { useNavigate } from "react-router";

export const MobileDashboard = () => {
  const navigate = useNavigate();
  const { data: findingsCounts, isFetching: isFetchingFindingsCounts } =
    useApiFindingsCounts();
  return (
    <Flex column w100 gap="16px">
      <RiskScoreCard />
      <Box
        data-tut="findings-action"
        className=" h-auto d-flex flex-column gap-16"
        style={{
          width: "100%",
          padding: "8px",
        }}
      >
        <ActionItems
          findingsCounts={findingsCounts}
          isFetching={!findingsCounts && isFetchingFindingsCounts}
        />
      </Box>
      <LastFindings
        onOpenProjectPane={() => {}}
        onSelectedFinding={(finding) =>
          navigate(`/finding-details/${finding.id}`)
        }
      />
    </Flex>
  );
};
