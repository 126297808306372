import React from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";

type Props = {};

export const JiraProjectsListSkeleton = (props: Props) => {
  return (
    <Flex column gap="16px" w100 h100 style={{ overflow: "scroll" }}>
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <SkeletonLoading
          key={`loading-project-${i}`}
          height="53px"
          width="387px"
        />
      ))}
    </Flex>
  );
};
