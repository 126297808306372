import { useEffect, useMemo, useState } from "react";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { useApiAssetsPaging } from "../../../hooks/queries/assetsContext";
import { Asset } from "../../../types/Asset";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ScanEvent } from "../../../types/ScanEvent";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Illustration } from "../../../components/elements/illustrations/Illustration";

type Props = {
  runScan: () => void;
  scan: ScanEvent | null | undefined;
  isStarting: boolean;
  isScanning: boolean;
};

export const StartSublisterScan = (props: Props) => {
  const { runScan, scan, isStarting, isScanning } = props;
  const [assetToEnumerate, setAssetToEnumerate] = useState<Asset | null>();

  const { data: assetsPages } = useApiAssetsPaging({}, true);

  const assets = useMemo(
    () => assetsPages?.pages.map((page) => page.results || []).flat() || [],
    [assetsPages]
  );

  useEffect(() => {
    // Set the inner stage (add asset -> verify -> enumerate)
    if (!assets?.length) return;
    const assetToEnumerate = assets?.find((a) => a.is_verified);
    if (assetToEnumerate) setAssetToEnumerate(assetToEnumerate);
  }, [assets]);

  return (
    <Flex
      column
      gap="32px"
      align="center"
      justify="center"
      style={{ width: "1400px", height: "500px", position: "relative" }}
    >
      <Illustration name="search" />
      <MainButton
        label={
          !!scan
            ? `Enumerating subdomains for ${assetToEnumerate?.name}  `
            : `Start Subdomains Enumeration for ${assetToEnumerate?.name}`
        }
        onClick={runScan}
        inProgress={isStarting || isScanning || scan?.status === "running"}
        disabled={isStarting || isScanning || !!scan}
        dataTestId="start-scan-btn"
        size="medium"
      />
      {scan?.status === "running" && (
        <BodyRegular>This process may take several minutes...</BodyRegular>
      )}
    </Flex>
  );
};
