import React, { useMemo, useState } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import {
  HeaderSub,
  LabelBold,
} from "../../../../components/elements/typography/Typography";
import { Box } from "../../../../components/elements/box/Box";
import { useApiCustomers } from "../../../../hooks/queries/customersContext";
import { CtiItem } from "../../../../types/CtiItem";

type Props = {
  selectedCtiItem: CtiItem;
};

export const CtiCustomersTab = (props: Props) => {
  const { selectedCtiItem } = props;
  const { data: customers } = useApiCustomers();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const ctiItemCustomersNames = useMemo(() => {
    return (
      customers
        ?.filter((c) => selectedCtiItem.customers.includes(c.id))
        .map((c) => c.name)
        .filter(
          (c) =>
            searchTerm === "" ||
            c.toLowerCase().includes(searchTerm.toLowerCase())
        ) || []
    );
  }, [customers, selectedCtiItem, searchTerm]);

  return (
    <Flex h100 style={{ overflowY: "auto" }} column gap="8px">
      <Flex w100 style={{ marginBottom: "8px" }} align="center" gap="16px">
        <InputText
          isClearable
          onClear={() => {
            setSearchTerm("");
          }}
          iconName="search"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          value={searchTerm}
        />
        <HeaderSub>{`Total: ${ctiItemCustomersNames.length}`}</HeaderSub>
      </Flex>
      {ctiItemCustomersNames.map((c) => (
        <Box>
          <LabelBold>{c}</LabelBold>
        </Box>
      ))}
    </Flex>
  );
};
