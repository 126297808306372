import { TextButton } from "../../../components/elements/button/text/TextButton";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Finding, FindingsCounts } from "../../../types/Finding";

type Props = {
  selectedFindings?: Finding[];
  isSelectAll: boolean;
  findingsCounts?: FindingsCounts;
  setIsSelectAll: (state: boolean) => void;
  setSelectedFindings: (findings: Finding[]) => void;
};

export const FindingsBulkCounters = (props: Props) => {
  const {
    selectedFindings,
    isSelectAll,
    findingsCounts,
    setIsSelectAll,
    setSelectedFindings,
  } = props;

  return (
    <>
      {!!selectedFindings?.length && (
        <Flex>
          <Flex gap="8px" align="center" style={{ width: "280px" }}>
            {isSelectAll && <BodyRegular>All </BodyRegular>}
            <BodyBold>
              {isSelectAll ? findingsCounts?.total : selectedFindings.length}
            </BodyBold>
            <BodyRegular>
              {selectedFindings.length > 1 ? "Findings " : "Finding "}
              {!isSelectAll && "on this page "}
              are selected.
            </BodyRegular>
          </Flex>

          <Flex style={{ width: "200px" }}>
            {isSelectAll ? (
              <TextButton
                label="Clear Selection"
                fontSize="14px"
                onClick={() => {
                  setIsSelectAll(false);
                  setSelectedFindings([]);
                }}
              />
            ) : (
              <TextButton
                fontSize="14px"
                onClick={() => setIsSelectAll(true)}
                label={`Select All ${findingsCounts?.total} Findings`}
              />
            )}
          </Flex>
        </Flex>
        // </Flex>
      )}
    </>
  );
};
