import AlertBanner from "../../../components/elements/toastTypes/AlertBanner";
import {
  LabelBold,
  LabelMedium,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiSingleCustomer } from "../../../hooks/queries/customersContext";

export const DifferentCustomerWarning = ({
  customerId,
}: {
  customerId?: number;
}) => {
  const { data: customer } = useApiSingleCustomer(customerId);

  return (
    <Flex w100 style={{ marginBottom: "16px" }}>
      <AlertBanner
        message={
          <>
            <LabelBold>Attention!</LabelBold>
            <LabelMedium>
              You are viewing a finding of a different customer (
              {customer?.name}) from the one you are assigned to.
            </LabelMedium>
          </>
        }
        type="warning"
      />
    </Flex>
  );
};
