import React, { useMemo } from "react";
import { useApiWaspAIConversations } from "./context/AIContext";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderMain,
  LabelBold,
} from "../../components/elements/typography/Typography";
import { Box } from "../../components/elements/box/Box";
import { Column, Table } from "../../components/elements/table/Table";
import { IconButton } from "../../components/elements/button/icon/IconButton";
import { Avatar } from "../../components/elements/avatar/Avatar";
import GrayAvatar from "../../assets/images/avatars/GrayAvatar.svg";
import { useNavigate } from "react-router";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";
import { getDate, getTime } from "../../shared/helper";

export const WaspAIConversations = () => {
  const navigate = useNavigate();

  const {
    data: conversations,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useApiWaspAIConversations(true);

  const allConversations = useMemo(
    () => conversations?.pages.map((page) => page.results || []).flat() || [],
    [conversations]
  );

  const isSuperuser = useIsSuperuser();

  if (!isSuperuser)
    return (
      <HeaderMain>
        Access Denied: You do not have the necessary permissions to view this
        page.
      </HeaderMain>
    );

  const columns: Column[] = [
    {
      title: "Profile",
      key: "profile",
      centerCell: true,
      cell: (row) => (
        <BodyRegular className="text-center w-100">
          <Avatar
            size={36}
            imageSrc={allConversations && row.avatar ? row.avatar : GrayAvatar}
          />
        </BodyRegular>
      ),
    },
    {
      title: "Account",
      key: "account",
      centerCell: true,

      cell: (row) => (
        <BodyRegular className="w-100 text-center">
          {allConversations ? row.account_name : "Unknown"}
        </BodyRegular>
      ),
    },
    {
      title: "Customer",
      key: "customer",
      centerCell: true,

      cell: (row) => (
        <BodyRegular className="w-100 text-center">
          {allConversations ? row.customer_name : "Unknown"}
        </BodyRegular>
      ),
    },
    {
      title: "Creation date",
      key: "date",
      centerCell: true,

      cell: (row) => (
        <BodyRegular className="w-100 text-center">
          {allConversations
            ? `${getDate(new Date(parseInt(row.created_at)))} - ${getTime(new Date(parseInt(row.created_at)))}`
            : "Unknown"}
        </BodyRegular>
      ),
    },
    {
      title: "Go to Chat",
      key: "conversation",
      centerCell: true,
      cell: (row) => (
        <IconButton
          iconName={"chevronRight"}
          onClick={() => {
            navigate(`/wasp-ai-conversations/${row.thread_id}`);
          }}
        />
      ),
    },
  ];

  const rows = allConversations ? allConversations : [];

  return (
    <Flex column gap="16px" style={{ paddingBottom: "8px" }}>
      <HeaderMain>WASP AI Conversations</HeaderMain>
      <Box>
        <Table
          columns={columns}
          rows={rows}
          hasScrollPagination
          isLoading={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          onScrollPagination={fetchNextPage}
          hasNextPage={hasNextPage}
        />
        {rows.length === 0 && (
          <Flex w100 h100 padding="16px" justify="center" align="center">
            <LabelBold>{"No conversations found."}</LabelBold>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
