import styled from "styled-components";

export const Box = styled.div`
  background-color: ${(props) => props.theme.bg2};
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.black500};
  transform: 0.4s;
`;

export const HoverBox = styled.div`
  background-color: ${(props) => props.theme.bg2};
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.black500};
  transform: 0.4s;
  &:hover {
    background-color: ${(props) => props.theme.blue50};
    border: 1px solid ${(props) => props.theme.primary};
  }
`;

export const CodeBox = styled.div`
  padding: 12px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.black500};
  transform: 0.4s;  background-color: ${(props) => props.theme.black300};
  border: 1px solid: ${(props) => props.theme.grayBlue50};
  border-top-right-radius: 0;
  font-family: Courier;
`;
