import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AssetComment, AssetCommentsPage } from "../../types/AssetComment";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
  updateContext,
} from "./utils";
import { bitAfterNow, getQueryParams } from "../../shared/helper";
import { createItem, deleteItem, getPage, updateItem } from "./sdk";

const key = "assets-comments";
const logsKey = "assets-logs";
// All possible attributes that can pass to API call
interface AssetCommentParams {}

export interface AssetCommentContext {
  assetCommentId: number;
  assetCommentData: AssetCommentParams;
}

interface UpdateAssetCommentContext {
  commentData: AssetCommentContext;
  onSuccessCallback?: (data: AssetComment) => void;
  onErrorCallback?: (error: Error) => void;
}

interface DeleteAssetCommentContext {
  commentId: number;
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiAssetCommentsPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useInfiniteQuery<AssetCommentsPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<AssetCommentsPage> =>
      getPage(key, { ...filters, page: pageParam }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiCreateAssetComment = () => {
  const queryClient = useQueryClient();
  return useMutation<AssetComment, Error, AssetCommentParams>({
    mutationKey: [key],
    mutationFn: async (newComment): Promise<AssetComment> =>
      await createItem(key, newComment),
    onError: (error) => console.log(`rolling back optimistic update. ${error}`),
    onSuccess: (data) =>
      updateContext({ data, queryKey: [key, { id: data?.id }], queryClient }),
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries([logsKey], queryClient);
    },
  });
};

export const useApiUpdateAssetComment = () => {
  // api/v1/findings-comments/:id/
  const queryClient = useQueryClient();
  return useMutation<AssetComment, Error, UpdateAssetCommentContext>({
    mutationKey: [key, "update"],
    mutationFn: async ({ commentData }): Promise<AssetComment> =>
      await updateItem(
        key,
        commentData.assetCommentId,
        commentData.assetCommentData
      ),
    onSuccess: (data: AssetComment, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries([logsKey], queryClient);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiDeleteAssetComment = () => {
  const queryClient = useQueryClient();
  return useMutation<undefined, Error, DeleteAssetCommentContext>({
    mutationKey: [key],
    mutationFn: async ({
      commentId,
    }: DeleteAssetCommentContext): Promise<any> =>
      await deleteItem(key, {}, commentId),
    // onSuccess: (data, variables) =>
    onSuccess: (data: undefined, { commentId, onSuccessCallback }) => {
      removeItemFromContext({
        data,
        queryKey: [key, { id: commentId }],
        queryClient,
      });
      onSuccessCallback && onSuccessCallback();
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries([logsKey], queryClient);
    },
  });
};
