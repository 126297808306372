import React, { useContext } from "react";
import { Box } from "../../components/elements/box/Box";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";
import { ThemeContext } from "styled-components";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  HeaderSecondaryRegular,
  HeaderSubBold,
} from "../../components/elements/typography/Typography";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";
import DemoImage from "../../assets/images/DemoImage.png";

export const QuickScanResultsFooter = () => {
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const theme = useContext(ThemeContext);

  return (
    <Box
      style={{
        width: isMobile ? "99%" : "70%",
        backgroundColor: theme.primary,
        borderColor: theme.primary,
        position: "relative",
        padding: "16px",
      }}
    >
      <Flex gap="16px">
        <Flex
          column
          gap="8px"
          style={{
            width: isMobile ? "100%" : "50%",
          }}
        >
          <HeaderSecondaryRegular
            style={{
              color: theme.white100,
            }}
          >
            Join WASP
          </HeaderSecondaryRegular>
          <HeaderSubBold
            style={{
              color: theme.white100,
            }}
          >
            Explore our recommendations to help you achieve a higher security
            grade.
          </HeaderSubBold>
          <SecondaryButton
            label="Get's Started"
            onClick={() => window.location.assign("/register")}
            size="small"
            variant="white"
            style={{
              width: "fit-content",
            }}
          />
        </Flex>
        {!isMobile && (
          <img
            src={DemoImage}
            alt="demo"
            style={{
              height: "140px",
              width: "auto",
              position: "absolute",
              bottom: "0",
              right: "0",
            }}
          />
        )}
      </Flex>
    </Box>
  );
};
