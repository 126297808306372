// Taken from https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips //

export function getTooltip(
  context: any,
  theme: any,
  chartType: "doughnut" | "bar" | "radar",
  totals: number = 0,
  footer?: string
) {
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip" + chartType);

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip" + chartType;
    tooltipEl.innerHTML = "<table></table>";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem: any) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);
    let innerHtml = "<thead>";

    const isRadarZeroPoint =
      chartType === "radar" &&
      !!bodyLines?.length &&
      !!bodyLines[0].length &&
      bodyLines[0][0].split(":")[1].replace(/\s/g, "") === "0";

    if (isRadarZeroPoint) {
      context.tooltip.dataPoints?.forEach((point: any, i: number) => {
        const colors = tooltipModel.labelColors[i];
        let style = "background:" + colors.borderColor;
        style += "; border:1px solid " + colors.borderColor;
        style += "; color:" + colors.borderColor;
        style += "; margin-right:8px; border-radius: 50%; font-size: 12px";
        const circle = '<span style="' + style + '">00</span>';
        innerHtml += `<div style="color:${theme.textSecondary}"> ${circle} ${point.dataset.label} - ${point.label} : 0 </div>`;
      });
    } else {
      titleLines.forEach(function (title: string, i: number) {
        const colors = tooltipModel.labelColors[i];
        let style = "background:" + colors.backgroundColor;
        style += "; border-color:" + colors.borderColor;
        style += "; color:" + colors.backgroundColor;
        style += "; margin-right:8px; border-radius: 50%";
        const circle =
          chartType !== "radar"
            ? '<div><span class="circle" style="' + style + '">00</span>'
            : "";
        innerHtml +=
          `<div style="color:${theme.textSecondary}">` +
          circle +
          title +
          "</div>";
      });
      innerHtml += "</thead><hr><tbody>";
      bodyLines.forEach((body: string, i: number) => {
        const colors = tooltipModel.labelColors[i];
        const value = parseInt(body[0].split(":")[1]);
        let style = "background:" + colors.borderColor;
        style += "; border:1px solid " + colors.borderColor;
        style += "; color:" + colors.borderColor;
        style += "; margin-right:8px; border-radius: 50%; font-size: 12px";

        const circle =
          chartType === "radar" ? '<span style="' + style + '">00</span>' : "";
        const percentSign = chartType === "radar" ? "%" : "";

        innerHtml +=
          `<tr><td style="color:${theme.textSecondary}">` +
          circle +
          body +
          percentSign;
        if (chartType === "doughnut") {
          const percent = Math.round((value / totals) * 100);
          innerHtml += `<div>${percent}% of total findings</div>`;
        }
        innerHtml += `</td></tr>`;
      });
      if (footer)
        innerHtml += `<tr><td></br><span style="color:${theme.blue600}">${footer}</span></td></tr>`;
      innerHtml += "</tbody>";
    }
    let tableRoot = tooltipEl.querySelector("table");
    //@ts-ignore
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  //@ts-ignore
  const bodyFont = "poppins";

  // Display, position, and set styles for font
  tooltipEl.style.opacity = "1";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left =
    position.left + window.pageXOffset + tooltipModel.caretX + "px";
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY + "px";
  tooltipEl.style.font = bodyFont;
  tooltipEl.style.padding = "8px";
  tooltipEl.style.pointerEvents = "none";
  tooltipEl.style.background = theme.bg2;
  // tooltipEl.style.color = theme.textSub;
  tooltipEl.style.border = `1px solid ${theme.separation}`;
  tooltipEl.style.borderRadius = "0 16px 16px 16px";
  tooltipEl.style.fontSize = "14px";
  tooltipEl.style.transition = "0.4s ease-in-out";
}
