import React from "react";
import { Action, FindingLog } from "../../../types/FindingLog";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { CardHeader } from "./CardHeader";

type Props = {
  findingLog: FindingLog;
};

export function FindingLogCard({ findingLog }: Props) {
  const getActionText = (action: Action): string => {
    switch (action) {
      case "FINDING_DELETED":
        return "Finding has been deleted";
      case "FINDING_CREATED":
        return "Finding has been created";
      case "SENT_TO_JIRA":
        return "Finding has been sent to Jira";
      case "BREACHED_SLA":
        return "Finding has breached SLA";
      default:
        return "Finding has been Changed";
    }
  };

  return (
    <Box>
      <Flex column gap="16px">
        <CardHeader
          userName={findingLog.actor.name}
          avatarUrl={findingLog.actor.avatar_url}
          createdAt={findingLog.created_at}
        />

        <Flex align="center" gap="8px" flexWrap>
          <BodyRegular>{getActionText(findingLog.action)}</BodyRegular>
        </Flex>
      </Flex>
    </Box>
  );
}
