import { Flex } from "../../../components/layouts/flex/Flex";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { DatePicker } from "../../../components/elements/datetimePicker/DatePicker";
import { Filters } from "../Findings";

type Props = {
  newFilters: Filters;
  onChange: (condition: string, date: string) => void;
};

const conditionOptions = [
  { label: "All", value: "all" },
  { label: "Breached On", value: "sla_expires_at" },
  { label: "Breached After", value: "sla_expires_after" },
];

export const SlaExpiration = (props: Props) => {
  const { newFilters, onChange } = props;

  const condition = Object.keys(newFilters).includes("sla_expires_at")
    ? "sla_expires_at"
    : Object.keys(newFilters).includes("sla_expires_after")
    ? "sla_expires_after"
    : "all";

  const date = newFilters.sla_expires_after || newFilters.sla_expires_at || "";

  return (
    <Flex gap="16px">
      <Dropdown
        value={conditionOptions.find((c) => c.value === condition)}
        size="medium"
        options={conditionOptions}
        variant="border"
        onChange={(opt) => onChange(`${opt?.value}`, date)}
        width="200px"
        dataTestId="sla-condition"
      />
      <DatePicker
        disabled={condition === "all"}
        value={date}
        width="200px"
        onChange={(e) => onChange(condition, e.target.value)}
        dataTestId="sla-date"
      />
    </Flex>
  );
};
