import React, { useContext, useEffect, useRef, useState } from "react";
import { Flex } from "../../layouts/flex/Flex";
import { BadgesCompact } from "./BadgesCompact";
import { Badge } from "./Badge";
import { ThemeContext } from "styled-components";
import { LabelMedium } from "../typography/Typography";

type Props = {
  badges: string[];
  variant?: "primary" | "secondary" | "dead";
  noWrap?: boolean;
  gap?: number;
  badgeMaxWidth?: string;
};

export const BadgesLine = (props: Props) => {
  const {
    badges,
    variant = "primary",
    noWrap = false,
    gap = 8,
    badgeMaxWidth = "250px",
  } = props;
  const [amountOfTagsToShow, setAmountOfTagsToShow] = useState<number | null>(
    null
  );

  const theme = useContext(ThemeContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    var amountOfTagsAllowed = 0;
    if (!Array.isArray(badges)) return;
    if (
      noWrap &&
      badges?.length &&
      (ref?.current?.scrollWidth || 0) > (ref?.current?.offsetWidth || 0)
    ) {
      const availablePixels = (ref?.current?.offsetWidth || 0) - 32;
      var pixelCount = 0;
      // tag width = 24
      // char width = 8
      badges?.forEach((tag) => {
        pixelCount += 24 + 8 * tag.length;
        if (pixelCount < availablePixels) {
          amountOfTagsAllowed++;
        } else {
          // if the next tag is too long, don't show the rest of the tags
          pixelCount = availablePixels;
        }
      });
      setAmountOfTagsToShow(amountOfTagsAllowed);
    }
  }, [ref, badges, noWrap]);

  if (!Array.isArray(badges)) return <></>;

  const color =
    variant === "primary"
      ? theme.blue700
      : variant === "dead"
        ? theme.black600
        : theme.blue800;
  const backgroundColor =
    variant === "primary"
      ? theme.blue100
      : variant === "dead"
        ? theme.black400
        : theme.blue500;

  return (
    <Flex
      align="center"
      gap={`${gap || 8}px`}
      ref={ref}
      flexWrap={!noWrap}
      style={{ overflowX: "auto" }}
    >
      {(badges || [])
        ?.slice(
          0,
          amountOfTagsToShow === null ? badges.length : amountOfTagsToShow
        )
        ?.map((tag: string, index: number) => (
          <Badge
            className="d-flex"
            key={`badge-line-${index}`}
            style={{
              maxWidth: badgeMaxWidth,
              color,
              backgroundColor,
            }}
          >
            <LabelMedium color={color} title={tag} className="text-truncate">
              {tag}
            </LabelMedium>
          </Badge>
        ))}
      {amountOfTagsToShow !== null &&
        badges.length - amountOfTagsToShow > 0 && (
          <BadgesCompact
            tags={badges}
            amountOfTagsToShow={amountOfTagsToShow}
            color={color}
            backgroundColor={backgroundColor}
          />
        )}
    </Flex>
  );
};
