import styled from "styled-components";
import { BodyBold, LabelMini } from "../typography/Typography";
import { Flex } from "../../layouts/flex/Flex";
import { TrendDownRedIcon, TrendUpGreenIcon } from "../icon/TrendUpGreenIcon";

type MeterStyleProps = {
  value?: number;
  min?: number;
  max?: number;
  optimum?: number;
};

const MeterStyle = styled.meter<MeterStyleProps>`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 10px;
  appearance: none;
  border-radius: 2rem;
  background: ${(props) => props.theme.black500};

  /* Chrome/Edge */
  &::-webkit-meter-bar {
    background: ${(props) => props.theme.black500};
    border-radius: 5px;
    height: 12px;
  }

  &::-webkit-meter-optimum-value {
    background: ${(props) => props.theme.blue500};
    border-radius: 2rem;
  }

  &::-webkit-meter-suboptimum-value {
    background: ${(props) => props.theme.blue500};
    border-radius: 2rem;
  }

  &::-webkit-meter-even-less-good-value {
    background: ${(props) => props.theme.blue500};
    border-radius: 2rem;
  }

  /* Firefox */
  &::-moz-meter-bar {
    border-radius: 2rem;
  }
`;

const MeterWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TopOptimumMarker = styled.div<{ position: number }>`
  position: absolute;
  left: ${(props) => props.position}%;
  height: 36px;
  top: -28px;
  width: 1px;
  border-right: 3px dotted ${(props) => props.theme.black500};
  z-index: 1;
`;
const BarOptimumMarker = styled.div<{ position: number }>`
  position: absolute;
  left: ${(props) => props.position}%;
  top: 10.3px;
  height: 12px;
  width: 3px;
  background: ${(props) => props.theme.bg1};
  z-index: 2;
`;

type Props = {
  value?: number;
  min?: number;
  max?: number;
  optimum?: number;
  isPercent?: boolean;
  isDays?: boolean;
  isPositiveTrend?: boolean; // fix the trends arrows to reflect high score is good
};

export const Meter = (props: Props) => {
  const {
    value,
    min = 0,
    max = 100,
    optimum,
    isPercent,
    isDays,
    isPositiveTrend,
  } = props;

  const optimumPosition =
    optimum !== undefined ? ((optimum - min) / (max - min)) * 100 : 0;

  const getTrendDirection = () => {
    if (!value || !optimum) return "";
    if (isPositiveTrend) return optimum < value ? "up" : "down";
    return optimum > value ? "up" : "down";
  };

  return (
    <MeterWrapper>
      {optimum !== undefined && (
        <>
          {/* The dotted part */}
          <TopOptimumMarker position={optimumPosition} />

          {/*The line that covers the meter bar */}
          <BarOptimumMarker position={optimumPosition} />

          {/* Label */}
          <Flex
            column
            align="end"
            style={{
              padding: "8px",
              width: "110px",
              position: "absolute",
              top: "-35px",
              left: !optimumPosition
                ? 0
                : optimumPosition < 15
                  ? `${optimumPosition}%`
                  : `calc(${optimumPosition}% - 110px)`,
            }}
          >
            <Flex gap="8px">
              <BodyBold>
                {optimum}
                {isPercent ? "%" : ""}
                {isDays ? " Days" : ""}
              </BodyBold>
              {getTrendDirection() === "up" && <TrendUpGreenIcon size={12} />}
              {getTrendDirection() === "down" && <TrendDownRedIcon size={12} />}
            </Flex>
            <LabelMini>Industry Avg. </LabelMini>
          </Flex>
        </>
      )}
      <MeterStyle {...props} />
    </MeterWrapper>
  );
};
