/* The custom-checkbox-container */

import styled from "styled-components";

export const CheckboxContainer = styled.label`
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 30px;
  height: 30px;

  &:hover {
    background: ${(props) => props.theme.blue50};
    border-radius: 50%;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

/* Hide the browser's default checkbox */
export const CheckboxStyle = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  /* Show the checkmark when checked */
  &:checked ~ .checkmark:after {
    display: block;
  }
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 15%;
  left: 15%;
  height: 70%;
  width: 70%;
  background-color: transparent;
  border: 1.3px solid ${(props) => props.theme.primary};
  border-radius: 4px;
  &:disabled {
    border: 1.3px solid ${(props) => props.theme.buttonDisabled}};
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;
