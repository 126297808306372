import { useContext } from "react";
import { ThemeContext } from "styled-components";

export const useToastStyles = () => {
  const theme = useContext(ThemeContext);

  const backgroundColors = {
    success: theme.green50,
    error: theme.red50,
    warning: theme.yellow50,
    info: theme.blue50,
  };

  const iconColors = {
    success: theme.greenPrimary,
    error: theme.redPrimary,
    warning: theme.yellowPrimary,
    info: theme.primary,
  };

  const statusToIcon = {
    success: "fixed",
    error: "dismissed",
    warning: "activeIssues",
    info: "info",
  };

  const buttonColors = {
    success: theme.green800,
    error: theme.red800,
    warning: theme.yellow800,
    info: theme.blue800,
  };

  const borderColors = {
    success: theme.greenPrimary,
    error: theme.redPrimary,
    warning: theme.yellowPrimary,
    info: theme.primary,
  };

  return {
    borderColors,
    backgroundColors,
    iconColors,
    statusToIcon,
    buttonColors,
  };
};
