import { useState } from "react";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import {
  BodyRegular,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ProductForm } from "../../settings/products/ProductForm";
import {
  ProductFormErrors,
  ProductFormValues,
} from "../../settings/products/AddEditProductForm";
import {
  useApiCreateProduct,
  useApiProducts,
  useApiUpdateProduct,
} from "../../../hooks/queries/productsContext";
import useToastContext from "../../../hooks/toastHook";
import { useApiMe } from "../../../hooks/queries/meContext";
import { OnboardingFooter } from "./OnboardingFooter";
import { Box } from "../../../components/elements/box/Box";

const emptyProduct = { name: "", description: "", accounts_allowed: [] };

type Props = {
  onSuccess: () => void;
  onClose: () => void;
};

export const AddProduct = (props: Props) => {
  const { onSuccess, onClose } = props;

  const addToast = useToastContext();

  const { data: me } = useApiMe();
  const { data: products } = useApiProducts();
  const { mutate: createProduct, isLoading: isCreating } =
    useApiCreateProduct();

  const validate = (values: ProductFormValues): ProductFormErrors => {
    const errors: ProductFormErrors = {};
    if (!values.name) errors.name = "Product name is required!";
    return errors;
  };

  const FormBox = ({ idx }: { idx: number }) => {
    const isFormDisabled =
      (!products?.length && idx > 1) ||
      (!!products?.length && products.length < idx - 1);

    const isEdit = !!products?.length && products.length >= idx;
    const editedProduct = isEdit ? products[idx - 1] : undefined;
    const initProd =
      isEdit && editedProduct
        ? {
            name: editedProduct.name,
            description: editedProduct.description,
            accounts_allowed: [],
          }
        : emptyProduct;

    const [formValues, setFormValues] = useState<ProductFormValues>(initProd);
    const [formErrors, setFormErrors] = useState<ProductFormErrors>({});

    const { mutate: updateProduct, isLoading: isUpdating } =
      useApiUpdateProduct({ id: editedProduct?.id || 0 });

    const isSubmitDisabled =
      isFormDisabled || isUpdating || isCreating || !formValues.name || !me;

    const handleSubmit = () => {
      if (isSubmitDisabled) return;
      let errors = validate(formValues);
      if (Object.keys(errors).length) {
        setFormErrors(errors);
        return;
      }
      let data = {
        customer: me?.customer.id,
        name: formValues.name,
        description: formValues.description,
      };
      if (!data) return;
      const callbacks = {
        onSuccessCallback: () => {
          addToast({
            message: `${
              editedProduct?.name || "Product"
            } has been successfully ${isEdit ? "updated" : "created"}`,
            type: "success",
          });
        },
        onErrorCallback: (error: any) => {
          addToast({ message: `Error: ${error}`, type: "error" });
        },
      };
      if (isEdit && editedProduct)
        updateProduct({
          productId: editedProduct.id,
          productData: data,
          ...callbacks,
        });
      else createProduct({ ...data, ...callbacks });
    };

    return (
      <Box>
        <Flex column gap="24px">
          <ProductForm
            idx={idx}
            isFormDisabled={isFormDisabled}
            formValues={formValues}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            setFormValues={setFormValues}
          />
          <Flex style={{ alignSelf: "end" }}>
            <MainButton
              dataTestId={`add-product-button-${idx}`}
              label={isEdit ? "Update" : "Add Product"}
              inProgress={isUpdating || isCreating}
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              size="medium"
            />
          </Flex>
        </Flex>
      </Box>
    );
  };

  return (
    <>
      <HeaderMain>Add your first Product</HeaderMain>
      <BodyRegular>
        You'll always have the option to add more from the settings page.
      </BodyRegular>
      <Flex
        column
        justify="evenly"
        style={{ height: "400px", width: "1000px" }}
      >
        <FormBox idx={1} />
      </Flex>
      <OnboardingFooter onClose={onClose}>
        <MainButton
          label="Next"
          onClick={onSuccess}
          disabled={!products?.length}
          size="medium"
        />
      </OnboardingFooter>
    </>
  );
};
