import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { ThemeContext } from "styled-components";
import { ConfirmModal } from "../../../components/composed/confirmModal/ConfirmModal";
import { Box } from "../../../components/elements/box/Box";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { Loading } from "../../../components/elements/loading/Loading";
import {
  BodyBold,
  BodyRegular,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import {
  useApiAssetsWithViewPaging,
  useApiDeleteAssetsBulk,
  useApiUpdateAssetsBulk,
} from "../../../hooks/queries/assetsContext";
import { Asset, AssetEdit } from "../../../types/Asset";
import { AssetsViewProps } from "../../../types/AssetsView";
import { AssetsBulkUpdate } from "./AssetsBulkUpdate";
import { AssetsTable } from "./AssetsTable";
import { getUpdateBulkModalWarning } from "../AssetUtils";
import { AssetsFilterLine } from "../filters/AssetsFilterLine";
import { emptyAssetsViewProps } from "../filters/FiltersUtils";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { useSearchParams } from "react-router-dom";
import { fromBase64AssetsView } from "../../../shared/helper";
import { CheckboxState } from "../../../components/elements/checkbox/Checkbox";
import useToastContext from "../../../hooks/toastHook";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { Mixpanel } from "../../../shared/mixpanel";
// import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";

export const AssetsEditTable = () => {
  useTrackPage("Assets - Edit");
  const addToast = useToastContext();
  const theme = useContext(ThemeContext);

  const navigate = useNavigate();
  // get filter from URL params
  const [searchParams] = useSearchParams();
  const assetViewFromParams = fromBase64AssetsView(
    searchParams.get("view") || ""
  );
  const [assetsView, setAssetsView] = useState<AssetsViewProps>(
    assetViewFromParams || emptyAssetsViewProps
  );
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);
  const [totalAssets, setTotalAssets] = useState<number>(0);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [assetsBulkUpdateProps, setAssetsBulkUpdateProps] = useState<AssetEdit>(
    {}
  );

  const {
    mutate: bulkUpdate,
    isLoading,
    reset,
    isSuccess,
  } = useApiUpdateAssetsBulk();

  const {
    mutate: deleteBulkAssets,
    isSuccess: isSuccessDelete,
    isLoading: isLoadingDelete,
    reset: resetDelete,
  } = useApiDeleteAssetsBulk();

  const deleteBulk = () => {
    Mixpanel.track("Assets Table - delete bulk assets");
    deleteBulkAssets({
      assetsData: {
        all: isSelectAll,
        filters: assetsView.filters,
        assets: selectedAssets.map((a) => a.id),
        updates: {},
      },

      onErrorCallback: (err: Error) =>
        addToast({ message: err.message, type: "error" }),
    });
  };

  const { data: assets } = useApiAssetsWithViewPaging(
    assetsView.filters,
    "created_at",
    false
  );

  const assetsMapped = useMemo(
    () => assets?.pages.map((page) => page.results || []).flat(),
    [assets]
  );

  const onCancel = () => {
    setAssetsBulkUpdateProps({});
    navigate(-1);
  };

  const isValidBulkUpdate = () => {
    return (
      Object.keys(assetsBulkUpdateProps).length > 0 &&
      (selectedAssets.length > 0 || isSelectAll)
    );
  };

  const onBulkUpdate = () => {
    Mixpanel.track("Assets Table - update bulk assets");
    bulkUpdate({
      assetsData: {
        all: isSelectAll,
        filters: assetsView.filters,
        assets: selectedAssets.map((a) => a.id),
        updates: assetsBulkUpdateProps,
      },
      onErrorCallback: (err) =>
        addToast({ type: "error", message: err.message }),
    });
  };

  const onRowSelect = (row: Asset, state: CheckboxState) => {
    var temp = [...selectedAssets];
    if (state === "checked") {
      const asset = assetsMapped?.find((a) => a.id === row.id);
      asset && temp.push(asset);
    } else {
      temp = temp.filter((a) => a.id !== row.id);
    }
    setSelectedAssets(temp);
  };

  const onSelectAll = (rowsId: number[], state: CheckboxState) => {
    if (state === "checked") setSelectedAssets(assetsMapped || []);
    else if (state === "unchecked") setSelectedAssets([]);
  };

  return (
    <Flex align="center" justify="between">
      {showConfirmModal && (
        <ConfirmModal
          title={`The Changes Will be Applied to ${
            isSelectAll ? totalAssets : selectedAssets.length
          } Assets`}
          confirmButtonText="Apply"
          cancelButtonText="Cancel"
          successMessage="Changes applied successfully"
          onConfirm={onBulkUpdate}
          onClose={() => {
            setShowConfirmModal(false);
            reset();
          }}
          isLoading={isLoading}
          isSuccess={isSuccess}
          // PREPARE FOR AC - To be used when assignee is implemented ////
          body={
            isLoading ? (
              <Loading />
            ) : (
              !!getUpdateBulkModalWarning(selectedAssets) && (
                <div className="text-center">
                  <BodyRegular>
                    Not all selected assets are related to the same{" "}
                    <BodyBold>
                      {getUpdateBulkModalWarning(selectedAssets)}
                    </BodyBold>
                  </BodyRegular>
                </div>
              )
            )
          }
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onClose={() => {
            setShowDeleteModal(false);
            if (isSuccessDelete) {
              setSelectedAssets([]);
              resetDelete();
            }
          }}
          onConfirm={deleteBulk}
          body={`This action cannot be undone!`}
          itemName={`selected
             assets`}
          itemType="assets"
          isSuccess={isSuccessDelete}
          isLoading={isLoadingDelete}
        />
      )}
      <Flex column w100>
        <Flex justify="between" className="mb-2">
          <Flex align="center">
            <HeaderMain style={{ minWidth: "204px", color: theme.black900 }}>
              Edit Assets
            </HeaderMain>
            <div data-testid="bulk-actions">
              {selectedAssets.length > 0 && (
                <Flex align="center">
                  <AssetsBulkUpdate
                    key={`bulk-updates-key-${
                      Object.keys(assetsBulkUpdateProps).length
                    }`}
                    selectedAssets={selectedAssets}
                    totalAssets={totalAssets}
                    setAssetsBulkUpdateProps={setAssetsBulkUpdateProps}
                    assetsBulkUpdateProps={assetsBulkUpdateProps}
                    isSelectAll={isSelectAll}
                    setIsSelectAll={(state) => setIsSelectAll(state)}
                    setSelectedAssets={(selected) =>
                      setSelectedAssets(selected)
                    }
                  />
                  <IconButton
                    iconName="remove"
                    color={theme.redPrimary}
                    label="Delete Selected Assets"
                    onClick={() => setShowDeleteModal(true)}
                  />
                </Flex>
              )}
            </div>
          </Flex>
          <Flex align="center" gap="8px">
            <TextButton
              label="Cancel"
              dataTestId="cancel-button"
              color={theme.black900}
              onClick={onCancel}
            />
            <MainButton
              label="Update"
              size="small"
              dataTestId="update-button"
              onClick={() => setShowConfirmModal(true)}
              disabled={selectedAssets.length === 0 || !isValidBulkUpdate()}
            />
          </Flex>
        </Flex>
        <AssetsFilterLine
          assetsView={assetsView}
          setAssetsView={setAssetsView}
        />

        <Box
          className="d-flex flex-column gap-24 w-100"
          style={{ marginTop: "16px", height: "calc(100vh - 260px)" }}
        >
          <AssetsTable
            filters={assetsView.filters}
            isEditable={true}
            selectedAssets={selectedAssets}
            onRowSelect={onRowSelect}
            onSelectAll={onSelectAll}
            setTotalAssets={setTotalAssets}
            isSelectAll={isSelectAll}
            setIsSelectAll={setIsSelectAll}
            hasBackToTopButton
          />
        </Box>
      </Flex>
    </Flex>
  );
};
