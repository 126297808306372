import { useRef, useState } from "react";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { Menu } from "../../../components/elements/menu/Menu";
import { Flex } from "../../../components/layouts/flex/Flex";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { Mixpanel } from "../../../shared/mixpanel";
// import { useIsSuperuser } from "../../../hooks/useIsSuperuser";

type Props = {
  OpenAddAssetModal: () => void;
  OpenImportAssetsModal: () => void;
};

export const AddAssetsMenu = (props: Props) => {
  const { OpenAddAssetModal, OpenImportAssetsModal } = props;
  // const isSuperuser = useIsSuperuser();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  return (
    <Flex>
      <SecondaryButton
        label="Add Assets"
        iconName="add"
        onClick={() => setShowMenu((prev) => !prev)}
        size="small"
      />
      {showMenu && (
        <Menu
          ref={wrapperRef}
          style={{ position: "absolute", top: "30px", width: "230px" }}
        >
          <Flex column>
            {/* {isSuperuser && ( */}
            <LinkButton
              label="Add Asset"
              onClick={() => {
                Mixpanel.track("Add Asset");
                OpenAddAssetModal();
                setShowMenu(false);
              }}
            />
            {/* )} */}
            <LinkButton
              label="Import Assets"
              onClick={() => {
                Mixpanel.track("Import Assets");
                OpenImportAssetsModal();
                setShowMenu(false);
              }}
            />
          </Flex>
        </Menu>
      )}
    </Flex>
  );
};
