import React from "react";
import { Box } from "../../../components/elements/box/Box";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";

export const TopBoxesSkeleton = () => {
  return (
    <div className="d-flex align-items-center gap-24 flex-wrap">
      <Box
        className="col"
        style={{
          height: "105px",
          width: "300px",
        }}
      >
        <SkeletonLoading width="100%" height="100%" />
      </Box>
      <Box
        className="col"
        style={{
          height: "105px",
          width: "300px",
        }}
      >
        <SkeletonLoading width="100%" height="100%" />
      </Box>
      <Box
        className="col"
        style={{
          height: "105px",
          width: "300px",
        }}
      >
        <SkeletonLoading width="100%" height="100%" />
      </Box>
      <Box
        className="col"
        style={{
          height: "105px",
          width: "300px",
          borderTopRightRadius: "0",
        }}
      >
        <SkeletonLoading width="100%" height="100%" />
      </Box>
    </div>
  );
};
