import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { Flex } from "../../../components/layouts/flex/Flex";
import { OnboardingFooter } from "./OnboardingFooter";
import { SelectAssets } from "./SelectAssets";
import { useApiAssetsWithViewPaging } from "../../../hooks/queries/assetsContext";
import { AssetsScanWarning } from "./AssetScanWarnings";
import { Loading } from "../../../components/elements/loading/Loading";
import {
  useApiRunScan,
  useApiScanEventsPaging,
} from "../../../hooks/queries/scansContext";
import { useEffect, useState } from "react";
import { useApiMe } from "../../../hooks/queries/meContext";
import { StartSublisterScan } from "./StartSublisterScan";
import { useApiUpdateCustomer } from "../../../hooks/queries/customersContext";
import useToastContext from "../../../hooks/toastHook";
import { ScanEvent } from "../../../types/ScanEvent";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  goToPrevStage: () => void;
};

export const InitiateScannings = (props: Props) => {
  const { onSuccess, onClose, goToPrevStage } = props;
  const addToast = useToastContext();

  const [scan, setScan] = useState<ScanEvent | null>();
  const [showEndMessage, setShowEndMessage] = useState<boolean>(false);

  const { data: me } = useApiMe();

  // check we have at least one verified root domain asset
  const { data: verifiedAssets, isLoading: checkingVerifiedAssets } =
    useApiAssetsWithViewPaging([
      {
        column: "is_verified",
        value: "True",
        condition: "is",
        order: 0,
        next_condition: "and",
      },
    ]);
  const verifiedAssetsCount =
    !!verifiedAssets?.pages[0].count && verifiedAssets.pages[0].count;

  // Get scan events if we have verified asset and scan is not finished
  const {
    data: scanEventsPages,
    isError: scansIsError,
    isLoading: isLoadingScans,
  } = useApiScanEventsPaging(
    {
      customer_id: me?.customer?.id,
      scanner: "sublister",
      status: "done,running,error,aborted,created",
    },
    scan?.status !== "done", // Stop query if scan finished
    20000
  );

  useEffect(() => {
    // if we have an active scan set it as current scan
    if (!scanEventsPages?.pages) return;
    const scanEvents =
      !!scanEventsPages?.pages?.length && scanEventsPages.pages[0].results;
    // scanEventsPages?.pages?.map((page) => page?.results || [])?.flat() || [];
    console.log({ scanEvents });
    if (!scanEvents || !scanEvents.length) return;
    setScan(scanEvents[0]);
  }, [scanEventsPages]);

  // Start Sublister scan
  const {
    mutate: runScanMutation,
    isLoading: isStarting,
    isSuccess: isScanning,
  } = useApiRunScan();
  const runScan = () => {
    runScanMutation({
      customerId: me?.customer.id || -1,
      scannerName: "sublister",
      onSuccessCallback: (se) => {
        addToast({
          type: "success",
          message: `Subdomains enumeration has started successfully`,
        });
        setScan(se);
      },
      onErrorCallback: () =>
        addToast({
          type: "error",
          message: `Failed to start scanner Sublister`,
        }),
    });
  };

  // Update customer when done
  const { mutate: updateCustomer } = useApiUpdateCustomer();
  const enableAsmForCustomer = () => {
    me &&
      updateCustomer({
        customerId: me?.customer.id,
        customerData: { is_asm_enabled: true },
        onSuccessCallback: () => {
          setShowEndMessage(true);
          setTimeout(onSuccess, 5000);
        },
        onErrorCallback: (error) => {
          addToast({
            type: "error",
            message: `Failed to enable ASM : ${error.message}`,
          });
        },
      });
  };

  return (
    <>
      {checkingVerifiedAssets || isLoadingScans ? (
        <Flex style={{ height: "650px", justifySelf: "center" }}>
          <Loading />
        </Flex>
      ) : !verifiedAssetsCount || verifiedAssetsCount === 0 ? ( // No verified assets - show warning
        <AssetsScanWarning warningType="verification" />
      ) : showEndMessage ? ( // Onboarding is done - show message
        <AssetsScanWarning warningType="success" />
      ) : scansIsError ? ( // failed to get scan events
        <AssetsScanWarning warningType="failed_get_scans" />
      ) : scan?.status === "done" ? ( // Scan is done - show assets to select
        <SelectAssets />
      ) : scan?.status === "error" ? ( // failed to start scan
        <AssetsScanWarning warningType="failed_run_scan" runScan={runScan} />
      ) : !scan || scan?.status === "running" ? ( // No scan or scan in process - show button
        <StartSublisterScan
          isScanning={isScanning}
          isStarting={isStarting}
          runScan={runScan}
          scan={scan}
        />
      ) : null}
      <OnboardingFooter onClose={onClose}>
        <Flex gap="8px">
          <SecondaryButton
            label="Back"
            onClick={goToPrevStage}
            disabled={showEndMessage}
            size="medium"
          />
          <MainButton
            label="Done"
            onClick={enableAsmForCustomer}
            disabled={
              showEndMessage || !scan || scan.status !== "done" || scansIsError
            }
            dataTestId="done-btn"
            size="medium"
          />
        </Flex>
      </OnboardingFooter>
    </>
  );
};
