import React, { useContext } from "react";
import { JiraProjectMapping } from "../../../../types/JiraIntegration";
import { Box } from "../../../../components/elements/box/Box";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  BodyMedium,
  BodyRegular,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { STATUSES } from "../../../../types/Finding";
import { getStatusName } from "../../../../shared/findingsHelper";
import { Icon } from "../../../../components/elements/icon/Icon";

type Props = {
  mapping: JiraProjectMapping;
};

export const MappingOverview = (props: Props) => {
  const { mapping } = props;
  const theme = useContext(ThemeContext);
  return (
    <Flex column gap={"16px"} w100 h100>
      <Flex gap={"24px"} w100 justify="between" align="center">
        <Flex column gap="8px" w100>
          <LabelRegular className="w-100" color={theme.black700}>
            Created Tickets Default Label
          </LabelRegular>
          <BodyMedium className="text-truncate w-100" color={theme.black800}>
            {mapping.default_label || "None"}
          </BodyMedium>
        </Flex>
        <SeparatorVertical height={"24px"} />
        <Flex column gap="8px" w100>
          <LabelRegular color={theme.black700}>Issue Type</LabelRegular>
          <BodyMedium className="text-truncate w-100" color={theme.black800}>
            {mapping.issue_type?.name || "None"}
          </BodyMedium>
        </Flex>
      </Flex>
      <Box
        style={{
          borderRadius: "8px",
        }}
        className="d-flex gap-24 flex-column"
      >
        <Flex gap="24px" justify="between" align="center" w100>
          <LabelRegular className="w-100" color={theme.black700}>
            WASP Status
          </LabelRegular>
          <LabelRegular className="w-100" color={theme.black700}>
            JIRA Status
          </LabelRegular>
        </Flex>
        <SeparatorHorizontal />
        <Flex column gap="24px">
          {STATUSES.map((status) => (
            <Flex
              key={`status-map-overview-${status}`}
              w100
              justify="between"
              align="center"
              gap="24px"
            >
              <Flex w100 align="center" gap="8px">
                <Icon
                  name={status}
                  size="24px"
                  color={
                    status === "fixed"
                      ? theme.greenPrimary
                      : status === "dismissed"
                      ? theme.blue300
                      : theme.primary
                  }
                />
                <BodyMedium
                  className="text-truncate w-100"
                  color={theme.black800}
                >
                  {getStatusName(status)}
                </BodyMedium>
              </Flex>
              <SeparatorVertical height="24px" />
              <BodyRegular className="w-100" color={theme.black800}>
                {mapping[status]?.name}
              </BodyRegular>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};
