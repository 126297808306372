import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { BodyMedium } from "../../../components/elements/typography/Typography";
import { Filter } from "../../../types/AssetsView";
import { FilterCondition } from "./controls/FilterCondition";
import { assetsFiltersColumns } from "./FiltersUtils";
import { FilterValue } from "./controls/FilterValue";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Toggle } from "../../../components/elements/toggle/Toggle";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  filter: Filter;
  onChange: (filter: Filter) => void;
  onRemove: () => void;
  hasNextCondition: boolean;
};

export const FilterAttribute = (props: Props) => {
  const { filter, onChange, onRemove, hasNextCondition } = props;
  const theme = useContext(ThemeContext);

  const onConditionChange = (condition: string) => {
    onChange({
      ...filter,
      condition: condition,
    });
  };

  const onValueChange = (value: string) => {
    onChange({
      ...filter,
      value: value,
    });
  };

  const onNextConditionChange = (checked: boolean) => {
    onChange({
      ...filter,
      next_condition: checked ? "and" : "or",
    });
  };

  return (
    <>
      {hasNextCondition && (
        <Flex align="center" gap="16px" className="w-100">
          <SeparatorHorizontal />
          <Toggle
            onText="And"
            offText="Or"
            width="168px"
            onChange={onNextConditionChange}
            checked={filter.next_condition !== "or"}
          />
          <SeparatorHorizontal />
          {!filter.column && (
            <IconButton
              iconName="cancel"
              color={theme.redPrimary}
              size="small"
              onClick={onRemove}
              dataTestId="filter-remove-button"
            />
          )}
        </Flex>
      )}
      {!filter.column ? (
        <Dropdown
          searchable
          variant="border"
          size="medium"
          dataTestId="filter-attribute-dropdown"
          onChange={(option) => {
            let tempFilter: Filter = {
              ...filter,
              column: option?.value.toString() || "",
            };
            onChange(tempFilter);
          }}
          placeholder="Select Attribute"
          options={assetsFiltersColumns}
        />
      ) : (
        <Flex column gap="16px">
          <Flex justify="between" align="center" gap="8px">
            <BodyMedium style={{ color: theme.black900 }}>
              {
                assetsFiltersColumns.find((c) => c.value === filter.column)
                  ?.label
              }
            </BodyMedium>
            <IconButton
              iconName="remove"
              color={theme.redPrimary}
              size="small"
              onClick={onRemove}
              dataTestId="filter-remove-button"
            />
          </Flex>
          <Flex align="center" gap="16px" className="w-100">
            <FilterCondition
              columnType={
                assetsFiltersColumns.find((c) => c.value === filter.column)
                  ?.type || "string"
              }
              condition={filter.condition}
              onChange={onConditionChange}
            />
            <FilterValue
              column={assetsFiltersColumns.find(
                (c) => c.value === filter.column
              )}
              onChange={onValueChange}
              filterValue={filter.value}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};
