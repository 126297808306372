import { Dispatch, SetStateAction } from "react";
import { Box } from "../../../components/elements/box/Box";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { AdminFindingEdit, Finding } from "../../../types/Finding";
import { AffectedAssetsList } from "../findingPane/items/AffectedAssetsList";
import { AffectedProductsList } from "../findingPane/items/AffectedProductsList";
import { AssignedToItem } from "../findingPane/items/AssignedToItem";
import { DiscoverOnItem } from "../findingPane/items/DiscoverOnItem";
import { ExploitabilityItem } from "../findingPane/items/ExploitabilityItem";
import { ImpactItem } from "../findingPane/items/ImpactItem";
import { IssuedByItem } from "../findingPane/items/IssuedByItem";
import { ScannerItem } from "../findingPane/items/ScannerItem";
import { Flex } from "../../../components/layouts/flex/Flex";
import { CVSSVectorItem } from "../findingPane/items/CVSSVector";
import { FindingLastSeen } from "../findingPane/items/FindingLastSeen";
import { Project } from "../../../types/Project";
import { useApiMe } from "../../../hooks/queries/meContext";
import { EditAffectedMultiTenant } from "./EditAffectedMultiTenant";
import { EditAffectedSuperusers } from "./EditAffectedSuperusers";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { SummaryItem } from "../findingPane/items/SummaryItem";

type Props = {
  finding: Finding | undefined;
  setShowAffectedAssetsPane?: Dispatch<SetStateAction<boolean>>;
  onClickProject?: (project: Project) => void;
  onClickAsset?: (assetId: number) => void;
  isEditable?: boolean;

  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  cachedFindingData?: AdminFindingEdit;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const MiddleBox = (props: Props) => {
  const {
    finding,
    setShowAffectedAssetsPane,
    onClickProject,
    onClickAsset,
    isEditable,

    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    cachedFindingData,
    setCachedFindingData,
  } = props;

  const isSuperuser = useIsSuperuser();
  const { data: me } = useApiMe();

  return (
    <Box className="d-flex flex-column gap-24 w-100">
      <Flex w100>
        <Flex column gap="32px" w100>
          <AssignedToItem finding={finding} isEditable />
          <DiscoverOnItem
            isAutomated={!!finding?.is_automated}
            onClickProject={onClickProject}
            finding={finding}
            isEditable={isEditable}
            editableUpdateFindingData={editableUpdateFindingData}
            setUpdateFinding={setUpdateFinding}
            setEditableUpdateFinding={setEditableUpdateFinding}
            setCachedFindingData={setCachedFindingData}
          />
        </Flex>

        <Flex column gap="32px" w100>
          <ImpactItem
            finding={finding}
            isEditable={isEditable}
            editableUpdateFindingData={editableUpdateFindingData}
            setUpdateFinding={setUpdateFinding}
            setEditableUpdateFinding={setEditableUpdateFinding}
            setCachedFindingData={setCachedFindingData}
          />
          <IssuedByItem
            finding={finding}
            isEditable={isEditable}
            editableUpdateFindingData={editableUpdateFindingData}
            setUpdateFinding={setUpdateFinding}
            setEditableUpdateFinding={setEditableUpdateFinding}
            setCachedFindingData={setCachedFindingData}
          />
        </Flex>

        <Flex column gap="32px" w100>
          <ExploitabilityItem
            finding={finding}
            isEditable={isEditable}
            editableUpdateFindingData={editableUpdateFindingData}
            setUpdateFinding={setUpdateFinding}
            setEditableUpdateFinding={setEditableUpdateFinding}
            setCachedFindingData={setCachedFindingData}
          />
          <FindingLastSeen finding={finding} />
        </Flex>

        <Flex column gap="32px" w100>
          <CVSSVectorItem
            noSeparator
            noTooltip={isEditable}
            finding={finding}
            isEditable={isEditable}
            editableUpdateFindingData={editableUpdateFindingData}
            setUpdateFinding={setUpdateFinding}
            setEditableUpdateFinding={setEditableUpdateFinding}
            cachedFindingData={cachedFindingData}
            setCachedFindingData={setCachedFindingData}
          />
          <ScannerItem
            finding={finding}
            noSeparator
            isEditable={isEditable}
            editableUpdateFindingData={editableUpdateFindingData}
            setUpdateFinding={setUpdateFinding}
            setEditableUpdateFinding={setEditableUpdateFinding}
            setCachedFindingData={setCachedFindingData}
          />
        </Flex>
      </Flex>

      <SeparatorHorizontal />

      <SummaryItem
        finding={finding}
        isEditable={isEditable}
        editableUpdateFindingData={editableUpdateFindingData}
        setUpdateFinding={setUpdateFinding}
        setEditableUpdateFinding={setEditableUpdateFinding}
        setCachedFindingData={setCachedFindingData}
      />

      {!isEditable ? (
        <Flex column gap="32px">
          <AffectedProductsList finding={finding} />
          <AffectedAssetsList
            finding={finding}
            setShowAffectedAssetsPane={setShowAffectedAssetsPane}
            onClickAsset={onClickAsset}
          />
        </Flex>
      ) : me?.customer.is_multi_tenant && !isSuperuser ? (
        <EditAffectedMultiTenant
          editableUpdateFindingData={editableUpdateFindingData}
          setUpdateFinding={setUpdateFinding}
          setEditableUpdateFinding={setEditableUpdateFinding}
          setCachedFindingData={setCachedFindingData}
        />
      ) : isSuperuser ? (
        <EditAffectedSuperusers
          editableUpdateFindingData={editableUpdateFindingData}
          setUpdateFinding={setUpdateFinding}
          setEditableUpdateFinding={setEditableUpdateFinding}
          setCachedFindingData={setCachedFindingData}
        />
      ) : null}
    </Box>
  );
};
