import { HeaderMain } from "../../elements/typography/Typography";
import { LinkButton } from "../../elements/button/link/LinkButton";
import { SeparatorHorizontal } from "../../elements/separators/SeparatorHorizontal";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { ThemeContext } from "styled-components";
import { useApiMe } from "../../../hooks/queries/meContext";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";

export const SettingsNav = ({ isOpen }: { isOpen: boolean }) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { data: me } = useApiMe();
  const isSuperuser = useIsSuperuser();

  let currentPage = location.pathname.replace("/", "");
  if (currentPage === "settings") currentPage = "settings/sla-policy";

  let pages = [
    { name: "My Profile", icon: "user" },
    { name: "SLA Policy", icon: "sla" },
    { name: "Notifications", icon: "notification" },
  ];

  if (me?.can_manage_customer || isSuperuser)
    pages = [
      ...pages,
      { name: "Users Management", icon: "friends" },
      { name: "Products", icon: "products" },
      { name: "Scanning Policy", icon: "shield" },
      { name: "Integrations", icon: "share" },
      { name: "Automation", icon: "map" },
    ];

  if (isSuperuser)
    pages = [
      ...pages,
      { name: "Effort Estimation", icon: "calender" },
      { name: "Credits", icon: "credits" },
    ];

  return (
    <div
      data-testid="settings-nav-container"
      className=""
      style={{
        width: isOpen ? "260px" : "0",
        position: "fixed",
        top: 0,
        left: 97,
        bottom: 0,
        height: "100%",
        paddingTop: "72px",
        overflowX: "hidden",
        backgroundColor: theme.bg2,
        border: isOpen ? `1px solid ${theme.black500}` : "none",
        transition: "0.4s",
      }}
    >
      <div style={{ margin: "24px" }}>
        <HeaderMain style={{}}>Settings</HeaderMain>
        {pages.map((page) => {
          const path = `settings/${page.name.replace(" ", "-").toLowerCase()}`;
          return (
            <div key={page.name}>
              <div
                style={{
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LinkButton
                  iconName={page.icon}
                  label={page.name}
                  onClick={() => navigate(path)}
                  color={
                    currentPage === path ? theme.primary : theme.buttonGray
                  }
                  style={{ textAlign: "start" }}
                />
              </div>
              <SeparatorHorizontal />
            </div>
          );
        })}
      </div>
    </div>
  );
};
