import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { Switch } from "../../../components/elements/switch/Switch";
import {
  BodyMedium,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ScanIdentifierCard } from "../../settings/scans/ScanIdentifierCard";
import { ProjectFormProps } from "./ProjectScheduler";

export const Whitelist = (props: ProjectFormProps) => {
  const { mainFormValues, setMainFormValues } = props;

  return (
    <Flex w100>
      <Flex w100 column gap="8px" style={{ padding: "32px" }}>
        <Flex align="center" justify="between">
          <BodyMedium>Wasp PT Should Be Whitelisted</BodyMedium>
          <Switch
            checked={mainFormValues.test_settings.is_whitelisted}
            onChange={(checked) =>
              setMainFormValues((prev) => ({
                ...prev,
                test_settings: {
                  ...prev.test_settings,
                  is_whitelisted: checked,
                },
              }))
            }
          />
        </Flex>
        {mainFormValues.test_settings.is_whitelisted && (
          <Flex column padding="0 24px" gap="16px">
            <Flex gap="32px">
              <BodyRegular>Source IP addresses</BodyRegular>
              <BadgesLine
                badgeMaxWidth="100%"
                badges={["3.73.239.182", "18.224.205.189", "18.224.187.124"]}
              />
            </Flex>
            <BodyRegular>Additional identifiers</BodyRegular>
            <ScanIdentifierCard
              title="User-Agent"
              text={`Mozilla/5.0 (X11; Ubuntu; Linux x86_64) AppleWebKit/537.36 (KHTML,
          like Gecko) Chrome/99.0.4844.74 Safari/537.36 WASP`}
            />
            {/* <ScanIdentifierCard title="HTTP Headers" text={"TBD"} /> */}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
