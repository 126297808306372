import React from "react";
import { QuickScanResult } from "../../types/QuickScanResult";
import { AnalysisResult, analyzeScanResult } from "./QuickScanResultsBL";
import { Flex } from "../../components/layouts/flex/Flex";
import { QuickScanResultsViewSummary } from "./QuickScanResultsViewSummary";
import { QuickScanResultsViewDetails } from "./QuickScanResultsViewDetails";
import { QuickScanResultsLockGraph } from "./QuickScanResultsLockGraph";
import { QuickScanResultsRawHeaders } from "./QuickScanResultsRawHeaders";
import { QuickScanResultsRawCookies } from "./QuickScanResultsRawCookies";
import { QuickScanResultsFooter } from "./QuickScanResultsFooter";

type Props = {
  scanResult: QuickScanResult;
};

export const QuickScanResultsView = ({ scanResult }: Props) => {
  const analysisResult: AnalysisResult = analyzeScanResult(scanResult);
  return (
    <Flex column w100 gap="16px" align="center">
      <QuickScanResultsViewSummary analysisResult={analysisResult} />
      <QuickScanResultsViewDetails analysisResult={analysisResult} />
      <QuickScanResultsLockGraph />
      <QuickScanResultsRawHeaders scanResult={scanResult} />
      <QuickScanResultsRawCookies scanResult={scanResult} />
      <QuickScanResultsFooter />
    </Flex>
  );
};
