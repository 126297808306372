import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { SlaThreshold } from "./SlaThreshold";
import { SlaPreBreached } from "./SlaPreBreached";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { useApiMe } from "../../../hooks/queries/meContext";

export const SlaPolicy = () => {
  const { data: me } = useApiMe();
  useTrackPage("Settings - SLA Policies", undefined, me);
  const theme = useContext(ThemeContext);
  return (
    <Flex column gap="24px" className="w-100">
      <HeaderSecondary style={{ color: theme.black900 }}>
        SLA Policies
      </HeaderSecondary>
      <SlaThreshold />
      <SlaPreBreached />
    </Flex>
  );
};
