import { useMutation } from "@tanstack/react-query";
import { createItem } from "./sdk";

const key = "register_api";

type DemoAccount = {
  email: string;
  name: string;
  company: string;
  role: string;
  country: string;
  allow_updates: boolean;
  accept_terms: boolean;
};

type DemoAccountParams = {
  email?: string;
  name?: string;
  company?: string;
  role?: string;
  country?: string;
  allow_updates?: boolean;
  accept_terms?: boolean;
  onSuccessCallback?: (data: any) => void;
  onErrorCallback?: (error: Error) => void;
};

export const useApiRegister = () => {
  return useMutation<DemoAccount, Error, DemoAccountParams>({
    mutationKey: [key],
    mutationFn: async (newDemoAccount): Promise<DemoAccount> =>
      await createItem(key, newDemoAccount),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
  });
};
