import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { useApiProjects } from "../../../../hooks/queries/projectsContext";
import { SEVERITIES } from "../../../../shared/consts";
import {
  convertToUiFilters,
  excludedFilters,
  filtersWithTextLabels,
  listFilters,
  stringsListToArray,
} from "../FindingsFilterBL";
import FilterBadge from "./FilterBadge";
import { Filters } from "../../Findings";
import { Flex } from "../../../../components/layouts/flex/Flex";

export const FindingsFilterLineTooltip = ({
  filters,
}: {
  filters: Filters;
}) => {
  const { data: products } = useApiProducts();
  const { data: projects } = useApiProjects();
  const filtersConverted = convertToUiFilters(filters);
  const filtersKeys = Object.keys(filtersConverted).filter(
    (key) => !excludedFilters.includes(key)
  );

  const getLabel = (value: string, filterKey: string): string => {
    if (!filtersWithTextLabels.includes(filterKey)) return value;
    const labelsDict: { [index: string]: string } = {
      products: products?.find((p) => p.id === parseInt(value))?.name || "",
      project: projects?.find((p) => p.id === parseInt(value))?.name || "",
      overall_risk: SEVERITIES[parseInt(value)],
      affected_assets: `asset id: ${value}`,
    };
    return labelsDict[filterKey];
  };

  const getBadgesList = (): JSX.Element[] => {
    return filtersKeys
      .filter((key) => !excludedFilters.includes(key))
      .map((filterKey) => {
        if (listFilters.includes(filterKey))
          return stringsListToArray(filters[filterKey]).map((value) => (
            <FilterBadge
              key={`${filterKey}-${value}`}
              filterKey={filterKey}
              label={getLabel(value, filterKey)}
              value={value}
            />
          ));
        return (
          <FilterBadge
            key={`${filterKey}`}
            filterKey={filterKey}
            label={
              filterKey === "timeframe" && filtersConverted[filterKey] !== 0
                ? `${filtersConverted[filterKey]} days`
                : filtersConverted[filterKey]
            }
          />
        );
      })
      .flat();
  };

  return (
    <Flex flexWrap style={{ maxWidth: "600px" }} gap="8px">
      {getBadgesList()}
    </Flex>
  );
};
