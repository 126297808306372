import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { Section } from "../../../../components/elements/section/Section";
import { Finding } from "../../../../types/Finding";
import { useApiFindingEvents } from "../../../../hooks/queries/findingEventsContext";
import { getDate } from "../../../../shared/helper";
import { useApiMe } from "../../../../hooks/queries/meContext";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
};

export const FindingLastSeen = (props: Props) => {
  const { finding, noSeparator = false, width } = props;

  const {data: me}= useApiMe();

  const { data: lastFindingEvents } = useApiFindingEvents(
    {
      page_size: 1,
      wasp_id:finding?.id,
      customer_id: me?.customer.id,
    },
    !!finding?.id && !!me?.customer.id // isEnabled
  );

  const lastSeenDate = !!lastFindingEvents?.length ? getDate(lastFindingEvents[0].created_at) : "-"

  return (
    <Section title="Last Seen" noSeparator={noSeparator} width={width}>
      <BodyRegular className="text-truncate w-100">{!lastSeenDate?.includes("Invalid")? lastSeenDate : "-"}</BodyRegular>
    </Section>
  );
};
