import { useContext } from "react";
import { IconButton } from "../../elements/button/icon/IconButton";
import { useNavigate, useNavigationType } from "react-router";
import { ThemeContext } from "styled-components";

type Props = {
  defaultLink: string;
};

export const BackButton = (props: Props) => {
  const { defaultLink } = props;
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const navType = useNavigationType();

  return (
    <IconButton
      iconName="chevronLeft"
      color={theme.primary}
      onClick={() => (navType === "POP" ? navigate(defaultLink) : navigate(-1))}
      size="medium"
    />
  );
};
