import { Illustration } from "../../elements/illustrations/Illustration";
import { HeaderSubBold } from "../../elements/typography/Typography";
import { Flex } from "../../layouts/flex/Flex";
import { ContactSalesButton } from "../contactSalesButton/ContactSalesButton";

export const FindingsTrialLimitation = () => {
  return (
    <Flex h100 w100 gap="24px" column justify="center" align="center">
      <Illustration name="alert" />
      <HeaderSubBold>
        Critical or high severity findings are not available on trial mode
      </HeaderSubBold>
      <ContactSalesButton />
    </Flex>
  );
};
