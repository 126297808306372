import React, { useContext } from "react";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { Asset, ThirdPartyConnections } from "../../../types/Asset";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
  LabelMedium,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { Badge } from "../../../components/elements/badge/Badge";
import { getDomain } from "tldts";

type Props = {
  onClose: () => void;
  assets: Asset[];
  corporateName: string;
};

export const AssetOwnerPane = ({ onClose, assets, corporateName }: Props) => {
  const theme = useContext(ThemeContext);

  const getRootDomain = (url: string): string | null => {
    return getDomain(url);
  };

  const isSameOwner = (connection: ThirdPartyConnections) => {
    return connection.tracker_corp
      ? connection.tracker_corp === corporateName
      : getRootDomain(connection.url) === corporateName;
  };

  return (
    <RightPane onClose={onClose} width="1008px">
      <Flex
        padding="24px"
        gap="24px"
        column
        style={{
          overflow: "auto",
          maxHeight: "calc(100vh - 100px)",
        }}
      >
        <HeaderSecondary
          className="text-truncate"
          style={{
            maxWidth: "300px",
            textTransform: "none",
          }}
        >
          {corporateName}
        </HeaderSecondary>
        <SeparatorHorizontal />
        <Flex column gap="16px">
          {assets.map((asset) => (
            <Flex
              key={`asset-owner-by-${asset.id}`}
              column
              gap="16px"
              style={{
                overflow: "auto",
                maxHeight: "300px",
              }}
            >
              <HeaderSubBold style={{ color: theme.black600 }}>
                {asset.name}
              </HeaderSubBold>
              {asset.third_party_connections
                ?.filter((con) => isSameOwner(con))
                .map((connection) => (
                  <Box key={`asset-owner-connection-${connection.url}`}>
                    <Flex column gap="8px">
                      {!!connection.tracker_categories && (
                        <Flex flexWrap align="center" gap="4px">
                          <LabelMedium>Categories:</LabelMedium>
                          {connection.tracker_categories?.map((category) => (
                            <Badge key={`category-${category}`}>
                              {category}
                            </Badge>
                          ))}
                        </Flex>
                      )}

                      <BodyRegular className="text-truncate">
                        {connection.url}
                      </BodyRegular>
                    </Flex>
                  </Box>
                ))}
            </Flex>
          ))}
        </Flex>
      </Flex>
    </RightPane>
  );
};
