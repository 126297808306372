import React, { useContext, useEffect, useState } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { LabelMedium } from "../../../../components/elements/typography/Typography";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { TextButton } from "../../../../components/elements/button/text/TextButton";
import { QuickFilters } from "./QuickFilters";
import { AssetsFiltersPane } from "../../filters/AssetsFiltersPane";
import { ThemeContext } from "styled-components";
import { AssetsViewProps } from "../../../../types/AssetsView";

type Props = {
  assetsView: AssetsViewProps;
  handleAssetsViewChange: (view: AssetsViewProps) => void;

  triggerOpenFilterPane: number;
};

export const MapFilters = ({
  assetsView,
  handleAssetsViewChange,
  triggerOpenFilterPane,
}: Props) => {
  const theme = useContext(ThemeContext);
  const [isQuickFiltersCollapsed, setIsQuickFiltersCollapsed] = useState(false);
  const [showFiltersPanel, setShowFiltersPanel] = useState(false);

  useEffect(() => {
    if (triggerOpenFilterPane > 0) {
      setShowFiltersPanel(true);
    }
  }, [triggerOpenFilterPane]);

  return (
    <>
      <Flex align="center" gap="8px" justify="between" w100>
        <LabelMedium>Quick Filters</LabelMedium>
        <IconButton
          iconName={isQuickFiltersCollapsed ? "chevronDown" : "chevronUp"}
          color={theme.black700}
          onClick={() => setIsQuickFiltersCollapsed(!isQuickFiltersCollapsed)}
          size="small"
        />
      </Flex>
      {!isQuickFiltersCollapsed && <QuickFilters />}
      <TextButton
        label="+ Add custom filter"
        onClick={() => setShowFiltersPanel(true)}
      />
      {showFiltersPanel && (
        <AssetsFiltersPane
          assetsView={assetsView}
          setAssetsView={handleAssetsViewChange}
          onClose={() => setShowFiltersPanel(false)}
        />
      )}
    </>
  );
};
