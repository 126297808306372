import React from "react";
import { IconProps } from "./WaspIcon";

export function CreditsIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8274 7.75186C18.7884 5.82997 15.8683 4.38477 11.9996 4.38477C8.13095 4.38477 5.21079 5.82997 5.17183 7.75186C5.17095 7.76525 5.16406 7.7766 5.16406 7.79026V16.2084C5.16406 18.1502 8.10254 19.6145 11.9996 19.6145C15.8967 19.6145 18.8352 18.1502 18.8352 16.2084V7.79023C18.8352 7.7766 18.8283 7.76525 18.8274 7.75186ZM17.6352 10.5963C17.606 10.7865 17.5357 10.968 17.429 11.1281C17.3223 11.2883 17.1819 11.4231 17.0176 11.5232C15.5108 12.4426 13.7626 12.8883 11.9996 12.8024C10.2347 12.8881 8.48482 12.4415 6.97695 11.5203C6.81373 11.4202 6.67432 11.2856 6.56847 11.126C6.46261 10.9664 6.39285 10.7856 6.36406 10.5963V9.76198C8.0577 10.7908 10.0202 11.2899 11.9996 11.1951C13.979 11.2899 15.9415 10.7908 17.6352 9.76198V10.5963ZM6.36406 12.5513C8.05823 13.5805 10.0191 14.0854 11.9996 14.0024C13.9802 14.0859 15.9411 13.581 17.6352 12.5516V13.4024C17.606 13.5927 17.5356 13.7742 17.4289 13.9344C17.3222 14.0947 17.1819 14.2296 17.0176 14.3299C15.5106 15.2488 13.7625 15.6942 11.9996 15.6084C10.2347 15.6942 8.48483 15.2475 6.97695 14.3264C6.81372 14.2263 6.67432 14.0917 6.56846 13.9321C6.46261 13.7725 6.39285 13.5917 6.36406 13.4024V12.5513ZM11.9996 5.58477C15.2228 5.58477 17.6311 6.74902 17.6311 7.79023C17.6311 8.83086 15.2229 9.99511 11.9996 9.99511C8.77636 9.99511 6.36817 8.83086 6.36817 7.79023C6.36817 6.74902 8.77641 5.58477 11.9996 5.58477ZM11.9996 18.4144C8.77401 18.4144 6.36406 17.2496 6.36406 16.2084V15.3573C8.05824 16.3865 10.0191 16.8914 11.9996 16.8084C13.9802 16.8919 15.9411 16.3871 17.6352 15.3577V16.2084C17.6352 17.2496 15.2252 18.4144 11.9996 18.4144Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
