import { useContext, useState } from "react";
import { LinkButton } from "../../components/elements/button/link/LinkButton";
import { HeaderMain } from "../../components/elements/typography/Typography";
import { useApiProducts } from "../../hooks/queries/productsContext";
import { ProductsFilter } from "./productsFilter/ProductsFilter";
import { useScreenWidth } from "../../hooks/utilsHooks";
import {
  SCREEN_MEDIUM_LAPTOP_WIDTH,
  SCREEN_MOBILE_WIDTH,
  SCREEN_VERY_SMALL_LAPTOP_WIDTH,
} from "../../shared/consts";
import { BottomBlockContainer } from "./bottomBlock/BottomBlockContainer";
import { Finding } from "../../types/Finding";
import { FindingPane } from "../findings/findingPane/FindingPane";
import { Project } from "../../types/Project";
import { ProjectPane } from "../projects/projectPane/ProjectPane";
import { ReportsGenerator } from "../reports/ReportsGenerator";
import { RiskScoreCard } from "./topBlocks/riskScore/RiskScoreCard";
import { DashboardOverviewCard } from "./topBlocks/dashboardOverview/DashboardOverviewCard";
import { useSearchParams } from "react-router-dom";
import { FindingsChartsContainer } from "../findings/findingsCharts/FindingsChartsContainer";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { IntelFeed } from "./intelligenceFeed/IntelFeed";
import { Flex } from "../../components/layouts/flex/Flex";
import { Mixpanel } from "../../shared/mixpanel";
import { Tours } from "../../tours/Tours";
import { dashboardSteps } from "../../tours/DashboardTourSteps";
import { ThemeContext } from "styled-components";
import { RequestsModal } from "../projects/RequestsModal";
import { ExpressPtModal } from "../projects/projectScheduler/ExpressPtModal";
import Onboarding from "../welcome/onboarding/Onboarding";
import { AlertsBanners } from "../alertsBanners/AlertsBanners";
import { useTrackPage } from "../../hooks/trackingHooks";
import { useApiMe } from "../../hooks/queries/meContext";
import { MobileDashboard } from "./MobileDashboard";
import { ASMUpdates } from "../asmUpdates/ASMUpdates";
import { Box } from "../../components/elements/box/Box";

export type Tab = "products" | "projects" | "activeIssues";

const Dashboard = () => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;
  const isVerySmallLaptop = screenWidth < SCREEN_VERY_SMALL_LAPTOP_WIDTH;
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  const [searchParams, setSearchParams] = useSearchParams();

  const productId = searchParams.get("productId");
  const projectId = searchParams.get("projectId");
  const showOnboardingModal = searchParams.get("onboarding");

  const { data: me } = useApiMe();
  const { data: products } = useApiProducts();

  const [selectedProduct, setSelectedProduct] = useState<number | "all">(
    parseInt(productId || "0") || "all"
  );

  const [project, setProject] = useState<Project | undefined>(undefined);
  const [showProjectPane, setShowProjectPane] = useState<boolean>(
    !!projectId || false
  );

  const [showFindingPane, setShowFindingPane] = useState<boolean>(false);
  const [selectedFinding, setSelectedFinding] = useState<Finding | null>(null);
  const [showReportsGen, setShowReportsGen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<Tab>("products");
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showExpressPtModal, setShowExpressPtModal] = useState(false);

  useTrackPage("Dashboard", undefined, me);

  const switchToExpressTest = () => {
    Mixpanel.track("Express Test");
    setShowRequestsModal(false);
    setShowExpressPtModal(true);
  };

  const onSelectedFinding = (finding: Finding) => {
    setSelectedFinding(finding);
    setShowFindingPane(true);
  };
  const onCloseFindingPane = () => {
    setSelectedFinding(null);
    setShowFindingPane(false);
  };

  const onOpenProjectPane = (project: Project) => {
    setSearchParams({ ...searchParams, projectId: `${project.id}` });
    setShowProjectPane(true);
    setProject(project);
  };

  const onCloseProjectPane = () => {
    setShowProjectPane(false);
    setProject(undefined);
  };

  const onOpenReportsGen = () => {
    setShowReportsGen(true);
    Mixpanel.track("Open Reports Generator");
  };

  const [numberOfShowingBanners, setNumberOfShowingBanners] = useState(0);

  const getAmountOfBanners = () => {
    const alertBanners = document.querySelectorAll(
      '[data-name="alert-banner"]'
    );
    console.log("numberOfShowingBanners", alertBanners.length);
    setNumberOfShowingBanners(alertBanners.length);
  };

  if (isMobile) return <MobileDashboard />;
  return (
    <Flex column w100 padding="0px 0px 16px 0px">
      <Tours steps={dashboardSteps(theme, setSelectedTab)} />
      <Flex gap="24px" justify="between" h100 w100>
        <Flex
          column
          h100
          gap="24px"
          style={{
            width: isLaptop ? "60%" : "75%",
            marginTop: numberOfShowingBanners > 0 ? "0px" : "-24px",
          }}
        >
          <Flex column gap="8px">
            <AlertsBanners onRender={getAmountOfBanners} />
          </Flex>
          <Flex column={isVerySmallLaptop} gap="8px" justify="between" w100>
            <Flex align="center" gap="24px">
              <HeaderMain data-testid="dashboard-title">Dashboard</HeaderMain>
              <Flex align="center">
                <ProductsFilter
                  selectedProduct={selectedProduct}
                  products={products || []}
                  onChange={(selectedProduct) => {
                    setSelectedProduct(selectedProduct);
                    setSearchParams({
                      productId: selectedProduct.toString(),
                    });
                    Mixpanel.track("Change Product", {
                      product: selectedProduct,
                    });
                  }}
                />
              </Flex>
            </Flex>

            <Flex align="center">
              <Flex align="center" data-tut="export-reports">
                <LinkButton
                  dataTestId="open-reports-generator"
                  label="Export Reports"
                  iconName="export"
                  size="medium"
                  onClick={onOpenReportsGen}
                />
              </Flex>

              <MainButton
                label="Schedule a Test"
                iconName="penetrationTest"
                size="medium"
                onClick={() => setShowRequestsModal(true)}
              />
            </Flex>
          </Flex>
          <Flex w100 justify="between" gap="24px" column={isLaptop}>
            <RiskScoreCard selectedProduct={selectedProduct} />
            <DashboardOverviewCard selectedProduct={selectedProduct} />
          </Flex>
          <FindingsChartsContainer selectedProduct={selectedProduct} />
          {!isLaptop && (
            <BottomBlockContainer
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              onSelectedFinding={onSelectedFinding}
              onOpenProjectPane={onOpenProjectPane}
              selectedProduct={selectedProduct}
              // We need to calculate the height of the banners to adjust the height of the container,
              // banner height is 63px and the paddings of the containers is about 45px
              bannersHeight={
                numberOfShowingBanners > 0
                  ? numberOfShowingBanners * 63 + 45
                  : 0
              }
            />
          )}
        </Flex>

        <Flex column gap="24px" style={{ width: isLaptop ? "40%" : "25%" }}>
          <Box
            style={{
              padding: isMobile ? "8px" : "24px",
              paddingBottom: "8px",
              paddingTop: "16px",
              width: "100%",
              height: isLaptop ? "100%" : "calc(100vh - 420px)",
              minHeight: "700px",
              overflowY: "hidden",
            }}
            data-tut={"asm-updates"}
            id="top-right-box"
          >
            <ASMUpdates />
          </Box>
          {!isLaptop && <IntelFeed />}
        </Flex>
      </Flex>

      {isLaptop && (
        <Flex column gap="16px" style={{ marginTop: "24px" }}>
          <BottomBlockContainer
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            onSelectedFinding={onSelectedFinding}
            onOpenProjectPane={onOpenProjectPane}
            selectedProduct={selectedProduct}
          />
          <IntelFeed />
        </Flex>
      )}

      {showFindingPane && (
        <FindingPane
          onClose={onCloseFindingPane}
          findingId={selectedFinding?.id}
        />
      )}
      {showProjectPane && (
        <ProjectPane project={project} onClose={onCloseProjectPane} />
      )}
      {showReportsGen && (
        <ReportsGenerator onClose={() => setShowReportsGen(false)} />
      )}
      {showRequestsModal && (
        <RequestsModal
          onClose={() => {
            Mixpanel.track("Cancel button");
            setShowRequestsModal(false);
          }}
          switchToExpressTest={switchToExpressTest}
        />
      )}
      {showExpressPtModal && (
        <ExpressPtModal
          onClose={() => {
            Mixpanel.track("Close button");
            setShowExpressPtModal(false);
            setSearchParams({ ...searchParams, express: "" });
          }}
        />
      )}
      {showOnboardingModal && (
        <Onboarding
          onClose={() => {
            searchParams.delete("onboarding");
            setSearchParams(searchParams);
          }}
        />
      )}
    </Flex>
  );
};

export default Dashboard;
