import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../../../components/elements/badge/Badge";
import { Box } from "../../../../components/elements/box/Box";
import { Icon } from "../../../../components/elements/icon/Icon";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import {
  HeaderSecondary,
  LabelMini,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { TextButton } from "../../../../components/elements/button/text/TextButton";
import { RiskScoreTooltip } from "./RiskScoreTooltip";
import { RiskScoreDoughnutChart } from "./RiskScoreDoughnutChart";
import { useApiRiskScoreHistory } from "../../../../hooks/queries/riskScoreHistoryContext";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { RiskScorePane } from "../riskScorePane/RiskScorePane";
import { LegendItem } from "../../../../components/elements/legend/LegendItem";
import {
  DAY_MS,
  SCREEN_MEDIUM_LAPTOP_WIDTH,
  SCREEN_MOBILE_WIDTH,
} from "../../../../shared/consts";
import { Mixpanel } from "../../../../shared/mixpanel";
import { useScreenWidth } from "../../../../hooks/utilsHooks";
import RiskScorePaneMobile from "../riskScorePane/RiskScorePaneMobile";

type Filters =
  | {
      page_size: number;
      ordering: string;
      products: number[];
    }
  | {
      page_size: number;
      ordering: string;
    };

type Props = {
  selectedProduct?: number | "all";
};

export const RiskScoreCard = (props: Props) => {
  const { selectedProduct } = props;
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const isLaptopScreen = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;

  const [showPane, setShowPane] = useState(false);
  const defaultFilter = {
    page_size: 365,
    ordering: "-created_at",
  };
  const [riskScoreFilters, setRiskScoreFilters] =
    useState<Filters>(defaultFilter);

  useEffect(() => {
    setRiskScoreFilters(
      !!selectedProduct && selectedProduct !== "all"
        ? { ...defaultFilter, products: [selectedProduct] }
        : defaultFilter
    ); // eslint-disable-next-line
  }, [selectedProduct]);

  const { data: riskHistoryItems, isFetching } =
    useApiRiskScoreHistory(riskScoreFilters);

  const getRiskScoreItemMonthBeforeNow = () => {
    if (selectedProduct && selectedProduct !== "all") return null;
    const beforeMe = new Date();
    beforeMe.setTime(Date.now() - DAY_MS * 30);
    var prev = riskHistoryItems?.find(
      (item) => new Date(item.created_at) < beforeMe
    );
    if (!prev) {
      // get the last item
      prev = riskHistoryItems?.[riskHistoryItems?.length - 1];
    }
    return prev;
  };

  const getNewestHistoryItem = () => {
    return riskHistoryItems?.[0];
  };
  const latestScore = getNewestHistoryItem();
  const currentScore = latestScore?.risk_score || 0;
  const prevScore = getRiskScoreItemMonthBeforeNow()?.risk_score || 0;
  const scoreDelta = (currentScore || 0) - (prevScore || 0);

  const getRiskLabel = () => {
    if (currentScore <= 30) return "Low Risk";
    if (currentScore <= 60) return "Moderate Risk";
    if (currentScore <= 85) return "Elevated Risk";
    return "High Risk";
  };

  return (
    <Box
      data-tut="dashboard-risk-score"
      style={{
        maxWidth: isLaptopScreen ? "100%" : "450px",
        minWidth: isLaptopScreen ? "100%" : "309px",
        flexGrow: 1,
        minHeight: "204px",
        borderTopRightRadius: "0px",
        display: "flex",
        overflowX: "hidden",
        padding: isMobile ? "8px" : "24px",
      }}
    >
      {showPane && isMobile && (
        <RiskScorePaneMobile onClose={() => setShowPane(false)} />
      )}
      {showPane && !isMobile && (
        <RiskScorePane onClose={() => setShowPane(false)} />
      )}

      <Flex column gap="16px" w100 justify="center">
        <Flex justify="between" align="center" w100>
          <Flex align="center" gap="4px" style={{ width: "calc(100% - 60px)" }}>
            <Icon name="securityStatus" size={32} color={theme.redPrimary} />
            <HeaderSecondary className="text-truncate" title="Risk Score">
              Risk Score
            </HeaderSecondary>
            <Tooltip
              placement="bottom"
              content={<RiskScoreTooltip latestRiskHistory={latestScore} />}
            >
              <Icon name="info" size={16} color={theme.textSub} />
            </Tooltip>
          </Flex>

          <TextButton
            isTextTruncate
            label="See More"
            disabled={selectedProduct ? selectedProduct !== "all" : false}
            onClick={() => {
              Mixpanel.track("See More");
              setShowPane((state) => !state);
            }}
          />
        </Flex>
        <Flex align="center" gap="24px">
          <RiskScoreDoughnutChart
            latestRiskHistory={latestScore}
            isLoadingData={isFetching && !scoreDelta}
            labelText={getRiskLabel()}
            size={110}
          />
          <Flex
            column
            justify="between"
            h100
            style={{
              overflow: "hidden",
            }}
          >
            <LegendItem
              label="Critical Issues"
              color={theme.redPrimary}
              ellipseSize={8}
              labelSize={12}
            />
            <LegendItem
              label="Breached SLA"
              color={theme.orangePrimary}
              ellipseSize={8}
              labelSize={12}
            />
            <LegendItem
              label="Amount Of Findings"
              color={theme.yellowPrimary}
              ellipseSize={8}
              labelSize={12}
            />

            {isFetching && !scoreDelta ? (
              <SkeletonLoading height="26px" width="100%" />
            ) : (
              <Badge
                className="d-flex align-items-center justify-content-between w-100"
                backgroundColor={theme.black300}
                color={scoreDelta > 0 ? theme.redPrimary : theme.greenPrimary}
                data-testid={scoreDelta > 0 ? "trend-down" : "trend-up"}
              >
                <Icon
                  name={scoreDelta > 0 ? "trendDown" : "trendUp"}
                  color={scoreDelta > 0 ? theme.redPrimary : theme.greenPrimary}
                  size={20}
                />

                <LabelMini className="fw-bold me-1">
                  {scoreDelta > 0 ? "+" : ""}
                  {scoreDelta}
                </LabelMini>
                <LabelMini
                  className="text-truncate"
                  title="in the Last 30 Days"
                  style={{
                    color: theme.black600,
                    textTransform: "capitalize",
                  }}
                >
                  in the Last 30 Days
                </LabelMini>
              </Badge>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
