import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "./utils";
import { getPage, getSingleItem, getItems } from "./sdk";
import { bitAfterNow, getQueryParams } from "../../shared/helper";
import { FindingEvent, FindingEventPage } from "../../types/FindingEvent";
// import { IS_DEMO_ENV } from "./utils";
// import { mockScanSummaries } from "../../mocks/scan_summaries";

const key = "finding-events";

// Returns a single scan summary object
export const useApiFindingEvent = (findingEventId: string) =>
  useQuery<FindingEvent | undefined, Error>({
    queryKey: [key, findingEventId],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    // enabled:!!findingEventId,
    queryFn: async (): Promise<FindingEvent | undefined> =>
      getSingleItem(key, findingEventId) as Promise<FindingEvent | undefined>,
  });

// Returns a list of scan summary object
export const useApiFindingEvents = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useQuery<FindingEvent[] | undefined, Error>({
    queryKey: [key, filters],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled,
    queryFn: async (): Promise<FindingEvent[] | undefined> =>
      getItems(key, filters) as Promise<FindingEvent[] | undefined>,
  });

export const useApiFindingEventsPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true,
  refetchInterval: number = 0
) =>
  useInfiniteQuery<FindingEventPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    refetchInterval: refetchInterval,
    queryFn: async ({ pageParam = 1 }): Promise<FindingEventPage> => {
      // if (IS_DEMO_ENV) return mockScanSummaries;
      return getPage(key, { ...filters, page: pageParam });
    },
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });
