import React from "react";
import { IconProps } from "./WaspIcon";

export function ASMIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_454_352)">
        <path
          d="M18.0134 5.6772C16.2566 5.16584 14.5574 4.47374 12.9433 3.612C12.6597 3.43729 12.3331 3.34479 12 3.34479C11.6669 3.34479 11.3403 3.43729 11.0567 3.612C9.44268 4.47363 7.74377 5.16573 5.98714 5.6772C5.61297 5.79538 5.2877 6.03264 5.06089 6.35284C4.83409 6.67304 4.71819 7.05862 4.73086 7.4508L4.92886 13.4268C5.3701 16.4291 8.5189 18.9329 10.3571 20.1575C10.8433 20.4826 11.4151 20.6561 12 20.6561C12.5849 20.6561 13.1566 20.4826 13.6429 20.1575C15.4811 18.9329 18.6299 16.4291 19.077 13.3588L19.269 7.4514C19.2818 7.05921 19.166 6.67359 18.9394 6.35329C18.7127 6.03299 18.3875 5.79557 18.0134 5.6772ZM17.8839 13.2516C17.5101 15.7956 14.6496 18.0439 12.9774 19.1584C12.6882 19.352 12.348 19.4555 12 19.4555C11.6519 19.4555 11.3117 19.352 11.0226 19.1584C9.35038 18.0439 6.48982 15.7951 6.1219 13.32L5.9299 7.41264C5.92553 7.28248 5.96373 7.15446 6.0387 7.04797C6.11368 6.94149 6.22133 6.86237 6.34534 6.8226C8.19646 6.28231 9.98568 5.54901 11.6835 4.63476C11.778 4.57441 11.8878 4.54235 12 4.54235C12.1121 4.54235 12.2219 4.57441 12.3164 4.63476C14.0145 5.54897 15.804 6.28212 17.6555 6.82212C17.7793 6.86201 17.8869 6.94119 17.9617 7.04765C18.0366 7.15412 18.0747 7.28208 18.0703 7.41216L17.8839 13.2516Z"
          fill={props.color || "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_454_352">
          <rect
            width={size}
            height={size}
            fill={props.color || "currentColor"}
          />
        </clipPath>
      </defs>
    </svg>
  );
}
