import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import { getStatusName } from "../../../shared/findingsHelper";
import { Finding } from "../../../types/Finding";
import {
  HeaderSecondary,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { Section } from "../../../components/elements/section/Section";
import { FindingStatusMenu } from "../findingStatus/FindingStatusMenu";

type Props = {
  finding: Finding | undefined | null;
  headerStyle: "secondary" | "sub";
  width?: string;
};

export const StatusItem = (props: Props) => {
  const { finding, headerStyle, width } = props;
  const theme = useContext(ThemeContext);
  const [showEditButton, setShowEditButton] = useState(false);
  const [showStatusMenu, setShowStatusMenu] = useState<boolean>(false);

  return (
    <>
      <div
        onMouseEnter={() => setShowEditButton(true)}
        onMouseLeave={() => setShowEditButton(false)}
        className="d-flex flex-column"
        style={{ width }}
      >
        <Section
          title="Status"
          noSeparator
          headerStyle={headerStyle === "secondary" ? "body" : "label"}
          width={width}
        >
          <div
            data-testid="status-box"
            className="d-flex align-items-center gap-8 pointer w-100"
            onClick={() => setShowStatusMenu((prev) => !prev)}
          >
            <Icon
              name={showEditButton ? "edit" : finding?.status || "spinner"}
              size={32}
              color={theme.primary}
            />
            {headerStyle === "secondary" ? (
              <HeaderSecondary
                style={{
                  color: showEditButton ? theme.primary : theme.black700,
                }}
                className="text-truncate w-100"
              >
                {getStatusName(finding?.status || "presented")}
              </HeaderSecondary>
            ) : (
              <></>
            )}
            {headerStyle === "sub" ? (
              <HeaderSubBold
                style={{
                  color: showEditButton ? theme.primary : theme.black700,
                }}
                className="text-truncate w-100"
              >
                {getStatusName(finding?.status || "presented")}
              </HeaderSubBold>
            ) : (
              <></>
            )}
          </div>
        </Section>
        {showStatusMenu && finding && (
          <div style={{ position: "relative", top: "-100px" }}>
            <FindingStatusMenu
              close={() => setShowStatusMenu(false)}
              finding={finding}
              customStyle={{ top: "100px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};
