import { AssetsConfigs } from "../../../types/Project";

const eeBackOffice = {
  asset: 0.5,
  login: 0.5,
  sso: 0.5,
  signup: 0.5,
  password_reset: 0.5,
  users_mgmt: 0.5,
  file_upload: 0.5,
};

export const calcEffortEstimation = (
  configs: AssetsConfigs,
  totalAssetsCount?: number
): number => {
  let duration = 0;
  Object.keys(configs).forEach((assetId) => {
    duration += eeBackOffice.asset;
    const config = configs[parseInt(assetId)];
    if (config.login) duration += eeBackOffice.login;
    if (config.login_types.sso) duration += eeBackOffice.sso;
    if (config.signup) duration += eeBackOffice.signup;
    if (config.password_reset) duration += eeBackOffice.password_reset;
    if (config.users_mgmt) duration += eeBackOffice.users_mgmt;
    if (config.file_upload) duration += eeBackOffice.file_upload;
  });
  // in case all assets are configured the same
  if (Object.keys(configs).includes("0") && totalAssetsCount)
    duration = duration * totalAssetsCount;
  return duration;
};
