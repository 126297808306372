import { ReactNode, useEffect } from "react";
import { MainButton } from "../../elements/button/main/MainButton";
import { SecondaryButton } from "../../elements/button/secondary/SecondaryButton";
import {
  BodyMedium,
  HeaderSecondary,
} from "../../elements/typography/Typography";
import { Modal } from "../../elements/modal/Modal";
import { Illustration } from "../../elements/illustrations/Illustration";
import { Flex } from "../../layouts/flex/Flex";

export type DeleteModalProps = {
  itemName: string;
  itemType: string;
  body?: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

export const DeleteModal = (props: DeleteModalProps) => {
  const { itemName, itemType, body, onClose, onConfirm, isLoading, isSuccess } =
    props;

  useEffect(() => {
    isSuccess && setTimeout(() => onClose(), 3000);
  }, [isSuccess, onClose]);

  return (
    <Modal onClose={() => onClose()} width="473px">
      <div
        data-testid="delete-modal"
        className="d-flex flex-column justify-content-center align-items-center gap-16"
      >
        {isSuccess ? (
          <div
            data-testid="delete-success"
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Illustration name="success" />
            <HeaderSecondary>
              {itemName || itemType} successfully deleted
            </HeaderSecondary>
          </div>
        ) : (
          <>
            <Illustration name="delete" />

            <HeaderSecondary>Delete {itemName} ?</HeaderSecondary>

            <Flex gap="4px">
              <BodyMedium>{body}</BodyMedium>
            </Flex>

            <MainButton
              dataTestId="keep-btn"
              label={`Keep ${itemType}`}
              onClick={() => onClose()}
              style={{ width: "100%" }}
              size="medium"
            />
            <SecondaryButton
              dataTestId="modal-delete-btn"
              label={`Delete ${itemType}`}
              onClick={() => onConfirm()}
              style={{ width: "100%" }}
              inProgress={isLoading}
              variant="danger"
              size="medium"
            />
          </>
        )}
      </div>
    </Modal>
  );
};
