import React, { useContext } from "react";
import { Flex } from "../../../layouts/flex/Flex";
import { InputText, TextInputProps } from "./InputText";
import { ThemeContext } from "styled-components";

type Props = TextInputProps & {
  leftText: string;
};

export const InputTextWithLeftAddon = (props: Props) => {
  const theme = useContext(ThemeContext);
  const { leftText, width } = props;
  return (
    <Flex
      style={{
        width: width || "",
      }}
    >
      <Flex
        align="center"
        padding="0 16px"
        style={{
          borderRadius: "0.6em 0 0 0.6em",
          backgroundColor: theme.black500,
          color: theme.black600,
          fontSize: "14px",
          whiteSpace: "nowrap",
        }}
      >
        {leftText}
      </Flex>
      <InputText
        style={{
          borderRadius: "0 0.6em 0.6em 0",
        }}
        {...props}
      />
    </Flex>
  );
};
