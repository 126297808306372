import { useQuery } from "@tanstack/react-query";
import { getItems } from "./sdk";
import { Scanner } from "../../types/Scanner";
import { IS_DEMO_ENV, IS_DEVELOPMENT_MODE } from "./utils";
import { mockScanners } from "../../mocks/scanners";

const key = "scanners";

export const useApiScanners = (
  customerId: number | undefined,
  params?: { [key: string]: any },
  enabled: boolean = true
) =>
  useQuery<Scanner[], Error>({
    queryKey: [key],
    keepPreviousData: true,
    placeholderData: [],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!customerId && enabled,
    queryFn: async (): Promise<Scanner[]> => {
      if (!IS_DEMO_ENV && !IS_DEVELOPMENT_MODE)
        return getItems(
          key,
          !!params
            ? { ...params, customer_id: customerId }
            : { customer_id: customerId }
        );
      return mockScanners;
    },
  });
