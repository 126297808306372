import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AlertBanner from "../../components/elements/toastTypes/AlertBanner";
import { BodyRegular } from "../../components/elements/typography/Typography";
import { useApiAssetsWithViewPaging } from "../../hooks/queries/assetsContext";
import { useMemo } from "react";
import { toBase64AssetsView } from "../../shared/helper";
import { unverifiedAssetsFilter } from "../assets/assetVerification/VerifyAssetModal";

type UnverifiedAssetsAlertProps = {
  onRender?: () => void;
};

export const UnverifiedAssetsAlert = ({
  onRender,
}: UnverifiedAssetsAlertProps) => {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Look for un-verified assets
  const { data: unverified_domains_page } = useApiAssetsWithViewPaging(
    unverifiedAssetsFilter,
    undefined,
    undefined
  );
  const unverified_domains = useMemo(
    () =>
      unverified_domains_page?.pages.map((page) => page.results || []).flat(),
    [unverified_domains_page]
  );

  return (
    <>
      {!!unverified_domains?.length && (
        <AlertBanner
          message={
            <BodyRegular>
              {unverified_domains.length > 1
                ? unverified_domains.length > 20
                  ? "You have more than 20 unverified domains"
                  : `You have ${unverified_domains.length} unverified domains`
                : `You have an unverified domain - ${unverified_domains[0].name}`}
            </BodyRegular>
          }
          customBackground={"error"}
          customIcon={"warning"}
          buttonLabel="Verify domains"
          onButtonClick={() => {
            if (location.pathname === "/assets")
              setSearchParams((prev) => ({
                ...prev,
                verify_asset: 0,
                view: toBase64AssetsView({
                  filters: unverifiedAssetsFilter,
                  name: "",
                }),
              }));
            else
              navigate(
                unverified_domains.length > 1
                  ? `/assets?view=${toBase64AssetsView({
                      filters: unverifiedAssetsFilter,
                      name: "",
                    })}&verify_asset=0`
                  : `/assets?verify_asset=${unverified_domains[0].id}`
              );
          }}
          showInPages={["/", "/dashboard", "/assets"]}
          onRender={onRender}
        />
      )}
    </>
  );
};
