import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../icon/Icon";
import { SliderWithIconStyle, ToggleTextWrapperStyle } from "./Toggle.style";

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  offText: string;
  offIconName: string;
  onText: string;
  onIconName: string;
  disabled?: boolean;
  width?: string;
  dataTestId?: string;
};

export function ToggleWithIcon(props: Props) {
  const {
    checked,
    onChange,
    offText,
    offIconName,
    onText,
    onIconName,
    disabled = false,
    width = "248px",
    dataTestId,
  } = props;
  const theme = useContext(ThemeContext);

  const getColor = (isOn: boolean): string => {
    return disabled ? theme.black600 : isOn ? theme.blue600 : theme.blue500;
  };

  const onSwitch = (state: boolean) => !disabled && onChange(state);

  return (
    <div className={`d-flex`}>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          position: "relative",
          width: width,
          height: "40px",
          padding: "4px",
        }}
      >
        <input
          data-testid={dataTestId}
          disabled={disabled}
          type="checkbox"
          role="button"
          aria-expanded="false"
          style={{
            display: "none",
            position: "relative",
            cursor: "unset",
          }}
          checked={checked}
          onChange={() => onSwitch(!checked)}
        />
        <SliderWithIconStyle
          checked={!checked}
          disabled={disabled}
          onClick={() => onSwitch(!checked)}
        />
        <ToggleTextWrapperStyle
          checked={!checked}
          disabled={disabled}
          onClick={() => onSwitch(true)}
          data-testid="toggle-on-button"
        >
          <span
            onClick={() => onSwitch(true)}
            style={{
              position: "absolute",
              pointerEvents: "none",
            }}
          >
            <div className="d-flex align-items-center gap-4 pointer">
              <Icon name={onIconName} size={24} color={getColor(!checked)} />
              <span>{onText}</span>
            </div>
          </span>
        </ToggleTextWrapperStyle>
        <ToggleTextWrapperStyle
          onClick={() => onSwitch(false)}
          checked={checked}
          disabled={disabled}
          data-testid="toggle-off-button"
        >
          <span
            onClick={() => onSwitch(false)}
            style={{
              position: "absolute",
              pointerEvents: "none",
            }}
          >
            <div className="d-flex align-items-center gap-4 pointer">
              <Icon name={offIconName} size={24} color={getColor(checked)} />
              <span>{offText}</span>
            </div>
          </span>
        </ToggleTextWrapperStyle>
      </div>
    </div>
  );
}
