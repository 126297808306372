import { Dispatch, SetStateAction, useContext, useState } from "react";
import { ScanEvent } from "../../../types/ScanEvent";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import {
  BodySemiBold,
  HeaderSecondary,
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Box } from "../../../components/elements/box/Box";
import { AssetItem, ScanTimes } from "./ScanResultsPane";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import {
  useApiFindingsCounts,
  useApiFindingsPaging,
} from "../../../hooks/queries/findingContext";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { useApiScanSummary } from "../../../hooks/queries/scansContext";
import { Icon } from "../../../components/elements/icon/Icon";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { FindingShortCard } from "../../findings/findingsCards/FindingShortCard";
import { CircularProgressBar } from "../../../components/elements/progressBar/CircularProgressBar";

type Props = {
  scan: ScanEvent | undefined;
  setAssetOnDisplay: Dispatch<SetStateAction<number>>;
};

export const ScanResultsPaneFindings = (props: Props) => {
  const { scan, setAssetOnDisplay } = props;
  const theme = useContext(ThemeContext);

  const { data: scanSummary } = useApiScanSummary(`${scan?.id}`);
  const { data: findingCounts } = useApiFindingsCounts({ scan_id: scan?.id });
  const { data: findingsPages } = useApiFindingsPaging({ scan_id: scan?.id });

  const [showAssets, setShowAssets] = useState<boolean>(false);

  const findings = findingsPages?.pages
    .map((page) => page.results || [])
    .flat();

  const filteredCount =
    scanSummary?.total_findings && scanSummary.total_reported_findings
      ? scanSummary.total_findings - scanSummary.total_reported_findings
      : 0;

  const BlockCounter = ({ title, value }: { title: string; value: number }) => (
    <Flex
      align="center"
      justify="center"
      gap="8px"
      style={{
        background: theme.blue50,
        width: "160px",
        height: "50px",
      }}
    >
      <HeaderSubBold color={theme.blue600}>{value}</HeaderSubBold>
      <LabelRegular>{title}</LabelRegular>
    </Flex>
  );

  return (
    <>
      {showAssets ? (
        <Flex column gap="24px">
          <Flex gap="8px" align="center">
            <IconButton
              iconName="chevronLeft"
              onClick={() => setShowAssets(false)}
            />
            <HeaderSecondary>Discovered Assets</HeaderSecondary>
          </Flex>
          <SeparatorHorizontal />
          {scanSummary?.assets?.map((asset) => (
            <AssetItem
              asset={asset}
              key={asset.id}
              setAssetOnDisplay={setAssetOnDisplay}
            />
          ))}
        </Flex>
      ) : (
        <>
          <Box>
            <Flex column w100 gap="24px">
              <ScanTimes scan={scan} />
              <SeparatorHorizontal />
              <Flex gap="24px" justify="evenly">
                <Flex column gap="8px" align="start" style={{ width: "190px" }}>
                  <LabelRegular>Total Findings</LabelRegular>
                  <HeaderSubBold>{scanSummary?.total_findings}</HeaderSubBold>
                </Flex>
                <SeparatorVertical height="58px" />
                <Flex column gap="8px" align="start" style={{ width: "190px" }}>
                  <LabelRegular>Reported Findings</LabelRegular>
                  <Flex gap="8px">
                    <HeaderSubBold>
                      {scanSummary?.total_reported_findings}
                    </HeaderSubBold>
                  </Flex>
                </Flex>
                <SeparatorVertical height="58px" />
                <Flex column gap="8px" align="start" style={{ width: "190px" }}>
                  <LabelRegular>Scanned Assets</LabelRegular>
                  <Flex gap="8px" align="center" justify="start">
                    <Icon
                      name="assetsOutline"
                      size={32}
                      color={theme.primary}
                    />
                    <HeaderSubBold>
                      {scanSummary?.assets.length} Assets
                    </HeaderSubBold>
                    <IconButton
                      iconName="chevronRight"
                      onClick={() => setShowAssets(true)}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Box>

          <Flex column gap="8px">
            <Flex gap="8px" align="center">
              <HeaderSubBold>Filtered Findings</HeaderSubBold>
              <BodySemiBold>{filteredCount}</BodySemiBold>
            </Flex>
            <Box>
              <Flex gap="24px" justify="evenly" align="center">
                <Flex column gap="8px" align="start" style={{ width: "190px" }}>
                  <LabelRegular>Previously reported findings</LabelRegular>
                  <HeaderSubBold>
                    {scanSummary?.skipped_reported_findings}
                  </HeaderSubBold>
                </Flex>
                <SeparatorVertical height="32px" />
                <Flex column gap="8px" align="start" style={{ width: "190px" }}>
                  <LabelRegular>Merged Findings</LabelRegular>
                  <HeaderSubBold>{scanSummary?.merged_findings}</HeaderSubBold>
                </Flex>
                <SeparatorVertical height="32px" />
                <Flex column gap="8px" align="start" style={{ width: "190px" }}>
                  <LabelRegular>Ignored Findings</LabelRegular>
                  <HeaderSubBold>
                    {scanSummary?.skipped_ignored_findings}
                  </HeaderSubBold>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <SeparatorHorizontal />
          <Flex justify="between">
            <Flex gap="8px">
              <Icon name="findings" size={32} color={theme.primary} />
              <HeaderSecondary>Reported Findings</HeaderSecondary>
            </Flex>
            {findingCounts && (
              <Flex gap="16px" align="center">
                <Flex gap="8px" align="center">
                  <HeaderSubBold>{findingCounts.open}</HeaderSubBold>
                  <LabelRegular>Open</LabelRegular>
                </Flex>
                <SeparatorVertical height="24px" />
                <Flex gap="8px" align="center">
                  <HeaderSubBold>{findingCounts.fixed}</HeaderSubBold>
                  <LabelRegular>Solved</LabelRegular>
                </Flex>
                <SeparatorVertical height="24px" />
                <CircularProgressBar
                  value={(findingCounts.fixed / findingCounts.open) * 100 || 0}
                />
              </Flex>
            )}
          </Flex>
          <Flex align="center" gap="8px">
            {scanSummary?.findings_risks_counter &&
              Object.keys(scanSummary.findings_risks_counter).map((risk) => (
                <BlockCounter
                  key={risk}
                  title={risk}
                  value={scanSummary.findings_risks_counter[risk]}
                />
              ))}
          </Flex>
          {findings?.map((f) => (
            <FindingShortCard
              finding={f}
              key={f.id}
              hasUnreadComments={false}
            />
          ))}
        </>
      )}
    </>
  );
};
