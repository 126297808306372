import { useQuery } from "@tanstack/react-query";
import { getItems } from "./sdk";
import { RiskScoreHistory } from "../../types/RiskScoreHistory";

const key = "risk-history";

export const useApiRiskScoreHistory = (filters?: { [key: string]: any }) =>
  useQuery<RiskScoreHistory[], Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    placeholderData: [],
    enabled: true,
    queryFn: async (): Promise<RiskScoreHistory[]> => getItems(key, filters),
  });
