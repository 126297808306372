import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  invalidateApiQueries,
  removeItemFromContext,
  updateContext,
} from "./utils";
import { getItems, createItem, updateItem, deleteItem } from "./sdk";
import {
  AssetsConfigs,
  ProjectRequest,
  ProjectRequestStatus,
  TestSettings,
} from "../../types/Project";

const key = "project-requests";

// All possible attributes that can pass to API call
export interface ProjectRequestParams {
  user_email?: string; //Added after requests are created in API
  include_all_assets?: boolean;
  included_assets: number[];
  assets_configs: AssetsConfigs;
  test_settings: TestSettings;
  start_date: string;
  end_date: string;
  status: ProjectRequestStatus;
  onSuccessCallback?: (projectRequest: ProjectRequest) => void;
  onErrorCallback?: (error: Error) => void;
}

// Context is the object sent on updates
export interface ProjectRequestContext {
  projectRequestId: number;
  projectRequestData: ProjectRequestParams;
}

export const useApiProjectRequests = () =>
  useQuery<ProjectRequest[], Error>({
    queryKey: [key],
    keepPreviousData: true,
    placeholderData: [],
    enabled: true,
    queryFn: async (): Promise<ProjectRequest[]> => getItems(key),
  });

export const useApiCreateProjectRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<ProjectRequest, Error, ProjectRequestParams>({
    mutationKey: [key],
    mutationFn: async (newProjectRequest): Promise<ProjectRequest> =>
      await createItem(key, newProjectRequest),
    onError: (error: Error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSuccess: (data, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
      updateContext({ data, queryKey: [key, { id: data?.id }], queryClient });
    },
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiUpdateProjectRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<ProjectRequest, Error, ProjectRequestContext>({
    mutationKey: [key],
    mutationFn: async ({
      projectRequestId,
      projectRequestData,
    }: ProjectRequestContext): Promise<ProjectRequest> =>
      await updateItem(key, projectRequestId, projectRequestData),
    onSuccess: (data: ProjectRequest) =>
      updateContext({ data, queryKey: [key, { id: data?.id }], queryClient }),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiDeleteProjectRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<ProjectRequest, Error, ProjectRequest>({
    mutationKey: [key],
    mutationFn: async (projectRequest): Promise<any> =>
      await deleteItem(key, projectRequest, projectRequest.id),
    onSuccess: (data, variables) =>
      removeItemFromContext({
        data,
        queryKey: [key, { id: variables.id }],
        queryClient,
      }),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};
