import React, { useState } from "react";
import {
  AnalysisResult,
  getCategoryName,
  Issue,
  IssueCategory,
} from "./QuickScanResultsBL";
import { Flex } from "../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";
import { Box } from "../../components/elements/box/Box";
import {
  HeaderSubBold,
  LabelRegular,
} from "../../components/elements/typography/Typography";
import { Illustration } from "../../components/elements/illustrations/Illustration";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";
import { QuickScanResultsViewDetailsIssue } from "./QuickScanResultsViewDetailsIssue";
import { QuickScanContactUsModal } from "./QuickScanContactUsModal";

type Props = {
  analysisResult: AnalysisResult;
};

export const QuickScanResultsViewDetails = ({ analysisResult }: Props) => {
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const getRandomButtonLabel = () => {
    const labels = [
      "Get a Deep Scan",
      "Get a Thorough Scan",
      "Get a Full Scan",
      "Get a Penetration Test",
      "Get a Code Review",
    ];
    return labels[Math.floor(Math.random() * labels.length)];
  };

  const Category = ({
    issueCategory,
    issues,
  }: {
    issueCategory: IssueCategory;
    issues: Issue[];
  }) => {
    return (
      <Box className="w-100">
        {showContactUsModal && (
          <QuickScanContactUsModal
            onClose={() => setShowContactUsModal(false)}
          />
        )}
        {issues.length === 0 ? (
          <Flex column gap="16px">
            <HeaderSubBold>
              {`No ${getCategoryName(issueCategory, issues.length)} Issues`}
            </HeaderSubBold>
            <Flex column={isMobile} gap="16px" align="center">
              <Illustration
                name="success"
                style={{ height: "120px", width: "fit-content" }}
              />
              <Flex column gap="8px">
                <LabelRegular>
                  We would be happy to tell you that you are safe, but this scan
                  was only the tip of the iceberg.
                </LabelRegular>
                <LabelRegular>
                  Contact us for a thorough test and to verify and confirm that
                  your app is secure.
                </LabelRegular>
                <SecondaryButton
                  label={getRandomButtonLabel()}
                  size="small"
                  onClick={() => setShowContactUsModal(true)}
                />
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Flex column gap="16px">
            <HeaderSubBold>
              {`${getCategoryName(issueCategory, issues.length)} ` +
                (issues.length > 1 ? ` Issues (${issues.length})` : "Issue")}
            </HeaderSubBold>
            <Flex column gap="8px">
              {issues.map((issue, index) => (
                <QuickScanResultsViewDetailsIssue
                  key={`issue-${index}`}
                  issue={issue}
                />
              ))}
            </Flex>
          </Flex>
        )}
      </Box>
    );
  };

  return (
    <Flex
      column
      style={{
        width: isMobile ? "99%" : "70%",
      }}
      gap="16px"
    >
      <Flex w100 gap="16px" column={isMobile}>
        <Category
          issueCategory="general"
          issues={analysisResult.issues.filter(
            (issue) => issue.category === "general"
          )}
        />
        <Category
          issueCategory="header"
          issues={analysisResult.issues.filter(
            (issue) => issue.category === "header"
          )}
        />
      </Flex>
      <Flex w100 gap="16px" column={isMobile}>
        <Category
          issueCategory="cookie"
          issues={analysisResult.issues.filter(
            (issue) => issue.category === "cookie"
          )}
        />
        <Category
          issueCategory="ssl"
          issues={analysisResult.issues.filter(
            (issue) => issue.category === "ssl"
          )}
        />
      </Flex>
    </Flex>
  );
};
