import { useContext } from "react";
import useToastContext from "../../../hooks/toastHook";
import { IconButton } from "../../elements/button/icon/IconButton";
import { ThemeContext } from "styled-components";

type Props = {
  value?: string;
  color?: string;
};

export const CopyToClipboard = (props: Props) => {
  const { value, color } = props;
  const addToast = useToastContext();
  const theme = useContext(ThemeContext);

  const copyValueToClipboard = () =>
    value &&
    navigator.clipboard
      .writeText(value)
      .then(() =>
        addToast({
          message: "Token copied to clipboard",
          type: "success",
        })
      )
      .catch((err) =>
        addToast({
          message: `Failed to copy token: ${err}`,
          type: "error",
        })
      );

  return (
    <IconButton
      iconName="copy"
      color={color || theme.primary}
      size="small"
      onClick={copyValueToClipboard}
    />
  );
};
