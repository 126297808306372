import styled, { CSSProperties, ThemeContext } from "styled-components";
import {
  BodyRegular,
  LabelRegular,
  TypographyBodyRegular,
  TypographyBodySemibold,
} from "../../typography/Typography";
import "../buttonsAnimation.css";
import { Flex } from "../../../layouts/flex/Flex";
import { SeparatorVertical } from "../../separators/SeparatorVertical";
import { Ellipse } from "../../ellipse/Ellipse";
import { useContext } from "react";

type ButtonProps = {
  label: string;
  onClick: () => void;
  inProgress?: boolean;
  disabled?: boolean;
  iconName?: string;
  dataTestId?: string;
  automatic?: boolean;
  style?: CSSProperties;
  isActive?: boolean;
  selected?: boolean;
};

const SelectionTabButtonStyle = styled.button<ButtonProps>`
  ${(props) =>
    props.selected ? TypographyBodySemibold : TypographyBodyRegular}
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 12px;
  background-color: ${(props) =>
    props.selected ? props.theme.blue50 : "transparent"};
  color: ${(props) => props.theme.black800};
  border: 1px solid
    ${(props) => (props.selected ? props.theme.blue600 : props.theme.black500)};
  border-radius: 8px;
  &:hover:enabled {
    background-color: ${(props) => props.theme.blue50};
  }
  &:active:enabled {
    ${TypographyBodySemibold}
    border: 1px solid ${(props) => props.theme.blue600};
    background-color: ${(props) => props.theme.blue50};
  }
  &:disabled {
    ${TypographyBodySemibold}
    color: ${(props) => props.theme.black600};
    border: 1px solid ${(props) => props.theme.black500};
  }
  &:focus {
    outline: none;
  }
`;

export const SelectionTabButton = (props: ButtonProps) => {
  const {
    label,
    onClick,
    automatic = false,
    inProgress = false,
    disabled = false,
    isActive = false,
    selected = false,
    style,
  } = props;
  const theme = useContext(ThemeContext);
  return (
    <SelectionTabButtonStyle
      className={inProgress ? "loading-animation" : ""}
      onClick={onClick}
      disabled={disabled || inProgress}
      data-testid={props.dataTestId}
      label={label}
      style={style}
      selected={selected}
    >
      <BodyRegular className="text-truncate" title={label}>
        {label}
      </BodyRegular>

      {isActive && (
        <Flex gap="8px" align="center">
          {automatic && (
            <Flex gap="8px" align="center">
              <LabelRegular color={theme.black600}>Automatic</LabelRegular>
              <SeparatorVertical height="8px" />
            </Flex>
          )}

          <Ellipse size={8} color={disabled ? theme.black600 : theme.primary} />
        </Flex>
      )}
    </SelectionTabButtonStyle>
  );
};
