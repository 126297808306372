import { IconProps } from "./WaspIcon";

export function ChevronDownIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3375 9.71677L13.2727 14.7816C12.9348 15.1185 12.4772 15.3077 12.0001 15.3077C11.5229 15.3077 11.0653 15.1185 10.7275 14.7816L5.66261 9.71677C5.55244 9.60378 5.49121 9.45193 5.4922 9.29412C5.49319 9.13631 5.55631 8.98525 5.66789 8.87365C5.77948 8.76205 5.93053 8.69891 6.08834 8.6979C6.24615 8.69688 6.39801 8.75809 6.51101 8.86825L11.5759 13.9331C11.6884 14.0456 11.841 14.1088 12.0001 14.1088C12.1591 14.1088 12.3117 14.0456 12.4243 13.9331L17.4891 8.86813C17.5445 8.81126 17.6107 8.76596 17.6838 8.73487C17.7569 8.70379 17.8355 8.68752 17.9149 8.68702C17.9943 8.68653 18.0731 8.70181 18.1466 8.73198C18.22 8.76215 18.2868 8.80661 18.343 8.86278C18.3991 8.91895 18.4436 8.98571 18.4737 9.0592C18.5039 9.13268 18.5192 9.21142 18.5187 9.29086C18.5181 9.37029 18.5019 9.44883 18.4708 9.52192C18.4397 9.59501 18.3944 9.6612 18.3375 9.71665V9.71677Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
