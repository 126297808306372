import { SingleValue } from "react-select";
import { Dropdown, Option } from "../../elements/dropdowns/Dropdown";
import { OpUser, useApiSuperUsers } from "../../../hooks/queries/usersContext";
import { useEffect, useMemo, useState } from "react";

type Props = {
  onSelect: (opUser: OpUser | undefined) => void;
  placeholder?: string;
  valueByEmail?: string;
  isClearable?: boolean;
  disabled?: boolean;
  isError?: boolean;
  size?: "medium" | "large" | "small";
  queryStatus?: "error" | "idle" | "loading" | "success";
  variant?: "border" | "outline";
};
export const SuperUsersDropdown = (props: Props) => {
  const {
    onSelect,
    placeholder = "",
    valueByEmail,
    size,
    isClearable = false,
    queryStatus,
    disabled,
    isError,
    variant,
  } = props;
  const [searchWord, setSearchWord] = useState("");
  const { data: users } = useApiSuperUsers({ search: searchWord });

  const options: SingleValue<Option>[] = useMemo(
    () =>
      users?.map((u) => ({
        label: `${u.first_name} ${u.last_name}`,
        value: `${u.email}`,
      })) || [],
    [users]
  );

  const onChange = (opt: SingleValue<Option>) => {
    setSelectedUserOption(opt);
    const user = users?.find((u) => u.email === opt?.value);
    onSelect(user);
  };

  const getOptionByEmail = (email: string) =>
    options?.filter((opt) => opt?.value === email)[0];

  const [selectedUserOption, setSelectedUserOption] = useState<
    SingleValue<Option>
  >(valueByEmail ? getOptionByEmail(valueByEmail) : null);

  useEffect(() => {
    if (!!selectedUserOption || !options || !valueByEmail) return;
    const selectedUser = users?.find((u) => u.email === valueByEmail);
    if (selectedUser)
      setSelectedUserOption({
        value: selectedUser.email,
        label: `${selectedUser?.first_name} ${selectedUser?.last_name}`,
      });
  }, [selectedUserOption, options, users, valueByEmail, setSelectedUserOption]);

  return (
    <Dropdown
      size={size}
      variant={variant || "border"}
      placeholder={placeholder}
      value={selectedUserOption}
      options={(options as Option[]) || []}
      onChange={onChange}
      onInputChange={(newValue) => setSearchWord(newValue)}
      searchable
      clearable={isClearable}
      queryStatus={queryStatus}
      disabled={disabled}
      isError={isError}
      isMenuPositionFixed
    />
  );
};
