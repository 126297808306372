import styled from "styled-components";
import {
  BodyRegular,
  TypographyKPILarge,
} from "../../components/elements/typography/Typography";

export const TopBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  height: 100%;
  justify-content: space-between;
`;

export const TopBoxTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

type CounterProps = {
  isLaptopScreen?: boolean;
  isSmallScreen?: boolean;
};

export const StatusCounter = styled.div`
  background-color: ${(props) => props.theme.white100};
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.black500};
  transform: 0.4s;
  background-color: ${(props) => props.theme.bg1};
  border-color: ${(props) => props.theme.bg1};
  padding: 16px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: ${(props) => props.theme.blue100};
  }
`;

export const ActionItemCounter = styled.div<CounterProps>`
  display: flex;
  flex-direction: ${(props) => (props.isLaptopScreen ? "row" : "column")};
  gap: 8px;
  align-items: center;
  justify-content: ${(props) => (props.isLaptopScreen ? "start" : "center")};
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  transition: 0.2s;
  background: transparent;
`;

export const ActionItemKPI = styled.span`
  ${TypographyKPILarge}
  transition: 0.2s;
  cursor: pointer;
  color: ${(props) => props.theme.redPrimary};
`;

export const MenuItemStyle = styled(BodyRegular)`
  cursor: pointer;
  &:hover {
    font-weight: 600 !important;
  }
`;
