import React from "react";
import { IconProps } from "./WaspIcon";

export function SearchIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.617 17.7685L15.677 14.8285C16.5434 13.7851 17.0206 12.4732 17.0268 11.117C17.0268 9.9482 16.6802 8.80561 16.0308 7.83375C15.3815 6.8619 14.4585 6.10443 13.3786 5.65713C12.2987 5.20984 11.1105 5.0928 9.9641 5.32083C8.81772 5.54886 7.7647 6.11171 6.93821 6.93821C6.11171 7.7647 5.54886 8.81772 5.32083 9.9641C5.0928 11.1105 5.20984 12.2987 5.65713 13.3786C6.10443 14.4585 6.8619 15.3815 7.83375 16.0308C8.80561 16.6802 9.9482 17.0268 11.117 17.0268C12.4734 17.0208 13.7854 16.5438 14.829 15.6774L17.769 18.6174C17.8817 18.7291 18.034 18.7916 18.1927 18.7913C18.3514 18.7909 18.5035 18.7277 18.6157 18.6155C18.7279 18.5033 18.7911 18.3513 18.7914 18.1926C18.7917 18.0339 18.7292 17.8816 18.6175 17.7689L18.617 17.7685ZM6.40728 11.117C6.40728 10.1855 6.6835 9.27495 7.20102 8.50044C7.71853 7.72592 8.45409 7.12226 9.31469 6.76579C10.1753 6.40932 11.1223 6.31605 12.0359 6.49777C12.9495 6.6795 13.7887 7.12806 14.4473 7.78673C15.106 8.44541 15.5546 9.28461 15.7363 10.1982C15.918 11.1118 15.8248 12.0588 15.4683 12.9194C15.1118 13.78 14.5082 14.5155 13.7336 15.0331C12.9591 15.5506 12.0485 15.8268 11.117 15.8268C9.86837 15.8254 8.67126 15.3287 7.78831 14.4458C6.90537 13.5628 6.40871 12.3657 6.40728 11.117Z"
        fill={props.color || "currentColor"}
        fillOpacity="1"
      />
    </svg>
  );
}
