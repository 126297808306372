import { UserForm } from "./UserForm";
import {
  BodyRegular,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiAccounts } from "../../../hooks/queries/accountsContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import { OnboardingFooter } from "./OnboardingFooter";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  goToPrevStage: () => void;
};
const AddPeers = (props: Props) => {
  const { onSuccess, onClose, goToPrevStage } = props;

  const { data: me } = useApiMe();
  const { data: accounts } = useApiAccounts();

  return (
    <>
      <HeaderMain>Add peers to your workspace</HeaderMain>
      <BodyRegular>
        So you can all use WASP together and keep you organization safe
      </BodyRegular>
      {(!accounts || accounts.length <= 3) && <UserForm />}
      <Flex column gap="8px" style={{ height: "340px", overflowY: "scroll" }}>
        {me &&
          accounts
            ?.filter((a) => a.email !== me.email)
            .map((a) => <UserForm key={`peer-account-${a.id}`} account={a} />)}
      </Flex>
      <OnboardingFooter onClose={onClose}>
        <Flex gap="8px">
          <SecondaryButton label="Back" onClick={goToPrevStage} size="medium" />
          <MainButton label="Next" onClick={onSuccess} size="medium" />
        </Flex>
      </OnboardingFooter>
    </>
  );
};

export default AddPeers;
