import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../elements/button/icon/IconButton";
import { InputText } from "../../elements/input/textInput/InputText";
import { Flex } from "../../layouts/flex/Flex";

export const SearchBar = ({
  setSearchWord,
  onChange,
}: {
  onChange?: (value: string) => void;
  setSearchWord?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const theme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(false);
    setSearchWord && setSearchWord("");
    onChange && onChange("");
  };

  document.addEventListener(
    "keydown",
    (e) => e.key === "Escape" && handleClose()
  );

  return (
    <Flex
      data-testid="search-bar-container"
      justify="evenly"
      align="center"
      style={{
        border: `1px solid ${isOpen ? theme.primary : "transparent"}`,
        background: isOpen ? theme.bg2 : "transparent",
        borderRadius: "8px",
        height: "40px",
        width: isOpen ? "275px" : "30px",
        transition: "0.4s",
        overflow: "hidden",
      }}
    >
      <IconButton
        iconName="search"
        size="small"
        onClick={() => setIsOpen(true)}
        dataTestId="open-search-bar"
      />
      <span>
        {isOpen ? (
          <InputText
            onChange={(e) => {
              setSearchWord && setSearchWord(e.target.value);
              onChange && onChange(e.target.value);
            }}
            width={isOpen ? "210px" : "0px"}
            border="none"
            autoFocus
            dataTestId="search-bar"
          />
        ) : null}
      </span>
      {isOpen && (
        <IconButton
          iconName="cancel"
          size="small"
          onClick={() => handleClose()}
          dataTestId="close-search-bar"
        />
      )}
    </Flex>
  );
};
