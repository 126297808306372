import React from "react";
import { IconProps } from "./WaspIcon";

export function LogoutIcon(props: IconProps) {
  const size = props.size || 16;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5008 8.00001C13.5012 8.25139 13.4462 8.49977 13.3398 8.72751C13.2333 8.95525 13.078 9.15676 12.885 9.31773L11.1723 10.7498C11.0502 10.8519 10.8925 10.9013 10.7339 10.8871C10.5753 10.8729 10.4289 10.7964 10.3268 10.6742C10.2247 10.5521 10.1753 10.3944 10.1895 10.2358C10.2037 10.0772 10.2803 9.93079 10.4024 9.82869L11.8724 8.60001H5.41544C5.25631 8.60001 5.1037 8.5368 4.99118 8.42427C4.87865 8.31175 4.81544 8.15914 4.81544 8.00001C4.81544 7.84088 4.87865 7.68827 4.99118 7.57575C5.1037 7.46322 5.25631 7.40001 5.41544 7.40001H11.873L10.4024 6.17133C10.3419 6.12078 10.292 6.05881 10.2555 5.98896C10.2189 5.9191 10.1965 5.84274 10.1895 5.76423C10.1825 5.68571 10.191 5.60659 10.2145 5.53136C10.2381 5.45614 10.2762 5.38629 10.3268 5.32581C10.4289 5.20366 10.5753 5.12708 10.7339 5.1129C10.8124 5.10588 10.8915 5.11439 10.9668 5.13795C11.042 5.16151 11.1118 5.19966 11.1723 5.25021L12.885 6.68169C13.078 6.84282 13.2332 7.04445 13.3397 7.27228C13.4461 7.5001 13.5011 7.74855 13.5008 8.00001ZM7.81544 14.1155C6.19352 14.1155 4.63804 13.4711 3.49117 12.3243C2.3443 11.1774 1.7 9.62193 1.7 8.00001C1.7 6.3781 2.3443 4.82261 3.49117 3.67574C4.63804 2.52887 6.19352 1.88457 7.81544 1.88457C7.97457 1.88457 8.12718 1.82136 8.2397 1.70883C8.35223 1.59631 8.41544 1.4437 8.41544 1.28457C8.41544 1.12544 8.35223 0.972828 8.2397 0.860306C8.12718 0.747785 7.97457 0.68457 7.81544 0.68457C5.87527 0.68457 4.01455 1.4553 2.64264 2.82721C1.27073 4.19912 0.5 6.05984 0.5 8.00001C0.5 9.94019 1.27073 11.8009 2.64264 13.1728C4.01455 14.5447 5.87527 15.3155 7.81544 15.3155C7.97457 15.3155 8.12718 15.2522 8.2397 15.1397C8.35223 15.0272 8.41544 14.8746 8.41544 14.7155C8.41544 14.5563 8.35223 14.4037 8.2397 14.2912C8.12718 14.1787 7.97457 14.1155 7.81544 14.1155Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
