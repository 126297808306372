import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { useApiMe } from "../../../hooks/queries/meContext";
import {
  ProductParams,
  useApiCreateProduct,
  useApiUpdateProduct,
} from "../../../hooks/queries/productsContext";
import { Product } from "../../../types/Product";
import useToastContext from "../../../hooks/toastHook";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Mixpanel } from "../../../shared/mixpanel";
import { ProductForm } from "./ProductForm";
import { Account } from "../../../types/Account";
import { useApiAccounts } from "../../../hooks/queries/accountsContext";
import { deepEqualArrays } from "../../../shared/helper";

export interface ProductFormValues {
  name: string;
  description: string;
  accounts_allowed: Account[];
}

export interface ProductFormErrors {
  name?: string;
  description?: string;
  accounts_allowed?: string;
}

const AddEditProductForm = ({
  editedProduct,
  setDeletedProduct,
  onClose: closePane,
}: {
  editedProduct?: Product;
  setDeletedProduct: React.Dispatch<React.SetStateAction<Product | undefined>>;
  onClose: () => void;
}) => {
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();

  const { data: me } = useApiMe();

  const { data: allowedAccounts } = useApiAccounts(
    { products_allowed: editedProduct?.id },
    !editedProduct?.id
  );

  const { mutate: updateProduct } = useApiUpdateProduct({
    id: parseInt(`${editedProduct}`),
  });

  const { mutate: createProduct } = useApiCreateProduct();

  const [isMounted, setIsMounted] = useState(false);
  const [formErrors, setFormErrors] = useState<ProductFormErrors>({});
  const [formValues, setFormValues] = useState<ProductFormValues>({
    name: editedProduct?.name || "",
    description: editedProduct?.description || "",
    accounts_allowed: allowedAccounts || [],
  });

  useEffect(() => {
    // Add allowed accounts to form values
    if (
      !isMounted &&
      !!allowedAccounts?.length &&
      !formValues.accounts_allowed?.length
    ) {
      setFormValues((prev) => ({ ...prev, accounts_allowed: allowedAccounts }));
      setIsMounted(true);
    }
    if (formValues.accounts_allowed?.length) setIsMounted(true);
  }, [allowedAccounts, formValues, isMounted]);

  const apiCall = async () => {
    let data: ProductParams = {
      customer: me?.customer.id,
      name: formValues.name,
      description: formValues.description,
    };
    const editedAllowedAccountsIds = formValues.accounts_allowed.map(
      (a) => a.id
    );
    if (
      !deepEqualArrays(
        allowedAccounts?.map((a) => a.id) || [],
        editedAllowedAccountsIds
      )
    )
      data.accounts_allowed = editedAllowedAccountsIds;

    if (!data) return;
    const isUpdate = !!editedProduct;
    const callbacks = {
      onSuccessCallback: () => {
        addToast({
          message: `${editedProduct?.name || "Product"} has been successfully ${
            isUpdate ? "updated" : "created"
          }`,
          type: "success",
        });
        closePane();
        Mixpanel.track(`Product ${!!editedProduct ? "updated" : "created"}`);
      },
      onErrorCallback: (error: any) => {
        addToast({
          message: `Error: ${error}`,
          type: "error",
        });
      },
    };
    if (isUpdate)
      updateProduct({
        productId: editedProduct.id,
        productData: data,
        ...callbacks,
      });
    else createProduct({ ...data, ...callbacks });
  };

  const validate = (values: ProductFormValues): ProductFormErrors => {
    const errors: ProductFormErrors = {};
    if (!values.name) errors.name = "Product name is required!";
    return errors;
  };

  const handleSubmit = () => {
    let errors = validate(formValues);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }
    apiCall();
  };

  const FormHeader = () => (
    <div
      style={{
        position: "absolute",
        top: "0",
        width: "100%",
        padding: "24px",
        zIndex: "160",
        background: "inherit",
      }}
    >
      <Flex w100 align="center">
        {!!editedProduct ? (
          <div data-testid="edit-product-form-header">
            <LabelRegular>Edit Product</LabelRegular>
            <HeaderSecondary>{editedProduct?.name}</HeaderSecondary>
          </div>
        ) : (
          <div>
            <LabelRegular>Add Product</LabelRegular>
            <HeaderSecondary>
              {formValues?.name || "New Product"}
            </HeaderSecondary>
          </div>
        )}
        <div className="ms-auto">
          <MainButton label="Save" onClick={handleSubmit} size="medium" />
        </div>
      </Flex>
      <SeparatorHorizontal style={{ marginTop: "24px" }} />
    </div>
  );

  const FormFooter = () =>
    !!editedProduct ? (
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          width: "100%",
          padding: "24px",
          paddingTop: "0",
          zIndex: "160",
          background: "inherit",
        }}
      >
        <SeparatorHorizontal />
        <Flex w100 align="center">
          <div className="ms-auto" style={{ marginTop: "24px" }}>
            <LinkButton
              dataTestId="delete-product-btn"
              label="Delete Product"
              iconColor={theme.redPrimary}
              iconName="remove"
              onClick={() => setDeletedProduct(editedProduct)}
            />
          </div>
        </Flex>
      </div>
    ) : null;

  return (
    <RightPane onClose={closePane}>
      <FormHeader />
      <div
        style={{
          marginTop: "110px",
          padding: "24px",
          paddingBottom: "210px",
          width: "100%",
          overflowY: "auto",
          height: "100%",
          position: "relative",
        }}
      >
        <ProductForm
          showAllowedAccounts
          formValues={formValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          setFormValues={setFormValues}
        />
      </div>
      <FormFooter />
    </RightPane>
  );
};

export default AddEditProductForm;
