import React from "react";
import { IconProps } from "./WaspIcon";

export function MainIcon(props: IconProps) {
  const size = props.size || 32;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 17.3333H13.3333C13.687 17.3333 14.0261 17.1929 14.2761 16.9428C14.5262 16.6928 14.6667 16.3536 14.6667 16V5.33333C14.6667 4.97971 14.5262 4.64057 14.2761 4.39052C14.0261 4.14048 13.687 4 13.3333 4H5.33333C4.97971 4 4.64057 4.14048 4.39052 4.39052C4.14048 4.64057 4 4.97971 4 5.33333V16C4 16.3536 4.14048 16.6928 4.39052 16.9428C4.64057 17.1929 4.97971 17.3333 5.33333 17.3333ZM4 26.6667C4 27.0203 4.14048 27.3594 4.39052 27.6095C4.64057 27.8595 4.97971 28 5.33333 28H13.3333C13.687 28 14.0261 27.8595 14.2761 27.6095C14.5262 27.3594 14.6667 27.0203 14.6667 26.6667V21.3333C14.6667 20.9797 14.5262 20.6406 14.2761 20.3905C14.0261 20.1405 13.687 20 13.3333 20H5.33333C4.97971 20 4.64057 20.1405 4.39052 20.3905C4.14048 20.6406 4 20.9797 4 21.3333V26.6667ZM17.3333 26.6667C17.3333 27.0203 17.4738 27.3594 17.7239 27.6095C17.9739 27.8595 18.313 28 18.6667 28H26.6667C27.0203 28 27.3594 27.8595 27.6095 27.6095C27.8595 27.3594 28 27.0203 28 26.6667V17.3333C28 16.9797 27.8595 16.6406 27.6095 16.3905C27.3594 16.1405 27.0203 16 26.6667 16H18.6667C18.313 16 17.9739 16.1405 17.7239 16.3905C17.4738 16.6406 17.3333 16.9797 17.3333 17.3333V26.6667ZM18.6667 13.3333H26.6667C27.0203 13.3333 27.3594 13.1929 27.6095 12.9428C27.8595 12.6928 28 12.3536 28 12V5.33333C28 4.97971 27.8595 4.64057 27.6095 4.39052C27.3594 4.14048 27.0203 4 26.6667 4H18.6667C18.313 4 17.9739 4.14048 17.7239 4.39052C17.4738 4.64057 17.3333 4.97971 17.3333 5.33333V12C17.3333 12.3536 17.4738 12.6928 17.7239 12.9428C17.9739 13.1929 18.313 13.3333 18.6667 13.3333Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
