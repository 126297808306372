import { useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Icon } from "../icon/Icon";

type Props = {
  children?: JSX.Element[] | JSX.Element;
  onClose: () => void;
  width?: string;
  noEffect?: boolean;
  isStaticPane?: boolean;
  cancelIcon?: string;
  iconColor?: any;
};

type BackdropProps = {
  blurEnabled?: boolean;
  transparent?: boolean;
};
export const Backdrop = styled.div<BackdropProps>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 120;
  background: ${({ transparent = false, theme }) =>
    transparent ? "transparent" : theme.backdrop};
  ${({ blurEnabled = true }) =>
    blurEnabled !== false && "backdrop-filter: blur(2.5px);"}
`;

const RightPaneContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 150;
  opacity: 1;
  width: 0;
  background: ${(props) => props.theme.bg2};
  transition: all 0.4s;
`;

const CloseIcon = styled.div`
  top: 30px;
  left: -50px;
  order: 110;
  color: ${(props) => props.theme.bg1};
  cursor: pointer;
  width: fit-content;
`;

export const RightPane = (props: Props) => {
  const theme = useContext(ThemeContext);
  const {
    onClose,
    width,
    noEffect = false,
    isStaticPane = false,
    cancelIcon = "cancel",
    iconColor = theme.white100,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showChildren, setShowChildren] = useState(false);
  // Delay opening / closing in 400ms to show animation
  setTimeout(() => setIsOpen(true), 400);

  const onCloseHandler = () => {
    setIsOpen(false);
    noEffect ? onClose() : setTimeout(() => onClose(), 400);
  };

  // Close pane on escape button
  const handleClose = (e: any) => e.key === "Escape" && onCloseHandler();
  useEffect(() => {
    window.addEventListener("keydown", handleClose);
    return () => window.removeEventListener("keydown", handleClose);
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOpen) {
      timer = setTimeout(() => {
        setShowChildren(true);
      }, 200); // Delay of 0.2 second
    } else {
      setShowChildren(false); // Immediately hide when isOpen is false
    }

    // Cleanup timer when isOpen changes or component unmounts
    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <>
      {!isStaticPane && (
        <Backdrop
          onClick={onClose}
          style={{
            opacity: isOpen ? 1 : 0,
            transition: "0.2s ease-out",
          }}
        ></Backdrop>
      )}
      <RightPaneContainer
        style={{
          width: isOpen ? width || "500px" : "0px",
          zIndex: isStaticPane ? 0 : 130,
          opacity: isOpen ? 1 : 0,
          transition: "0.2s ease-out",
          position: isStaticPane ? "static" : "fixed",
        }}
      >
        <CloseIcon
          onClick={onCloseHandler}
          data-testid={`close-${isStaticPane ? "static" : "right"}-pane-btn`}
          style={{ position: isStaticPane ? "static" : "absolute" }}
        >
          <Icon name={cancelIcon} size={42} color={iconColor} />
        </CloseIcon>
        {showChildren && props.children}
      </RightPaneContainer>
    </>
  );
};
