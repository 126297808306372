import React, { useContext, useMemo, useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { LineChart } from "../../../components/composed/charts/LineChart";
import { RangeDatePicker } from "../../../components/elements/datetimePicker/RangeDatePicker";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  objectToOption,
  objectsToOptions,
  customersPriorityTierOptions,
} from "../../../shared/formUtils";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import { ThemeContext } from "styled-components";
import { useApiAsmAnalytics } from "../../../hooks/queries/findingContext";
import moment from "moment";
import { getRelevantCustomers, getRelevantCustomersIds } from "../utils";
import { PriorityTier } from "../../../types/Customer";

export const AsmFindingsOvertime = () => {
  const theme = useContext(ThemeContext);
  const { data: customers } = useApiCustomers();
  const { data: asmAnalytics } = useApiAsmAnalytics();
  const [selectedCustomer, setCustomer] = useState<number | string | undefined>(
    "all"
  );
  const [selectedTier, setTier] = useState<PriorityTier | "all">("all");
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(1, "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  const getDatesLabels = (startDate: string, endDate: string): string[] => {
    if (!asmAnalytics) return [];

    const isDateInValidRange = (date?: string): boolean =>
      !!date &&
      moment(date).isAfter(startDate) &&
      moment(date).isBefore(endDate) &&
      !dates.includes(date);

    const dates: string[] = [];

    const addDatesFromCategory = (category: any) => {
      for (const entry of category) {
        if (
          (selectedCustomer === "all" ||
            entry.customer_id === selectedCustomer) &&
          getRelevantCustomersIds(customers || [], selectedTier).includes(
            entry.customer_id
          )
        ) {
          for (const date of entry.dates) {
            if (isDateInValidRange(date.date)) {
              dates.push(date.date);
            }
          }
        }
      }
    };

    addDatesFromCategory(asmAnalytics.overtime_data.created);
    addDatesFromCategory(asmAnalytics.overtime_data.valid);
    addDatesFromCategory(asmAnalytics.overtime_data.dismissed);

    return dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  };
  const calcDataset = (
    dataCategory: "created" | "valid" | "dismissed"
  ): number[] => {
    const dataset: number[] = [];
    if (!asmAnalytics) return dataset;

    const dates = getDatesLabels(startDate, endDate);
    for (const date of dates) {
      let dateCount = 0;
      for (const entry of asmAnalytics.overtime_data[dataCategory]) {
        if (
          (selectedCustomer === "all" ||
            entry.customer_id === selectedCustomer) &&
          getRelevantCustomersIds(customers || [], selectedTier).includes(
            entry.customer_id
          )
        ) {
          const foundDate = entry.dates.find((d) => d.date === date);
          if (foundDate) {
            dateCount += foundDate.count;
          }
        }
      }
      dataset.push(dateCount);
    }
    return dataset;
  };

  const calcCreatedDataset = (): number[] => calcDataset("created");
  const calcValidDataset = (): number[] => calcDataset("valid");
  const calcDismissedDataset = (): number[] => calcDataset("dismissed");

  /* eslint-disable */
  const datesLabels = useMemo(
    () => getDatesLabels(startDate, endDate),
    [startDate, endDate, asmAnalytics, selectedCustomer, selectedTier]
  );
  const createdDataset = useMemo(
    () => calcCreatedDataset(),
    [startDate, endDate, asmAnalytics, selectedCustomer, selectedTier]
  );
  const validDataset = useMemo(
    () => calcValidDataset(),
    [startDate, endDate, asmAnalytics, selectedCustomer, selectedTier]
  );
  const dismissedDataset = useMemo(
    () => calcDismissedDataset(),
    [startDate, endDate, asmAnalytics, selectedCustomer, selectedTier]
  );
  /* eslint-enable */

  return (
    <Box>
      <Flex gap="8px" column>
        <Flex justify="center" w100>
          <HeaderSecondary>
            Created vs. Valid vs. Dismissed ASM Findings
          </HeaderSecondary>
        </Flex>
        <Flex justify="between" align="center">
          <Flex gap="8px">
            <Dropdown
              searchable
              placeholder="Select customer"
              onChange={(opt) => {
                if (opt?.value) {
                  setCustomer(opt.value);
                  setTier("all");
                }
              }}
              value={
                selectedCustomer === "all"
                  ? { label: "All customers", value: "all" }
                  : customers?.filter((c) => c.id === selectedCustomer)[0]
                    ? objectToOption(
                        customers?.filter((c) => c.id === selectedCustomer)[0]
                      )
                    : null
              }
              options={
                customers
                  ? [
                      { label: "All customers", value: "all" },
                      ...objectsToOptions(getRelevantCustomers(customers)),
                    ]
                  : []
              }
            />
            <Dropdown
              searchable
              placeholder="Select tier"
              onChange={(opt) => {
                if (opt?.value) {
                  setCustomer("all");
                  setTier(opt.value as PriorityTier);
                }
              }}
              value={
                customersPriorityTierOptions.filter(
                  (t) => t.value === selectedTier
                )[0]
              }
              options={customersPriorityTierOptions}
            />
          </Flex>
          <RangeDatePicker
            start={startDate}
            end={endDate}
            onChangeStart={(e) => {
              if (e.target.value) setStartDate(e.target.value);
            }}
            onChangeEnd={(e) => {
              if (e.target.value) setEndDate(e.target.value);
            }}
          />
        </Flex>
        <LineChart
          datasets={[
            {
              label: "Created",
              data: createdDataset,
              borderColor: theme.primary,
            },
            {
              label: "Valid",
              data: validDataset,
              borderColor: theme.greenPrimary,
            },
            {
              label: "Dismissed",
              data: dismissedDataset,
              borderColor: theme.redPrimary,
            },
          ]}
          labels={datesLabels}
          height={500}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            tension: 0.3,
            plugins: {
              legend: {
                display: true,
              },
              title: { display: false },
              tooltip: {
                backgroundColor: theme.chartsTooltip,
                bodyColor: theme.textBody,
                titleColor: theme.textHeader,
                usePointStyle: true,
                cornerRadius: 0,
                boxHeight: 7,
                boxPadding: 12,
                callbacks: {
                  label: (context: any) =>
                    `${context.dataset.label}: ${context.parsed.y}`,
                },
              },
            },
          }}
        />
      </Flex>
    </Box>
  );
};
