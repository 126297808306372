import React, { useContext } from "react";
import { ThirdPartyConnections } from "../../../types/Asset";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  HeaderSub,
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { Badge } from "../../../components/elements/badge/Badge";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { Icon } from "../../../components/elements/icon/Icon";

type Props = {
  domain: string;
  connections: ThirdPartyConnections[];
};

export const AssetDomainGroupConnections = ({ domain, connections }: Props) => {
  const theme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = React.useState(false);

  const getUniqueValues = (
    connections: ThirdPartyConnections[],
    field: keyof ThirdPartyConnections
  ) => [
    ...new Set(
      connections
        .filter((connection) => connection[field])
        .map((connection) => connection[field])
        .flat()
    ),
  ];

  const getMethodColor = (method: string) => {
    switch (method) {
      case "GET":
        return theme.primary;
      case "POST":
        return theme.green800;
      case "PUT":
        return theme.yellow800;
      case "DELETE":
        return theme.red800;
      case "PATCH":
        return theme.yellow800;
      default:
        return theme.primary;
    }
  };

  const getMethodBackgroundColor = (method: string) => {
    switch (method) {
      case "GET":
        return theme.blue100;
      case "POST":
        return theme.green50;
      case "PUT":
        return theme.yellow50;
      case "DELETE":
        return theme.red50;
      case "PATCH":
        return theme.yellow50;
      default:
        return theme.blue100;
    }
  };

  return (
    <Flex column gap="4px">
      <HeaderSub
        color={theme.black700}
      >{`${domain}${connections[0]?.tracker_corp ? ` - ${connections[0]?.tracker_corp}` : ""}`}</HeaderSub>
      <Box className="d-flex flex-column gap-8">
        <Flex column gap="8px">
          {getUniqueValues(connections, "tracker_categories").length > 0 && (
            <Flex gap="8px" align="center">
              <LabelBold style={{ width: "95px" }}>Categories:</LabelBold>
              <Flex gap="4px" flexWrap>
                {getUniqueValues(connections, "tracker_categories").map(
                  (category) => (
                    <Badge key={`${domain}-${category}`}>{category}</Badge>
                  )
                )}
              </Flex>
            </Flex>
          )}
          {getUniqueValues(connections, "mime_type").length > 0 && (
            <Flex gap="8px" align="center">
              <LabelBold style={{ width: "95px" }}>Content Types:</LabelBold>
              <Flex gap="4px" flexWrap>
                {getUniqueValues(connections, "mime_type").map((mime_type) => (
                  <Badge key={`${domain}-${mime_type}`}>{mime_type}</Badge>
                ))}
              </Flex>
            </Flex>
          )}
          <Flex align="center" gap="8px">
            <LabelBold style={{ width: "95px" }}>Connections:</LabelBold>
            <LabelRegular>{connections.length}</LabelRegular>
            <IconButton
              iconName={isOpen ? "chevronUp" : "chevronDown"}
              onClick={() => setIsOpen(!isOpen)}
              size="small"
            />
          </Flex>
          {isOpen && (
            <Flex column gap="8px">
              {connections.map((connection) => (
                <Flex
                  key={`connection-url-${connection.url}`}
                  align="center"
                  gap="8px"
                >
                  <Tooltip
                    content={
                      connection.connection_type === "link"
                        ? "Link inside the website page"
                        : "Request made by a website"
                    }
                  >
                    <Flex
                      align="center"
                      justify="center"
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        backgroundColor: theme.lightBlue50,
                        color: theme.black900,
                      }}
                    >
                      <Icon
                        name={
                          connection.connection_type === "link"
                            ? "share"
                            : "send"
                        }
                        size="16px"
                      />
                    </Flex>
                  </Tooltip>
                  {!!connection.method && (
                    <Badge
                      color={getMethodColor(connection.method)}
                      backgroundColor={getMethodBackgroundColor(
                        connection.method
                      )}
                    >
                      {connection.method}
                    </Badge>
                  )}
                  <LabelRegular className="text-truncate">
                    {connection.url}
                  </LabelRegular>
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
