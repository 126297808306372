import { IconProps } from "./WaspIcon";

export function FilterIcon(props: IconProps) {
  const size = props.size || 20;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7688 4.76398C19.6191 4.46719 19.3897 4.21794 19.1063 4.04415C18.823 3.87036 18.4968 3.77889 18.1644 3.77998H5.8356C5.50363 3.78004 5.17814 3.87191 4.89513 4.04543C4.61211 4.21894 4.38259 4.46734 4.23195 4.76316C4.08131 5.05899 4.01541 5.39071 4.04153 5.72165C4.06766 6.05259 4.1848 6.36986 4.38 6.63838L9.5724 13.7772V17.9772C9.5727 18.3492 9.68824 18.7119 9.90313 19.0155C10.118 19.3192 10.4217 19.5488 10.7724 19.6728L12.0288 20.1216C12.3002 20.2175 12.5906 20.247 12.8757 20.2075C13.1609 20.168 13.4323 20.0607 13.6674 19.8946C13.9025 19.7285 14.0944 19.5085 14.2268 19.253C14.3593 18.9974 14.4286 18.7138 14.4288 18.426V13.7772L19.62 6.63838C19.8162 6.37047 19.934 6.05329 19.9603 5.72228C19.9865 5.39127 19.9203 5.05948 19.7688 4.76398ZM18.6504 5.93278L13.3428 13.2288C13.2664 13.3307 13.2243 13.4542 13.2228 13.5816V18.42C13.2228 18.5159 13.1998 18.6104 13.1557 18.6956C13.1117 18.7808 13.0479 18.8541 12.9696 18.9096C12.891 18.9653 12.8001 19.0012 12.7046 19.0144C12.6092 19.0276 12.512 19.0175 12.4212 18.9852L11.166 18.5364C11.0494 18.4948 10.9485 18.4181 10.8772 18.3169C10.8059 18.2158 10.7676 18.095 10.7676 17.9712V13.5816C10.7661 13.4542 10.724 13.3307 10.6476 13.2288L5.3496 5.93158C5.28483 5.84202 5.24606 5.73631 5.23756 5.62611C5.22907 5.51591 5.25117 5.40551 5.30144 5.30708C5.35171 5.20865 5.42819 5.12601 5.52245 5.06829C5.61671 5.01058 5.72508 4.98001 5.8356 4.97998H18.1644C18.2746 4.98095 18.3824 5.01226 18.476 5.07048C18.5696 5.1287 18.6454 5.21157 18.6949 5.31001C18.7445 5.40844 18.7661 5.51863 18.7571 5.62849C18.7482 5.73835 18.7092 5.84363 18.6444 5.93278H18.6504Z"
        fill={props.color || "currentColor"}
        fillOpacity="1"
      />
    </svg>
  );
}
