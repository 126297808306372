import { Flex } from "../../components/layouts/flex/Flex";
import { LabelRegular } from "../../components/elements/typography/Typography";

export const NoDataAvailable = () => {
  return (
    <Flex
      justify="center"
      align="center"
      className="w-100 h-100"
      style={{ position: "absolute", top: 0 }}
    >
      <LabelRegular>No data available</LabelRegular>
    </Flex>
  );
};
