import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../../components/elements/badge/Badge";
import { Box } from "../../../components/elements/box/Box";
import { getDateTime } from "../../../shared/helper";
import { Finding } from "../../../types/Finding";
import {
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Icon } from "../../../components/elements/icon/Icon";
import { Project } from "../../../types/Project";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { Assignee } from "../../../components/composed/assignee/Assignee";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  getFindingInsight,
  getRiskColor,
} from "../../../shared/findingsHelper";
import { Flex } from "../../../components/layouts/flex/Flex";
import { StatusLegend } from "../../../components/composed/status/StatusLegend";
import { actionUI, FindingLog } from "../../../types/FindingLog";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../../shared/consts";

type Props = {
  finding: Finding;
  project: Project | undefined;
  hasUnreadComments: boolean;
  onSelectedFinding: (finding: Finding) => void;
  onOpenProjectPane: (project: Project) => void;
  isSmall?: boolean;
  findingLog?: FindingLog;
  isFetchingLogs?: boolean;
};

export const FindingCard = (props: Props) => {
  const {
    finding,
    project,
    hasUnreadComments,
    onSelectedFinding,
    onOpenProjectPane,
    isSmall,
    findingLog,
    isFetchingLogs,
  } = props;

  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  function getActionText(): string {
    if (!findingLog) return "";
    return findingLog.action === "FINDING_UPDATED"
      ? `${findingLog.field} ${actionUI[findingLog.action].text}`
      : actionUI[findingLog.action].text;
  }

  return (
    <Box
      className="d-flex"
      style={{
        padding: "0px",
        width: "calc(100% - 8px)",
        maxWidth: isMobile ? "100%" : isSmall ? "257px" : "calc(100% - 8px)",
        height: "160px",
        minHeight: "160px",
        maxHeight: "160px",
        overflow: "hidden",
        borderRadius: "8px",
        margin: "0",
      }}
    >
      <div // Vertical Status Color Line
        style={{
          width: "4px",
          height: "inherit",
          background: theme[getRiskColor(finding?.overall_risk || 0)],
        }}
      ></div>
      <Flex
        gap="12px"
        column
        padding="16px"
        justify="between"
        style={{
          width: "calc(100% - 4px)",
        }}
      >
        <Flex column gap="8px" w100>
          <Flex justify="between" align="center" w100>
            <LabelRegular
              style={{
                cursor: "pointer",
              }}
              color={theme.black600}
              onClick={() => project && onOpenProjectPane(project)}
              data-testid={`finding-item-${finding.id}-project`}
              className="text-truncate w-50"
              title={project?.name || "No Project"}
            >
              {project?.name || "No Project"}
            </LabelRegular>
            {isFetchingLogs ? (
              <SkeletonLoading
                width="120px"
                height="26px"
                style={{
                  borderRadius: "24px",
                }}
              />
            ) : !!findingLog ? (
              <Badge
                className="text-truncate text-capitalize"
                title={getActionText()}
                style={{
                  maxWidth: "50%",
                }}
                color={theme[actionUI[findingLog.action].color]}
                backgroundColor={theme[actionUI[findingLog.action].background]}
              >
                {getActionText()}
              </Badge>
            ) : (
              <></>
            )}
          </Flex>
          <Tooltip content={finding.title}>
            <HeaderSubBold
              className="pointer text-truncate"
              onClick={() => onSelectedFinding(finding)}
              style={{
                width: `calc(100% - 10px)`,
              }}
            >
              {finding.title}
            </HeaderSubBold>
          </Tooltip>
        </Flex>

        <Flex
          gap="8px"
          style={{
            color: theme.black600,
          }}
        >
          <LabelRegular className="text-truncate">
            {getDateTime(finding.date_found || finding.created_at)}
          </LabelRegular>
          <SeparatorVertical
            height="18px"
            style={{
              color: theme.black600,
            }}
          />
          <LabelRegular className="text-truncate">
            {getFindingInsight(finding)}
          </LabelRegular>
          {finding.is_automated && (
            <Flex align="center" gap="8px">
              <SeparatorVertical
                height="18px"
                style={{
                  color: theme.black600,
                }}
              />
              <Icon name={"wasp"} color={theme.yellowPrimary} size={16} />
              {!isSmall && <LabelRegular>Automated</LabelRegular>}
            </Flex>
          )}
        </Flex>
        <Flex align="center" gap="8px" className="text-truncate">
          <Assignee
            imageSrc={finding.customer_jira_assignee?.avatar_url}
            name={finding.customer_jira_assignee?.display_name}
            hideName={isSmall}
            fontSize="12px"
          />
          <SeparatorVertical height="24px" />
          <StatusLegend status={finding.status} isSmall={isSmall} />
          <SeparatorVertical height="24px" />

          <LabelRegular
            className="d-flex align-items-center gap-4 pointer"
            onClick={() => onSelectedFinding(finding)}
          >
            <Icon name={"comment"} color={theme.primary} size={24} />
            <span>{finding.comments_count || 0}</span>
            {hasUnreadComments && (
              <span
                data-testid="unread-comments"
                style={{
                  background: theme.redPrimary,
                  width: "4px",
                  height: "4px",
                  borderRadius: "50%",
                }}
              />
            )}
          </LabelRegular>
        </Flex>
      </Flex>
    </Box>
  );
};
