import { getAssetGraphIcon } from "../sigmaGraph/utils";
import { SEVERITIES } from "../../../../shared/consts";

export const formatDataForReGraphIPs = (data: any): Record<string, any> => {
  var items: Record<string, any> = {};

  var IPs: any = [];
  data.assets.forEach((a: any) => {
    if (a.properties.ip) IPs.push(...a.properties.ip);
  });
  const uniqueIPs = new Set(IPs);

  uniqueIPs.forEach((u) => {
    items[`IP-${u}`] = {
      label: {
        bold: true,
        color: "blue",
        position: "s",
        text: u,
      },
      size: 4,
      image: "/icons/asset-server.svg",
    };
  });

  data.assets.forEach((a: any) => {
    items[`asset-${a.id}`] = {
      label: {
        bold: true,
        color: "black",
        position: "s",
        text: a.name,
      },
      data: { level: 1, ...a },
      size: 2,
      image: getAssetGraphIcon(a),
    };
    items[`link-${a.id}-${a.parent_asset}`] = {
      id1: `asset-${a.parent_asset}`,
      id2: `asset-${a.id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });
  data.findings.forEach((f: any) => {
    items[`finding-${f.id}`] = {
      color: "blue",
      data: { level: 2, ...f },
      image: `/icons/finding-${SEVERITIES[f.overall_risk].toLowerCase()}.svg`,
    };
  });
  data.relations.forEach((r: any, index: number) => {
    items[`link-${index}`] = {
      id1: `finding-${r.finding_id}`,
      id2: `asset-${r.asset_id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });

  // Add links between IPs and assets
  data.assets.forEach((a: any) => {
    if (!a.properties.ip) return;

    a.properties.ip.forEach((ip: any) => {
      items[`link-${a.id}-${ip}`] = {
        id1: `asset-${a.id}`,
        id2: `IP-${ip}`,
        flow: {
          velocity: 1,
          color: "#f15d5b",
        },
      };
    });
  });

  return items;
};

export const formatDataForReGraphTechs = (data: any): any => {
  const copy_data = JSON.parse(JSON.stringify(data));
  var items: any = {};

  var techs: any = [];
  copy_data.assets.forEach((a: any) => {
    if (a.properties.technologies) techs.push(...a.properties.technologies);
  });
  const uniqueSetTechs = new Set(techs);

  uniqueSetTechs.forEach((u) => {
    items[`tech-${u}`] = {
      label: {
        bold: true,
        color: "blue",
        position: "s",
        text: u,
      },
      size: 4,
      image: "/icons/asset-server.svg",
    };
  });

  copy_data.assets.forEach((a: any) => {
    items[`asset-${a.id}`] = {
      label: {
        bold: true,
        color: "black",
        position: "s",
        text: a.name,
      },
      data: { level: 1, ...a },
      size: 2,
      image: getAssetGraphIcon(a),
    };
    items[`link-${a.id}-${a.parent_asset}`] = {
      id1: `asset-${a.parent_asset}`,
      id2: `asset-${a.id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };

    if (a.properties.technologies) {
      a.properties.technologies.forEach((t: any) => {
        items[`link-${a.id}-${t}`] = {
          id1: `asset-${a.id}`,
          id2: `tech-${t}`,
          flow: {
            velocity: 1,
            color: "#f15d5b",
          },
        };
      });
    }
  });
  copy_data.findings.forEach((f: any) => {
    items[`finding-${f.id}`] = {
      color: "blue",
      data: { level: 2, ...f },
      image: `/icons/finding-${SEVERITIES[f.overall_risk].toLowerCase()}.svg`,
    };
  });
  copy_data.relations.forEach((r: any, index: number) => {
    items[`link-${index}`] = {
      id1: `finding-${r.finding_id}`,
      id2: `asset-${r.asset_id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });
  return items;
};

export const formatDataForReGraphDynamic = (data: any): any => {
  const copy_data = JSON.parse(JSON.stringify(data));
  var items: any = {};

  copy_data.assets.forEach((a: any) => {
    if (!a["environment"]) a["environment"] = "Production";

    if (a.risk_score >= 0 && a.risk_score <= 25) a.risk_score = "0-25";
    if (a.risk_score >= 26 && a.risk_score <= 50) a.risk_score = "26-50";
    if (a.risk_score >= 51 && a.risk_score <= 75) a.risk_score = "51-75";
    if (a.risk_score >= 76 && a.risk_score <= 100) a.risk_score = "76-100";

    if (a.coverage_score >= 0 && a.coverage_score <= 25)
      a.coverage_score = "0-25";
    if (a.coverage_score >= 26 && a.coverage_score <= 50)
      a.coverage_score = "26-50";
    if (a.coverage_score >= 51 && a.coverage_score <= 75)
      a.coverage_score = "51-75";
    if (a.coverage_score >= 76 && a.coverage_score <= 100)
      a.coverage_score = "76-100";
    items[`asset-${a.id}`] = {
      label: {
        bold: true,
        color: "black",
        position: "s",
        text: a.name,
      },
      data: { level: 1, ...a },
      image: getAssetGraphIcon(a),
    };
    items[`link-${a.id}-${a.parent_asset}`] = {
      id1: `asset-${a.parent_asset}`,
      id2: `asset-${a.id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });
  copy_data.findings.forEach((f: any) => {
    items[`finding-${f.id}`] = {
      color: "blue",
      data: { level: 2, ...f },
      image: `/icons/finding-${SEVERITIES[f.overall_risk].toLowerCase()}.svg`,
    };
  });
  copy_data.relations.forEach((r: any, index: number) => {
    items[`link-${index}`] = {
      id1: `finding-${r.finding_id}`,
      id2: `asset-${r.asset_id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });
  return items;
};

export const formatDataForReGraphDynamicSeqMany = (data: any): any => {
  console.log(data);
  const copy_data = JSON.parse(JSON.stringify(data));
  var items: any = {};

  copy_data.assets.forEach((a: any) => {
    if (!a["environment"]) a["environment"] = `Production (${a.parent_asset})`;
    else a["environment"] = `${a["environment"]} (${a.parent_asset})`;

    a.product_id = `${a.product_id} (${a.parent_asset})`;

    if (a.risk_score >= 0 && a.risk_score <= 25)
      a.risk_score = `0-25 (${a.parent_asset})`;
    if (a.risk_score >= 26 && a.risk_score <= 50)
      a.risk_score = `26-50 (${a.parent_asset})`;
    if (a.risk_score >= 51 && a.risk_score <= 75)
      a.risk_score = `51-75 (${a.parent_asset})`;
    if (a.risk_score >= 76 && a.risk_score <= 100)
      a.risk_score = `76-100 (${a.parent_asset})`;

    if (a.coverage_score >= 0 && a.coverage_score <= 25)
      a.coverage_score = `0-25 (${a.parent_asset})`;
    if (a.coverage_score >= 26 && a.coverage_score <= 50)
      a.coverage_score = `26-50 (${a.parent_asset})`;
    if (a.coverage_score >= 51 && a.coverage_score <= 75)
      a.coverage_score = `51-75 (${a.parent_asset})`;
    if (a.coverage_score >= 76 && a.coverage_score <= 100)
      a.coverage_score = `76-100 (${a.parent_asset})`;
    items[`asset-${a.id}`] = {
      label: {
        bold: true,
        color: "black",
        position: "s",
        text: a.name,
      },
      data: { level: 1, ...a },
      image: getAssetGraphIcon(a),
    };

    items[`link-${a.id}-${a.parent_asset}`] = {
      id1: `asset-${a.parent_asset}`,
      id2: `asset-${a.id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });
  copy_data.findings.forEach((f: any) => {
    items[`finding-${f.id}`] = {
      color: "blue",
      data: { level: 2, ...f },
      image: `/icons/finding-${SEVERITIES[f.overall_risk].toLowerCase()}.svg`,
    };
  });
  copy_data.relations.forEach((r: any, index: number) => {
    items[`link-${index}`] = {
      id1: `finding-${r.finding_id}`,
      id2: `asset-${r.asset_id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });
  return items;
};

export const formatDataForReGraphCombos = (data: any): any => {
  const copy_data = JSON.parse(JSON.stringify(data));
  var items: any = {};
  copy_data.assets.forEach((a: any) => {
    items[`asset-${a.id}`] = {
      label: {
        bold: true,
        color: "black",
        position: "s",
        text: a.name,
      },
      data: a,
      image: getAssetGraphIcon(a),
    };
  });
  copy_data.findings.forEach((f: any) => {
    items[`finding-${f.id}`] = {
      color: "blue",
      data: f,
      image: `/icons/finding-${SEVERITIES[f.overall_risk].toLowerCase()}.svg`,
    };
  });
  copy_data.relations.forEach((r: any, index: number) => {
    items[`link-${index}`] = {
      id1: `finding-${r.finding_id}`,
      id2: `asset-${r.asset_id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });
  return items;
};

export const formatDataForReGraphSeq = (data: any): any => {
  const copy_data = JSON.parse(JSON.stringify(data));
  var items: any = {};
  copy_data.assets.forEach((a: any) => {
    items[`asset-${a.id}`] = {
      label: {
        bold: true,
        color: "black",
        position: "s",
        text: a.name,
      },
      data: { level: 1, ...a },
      image: getAssetGraphIcon(a),
    };
    items[`prod-${a.product_id}`] = {
      label: {
        bold: true,
        color: "black",
        position: "s",
        text: `prod-${a.product_id}`,
      },
      image: "/icons/product.svg",
      glyphs: [
        {
          position: "ne",
          border: {
            color: "#f15d5b",
          },
          color: "#f15d5b",
          label: {
            text: "!",
          },
        },
      ],
      data: { level: 0 },
    };

    // Edges
    // items[`${a.type}-${a.product_id}`] = {
    //   label: {
    //     bold: true,
    //     color: "black",
    //     position: "s",
    //     text: `${a.type}`,
    //   },
    //   image: getImageFromType(a.type),
    //   level: 1,
    // };
    // items[`link-prod-type-${a.type}-${a.product_id}`] = {
    //   id1: `prod-${a.product_id}`,
    //   id2: `${a.type}-${a.product_id}`,
    //   flow: {
    //     velocity: 1,
    //     color: "#f15d5b",
    //   },
    // };
    // items[`link-type-asset-${a.type}-${a.id}`] = {
    //   id1: `${a.type}-${a.product_id}`,
    //   id2: `asset-${a.id}`,
    //   flow: {
    //     velocity: 1,
    //     color: "#f15d5b",
    //   },
    // };
    // Product<>Asset link
    items[`link-prod-${a.product_id}-asset-${a.id}`] = {
      id1: `prod-${a.product_id}`,
      id2: `asset-${a.id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });
  // Findings
  // Nodes
  copy_data.findings.forEach((f: any) => {
    items[`finding-${f.id}`] = {
      color: "blue",
      data: { level: 2, ...f },
      image: `/icons/finding-${SEVERITIES[f.overall_risk].toLowerCase()}.svg`,
    };
  });
  // Edges
  copy_data.relations.forEach((r: any, index: number) => {
    items[`link-${index}`] = {
      id1: `finding-${r.finding_id}`,
      id2: `asset-${r.asset_id}`,
      flow: {
        velocity: 1,
        color: "#f15d5b",
      },
    };
  });
  return items;
};
