import { IconProps } from "./WaspIcon";

export function PlusIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6039 0.996094H6.39609C4.9644 0.997653 3.5918 1.56708 2.57944 2.57944C1.56708 3.5918 0.997653 4.9644 0.996094 6.39609V11.6039C0.997653 13.0356 1.56708 14.4082 2.57944 15.4206C3.5918 16.4329 4.9644 17.0023 6.39609 17.0039H11.6039C13.0356 17.0023 14.4082 16.4329 15.4206 15.4206C16.4329 14.4082 17.0023 13.0356 17.0039 11.6039V6.39609C17.0023 4.9644 16.4329 3.5918 15.4206 2.57944C14.4082 1.56708 13.0356 0.997653 11.6039 0.996094ZM15.8039 11.6039C15.8026 12.7174 15.3596 13.7849 14.5723 14.5723C13.7849 15.3596 12.7174 15.8026 11.6039 15.8039H6.39609C5.2826 15.8026 4.21509 15.3596 3.42773 14.5723C2.64037 13.7849 2.19744 12.7174 2.19609 11.6039V6.39609C2.19744 5.2826 2.64037 4.21509 3.42773 3.42773C4.21509 2.64037 5.2826 2.19744 6.39609 2.19609H11.6039C12.7174 2.19744 13.7849 2.64037 14.5723 3.42773C15.3596 4.21509 15.8026 5.2826 15.8039 6.39609V11.6039ZM12.1219 9C12.1219 9.0788 12.1064 9.15685 12.0763 9.22966C12.0462 9.30248 12.002 9.36864 11.9462 9.42436C11.8905 9.48009 11.8244 9.52428 11.7515 9.55442C11.6787 9.58455 11.6007 9.60004 11.5219 9.6H9.6V11.5225C9.6 11.6816 9.53678 11.8342 9.42426 11.9467C9.31174 12.0592 9.15913 12.1225 9 12.1225C8.84087 12.1225 8.68826 12.0592 8.57573 11.9467C8.46321 11.8342 8.4 11.6816 8.4 11.5225V9.6H6.47813C6.319 9.6 6.16638 9.53678 6.05386 9.42426C5.94134 9.31174 5.87813 9.15913 5.87813 9C5.87813 8.84087 5.94134 8.68826 6.05386 8.57573C6.16638 8.46321 6.319 8.4 6.47813 8.4H8.4V6.4776C8.4 6.31847 8.46321 6.16586 8.57573 6.05333C8.68826 5.94081 8.84087 5.8776 9 5.8776C9.15913 5.8776 9.31174 5.94081 9.42426 6.05333C9.53678 6.16586 9.6 6.31847 9.6 6.4776V8.4H11.5219C11.6007 8.39995 11.6787 8.41544 11.7515 8.44558C11.8244 8.47571 11.8905 8.51991 11.9462 8.57563C12.002 8.63136 12.0462 8.69752 12.0763 8.77033C12.1064 8.84315 12.1219 8.92119 12.1219 9Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
