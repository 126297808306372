import { useEffect, useState } from "react";
import { FormStages } from "../../../components/elements/formStages/FormStages";
import { Modal } from "../../../components/elements/modal/Modal";
import { Flex } from "../../../components/layouts/flex/Flex";
import { InitiateScannings } from "./InitiateScannings";
import AddPeers from "./AddPeers";
import SetSla from "./SetSla";
import { AddProduct } from "./AddProduct";
import { AddAsset } from "./AddAsset";
import { useApiMe } from "../../../hooks/queries/meContext";
import { useApiUpdateCustomer } from "../../../hooks/queries/customersContext";
import { LetsGetStarted } from "./LetsGetStarted";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Hexagon } from "../../../components/elements/hexagon/Hexagon";
import { Mixpanel } from "../../../shared/mixpanel";

type Props = {
  onClose: () => void;
};

const formStages = [
  "Add Product",
  "Add Asset",
  "Add Peers",
  "Set SLA",
  "Initiate Scanning",
];

const Onboarding = (props: Props) => {
  const { onClose } = props;
  const { data: me } = useApiMe();
  const { mutate: updateCustomer } = useApiUpdateCustomer();

  const [currentStage, setCurrentStage] = useState<number>(
    me?.customer.onboarding_stage || 0
  );

  useEffect(() => {
    // Set current stage from Customer.onboarding_stage
    if (!me?.customer.onboarding_stage) return;
    setCurrentStage(me?.customer.onboarding_stage);
  }, [me?.customer.onboarding_stage]);

  const goToNextStage = () => {
    const nextStage = currentStage < formStages.length ? currentStage + 1 : -1;
    me &&
      updateCustomer({
        customerId: me.customer.id,
        customerData: { onboarding_stage: nextStage },
        onSuccessCallback: () => setCurrentStage((prev) => prev + 1),
      });

    if (nextStage !== -1)
      Mixpanel.track(`Onboarding - ${formStages[nextStage - 1]}`);
    else {
      Mixpanel.track(`Onboarding completed`);
      onClose();
    }
  };

  const goToPrevStage = () =>
    currentStage !== 1 && setCurrentStage((prev) => prev - 1);

  const ModalHeader = () => (
    <Flex align="center" justify="between" style={{ width: "1320px" }}>
      <HeaderSecondary>Welcome to WASP</HeaderSecondary>
      {currentStage > 0 && (
        <FormStages currentStage={currentStage} stages={formStages} />
      )}
    </Flex>
  );

  return (
    <Modal
      onClose={onClose}
      header={<ModalHeader />}
      width="1400px"
      style={{ height: "800px", overflow: "hidden" }}
    >
      <Flex column gap="24px" style={{ position: "relative" }}>
        <Flex
          column
          gap="32px"
          align="center"
          style={{
            width: "1400px",
            height: "805px",
            position: "relative",
            zIndex: 100,
          }}
        >
          {currentStage === 0 && <LetsGetStarted onSuccess={goToNextStage} />}
          {currentStage === 1 && (
            <AddProduct onSuccess={goToNextStage} onClose={onClose} />
          )}
          {currentStage === 2 && (
            <AddAsset
              onSuccess={goToNextStage}
              onClose={onClose}
              goToPrevStage={goToPrevStage}
            />
          )}
          {currentStage === 3 && (
            <AddPeers
              onSuccess={goToNextStage}
              onClose={onClose}
              goToPrevStage={goToPrevStage}
            />
          )}
          {currentStage === 4 && (
            <SetSla
              onSuccess={goToNextStage}
              onClose={onClose}
              goToPrevStage={goToPrevStage}
            />
          )}
          {currentStage === 5 && (
            <InitiateScannings
              onSuccess={goToNextStage}
              onClose={onClose}
              goToPrevStage={goToPrevStage}
            />
          )}
        </Flex>
        <Flex style={{ position: "absolute", top: "700px" }}>
          <Hexagon
            opacity={0.3}
            top="-300px"
            left="1100px"
            transform="rotate(5deg)"
          />
          <Hexagon
            opacity={0.1}
            top="-185px"
            left="970px"
            transform="rotate(5deg)"
          />
          <Hexagon
            opacity={0.2}
            top="-157px"
            left="1184px"
            transform="rotate(5deg)"
          />
          <Hexagon
            opacity={0.2}
            top="-40px"
            left="1060px"
            transform="rotate(5deg)"
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default Onboarding;
