import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageLayout } from "./generator/PageLayout";
import { WelcomeBanner } from "../../components/composed/banner/WelcomeBanner";
import { Modal } from "../../components/elements/modal/Modal";
import { Flex } from "../../components/layouts/flex/Flex";
import { BodyBold } from "../../components/elements/typography/Typography";
import { FindingPage } from "./fullReport/FindingPage";
import { useCountDown, usePrint, useWaitLoading } from "./generator/hooks";
import { useApiFindings } from "../../hooks/queries/findingContext";
import { Filters } from "../findings/Findings";
import { base64ToObject } from "../../shared/helper";

const WAITING_TIME = 3; //seconds

export const MultiFindingsReport = () => {
  const [searchParams] = useSearchParams();

  const urlFilters = searchParams.get("filters");
  const filters = urlFilters ? base64ToObject(urlFilters) : {};

  const queryParams: Filters = { page_size: 1000, all_data: true, ...filters };

  const exportedFindingsIds = searchParams.get("ids");
  if (!!exportedFindingsIds)
    queryParams.id =
      exportedFindingsIds.split(",").map((id) => parseInt(id)) || [];

  const { data: findings, isFetching } = useApiFindings(queryParams, true);

  const [countdown, setCountdown] = useState<number>(WAITING_TIME);
  const [loadingData, setLoadingData] = useState(true);

  const isPageLoaded = Boolean(findings && !isFetching);
  useWaitLoading(WAITING_TIME, () => setLoadingData(false), isPageLoaded);

  const isReadyToPrint = !!(!loadingData && findings);
  usePrint(isReadyToPrint, `Findings=${exportedFindingsIds}`);
  useCountDown(setCountdown);

  if (isFetching) return <WelcomeBanner />;

  return (
    <PageLayout>
      {loadingData && (
        <Modal onClose={() => setLoadingData(false)} hideCloseIcon>
          <Flex align="center" gap="6px">
            <BodyBold>
              {`Generating PDF report in ${
                countdown > 0 ? countdown : "just a sec"
              }...`}
            </BodyBold>
          </Flex>
        </Modal>
      )}
      {findings?.map((f, idx) => (
        <FindingPage finding={f} index={idx} />
      ))}
    </PageLayout>
  );
};
