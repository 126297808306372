import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";

type Props = {
  iconName: string;
  tooltip: string;
  isOn: boolean;
  dataTestId?: string;
  isEditable?: boolean;
  onClick?: () => void;
  isDead?: boolean;
};

export const CoverageItem = (props: Props) => {
  const { iconName, tooltip, isOn, dataTestId, isEditable, onClick, isDead } =
    props;
  const theme = useContext(ThemeContext);

  return (
    <div
      data-testid={dataTestId}
      className="d-flex align-items-center justify-content-center"
      style={{
        padding: "3px",
        height: "fit-content",
        width: "fit-content",
        borderRadius: "16px",
        backgroundColor:
          isDead && (isOn || isEditable) ? theme.blue50 : "transparent",
      }}
    >
      <Tooltip
        placement="bottom"
        content={<div style={{ maxWidth: "250px" }}>{tooltip}</div>}
      >
        <Icon
          name={iconName}
          size={24}
          color={
            isOn
              ? isDead
                ? theme.black800
                : theme.blue800
              : isDead
                ? theme.black500
                : theme.blue500
          }
        />
      </Tooltip>
      {isEditable && (
        <IconButton
          iconName="chevronDown"
          color={theme.blue800}
          size="very-small"
          onClick={() => !!onClick && onClick()}
        />
      )}
    </div>
  );
};
