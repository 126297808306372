import styled from "styled-components";

type Props = {
  size: number;
  color: string;
};

export const Ellipse = styled.div<Props>`
  width: ${(props: Props) => props.size}px;
  height: ${(props: Props) => props.size}px;
  border-radius: 50%;
  background-color: ${(props: Props) => props.color};
`;
