import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { CLEAR_EDITOR_COMMAND } from "lexical";
export const ClearEditorPlugin = (props) => {
    const { clearTrigger } = props;
    const [editor] = useLexicalComposerContext();
    if (clearTrigger && clearTrigger.current) {
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        clearTrigger.current = false;
    }
    return null;
};
