import React, { useContext, useEffect, useState } from "react";
import {
  FindingStatus,
  STATUSES_DESCRIPTIONS,
} from "../../../../types/Finding";
import { JiraField } from "../../../../types/JiraIntegration";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  Dropdown,
  Option,
} from "../../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import {
  BodyMedium,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { getStatusName } from "../../../../shared/findingsHelper";
import { SingleValue } from "react-select";

type Props = {
  waspStatus: FindingStatus;
  jiraStatus: JiraField | undefined;
  allStatuses: JiraField[];
  onChange: (jiraStatus: JiraField) => void;
};

export const StatusMap = (props: Props) => {
  const { waspStatus, jiraStatus, onChange, allStatuses } = props;
  const [selectedStatus, setSelectedStatus] = useState<JiraField | undefined>(
    jiraStatus
  );
  const theme = useContext(ThemeContext);
  const handleChange = (option: SingleValue<Option>) => {
    const optionAsJiraField = {
      name: option?.label || "",
      id: option?.value.toString() || "",
    };
    setSelectedStatus(optionAsJiraField);
    onChange(optionAsJiraField);
  };

  useEffect(() => {
    if (
      selectedStatus &&
      !allStatuses.find((s) => s.id === selectedStatus.id)
    ) {
      setSelectedStatus(undefined);
      onChange({ id: "", name: "" });
    }
  }, [allStatuses, selectedStatus, onChange]);

  return (
    <Flex gap="40px" justify="between" align="center" w100>
      <Flex
        gap="16px"
        align="center"
        w100
        style={{ overflow: "clip" }}
        className="text-truncate w-100"
      >
        <Icon
          name={waspStatus}
          color={
            waspStatus === "fixed"
              ? theme.greenPrimary
              : waspStatus === "dismissed"
              ? theme.blue300
              : theme.primary
          }
          size={24}
        />
        <Flex column gap="4px" className="text-truncate w-100">
          <BodyMedium>{getStatusName(waspStatus)}</BodyMedium>
          <LabelRegular
            className="text-truncate w-100"
            style={{ color: theme.black600 }}
            title={STATUSES_DESCRIPTIONS[waspStatus]}
          >
            {STATUSES_DESCRIPTIONS[waspStatus]}
          </LabelRegular>
        </Flex>
      </Flex>
      <Dropdown
        variant="border"
        size="medium"
        width="360px"
        disabled={allStatuses.length === 0}
        placeholder="Select Status"
        key={JSON.stringify(allStatuses)}
        value={
          !!selectedStatus
            ? { label: selectedStatus.name, value: selectedStatus.id }
            : undefined
        }
        options={allStatuses.map((status) => ({
          label: status.name,
          value: status.id,
        }))}
        onChange={handleChange}
        isMenuPositionFixed
        dataTestId={`jira-project-mapping-status-${waspStatus}-dropdown`}
      />
    </Flex>
  );
};
