import React from "react";
import { SkeletonLoading } from "../loading/SkeletonLoading";
import { SeparatorHorizontal } from "../separators/SeparatorHorizontal";

type Props = {
  height?: string;
};

export const TableSkeleton = ({ height }: Props) => {
  const Header = () => (
    <div className="d-flex flex-column gap-16">
      <div
        className="d-flex align-items-center justify-content-between gap-16"
        style={{
          width: "100%",
          overflowX: "clip",
        }}
      >
        <SkeletonLoading width="10%" height="24px" />
        <SkeletonLoading width="10%" height="24px" />
        <SkeletonLoading width="10%" height="24px" />
        <SkeletonLoading width="10%" height="24px" />
        <SkeletonLoading width="10%" height="24px" />
        <SkeletonLoading width="10%" height="24px" />
        <SkeletonLoading width="10%" height="24px" />
        <SkeletonLoading width="10%" height="24px" />
        <SkeletonLoading width="10%" height="24px" />
        <SkeletonLoading width="10%" height="24px" />
      </div>
      <SeparatorHorizontal />
    </div>
  );

  const Row = () => (
    <div className="d-flex flex-column gap-16">
      <SkeletonLoading width="100%" height="45px" />
      <SeparatorHorizontal />
    </div>
  );

  return (
    <div
      className="d-flex flex-column gap-16 w-100"
      style={{
        overflowY: "clip",
        height: height || "100%",
      }}
    >
      <Header />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
    </div>
  );
};
