import React, { useContext } from "react";
import { FindingLog } from "../../../types/FindingLog";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { CardHeader } from "./CardHeader";

type Props = {
  findingLog: FindingLog;
};

export function FindingLogUpdatedCard({ findingLog }: Props) {
  const theme = useContext(ThemeContext);
  return (
    <Box>
      <Flex column gap="16px">
        <CardHeader
          userName={findingLog.actor.name}
          avatarUrl={findingLog.actor.avatar_url}
          createdAt={findingLog.created_at}
        />

        <Flex align="center" gap="8px" flexWrap>
          <LabelRegular color={theme.black600}>{`The field`}</LabelRegular>
          <LabelBold className="text-capitalize">{findingLog.field}</LabelBold>
          <LabelRegular
            color={theme.black600}
          >{`has been changed`}</LabelRegular>
        </Flex>
        {!!findingLog.comment && <SeparatorHorizontal />}
        {!!findingLog.comment && (
          <BodyRegular>{findingLog.comment as string}</BodyRegular>
        )}
      </Flex>
    </Box>
  );
}
