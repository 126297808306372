import React from "react";
import { Box } from "../../components/elements/box/Box";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../components/elements/typography/Typography";
import { Illustration } from "../../components/elements/illustrations/Illustration";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";

type Props = {};

export const QuickScanResultsLockGraph = (props: Props) => {
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  return (
    <Box
      style={{
        width: isMobile ? "99%" : "70%",
      }}
    >
      <Flex column gap="16px">
        <HeaderSubBold>Your Assets Graph</HeaderSubBold>
        <Flex column={isMobile} align="center" gap="16px">
          <Illustration
            name="lock"
            style={{
              height: "80px",
              width: "fit-content",
            }}
          />
          <BodyRegular>
            To view your assets graph and many other features and capabilities
            that will improve your application security and let you sleep
            peacefully at night, join WASP now.
          </BodyRegular>
          <SecondaryButton
            size="small"
            label="Join WASP Now"
            onClick={() => window.location.assign("/register")}
            style={{
              width: "250px",
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
