import { IconProps } from "./WaspIcon";

export function UploadIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.02136 7.9267C7.89836 7.82134 7.82125 7.67035 7.80697 7.50689C7.79268 7.34342 7.84237 7.18084 7.94514 7.05482L9.17909 5.54496C9.69245 4.94296 10.4181 4.5736 11.1971 4.51776C11.2023 4.51491 11.2043 4.50835 11.2092 4.50538H11.3161C11.4383 4.49821 11.5608 4.49821 11.6829 4.50538H11.7903C11.7957 4.50811 11.7977 4.51467 11.8024 4.51776C12.5814 4.5736 13.3071 4.94296 13.8204 5.54496L15.0544 7.05482C15.1573 7.18079 15.2071 7.34344 15.1928 7.50697C15.1785 7.6705 15.1013 7.82153 14.9782 7.92682C14.855 8.03212 14.696 8.08306 14.5362 8.06844C14.3763 8.05382 14.2287 7.97483 14.1258 7.84886L12.8918 6.339C12.6832 6.09107 12.4104 5.90835 12.1049 5.81178V15.978C12.1049 16.1421 12.0411 16.2995 11.9277 16.4155C11.8143 16.5316 11.6604 16.5968 11.5 16.5968C11.3396 16.5968 11.1857 16.5316 11.0723 16.4155C10.9589 16.2995 10.8951 16.1421 10.8951 15.978V5.81178C10.5894 5.90819 10.3165 6.09084 10.1077 6.33875L8.87375 7.84861C8.77062 7.97426 8.62305 8.05302 8.46331 8.06765C8.30357 8.08229 8.14467 8.03161 8.02136 7.9267ZM17.3946 16.4684C17.2342 16.4684 17.0804 16.5336 16.9669 16.6497C16.8535 16.7657 16.7898 16.9231 16.7898 17.0872C16.699 17.7387 16.3644 18.3286 15.8566 18.7325C15.3487 19.1363 14.7073 19.3225 14.0678 19.2518H8.9317C8.29218 19.3225 7.65079 19.1363 7.14293 18.7325C6.63507 18.3286 6.30047 17.7387 6.20975 17.0872C6.20975 16.9231 6.14603 16.7657 6.03259 16.6497C5.91915 16.5336 5.7653 16.4684 5.60488 16.4684C5.44445 16.4684 5.2906 16.5336 5.17716 16.6497C5.06373 16.7657 5 16.9231 5 17.0872C5.08788 18.0686 5.54936 18.9754 6.2849 19.6119C7.02043 20.2483 7.97106 20.5635 8.9317 20.4894H14.0683C15.0289 20.5635 15.9796 20.2483 16.7151 19.6119C17.4506 18.9754 17.9121 18.0686 18 17.0872C18 17.0059 17.9843 16.9254 17.9539 16.8503C17.9235 16.7752 17.8789 16.707 17.8227 16.6495C17.7664 16.592 17.6997 16.5465 17.6263 16.5154C17.5528 16.4843 17.4741 16.4684 17.3946 16.4684Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
