import React from "react";
import { IconProps } from "./WaspIcon";

export function ColumnSortIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16775 7.56607C9.11149 7.62231 9.0352 7.65391 8.95565 7.65391C8.8761 7.65391 8.79981 7.62231 8.74355 7.56607L6.21113 5.03365C6.15487 4.97741 6.07858 4.94582 5.99903 4.94582C5.91948 4.94582 5.84319 4.97741 5.78693 5.03365L3.25451 7.56607C3.19793 7.62072 3.12215 7.65096 3.04349 7.65027C2.96483 7.64959 2.88958 7.61804 2.83396 7.56242C2.77834 7.50679 2.74679 7.43155 2.74611 7.35289C2.74542 7.27423 2.77566 7.19845 2.83031 7.14187L5.36303 4.60945C5.53188 4.44087 5.76073 4.34619 5.99933 4.34619C6.23793 4.34619 6.46677 4.44087 6.63563 4.60945L9.16805 7.14187C9.22425 7.19817 9.25579 7.27448 9.25573 7.35403C9.25568 7.43358 9.22403 7.50985 9.16775 7.56607Z"
        fill={props.color || "currentColor"}
      />
      <path
        d="M2.83225 16.4339C2.88851 16.3777 2.9648 16.3461 3.04435 16.3461C3.1239 16.3461 3.20019 16.3777 3.25645 16.4339L5.78887 18.9663C5.84513 19.0226 5.92142 19.0542 6.00097 19.0542C6.08052 19.0542 6.15681 19.0226 6.21307 18.9663L8.74549 16.4339C8.80207 16.3793 8.87785 16.349 8.95651 16.3497C9.03517 16.3504 9.11042 16.382 9.16604 16.4376C9.22166 16.4932 9.25321 16.5684 9.25389 16.6471C9.25458 16.7258 9.22434 16.8015 9.16969 16.8581L6.63697 19.3905C6.46812 19.5591 6.23927 19.6538 6.00067 19.6538C5.76207 19.6538 5.53323 19.5591 5.36437 19.3905L2.83195 16.8581C2.77575 16.8018 2.74421 16.7255 2.74427 16.646C2.74432 16.5664 2.77597 16.4901 2.83225 16.4339Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
