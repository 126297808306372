import React, { useContext, useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { HeaderSubBold } from "../../../components/elements/typography/Typography";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { ThemeContext } from "styled-components";
import { useApiMe } from "../../../hooks/queries/meContext";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { useApiScanEventsPaging } from "../../../hooks/queries/scansContext";
import { Scanner } from "./Scanner";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { useApiScanners } from "../../../hooks/queries/scannersContext";
import { ScannerManagementSkeleton } from "./ScannerManagementSkeleton";
import { Scanner as ScannerType } from "../../../types/Scanner";
import {
  CustomerContext,
  useApiUpdateCustomer,
} from "../../../hooks/queries/customersContext";
import { useDebounceCallback } from "../../../hooks/utilsHooks";
import useToastContext from "../../../hooks/toastHook";
import { ScanEvent } from "../../../types/ScanEvent";
import { ScannerManagementPane } from "./ScannerManagementPane";
import { Mixpanel } from "../../../shared/mixpanel";

type Props = {};

export const ScannerManagement = (props: Props) => {
  const theme = useContext(ThemeContext);
  const [showSettingsPane, setShowSettingsPane] = useState<boolean>(false);
  const { data: me, refetch } = useApiMe();
  const { data: scanEvents, isFetching: isFetchingEvents } =
    useApiScanEventsPaging(
      {
        customer_id: me?.customer?.id,
        status: "running",
      },
      !!me?.customer?.id
    );
  const { data: scanners, isFetching: isFetchingScanners } = useApiScanners(
    me?.customer?.id
  );
  const disabledScanners = scanners?.filter((scanner) =>
    me?.customer?.disabled_scanners?.includes(scanner.name)
  );
  const enabledScanners = scanners?.filter(
    (scanner) => !me?.customer?.disabled_scanners?.includes(scanner.name)
  );

  const getScanEvents = () => {
    return scanEvents?.pages?.map((page) => page?.results || []).flat() || [];
  };
  const addToast = useToastContext();
  const { mutate, isLoading } = useApiUpdateCustomer();

  const updatePolicy = useDebounceCallback((customerContext: CustomerContext) =>
    mutate({
      ...customerContext,
      onSuccessCallback: () => {
        addToast({
          message: "Scanning policy updated successfully",
          type: "success",
        });
        refetch();
      },
      onErrorCallback: (error: Error) =>
        addToast({ message: error.message, type: "error" }),
    })
  );

  const updateCustomerScanners = (scanner: ScannerType, isEnabled: boolean) => {
    const disabledScanners = me?.customer?.disabled_scanners || [];
    if (isEnabled) {
      const index = disabledScanners.indexOf(scanner.name);
      if (index > -1) {
        disabledScanners.splice(index, 1);
      } else {
        addToast({ message: "No changes", type: "info" });
        return;
      }
    } else {
      const index = disabledScanners.indexOf(scanner.name);
      if (index > -1) {
        addToast({ message: "No changes", type: "info" });
        return;
      } else {
        disabledScanners.push(scanner.name);
      }
    }

    updatePolicy({
      customerId: me?.customer?.id,
      customerData: {
        disabled_scanners: disabledScanners,
      },
    });
  };

  const getRunningScanEvent = (scanner: ScannerType): ScanEvent | null =>
    getScanEvents()?.find((scanEvent) => scanEvent.scanner === scanner.name) ||
    null;

  return (
    <Flex column gap="16px" style={{ width: "331px" }}>
      {showSettingsPane && !!me && (
        <ScannerManagementPane
          onClose={() => setShowSettingsPane(false)}
          updatePolicyMutate={updatePolicy}
          me={me}
        />
      )}
      <Flex justify="between" align="center">
        <HeaderSubBold>Scanners Management</HeaderSubBold>
        <IconButton
          iconName="settings"
          onClick={() => {
            Mixpanel.track("Use Scanners Management Settings");
            setShowSettingsPane(true);
          }}
          color={theme.primary}
          size="small"
          dataTestId="scanner-management-settings"
        />
      </Flex>
      {(isFetchingScanners ||
        (isFetchingEvents && !getScanEvents()?.length)) && (
        <ScannerManagementSkeleton />
      )}
      {enabledScanners?.map((scanner) => (
        <Scanner
          dataTestId={`scanner-enabled-${scanner.name}`}
          key={`scanner-${scanner.name}`}
          scanner={scanner}
          isEnabled={true}
          onChange={(isEnabled) => {
            updateCustomerScanners(scanner, isEnabled);
          }}
          scanEvent={getRunningScanEvent(scanner)}
        />
      ))}
      <SeparatorHorizontal />
      <Flex justify="between" align="center">
        <HeaderSubBold>Disabled Scanners</HeaderSubBold>
        <TextButton
          label={isLoading ? "Updating..." : "Enable All"}
          dataTestId="enable-all-scanners"
          onClick={() =>
            updatePolicy({
              customerId: me?.customer?.id,
              customerData: {
                disabled_scanners: [],
              },
            })
          }
          color={theme.primary}
          disabled={isLoading || !disabledScanners?.length}
          size="small"
        />
      </Flex>

      {disabledScanners?.map((scanner) => (
        <Scanner
          dataTestId={`scanner-disabled-${scanner.name}`}
          key={`scanner-${scanner.name}`}
          scanner={scanner}
          isEnabled={false}
          onChange={(isEnabled) => {
            updateCustomerScanners(scanner, isEnabled);
          }}
          scanEvent={getRunningScanEvent(scanner)}
        />
      ))}
    </Flex>
  );
};
