import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTrackPage } from "../../../../hooks/trackingHooks";
import { useApiAsset } from "../../../../hooks/queries/assetsContext";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  HeaderMain,
  HeaderSub,
} from "../../../../components/elements/typography/Typography";
import { Box } from "../../../../components/elements/box/Box";
import { SecondaryButton } from "../../../../components/elements/button/secondary/SecondaryButton";
import { MainButton } from "../../../../components/elements/button/main/MainButton";
import { AssetIsolateGraph } from "./AssetIsolateGraph";
import { AssetIsolateDetailsColumn } from "./AssetIsolateDetailsColumn";
import { AssetPane } from "../../assetsPane/AssetPane";
import { AssetIsolateDetailsColumnSkeleton } from "./AssetIsolateDetailsColumnSkeleton";
import { Loading } from "../../../../components/elements/loading/Loading";

export const IsolateAssetPage = () => {
  const { id: assetId } = useParams();
  const navigate = useNavigate();
  useTrackPage("Assets - Isolate asset", { assetId: assetId || "" });
  const { data: asset, isFetching: isFetchingAsset } = useApiAsset(
    Number(assetId)
  );
  const [assetPaneId, setAssetPaneId] = React.useState<number | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const assetId = searchParams.get("assetId");
    if (assetId) {
      setAssetPaneId(Number(assetId));
    }
  }, [searchParams]);

  return (
    <Flex column gap="24px">
      <HeaderMain>Isolate Asset</HeaderMain>
      <Box
        id="graph-component-container"
        className="d-flex w-100"
        style={{
          position: "relative",
          height: `calc(100vh - 164px)`,
        }}
      >
        <Flex column w100 gap="16px">
          <Flex justify="between" w100 gap="24px" align="center">
            <HeaderSub>{asset?.name}</HeaderSub>
            <Flex align="center" gap="8px">
              {!!asset?.id && (
                <SecondaryButton
                  label="More details"
                  onClick={() => setAssetPaneId(asset.id)}
                />
              )}
              <MainButton label="Exit isolate" onClick={() => navigate(-1)} />
            </Flex>
          </Flex>
          {isFetchingAsset && (
            <Flex gap="4px" w100 h100>
              <AssetIsolateDetailsColumnSkeleton />
              <Flex w100 h100 align="center" justify="center">
                <Loading />
              </Flex>
            </Flex>
          )}
          {!!asset && (
            <Flex gap="4px" w100 h100>
              <AssetIsolateDetailsColumn asset={asset} />
              <AssetIsolateGraph asset={asset} />
            </Flex>
          )}
        </Flex>
      </Box>
      {assetPaneId !== null && (
        <AssetPane
          onClose={() => {
            setAssetPaneId(null);
            // remove assetId from search params
            setSearchParams((prev) => {
              prev.delete("assetId");
              return prev;
            });
          }}
          assetId={assetPaneId}
        />
      )}
    </Flex>
  );
};
