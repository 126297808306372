import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Box } from "../../elements/box/Box";
import { LinkButton } from "../../elements/button/link/LinkButton";
import { Icon } from "../../elements/icon/Icon";
import { HeaderSubBold } from "../../elements/typography/Typography";
import { IconButton } from "../../elements/button/icon/IconButton";
import { SeparatorHorizontal } from "../../elements/separators/SeparatorHorizontal";
import { SeparatorVertical } from "../../elements/separators/SeparatorVertical";
import { Flex } from "../../layouts/flex/Flex";

type Props = {
  filtersBadges: JSX.Element;
  hasBadges: boolean;
  onShowFiltersPanel: () => void;
  onRemoveAllFilters: () => void;
  showSaveAsFilterButton?: boolean;
  onSaveAsFilter?: () => void;
  isAssetsFilter?: boolean;
};

export const FilterLine = (props: Props) => {
  const {
    filtersBadges,
    onShowFiltersPanel,
    onRemoveAllFilters,
    showSaveAsFilterButton,
    onSaveAsFilter,
    hasBadges,
  } = props;
  const theme = useContext(ThemeContext);
  const [, setSearchParams] = useSearchParams();

  return (
    <Box className="d-flex flex-column">
      <Flex
        justify="between"
        w100
        align="center"
        data-testid="filter-line-header"
      >
        <Flex align="center" gap="16px">
          <Icon name="filter" color={theme.primary} size={"24"} />
          <HeaderSubBold>Applied Filters</HeaderSubBold>
        </Flex>
        <Flex gap="16px" align="center">
          {hasBadges ? (
            <>
              {showSaveAsFilterButton && (
                <>
                  <LinkButton
                    label="Save All As Filter"
                    iconName="save"
                    onClick={onSaveAsFilter || (() => {})}
                  />
                  <SeparatorVertical height="16px" />
                </>
              )}
              <LinkButton
                label="Clear All"
                iconColor={theme.bluePrimary}
                iconName="cancel"
                onClick={() => {
                  onRemoveAllFilters();
                  setSearchParams({});
                }}
                dataTestId="remove-all-filters"
              />
            </>
          ) : (
            <LinkButton
              label="Add Filter"
              iconName="plus"
              onClick={onShowFiltersPanel}
            />
          )}
        </Flex>
      </Flex>
      <Flex column gap="16px" data-testid="filter-line">
        {hasBadges && (
          <>
            <SeparatorHorizontal style={{ marginTop: "16px" }} />
            <div className="d-flex flex-wrap align-items-center gap-8">
              {filtersBadges}
              <IconButton
                color={theme.primary}
                iconName="plus"
                size="very-small"
                onClick={onShowFiltersPanel}
                dataTestId="filter-line-add-filter"
              />
            </div>
          </>
        )}
      </Flex>
    </Box>
  );
};
