import { Dispatch, SetStateAction } from "react";
import { Section } from "../../../../components/elements/section/Section";
import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { useApiScanners } from "../../../../hooks/queries/scannersContext";
import { useIsSuperuser } from "../../../../hooks/useIsSuperuser";
import { FormModeState } from "../../../../shared/formUtils";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { IsAutomatedAndScanner } from "../../../admin/research/sub-components/IsAutomatedAndScanner";

type Props = {
  finding: Finding | undefined | null;
  isEditable?: boolean;
  noSeparator?: boolean;
  width?: string;

  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const ScannerItem = (props: Props) => {
  const {
    finding,
    noSeparator = false,
    isEditable,
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const { data: me } = useApiMe();
  const isSuperuser = useIsSuperuser();
  const { data: scanners } = useApiScanners(me?.customer?.id);

  let scannersDisplayNamesMap =
    scanners?.reduce<Record<string, string>>((acc, current) => {
      acc[current.name] = current.display_name;
      return acc;
    }, {}) || {};

  // Add display name for "wasp" (for automate_findings created by SU)
  scannersDisplayNamesMap.wasp = "WASP";

  return (
    <Section title="Source Scanner" noSeparator={noSeparator}>
      {isSuperuser && isEditable ? (
        <IsAutomatedAndScanner
          formMode={FormModeState.Update}
          setUpdateFinding={setUpdateFinding}
          editableUpdateFindingData={editableUpdateFindingData}
          setEditableUpdateFinding={setEditableUpdateFinding}
          setCachedFindingData={setCachedFindingData}
        />
      ) : (
        <BodyRegular className="text-truncate w-100">
          {scannersDisplayNamesMap && finding?.scanner_name
            ? scannersDisplayNamesMap[finding.scanner_name]
            : "-"}
        </BodyRegular>
      )}
    </Section>
  );
};
