import { useApiMe } from "./queries/meContext";

export const useIsSuperuser = () => {
  const { data: me } = useApiMe();

  // On presentation mode all SU features are hidden
  const isPresentationMode = !!localStorage.getItem("presentationMode");

  return isPresentationMode ? false : me?.is_superuser;
};
