import { ReactourStep } from "reactour";
import {
  BodyRegular,
  HeaderSecondary,
} from "../components/elements/typography/Typography";
import { Tab } from "../features/dashboard/Dashboard";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { TextButton } from "../components/elements/button/text/TextButton";

export const dashboardSteps = (
  theme: any,
  setSelectedTab: Dispatch<SetStateAction<Tab>>
): ReactourStep[] => [
  {
    selector: '[data-tut="dashboard-risk-score"]',
    stepInteraction: true,
    content: () => (
      <>
        <HeaderSecondary>Risk Score</HeaderSecondary>
        <BodyRegular>
          Evaluate your overall organizational exposure profile, calculated in
          real time, based on all open issues detected, their severity, and your
          remediation status.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="dashboard-overview"]',
    content: () => (
      <>
        <HeaderSecondary>Overview</HeaderSecondary>
        <BodyRegular>
          Get a bird's eye view of your most pressing vulnerabilities.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="export-reports"]',
    content: () => (
      <>
        <HeaderSecondary>Report Generator</HeaderSecondary>
        <BodyRegular>
          Compile on the spot various report types, including Full, Executive,
          and Attestation reports.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="dashboard-funnel"]',
    stepInteraction: true,
    content: () => (
      <>
        <HeaderSecondary>Findings Funnel Graph</HeaderSecondary>
        <BodyRegular>
          Track all findings along their full lifecycle and eliminate SLA
          breaches.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="dashboard-bottom-blocks"]',
    stepInteraction: true,
    action: () => setSelectedTab("products"),
    content: () => (
      <>
        <HeaderSecondary>Products Table</HeaderSecondary>
        <BodyRegular>
          Manage, prioritize and compare exposure efforts on various products
          and organizational units.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="dashboard-bottom-blocks"]',
    stepInteraction: true,
    action: () => setSelectedTab("activeIssues"),
    content: () => (
      <>
        <HeaderSecondary>Findings Table</HeaderSecondary>
        <BodyRegular>
          Get quick access to your most urgent unresolved vulnerabilities.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="dashboard-bottom-blocks"]',
    stepInteraction: true,
    action: () => setSelectedTab("projects"),
    content: () => (
      <>
        <HeaderSecondary>Projects Management</HeaderSecondary>
        <BodyRegular>
          Manage proactively and dynamically all your offensive security
          efforts.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="asm-updates"]',
    stepInteraction: true,
    content: () => (
      <>
        <HeaderSecondary>ASM Updates</HeaderSecondary>
        <BodyRegular>
          Access the latest ASM updates, including scanner status (running or
          stopped), and newly discovered assets and vulnerabilities.
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="intel-feed"]',
    content: () => (
      <>
        <HeaderSecondary>Threat Intel Feed</HeaderSecondary>
        <BodyRegular>
          Offers contextual cyber threat intelligence updates and advisories
        </BodyRegular>
      </>
    ),
  },
  {
    selector: '[data-tut="global-search"]',
    stepInteraction: true,
    content: () => (
      <>
        <HeaderSecondary>Global Search</HeaderSecondary>
        <BodyRegular>
          Facilitates free-text search across all entity types simultaneously
        </BodyRegular>
        <Link to="/findings?tour=on" style={{ textDecoration: "none" }}>
          <TextButton label="Go to Next Tour - Findings Page" />
        </Link>
      </>
    ),
  },
];
