import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../icon/Icon";
import { BodyRegular } from "../typography/Typography";
import { CheckboxContainer, CheckboxStyle, Checkmark } from "./Checkbox.style";
import { Flex } from "../../layouts/flex/Flex";

export type CheckboxState = "checked" | "mid" | "unchecked";

type Props = {
  hasMidCheck?: boolean;
  onChange?: (state: CheckboxState) => void;
  disabled?: boolean;
  state?: CheckboxState;
  label?: string;
  isLabelBefore?: boolean;
  dataTestId?: string;
  labelColor?: string;
  size?: "small" | "medium";
};

export const Checkbox = (props: Props) => {
  const {
    hasMidCheck = false,
    onChange,
    disabled,
    state,
    label,
    isLabelBefore,
    dataTestId,
    size = "medium",
  } = props;
  const [checked, setChecked] = useState(state || "unchecked");
  const theme = useContext(ThemeContext);

  const onChangeHandler = () => {
    var newState: CheckboxState = "checked";
    if (checked === "unchecked") {
      newState = "checked";
    } else if (hasMidCheck && checked === "checked") {
      newState = "mid";
    } else {
      newState = "unchecked";
    }
    setChecked(newState);
    onChange && onChange(newState);
  };

  return (
    <div className={`d-flex align-items-center ${disabled ? "" : "pointer"}`}>
      {isLabelBefore && (
        <BodyRegular
          className="ms-2"
          onClick={disabled ? undefined : onChangeHandler}
          data-testid={dataTestId}
          color={props.labelColor || theme.textBody}
          style={{
            fontSize: size === "small" ? "12px" : "",
          }}
        >
          {label}
        </BodyRegular>
      )}
      <CheckboxContainer
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          width: size === "small" ? "24px" : "",
          height: size === "small" ? "24px" : "",
        }}
      >
        <CheckboxStyle
          type="checkbox"
          data-testid={`${dataTestId}-input` || "checkbox"}
          checked={checked !== "unchecked"}
          disabled={disabled}
          onChange={onChangeHandler}
        />
        <Checkmark
          className="checkmark"
          style={{
            backgroundColor:
              checked !== "unchecked"
                ? disabled
                  ? theme.buttonDisabled
                  : theme.primary
                : "transparent",
            borderColor: disabled ? theme.buttonDisabled : theme.primary,
          }}
        />
        {checked !== "unchecked" && (
          <Flex style={{ position: "absolute" }}>
            <Icon
              name={checked === "checked" ? "check" : "midCheck"}
              color="white"
            />
          </Flex>
        )}
      </CheckboxContainer>
      {!isLabelBefore && (
        <BodyRegular
          className="ms-2"
          onClick={disabled ? undefined : onChangeHandler}
          data-testid={dataTestId}
          color={props.labelColor || theme.textBody}
          style={{
            fontSize: size === "small" ? "12px" : "",
          }}
        >
          {label}
        </BodyRegular>
      )}
    </div>
  );
};
