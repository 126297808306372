import { Box } from "../../../components/elements/box/Box";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";

type Props = {};

export const FindingCardSkeleton = (props: Props) => {
  const Card = () => (
    <Box
      className="d-flex flex-column gap-16"
      style={{
        height: "160px",
        width: "100%",
      }}
    >
      <SkeletonLoading width="115px" height="16px" />
      <SkeletonLoading width="260px" height="24px" />
      <SkeletonLoading width="260px" height="24px" />
      <div className="d-flex align-items-center gap-8">
        <SkeletonLoading width="24px" height="24px" />
        <SkeletonLoading width="100px" height="16px" />
      </div>
    </Box>
  );

  return (
    <div className="d-flex flex-column gap-16">
      <Card />
      <Card />
      <Card />
      <Card />
      <Card />
    </div>
  );
};
