import { Dispatch, SetStateAction } from "react";
import { Box } from "../../../components/elements/box/Box";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_DESKTOP_WIDTH } from "../../../shared/consts";
import { AdminFindingEdit, Finding } from "../../../types/Finding";
import { DateItem } from "../findingPane/items/DateItem";
import { SeverityItem } from "../findingPane/items/SeverityItem";
import { SlaItem } from "../findingPane/items/SlaItem";
import { StatusItem } from "./StatusItemFindingDetails";

type Props = {
  finding: Finding | undefined;
  isEditable?: boolean;
  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const TopBoxes = (props: Props) => {
  const {
    finding,
    isEditable,
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const screenWidth = useScreenWidth();
  const isLargeScreen = screenWidth > SCREEN_DESKTOP_WIDTH;

  return (
    <div className="d-flex align-items-center gap-24 flex-wrap">
      <Box
        className="col"
        style={{
          height: "105px",
          width: "300px",
        }}
      >
        <DateItem
          finding={finding}
          headerStyle={isLargeScreen ? "secondary" : "sub"}
          noSeparator
        />
      </Box>
      <Box
        className="col"
        style={{
          height: "105px",
          width: "300px",
        }}
      >
        <SlaItem
          finding={finding}
          headerStyle={isLargeScreen ? "secondary" : "sub"}
          noSeparator
        />
      </Box>
      <Box
        className="col"
        style={{
          position: "relative",
          height: "105px",
          width: "300px",
        }}
      >
        <StatusItem
          finding={finding}
          headerStyle={isLargeScreen ? "secondary" : "sub"}
        />
      </Box>
      <Box
        className="col"
        style={{
          height: "105px",
          width: "300px",
          borderTopRightRadius: "0",
        }}
      >
        <SeverityItem
          finding={finding}
          isEditable={isEditable}
          headerStyle={isLargeScreen ? "secondary" : "sub"}
          noSeparator
          editableUpdateFindingData={editableUpdateFindingData}
          setUpdateFinding={setUpdateFinding}
          setEditableUpdateFinding={setEditableUpdateFinding}
          setCachedFindingData={setCachedFindingData}
        />
      </Box>
    </div>
  );
};
