import { useCallback, useEffect, useState } from "react";
import GoogleButton from "react-google-button";
import MicrosoftLogin from "react-microsoft-login";
import { BASE_API_URL } from "../../../hooks/queries/utils";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Loading } from "../../../components/elements/loading/Loading";
import { useSearchParams } from "react-router-dom";
import { Mixpanel } from "../../../shared/mixpanel";
import { OktaAuth, OktaAuthOptions } from "@okta/okta-auth-js";
import { Modal } from "../../../components/elements/modal/Modal";
import { Flex } from "../../../components/layouts/flex/Flex";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import useToastContext from "../../../hooks/toastHook";
import { HiddenSSOButton } from "./HiddenSSOButton";
import { IS_DEMO_ENV } from "../../../hooks/queries/utils";

import GoogleIcon from "../../../assets/icons/google.svg";
import MicrosoftIcon from "../../../assets/icons/microsoft.svg";
import OktaIcon from "../../../assets/icons/okta-icon.png";

export const MICROSOFT_CLIENT_ID = "84145e02-2557-479a-8cce-ac6106f313a7";
export const redirectUriGoogle = `api/v1/auth/login/google/`;

const redirectUriOkta = `api/v1/auth/login/okta/`;

export const LoginSSO = () => {
  const themeType = localStorage.getItem("wasp-theme");
  const addToast = useToastContext();
  const [searchParams] = useSearchParams();

  const { data: me, isSuccess } = useApiMe();

  const [didMount, setDidMount] = useState(false);
  const [didCheckForErrors, setDidCheckForErrors] = useState(false);
  const [showEnterOktaEmailPopup, setShowEnterOktaEmailPopup] = useState(false);
  const [oktaEmailAddress, setOktaEmailAddress] = useState("");
  const [inProgress, setInProgress] = useState(false);

  if (isSuccess) {
    const afterLoginRoute = `/`;
    Mixpanel.track(`login`);
    if (me?.customer) window.location.replace(afterLoginRoute);
  }

  const redirectUriMicrosoft = `${window.location.origin}/login`;
  const originalUrl = searchParams.get("next");

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id:
        "682747812624-9rmgt1hb5g46uh1r4ms9ie3krjq715ss.apps.googleusercontent.com",
      redirect_uri:
        `${window.location.protocol || "http:"}//` +
        `${window.location.host}/${redirectUriGoogle}`,
      prompt: "select_account",
      access_type: "offline",
      state: originalUrl || "",
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location.replace(`${googleAuthUrl}?${urlParams}`);
  }, [originalUrl]);

  useEffect(() => {
    if (didCheckForErrors) return;
    const queryParams = new URLSearchParams(window.location.search);
    const error = queryParams.get("error");
    if (!error) return;
    sessionStorage.clear();
    console.log(error);
    addToast({ type: "error", message: error });
    setDidCheckForErrors(true);
  }, [addToast, didCheckForErrors]);

  useEffect(() => {
    if (!me?.errmsg || didMount) return;
    addToast({ type: "error", message: me.errmsg });
    sessionStorage.clear();
    setDidMount(true);
  }, [me, addToast, didMount]);

  const microsoftAuthHandler = (err: any, data: any) => {
    if (err) {
      console.log(err);
    } else {
      window.location.replace(
        `${BASE_API_URL}/auth/login/microsoft/?` +
          new URLSearchParams({
            email: data.account.userName,
            name: data.account.name,
            access_token: data.accessToken,
            state: originalUrl || "",
          })
      );
    }
    console.log(err, data);
  };
  const closeOktaPopup = () => {
    setShowEnterOktaEmailPopup(false);
    setInProgress(false);
  };

  const getOktaTenantSsoInfo = () => {
    fetch(`${BASE_API_URL}/auth/okta_sso_info/?email=${oktaEmailAddress}`)
      .then((res) => res.json())
      .then((data) => {
        const { org_url, client_id } = data;
        if (!org_url || !client_id) {
          addToast({
            type: "error",
            message:
              "Failed to get Okta tenant SSO info, make sure your email is whitelisted.",
          });
          closeOktaPopup();
          return;
        }
        const oktaConfig: OktaAuthOptions = {
          issuer: `${org_url}/oauth2`,
          clientId: client_id,
          redirectUri: `${window.location.origin}/${redirectUriOkta}`,
          // Use authorization_code flow
          scopes: ["openid", "profile", "email"],
          state: JSON.stringify({
            original_url: originalUrl || "",
            email: oktaEmailAddress,
          }),
          pkce: false,
          responseType: ["code"],
        };
        const oktaAuthClient = new OktaAuth(oktaConfig);
        oktaAuthClient.token.getWithRedirect({
          responseType: ["code"],
          scopes: ["openid", "profile", "email"],
        });
      });
  };

  return (
    <>
      {showEnterOktaEmailPopup && (
        <Modal
          onClose={closeOktaPopup}
          header="Enter Your Email Address"
          width="420px"
        >
          <Flex column justify="center" w100 gap="24px">
            <InputText
              width="100%"
              type="email"
              placeholder="me@mydomain.com"
              onChange={(e) => setOktaEmailAddress(e.target.value)}
            />
            <Flex align="center" justify="between" w100>
              <SecondaryButton
                label="Cancel"
                onClick={closeOktaPopup}
                size="medium"
              />
              <MainButton
                label="Send"
                onClick={getOktaTenantSsoInfo}
                size="medium"
              />
            </Flex>
          </Flex>
        </Modal>
      )}
      {inProgress && <Loading />}
      <Flex column>
        <HiddenSSOButton
          title="Continue with Google"
          background="transparent"
          color={themeType !== "dark" ? "black" : "white"}
          onClick={() => {
            document.getElementById("google-sso")?.click();
            setInProgress(true);
          }}
          icon={GoogleIcon}
        >
          <GoogleButton
            id="google-sso"
            type="light"
            onClick={openGoogleLoginPage}
            label="Sign in with Google"
            style={{
              zIndex: -1,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              opacity: 0,
            }}
          />
        </HiddenSSOButton>

        <HiddenSSOButton
          title="Continue with Microsoft"
          background="transparent"
          color={themeType !== "dark" ? "black" : "white"}
          onClick={() => {
            document.getElementById("microsoft-sso")?.click();
            setInProgress(true);
          }}
          icon={MicrosoftIcon}
        >
          <MicrosoftLogin
            redirectUri={redirectUriMicrosoft}
            clientId={MICROSOFT_CLIENT_ID}
            buttonTheme="light"
            authCallback={microsoftAuthHandler}
            useLocalStorageCache={false}
            // @ts-ignore
            children={
              <div
                id="microsoft-sso"
                style={{
                  opacity: "0",
                  zIndex: "0",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            }
          />
        </HiddenSSOButton>
        {!IS_DEMO_ENV && (
          <HiddenSSOButton
            title="Continue with Okta"
            background="transparent"
            color={themeType !== "dark" ? "black" : "white"}
            onClick={() => {
              document.getElementById("okta-sso")?.click();
              setInProgress(true);
            }}
            icon={OktaIcon}
          >
            <button
              style={{
                opacity: "0",
                zIndex: "0",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              id="okta-sso"
              onClick={() => setShowEnterOktaEmailPopup(true)}
            ></button>
          </HiddenSSOButton>
        )}
      </Flex>
    </>
  );
};
