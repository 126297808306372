import { CSSProperties, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../elements/badge/Badge";
import { IconButton } from "../../elements/button/icon/IconButton";
import { Flex } from "../../layouts/flex/Flex";
import { SingleValue } from "react-select";
import { Option } from "../../elements/dropdowns/Dropdown";

type Props = {
  tag?: string;
  option?: SingleValue<Option>;
  isEditable?: boolean;
  isDisabled?: boolean;
  style?: CSSProperties;
  onDeleteOption?: (opt: SingleValue<Option>) => void;
  onDeleteTag?: (tag: string) => void;
  onClick?: (opt?: SingleValue<Option>) => void;
};

export const TagBadge = (props: Props) => {
  const {
    option,
    tag,
    isEditable,
    style,
    isDisabled,
    onDeleteTag,
    onDeleteOption,
    onClick,
  } = props;

  const theme = useContext(ThemeContext);

  const onClickOnX = () =>
    onDeleteOption
      ? option && onDeleteOption(option)
      : onDeleteTag && tag && onDeleteTag(tag);

  return (
    <Badge
      style={{
        marginRight: "8px",
        backgroundColor: theme.blue500,
        cursor: !!onClick ? "pointer" : "default",
        ...style,
      }}
      onClick={() => !!onClick && onClick()}
    >
      <Flex align="center" gap="8px" h100>
        <Flex
          className=" text-truncate"
          align="center"
          style={{
            color: theme.blue800,
            maxWidth: "300px",
          }}
        >
          {option?.label || tag}
        </Flex>
        {isEditable && (
          <IconButton
            color={theme.blue800}
            size="very-small"
            iconName="cancel"
            onClick={onClickOnX}
            dataTestId={`delete-tag-${option?.label || tag}`}
            disabled={isDisabled}
          />
        )}
      </Flex>
    </Badge>
  );
};
