import { useState } from "react";
import { DatePicker } from "../../../../components/elements/datetimePicker/DatePicker";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { dateOptions } from "../FiltersUtils";

type Props = {
  onChange: (value: string) => void;
  filterValue: string;
};

export const FilterDatePicker = (props: Props) => {
  const { onChange, filterValue } = props;

  const getDefaultOption = () => {
    if (!filterValue) return undefined;
    if (dateOptions.map((o) => o.value.toString()).includes(filterValue))
      return parseInt(filterValue);
    return -1;
  };

  const [selectedOption, setSelectedOption] = useState(getDefaultOption());

  return (
    <>
      {selectedOption === -1 ? (
        <DatePicker
          value={filterValue ? filterValue.substring(0, 10) : ""}
          onChange={(e) => onChange(e.target.value)}
          width="100%"
          dataTestId="filter-date-picker"
        />
      ) : (
        <Dropdown
          variant="border"
          size="medium"
          width="100%"
          dataTestId="filter-date-dropdown"
          onChange={(option) => {
            setSelectedOption(parseInt(option?.value.toString() || "-1"));
            if (option?.value.toString() !== "-1") {
              onChange(option?.value.toString() || "");
            }
          }}
          placeholder="Select time"
          value={dateOptions.find((c) => c.value === selectedOption)}
          options={dateOptions}
        />
      )}
    </>
  );
};
