import React from "react";
import { IconProps } from "./WaspIcon";

export function FixedIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6039 3.99609H9.39609C7.9644 3.99765 6.5918 4.56708 5.57944 5.57944C4.56708 6.5918 3.99765 7.9644 3.99609 9.39609V14.6039C3.99765 16.0356 4.56708 17.4082 5.57944 18.4206C6.5918 19.4329 7.9644 20.0023 9.39609 20.0039H14.6039C16.0356 20.0023 17.4082 19.4329 18.4206 18.4206C19.4329 17.4082 20.0023 16.0356 20.0039 14.6039V9.39609C20.0023 7.9644 19.4329 6.5918 18.4206 5.57944C17.4082 4.56708 16.0356 3.99765 14.6039 3.99609ZM18.8039 14.6039C18.8026 15.7174 18.3596 16.7849 17.5723 17.5723C16.7849 18.3596 15.7174 18.8026 14.6039 18.8039H9.39609C8.2826 18.8026 7.21509 18.3596 6.42773 17.5723C5.64037 16.7849 5.19744 15.7174 5.19609 14.6039V9.39609C5.19744 8.2826 5.64037 7.21509 6.42773 6.42773C7.21509 5.64037 8.2826 5.19744 9.39609 5.19609H14.6039C15.7174 5.19744 16.7849 5.64037 17.5723 6.42773C18.3596 7.21509 18.8026 8.2826 18.8039 9.39609V14.6039ZM14.8547 9.8373C14.9871 9.92557 15.079 10.0628 15.1102 10.2189C15.1414 10.3749 15.1094 10.5369 15.0211 10.6693L13.2973 13.2563C13.0924 13.5639 12.8152 13.8166 12.4899 13.9922C12.1646 14.1678 11.8013 14.261 11.4317 14.2635H11.4152C11.0483 14.2636 10.6869 14.1745 10.3621 14.0038C10.0372 13.8332 9.75882 13.586 9.5508 13.2838L8.98362 12.457C8.89349 12.3258 8.85915 12.1642 8.88816 12.0078C8.91716 11.8513 9.00715 11.7127 9.1383 11.6226C9.26946 11.5325 9.43105 11.4981 9.58753 11.5271C9.74401 11.5562 9.88255 11.6461 9.97268 11.7773L10.5399 12.6035C10.6366 12.7461 10.7669 12.8626 10.9194 12.9429C11.0719 13.0231 11.2418 13.0645 11.4141 13.0634H11.4223C11.5961 13.0634 11.7672 13.0201 11.9202 12.9376C12.0732 12.8551 12.2033 12.7359 12.2988 12.5906L14.0227 10.0037C14.1112 9.87158 14.2484 9.77988 14.4043 9.74869C14.5603 9.7175 14.7222 9.74936 14.8547 9.8373H14.8547Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
