import { useState } from "react";
import { useParams } from "react-router";
import { useApiProjects } from "../../../hooks/queries/projectsContext";

import { FirstPage } from "../generator/FirstPage";
import { AboutPage } from "../generator/AboutPage";
import { useApiFindings } from "../../../hooks/queries/findingContext";
import { PageLayout } from "../generator/PageLayout";
import { WelcomeBanner } from "../../../components/composed/banner/WelcomeBanner";

import { useQueryClient } from "@tanstack/react-query";
import { Flex } from "../../../components/layouts/flex/Flex";
import { BodyBold } from "../../../components/elements/typography/Typography";
import { Modal } from "../../../components/elements/modal/Modal";
import { useCountDown, usePrint, useWaitLoading } from "../generator/hooks";
import { AttestationExecutiveSummary } from "./AttestationExecutiveSummary";
import { STATUSES } from "../../../types/Finding";
import { useApiMe } from "../../../hooks/queries/meContext";

type Props = {};
const WAITING_TIME = 3; //seconds
export const AttestationLetterReport = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const { data: projects, isFetching: isFetchingProject } = useApiProjects();
  const [loadingData, setLoadingData] = useState(true);
  const [countdown, setCountdown] = useState<number>(WAITING_TIME);
  const project = projects?.find((p) => p.id === parseInt(id || "0"));
  const { data: findings, isFetching: isFetchingFindings } = useApiFindings(
    {
      page_size: 1000,
      project: parseInt(id || "0"),
      all_data: true,
      no_prefetch_assets: true,
      status: STATUSES.filter((s) => s !== "dismissed").join(","), // Exclude dismissed findings
      is_false_positive: false, // Exclude false positive findings
      ordering: "-overall_risk",
    },
    !!project
  );

  const { data: me } = useApiMe();
  const isMultiTenantReport =
    me?.customer.is_multi_tenant && project?.is_self_managed;

  const queryClient = useQueryClient();
  // Check if there are any background fetching requests
  const isFetching = queryClient.isFetching();

  const isPageLoaded = !!(
    project &&
    !!findings &&
    !isFetching &&
    !isFetchingFindings
  );
  useWaitLoading(WAITING_TIME, () => setLoadingData(false), isPageLoaded);

  const isReadyToPrint = !!(!loadingData && project);
  usePrint(isReadyToPrint, `Attestation-Letter-Report-${project?.name}`);
  useCountDown(setCountdown);

  if (isFetchingProject || isFetchingFindings || isFetching) {
    return <WelcomeBanner />;
  }

  return (
    <PageLayout isMultiTenantReport={isMultiTenantReport}>
      {loadingData && (
        <Modal onClose={() => setLoadingData(false)} hideCloseIcon>
          <Flex align="center" gap="6px">
            <BodyBold>{`Generating PDF report in ${
              countdown > 0 ? countdown : "just a sec"
            }...`}</BodyBold>
          </Flex>
        </Modal>
      )}
      <FirstPage project={project} reportType="Attestation Letter" />
      {!isMultiTenantReport && <AboutPage />}
      {!!project && (
        <AttestationExecutiveSummary
          project={project}
          findings={findings || []}
        />
      )}
    </PageLayout>
  );
};
