import React, { useCallback, useContext, useEffect, useState } from "react";
import { QuickScanResult } from "../../types/QuickScanResult";
import { Flex } from "../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import {
  BodyMedium,
  BodyRegular,
  HeaderMain,
  HeaderSecondary,
  HeaderSubBold,
} from "../../components/elements/typography/Typography";
import beeLeft from "../../assets/images/beeLookingLeft.png";
import beeRight from "../../assets/images/beeLookingRight.png";

import { Box } from "../../components/elements/box/Box";
import { Icon } from "../../components/elements/icon/Icon";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { QuickScanResultsView } from "./QuickScanResultsView";
import { Mixpanel } from "../../shared/mixpanel";
import { Illustration } from "../../components/elements/illustrations/Illustration";
import OPWhiteLogo from "../../assets/images/OP-Logo-White.png";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";
import { InputTextWithLeftAddon } from "../../components/elements/input/textInput/InputTextWithLeftAddon";
import { HexagonsBackground } from "./HexagonsBackground";

export const QuickScan = () => {
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  // get domain and email from query params
  const searchParams = new URLSearchParams(window.location.search);
  const theme = useContext(ThemeContext);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [scanResult, setScanResult] = useState<QuickScanResult | null>(null);
  const [domain, setDomain] = useState<string>(
    searchParams.get("domain") || ""
  );
  const email = searchParams.get("email") || null;
  // use hook to make it run only once
  const [initialDomain] = useState<string>(searchParams.get("domain") || "");
  const updateURLParams = (newParams: Record<string, string>) => {
    const searchParams = new URLSearchParams(window.location.search);
    Object.keys(newParams).forEach((key) =>
      searchParams.set(key, newParams[key])
    );
    const newRelativePathQuery =
      window.location.pathname + "?" + searchParams.toString();
    window.history.pushState(null, "", newRelativePathQuery);
  };

  const startScan = useCallback((domain: string, email: string | null) => {
    setError(null);
    setScanResult(null);
    if (!domain) return;
    Mixpanel.track("Quick Scan started", { domain, email });
    setLoading(true);
    fetch(`/api/v1/quick-scan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        domain,
      }),
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => setScanResult(data));
        } else {
          setError("Failed to scan");
          console.error(res);
        }
        setLoading(false);
      })
      .catch((err) => setError(err.message));
  }, []);

  useEffect(() => {
    setLoading(false);
    if (!initialDomain) return;
    startScan(initialDomain, email);
  }, [initialDomain, email, startScan]);

  return (
    <Flex column w100>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: isMobile ? "500px" : "400px",
          width: "100%",
          backgroundColor: theme.primary,
        }}
      ></div>

      <div
        style={{
          position: "relative",
        }}
      >
        <HexagonsBackground />
      </div>
      {!isMobile && (
        <img
          src={beeLeft}
          alt=""
          style={{
            position: "absolute",
            top: "5%",
            right: "5%",
          }}
        />
      )}
      {!isMobile && (
        <img
          src={beeRight}
          alt=""
          style={{ position: "absolute", bottom: "100px", left: "48px" }}
        />
      )}

      <Flex column justify="between" h100 w100 padding="24px">
        <Flex column w100 gap="48px">
          <Flex
            w100
            justify="between"
            style={{
              zIndex: 2,
            }}
            padding={isMobile ? "5px" : "0 15%"}
            align="center"
            gap="16px"
          >
            <img src={OPWhiteLogo} alt="op-white-logo" width={"100px"} />
            <SecondaryButton
              label="GET STARTED"
              size="small"
              variant="white"
              style={{
                height: "40px",
                backgroundColor: theme.primary,
                minWidth: "150px",
              }}
              iconName="play"
              onClick={() => window.location.assign("/register")}
            />
          </Flex>
          <Flex
            w100
            gap="24px"
            column
            align="center"
            style={{
              zIndex: 2,
              color: theme.white100,
            }}
          >
            <Flex
              justify="center"
              w100
              column
              gap="16px"
              align="center"
              className="text-center"
            >
              <HeaderMain
                style={{
                  color: theme.black500,
                  fontSize: isMobile ? "20px" : "",
                }}
              >
                Welcome to WASP
              </HeaderMain>
              <HeaderMain
                style={{
                  fontSize: isMobile ? "24px" : "48px",
                  color: theme.white100,
                }}
              >
                Scan Your Web Application
              </HeaderMain>
              <HeaderSecondary
                style={{
                  fontSize: isMobile ? "16px" : "24px",
                }}
                color={theme.black500}
              >
                Check your website grade or{" "}
                <a style={{ color: theme.white100 }} href="/register">
                  join WASP
                </a>{" "}
                for the full experience.
              </HeaderSecondary>
            </Flex>
            <Box
              style={{
                width: isMobile ? "99%" : "70%",
                borderRadius: "16px 0 16px 16px",
              }}
            >
              <Flex column justify="start" gap="16px">
                <Flex gap="8px" align="center">
                  <Icon name="globe" size={24} color={theme.blue600} />
                  <HeaderSubBold>Enter Domain To Scan</HeaderSubBold>
                </Flex>
                <Flex
                  column={isMobile}
                  align="center"
                  w100
                  justify="between"
                  gap="8px"
                >
                  <InputTextWithLeftAddon
                    leftText="https://"
                    width="100%"
                    value={domain}
                    disabled={loading}
                    placeholder="app.example.com"
                    onChange={(e) => {
                      setDomain(e.target.value);
                      updateURLParams({ domain: e.target.value });
                    }}
                    onKeyDown={() => startScan(domain, email)}
                  />
                  <MainButton
                    label="Scan"
                    onClick={() => startScan(domain, email)}
                    disabled={!domain || loading}
                    size="medium"
                  />
                </Flex>
              </Flex>
            </Box>
            <Flex
              w100
              column
              padding={scanResult ? "" : "48px 0"}
              justify="center"
              align="center"
            >
              {!!error && (
                <Flex padding="24px">
                  <Flex
                    align="center"
                    gap="8px"
                    padding="16px"
                    style={{
                      border: `1px solid ${theme.redPrimary}`,
                      borderRadius: "8px",
                      backgroundColor: theme.red50,
                    }}
                  >
                    <Icon
                      color={theme.redPrimary}
                      name={"activeIssues"}
                      size={32}
                    />
                    <HeaderSubBold color={theme.redPrimary}>
                      {error}
                    </HeaderSubBold>
                  </Flex>
                </Flex>
              )}
              {scanResult ? (
                <QuickScanResultsView scanResult={scanResult} />
              ) : (
                <Flex justify="center" w100 column gap="8px" align="center">
                  <Illustration name={loading ? "search" : "wip"} />
                  <HeaderSecondary className="text-center">
                    {loading
                      ? "Loading your results"
                      : "No domain was scanned yet"}
                  </HeaderSecondary>
                  <BodyRegular>
                    {loading
                      ? "This process may take several seconds..."
                      : "Scan your domain and see your risks"}
                  </BodyRegular>
                </Flex>
              )}
              {loading && (
                <Flex w100 h100 justify="center" align="center">
                  <Icon size={50} name="spinner" />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex w100 justify="center">
          <BodyMedium color={theme.white100}>Powered by OP Innovate</BodyMedium>
        </Flex>
      </Flex>
    </Flex>
  );
};
