import { ReactNode, useEffect, useRef, useState } from "react";
import { IconButton } from "../button/icon/IconButton";

export const HorizontalScroller = ({ children }: { children: ReactNode }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkOverflow = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
      setIsOverflowing(scrollWidth > clientWidth);
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", checkOverflow);
    }

    return () => {
      window.removeEventListener("resize", checkOverflow);
      if (container) {
        container.removeEventListener("scroll", checkOverflow);
      }
    };
  }, [children]); // Run whenever items change

  const scroll = (direction: "left" | "right") => {
    if (containerRef.current) {
      const { scrollLeft, clientWidth } = containerRef.current;
      const scrollAmount = clientWidth / 2; // Scroll half the container width
      //@ts-ignore
      containerRef?.current?.scrollTo({
        left:
          direction === "left"
            ? scrollLeft - scrollAmount
            : scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      data-testid="scroll-container"
      style={{
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        display: "flex",
      }}
    >
      {isOverflowing && (
        <IconButton
          dataTestId="scroll-left"
          iconName="chevronLeft"
          onClick={() => scroll("left")}
          disabled={!!!canScrollLeft}
        />
      )}
      <div
        ref={containerRef}
        style={{
          display: "flex",
          overflowX: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          width: "100%",
        }}
      >
        {children}
      </div>
      {isOverflowing && (
        <IconButton
          dataTestId="scroll-right"
          iconName="chevronRight"
          onClick={() => scroll("right")}
          disabled={!!!canScrollRight}
        />
      )}
    </div>
  );
};
