import { useMutation } from "@tanstack/react-query";
import { createItem } from "./sdk";

const key = "contact_sales";

export const useApiContactSales = () => {
  return useMutation<any, Error, any>({
    mutationKey: [key],
    mutationFn: async (salesCall): Promise<any> =>
      await createItem(key, salesCall),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
  });
};
