import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
type Props = {
  isRead: boolean;
};
export const NewNotificationTip = (props: Props) => {
  const { isRead } = props;
  const theme = useContext(ThemeContext);

  return (
    <div
      style={{
        minHeight: "6px",
        minWidth: "6px",
        maxHeight: "6px",
        maxWidth: "6px",
        backgroundColor: isRead ? theme.white : theme.redPrimary,
        borderRadius: "50%",
        position: "relative",
        top: "10px",
      }}
    />
  );
};
