import { useQuery } from "@tanstack/react-query";
import { getItems } from "./sdk";

const superUsersKey = "users/superusers/";

export type OpUser = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar_url: string;
  is_superuser: boolean;
};

export const useApiSuperUsers = (
  params?: { [key: string]: any },
  enabled = true
) =>
  useQuery<OpUser[], Error>({
    queryKey: [superUsersKey],
    keepPreviousData: true,
    placeholderData: [],
    enabled: enabled,
    queryFn: async (): Promise<OpUser[]> => getItems(superUsersKey, params),
  });
