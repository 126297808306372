import React, { useContext } from "react";
import { useReactFlow } from "@xyflow/react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { GroupButton } from "../../../../components/elements/button/group/GroupButton";
import { ThemeContext } from "styled-components";

type Props = {
  right?: number;
  bottom?: number;
};
export const CustomControls = ({ right, bottom }: Props) => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const theme = useContext(ThemeContext);

  return (
    <div
      style={{
        position: "absolute",
        right: right || 0,
        bottom: bottom || 0,
        zIndex: 1000,
        backgroundColor: theme.bg2,
        borderRadius: "16px",
        padding: "4px",
      }}
    >
      <Flex gap="16px" align="center">
        <Tooltip placement="right" content="Zoom to fit">
          <IconButton
            size="small"
            iconName="reverse"
            color={theme.primary}
            id="zoom-reset-btn"
            onClick={fitView}
          />
        </Tooltip>

        <GroupButton
          buttonsProps={[
            {
              iconName: "zoomIn",
              onClick: zoomIn,
              id: "zoom-in-btn",
            },
            {
              iconName: "zoomOut",
              onClick: zoomOut,
              id: "zoom-out-btn",
            },
          ]}
        />
      </Flex>
    </div>
  );
};
