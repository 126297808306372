import styled from "styled-components";

export type InputProps = {
  isError?: boolean;
  iconName?: string;
  inverted?: boolean;
  width?: string | number;
  border?: string;
  color?: string;
  initValue?: string;
  saveChangesMode?: boolean;
  isClearable?: boolean;
  variant?: "body" | "header";
};

export const InputStyle = styled.input<InputProps>`
  width: ${(props) => props.width || "inherit"}
  height: ${(props) => (props.variant === "header" ? "" : "40px")};

  font-size: ${(props) => (props.variant === "header" ? "32px" : "14px")};
  font-weight: ${(props) => (props.variant === "header" ? "600" : "400")};
  line-height: ${(props) => (props.variant === "header" ? "48px" : "21px")};

  transition: 0.2s;
  border-radius: 0.6em;

  color: ${(props) =>
    props.isError
      ? props.theme.redPrimary
      : props.inverted
        ? props.theme.textWhite
        : props.variant === "header"
          ? props.theme.textHeader
          : props.color || props.theme.textBody};

  border: ${(props) =>
    props.isError
      ? `1px solid ${props.theme.redPrimary}`
      : props.border
        ? props.border
        : `1px solid ${
            props.inverted ? props.theme.white30 : props.theme.black500
          }`};

  padding: ${(props) => (props.variant === "header" ? "0px 24px" : "8px 16px")};

  /* If changed value, set background blue*/
  /* initValue can be empty string */
  background-color: ${(props) => (props.initValue !== null && props.initValue !== undefined && props.initValue !== props.value ? props.theme.blue50 : "inherit")};

  /* Make some room for the icon on the left */
  ${(props) =>
    !!props.iconName &&
    `padding-left:  ${props.variant === "header" ? "54px" : "40px"};
    background-size: 14px;
    `}

  /* Make some room for the icon on the right */
  ${(props) =>
    props.saveChangesMode &&
    `padding-right: ${props.variant === "header" ? "120px" : "78px"};`}
  ${(props) =>
    props.isClearable &&
    `padding-right: ${props.variant === "header" ? "60px" : "40px"};`}


  &:hover:enabled {
    border-color: ${(props) =>
      props.inverted ? props.theme.white80 : props.theme.primary};
  }
  &:focus {
    outline: none;
    border-color: ${(props) =>
      props.inverted ? props.theme.white100 : props.theme.primary};

    color: ${(props) =>
      props.inverted ? props.theme.white100 : props.theme.textHeader};
  }
  &:disabled {
    background-color: ${(props) =>
      props.inverted ? "inherit" : props.theme.separation};
    opacity: ${(props) => (props.inverted ? 0.5 : 1)};
  }
  &::placeholder {
    color: ${(props) =>
      props.inverted ? props.theme.white80 : props.theme.textSub};
  }


  /* Hide the default arrows in most browsers */
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;

  /* Hide spinners in Firefox */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
