import { InputText } from "../../../../components/elements/input/textInput/InputText";

type Props = {
  type: "text" | "number";
  onChange: (value: string) => void;
  filterValue: string;
};

export const FilterTextInput = (props: Props) => {
  const { type, onChange, filterValue } = props;
  return (
    <InputText
      placeholder="Enter value"
      value={filterValue || ""}
      onChange={(e) => onChange(e.target.value)}
      width="100%"
      type={type}
      dataTestId="filter-value-input"
    />
  );
};
