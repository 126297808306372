import { useState, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../../components/elements/icon/Icon";
import { getStatusName } from "../../../../shared/findingsHelper";
import { Finding } from "../../../../types/Finding";
import { FindingStatusMenu } from "../../findingStatus/FindingStatusMenu";
import { Section } from "../../../../components/elements/section/Section";
import { HeaderSubHover } from "../../../../components/elements/typography/Typography";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
};

export const StatusItem = (props: Props) => {
  const { finding, noSeparator = false } = props;
  const theme = useContext(ThemeContext);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <Section title="Status" noSeparator={noSeparator}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center gap-8 w-100">
          <Icon
            name={finding?.status || "spinner"}
            size={32}
            color={theme.primary}
          />
          <HeaderSubHover
            style={{ color: theme.black700 }}
            className="text-truncate w-100"
            title={getStatusName(finding?.status || "presented")}
            onClick={() => setShowMenu(!showMenu)}
          >
            {getStatusName(finding?.status || "presented")}
          </HeaderSubHover>
        </div>
        <div className="flex-item">
          {showMenu && (
            <FindingStatusMenu
              close={() => setShowMenu(!showMenu)}
              finding={finding}
              customStyle={{ top: "auto" }}
            />
          )}
        </div>
      </div>
    </Section>
  );
};
