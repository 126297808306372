import React from "react";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { Flex } from "../../../../components/layouts/flex/Flex";

export const AssetIsolateDetailsColumnSkeleton = () => {
  return (
    <Flex
      column
      gap="20px"
      style={{
        width: "240px",
      }}
    >
      <SkeletonLoading width="100%" height="20px" />
      <SkeletonLoading width="100px" height="24px" />
      <SkeletonLoading width="100%" height="48px" />
      <SkeletonLoading width="100%" height="48px" />
      <SkeletonLoading width="100%" height="48px" />
      <SkeletonLoading width="100%" height="48px" />
    </Flex>
  );
};
