import React from "react";
import LegendItem from "../../../components/elements/legend/RiskLegendItem";

export interface IFindingsLegend {
  total?: boolean;
  info?: boolean;
  low: boolean;
  medium: boolean;
  high: boolean;
  critical: boolean;
}

const FindingsLegend = ({
  legend,
  setLegend,
}: {
  legend: IFindingsLegend;
  setLegend: React.Dispatch<React.SetStateAction<IFindingsLegend>>;
}) => {
  const handleLegendClick = (label: string) => {
    let newLegend: IFindingsLegend = { ...legend };
    newLegend[label as keyof IFindingsLegend] =
      !legend[label as keyof IFindingsLegend];
    setLegend(newLegend);
  };

  return (
    <div className="d-flex justify-content-around gap-24" data-testid="legend">
      {Object.keys(legend).map((label, i) => (
        <LegendItem
          key={i}
          label={label}
          isDisabled={!legend[label as keyof IFindingsLegend]}
          handleLegendClick={handleLegendClick}
        />
      ))}
    </div>
  );
};

export default FindingsLegend;
