import { isFindingValid } from "../../../shared/helper";
import { Finding } from "../../../types/Finding";

export const calcCweStats = (findings: Finding[]) => {
  let cweStats: { [index: string]: number } = {};
  findings.forEach((finding) => {
    if (!isFindingValid(finding)) return;
    if (!finding.cwe_id) return;
    if (finding.cwe_id in cweStats) cweStats[finding.cwe_id] += 1;
    else cweStats[finding.cwe_id] = 1;
  });
  return cweStats;
};
