import React, { useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import {
  HeaderSecondary,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  objectToOption,
  objectsToOptions,
  customersPriorityTierOptions,
} from "../../../shared/formUtils";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import { useApiAsmAnalytics } from "../../../hooks/queries/findingContext";
import { getRelevantCustomers, getRelevantCustomersIds } from "../utils";
import { PriorityTier } from "../../../types/Customer";

export const ValidFindingsWidget = () => {
  const { data: customers } = useApiCustomers();
  const { data: asmAnalytics } = useApiAsmAnalytics();
  const [selectedCustomer, setCustomer] = useState<number | string | undefined>(
    "all"
  );
  const [selectedTier, setTier] = useState<PriorityTier | "all">("all");
  const calcValidFindings = (): number | string => {
    if (!asmAnalytics) return "--";

    let validCount = 0;
    const data = asmAnalytics.current_data.filter((i) =>
      getRelevantCustomersIds(customers || [], selectedTier).includes(
        i.customer_id
      )
    );

    if (selectedCustomer === "all") {
      validCount = data.reduce((sum, item) => sum + item.valid_count, 0);
    } else {
      const foundItem = data.find(
        (item) => item.customer_id === selectedCustomer
      );
      validCount = foundItem ? foundItem.valid_count : 0;
    }

    return validCount;
  };

  return (
    <Box style={{ width: "50%" }}>
      <Flex column w100 gap="16px">
        <Flex justify="center" w100>
          <HeaderSecondary>Num. of Valid Findings</HeaderSecondary>
        </Flex>
        <Flex justify="between" align="center">
          <Flex>
            <Dropdown
              searchable
              placeholder="Select customer"
              onChange={(opt) => {
                if (opt?.value) {
                  setCustomer(opt.value);
                  setTier("all");
                }
              }}
              value={
                selectedCustomer === "all"
                  ? { label: "All customers", value: "all" }
                  : customers?.filter((c) => c.id === selectedCustomer)[0]
                    ? objectToOption(
                        customers?.filter((c) => c.id === selectedCustomer)[0]
                      )
                    : null
              }
              options={
                customers
                  ? [
                      { label: "All customers", value: "all" },
                      ...objectsToOptions(getRelevantCustomers(customers)),
                    ]
                  : []
              }
            />
            <Dropdown
              searchable
              placeholder="Select tier"
              onChange={(opt) => {
                if (opt?.value) {
                  setCustomer("all");
                  setTier(opt.value as PriorityTier);
                }
              }}
              value={
                customersPriorityTierOptions.filter(
                  (t) => t.value === selectedTier
                )[0]
              }
              options={customersPriorityTierOptions}
            />
          </Flex>
        </Flex>
        <Flex w100 justify="center" align="center">
          <HeaderMain
            style={{
              fontSize: "100px",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            {calcValidFindings()}
          </HeaderMain>
        </Flex>
      </Flex>
    </Box>
  );
};
