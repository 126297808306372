import React, { useContext, useState } from "react";
import { useApiFindingsTrends } from "./findingsTrendsContext";
import { FindingStatus, FindingType } from "../../../types/Finding";
import { Flex } from "../../../components/layouts/flex/Flex";
import { LineChart } from "../../../components/composed/charts/LineChart";
import { ThemeContext } from "styled-components";
import { TrendFiltersBar } from "./TrendFiltersBar";

export type TrendFilters = {
  product_id: number | "All";
  finding_type: FindingType | "All";
  status: FindingStatus | "All";
  risk: number | "All";
};

export const FindingsTrends = () => {
  const theme = useContext(ThemeContext);
  const [trendFilters, setFilters] = useState<TrendFilters>({
    product_id: "All",
    finding_type: "All",
    status: "All",
    risk: "All",
  });
  const { data: trends } = useApiFindingsTrends(trendFilters);

  const trendDates = trends ? trends.map((t) => t.date) : [];
  const trendCounts = trends ? trends.map((t) => t.total_count) : [];

  const onChangeFilters = (trendFilters: TrendFilters): void =>
    setFilters(trendFilters);

  return (
    <Flex w100 h100 column gap="16px">
      <TrendFiltersBar
        trendFilters={trendFilters}
        onChangeFilters={onChangeFilters}
      />
      <LineChart
        datasets={[
          {
            label: "Total",
            data: trendCounts,
            borderColor: theme.primary,
          },
        ]}
        labels={trendDates}
        height={500}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          tension: 0.3,
          plugins: {
            legend: {
              display: false,
            },
            title: { display: false },
            tooltip: {
              backgroundColor: theme.chartsTooltip,
              bodyColor: theme.textBody,
              titleColor: theme.textHeader,
              usePointStyle: true,
              cornerRadius: 0,
              boxHeight: 7,
              boxPadding: 12,
              callbacks: {
                label: (context: any) =>
                  `${context.dataset.label}: ${context.parsed.y}`,
              },
            },
          },
        }}
      />
    </Flex>
  );
};
