import { Flex } from "../../components/layouts/flex/Flex";
import { Box } from "../../components/elements/box/Box";
import { HeaderSecondary } from "../../components/elements/typography/Typography";

export const AssetsExpired = () => {
  return (
    <Flex w100 align="center" style={{ height: "250px" }}>
      <Box
        style={{
          height: "100%",
        }}
      >
        <HeaderSecondary style={{ padding: "32px" }}>
          To ensure the security of sensitive data on the WASP platform, access
          to certain features is removed when licenses expire. To regain access,
          please contact our support team at sales@op-c.net
        </HeaderSecondary>
      </Box>
    </Flex>
  );
};
