import React, { useContext } from "react";
import { Box } from "../../../../components/elements/box/Box";
import { ThemeContext } from "styled-components";
import { Chart } from "regraph";
import { formatDataForReGraphTechs } from "./RegraphUtils";
import { useApiReGraphItems } from "./context/ReGraphContext";
import { Loading } from "../../../../components/elements/loading/Loading";

export const AssetsReGraphTechs = () => {
  const theme = useContext(ThemeContext);
  var reGraphItems = {};
  const { data, isFetching } = useApiReGraphItems();

  if (!isFetching) {
    reGraphItems = formatDataForReGraphTechs(data);
  }

  return (
    <Box
      className="d-flex flex-column w-100"
      style={{
        position: "relative",
        height: `calc(100vh - 260px)`,
        cursor: "grab",
        backgroundColor: theme.white100,
      }}
    >
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <Chart
            items={reGraphItems}
            layout={{
              tightness: 1,
              linkShape: "curved",
              name: "lens",
              stretch: 2,
            }}
            options={{ logo: { image: "/favicon.ico", position: "ne" } }}
          />
        </>
      )}
    </Box>
  );
};
