import styled from "styled-components";
import {
  TypographyBodyBold,
  TypographyBodyMedium,
  TypographyHeaderSub,
} from "../../typography/Typography";
type ButtonProps = {
  size: string;
  variant?: "primary" | "danger" | "secondary";
};

const getPadding = (size: string) => {
  if (size === "small") {
    return "8px 16px";
  }
  if (size === "medium") {
    return "12px 24px";
  }
  if (size === "large") {
    return "18px 32px";
  }
};

const getTypology = (size: string) => {
  if (size === "small") {
    return TypographyBodyMedium;
  }
  if (size === "medium") {
    return TypographyBodyBold;
  }
  if (size === "large") {
    return TypographyHeaderSub;
  }
};

export const StyleMainButton = styled.button<ButtonProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  ${(props) => getTypology(props.size)}
  padding: ${(props) => getPadding(props.size)};
  border-radius: 2rem;
  border: none;
  color: ${(props) => props.theme.white100};
  box-shadow: 0px 8px 20px
    ${(props) =>
      props.variant === "secondary"
        ? props.theme.grayShadow
        : props.theme.shadowBlue};
  background-color: ${(props) =>
    props.variant === "danger"
      ? props.theme.redPrimary
      : props.variant === "secondary"
      ? props.theme.black600
      : props.theme.blue};
  cursor: pointer;
  transition: 0.2s;
  &:hover:enabled {
    background-color: ${(props) =>
      props.variant === "danger"
        ? props.theme.redPrimaryOpacity50
        : props.variant === "secondary"
        ? props.theme.black500
        : props.theme.blue500};
  }
  &:disabled {
    background-color: ${(props) => props.theme.separation};
    color: ${(props) => props.theme.textSub};
    cursor: not-allowed;
    box-shadow: unset;
  }
  &:active:enabled {
    background-color: ${(props) =>
      props.variant === "danger" ? props.theme.red800 : props.theme.secondary};
  }
  &:focus {
    outline: none;
  }
`;
