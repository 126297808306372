import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
} from "./utils";
import {
  getItems,
  createItem,
  updateItem,
  deleteItem,
  getPage,
  getSingleItem,
} from "./sdk";
import { Account, AccountPage } from "../../types/Account";
import { bitAfterNow, getQueryParams } from "../../shared/helper";
import { SLAPreBreachedNotificationSettings } from "../../types/Me";
import { NotificationSettings } from "../../types/Notifications";

const key = "accounts";
const changeCustomerKey = "change_customer";

// All possible attributes that can pass to API call
export interface AccountParams {
  can_manage_customer?: boolean;
  customer?: number; // will be used in API if me.is_superuser
  email?: string;
  phone?: string;
  products_allowed?: number[];
  projects_allowed?: number[];
  role?: string;
  email_settings?: NotificationSettings;
  in_app_settings?: NotificationSettings;
  sla_pre_breached_notifications?: SLAPreBreachedNotificationSettings;
  has_unseen_whats_new?: boolean;
  onSuccessCallback?: (data: Account) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface AccountContext {
  accountId: number;
  accountData: AccountParams;
  onSuccessCallback?: (data: Account) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface SwitchCustomerContext {
  accountId: number;
  customerId: number;
  onSuccessCallback?: (data: Account) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiAccounts = (
  params?: { [key: string]: any },
  isDisabled?: boolean
) =>
  useQuery<Account[], Error>({
    queryKey: [key],
    keepPreviousData: true,
    placeholderData: [],
    enabled: !isDisabled,
    queryFn: async (): Promise<Account[]> => getItems(key, params),
  });

export const useApiSingleAccount = (id: string) =>
  useQuery<Account | undefined, Error>({
    queryKey: [key, parseInt(id)],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: false,
    queryFn: async (): Promise<Account | undefined> =>
      getSingleItem(key, id) as Promise<Account | undefined>,
  });

export const useApiAccountsPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useInfiniteQuery<AccountPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<AccountPage> =>
      getPage(key, { ...filters, page: pageParam }).then((resp) => {
        var page = { ...resp };
        const accountsDicts = page?.results || [];
        page.results = accountsDicts as Account[];
        return page;
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiCreateAccount = () => {
  const queryClient = useQueryClient();
  return useMutation<Account, Error, AccountParams>({
    mutationKey: [key],
    mutationFn: async (newAccount): Promise<Account> =>
      await createItem(key, newAccount),
    onSuccess: (data: Account, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiUpdateAccount = () => {
  const queryClient = useQueryClient();
  return useMutation<Account, Error, AccountContext>({
    mutationKey: [key],
    mutationFn: async ({
      accountId,
      accountData,
    }: AccountContext): Promise<Account> =>
      await updateItem(key, accountId, accountData),
    onSuccess: (data: Account, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiSwitchCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation<Account, Error, SwitchCustomerContext>({
    mutationKey: [key, changeCustomerKey],
    mutationFn: async ({
      accountId,
      customerId,
    }: SwitchCustomerContext): Promise<Account> =>
      await createItem(`${key}/${accountId}/${changeCustomerKey}/`, {
        customer_id: customerId,
      }),
    onSuccess: (data: Account, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () =>
      invalidateApiQueries([key, changeCustomerKey], queryClient),
  });
};

export const useApiDeleteAccount = () => {
  const queryClient = useQueryClient();
  return useMutation<Account, Error, Account>({
    mutationKey: [key],
    mutationFn: async (account): Promise<any> =>
      await deleteItem(key, account, account.id),
    onSuccess: (data, variables) =>
      removeItemFromContext({
        data,
        queryKey: [key, { id: variables?.id }],
        queryClient,
      }),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};
