import { useContext } from "react";
import { Box } from "../../components/elements/box/Box";
import { Flex } from "../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../components/elements/typography/Typography";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";
import { useApiUpdateCustomer } from "../../hooks/queries/customersContext";
import { useApiMe } from "../../hooks/queries/meContext";
import useToastContext from "../../hooks/toastHook";
import "./pulseAnimation.css";

type ArchProps = {
  widthPX: number;
  opacity: string;
  isDisabled?: boolean;
  animationDelay?: string;
};
export const Arch = (props: ArchProps) => {
  const { widthPX, opacity, isDisabled, animationDelay } = props;
  const theme = useContext(ThemeContext);
  return (
    <div
      className={isDisabled ? "" : "pulse-animation"}
      style={{
        position: "absolute",
        right: `-${widthPX / 2}px`,
        bottom: `-${widthPX / 2}px`,
        border: `1px solid ${isDisabled ? theme.black600 : theme.blue600}`,
        borderRadius: "50%",
        width: `${widthPX}px`,
        height: `${widthPX}px`,
        opacity: opacity,
        transition: "0.4s",
        animationDelay: animationDelay ? animationDelay : "none",
        boxShadow: `20px 5px 5px 2px ${theme.blue800}`,
      }}
    ></div>
  );
};

type ArchesProps = {
  isDisabled?: boolean;
};
export const Arches = (props: ArchesProps) => {
  const { isDisabled } = props;
  return (
    <Flex>
      <Flex
        w100
        h100
        justify="end"
        align="end"
        style={{ position: "relative", zIndex: "" }}
      >
        <Arch
          isDisabled={isDisabled}
          widthPX={50}
          opacity="0.1"
          animationDelay="0s"
        />
        <Arch
          isDisabled={isDisabled}
          widthPX={100}
          opacity="0.2"
          animationDelay="0.1s"
        />
        <Arch
          isDisabled={isDisabled}
          widthPX={150}
          opacity="0.3"
          animationDelay="0.2s"
        />
        <Arch
          isDisabled={isDisabled}
          widthPX={200}
          opacity="0.4"
          animationDelay="0.3s"
        />
        <Arch
          isDisabled={isDisabled}
          widthPX={250}
          opacity="0.5"
          animationDelay="0.4s"
        />
      </Flex>
    </Flex>
  );
};

export const TurnOnASM = () => {
  const { data: me } = useApiMe();
  const { mutate: updateCustomer, isLoading } = useApiUpdateCustomer();
  const addToast = useToastContext();
  const theme = useContext(ThemeContext);

  const enableASM = () => {
    updateCustomer({
      customerId: me?.customer.id || 0,
      customerData: { is_asm_enabled: true },
      onErrorCallback: (error) =>
        addToast({ type: "error", message: `Failed to enable ASM - ${error}` }),
    });
  };

  return (
    <Box
      style={{
        height: "214px",
        overflow: "hidden",
        padding: "0",
        width: "100%",
      }}
    >
      <Flex
        column
        w100
        h100
        gap="12px"
        align="center"
        justify="center"
        style={{ padding: "24px" }}
      >
        <HeaderSubBold>ASM Scanning Turned Off</HeaderSubBold>
        <BodyRegular style={{ textAlign: "center" }}>
          Turn on ASM Scanning to keep your products safe
        </BodyRegular>
        <SecondaryButton
          label="Turn On ASM"
          onClick={enableASM}
          iconName="play"
          inProgress={isLoading}
          style={{ zIndex: 80, background: theme.bg2 }}
          size="small"
        />
      </Flex>

      <Arches isDisabled />
    </Box>
  );
};
