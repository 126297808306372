import { ScanEventPage } from "../types/ScanEvent";

export const mockScanEvents: ScanEventPage = {
  results: [
    {
      id: "a785d5bb-1208-46e9-9daa-d93ca1694278",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/32842a3fb2564eb09b7b95050be25078",
      scanner: "sublister",
      status: "done",
      created_at: "2023-10-08 10:59:45.289961",
      finished_at: "2023-10-09T07:57:47.900000",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "8802f738-4b88-4fc7-94ab-b367b033194e",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/1cdd7fdca3ce49918b675ef8208d9e30",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-24 00:02:20.620877",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "183351fd-ef32-4dc7-99f6-1ef1beba4f05",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/35d67f2ba45d47d4b9ff05ce684676d1",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-24 00:02:19.744650",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "9ceb8302-a280-429c-a1a2-7c5a54fb2dc5",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/343c529fc3c64398aecaac177d5227cc",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-24 00:02:19.059378",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "401bd0be-2852-4e28-a0ed-d6d80fcc53c9",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/fd35c3b14cac4032a4955bf073392c24",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-24 00:02:18.311307",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "50bda477-76de-4c5e-ad59-dccbc6ba0991",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/143f3577df274b75aaa39dc99e1b4bc7",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-24 00:02:17.598236",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "42b05aa4-f2e9-4647-88ba-4fad1d9f23a1",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/7e0d35d7e46d4f3986523dda5bfeb8ef",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-24 00:02:16.730718",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "300d8da6-a431-4354-9f13-5e09948b1cb6",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/24de16ef64bd4aa4abc4453ff68e6f96",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-24 00:02:15.913169",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "e6672594-b4c2-4e00-b5dd-98e3e0cb5fc9",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/8e81768a130e42e49f88a35bf2a97d15",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-23 00:58:30.364873",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "483708fa-bbc4-4f17-abf2-0d45f9927d64",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/39a4e3630cd94a8e8fa5d05be6d92e42",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-23 00:58:29.857791",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "b8b30dd1-cf79-40d1-a3e3-1f7fc570c59a",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/8fc9e583cc22434eaaa56b7e8d2120ea",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-23 00:58:29.283181",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "737f6cd8-afab-4959-a968-eb1def8800a3",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/39037a62da42498e90d8600ea3170dde",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-23 00:58:28.595262",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "638cb9f5-d536-4006-8db3-fd34c860d171",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/c5ce0fb8dea0447399366960bf1d8fea",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-23 00:58:28.128292",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "5476e0d6-11e7-4e16-8a40-900f42b37d6f",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/e08d47fa4a104db8ab089e14a8ec9190",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-23 00:58:27.420115",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "ca0849fc-7c89-4529-92e0-b41ec59e02dd",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/c926b07a5c4a4847a749609c5cd8ac2f",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-23 00:58:26.150646",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "90a457a0-c088-42ce-824d-52217c8b6b8f",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/96899ff799ab418fa862c1fd405c5941",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-22 00:56:36.661416",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "d3061612-fb68-48a7-9728-1fc71eb22691",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/c6d3a092c62e48f8b0d2906bfc81bc83",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-22 00:56:35.759899",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "0ef1a084-5c1c-4289-a27e-400d0c044104",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/86161ffcd75e4441bfa64bddaf83620e",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-22 00:56:34.896641",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "d39f6448-2dd0-45df-a4fb-23ff21ce3ab1",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/bc2155e5b1604acb8492ed0d04387c23",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-22 00:56:34.080811",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "675d5e2b-d66a-4bd9-aa81-e2a3818f4246",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/269ad973fd23478cb4d1a772b7efdd0c",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-22 00:56:33.319577",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "49ac4295-14ca-4dc9-915e-079acdc3471b",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/5c8d05cd9b6340ac94d0aa568955b68f",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-22 00:56:32.285737",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "2255afe2-1685-4c26-9c41-1c4bee76fe12",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/5c0b9b577e02453885e1b002d191738f",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-21 13:24:43.800371",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "a939e024-4977-40e8-826d-10d4ce90e731",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/6d363c0d959d494ca4f6978d22d13782",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-21 13:24:42.843333",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "8cc6d1be-b286-43a1-8233-4e04e94891bd",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/9c7f6a15556c45289c336d3fa38f5331",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-21 13:24:27.937463",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "755e16b0-1375-4460-9ccf-ea3619fd7fe3",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/949342bd15054b2296e62c9e705d01e8",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-21 13:24:26.744787",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "f279fc91-9266-45b3-9962-62e7e53fee55",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/f44e728ec5b8479fbe37b5d0d3b3f36b",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-21 13:24:25.410114",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "3f652f9b-02a6-4289-ab50-af1b4b5b1a48",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/56c42929dabd48d4af4e5177536a2dfd",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-21 13:24:23.979670",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "56bbfed5-587a-423a-a7d3-f54553d88fa5",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/7b073795bbde4733bcfb46c6791c9cd5",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-21 13:24:21.640147",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "361f00c2-883e-42d4-ab4a-26e164a730b9",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/b42bb47c769046f7be86c5be07383c13",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-21 00:54:42.906176",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "6b5448c2-d511-4ee4-a77c-acf71de3db81",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/e513f1155ed949518e08689197c697ef",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-21 00:54:42.203841",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "9f3fa231-6256-44a3-a7aa-8f644d2bd25b",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/70b40b363a8343679ad569c5657565f0",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-21 00:54:41.211685",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "10464210-d9dc-4695-9c17-d29a58fe9093",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/e73b9b87815f4580b8cd61ab420098b6",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-21 00:54:40.533419",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "8ae7a5be-5130-4bcb-9cc5-3617cc1c5a58",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/fb034a07a5904fb9915e522f8ff87aa8",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-21 00:54:39.852408",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "8edc27c5-f4ab-499c-b5e0-d8c053180fb7",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/7043a567d0ee4a3ca9c2889ca2bc8b6b",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-21 00:54:39.024239",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "0a483f0a-06a8-48f7-aee6-2a5ecc0a2cea",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/47c5c0b7b6b343cf83f3e113430912f2",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-21 00:54:37.741786",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "352da2ff-b05a-457e-b9e5-e81cb9c68d6b",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/cfe95146a15c4c70988647fec26a030f",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-20 00:00:52.516235",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "66ba12e5-f126-4972-9ee6-0fd2952ca7f1",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/84bcebd1fe9242b9a9997ab3bdf79714",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-20 00:00:51.414482",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "68f94fde-eb31-496a-a13a-538c70cd8c8f",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/194187a5c64d4e628883a10f0a10eaf1",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-20 00:00:50.597951",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "349c0dc7-44ba-4118-a0d8-4676fcdee179",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/643bbae026ec40f592206c43d4e67477",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-20 00:00:49.836207",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "4c4e6642-df52-470d-ab57-60c3e13c6208",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/45a9c704e86d482db50e88b24c1975e5",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-20 00:00:48.859128",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "3c0a83f6-f20a-4ae2-bf2f-fae62e0c4bbd",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/03b72713037741168d422eb336205952",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-20 00:00:48.137388",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "a8177b18-fc06-4e1a-b1c4-c757c351c7e6",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/9415ed1c49714cd18add86dd3eb5b4c0",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-20 00:00:46.848973",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "d0a93fd0-96ec-4e40-9800-b837941a035a",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/1f2638c7c94d4adf84e2a362b018867f",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-19 00:58:56.416891",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "1361f9f1-8821-4c8d-b1db-3202841e36fe",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/2fc8b519751743f0a33b640990db3610",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-19 00:58:55.628548",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "1e9c5ad8-5fe5-41b9-ba67-b98c40b08b23",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/28199074e552466ea2955260195701d7",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-19 00:58:54.899005",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "6feed8e2-fe59-44ba-9391-33eedf54b8b8",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/3e356ed7bf8d4492a3e614705d371f9b",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-19 00:58:54.189382",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "015aa2b1-75e1-4df6-8e01-fe199d03c405",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/1950a35347764344a2d828d7b7807e06",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-19 00:58:53.377973",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "a6f3fa68-56dd-481f-8d22-176eaa186f36",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/2e365a65f8164acc8df60c573bef21c6",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-19 00:58:52.620445",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "e2b2d358-a2a5-4f9e-b669-6d6ead7e2185",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/75d68bd417e84eb6a1fe8ca2cd046b4a",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-19 00:58:51.263528",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "407ec671-542b-4c20-897c-13e9886b5787",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/de6543e087ee438283bef093ead557b7",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-18 00:57:23.416349",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "926747de-5d21-489a-affd-09782fd9ede9",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/3d810262fc1048debc8c6cbba1f512db",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-18 00:57:22.878995",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "b39fcc2b-0efc-4a24-90b1-4b8cc98aaa98",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/88c1f346a19c4abaa6f90cce550484b5",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-18 00:57:22.344753",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "32a93214-a516-472d-b3bd-fd20fcf2cf38",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/b50067ee27a0403e94c0d7761f6cc63d",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-18 00:57:21.616900",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "b669e2bf-f632-4f62-9115-539ff3094783",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/b693e3f7277344fc9d9af6c5e9735382",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-18 00:57:20.840513",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "0b874294-121e-4200-95ae-6e558541c6e9",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/74c5c3e138344f8aac2bfb0328125421",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-18 00:57:20.335172",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "4db1ff41-f61a-4a18-bb1b-78c1c1b888c1",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/8c4b827e4daf49b2b93deef027e28215",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-18 00:57:19.083979",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "0f3dce5d-3dd6-443b-ad1c-61b89c462c02",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/30f375606c4c4839a8cae2d894dca192",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-17 00:39:26.422358",
      finished_at: "2023-10-02T06:46:06.584000",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "2ab8a181-88d0-4a73-a754-69fbcde959c8",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/d64034dd5fd1442cb9c0907326157364",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-17 00:39:25.918500",
      finished_at: "2023-10-02T06:46:06.359000",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "97fb76d4-448d-42d0-84cf-26b813ec1703",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/a5c03f44234745f2b6505c2cd052cf33",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-17 00:39:25.304513",
      finished_at: "2023-10-02T06:46:06.143000",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "4873a687-b533-47f9-aabd-c51c9d943764",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/c77aa1f47c3644ccbf600088cd6a7efb",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-17 00:39:24.798013",
      finished_at: "2023-10-02T06:46:05.928000",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "d6e88ffd-34b8-4d1e-beff-70bc5557a70a",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/3e9b44c3dd8e482a854f3d0e02bfd2a8",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-17 00:39:24.320146",
      finished_at: "2023-10-02T06:46:05.710000",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "52f17e50-cf63-419a-9b5a-bb68b5b0761d",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/c58a82920a07417386e2e55dc609c846",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-17 00:39:23.752514",
      finished_at: "2023-10-02T06:46:05.463000",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "89eeca8b-d2ca-4b4a-aba7-5ecd8385949b",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/97a53ea5168f45cd9d08cb7be9ac684c",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-17 00:39:22.545104",
      finished_at: "2023-10-02T06:46:05.237000",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "adff61b1-3f55-4c4f-be82-b7844257394c",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/24612e18c8db432c9808d7f5101c1b2f",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-16 00:37:30.510181",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "4d182abb-37fd-4000-a204-ad97182c7539",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/2b442241684e4beeb9824be9cbe472e6",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-16 00:37:29.709120",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "757ac3aa-772c-4867-bae9-bdb20cd7354c",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/ccb8b1f7f55644b1a4bf597c93faa820",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-16 00:37:28.789036",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "1dcdaa03-505a-4316-9ec8-b8aad51f628c",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/dc935f95ae9f473fb88ecd4de8c7ddae",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-16 00:37:27.980736",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "242b5243-9c3e-45d4-9a8d-26dcd2937f0e",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/800bd5095e974032bda210b388af3858",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-16 00:37:27.291479",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "5a743208-d8b7-4c30-b3d7-2286c48d163a",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/ea2bc392cce848c08a1d64ef0742d83d",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-16 00:37:26.459271",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "52322cf0-747d-4442-949d-2458cf3019cb",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/8905bd60d1314e5d833e56721d0f8ed7",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-16 00:37:24.984083",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "811b8989-5870-4832-8629-1d42fc9e5c71",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/e3c96734c42e465987e73800ebbb4b87",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-15 00:35:38.050082",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "4511c62b-b457-40f6-8abc-3e5ac4059400",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/94a7dd5a04564898983e691a53bbda87",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-15 00:35:37.100250",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "aeba1521-c1b0-477d-ab2d-2344bc4220d8",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/a27bb1ae42294482867851796a8751fb",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-15 00:35:36.415178",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "fbfb07c9-1fa0-44ea-be47-40f541780b30",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/c194497620174f9aa19fead24d8da77e",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-15 00:35:35.591153",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "c1eced54-6ac9-4671-be37-53964bce4313",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/27401a5dcb5d4f3a860f8474f112ff04",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-15 00:35:34.940502",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "1206aee6-d70c-4222-9fdf-2ce6db963650",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/a94e7250940643c09265ff624967d422",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-15 00:35:34.260330",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "b47b42bb-0414-440e-923f-0931fe49fa59",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/0ed587538b6b4040bf29e2ff1671a800",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-15 00:35:32.572704",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "11289348-ecf6-4070-9d21-a49f4bcb0c99",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/34fe116f6424487493bf7082e0b4752e",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-14 00:33:59.315687",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "ae24731e-c05f-43fd-8f1f-f9f582938ecd",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/32b7027c1bff4472a417be23df00c79c",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-14 00:33:58.491880",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "b9945aef-14c8-4482-9f12-96df66b73d2f",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/93358a6b84a04aaaa1cfac43a2af54fa",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-14 00:33:57.774714",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "3e1d405b-36fc-4513-b308-1d5e3127750c",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/8f588b36d51a4257b983d583329f1631",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-14 00:33:56.932921",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "cdc238cf-296f-4c56-8021-1bcd44c08678",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/69067ad8160044ef87f1ec68f982cb55",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-14 00:33:56.085013",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "0a6ba96f-9cc5-411b-843c-da0956cff236",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/8d6ab73f526149edb192a8ed2c11c915",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-14 00:33:55.278068",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "4ef025ba-e484-4091-80cf-f85acaf15b1a",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/3dfb617f9d03463bb89c865d33b710e1",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-14 00:33:54.543948",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "20b42034-8192-4b6c-8cb4-74b1d83272b1",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/814b55a29956482d8a8949ca04087040",
      scanner: "dalfox",
      status: "done",
      created_at: "2023-08-13 00:32:02.777917",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "cc3979ca-d9bb-4254-b124-102a7705ce4a",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/353aa20e3c4e46cba554702c29fe84eb",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-13 00:32:01.951860",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "d91dff84-a0b4-4c1a-9283-fe656d084929",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/a4af3c30229545c48ecb3cc748c9863b",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-13 00:32:01.010656",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "52d283a5-6bf8-4c5b-9156-0308dbceea0b",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/a13e343d27474664923f73242e150ec5",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-13 00:32:00.114815",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "16014176-1d76-47c8-9a21-875fb4ba9d9c",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/68aec35503864c93a5520e4a5e1360d7",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-13 00:31:59.073712",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "9e75b5a5-30a6-498c-ba0a-c02e67da5c7e",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/8a89642b83ad4032b2015993410059e9",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-13 00:31:58.158185",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "f7fbadf6-186e-4284-9063-ff1c1e7a665c",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/5f00fe26a79245d290aa4985f722b3c6",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-13 00:31:57.421326",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "3743b266-db8a-4b7a-9936-d4d6298a0515",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/6add1472807d4665bb06f28f4082a6df",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-12 00:29:54.957460",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "df9278a4-6b07-47c5-871e-05c5dd55df0a",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/21fbb0122e674f9cb5c8e6a918d0184b",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-12 00:29:54.131714",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "20f27fa8-0f9a-4a87-b00a-95ae0d288e50",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/03bb7a29e2184e3991d2ebc15886907c",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-12 00:29:53.505884",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "740e4764-e387-4314-8de7-e1b2d2186107",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/5e96f76a6b524037bd44bc51919241e8",
      scanner: "zap",
      status: "done",
      created_at: "2023-08-12 00:29:52.851260",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "8575f35f-cd7c-4692-8306-e53a693492b5",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/3fe289332e43489fbb3a8fbbf9cd5b1c",
      scanner: "nuclei",
      status: "done",
      created_at: "2023-08-12 00:29:51.897459",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "117c2f27-7d20-42ef-8eae-e769a7017a4b",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/91e3515b9fb042ef9f7efea39e935382",
      scanner: "sublister",
      status: "done",
      created_at: "2023-08-12 00:29:51.152912",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "fe31452a-0501-48b1-96ec-d0eee20558ad",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/ff0ad30018b24896b27922182c3663d2",
      scanner: "wpscan",
      status: "done",
      created_at: "2023-08-11 00:28:04.240443",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "26fa619a-5fce-43cf-aa55-cf5597238f96",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/f259685c4b834f42ab4230ba018226a8",
      scanner: "dns_reaper",
      status: "done",
      created_at: "2023-08-11 00:28:03.454841",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
    {
      id: "078d7424-e701-46da-8ca0-d0c855239aab",
      customer_id: 1,
      aws_task_id:
        "arn:aws:ecs:us-east-2:195533439922:task/ASM-Prod/62bb254c35774d44a7899034f24d4b78",
      scanner: "smto",
      status: "done",
      created_at: "2023-08-11 00:28:02.664612",
      finished_at: "",
      scanner_configuration: {},
      scan_type: "whitelisted",
    },
  ],
  count: 2267,
  next: null,
  previous: null,
};
