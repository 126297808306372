import React from "react";
import { IconProps } from "./WaspIcon";

export function AssetsFillIcon(props: IconProps) {
  const size = props.size || 28;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.85632 21.818C3.85632 23.103 4.89709 24.1438 6.18208 24.1438H6.76359V26.1791C6.76359 26.9814 7.41492 27.6326 8.21722 27.6326C9.01965 27.6326 9.67085 26.9814 9.67085 26.1791V24.1438H12.5783V26.1791C12.5783 26.9814 13.2296 27.6326 14.0319 27.6326C14.8343 27.6326 15.4857 26.9814 15.4857 26.1791V24.1438H18.393V26.1791C18.393 26.9814 19.0443 27.6326 19.8465 27.6326C20.6492 27.6326 21.3004 26.9814 21.3004 26.1791V24.1438H21.8819C23.1669 24.1438 24.2077 23.103 24.2077 21.818V21.2365H26.243C27.0453 21.2365 27.6965 20.5853 27.6965 19.7826C27.6965 18.9803 27.0453 18.3291 26.243 18.3291H24.2077V15.4218H26.243C27.0453 15.4218 27.6965 14.7703 27.6965 13.968C27.6965 13.1657 27.0453 12.5144 26.243 12.5144H24.2077V9.60696H26.243C27.0453 9.60696 27.6965 8.95576 27.6965 8.15332C27.6965 7.35102 27.0453 6.69969 26.243 6.69969H24.2077V6.11819C24.2077 4.83313 23.1669 3.79243 21.8819 3.79243H21.3004V1.75723C21.3004 0.954795 20.6492 0.303467 19.8465 0.303467C19.0443 0.303467 18.393 0.954795 18.393 1.75723V3.79243H15.4857V1.75723C15.4857 0.954795 14.8343 0.303467 14.0319 0.303467C13.2296 0.303467 12.5783 0.954795 12.5783 1.75723V3.79243H9.67085V1.75723C9.67085 0.954795 9.01965 0.303467 8.21722 0.303467C7.41492 0.303467 6.76359 0.954795 6.76359 1.75723V3.79243H6.18208C4.89703 3.79243 3.85632 4.83313 3.85632 6.11819V6.69969H1.82112C1.01869 6.69969 0.367493 7.35102 0.367493 8.15332C0.367493 8.95582 1.01869 9.60696 1.82112 9.60696H3.85632V12.5144H1.82112C1.01869 12.5144 0.367493 13.1657 0.367493 13.968C0.367493 14.7704 1.01869 15.4218 1.82112 15.4218H3.85632V18.3291H1.82112C1.01869 18.3291 0.367493 18.9803 0.367493 19.7826C0.367493 20.5853 1.01869 21.2365 1.82112 21.2365H3.85632V21.818ZM18.3931 10.8864V17.0497C18.3931 17.7588 17.8228 18.3291 17.1137 18.3291H10.9504C10.2411 18.3291 9.67098 17.7588 9.67098 17.0497V10.8864C9.67098 10.1769 10.2412 9.60702 10.9504 9.60702H17.1137C17.8227 9.60702 18.3931 10.1769 18.3931 10.8864Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
