import React, { useContext, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Box } from "../../../components/elements/box/Box";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  objectToOption,
  objectsToOptions,
  customersPriorityTierOptions,
} from "../../../shared/formUtils";
import { ThemeContext } from "styled-components";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import {
  analyticsCurrentItem,
  useApiAsmAnalytics,
} from "../../../hooks/queries/findingContext";
import { getRelevantCustomers, getRelevantCustomersIds } from "../utils";
import { PriorityTier } from "../../../types/Customer";

export const FindingsCurrentBreakdown = () => {
  const theme = useContext(ThemeContext);
  const { data: customers } = useApiCustomers();
  const { data: asmAnalytics } = useApiAsmAnalytics();
  const [selectedCustomer, setCustomer] = useState<number | string | undefined>(
    "all"
  );
  const [selectedTier, setTier] = useState<PriorityTier | "all">("all");

  // Function to sum counts for a given property
  const sumPropertyCounts = (property: keyof analyticsCurrentItem) => {
    if (!asmAnalytics) return -1;
    const data =
      selectedCustomer === "all"
        ? asmAnalytics.current_data.filter((i) =>
            getRelevantCustomersIds(customers || [], selectedTier).includes(
              i.customer_id
            )
          )
        : asmAnalytics.current_data.filter(
            (a) => a.customer_id === selectedCustomer
          );
    return data.map((a) => a[property]).reduce((acc, curr) => acc + curr, 0);
  };

  const calcPositiveFindings = (): number[] => {
    if (!asmAnalytics || !selectedCustomer) {
      return [0, 0];
    }

    // List of property names
    const positiveProperties: (keyof analyticsCurrentItem)[] = [
      "tp_count",
      "fp_count",
    ];

    // Calculate and return the sum for each property
    return positiveProperties.map(sumPropertyCounts);
  };
  const calcStatusBreakdown = (): number[] => {
    if (!asmAnalytics || !selectedCustomer) {
      return [0, 0, 0, 0, 0, 0];
    }

    // List of property names corresponding to each status
    const statusProperties: (keyof analyticsCurrentItem)[] = [
      "presented_count",
      "in_progress_count",
      "re_test_count",
      "fixed_count",
      "dismissed_count",
      "acceptable_risk_count",
    ];

    // Calculate and return the sum for each property
    return statusProperties.map(sumPropertyCounts);
  };

  const calcRiskBreakdown = (): number[] => {
    if (!asmAnalytics || !selectedCustomer) {
      return [0, 0, 0, 0, 0];
    }

    // List of properties to calculate
    const properties: (keyof analyticsCurrentItem)[] = [
      "info_count",
      "low_count",
      "medium_count",
      "high_count",
      "critical_count",
    ];

    // Calculate and return the sum for each property
    return properties.map(sumPropertyCounts);
  };

  return (
    <Box>
      <Flex column gap="16px">
        <Flex justify="between" align="center">
          <Flex gap="8px">
            <Dropdown
              searchable
              placeholder="Select customer"
              onChange={(opt) => {
                if (opt?.value) {
                  setCustomer(opt.value);
                  setTier("all");
                }
              }}
              value={
                selectedCustomer === "all"
                  ? { label: "All customers", value: "all" }
                  : customers?.filter((c) => c.id === selectedCustomer)[0]
                    ? objectToOption(
                        customers?.filter((c) => c.id === selectedCustomer)[0]
                      )
                    : null
              }
              options={
                customers
                  ? [
                      { label: "All customers", value: "all" },
                      ...objectsToOptions(getRelevantCustomers(customers)),
                    ]
                  : []
              }
            />
            <Dropdown
              searchable
              placeholder="Select tier"
              onChange={(opt) => {
                if (opt?.value) {
                  setCustomer("all");
                  setTier(opt.value as PriorityTier);
                }
              }}
              value={
                customersPriorityTierOptions.filter(
                  (t) => t.value === selectedTier
                )[0]
              }
              options={customersPriorityTierOptions}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex justify="evenly" w100>
        <Flex column style={{ width: "33%" }}>
          <Flex justify="center" w100>
            <HeaderSecondary>TP vs. FP Findings</HeaderSecondary>
          </Flex>
          <Flex style={{ maxHeight: "500px" }} justify="center">
            <Doughnut
              data={{
                labels: ["True Positive", "False Positive"],
                datasets: [
                  {
                    label: "Amount",
                    data: calcPositiveFindings(),
                    backgroundColor: [theme.greenPrimary, theme.redPrimary],
                  },
                ],
              }}
              options={{ radius: "100%" }}
            />
          </Flex>
        </Flex>
        <Flex column style={{ width: "33%" }}>
          <Flex justify="center" w100>
            <HeaderSecondary>Status Breakdown</HeaderSecondary>
          </Flex>

          <Flex style={{ maxHeight: "500px" }} justify="center">
            <Doughnut
              data={{
                labels: [
                  "Presented",
                  "In Progress",
                  "Ready For Retest",
                  "Fixed",
                  "Dismissed",
                  "Acceptable Risk",
                ],
                datasets: [
                  {
                    label: "Amount",
                    data: calcStatusBreakdown(),
                    backgroundColor: [
                      theme.lightBluePrimary,
                      theme.primary,
                      theme.greenPrimary,
                      theme.orangePrimary,
                      theme.yellowPrimary,
                      theme.redPrimary,
                    ],
                  },
                ],
              }}
              options={{ radius: "100%" }}
            />
          </Flex>
        </Flex>
        <Flex column style={{ width: "33%" }}>
          <Flex justify="center" w100>
            <HeaderSecondary>Risk Breakdown</HeaderSecondary>
          </Flex>

          <Flex style={{ maxHeight: "500px" }} justify="center">
            <Doughnut
              data={{
                labels: ["Info", "Low", "Medium", "High", "Critical"],
                datasets: [
                  {
                    label: "Amount",
                    data: calcRiskBreakdown(),
                    backgroundColor: [
                      theme.grayBluePrimary,
                      theme.primary,
                      theme.yellowPrimary,
                      theme.orangePrimary,
                      theme.redPrimary,
                    ],
                  },
                ],
              }}
              options={{ radius: "100%" }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
