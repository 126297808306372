import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import {
  BodyMedium,
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { SimpleCheckbox } from "../../../components/elements/checkbox/SimpleCheckbox";
import { Switch } from "../../../components/elements/switch/Switch";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { Asset } from "../../../types/Asset";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { NotFoundBanner } from "../../../components/composed/banner/NotFoundBanner";
import { InfoTooltip } from "../../../components/composed/infoTooltip/InfoTooltip";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { TagsLine } from "../../../components/composed/tagsLine/TagsLine";
import { AssetConfig, AssetsConfigs } from "../../../types/Project";
import { ProjectRequestParams } from "../../../hooks/queries/projectRequestsContext";

const sensitivityOptions = [
  { value: "none", label: "None" },
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
  { value: "critical", label: "Critical" },
];

const defaultAssetConfig: AssetConfig = {
  scan_mode: "static",
  api_testing: true,
  tech_in_use: [],
  data_sensitivity: sensitivityOptions[0].value,
  login: false,
  login_types: { oauth: false, sso: false, sso_types: "" },
  signup: false,
  password_reset: false,
  users_mgmt: false,
  file_upload: false,
  extra_mechanisms: "",
};

type Props = {
  configuredAssets: Asset[];
  mainFormValues: ProjectRequestParams;
  setMainFormValues: Dispatch<SetStateAction<ProjectRequestParams>>;
  currentlyConfiguredCount?: number;
  configureAllAssets: boolean;
};

export const AssetsConfigForm = (props: Props) => {
  const {
    configuredAssets,
    setMainFormValues,
    mainFormValues,
    currentlyConfiguredCount,
    configureAllAssets,
  } = props;

  // Current changes in form will be saved here
  const [config, setConfig] = useState<AssetConfig>(defaultAssetConfig);

  const [showAddMechanism, setShowAddMechanism] = useState<boolean>(false);

  useEffect(() => {
    // When selected single asset - load it's configurations,
    if (!mainFormValues?.assets_configs || !configuredAssets?.length) return;
    const id = Object.keys(mainFormValues.assets_configs).includes("0")
      ? 0
      : configuredAssets[0].id;

    id &&
      configuredAssets.length === 1 &&
      mainFormValues.assets_configs[id as keyof AssetsConfigs] &&
      setConfig(mainFormValues.assets_configs[id]);
  }, [configuredAssets, mainFormValues.assets_configs]);

  // On applying, new assets configs will be merged into the main form values state
  // if configuring all assets than we use index 0
  const applyConfigurations = async () => {
    const newAssetsConfigs: AssetsConfigs = {};
    if (configureAllAssets) {
      newAssetsConfigs[0] = config;
      setMainFormValues((prev) => ({
        ...prev,
        assets_configs: newAssetsConfigs,
      }));
    } else {
      configuredAssets.forEach((asset) => {
        newAssetsConfigs[asset.id] = config;
      });
      setMainFormValues((prev) => ({
        ...prev,
        assets_configs: { ...prev.assets_configs, ...newAssetsConfigs },
      }));
    }
  };

  return (
    <Flex column gap="24px" w100>
      {!configuredAssets?.length && (
        <Flex w100 h100 align="center" justify="center">
          <NotFoundBanner
            title="No asset was selected"
            text="Please Select Assets To Configure"
          />
        </Flex>
      )}
      {(!!configuredAssets?.length || configureAllAssets) && (
        <>
          <Flex align="center" justify="between">
            {configuredAssets?.length === 1 && (
              <HeaderSecondary style={{ textTransform: "none" }}>
                {configuredAssets[0].name}
              </HeaderSecondary>
            )}
            {configuredAssets?.length > 1 && (
              <HeaderSecondary>
                {currentlyConfiguredCount} Assets selected
              </HeaderSecondary>
            )}
            <Flex gap="8px">
              <Tooltip content="Reset Form">
                <IconButton
                  iconName="reverse"
                  onClick={() => {
                    setConfig(defaultAssetConfig);
                    setShowAddMechanism(false);
                  }}
                />
              </Tooltip>
              <MainButton
                label="Apply"
                dataTestId="apply-asset-config"
                onClick={applyConfigurations}
                size="medium"
              />
            </Flex>
          </Flex>

          <Flex
            column
            w100
            style={{
              height: "calc(90vh - 240px)",
              overflowY: "auto",
              paddingRight: "32px",
            }}
          >
            <SeparatorHorizontal style={{ width: "100%" }} />

            <Flex column gap="8px" style={{ padding: "32px" }}>
              <Flex align="center" gap="8px">
                <BodyMedium>Select Application type</BodyMedium>
                <InfoTooltip content="Please select whether the application is static or dynamic." />
              </Flex>

              <Flex gap="32px">
                <SimpleCheckbox
                  onChange={() =>
                    setConfig((prev) => ({
                      ...prev,
                      scan_mode:
                        config.scan_mode === "static" ? null : "static",
                    }))
                  }
                  isChecked={config.scan_mode === "static"}
                  label="Static"
                />
                <SimpleCheckbox
                  onChange={() =>
                    setConfig((prev) => ({
                      ...prev,
                      scan_mode:
                        config.scan_mode === "dynamic" ? null : "dynamic",
                    }))
                  }
                  isChecked={config.scan_mode === "dynamic"}
                  label="Dynamic"
                  dataTestId="checkbox-dynamic"
                />
              </Flex>
            </Flex>

            <SeparatorHorizontal style={{ width: "100%" }} />

            <Flex align="center" gap="8px" style={{ padding: "32px" }}>
              <BodyMedium>Notable Mechanism / Interface</BodyMedium>
              <InfoTooltip content="If there are any specific mechanisms in place that you would like to be tested during the penetration test, please specify them here. Click the 'Add More' button to include additional mechanisms." />
            </Flex>
            <Flex column gap="24px">
              <Flex
                align="center"
                justify="between"
                style={{ paddingLeft: "32px" }}
              >
                <BodyRegular>Login</BodyRegular>
                <Switch
                  checked={config.login}
                  onChange={(state) =>
                    setConfig((prev) => ({ ...prev, login: state }))
                  }
                />
              </Flex>
              {config.login && (
                <Flex gap="32px" style={{ paddingLeft: "32px" }}>
                  <SimpleCheckbox
                    label="Oauth"
                    isChecked={config.login_types.oauth}
                    onChange={() =>
                      setConfig((prev) => ({
                        ...prev,
                        login_types: {
                          ...prev.login_types,
                          oauth: !prev.login_types.oauth,
                        },
                      }))
                    }
                  />
                  <SimpleCheckbox
                    label="SSO"
                    isChecked={config.login_types.sso}
                    onChange={() =>
                      setConfig((prev) => ({
                        ...prev,
                        login_types: {
                          ...prev.login_types,
                          sso: !prev.login_types.sso,
                        },
                      }))
                    }
                  />
                  <InputText
                    placeholder="Google, Microsoft"
                    value={config.login_types.sso_types}
                    disabled={!config.login_types.sso}
                    onChange={(e) =>
                      setConfig((prev) => ({
                        ...prev,
                        login_types: {
                          ...prev.login_types,
                          sso_types: e.target.value,
                        },
                      }))
                    }
                    width="100%"
                  />
                </Flex>
              )}

              <Flex
                align="center"
                justify="between"
                style={{ paddingLeft: "32px" }}
              >
                <BodyRegular>Sign up</BodyRegular>
                <Switch
                  checked={config.signup}
                  onChange={(state) =>
                    setConfig((prev) => ({ ...prev, signup: state }))
                  }
                />
              </Flex>

              <Flex
                align="center"
                justify="between"
                style={{ paddingLeft: "32px" }}
              >
                <BodyRegular>Password reset</BodyRegular>
                <Switch
                  checked={config.password_reset}
                  onChange={(state) =>
                    setConfig((prev) => ({ ...prev, password_reset: state }))
                  }
                />
              </Flex>

              <Flex
                align="center"
                justify="between"
                style={{ paddingLeft: "32px" }}
              >
                <BodyRegular>Users mgmt</BodyRegular>
                <Switch
                  checked={config.users_mgmt}
                  onChange={(state) =>
                    setConfig((prev) => ({
                      ...prev,
                      users_mgmt: state,
                    }))
                  }
                />
              </Flex>

              <Flex
                align="center"
                justify="between"
                style={{ paddingLeft: "32px" }}
              >
                <BodyRegular>File upload mechanism</BodyRegular>
                <Switch
                  checked={config.file_upload}
                  onChange={(state) =>
                    setConfig((prev) => ({
                      ...prev,
                      file_upload: state,
                    }))
                  }
                />
              </Flex>
              <Flex column gap="24px" style={{ padding: "0 0 32px 32px" }}>
                <Flex align="center" justify="between">
                  <BodyRegular>Other</BodyRegular>
                  <Switch
                    checked={showAddMechanism}
                    onChange={(state) => setShowAddMechanism(state)}
                  />
                </Flex>
                {showAddMechanism && (
                  <Flex gap="32px">
                    <InputText
                      width="100%"
                      value={config.extra_mechanisms}
                      placeholder=""
                      onChange={(e) =>
                        setConfig((prev) => ({
                          ...prev,
                          extra_mechanisms: e.target.value,
                        }))
                      }
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>

            <SeparatorHorizontal style={{ width: "100%" }} />

            {/* <Flex align="center" justify="between">
              <Flex column gap="8px" style={{ padding: "32px" }}>
                <BodyMedium>API Testing Required</BodyMedium>
                <BodyRegular>
                  Please indicate whether API testing is required for this
                  particular asset. Note: The default selection is 'Yes'.
                </BodyRegular>
              </Flex>
              <Switch
                checked
                onChange={(checked) =>
                  setConfig((prev) => ({ ...prev, api_testing: checked }))
                }
              />
            </Flex> */}

            {/* <SeparatorHorizontal style={{ width: "100%" }} /> */}

            <Flex column gap="8px" style={{ padding: "32px" }}>
              <BodyMedium>Technologies in Use</BodyMedium>
              <BodyRegular>
                If applicable, please provide details about the technologies and
                frameworks being used in your application. This information can
                help us tailor the penetration test to your specific needs.
              </BodyRegular>
              <br />
              <TagsLine
                placeholder="Enter a technology"
                isEditable
                selectedTags={config.tech_in_use || []}
                onTagAdd={(tech) =>
                  setConfig((prev) => ({
                    ...prev,
                    tech_in_use: [...prev.tech_in_use, tech],
                  }))
                }
                onTagRemove={(tech) =>
                  setConfig((prev) => ({
                    ...prev,
                    tech_in_use: prev.tech_in_use.filter((t) => t !== tech),
                  }))
                }
              />
            </Flex>

            <SeparatorHorizontal style={{ width: "100%" }} />

            <Flex column gap="8px" style={{ padding: "32px" }}>
              <BodyMedium>Data Sensitivity</BodyMedium>
              <BodyRegular>
                Please specify the sensitivity level of the data involved in the
                test. Choose ‘PII’ for Personally Identifiable Information,
                ‘PHI’ for Protected Health Information, and ‘non-sensitive’ for
                data that doesn’t contain personally identifiable or protected
                health information.
              </BodyRegular>
              <Dropdown
                onChange={(opt) =>
                  setConfig((prev) => ({
                    ...prev,
                    data_sensitivity: `${opt?.value}`,
                  }))
                }
                options={sensitivityOptions}
                value={sensitivityOptions.find(
                  (opt) => opt.value === config.data_sensitivity
                )}
                variant="border"
                isMenuPositionFixed
                width="100px"
              />
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};
