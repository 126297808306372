import { Dispatch, MutableRefObject, SetStateAction, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../../components/elements/box/Box";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../../components/elements/icon/Icon";
import { OptionalField } from "../../../../components/elements/requiredField/RequiredField";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { MITIGATION } from "../../../../shared/consts";
import {
  onRichTextChangedHandler,
  mapToOptions,
  getOptionFromKeyValuePairs,
  useUpdateFindingInPlace,
  FormModeState,
} from "../../../../shared/formUtils";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { RichTextEditorWASP } from "../../../../components/elements/richTextEditor/RichTextEditor";
import { uploadFindingTextAndImages } from "../ImageUtils";
import { useParams } from "react-router";
import {
  useApiCreateFindingImages,
  useApiUpdateFinding,
} from "../../../../hooks/queries/findingContext";
import useToastContext from "../../../../hooks/toastHook";

type Props = {
  formMode: FormModeState;
  createFindingData: AdminFindingEdit;
  setCreateFinding: Dispatch<SetStateAction<AdminFindingEdit>>;
  updateFindingData?: Finding;
  setUpdateFinding: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  clearTrigger: MutableRefObject<boolean>;
  valueTrigger: MutableRefObject<boolean>;
};

export const MitigationAssessment = (props: Props) => {
  const {
    formMode,
    createFindingData,
    setCreateFinding,
    updateFindingData,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
    clearTrigger,
    valueTrigger,
  } = props;

  const theme = useContext(ThemeContext);
  const { id: updateFindingId } = useParams();
  const addToast = useToastContext();
  const { mutate: updateFinding } = useApiUpdateFinding({ "admin-mode": true });
  const { mutate: createImage } = useApiCreateFindingImages();

  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  return (
    <Box style={{ width: "100%", marginBottom: "16px" }}>
      <Flex column gap="32px">
        <Flex gap="8px">
          <Icon name="data" size={30} color={theme.primary} />{" "}
          <HeaderSecondary>Mitigation Assessment</HeaderSecondary>
        </Flex>
        <Flex w100 gap="32px">
          <Flex column style={{ width: "50%" }} gap="16px">
            <Flex column>
              <Flex>
                <LabelRegular>Mitigation</LabelRegular>
                <OptionalField />
              </Flex>

              <RichTextEditorWASP
                isHidden
                placeholderText="Detail mitigation strategies and guidelines to prevent future exploits..."
                saveChangesMode={formMode === FormModeState.Update}
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.mitigation_wasp
                    : createFindingData?.mitigation_wasp
                }
                valueTrigger={valueTrigger}
                clearTrigger={clearTrigger}
                onChange={(html: string, markdown: string) => {
                  onRichTextChangedHandler(
                    html,
                    "mitigation_wasp",
                    markdown,
                    "mitigation",
                    formMode === FormModeState.Update
                      ? setEditableUpdateFinding
                      : setCreateFinding
                  );
                }}
                isSaveDisabled={
                  updateFindingData?.mitigation_wasp ===
                    editableUpdateFindingData?.mitigation_wasp ||
                  queryStatus !== "idle"
                }
                onSave={async () => {
                  uploadFindingTextAndImages(
                    parseInt(updateFindingId ? updateFindingId : "0"),
                    editableUpdateFindingData?.description_wasp || "",
                    editableUpdateFindingData?.attack_details_wasp || "",
                    editableUpdateFindingData?.mitigation_wasp || "",
                    editableUpdateFindingData?.description || "",
                    editableUpdateFindingData?.attack_details || "",
                    editableUpdateFindingData?.mitigation || "",
                    editableUpdateFindingData?.description_html || "",
                    editableUpdateFindingData?.attack_details_html || "",
                    editableUpdateFindingData?.mitigation_html || "",
                    createImage,
                    updateFinding,
                    undefined,
                    addToast
                  );
                }}
                onCancel={() => {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    mitigation_wasp: updateFindingData?.mitigation_wasp,
                    mitigation: updateFindingData?.mitigation,
                  }));
                }}
              />
            </Flex>
          </Flex>
          <Flex column style={{ width: "50%" }} gap="32px">
            <Flex column gap="8px">
              <Flex>
                <LabelRegular>Mitigation Complexity</LabelRegular>
                <OptionalField />
              </Flex>

              <Dropdown
                placeholder="Select mitigation complexity"
                variant="border"
                size="medium"
                options={mapToOptions(MITIGATION)}
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.mitigation_complexity !==
                        null &&
                      editableUpdateFindingData?.mitigation_complexity !==
                        undefined
                      ? getOptionFromKeyValuePairs(
                          MITIGATION,
                          editableUpdateFindingData.mitigation_complexity
                        )
                      : null
                    : createFindingData?.mitigation_complexity !== null &&
                        createFindingData?.mitigation_complexity !== undefined
                      ? getOptionFromKeyValuePairs(
                          MITIGATION,
                          createFindingData.mitigation_complexity
                        )
                      : null
                }
                onChange={(opt) => {
                  if (opt?.value) {
                    if (formMode === FormModeState.Update) {
                      setEditableUpdateFinding((prev) => ({
                        ...prev,
                        mitigation_complexity: Number(opt.value),
                      }));
                      updateInPlace({
                        mitigation_complexity: Number(opt.value),
                      });
                    }
                    if (formMode === FormModeState.Create) {
                      setCreateFinding((prev) => ({
                        ...prev,
                        mitigation_complexity: Number(opt.value),
                      }));
                    }
                  }
                }}
                disabled={queryStatus !== "idle"}
                queryStatus={
                  changedField === "mitigation_complexity"
                    ? queryStatus
                    : undefined
                }
              />
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>Mitigation Cost</LabelRegular>
                  <OptionalField />
                </Flex>
              </Flex>
              <Dropdown
                placeholder="Select mitigation cost level"
                variant="border"
                size="medium"
                menuPlacement="top"
                options={mapToOptions(MITIGATION)}
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.mitigation_cost !== null &&
                      editableUpdateFindingData?.mitigation_cost !== undefined
                      ? getOptionFromKeyValuePairs(
                          MITIGATION,
                          editableUpdateFindingData.mitigation_cost
                        )
                      : null
                    : createFindingData?.mitigation_cost !== null &&
                        createFindingData?.mitigation_cost !== undefined
                      ? getOptionFromKeyValuePairs(
                          MITIGATION,
                          createFindingData.mitigation_cost
                        )
                      : null
                }
                onChange={(opt) => {
                  if (opt?.value) {
                    if (formMode === FormModeState.Update) {
                      setEditableUpdateFinding((prev) => ({
                        ...prev,
                        mitigation_cost: Number(opt.value),
                      }));
                      updateInPlace({
                        mitigation_cost: Number(opt.value),
                      });
                    }
                    if (formMode === FormModeState.Create) {
                      setCreateFinding((prev) => ({
                        ...prev,
                        mitigation_cost: Number(opt.value),
                      }));
                    }
                  }
                }}
                disabled={queryStatus !== "idle"}
                queryStatus={
                  changedField === "mitigation_cost" ? queryStatus : undefined
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
