import { IconProps } from "./WaspIcon";

export function UserIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 11.5548C12.7563 11.5548 13.4956 11.3306 14.1244 10.9104C14.7532 10.4902 15.2433 9.89304 15.5327 9.19433C15.8221 8.49563 15.8979 7.72679 15.7503 6.98504C15.6028 6.2433 15.2386 5.56196 14.7038 5.02719C14.1691 4.49243 13.4877 4.12824 12.746 3.9807C12.0042 3.83316 11.2354 3.90888 10.5367 4.1983C9.83798 4.48771 9.24078 4.97782 8.82062 5.60664C8.40046 6.23546 8.17619 6.97475 8.17619 7.73103C8.1773 8.74482 8.58053 9.71677 9.29739 10.4336C10.0142 11.1505 10.9862 11.5537 12 11.5548ZM12 5.10723C12.5189 5.10723 13.0262 5.26111 13.4577 5.54942C13.8892 5.83773 14.2255 6.24751 14.4241 6.72694C14.6227 7.20638 14.6746 7.73394 14.5734 8.24291C14.4721 8.75187 14.2222 9.21939 13.8553 9.58634C13.4884 9.95328 13.0208 10.2032 12.5119 10.3044C12.0029 10.4057 11.4753 10.3537 10.9959 10.1551C10.5165 9.95651 10.1067 9.62022 9.81838 9.18873C9.53008 8.75725 9.37619 8.24997 9.37619 7.73103C9.37689 7.03537 9.65355 6.3684 10.1455 5.87649C10.6374 5.38459 11.3043 5.10793 12 5.10723ZM17.988 16.9548V19.4931C17.988 19.6522 17.9248 19.8048 17.8123 19.9173C17.6997 20.0299 17.5471 20.0931 17.388 20.0931C17.2289 20.0931 17.0763 20.0299 16.9637 19.9173C16.8512 19.8048 16.788 19.6522 16.788 19.4931V16.9548C16.7872 16.1594 16.4708 15.3969 15.9084 14.8344C15.346 14.272 14.5834 13.9557 13.788 13.9548H10.212C9.4166 13.9557 8.65402 14.272 8.09159 14.8344C7.52916 15.3969 7.21282 16.1594 7.21199 16.9548V19.4931C7.21199 19.6522 7.14878 19.8048 7.03626 19.9173C6.92374 20.0299 6.77112 20.0931 6.61199 20.0931C6.45286 20.0931 6.30025 20.0299 6.18773 19.9173C6.07521 19.8048 6.01199 19.6522 6.01199 19.4931V16.9548C6.01333 15.8413 6.45625 14.7738 7.24362 13.9865C8.03098 13.1991 9.09849 12.7562 10.212 12.7548H13.788C14.9015 12.7562 15.969 13.1991 16.7564 13.9865C17.5437 14.7738 17.9867 15.8413 17.988 16.9548Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
