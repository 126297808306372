import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.interaction.mode = "index";
ChartJS.defaults.interaction.intersect = false;
ChartJS.defaults.scales.linear.min = 0;

export type LineChartDataset = {
  label: string;
  data: number[];
  borderColor: string;
};

type Props = {
  datasets: LineChartDataset[];
  labels: string[];
  height: number;
  options?: { [key: string]: any };
  dataTestId?: string;
  tooltipLabelCallback?: (context: TooltipItem<"line">) => string;
};

export const LineChart = ({
  datasets,
  labels,
  height,
  options,
  dataTestId,
  tooltipLabelCallback,
}: Props) => {
  const theme = useContext(ThemeContext);

  const data = labels && datasets && { labels, datasets };
  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.3,
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        backgroundColor: theme.chartsTooltip,
        bodyColor: theme.textBody,
        titleColor: theme.textHeader,
        usePointStyle: true,
        cornerRadius: 0,
        boxHeight: 7,
        boxPadding: 12,
        callbacks: {
          label:
            tooltipLabelCallback ||
            ((context) => `${context.dataset.label}: ${context.parsed.y}`),
        },
      },
    },
  };
  return (
    <div data-testid={dataTestId} style={{ height: `${height}px` }}>
      {data && (
        <Line
          options={options ? options : defaultOptions}
          data={data}
          height={height - 11}
        />
      )}
    </div>
  );
};
