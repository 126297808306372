import React, { useContext, useRef, useState } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { HeaderSubBold } from "../../../../components/elements/typography/Typography";
import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { JiraIntegrationModalFooter } from "./JiraIntegrationModalFooter";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import { Box } from "../../../../components/elements/box/Box";
import { SelectionTabButton } from "../../../../components/elements/button/selectionTab/SelectionTabButton";
import { NotFoundBanner } from "../../../../components/composed/banner/NotFoundBanner";
import {
  useDebounceCallback,
  useInfiniteScroll,
} from "../../../../hooks/utilsHooks";
import { JiraProjectMappingSetup } from "./JiraProjectMappingSetup";
import { useApiJiraProjectsPaging } from "../../../../hooks/queries/jiraIntegrationContext";
import {
  JiraField,
  JiraProject,
  JiraProjectMapping,
} from "../../../../types/JiraIntegration";
import { JiraProjectsListSkeleton } from "./JiraProjectsListSkeleton";
import { Loading } from "../../../../components/elements/loading/Loading";

type Props = {
  onNext: (jiraProjectsMapping: JiraProjectMapping[]) => void;
  onPrev: () => void;
  jiraProjectsMapping: JiraProjectMapping[];
};

const emptyFiled: JiraField = {
  id: "",
  name: "",
};

const emptyJiraProjectMapping: JiraProjectMapping = {
  enabled: false,
  auto_send: false,
  default_label: "",
  issue_type: emptyFiled,
  project_key: "",
  project_name: "",
  presented: emptyFiled,
  re_test: emptyFiled,
  dismissed: emptyFiled,
  fixed: emptyFiled,
  in_progress: emptyFiled,
  acceptable_risk: emptyFiled,
};

export const ProjectsSetup = (props: Props) => {
  const { onNext, onPrev, jiraProjectsMapping } = props;
  const theme = useContext(ThemeContext);
  const [readyForNext, setReadyForNext] = useState<boolean>(false);
  const [searchWord, setSearchWord] = useState<string>("");
  const {
    data: jiraProjectsPages,
    hasNextPage,
    fetchNextPage,
    isFetching: isFetchingJiraProjects,
    isFetchingNextPage: isFetchingNextPageJiraProjects,
  } = useApiJiraProjectsPaging(searchWord);
  const [_jiraProjectsMapping, set_JiraProjectsMapping] =
    useState<JiraProjectMapping[]>(jiraProjectsMapping);
  const [selectedProject, setSelectedProject] =
    useState<JiraProject | null>(null);

  const getJiraProjects = (): JiraProject[] => {
    return (
      jiraProjectsPages?.pages?.map((page) => page.results || []).flat() || []
    );
  };
  const onSearch = useDebounceCallback((textQuery) => setSearchWord(textQuery));
  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(
    observerElemForFetchPage,
    hasNextPage || false,
    fetchNextPage
  );

  const handleMappingChange = (jiraProjectMapping: JiraProjectMapping) => {
    const currentMapping = _jiraProjectsMapping.find(
      (p) => p.project_key === jiraProjectMapping.project_key
    );
    const tempMappingList = !currentMapping
      ? [..._jiraProjectsMapping, jiraProjectMapping]
      : _jiraProjectsMapping;
    const newMapping = tempMappingList.map((p) =>
      p.project_key === jiraProjectMapping.project_key
        ? jiraProjectMapping
        : jiraProjectMapping.auto_send
        ? { ...p, auto_send: false } // only one project can be auto send
        : p
    );
    set_JiraProjectsMapping(newMapping);
    const atLeastOneEnabled = newMapping.some((p) => p.enabled);
    const oneIsAutoSend =
      newMapping.filter((p) => p.auto_send).length in [0, 1];
    const isReady = newMapping.every(
      (p) =>
        !p.enabled ||
        (p.enabled &&
          p.issue_type.id &&
          p.presented.id &&
          p.re_test.id &&
          p.dismissed.id &&
          p.fixed.id &&
          p.in_progress.id &&
          p.acceptable_risk.id)
    );
    setReadyForNext(oneIsAutoSend && atLeastOneEnabled && isReady);
  };

  return (
    <Flex column gap="24px" justify="between" h100>
      <Flex column gap="24px" w100>
        <Flex column gap="16px">
          <HeaderSubBold>Projects Setup</HeaderSubBold>
          <BodyRegular style={{ color: theme.black700 }}>
            Please select and enable the Jira projects you'd like to integrate
            with, and setup each of them for your needs
          </BodyRegular>
        </Flex>
        <Flex gap="24px" w100 h100>
          <Flex
            column
            gap="16px"
            style={{
              width: "405px",
            }}
          >
            <InputText
              placeholder="Search"
              iconName="search"
              onChange={(e) => onSearch(e.target.value)}
              width={"100%"}
              className="me-2"
            />
            {isFetchingJiraProjects && !isFetchingNextPageJiraProjects ? (
              <JiraProjectsListSkeleton />
            ) : (
              <Flex
                column
                gap="16px"
                w100
                h100
                className="pe-2"
                style={{ overflow: "scroll", maxHeight: "480px" }}
              >
                {getJiraProjects().map((project, i) => (
                  <SelectionTabButton
                    label={project.name}
                    key={`project-jira-${project.key}-${i}`}
                    onClick={() => setSelectedProject(project)}
                    selected={selectedProject?.key === project.key}
                    isActive={_jiraProjectsMapping.some(
                      (p) => p.project_key === project.key && p.enabled
                    )}
                    automatic={_jiraProjectsMapping.some(
                      (p) => p.project_key === project.key && p.auto_send
                    )}
                  />
                ))}
                <Flex ref={observerElemForFetchPage} style={{ height: "50px" }}>
                  {isFetchingNextPageJiraProjects && <Loading />}
                </Flex>
              </Flex>
            )}
          </Flex>
          <Flex
            column
            style={{
              width: "calc(100% - 405px)",
            }}
          >
            {selectedProject ? (
              <JiraProjectMappingSetup
                jiraProjectMapping={
                  _jiraProjectsMapping.find(
                    (p) => p.project_key === selectedProject.key
                  ) || emptyJiraProjectMapping
                }
                jiraProject={selectedProject}
                onChange={handleMappingChange}
              />
            ) : (
              <Box
                className="d-flex w-100 justify-content-center"
                style={{ borderRadius: "8px" }}
              >
                <NotFoundBanner
                  title="No Projects Were Selected"
                  text="Please Select fields on the left to integrate a project."
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
      <JiraIntegrationModalFooter
        onPrev={onPrev}
        readyForNext={readyForNext}
        onNext={() => onNext(_jiraProjectsMapping)}
      />
    </Flex>
  );
};
