import React, { useContext, useState } from "react";
import { Modal } from "../../components/elements/modal/Modal";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
  LabelMedium,
} from "../../components/elements/typography/Typography";
import { Illustration } from "../../components/elements/illustrations/Illustration";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";
import { InputTextWithLabels } from "../../components/composed/formControls/InputTextWithLabels";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { ThemeContext } from "styled-components";

type Props = {
  onClose: () => void;
};

type ContactUsFormData = {
  name: string;
  email: string;
  role?: string;
  phone?: string;
  company_name?: string;
};

export const QuickScanContactUsModal = ({ onClose }: Props) => {
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const theme = useContext(ThemeContext);
  const [formData, setFormData] = useState<ContactUsFormData>({
    name: "",
    email: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleFormSubmit = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setSubmitError(null);
    fetch("/api/v1/contact-us", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Form submitted successfully");
          setSubmitSuccess(true);
          setTimeout(() => onClose(), 3000);
        } else {
          console.error("Form submit failed");
          setSubmitError("Failed to submit form. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Form submit failed", error);
        setSubmitError("Failed to submit form. Please try again later.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      onClose={onClose}
      width={isMobile ? "99%" : "50%"}
      style={{
        position: isMobile ? "absolute" : "fixed",
      }}
    >
      {submitSuccess ? (
        <Flex column gap="16px" align="center">
          <HeaderSecondary>Successfully Submitted!</HeaderSecondary>
          <Illustration
            name="success"
            style={{ height: "120px", width: "fit-content" }}
          />
          <BodyRegular className="text-center">
            We will reach out to you as soon as possible
          </BodyRegular>
        </Flex>
      ) : (
        <Flex column gap="24px">
          <Flex column gap="16px" align="center">
            <HeaderSecondary>Contact Us</HeaderSecondary>
          </Flex>
          <Flex column={isMobile} justify="between" gap="16px">
            <Flex column gap="16px" align="center">
              <BodyRegular className="text-center">
                Leave your details and we will reach you back as soon as
                possible
              </BodyRegular>

              <Illustration
                name="education"
                style={{ height: "180px", width: "fit-content" }}
              />
              <LabelMedium
                className="text-center"
                style={{ color: theme.redPrimary }}
              >
                {submitError}
              </LabelMedium>
            </Flex>
            <Flex column gap="16px" w100 align="center">
              <InputTextWithLabels
                label="Full Name"
                width="300px"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                onValidated={(isValid) => setIsFormValid(isValid)}
              />

              <InputTextWithLabels
                label="Email Address"
                width="300px"
                type="email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                onValidated={(isValid) => setIsFormValid(isValid)}
              />

              <InputTextWithLabels
                label="Phone Number"
                optional
                width="300px"
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
              <InputTextWithLabels
                label="Role"
                optional
                width="300px"
                onChange={(e) =>
                  setFormData({ ...formData, role: e.target.value })
                }
              />
              <InputTextWithLabels
                label="Company Name"
                optional
                width="300px"
                onChange={(e) =>
                  setFormData({ ...formData, company_name: e.target.value })
                }
              />
              <MainButton
                size="small"
                disabled={!isFormValid || !formData.name || !formData.email}
                inProgress={isSubmitting}
                onClick={handleFormSubmit}
                label="Submit"
                style={{
                  width: "300px",
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};
