import { useContext } from "react";
import { Modal } from "../../components/elements/modal/Modal";
import { Flex } from "../../components/layouts/flex/Flex";
import { useApiMe } from "../../hooks/queries/meContext";
import { HeaderSecondary } from "../../components/elements/typography/Typography";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";
import { TextButton } from "../../components/elements/button/text/TextButton";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { Illustration } from "../../components/elements/illustrations/Illustration";

type Props = {
  onClose: () => void;
  switchToExpressTest: () => void;
};

export const RequestsModal = (props: Props) => {
  const { onClose, switchToExpressTest } = props;
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();

  return (
    <Modal width="491px" onClose={onClose}>
      <Flex column gap="24px" align="center">
        <Illustration name="search" />
        <HeaderSecondary>Hey {me?.first_name}</HeaderSecondary>
        <HeaderSecondary>Ready to Schedule a PT ?</HeaderSecondary>
        <MainButton
          label="Express Test"
          style={{ width: "100%" }}
          onClick={switchToExpressTest}
          size="medium"
        />
        <Link
          to="/projects/scheduler"
          style={{ textDecoration: "none", width: "100%" }}
        >
          <SecondaryButton
            onClick={() => {}}
            label="Custom Test"
            style={{ width: "100%" }}
            size="medium"
          />
        </Link>
        <TextButton label="Cancel" color={theme.black800} onClick={onClose} />
      </Flex>
    </Modal>
  );
};
