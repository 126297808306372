import { CSSProperties } from "react";
import {
  BodyRegular,
  KPIHuge,
  KPILarge,
  KPISmall,
} from "../../elements/typography/Typography";
import { Tooltip } from "../../elements/tooltip/Tooltip";

export type KPISize = "huge" | "large" | "small";

type Props = {
  count?: number;
  size?: KPISize;
  color?: string;
  style?: CSSProperties;
};

const formatCounter = (num: number): string => {
  if (num < 1000) {
    return num.toString();
  } else {
    const thousands = Math.floor(num / 1000);
    const remainder = num % 1000;

    // If there's a remainder in the hundreds, format with decimal (e.g., 6.7K).
    // currently we don't expect assets or findings can pass 999,999 objects
    if (remainder >= 100) {
      const decimalPart = Math.floor(remainder / 100);
      return `${thousands}.${decimalPart}K`;
    } else {
      return `${thousands}K`;
    }
  }
};

export const KPI = (props: Props) => {
  const { count = 0, size = "small", color, style } = props;

  const counter = formatCounter(count);

  return (
    <Tooltip
      content={<BodyRegular>{count}</BodyRegular>}
      isActive={counter.includes("K")} // Only show tooltip if number is 1000 or above
    >
      {size === "huge" ? (
        <KPIHuge color={color} style={style}>
          {counter}
        </KPIHuge>
      ) : size === "large" ? (
        <KPILarge color={color} style={style}>
          {counter}
        </KPILarge>
      ) : (
        <KPISmall color={color} style={style}>
          {counter}
        </KPISmall>
      )}
    </Tooltip>
  );
};
