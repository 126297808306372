import { Account } from "../../../types/Account";
import { Box } from "../../elements/box/Box";
import { TextButton } from "../../elements/button/text/TextButton";
import { Flex } from "../../layouts/flex/Flex";
import { TagBadge } from "../tagsLine/TagBadge";
import { AccountsDropdown } from "./AccountsDropdown";

type Props = {
  allowedAccounts: Account[];
  onChange: (accounts: Account[]) => void;
  onSelectAll: () => void;
};

export const AllowedAccountsMultiSelect = (props: Props) => {
  const { allowedAccounts, onChange, onSelectAll } = props;

  return (
    <Box>
      <Flex column gap="12px">
        <AccountsDropdown
          onSelect={(account) =>
            !!account && onChange([...allowedAccounts, account])
          }
          placeholder="Select Allowed Users"
          selected={allowedAccounts}
        />
        <Flex justify="around" w100>
          <TextButton label="Clear All" onClick={() => onChange([])} />
          <TextButton label="Select All" onClick={onSelectAll} />
        </Flex>
        <Flex
          w100
          flexWrap
          gap="8px"
          style={{ maxHeight: "200px", overflow: "auto" }}
        >
          {allowedAccounts.map((a) => (
            <TagBadge
              isEditable
              option={{ value: a.id, label: a.user.name || a.email }}
              key={a.id}
              onDeleteOption={(opt) =>
                onChange(allowedAccounts.filter((a) => a.id !== opt?.value))
              }
            />
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};
