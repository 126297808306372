import React from "react";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { AssetLog, AssetLogAction } from "../../../types/AssetLog";
import { AssetLogUpdatedCard } from "./AssetLogUpdatedCard";
import { CardHeader } from "../../findings/findingHistoryAndComments/CardHeader";

type Props = {
  assetLog: AssetLog;
};

export function AssetLogCard({ assetLog }: Props) {
  const getActionText = (action: AssetLogAction): string => {
    switch (action) {
      case "ASSET_DELETED":
        return `Asset has been deleted`;
      case "ASSET_CREATED":
        return `Asset has been created`;
      default:
        return `Asset has been changed`;
    }
  };

  if (assetLog.action === "ASSET_UPDATED") {
    return <AssetLogUpdatedCard assetLog={assetLog} />;
  }

  return (
    <Box>
      <Flex column gap="16px">
        <CardHeader
          userName={assetLog.actor.name}
          avatarUrl={assetLog.actor.avatar_url}
          createdAt={assetLog.created_at}
        />

        <Flex align="center" gap="8px" flexWrap>
          <BodyRegular>{getActionText(assetLog.action)}</BodyRegular>
        </Flex>
      </Flex>
    </Box>
  );
}
