import { Dropdown, Option } from "../../elements/dropdowns/Dropdown";
import { SingleValue, components } from "react-select";
import { SEVERITIES } from "../../../shared/consts";
import RiskLegendItem from "../../elements/legend/RiskLegendItem";

export const SeverityDropdown = ({
  value,
  onChange,
  dataTestId,
  width,
  disabled,
  queryStatus,
  isError,
  placeholder,
  variant,
  size = "medium",
}: {
  onChange: (option: SingleValue<Option>) => void;
  value: SingleValue<Option>;
  dataTestId?: string;
  width?: string;
  disabled?: boolean;
  isError?: boolean;
  placeholder?: string;
  queryStatus?: "error" | "idle" | "loading" | "success";
  variant?: "border" | "outline";
  size?: "small" | "medium" | "large";
}) => {
  const { Option } = components;

  const SeverityOption = (props: any) => (
    <Option {...props}>
      <RiskLegendItem label={props.data.label} handleLegendClick={() => {}} />
    </Option>
  );

  const fontSizeMap = {
    small: 12,
    medium: 14,
    large: 16,
  };

  const SeverityValue = (props: any) => {
    const severity = props.selectProps?.value.label;
    return (
      <components.SingleValue {...props}>
        <div
          data-testid={`${dataTestId}-${severity}`}
          className="d-flex align-items-center dropdown-container w-100"
        >
          <RiskLegendItem label={severity} size={fontSizeMap[size]} />
        </div>
      </components.SingleValue>
    );
  };

  return (
    <div style={{ minWidth: "120px" }}>
      <Dropdown
        size={size}
        variant={variant}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        width={width}
        options={Object.keys(SEVERITIES).map((key) => ({
          value: key,
          label: SEVERITIES[key],
        }))}
        customComponents={
          value
            ? {
                Option: SeverityOption,
                SingleValue: SeverityValue,
              }
            : { Option: SeverityOption }
        }
        isMenuPositionFixed
        disabled={disabled}
        queryStatus={queryStatus}
        isError={isError}
      />
    </div>
  );
};
