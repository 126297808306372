import { useEffect, useState } from "react";
import { SecondaryButton } from "../../elements/button/secondary/SecondaryButton";
import useToastContext from "../../../hooks/toastHook";
import { useApiContactSales } from "../../../hooks/queries/contactSalesContext";

export const ContactSalesButton = () => {
  const addToast = useToastContext();

  const {
    mutate: sendMessageToSales,
    data: contactSalesResponse,
    error: contactSalesError,
    reset: resetContactSalesStatus,
    status: contactSalesStatus,
  } = useApiContactSales();

  const isContactSalesButtonDisabled =
    contactSalesStatus === "loading" ||
    contactSalesStatus === "error" ||
    contactSalesStatus === "success";

  const [toasted, setToasted] = useState(false);

  useEffect(() => {
    if (toasted) return;
    if (contactSalesStatus === "success") {
      addToast({ type: "info", message: contactSalesResponse.message });
      setToasted(true);
    }
    if (contactSalesStatus === "error") {
      addToast({ type: "error", message: contactSalesError.message });
      setToasted(true);
      resetContactSalesStatus();
      setTimeout(() => setToasted(false), 4000);
    }
  }, [
    contactSalesStatus,
    contactSalesResponse,
    contactSalesError,
    addToast,
    toasted,
    resetContactSalesStatus,
  ]);

  return (
    <SecondaryButton
      label="Contact Sales"
      onClick={() => sendMessageToSales({}, {})}
      disabled={isContactSalesButtonDisabled}
      size="medium"
    />
  );
};
