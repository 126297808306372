import React, { useContext } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Ellipse } from "../../../components/elements/ellipse/Ellipse";
import { ThemeContext } from "styled-components";
import { BodyRegular } from "../../../components/elements/typography/Typography";

type Props = {
  title: string;
  text: string;
};

export const ScanIdentifierCard = (props: Props) => {
  const { title, text } = props;
  const theme = useContext(ThemeContext);
  return (
    <Box>
      <Flex column gap="16px">
        <Flex align="center" gap="8px">
          <Ellipse color={theme.primary} size={8} />
          <BodyRegular style={{ color: theme.black800 }}>{title}</BodyRegular>
        </Flex>
        <BodyRegular style={{ color: theme.black800 }}>{text}</BodyRegular>
      </Flex>
    </Box>
  );
};
