import React, { useState } from "react";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { Flex } from "../../../components/layouts/flex/Flex";
import { TabButton } from "../../../components/elements/button/tab/TabButton";
import { CtiItem } from "../../../types/CtiItem";
import { CtiCustomersTab } from "./sub-components/CtiCustomersTab";
import { CtiEditTab } from "./sub-components/CtiEditTab";

type Props = {
  onClose: () => void;
  selectedCtiItem: CtiItem;
};

export const CtiItemPane = (props: Props) => {
  const { onClose, selectedCtiItem } = props;
  const [selectedTab, setTab] = useState<"customers" | "edit">("customers");

  return (
    <RightPane onClose={onClose}>
      <Flex w100 h100 column>
        <Flex w100 align="center" justify="around" padding="16px">
          <TabButton
            label={"Customers"}
            onClick={() => {
              setTab("customers");
            }}
            selected={selectedTab === "customers"}
            iconName={"user"}
          />
          <TabButton
            label={"Edit Post"}
            onClick={() => {
              setTab("edit");
            }}
            selected={selectedTab === "edit"}
            iconName={"write"}
          />
        </Flex>
        <Flex h100 w100 column padding="16px">
          {selectedTab === "customers" && (
            <CtiCustomersTab selectedCtiItem={selectedCtiItem} />
          )}
          {selectedTab === "edit" && (
            <CtiEditTab selectedCtiItem={selectedCtiItem} />
          )}
        </Flex>
      </Flex>
    </RightPane>
  );
};
