import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { light } from "../../../shared/theme";

export const Methodology = () => {
  return (
    <Flex
      column
      w100
      gap="8px"
      className="mt-3 fully-justify"
      style={{ pageBreakAfter: "always" }}
    >
      <HeaderSecondary color={light.blue800}>Methodology </HeaderSecondary>
      <BodyRegular color={light.black800}>
        OP Innovate’s Testing Methodology simulated an external threat actor
        using a Grey Box methodology - a method that simulates an attack
        campaign using intelligence gathering and applicative TTPs (Tactics,
        Techniques and Procedures). These are used to bypass existing
        application restrictions and breach information related to the system
        and its users. A Grey Box penetration provides the organization with a
        real world experience that includes findings from an overall hacking
        campaign on the organization assets and thus increases the cyber
        resilience of the organization and the staff.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        OP Innovate’s Grey Box methodology incorporates the MITRE ATT&CK Matrix
        and OWASP Top 10 for APIs where relevant.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        As per the project’s scope, the Red Team began researching the target
        and gathering information that could potentially have an impact on the
        application, customers or other in-scope components. Acquiring
        information such as business purposes, personnel, technological
        platforms, employees etc is an important part of the penetration testing
        processes.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        Note: While the Red Team did not fully exploit all the vulnerabilities
        found, it should be considered that a motivated attacker not subjected
        to any time & resource limitations could achieve a full exploitation.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        In terms of the defensive aspect, the Red Team recommended some
        mitigation actions to help close the security gaps found. Risk
        management should be an important objective in the goal of making the
        network as resilient as possible against threats of all kinds.
      </BodyRegular>
    </Flex>
  );
};
