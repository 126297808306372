import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./customReactFlow.css";
import "@xyflow/react/dist/style.css";
import { Asset } from "../../../../types/Asset";
import { CustomControls } from "./CustomControls";
import {
  applyNodeChanges,
  Edge,
  Node,
  OnNodesChange,
  OnEdgesChange,
  ReactFlow,
  applyEdgeChanges,
  OnConnect,
  addEdge,
  DefaultEdgeOptions,
} from "@xyflow/react";
import {
  createGroupsLayout,
  createNodesLayout,
  getPositionOfNodeDragOutsideGroup,
} from "./NodeLayoutManager";
import { useApiAssetsPaging } from "../../../../hooks/queries/assetsContext";
import { GraphGroupNode, GraphNode, NodeTypes } from "./GraphTypes";
import {
  calcGroupRisk,
  getNodeRiskColor,
  mapGraphNodeToFlowNode,
} from "./GraphHelper";
import { ThemeContext } from "styled-components";
import { Loading } from "../../../../components/elements/loading/Loading";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { Flex } from "../../../../components/layouts/flex/Flex";

type Props = {
  asset: Asset;
};

export const AssetIsolateGraph = ({ asset }: Props) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const theme = useContext(ThemeContext);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect: OnConnect = useCallback(
    (connection) => {
      const { source, target } = connection;
      const sourceNodeExists = nodes.some((n) => n.id === source);
      const targetNodeExists = nodes.some((n) => n.id === target);
      if (sourceNodeExists && targetNodeExists) {
        setEdges((eds) => addEdge(connection, eds));
      } else {
        console.warn(`Invalid connection from ${source} to ${target}`);
      }
    },
    [nodes]
  );

  const defaultEdgeOptions: DefaultEdgeOptions = {
    // animated: true,
    style: { stroke: theme.black600, strokeWidth: 1 }, // Set edge color and width
  };

  const { data: products } = useApiProducts();

  const { data: assetsPages, isFetching: isFetchingAssets } =
    useApiAssetsPaging(
      !!asset.parent_asset
        ? {
            id: asset.parent_asset,
          }
        : {
            parent_asset: asset.id,
          },
      !!asset
    );

  const assets = useMemo(
    () => [
      ...(assetsPages?.pages.flatMap((page) => page.results || []) || []),
      asset,
    ],
    [assetsPages, asset]
  );

  const mapToReactFlowNode = useCallback(
    (node: GraphNode, isExpand: boolean, showLabel: boolean): Node => {
      return mapGraphNodeToFlowNode(
        node,
        getNodeRiskColor(node.risk, true, theme, false),
        isExpand,
        showLabel
      );
    },
    [theme]
  );

  useEffect(() => {
    if (assets.length === 0 || !products) {
      return;
    }
    // group assets by product
    const groupedAssets = assets.reduce(
      (acc, asset) => {
        const product = asset.product || "Unknown";
        if (!acc[product]) {
          acc[product] = [];
        }
        acc[product].push({
          id: `a${asset.id}`,
          groupId: product.toString(),
          risk: asset.risk_score || 0,
          x: 0,
          y: 0,
          name: asset.name,
          createdAt: asset.created_at,
        });
        return acc;
      },
      {} as Record<string, GraphNode[]>
    );

    // map to layout manager object
    const groups: GraphGroupNode[] = Object.keys(groupedAssets).map(
      (groupId) => ({
        id: groupId,
        center: { x: 0, y: 0 },
        radius: 0,
        nodes: groupedAssets[groupId],
        isExpand: true,
      })
    );
    createGroupsLayout(groups);

    const groupNodes = groups.map((group) => {
      createNodesLayout(group.radius, groupedAssets[group.id]);

      const groupRisk = calcGroupRisk(group);
      return {
        id: group.id,
        data: {
          label:
            products?.find((p) => p.id === Number(group.id))?.name || group.id,
          radius: group.radius,
          x: group.center.x,
          y: group.center.y,
          nodesCount: group.nodes.length,
          isGroup: true,
          risk: groupRisk,
          color: getNodeRiskColor(groupRisk, true, theme, false),
          isExpand: true,
          onClick: undefined,
        },
        type: "assetsGroup",
        position: {
          x: group.center.x,
          y: group.center.y,
        },
      };
    });

    const assetsNodes = Object.values(groupedAssets)
      .flat()
      .map((node) => mapToReactFlowNode(node, true, false));

    const newEdges = assets
      .filter((a) => !!a.parent_asset)
      .map((asset) => ({
        id: `e${asset.parent_asset}-${asset.id}`,
        source: `a${asset.parent_asset}`,
        target: `a${asset.id}`,
      }));
    setNodes([...groupNodes, ...assetsNodes]);
    setEdges(newEdges);
  }, [assets, mapToReactFlowNode, theme, products]);

  const onNodeDrag = useCallback(
    (event: React.MouseEvent, node: Node) => {
      if (!!node.data?.isGroup || !!node.data?.isHidden) {
        return;
      }
      const groupNode = nodes.find((n) => n.id === node.parentId);
      if (!groupNode) {
        return;
      }
      const newPos = getPositionOfNodeDragOutsideGroup(
        groupNode.data.radius as number,
        node.position.x,
        node.position.y
      );
      if (newPos) {
        setNodes((nds) =>
          nds.map((n) => {
            if (n.id === node.id) {
              // Update node position to stay within the boundary
              return {
                ...n,
                position: newPos,
              };
            }
            return n;
          })
        );
      }
    },
    [setNodes, nodes]
  );
  if (isFetchingAssets) {
    return (
      <Flex w100 h100 align="center" justify="center">
        <Loading />
      </Flex>
    );
  }
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={NodeTypes}
      onNodeDrag={onNodeDrag}
      onNodeDragStop={onNodeDrag}
      defaultEdgeOptions={defaultEdgeOptions}
    >
      <CustomControls />
    </ReactFlow>
  );
};
