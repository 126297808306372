import React from "react";
import { IconProps } from "./WaspIcon";

export function AddIcon(props: IconProps) {
  const size = props.size || 32;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6039 3.99609H9.39609C7.9644 3.99765 6.5918 4.56708 5.57944 5.57944C4.56708 6.5918 3.99765 7.9644 3.99609 9.39609V14.6039C3.99765 16.0356 4.56708 17.4082 5.57944 18.4206C6.5918 19.4329 7.9644 20.0023 9.39609 20.0039H14.6039C16.0356 20.0023 17.4082 19.4329 18.4206 18.4206C19.4329 17.4082 20.0023 16.0356 20.0039 14.6039V9.39609C20.0023 7.9644 19.4329 6.5918 18.4206 5.57944C17.4082 4.56708 16.0356 3.99765 14.6039 3.99609ZM18.8039 14.6039C18.8026 15.7174 18.3596 16.7849 17.5723 17.5723C16.7849 18.3596 15.7174 18.8026 14.6039 18.8039H9.39609C8.2826 18.8026 7.21509 18.3596 6.42773 17.5723C5.64037 16.7849 5.19744 15.7174 5.19609 14.6039V9.39609C5.19744 8.2826 5.64037 7.21509 6.42773 6.42773C7.21509 5.64037 8.2826 5.19744 9.39609 5.19609H14.6039C15.7174 5.19744 16.7849 5.64037 17.5723 6.42773C18.3596 7.21509 18.8026 8.2826 18.8039 9.39609V14.6039ZM15.1219 12C15.1219 12.0788 15.1064 12.1568 15.0763 12.2297C15.0462 12.3025 15.002 12.3686 14.9462 12.4244C14.8905 12.4801 14.8244 12.5243 14.7515 12.5544C14.6787 12.5846 14.6007 12.6 14.5219 12.6H12.6V14.5225C12.6 14.6816 12.5368 14.8342 12.4243 14.9467C12.3117 15.0592 12.1591 15.1225 12 15.1225C11.8409 15.1225 11.6883 15.0592 11.5757 14.9467C11.4632 14.8342 11.4 14.6816 11.4 14.5225V12.6H9.47813C9.319 12.6 9.16638 12.5368 9.05386 12.4243C8.94134 12.3117 8.87813 12.1591 8.87813 12C8.87813 11.8409 8.94134 11.6883 9.05386 11.5757C9.16638 11.4632 9.319 11.4 9.47813 11.4H11.4V9.4776C11.4 9.31847 11.4632 9.16586 11.5757 9.05333C11.6883 8.94081 11.8409 8.8776 12 8.8776C12.1591 8.8776 12.3117 8.94081 12.4243 9.05333C12.5368 9.16586 12.6 9.31847 12.6 9.4776V11.4H14.5219C14.6007 11.4 14.6787 11.4154 14.7515 11.4456C14.8244 11.4757 14.8905 11.5199 14.9462 11.5756C15.002 11.6314 15.0462 11.6975 15.0763 11.7703C15.1064 11.8431 15.1219 11.9212 15.1219 12Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
