import { ReactNode, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import {
  useApiAssetsBreakdown,
  useApiAssetsRiskDistribution,
} from "../../../hooks/queries/assetsContext";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import {
  SCREEN_LAPTOP_WIDTH,
  assetsDistributionConditionsMap,
  assetsDistributionOptions,
  defaultAssetDistribution,
} from "../../../shared/consts";
import { Filter } from "../../../types/AssetsView";
import {
  DistributionChartItemData,
  DistributionItem,
} from "./TopDistributionItem";
import { TopDistributionSkeleton } from "./TopDistributionSkeleton";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SingleValue } from "react-select";
import { Mixpanel } from "../../../shared/mixpanel";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import {
  getGradeFilters,
  gradeToRiskLevelMap,
  priorityOptions,
} from "../AssetUtils";
import { HorizontalScroller } from "../../../components/elements/horizontalScroller/HorizontalScroller";
import { AssetGrade } from "../../../types/Asset";
import { AssetGradeBadge } from "../assetsTable/AssetGradeBadge";

type Props = {
  onChange?: (filters: Filter[]) => void;
  filters: Filter[];
};

export const TopDistribution = (props: Props) => {
  const { filters, onChange } = props;
  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;
  const theme = useContext(ThemeContext);

  const [selectedGroup, setSelectedGroup] = useState<string>("");

  const [distribution, setDistribution] = useState<SingleValue<Option>>(
    defaultAssetDistribution
  );
  const distributionStr = (
    distribution || defaultAssetDistribution
  ).value.toString();

  const { data: products } = useApiProducts();

  const { data: assetsRisks } = useApiAssetsRiskDistribution(
    filters,
    // query should be enabled only if distribution is security grade
    distributionStr === "risk_score"
  );

  const { data: assetsBreakdown, isFetching } = useApiAssetsBreakdown(
    distributionStr,
    "desc",
    filters,
    // query should be disabled if distribution is security grade
    distributionStr !== "risk_score"
  );

  const breakdown =
    distributionStr === "risk_score" ? assetsRisks : assetsBreakdown;

  let sortOptions = [
    { value: "desc", label: "High to Low" },
    { value: "asc", label: "Low to High" },
  ];
  const extraOptions = [
    {
      value: `-${distributionStr}`,
      label: `High to Low ${distribution?.label}`,
    },
    { value: distributionStr, label: `Low to High ${distribution?.label}` },
  ];
  const sortableDistributions = ["risk_score", "priority"];
  sortOptions = sortableDistributions.includes(distributionStr)
    ? [...extraOptions, ...sortOptions]
    : sortOptions;

  const [sorting, setSorting] = useState<SingleValue<Option>>(sortOptions[0]);

  const processData = (data: {
    [key: string]: number;
  }): DistributionChartItemData[] =>
    Object.keys(data)
      .filter((key) => !!key)
      .map((key) => ({ name: key, value: data[key], percentage: 0 }))
      .sort((a, b) => {
        if (["-priority", "risk_score"].includes(`${sorting?.value}`))
          return a.name > b.name ? -1 : 1;
        if (["priority", "-risk_score"].includes(`${sorting?.value}`))
          return b.name > a.name ? -1 : 1;
        if (sorting?.value === "asc") return a.value - b.value;
        return b.value - a.value;
      });

  const processedData = processData(breakdown || {});
  const totalData = processedData.reduce((acc, curr) => acc + curr.value, 0);
  const dataWithPercentage = processedData.map((item) => ({
    ...item,
    percentage: (item.value / totalData) * 100,
  }));

  const getLabel = (distributeBy: string, itemName: string): ReactNode => {
    const labelsMap: { [key: string]: ReactNode } = {
      product_id:
        products?.find((p) => p.id === parseInt(itemName))?.name || "",
      priority:
        priorityOptions.find((p) => p.value === parseInt(itemName))?.label ||
        "",
      risk_score: (
        <Flex align="center" gap="4px">
          <AssetGradeBadge
            assetGrade={itemName.toUpperCase() as AssetGrade}
            isTooltipActive={false}
          />
          <LabelRegular>{gradeToRiskLevelMap[itemName]}</LabelRegular>
        </Flex>
      ),
      type: (
        <LabelRegular style={{ textTransform: "capitalize" }}>
          {itemName.replace("_", " ")}
        </LabelRegular>
      ),
    };

    return Object.keys(labelsMap).includes(distributeBy)
      ? labelsMap[distributeBy]
      : itemName;
  };

  const getFilterKey = (distributeBy: string): string => {
    const filtersMap: { [key: string]: string } = {
      product_id: "product",
    };
    return Object.keys(filtersMap).includes(distributeBy)
      ? filtersMap[distributeBy]
      : distributeBy;
  };

  const getFilter = (selectedGroupName: string, distributeBy: string) =>
    distributeBy === "risk_score"
      ? getGradeFilters(selectedGroupName as AssetGrade)
      : [
          {
            column: getFilterKey(distributeBy),
            value: selectedGroupName,
            condition: assetsDistributionConditionsMap[distributeBy],
            order: 0,
            next_condition: "and",
          },
        ];

  const onClickItem = (selectedGroupName: string) => {
    const isOn = selectedGroup === selectedGroupName;
    setSelectedGroup(isOn ? "" : selectedGroupName);
    if (onChange)
      onChange(isOn ? [] : getFilter(selectedGroupName, distributionStr));
  };

  const onChangeGroupBy = (selectedDistribution: SingleValue<Option>) => {
    Mixpanel.track("Distribution change", {
      selected: selectedDistribution?.label,
    });
    setDistribution(selectedDistribution);
    setSorting(
      sortableDistributions.includes(`${selectedDistribution?.value}`)
        ? {
            value: `-${selectedDistribution?.value}`,
            label: `High to Low ${selectedDistribution?.label}`,
          }
        : { value: "desc", label: "High to Low" }
    );
  };

  return (
    <Box
      style={{
        padding: "16px",
        paddingBottom: "24px",
        gap: isLaptop ? "16px" : "24px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      }}
    >
      <Flex align="center" justify="between" gap="8px" w100>
        <Flex align="center" gap={isLaptop ? "4px" : "16px"}>
          <Flex align="center" gap="8px">
            <Icon name="insightsOutline" size={32} color={theme.primary} />
            <HeaderSubBold>Group By</HeaderSubBold>
          </Flex>
          <SeparatorVertical style={{ height: "16px" }} />
          <Dropdown
            value={distribution}
            onChange={onChangeGroupBy}
            options={assetsDistributionOptions}
          />
        </Flex>
        <Flex align="center">
          <Icon name="sort" color={theme.primary} />
          <Dropdown
            onChange={(option) => {
              Mixpanel.track("Sorting changed", { selected: option?.label });
              setSorting(option);
            }}
            value={sorting}
            options={sortOptions}
          />
        </Flex>
      </Flex>
      {!isFetching && !Object.keys(breakdown || {})?.length && (
        <Flex justify="center" align="center" h100 w100>
          <LabelRegular>No data available</LabelRegular>
        </Flex>
      )}
      {isFetching && !breakdown ? (
        <TopDistributionSkeleton />
      ) : (
        <HorizontalScroller>
          <Flex align="center" gap="8px" w100 justify="between">
            {dataWithPercentage.map((dataItem, idx) => (
              <DistributionItem
                key={`${dataItem.name}-${idx}`}
                dataItemLabel={getLabel(distributionStr, dataItem.name)}
                dataItem={dataItem}
                onClick={onClickItem}
                isSelected={selectedGroup === dataItem.name}
                idx={idx}
              />
            ))}
          </Flex>
        </HorizontalScroller>
      )}
    </Box>
  );
};
