import { useContext } from "react";
import { Asset, AssetCoverageIssue } from "../../../types/Asset";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { CircularProgressBar } from "../../../components/elements/progressBar/CircularProgressBar";

const issuesPercentage = {
  WAF: 20,
  "TLS/SSL": 20,
  ASM: 30,
  PT: 30,
};

const coverageIssuesTypes = Object.keys(
  issuesPercentage
) as AssetCoverageIssue[];

const CoverageTooltip = ({
  coverage_issues,
}: {
  coverage_issues: AssetCoverageIssue[];
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Flex column gap="16px">
      {coverageIssuesTypes.map((issue, i) => (
        <Flex align="center" gap="4px" key={`issue-${i}-${issue}`}>
          <BodyRegular>{issue}:</BodyRegular>
          {!coverage_issues.includes(issue) ? (
            <BodyRegular style={{ color: theme.blue600 }}>
              {`+${issuesPercentage[issue]}%`}
            </BodyRegular>
          ) : (
            <BodyRegular style={{ color: theme.redPrimary }}>
              {`-${issuesPercentage[issue]}%`}
            </BodyRegular>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export const AssetCoverageIndicator = ({
  asset,
  placement,
  color,
  backgroundColor,
}: {
  asset: Asset;
  placement?: "top" | "bottom" | "left" | "right";
  color?: string;
  backgroundColor?: string;
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Tooltip
      placement={placement}
      content={
        <CoverageTooltip coverage_issues={asset.coverage_issues || []} />
      }
    >
      <CircularProgressBar
        background={
          backgroundColor ||
          ((asset.coverage_score || 0) > 50 ? theme.blue100 : theme.red50)
        }
        color={
          color ||
          ((asset.coverage_score || 0) > 50 ? theme.primary : theme.redPrimary)
        }
        value={asset.coverage_score || 0}
      />
    </Tooltip>
  );
};
