import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";

type Props = {
  children: JSX.Element[] | JSX.Element;
  sideBarWidth?: number;
};

export const PageContainer = (props: Props) => {
  const { children, sideBarWidth } = props;
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  return (
    <div
      style={{
        paddingLeft: isMobile
          ? "20px"
          : sideBarWidth
            ? `${sideBarWidth}px`
            : "120px",
        paddingTop: "90px",
        paddingRight: "20px",
        width: "100%",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      <div className="w-100">{children}</div>
    </div>
  );
};
