import { useState } from "react";
import { useApiUpdateCustomer } from "../../../../hooks/queries/customersContext";
import { useApiMe } from "../../../../hooks/queries/meContext";
import useToastContext from "../../../../hooks/toastHook";
import { AwsDetails } from "../../../../types/Customer";
import { Modal } from "../../../../components/elements/modal/Modal";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import {
  BodyRegular,
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import { MainButton } from "../../../../components/elements/button/main/MainButton";

const defaultFormValues = {
  enabled: true,
  access_key_id: "",
  secret_access_key: "",
  region_name: "",
};

type Props = {
  onClose: () => void;
};

export const Route53Integration = (props: Props) => {
  const { onClose } = props;
  const addToast = useToastContext();
  const { data: me } = useApiMe();
  const { mutate: updateCustomer, isLoading } = useApiUpdateCustomer();

  const initFormValues = me?.customer.aws_details || defaultFormValues;
  const [formValues, setFormValues] = useState<AwsDetails>(initFormValues);

  const handleUpdateCustomer = () =>
    updateCustomer({
      customerData: { aws_details: { ...formValues, enabled: true } },
      customerId: me?.customer.id || 0,
      onSuccessCallback: () => {
        addToast({
          message: "AWS details saved successfully",
          type: "success",
        });
        onClose();
      },
      onErrorCallback: (err) =>
        addToast({
          message: `Failed to update AWS details - ${err}`,
          type: "error",
        }),
    });

  return (
    <Modal onClose={onClose} width="470px">
      <Flex column gap="24px" w100>
        <Flex align="center" gap="16px">
          <Icon name="aws" size={32} />
          <HeaderSecondary style={{ textTransform: "none" }}>
            AWS Route53 Integration
          </HeaderSecondary>
        </Flex>
        <SeparatorHorizontal />

        <BodyRegular>
          To integrate with AWS, the attack surface mapping app requires an
          access token, typically obtained after setting up permissions through
          AWS Identity and Access Management (IAM).
        </BodyRegular>

        <Flex align="center" gap="8px" w100>
          <LabelRegular>Access Key ID</LabelRegular>
          <InputText
            isPassword
            value={formValues.access_key_id}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                access_key_id: e.target.value,
              }))
            }
            width="100%"
          />
        </Flex>

        <Flex align="center" gap="8px" w100>
          <LabelRegular>Secret Access Key</LabelRegular>
          <InputText
            isPassword
            value={formValues.secret_access_key}
            width="100%"
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                secret_access_key: e.target.value,
              }))
            }
          />
        </Flex>

        <Flex align="center" gap="8px" w100>
          <LabelRegular>Region Name</LabelRegular>
          <InputText
            value={formValues.region_name}
            width="100%"
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                region_name: e.target.value,
              }))
            }
          />
        </Flex>

        <Flex align="center" justify="end">
          <MainButton
            label="Submit"
            onClick={handleUpdateCustomer}
            inProgress={isLoading}
            size="medium"
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
