import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
  updateContext,
} from "./utils";
import {
  getItems,
  createItem,
  getPage,
  getSingleItem,
  updateItem,
  deleteItem,
} from "./sdk";
import {
  CreditPack,
  CreditPacksPage,
  CreditPacksParams,
} from "../../types/CreditPack";
import { bitAfterNow, getQueryParams } from "../../shared/helper";

const key = "credits";

export interface CreditPackContext {
  creditPackId: number;
  creditPackData: CreditPacksParams;
  onSuccessCallback?: (data: CreditPack) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiCreditPacks = () =>
  useQuery<CreditPack[], Error>({
    queryKey: [key],
    keepPreviousData: true,
    placeholderData: [],
    enabled: true,
    queryFn: async (): Promise<CreditPack[]> => getItems(key),
  });

export const useApiSingleCreditPacks = (id: string) =>
  useQuery<CreditPack | undefined, Error>({
    queryKey: [key, parseInt(id)],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: false,
    queryFn: async (): Promise<CreditPack | undefined> =>
      getSingleItem(key, id) as Promise<CreditPack | undefined>,
  });

export const useApiCreditPacksPaging = (filters?: { [key: string]: any }) =>
  useInfiniteQuery<CreditPacksPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async ({ pageParam = 1 }): Promise<CreditPacksPage> =>
      getPage(key, { ...filters, page: pageParam }).then((resp) => {
        var page = { ...resp };
        const CreditPacksDicts = page?.results || [];
        page.results = CreditPacksDicts as CreditPack[];
        return page;
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiCreateCreditPacks = () => {
  const queryClient = useQueryClient();
  return useMutation<CreditPack, Error, CreditPacksParams>({
    mutationKey: [key],
    mutationFn: async (newCreditPacks): Promise<CreditPack> =>
      await createItem(key, newCreditPacks),
    onSuccess: (data: CreditPack, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiUpdateCreditPack = () => {
  const queryClient = useQueryClient();
  return useMutation<CreditPack, Error, CreditPackContext>({
    mutationKey: [key],
    mutationFn: async ({
      creditPackId,
      creditPackData,
    }: CreditPackContext): Promise<CreditPack> =>
      await updateItem(key, creditPackId, creditPackData),
    onSuccess: (data: CreditPack, variables) => {
      updateContext({ data, queryKey: [key, { id: data?.id }], queryClient });
      variables.onSuccessCallback && variables.onSuccessCallback(data);
    },
    onError: (err, variables) => {
      variables.onErrorCallback && variables.onErrorCallback(err);
    },
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};

export const useApiDeleteCreditPack = () => {
  const queryClient = useQueryClient();
  return useMutation<CreditPack, Error, CreditPackContext>({
    mutationKey: [key],
    mutationFn: async (context): Promise<any> =>
      await deleteItem(key, context.creditPackData, context.creditPackId),
    onSuccess: (data, variables) => {
      variables.onSuccessCallback && variables.onSuccessCallback(data);
      removeItemFromContext({
        data,
        queryKey: [key, { id: variables.creditPackId }],
        queryClient,
      });
    },
    onError: (err, variables) => {
      variables.onErrorCallback && variables.onErrorCallback(err);
    },
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};
