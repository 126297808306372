import { MainButton } from "../../../components/elements/button/main/MainButton";
import { HeaderMain } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { AddAlertModal } from "./AddAlertModal";
import { AlertMessagesTable } from "./sub-components/AlertMessagesTable";
import { useState } from "react";
import { DismissedPane } from "./sub-components/DismissedPane";
import { AlertBannerMessage } from "../../../types/AlertBannerMessage";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";

export const AlertMessagesDashboard = () => {
  const isSuperuser = useIsSuperuser();
  const [showAddAlertModal, setShowAddAlertModal] = useState<boolean>(false);
  const [showDismissedUsers, setShowDismissedUsers] = useState<boolean>(false);
  const [selectedAlert, setSelectedAlert] = useState<AlertBannerMessage>();

  if (!isSuperuser)
    return (
      <HeaderMain>
        Access Denied: You do not have the necessary permissions to view this
        page.
      </HeaderMain>
    );
  return (
    <>
      <Flex column gap="16px" style={{ height: "100vh" }}>
        <Flex justify="between">
          <HeaderMain>Alert Messages - Control Panel</HeaderMain>
          <MainButton
            label={"Add Alert Message"}
            size="small"
            iconName="add"
            onClick={() => {
              setShowAddAlertModal(true);
            }}
          />
        </Flex>
        <AlertMessagesTable
          setShowDismissedUsers={setShowDismissedUsers}
          setSelectedAlert={setSelectedAlert}
        />
        {showAddAlertModal && (
          <AddAlertModal showModal={setShowAddAlertModal} />
        )}
        {showDismissedUsers && (
          <DismissedPane
            setShowDismissedUsers={setShowDismissedUsers}
            selectedAlert={selectedAlert}
          />
        )}
      </Flex>
    </>
  );
};
