import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { MainButton } from "../button/main/MainButton";
import { Icon } from "../icon/Icon";
import { Column } from "./Table";
import {
  BodyRegular,
  HeaderSecondary,
  LabelMedium,
} from "../typography/Typography";
import { RightPane } from "../rightPane/RightPane";
import { TextButton } from "../button/text/TextButton";
import { SimpleCheckbox } from "../checkbox/SimpleCheckbox";
import { SeparatorHorizontal } from "../separators/SeparatorHorizontal";
import { Flex } from "../../layouts/flex/Flex";
import { InputText } from "../input/textInput/InputText";

export type ColumnState = { [index: string]: boolean };

export const getDefaultColumnsState = (
  value: boolean,
  columns: Column[],
  maxTableColumns: number
) => {
  // columns with isRequired always true
  // columns with index higher then max columns will be false
  const newColumnsState: ColumnState = {};
  columns
    ?.filter((c) => !!c.title)
    .forEach(
      (col, i) =>
        (newColumnsState[col.title] =
          i < maxTableColumns ? (col.isRequired ? true : value) : false)
    );
  return newColumnsState;
};

const ColumnsEditor = ({
  onClose,
  columns,
  stateStorageKey,
  maxTableColumns = 10,
}: {
  onClose: () => void;
  columns: Column[];
  stateStorageKey: string;
  maxTableColumns?: number;
}) => {
  const theme = useContext(ThemeContext);

  const storedStateStr = localStorage.getItem(stateStorageKey) || "";
  const savedState = storedStateStr ? JSON.parse(storedStateStr) : null;

  const [errors, setErrors] = useState<string[]>([]);
  const [searchWord, setSearchWord] = useState<string>("");
  const [columnsState, setColumnsState] = useState<ColumnState>(
    savedState || getDefaultColumnsState(true, columns, maxTableColumns)
  );

  const selectedCount = Object.values(columnsState).filter((v) => !!v).length;
  const filteredColumns = !!searchWord
    ? columns.filter((c) =>
        c.title.toLowerCase().includes(searchWord.toLocaleLowerCase())
      )
    : columns;

  const saveHandler = () => {
    localStorage.setItem(stateStorageKey, JSON.stringify(columnsState));
    onClose();
  };

  const onChangeHandler = (colTitle: string) => {
    setErrors([]);
    if (!columnsState[colTitle] && selectedCount === maxTableColumns) {
      setErrors([`Maximum ${maxTableColumns} columns allowed`]);
      // setTimeout(() => setErrors([]), 3000);
      return;
    }
    const newColumnsState = { ...columnsState };
    newColumnsState[colTitle] = !newColumnsState[colTitle];
    setColumnsState(newColumnsState);
  };

  return (
    <RightPane onClose={onClose} width="462px">
      <Flex column gap="32px" style={{ height: "100%" }}>
        <Flex column justify="center">
          <Flex
            w100
            justify="between"
            align="center"
            gap="16px"
            style={{ padding: "24px" }}
          >
            <Flex align="center" gap="8px">
              <Icon name="write" size={24} color={theme.primary} />
              <HeaderSecondary
                className="text-truncate"
                style={{ maxWidth: "300px" }}
              >
                Edit Columns
              </HeaderSecondary>
            </Flex>
            <Flex w100 justify="between" align="center" gap="16px">
              <TextButton
                label="Reset"
                onClick={() =>
                  setColumnsState(
                    savedState ||
                      getDefaultColumnsState(true, columns, maxTableColumns)
                  )
                }
                data-testid="reset"
              />
              <MainButton
                label="Save"
                onClick={saveHandler}
                dataTestId="save"
                size="medium"
              />
            </Flex>
          </Flex>
          <SeparatorHorizontal />
          <Flex w100 column gap="16px" style={{ padding: "24px" }}>
            <InputText
              placeholder="search"
              iconName="search"
              onChange={(e) => setSearchWord(e.target.value)}
              width={"100%"}
            />
            <Flex w100 align="center">
              <LabelMedium style={{ color: theme.primary }}>
                {selectedCount} / {maxTableColumns} Selected
              </LabelMedium>
              <div style={{ marginLeft: "auto" }}>
                <TextButton
                  label="Unselect All"
                  onClick={() =>
                    setColumnsState(
                      getDefaultColumnsState(false, columns, maxTableColumns)
                    )
                  }
                />
              </div>
            </Flex>
          </Flex>
          <SeparatorHorizontal />
        </Flex>
        <Flex
          column
          gap="32px"
          style={{
            paddingBlock: "12px",
            paddingInline: "24px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          {errors?.map((err, i) => (
            <Flex
              w100
              key={i}
              justify="center"
              style={{
                position: "absolute",
                top: "0",
                color: theme.redPrimary,
              }}
            >
              <BodyRegular>{err}</BodyRegular>
            </Flex>
          ))}
          {filteredColumns.map(
            (col) =>
              col.title && (
                <SimpleCheckbox
                  key={col.title}
                  dataTestId={`${col.title}-checkbox`}
                  label={col.title}
                  isChecked={columnsState[col.title] || !!col.isRequired}
                  onChange={() => !col.isRequired && onChangeHandler(col.title)}
                  isDisabled={col.isRequired}
                />
              )
          )}
        </Flex>
      </Flex>
    </RightPane>
  );
};

export default ColumnsEditor;
