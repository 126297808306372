import { ProjectFormProps } from "./ProjectScheduler";
import { dateObjToDateString } from "../../../shared/helper";
import { RangeDatePicker } from "../../../components/elements/datetimePicker/RangeDatePicker";

export const ProjectRangeCalendar = (props: ProjectFormProps) => {
  const { mainFormValues, setMainFormValues } = props;
  const startDate = new Date(mainFormValues.start_date);
  const start = dateObjToDateString(startDate);
  const endDate = new Date(mainFormValues.end_date);
  const end = dateObjToDateString(endDate);

  return (
    <RangeDatePicker
      start={start}
      end={end}
      onChangeEnd={(e) =>
        setMainFormValues((prev) => ({
          ...prev,
          end_date: e.target.value,
        }))
      }
      onChangeStart={(e) =>
        setMainFormValues((prev) => ({
          ...prev,
          start_date: e.target.value,
        }))
      }
    />
  );
};
