import { TabButton } from "./TabButton";
import { Flex } from "../../../layouts/flex/Flex";
import { Badge } from "../../badge/Badge";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

type Props = {
  onClick: (tabKey: string) => void;
  selectedTab: string;
  label: string;
  count?: number;
  tabKey: string;
};

export const TabButtonWithCounter = (props: Props) => {
  const { tabKey, count, selectedTab, label, onClick } = props;
  const theme = useContext(ThemeContext);

  return (
    <Flex align="center">
      <TabButton
        iconName=""
        label={label}
        onClick={() => onClick(tabKey)}
        selected={selectedTab === tabKey}
      />
      <Badge
        style={
          selectedTab === tabKey
            ? {}
            : {
                background: "transparent",
                border: `1px solid ${theme.black500}`,
                color: theme.black650,
              }
        }
      >
        {count}
      </Badge>
    </Flex>
  );
};
