import { useState } from "react";
import { useParams } from "react-router";
import { useApiProjects } from "../../../hooks/queries/projectsContext";

import { FirstPage } from "../generator/FirstPage";
import {
  DisclaimerPage,
  MultiTenantDisclaimerPage,
} from "../generator/DisclaimerPage";
import { AboutPage } from "../generator/AboutPage";
import { TableOfContents } from "../generator/TableOfContents";
import { useApiFindings } from "../../../hooks/queries/findingContext";
import { PageLayout } from "../generator/PageLayout";
import { ExecutiveSummary } from "../generator/ExecutiveSummary";
import { getActivityType } from "../../../shared/helper";
import { Methodology } from "../generator/Methodology";
import { TeamMembers } from "../generator/TeamMembers";
import { WelcomeBanner } from "../../../components/composed/banner/WelcomeBanner";
import { SummaryOfResults } from "../generator/SummaryOfResults";
import { ProjectObjectives } from "../generator/ProjectObjectives";
import { FindingsInDetails } from "./FindingsInDetails";
import { FindingPage } from "./FindingPage";
import { AppendixA } from "../generator/AppendixA";
import { AppendixB } from "../generator/AppendixB";
import { ResultsAtGlance } from "../generator/ResultsAtGlance";
import { useQueryClient } from "@tanstack/react-query";
import { Flex } from "../../../components/layouts/flex/Flex";
import { BodyBold } from "../../../components/elements/typography/Typography";
import { Modal } from "../../../components/elements/modal/Modal";
import { useCountDown, usePrint, useWaitLoading } from "../generator/hooks";
import { ProjectScope } from "../generator/ProjectScope";
import { STATUSES } from "../../../types/Finding";
import { useApiMe } from "../../../hooks/queries/meContext";

type Props = {};
const WAITING_TIME = 10; //seconds
export const FullReport = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const { data: projects, isFetching: isFetchingProject } = useApiProjects();
  const [loadingData, setLoadingData] = useState(true);
  const [countdown, setCountdown] = useState<number>(WAITING_TIME);
  const project = projects?.find((p) => p.id === parseInt(id || "0"));
  const { data: findings, isFetching: isFetchingFindings } = useApiFindings(
    {
      page_size: 1000,
      project: parseInt(id || "0"),
      all_data: true,
      no_prefetch_assets: true,
      status: STATUSES.filter((s) => s !== "dismissed").join(","), // Exclude dismissed findings
      is_false_positive: false, // Exclude false positive findings
      ordering: "-overall_risk",
    },
    !!project
  );

  const { data: me } = useApiMe();
  const isMultiTenantReport =
    me?.customer.is_multi_tenant && project?.is_self_managed;

  const queryClient = useQueryClient();
  // Check if there are any background fetching requests
  const isFetching = queryClient.isFetching();

  const isPageLoaded = !!(
    project &&
    !!findings &&
    !isFetching &&
    !isFetchingFindings
  );
  useWaitLoading(WAITING_TIME, () => setLoadingData(false), isPageLoaded);

  const isReadyToPrint = !!(!loadingData && project);
  usePrint(isReadyToPrint, `Full-Report-${project?.name}`);
  useCountDown(setCountdown);

  if (isFetchingProject || isFetchingFindings || isFetching) {
    return <WelcomeBanner />;
  }

  return (
    <PageLayout isMultiTenantReport={isMultiTenantReport}>
      {loadingData && (
        <Modal onClose={() => setLoadingData(false)} hideCloseIcon>
          <Flex align="center" gap="6px">
            <BodyBold>{`Generating PDF report in ${
              countdown > 0 ? countdown : "just a sec"
            }...`}</BodyBold>
          </Flex>
        </Modal>
      )}
      <FirstPage project={project} reportType="Full Report" />
      {isMultiTenantReport ? <MultiTenantDisclaimerPage /> : <DisclaimerPage />}
      {!isMultiTenantReport && <AboutPage />}
      <TableOfContents
        findings={findings || []}
        isMultiTenantReport={isMultiTenantReport}
      />
      {!isMultiTenantReport && (
        <>
          <ExecutiveSummary
            findings={findings || []}
            activityType={getActivityType(project?.type)}
          />
          <Methodology />
          <ProjectObjectives />
          <TeamMembers findings={findings || []} />
        </>
      )}
      {!!project?.project_scope && <ProjectScope project={project} />}
      <SummaryOfResults findings={findings || []} />
      <ResultsAtGlance findings={findings || []} />
      <FindingsInDetails />
      {findings?.map((finding, index) => (
        <FindingPage
          finding={finding}
          key={`finding-page-${finding.id}`}
          index={index}
        />
      ))}
      {!isMultiTenantReport && <AppendixA />}
      <AppendixB />
    </PageLayout>
  );
};
