import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import {
  KPILarge,
  LabelRegular,
  LabelRegularHover,
} from "../../../../components/elements/typography/Typography";
import { Link } from "react-router-dom";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../../hooks/utilsHooks";
import { SCREEN_LAPTOP_WIDTH } from "../../../../shared/consts";

export const AssetOverviewCounter = ({
  title,
  titleElement,
  count,
  total,
  linkTo,
  isLoading,
}: {
  title: string;
  titleElement?: JSX.Element;
  count: string;
  total?: string;
  linkTo?: string;
  isLoading: boolean;
}) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();

  return (
    <Flex
      column
      align="start"
      justify="end"
      h100
      w100
      gap="4px"
      style={{
        overflow: "hidden",
      }}
    >
      <Flex align="end" gap="6px" w100>
        {isLoading ? (
          <SkeletonLoading height="48px" width="48px" />
        ) : !total ? (
          <Flex w100>
            <KPILarge className="text-truncate" title={count.toString()}>
              {count}
            </KPILarge>
          </Flex>
        ) : (
          <Flex align="end" gap="4px" w100>
            <Flex w100={screenWidth <= SCREEN_LAPTOP_WIDTH}>
              <KPILarge className="text-truncate" title={count.toString()}>
                {count}
              </KPILarge>
            </Flex>
            <Flex
              w100={screenWidth <= SCREEN_LAPTOP_WIDTH}
              className="text-truncate "
            >
              <LabelRegular
                className="text-truncate "
                title={`Total ${total}`}
                color={theme.black600}
              >
                {`${total} Total`}
              </LabelRegular>
            </Flex>
          </Flex>
        )}
      </Flex>

      {linkTo ? (
        <Link
          to={linkTo}
          style={{ textDecoration: "none", color: "inherit" }}
          className="text-truncate  w-100"
        >
          <LabelRegularHover title={title} color={theme.textSecondary}>
            {titleElement || title}
          </LabelRegularHover>
        </Link>
      ) : (
        <LabelRegular
          className="text-truncate w-100"
          title={title}
          color={theme.textSecondary}
        >
          {titleElement || title}
        </LabelRegular>
      )}
    </Flex>
  );
};
