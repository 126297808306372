import React, { useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { useApiMe } from "../../../hooks/queries/meContext";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import {
  useApiCreateAutomation,
  useApiDeleteAutomation,
  useApiGetJWTAutomation,
} from "../../../hooks/queries/automationContext";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import useToastContext from "../../../hooks/toastHook";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import { Box } from "../../../components/elements/box/Box";
import { TabButton } from "../../../components/elements/button/tab/TabButton";

type Props = {};

export const Automation = (props: Props) => {
  const {
    data: workatoJWT,
    isFetching: isFetchingJWT,
    refetch: refetchWorkatoJwt,
  } = useApiGetJWTAutomation();
  const { data: me, isFetching: isFetchingMe } = useApiMe();
  const { mutate: createAutomation, isLoading: isCreatingAccount } =
    useApiCreateAutomation();
  const {
    mutate: deleteAutomation,
    isLoading: isDeletingAccount,
    isSuccess: isDeleted,
  } = useApiDeleteAutomation();
  const addToast = useToastContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTab, setTab] = useState("dashboard");

  const createWorkatoAccount = () => {
    createAutomation({
      onSuccessCallback: (ok) => {
        if (ok) {
          window.location.reload();
        }
      },
      onErrorCallback: (error) => {
        console.error("Error creating automation account", error);
        addToast({
          type: "error",
          message: "Failed to create automation account.",
        });
      },
    });
  };

  const handleDeleteAutomation = () => {
    deleteAutomation({
      onSuccessCallback: (ok) => {
        if (ok) {
          window.location.reload();
        }
      },
      onErrorCallback: (error) => {
        console.error("Error deleting automation account", error);
        addToast({
          type: "error",
          message: "Failed to delete automation account.",
        });
      },
    });
  };

  const getWorkatoPath = () => {
    switch (selectedTab) {
      case "dashboard":
        return "dashboard";
      case "recipes":
        return "recipes";
      case "templates":
        return "%2Fbrowse%2Frecipes%3Fq%3Dapp%3Awasp_connector_500144445_1711025459";
      default:
        return "dashboard";
    }
  };

  return (
    <Flex column gap="8px">
      {showDeleteModal && (
        <DeleteModal
          isLoading={isDeletingAccount}
          itemName="Automation Account"
          itemType="automation"
          body="Are you sure you want to delete your automation account? This action cannot be undone and you will lose all your automation projects."
          isSuccess={isDeleted}
          onConfirm={handleDeleteAutomation}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      <Flex w100 justify="between" align="center" gap="24px">
        <HeaderSecondary>Automation</HeaderSecondary>
        {!!me?.customer?.is_workato_connected && (
          <Flex align="center" gap="8px">
            <SecondaryButton
              label="Delete Automation Account"
              variant="danger"
              onClick={() => setShowDeleteModal(true)}
              size="medium"
            />
          </Flex>
        )}
      </Flex>

      <SeparatorHorizontal />
      {(isFetchingJWT || isFetchingMe) && (
        <SkeletonLoading height="calc(100vh - 160px)" width="100%" />
      )}
      {!isFetchingMe && !me?.customer?.is_workato_connected && (
        <Flex column gap="16px">
          <BodyRegular>
            You currently do not have an automation account.
          </BodyRegular>
          <Flex
            style={{
              width: "fit-content",
            }}
          >
            <MainButton
              label="Create Automation Account"
              onClick={createWorkatoAccount}
              dataTestId="create-automation-account-button"
              inProgress={isCreatingAccount}
              size="medium"
            />
          </Flex>
        </Flex>
      )}
      {workatoJWT && me?.customer?.is_workato_connected && (
        <Box className="d-flex flex-column w-100 gap-16">
          <Flex gap="32px">
            <TabButton
              label="Dashboard"
              iconName="dashboard"
              selected={selectedTab === "dashboard"}
              onClick={() =>
                refetchWorkatoJwt().then(() => setTab("dashboard"))
              }
            />

            <TabButton
              label="Manage"
              iconName="maintenance"
              selected={selectedTab === "recipes"}
              onClick={() => refetchWorkatoJwt().then(() => setTab("recipes"))}
            />
            <TabButton
              label="Templates"
              iconName="folder"
              selected={selectedTab === "templates"}
              onClick={() =>
                refetchWorkatoJwt().then(() => setTab("templates"))
              }
            />
          </Flex>
          <Flex key={workatoJWT}>
            <iframe
              title="workato-iframe"
              style={{
                width: "100%",
                height: "calc(100vh - 160px)",
                borderRadius: "12px",
              }}
              src={`https://app.eu.workato.com/direct_link?workato_dl_path=${getWorkatoPath()}&workato_dl_token=${workatoJWT}`}
            />
          </Flex>
        </Box>
      )}
    </Flex>
  );
};
