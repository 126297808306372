import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { useApiMe } from "../../../hooks/queries/meContext";
import { useApiUpdateAccount } from "../../../hooks/queries/accountsContext";
import { useApiUpdateCustomer } from "../../../hooks/queries/customersContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import useToastContext from "../../../hooks/toastHook";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { Mixpanel } from "../../../shared/mixpanel";
import {
  defaultEmailNotificationSettings,
  NotificationChannel,
  NotificationSettings,
} from "../../../types/Notifications";
import { NotificationsSettingsTable } from "./NotificationsSettingsTable";
import { FindingNotificationsSettingsTable } from "./FindingNotificationsTable";
import { AutomatedReportsSettingsTable } from "./AutomatedReportsSettingsTable";

const NotificationsSettings = () => {
  const { data: me, refetch } = useApiMe();
  useTrackPage("Settings - Notifications", undefined, me);
  const theme = useContext(ThemeContext);

  const addToast = useToastContext();

  const { mutate: updateAccount } = useApiUpdateAccount();
  const { mutate: updateCustomer } = useApiUpdateCustomer();

  const [emailValues, setEmailValues] = useState<NotificationSettings>(
    me?.email_settings || defaultEmailNotificationSettings
  );
  const [inAppValues, setInAppValues] = useState<NotificationSettings>(
    me?.in_app_settings || defaultEmailNotificationSettings
  );
  const [slackValues, setSlackValues] = useState<NotificationSettings>(
    me?.customer.slack_settings || defaultEmailNotificationSettings
  );

  const updateSettings = ({
    channel,
    values,
  }: {
    channel: NotificationChannel;
    values: NotificationSettings;
  }): void => {
    if (!me || !values) return;
    if (channel === "email") {
      setEmailValues(values);
      updateAccount({
        accountId: me.account_id,
        accountData: { email_settings: values },
        onSuccessCallback: () => {
          addToast({
            message: "Notification Settings updated successfully",
            type: "success",
          });
          refetch();
          Mixpanel.track("Edit email notifications");
        },
        onErrorCallback: (error: Error) =>
          addToast({ message: error.message, type: "error" }),
      });
    }
    if (channel === "inApp") {
      setInAppValues(values);
      updateAccount({
        accountId: me.account_id,
        accountData: { in_app_settings: values },
        onSuccessCallback: () => {
          addToast({
            message: "Notification Settings updated successfully",
            type: "success",
          });
          refetch();
          Mixpanel.track("Edit in-app notifications");
        },
        onErrorCallback: (error: Error) =>
          addToast({ message: error.message, type: "error" }),
      });
    }
    if (channel === "slack") {
      setSlackValues(values);
      updateCustomer({
        customerId: me.customer.id,
        customerData: { slack_settings: values },
        onSuccessCallback: () => {
          addToast({
            message: "Notification Settings updated successfully",
            type: "success",
          });
          refetch();
        },
        onErrorCallback: (error: Error) =>
          addToast({ message: error.message, type: "error" }),
      });
      Mixpanel.track("Edit Slack notifications");
    }
  };

  return (
    <Flex gap="16px" justify="between" style={{ height: "calc(100vh - 90px)" }}>
      <Flex column className="w-100" gap="24px">
        <Flex justify="between">
          <Flex align="center" style={{ height: "50px" }}>
            <HeaderSecondary
              style={{ paddingRight: "24px", color: theme.black900 }}
            >
              Notifications
            </HeaderSecondary>
          </Flex>
        </Flex>

        <NotificationsSettingsTable
          formValues={{
            email: emailValues,
            inApp: inAppValues,
            slack: slackValues,
          }}
          updateSettings={updateSettings}
        />
        <FindingNotificationsSettingsTable
          formValues={{
            email: emailValues,
            inApp: inAppValues,
            slack: slackValues,
          }}
          updateSettings={updateSettings}
        />
        <AutomatedReportsSettingsTable
          formValues={{ email: emailValues }}
          updateSettings={updateSettings}
        />
      </Flex>
    </Flex>
  );
};

export default NotificationsSettings;
