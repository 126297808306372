import { useQuery } from "@tanstack/react-query";
import { getItems } from "../../../../../hooks/queries/sdk";

const key = "assets/graph";
export const useApiReGraphItems = (
  params?: { [key: string]: any },
  data?: unknown
) => {
  const enabled = params?.id && Boolean(params?.id.length);

  return useQuery<any, Error>({
    queryKey: [key, params, data],
    keepPreviousData: true,
    placeholderData: [],
    enabled: enabled,
    queryFn: async (): Promise<any> => {
      return getItems(key, params);
    },
  });
};
