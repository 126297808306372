import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import {
  BodyRegularHover,
  HeaderSecondary,
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { AssetCompact } from "../../../types/Asset";
import { ScanEvent } from "../../../types/ScanEvent";
import { ScanResultsPaneAssets } from "./ScanResultsPaneAssets";
import { ScanResultsPaneFindings } from "./ScanResultsPaneFindings";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  calculateTimeDiff,
  getDateTime,
  getTime,
} from "../../../shared/helper";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { useApiScanSummary } from "../../../hooks/queries/scansContext";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { AssetPane } from "../../assets/assetsPane/AssetPane";
import { useApiAsset } from "../../../hooks/queries/assetsContext";

type Props = {
  onClose: () => void;
  scan: ScanEvent | undefined;
};

export const ScanResultsPane = (props: Props) => {
  const { onClose, scan } = props;
  const theme = useContext(ThemeContext);
  const [assetOnDisplay, setAssetOnDisplay] = useState<number>(0);
  const { data: asset } = useApiAsset(assetOnDisplay);

  return !!assetOnDisplay && asset ? (
    <AssetPane assetId={asset.id} onClose={() => setAssetOnDisplay(0)} />
  ) : (
    <RightPane onClose={onClose} width="800px">
      <Flex
        column
        gap="24px"
        h100
        style={{ padding: "24px", overflow: "scroll" }}
      >
        <HeaderSecondary>Scan Summary</HeaderSecondary>
        <Flex>
          <Flex column padding="0 24px 0 0px">
            <LabelRegular color={theme.black600}>Scan ID</LabelRegular>
            <HeaderSubBold style={{ textTransform: "lowercase" }}>
              {scan?.id}
            </HeaderSubBold>
          </Flex>
          <Flex column padding="0 24px 0 24px">
            <LabelRegular color={theme.black600}>Scanner</LabelRegular>
            <HeaderSubBold style={{ textTransform: "capitalize" }}>
              {scan?.scanner}
            </HeaderSubBold>
          </Flex>
          <Flex column padding="0 24px 0 24px">
            <LabelRegular color={theme.black600}>Scan Type</LabelRegular>
            <HeaderSubBold style={{ textTransform: "capitalize" }}>
              {scan?.scan_type?.replace("_", " ")}
            </HeaderSubBold>
          </Flex>
        </Flex>
        {scan?.scanner === "sublister" ? (
          <ScanResultsPaneAssets
            scan={scan}
            setAssetOnDisplay={setAssetOnDisplay}
          />
        ) : (
          <ScanResultsPaneFindings
            scan={scan}
            setAssetOnDisplay={setAssetOnDisplay}
          />
        )}
      </Flex>
    </RightPane>
  );
};

export const AssetItem = ({
  asset,
  setAssetOnDisplay,
}: {
  asset: AssetCompact;
  setAssetOnDisplay: Dispatch<SetStateAction<number>>;
}) => {
  const viewAsset = () => setAssetOnDisplay(asset.id);
  return (
    <>
      <Flex align="center" justify="between">
        <Flex column gap="8px">
          <BodyRegularHover onClick={viewAsset} style={{ fontWeight: "500" }}>
            {asset.name}
          </BodyRegularHover>
          <LabelRegular>{asset.id}</LabelRegular>
        </Flex>
        <IconButton iconName="chevronRight" onClick={viewAsset} />
      </Flex>
      <SeparatorHorizontal />
    </>
  );
};

export const ScanTimes = ({ scan }: { scan: ScanEvent | undefined }) => {
  const theme = useContext(ThemeContext);
  const { data: scanSummary } = useApiScanSummary(`${scan?.id}`);

  const start = scanSummary?.scan_start_time
    ? scanSummary?.scan_start_time
    : scan?.created_at;

  const end = scanSummary?.scan_end_time
    ? scanSummary?.scan_end_time
    : scan?.finished_at;

  return (
    <Flex gap="24px" justify="evenly">
      <Flex column gap="8px" style={{ width: "190px" }}>
        <LabelRegular>Start Time</LabelRegular>
        <Flex gap="8px" align="center">
          <Icon name="projectStart" color={theme.primary} size={32} />
          <HeaderSubBold>{getDateTime(`${start}`)}</HeaderSubBold>
          <HeaderSubBold color={theme.black600}>
            {getTime(`${start}`)}
          </HeaderSubBold>
        </Flex>
      </Flex>
      <SeparatorVertical height="58px" />
      <Flex column gap="8px" style={{ width: "190px" }}>
        <LabelRegular>End Time</LabelRegular>
        <Flex gap="8px" align="center">
          <Icon name="fixed" color={theme.primary} size={32} />
          {end ? (
            <>
              <HeaderSubBold>{getDateTime(`${end}`)}</HeaderSubBold>
              <HeaderSubBold color={theme.black600}>
                {getTime(`${end}`)}
              </HeaderSubBold>
            </>
          ) : (
            "N/A"
          )}
        </Flex>
      </Flex>
      <SeparatorVertical height="58px" />
      <Flex column gap="8px" style={{ width: "190px" }}>
        <LabelRegular>Duration</LabelRegular>
        <Flex gap="8px" align="center">
          <Icon name="sla" color={theme.primary} size={32} />
          {end ? (
            <HeaderSubBold>
              {calculateTimeDiff(`${start}`, `${end}`)}
            </HeaderSubBold>
          ) : (
            "N/A"
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
