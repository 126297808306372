import { useNavigate } from "react-router";
import { RegisterSSO } from "./RegisterSSO";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_SMALL_LAPTOP_WIDTH } from "../../../shared/consts";
import { Flex } from "../../../components/layouts/flex/Flex";
import { PoweredByOP } from "../login/PoweredByOP";
import {
  BodyRegular,
  HeaderMain,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { IS_DEMO_ENV } from "../../../hooks/queries/utils";
import { Icon } from "../../../components/elements/icon/Icon";
import { TermsAndPrivacyPolicy } from "../login/TermsAndPrivacyPolicy";
import { HexagonsBackground } from "../login/HexagonsBackground";
import { Hexagon } from "../../../components/elements/hexagon/Hexagon";

import beeLeft from "../../../assets/images/beeLookingLeft.png";
import beeRight from "../../../assets/images/beeLookingRight.png";
import { WaspLogo } from "../login/WaspLogo";

export const Register = () => {
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_SMALL_LAPTOP_WIDTH;

  const TextBox = ({ text, iconName }: { text: string; iconName: string }) => (
    <Flex gap="24px">
      <Flex column justify="start" style={{ width: "80px" }}>
        <Hexagon width="47px" height="48px" color={theme.white100}>
          <Flex
            align="center"
            justify="center"
            style={{ position: "relative", top: "12px" }}
          >
            <Icon name={iconName} size="22px" color={theme.primary} />
          </Flex>
        </Hexagon>
      </Flex>
      <Flex gap="8px" style={{ width: "272px" }}>
        <HeaderSecondary color={theme.white100} style={{ fontWeight: "400" }}>
          {text}
        </HeaderSecondary>
      </Flex>
    </Flex>
  );

  return (
    <Flex style={{ height: "100vh" }} justify="between">
      <Flex
        column
        justify="between"
        style={{
          width: "100%",
          height: "100vh",
          padding: "12px",
          background: theme.bg2,
        }}
      >
        <WaspLogo />
        <Flex w100 gap="32px" column align="center">
          <HeaderMain style={{ textAlign: "center" }}>
            Create your WASP {IS_DEMO_ENV ? "Demo" : "Trial"} account
          </HeaderMain>
          <BodyRegular>
            Choose the method you prefer for registration.
          </BodyRegular>
          <RegisterSSO />
          <Flex align="center" column>
            <BodyRegular>Already have an account ?</BodyRegular>
            <Flex align="center">
              <TextButton
                fontSize="14px"
                label="Sign In"
                onClick={() => navigate("/login")}
              />
            </Flex>
          </Flex>
          <TermsAndPrivacyPolicy />
        </Flex>
        <PoweredByOP />
      </Flex>

      {!isLaptop && (
        <Flex
          column
          align="center"
          justify="center"
          gap="48px"
          style={{
            minWidth: "66%",
            height: "100vh",
            backgroundColor: theme.primary,
            position: "relative",
            overflow: "hidden",
            padding: "24px",
          }}
        >
          <HexagonsBackground />
          <Flex
            column
            w100
            style={{ zIndex: 100, maxWidth: "1200px" }}
            align="center"
            gap="48px"
          >
            <Flex column align="center" gap="12px">
              <HeaderMain
                style={{
                  fontSize: "48px",
                  fontWeight: "700",
                  lineHeight: "72px",
                  color: theme.white100,
                }}
              >
                Get started with WASP
              </HeaderMain>
              <HeaderSecondary
                style={{
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: theme.white100,
                }}
              >
                PTaaS (Penetration Testing As a Service)
              </HeaderSecondary>
            </Flex>
            <Flex column gap="32px">
              <Flex gap="32px" align="center" flexWrap justify="evenly">
                <TextBox
                  iconName="presented"
                  text="Get full visibility and easily manage all your security validation efforts"
                />
                <TextBox
                  iconName="search"
                  text="Continuously monitor your external facing assets"
                />
                <TextBox
                  iconName="shield"
                  text="Ensure year-round secure posture with flexible test scoping and allocation"
                />
                <TextBox
                  iconName="share"
                  text="Collaborate with your team and OP Innovate experts"
                />
                <TextBox
                  iconName="calender"
                  text="Easily schedule your next penetration test from within the platform"
                />
              </Flex>
            </Flex>
          </Flex>
          <img
            src={beeLeft}
            alt=""
            style={{ position: "absolute", top: "48px", right: "48px" }}
          />
          <img
            src={beeRight}
            alt=""
            style={{ position: "absolute", bottom: "48px", left: "48px" }}
          />
        </Flex>
      )}
    </Flex>
  );
};
