export const mockScanners = [
  {
    name: "sublister",
    aws_task_name: "Sublister-new",
    aws_container_name: "sublister",
    environment: {},
    display_name: "Asset Enumerator",
  },
  {
    name: "nuclei",
    aws_task_name: "nuclei-scanner",
    aws_container_name: "nuclei-scanner",
    environment: {},
    display_name: "Vulnerability Scanner 2",
  },
  {
    name: "nessus",
    aws_task_name: "nessus_scanner",
    aws_container_name: "nessus_scanner",
    environment: {},
    display_name: "Vulnerability Scanner 1",
  },
  {
    name: "zap",
    aws_task_name: "zap-scanner",
    aws_container_name: "zap",
    environment: {},
    display_name: "DAST",
  },
  {
    name: "smto",
    aws_task_name: "smto-scanner",
    aws_container_name: "smto-scanner",
    environment: {},
    display_name: "Social Media Takeover Scanner",
  },
  {
    name: "dns_reaper",
    aws_task_name: "dns_reaper",
    aws_container_name: "dnsreaper",
    environment: {},
    display_name: "Subdomain Takeover Scanner",
  },
  {
    name: "wpscan",
    aws_task_name: "wpscan",
    aws_container_name: "wpscan",
    environment: {},
    display_name: "Wordpress Scanner",
  },
  {
    name: "dalfox",
    aws_task_name: "dalfox",
    aws_container_name: "dalfox",
    environment: {},
    display_name: "XSS Scanner",
  },
];
