import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "../../../components/elements/modal/Modal";
import {
  BodyBold,
  BodyRegular,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { RequiredField } from "../../../components/elements/requiredField/RequiredField";
import { Switch } from "../../../components/elements/switch/Switch";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { CodeBox } from "../../../components/elements/box/Box";
import AlertBanner from "../../../components/elements/toastTypes/AlertBanner";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { Option } from "../../../components/elements/dropdowns/Dropdown";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import { objectsToOptions } from "../../../shared/formUtils";
import { useApiAccountsPaging } from "../../../hooks/queries/accountsContext";
import { TagBadge } from "../../../components/composed/tagsLine/TagBadge";
import { useApiCreateAlertBannerMessage } from "../../../hooks/queries/alertBannerMessageContext";
import useToastContext from "../../../hooks/toastHook";
import { Account } from "../../../types/Account";

type Props = {
  showModal: Dispatch<SetStateAction<boolean>>;
};

export const AddAlertModal = (props: Props) => {
  const { showModal } = props;
  const addToast = useToastContext();
  const [showAll, setShowAll] = useState<boolean>(false);
  const { data: customers } = useApiCustomers();
  const {
    data: accounts,
    hasNextPage,
    fetchNextPage,
  } = useApiAccountsPaging({ "admin-mode": true });
  const [selectedCustomers, setSelectedCustomers] = useState<Option[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Option[]>([]);
  const [messageContent, setMessageContent] =
    useState<string>("Placeholder text");
  const { mutate: createAlert } = useApiCreateAlertBannerMessage();
  const getAccounts = (): Account[] => {
    return accounts?.pages.map((page) => page.results || []).flat() || [];
  };
  const filteredCustomers = customers?.filter((c) =>
    selectedCustomers.every((op) => op.value !== c.id)
  );
  const filteredAccounts = getAccounts()?.filter((a) =>
    selectedAccounts.every((op) => op.value !== a.id)
  );
  const [showValidationError, setShowValidationError] =
    useState<boolean>(false);

  const [alertTypeOption, setAlertTypeOption] = useState<Option>({
    label: "Info",
    value: "info",
  });

  const options = [
    { label: "Info", value: "info" },
    { label: "Warning", value: "warning" },
    { label: "Error", value: "error" },
    { label: "Success", value: "success" },
  ];

  const validateForm = (): boolean => {
    return (
      messageContent !== "" &&
      (showAll ||
        selectedAccounts.length !== 0 ||
        selectedCustomers.length !== 0)
    );
  };

  const resetForm = () => {
    setShowAll(false);
    setSelectedCustomers([]);
    setSelectedAccounts([]);
    setMessageContent("Placeholder text");
    setAlertTypeOption({
      label: "Info",
      value: "info",
    });
  };
  const submitForm = () => {
    if (validateForm()) {
      const receivers = selectedAccounts.map((accountOption) => {
        const customer = getAccounts()?.find(
          (a) => a.id === accountOption.value
        )?.customer;

        return {
          customer: customer ? customer : -1,
          account:
            typeof accountOption.value === "number"
              ? accountOption.value
              : parseInt(accountOption.value, 10),
        };
      });
      createAlert({
        receivers: receivers,
        customer_ids:
          showAll && customers
            ? customers.map((c) => c.id)
            : selectedCustomers.map((c) => parseInt(c.value.toString())),
        type: alertTypeOption.value as "error" | "info" | "success" | "warning",
        message_content: messageContent,
        onSuccessCallback() {
          setShowValidationError(false);
          addToast({
            message: `Alert '${messageContent}' created successfully`,
            type: "success",
          });
          showModal(false);
          resetForm();
        },
        onErrorCallback() {
          setShowValidationError(false);
          addToast({
            message: "Alert creation failed - Contact the WASP team for help.",
            type: "error",
          });
          showModal(false);
          resetForm();
        },
      });
    } else {
      setShowValidationError(true);
    }
  };
  return (
    <Modal
      onClose={() => showModal(false)}
      width="40%"
      header={"Create Alert Message"}
    >
      <Flex column gap="16px">
        {showValidationError && (
          <AlertBanner
            type="warning"
            message={
              <BodyRegular>
                Please complete the form before creating the alert.
              </BodyRegular>
            }
          />
        )}
        <Flex>
          <BodyBold>Alert Message Properties</BodyBold>
        </Flex>
        <Flex gap="8px" justify="between">
          <Flex>
            <LabelRegular>show to all customers</LabelRegular>
          </Flex>
          <Switch
            checked={showAll}
            onChange={() => {
              setShowAll(!showAll);
            }}
          />
        </Flex>
        {!showAll && (
          <Flex gap="16px" column>
            <Flex column gap="8px">
              <Flex>
                <LabelRegular>customers (and all their accounts)</LabelRegular>
                <RequiredField />
              </Flex>
              <Dropdown
                searchable
                placeholder="Select customer"
                options={
                  filteredCustomers ? objectsToOptions(filteredCustomers) : []
                }
                onChange={(op) => {
                  if (op) setSelectedCustomers([...selectedCustomers, op]);
                }}
                variant="border"
                size="medium"
              />
              {selectedCustomers &&
                selectedCustomers.map?.((customer) => (
                  <TagBadge
                    key={customer.value}
                    option={customer}
                    onDeleteOption={(op) => {
                      if (op)
                        setSelectedCustomers(
                          selectedCustomers.filter(
                            (opCustomer) => op.value !== opCustomer.value
                          )
                        );
                    }}
                    isEditable={true}
                  />
                ))}
            </Flex>
            <Flex column gap="8px">
              <Flex>
                <LabelRegular>accounts (Only these specific)</LabelRegular>
                <RequiredField />
              </Flex>
              <Dropdown
                searchable
                variant="border"
                size="medium"
                options={
                  filteredAccounts
                    ? objectsToOptions(filteredAccounts, true)
                    : []
                }
                onChange={(op) => {
                  if (op) setSelectedAccounts([...selectedAccounts, op]);
                }}
                placeholder="Select accounts"
                onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
              />
              {selectedAccounts &&
                selectedAccounts?.map((customer) => (
                  <TagBadge
                    key={customer.value}
                    option={customer}
                    onDeleteOption={(op) => {
                      if (op)
                        setSelectedAccounts(
                          selectedAccounts.filter(
                            (opAccount) => op.value !== opAccount.value
                          )
                        );
                    }}
                    isEditable={true}
                  />
                ))}
            </Flex>
          </Flex>
        )}
        <Flex column gap="8px">
          <Flex>
            <LabelRegular>alert type</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            variant="border"
            size="medium"
            onChange={(op) => {
              if (op) setAlertTypeOption(op);
            }}
            value={alertTypeOption}
            options={options}
          />
        </Flex>
        <Flex column gap="8px">
          <Flex>
            <LabelRegular>message content</LabelRegular>
            <RequiredField />
          </Flex>
          <InputText
            width="100%"
            value={messageContent}
            onChange={(e) => {
              setMessageContent(e.target.value);
            }}
          />
        </Flex>
        <Flex>
          <BodyBold>Alert Message Preview</BodyBold>
        </Flex>
        <CodeBox>
          <AlertBanner
            message={<BodyRegular>{messageContent}</BodyRegular>}
            type={
              typeof alertTypeOption.value === "string"
                ? (alertTypeOption.value as
                    | "info"
                    | "success"
                    | "warning"
                    | "error")
                : "info"
            }
            previewMode
          />
        </CodeBox>
        <Flex justify="end">
          <MainButton
            label={"Create Alert Message"}
            onClick={() => {
              submitForm();
            }}
            disabled={false}
            dataTestId="create-alert-btn"
            size="medium"
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
