import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "./Icon";

type Props = {
  size?: number;
};

export const TrendUpGreenIcon = (props: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <span
      style={{
        borderRadius: "50%",
        background: theme.green50,
        width: props.size ? props.size * 1.3 : "32px",
        height: props.size ? props.size * 1.3 : "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon name="trendUp" size={props.size || 24} color={theme.greenPrimary} />
    </span>
  );
};

export const TrendDownRedIcon = (props: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <span
      style={{
        borderRadius: "50%",
        background: theme.red50,
        width: props.size ? props.size * 1.3 : "32px",
        height: props.size ? props.size * 1.3 : "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon name="trendDown" size={props.size || 24} color={theme.redPrimary} />
    </span>
  );
};
