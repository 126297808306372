import { useContext } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { BodyMediumHover } from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";

export const PoweredByOP = () => {
  const theme = useContext(ThemeContext);
  return (
    <Flex column align="center" style={{ justifySelf: "end" }}>
      <Link to={"https://op-c.net"} style={{ textDecoration: "none" }}>
        <BodyMediumHover color={theme.black600}>
          Powered by OP Innovate
        </BodyMediumHover>
      </Link>
    </Flex>
  );
};
