import { useContext, useRef, useState } from "react";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { RichTextEditorWASP } from "../../../components/elements/richTextEditor/RichTextEditor";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { WhatsNew, WhatsNewParams } from "../../../types/WhatsNew";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import {
  HeaderMain,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { WhatsNewModal } from "./WhatsNewModal";
import { getDate } from "../../../shared/helper";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import {
  useApiCreateWhatsNew,
  useApiDeleteWhatsNew,
  useApiUpdateWhatsNew,
} from "../../../hooks/queries/whatsNewContext";
import useToastContext from "../../../hooks/toastHook";
import { ConfirmModal } from "../../../components/composed/confirmModal/ConfirmModal";

type Props = {
  whatsNew?: WhatsNew;
  onClose: () => void;
};

export const WhatsNewEditor = (props: Props) => {
  const { whatsNew, onClose } = props;
  const addToast = useToastContext();
  const theme = useContext(ThemeContext);
  const valueTrigger = useRef(true);
  const clearTrigger = useRef(true);

  const cachedHtml = localStorage.getItem(
    `whats_new_item_html${whatsNew?.id || ""}`
  );

  const {
    mutate: deleteWhatsNew,
    isLoading: isDeleting,
    isSuccess: isDeleted,
  } = useApiDeleteWhatsNew();

  const {
    mutate: createWhatsNew,
    isLoading: isCreating,
    isSuccess: isCreated,
    reset: resetCreate,
  } = useApiCreateWhatsNew();

  const {
    mutate: updateWhatsNew,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    reset: resetUpdate,
  } = useApiUpdateWhatsNew();

  const [showPreview, setShowPreview] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [html, setHtml] = useState(whatsNew ? whatsNew.html : cachedHtml || "");
  const [featureName, setFeatureName] = useState(
    whatsNew ? whatsNew.feature_name : ""
  );

  const clearRteCache = () =>
    localStorage.removeItem(`whats_new_item_html${whatsNew?.id || ""}`);

  const onErrorCallback = ({ message }: Error) =>
    addToast({ type: "error", message });

  const updateWhatsNewItem = (
    whatsNewData: WhatsNewParams,
    whatsNewId: number,
    onSuccessCallback: () => void
  ) =>
    updateWhatsNew({
      whatsNewData,
      whatsNewId,
      onErrorCallback,
      onSuccessCallback,
    });

  const createWhatsNewItem = (
    whatsNewData: WhatsNewParams,
    onSuccessCallback: () => void
  ) =>
    createWhatsNew({
      ...whatsNewData,
      onErrorCallback,
      onSuccessCallback,
    });

  const onSaveDraft = (whatsNewData: WhatsNewParams, whatsNewId?: number) => {
    const onSuccessCallback = () => {
      addToast({ type: "success", message: "Draft saved successfully" });
      clearRteCache();
      onClose();
    };
    if (!!whatsNewId)
      updateWhatsNewItem(whatsNewData, whatsNewId, onSuccessCallback);
    else createWhatsNewItem(whatsNewData, onSuccessCallback);
  };

  const onPublish = (whatsNewData: WhatsNewParams, whatsNewId?: number) => {
    const onSuccessCallback = () => {
      addToast({ type: "success", message: "Item published successfully" });
      clearRteCache();
      onClose();
    };
    let params: WhatsNewParams = { ...whatsNewData, is_draft: false };
    if (!!whatsNewId) updateWhatsNewItem(params, whatsNewId, onSuccessCallback);
    else createWhatsNewItem({ ...params, is_draft: false }, onSuccessCallback);
  };

  const deleteWhatsNewItem = (whatsNewId: number) =>
    deleteWhatsNew({
      whatsNewId,
      whatsNewData: {},
      onErrorCallback: ({ message }: Error) =>
        addToast({ type: "error", message }),
      onSuccessCallback: () => {
        clearRteCache();
        onClose();
      },
    });

  const onCancel = () => {
    clearRteCache();
    onClose();
  };

  return (
    <>
      <Flex column gap="24px" style={{ height: "100vh", padding: "24px" }}>
        <HeaderMain>
          {whatsNew ? "Edit" : "Create"} "What's new in WASP" Item
        </HeaderMain>

        <Flex gap="8px" align="center" w100>
          <LabelRegular>Feature Name</LabelRegular>
          <InputText
            width={"100%"}
            value={featureName}
            onChange={(e) => setFeatureName(e.target.value)}
          />
        </Flex>

        <Flex column h100 gap="8px">
          <Flex style={{ maxHeight: "calc(100vh - 294px)" }}>
            <RichTextEditorWASP
              value={html}
              valueTrigger={valueTrigger}
              clearTrigger={clearTrigger}
              onChange={(value) => {
                setHtml(value);
                localStorage.setItem(
                  `whats_new_item_html${whatsNew?.id || ""}`,
                  html
                );
              }}
            />
          </Flex>

          <Flex w100 justify="between" gap="8px" style={{ paddingTop: "24px" }}>
            <MainButton
              label="Cancel"
              variant="secondary"
              size="small"
              onClick={() => onCancel()}
            />

            <Flex gap="8px" justify="end">
              <MainButton
                label="Preview"
                size="small"
                iconName="eye"
                onClick={() => setShowPreview(true)}
              />
              {!whatsNew?.published_at ? (
                <>
                  <MainButton
                    label="Save Draft"
                    size="small"
                    iconName="save"
                    onClick={() => {
                      onSaveDraft(
                        { feature_name: featureName, html },
                        whatsNew?.id
                      );
                    }}
                    disabled={!featureName || !html}
                  />
                  <MainButton
                    label="Publish"
                    iconName="send"
                    size="small"
                    onClick={() => setShowConfirmModal(true)}
                    disabled={!featureName || !html}
                  />
                </>
              ) : (
                <>
                  <MainButton
                    label="update"
                    iconName="send"
                    size="small"
                    onClick={() => {
                      onPublish(
                        { feature_name: featureName, html },
                        whatsNew?.id
                      );
                    }}
                  />
                  <MainButton
                    label={`Publish at ${getDate(whatsNew.published_at)}`}
                    iconName="globe"
                    size="small"
                    disabled
                    style={{ color: theme.red600 }}
                  />
                </>
              )}
            </Flex>
          </Flex>
          {!!whatsNew && (
            <SecondaryButton
              label="Delete Item"
              onClick={() => setShowDeleteModal(true)}
              variant="danger"
            />
          )}
        </Flex>
      </Flex>
      {showPreview && (
        <WhatsNewModal
          onClose={() => setShowPreview(false)}
          whatsNewPreview={{ feature_name: featureName, html }}
        />
      )}
      {whatsNew && showDeleteModal && (
        <DeleteModal
          onConfirm={() => deleteWhatsNewItem(whatsNew.id)}
          isLoading={isDeleting}
          isSuccess={isDeleted}
          itemName='"whats new" item '
          itemType=""
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          confirmButtonText="Publish"
          body="Once confirmed all customers will be notified"
          title="are you sure you want to publish this item?"
          successMessage="Item is now published!"
          isLoading={isCreating || isUpdating}
          isSuccess={isCreated || isUpdated}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={() => {
            onPublish({ feature_name: featureName, html }, whatsNew?.id);
            resetCreate();
            resetUpdate();
          }}
        />
      )}
    </>
  );
};
