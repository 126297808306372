import React from "react";
import { StyleTextArea } from "./TextArea.style";

type Props = {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  isError?: boolean;
  placeholder?: string;
  width?: string;
  height?: string;
  dataTestId?: string;
  color?: string;
  autoFocus?: boolean;
};

export const TextArea = (props: Props) => {
  const {
    value,
    onChange,
    disabled = false,
    isError = false,
    placeholder,
    width,
    height,
    dataTestId,
    color,
    autoFocus,
  } = props;

  return (
    <StyleTextArea
      onChange={(e) => onChange && onChange(e)}
      disabled={disabled}
      placeholder={placeholder}
      width={width}
      data-testid={dataTestId}
      autoFocus={autoFocus}
      color={color}
      isError={isError}
      value={value}
      style={{ height: height || "100px", resize: "none" }}
    ></StyleTextArea>
  );
};
