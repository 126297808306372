import { useContext } from "react";

import { ThemeContext } from "styled-components";
import { Flex } from "../../components/layouts/flex/Flex";
import { Hexagon } from "../../components/elements/hexagon/Hexagon";

export const HexagonsBackground = () => {
  const theme = useContext(ThemeContext);
  return (
    <Flex
      style={{
        width: "100%",
        height: "100vh",
        position: "absolute",
        zIndex: 0,
        opacity: 0.3,
        overflowX: "hidden",
        overflow: "hidden",
      }}
    >
      <Hexagon
        color={theme.blue500}
        transform="rotate(-5deg)"
        top="-80px"
        left="-30px"
        height="200px"
        width="200px"
      />
      <Hexagon
        color={theme.blue700}
        transform="rotate(-4deg)"
        top="90px"
        left="-240px"
        height="300px"
        width="300px"
      />
      <Hexagon
        color={theme.blue800}
        transform="rotate(-6deg)"
        top="30px"
        left="100px"
        width="400px"
        height="400px"
      />
    </Flex>
  );
};
