import React from "react";
import { Flex } from "../../components/layouts/flex/Flex";
import { Box } from "../../components/elements/box/Box";
import { SkeletonLoading } from "../../components/elements/loading/SkeletonLoading";

type Props = {};

export const ASMUpdatesTimelineSkeleton = (props: Props) => {
  return (
    <Flex gap="8px" column>
      <Box>
        <Flex gap="4px" column>
          <SkeletonLoading width="80%" height="40px" />
          <SkeletonLoading width="50%" height="25px" />
          <SkeletonLoading width="100%" height="15px" />
        </Flex>
      </Box>
      <Box>
        <Flex gap="4px" column>
          <SkeletonLoading width="80%" height="40px" />
          <SkeletonLoading width="50%" height="25px" />
          <SkeletonLoading width="100%" height="15px" />
        </Flex>
      </Box>
      <Box>
        <Flex gap="4px" column>
          <SkeletonLoading width="80%" height="40px" />
          <SkeletonLoading width="50%" height="25px" />
          <SkeletonLoading width="100%" height="15px" />
        </Flex>
      </Box>
      <Box>
        <Flex gap="4px" column>
          <SkeletonLoading width="80%" height="40px" />
          <SkeletonLoading width="50%" height="25px" />
          <SkeletonLoading width="100%" height="15px" />
        </Flex>
      </Box>
      <Box>
        <Flex gap="4px" column>
          <SkeletonLoading width="80%" height="40px" />
          <SkeletonLoading width="50%" height="25px" />
          <SkeletonLoading width="100%" height="15px" />
        </Flex>
      </Box>
    </Flex>
  );
};
