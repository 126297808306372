import styled from "styled-components";
import { BodyRegular } from "../../elements/typography/Typography";

type AssigneeLabelProps = { isEditable?: boolean; isDisabled?: boolean };
export const AssigneeLabelStyle = styled(BodyRegular)<AssigneeLabelProps>`
  &:hover {
    color: ${(props) =>
      props.isEditable && !props.isDisabled
        ? props.theme.primary
        : ""} !important;
  }
`;

export const AssigneeItemStyle = styled(BodyRegular)`
  &:hover {
    font-weight: 600 !important;
  }
`;
