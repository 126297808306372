import React from "react";
import { DAY_MS } from "../../../shared/consts";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";

export interface ITimeframeOption {
  label: string;
  value: number;
}

export const defaultTimeframeOptions = [
  { label: "Last 7 days", value: 7 * DAY_MS },
  { label: "Last two weeks", value: 14 * DAY_MS },
  { label: "Last 30 days", value: 30 * DAY_MS },
  { label: "Last 6 months", value: 184 * DAY_MS },
  { label: "Last Year", value: 365 * DAY_MS },
];

export const overTimeTimeframeOptions = [
  { label: "Last 30 days", value: 30 * DAY_MS },
  { label: "Last 90 days", value: 90 * DAY_MS },
  { label: "Last Year", value: 365 * DAY_MS },
];

export const TimeframeDropdown = ({
  timeframe,
  setTimeframe,
  useDefaultOptions,
  options,
}: {
  timeframe: ITimeframeOption;
  setTimeframe: React.Dispatch<React.SetStateAction<ITimeframeOption>>;
  useDefaultOptions?: boolean;
  options?: ITimeframeOption[];
}) => {
  return (
    <Dropdown
      dataTestId="timeframe-dropdown"
      value={timeframe}
      isMenuPositionFixed
      options={
        options
          ? options
          : useDefaultOptions
          ? defaultTimeframeOptions
          : overTimeTimeframeOptions
      }
      onChange={(option) => {
        setTimeframe({
          value: Number(option?.value) || 0,
          label: option?.label || "",
        });
      }}
    />
  );
};
