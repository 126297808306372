import { GroupNavButton } from "../group/GroupButton";

type PageNavButtonProps = {
  onBackward: () => void;
  onForward: () => void;
  disabledLeft?: boolean;
  disabledRight?: boolean;
  dataTestId?: string;
  style?: any;
  totalPages?: number;
  currentPage?: number;
};

export const PageNavButton = (props: PageNavButtonProps) => {
  const {
    onBackward,
    onForward,
    dataTestId,
    disabledLeft = false,
    disabledRight = false,
    style,
    totalPages,
    currentPage,
  } = props;
  return (
    <GroupNavButton
      dataTestid={dataTestId}
      style={style}
      buttonsProps={[
        {
          onClick: onBackward,
          iconName: "chevronLeft",
          disabled: disabledLeft,
        },
        {
          onClick: onForward,
          iconName: "chevronRight",
          disabled: disabledRight,
        },
      ]}
      totalPages={totalPages}
      currentPage={currentPage}
    />
  );
};
