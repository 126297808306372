import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createItem, deleteItem, getItems, getPage, updateItem } from "./sdk";
import { CtiItem } from "../../types/CtiItem";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
} from "./utils";
import { bitAfterNow, getQueryParams } from "../../shared/helper";

const key = "cti-items";

export type CtiPage = {
  results: CtiItem[] | null | undefined;
  count: number | null | undefined;
  next: string | null | undefined;
  previous: string | null | undefined;
};

type CtiItemParams = CtiItem & {
  affected_products?: number[];
  customers?: number[];
  id?: number;
  image?: string;
  link?: string;
  title?: string;
  updated_at?: string;
  created_at?: string;
  onSuccessCallback?: (data: CtiItem) => void;
  onErrorCallback?: (error: Error) => void;
};

export const useApiCtiItems = (params?: { [key: string]: any }) =>
  useQuery<CtiItem[], Error>({
    queryKey: [key, "short"],
    keepPreviousData: true,
    placeholderData: [],
    enabled: true,
    queryFn: async (): Promise<CtiItem[]> => getItems(key, params),
  });

export const useApiCreateCtiItem = () => {
  const queryClient = useQueryClient();
  return useMutation<CtiItem, Error, CtiItemParams>({
    mutationKey: [key, "create"],
    mutationFn: async (newCtiItem): Promise<CtiItem> =>
      await createItem(key, newCtiItem),
    onSuccess: (data: CtiItem, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};

export const useApiCtiItemsPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useInfiniteQuery<CtiPage, Error>({
    queryKey: [key],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<CtiPage> =>
      getPage(key, { ...filters, page: pageParam }).then((resp) => {
        var page = { ...resp };
        const CtisDicts = page?.results || [];
        page.results = CtisDicts as CtiItem[];
        return page;
      }),
    getNextPageParam: (lastPage) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiUpdateCtiItem = (filters?: { [key: string]: any }) => {
  const queryClient = useQueryClient();
  return useMutation<CtiItem, Error, CtiItemParams>({
    mutationKey: [key, "update"],
    mutationFn: async ({ ...ctiItemData }): Promise<CtiItem> =>
      await updateItem(key, ctiItemData.id as number, ctiItemData, filters),
    onSuccess: (data: CtiItem, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiDeleteCtiItem = () => {
  const queryClient = useQueryClient();
  return useMutation<CtiItem, Error, CtiItemParams>({
    mutationKey: [key],
    mutationFn: async ({ ...ctiItemData }: CtiItemParams): Promise<any> =>
      await deleteItem(key, ctiItemData, ctiItemData.id as number),
    onSuccess: (data: CtiItem, { onSuccessCallback }) => {
      removeItemFromContext({
        data,
        queryKey: [key, { id: data.id }],
        queryClient,
      });
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};
