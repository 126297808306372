import { IconProps } from "./WaspIcon";

export function WAFIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0063 3.99371H6.99371C6.19832 3.99454 5.43574 4.31088 4.87331 4.87331C4.31088 5.43574 3.99454 6.19832 3.99371 6.99371V17.0063C3.99454 17.8017 4.31088 18.5643 4.87331 19.1267C5.43574 19.6891 6.19832 20.0054 6.99371 20.0063H17.0063C17.8017 20.0054 18.5643 19.6891 19.1267 19.1267C19.6891 18.5643 20.0054 17.8017 20.0063 17.0063V6.99371C20.0054 6.19832 19.6891 5.43574 19.1267 4.87331C18.5643 4.31088 17.8017 3.99454 17.0063 3.99371ZM15.516 8.90399V11.4H8.48399V8.90399H15.516ZM18.8066 8.90399V11.4H16.716V8.90399H18.8066ZM7.28399 11.4H5.19371V8.90399H7.28399V11.4ZM5.19335 12.6H11.4V15.108H5.19371L5.19335 12.6ZM15.516 16.308V18.8065H8.48399V16.308H15.516ZM12.6 15.108V12.6H18.8063V15.108H12.6ZM18.8063 6.99395V7.70399H12.6V5.19371H17.0063C17.4835 5.19425 17.941 5.38407 18.2785 5.72152C18.6159 6.05897 18.8057 6.51649 18.8063 6.99371V6.99395ZM6.99371 5.19395H11.4V7.70399H5.19371V6.99371C5.19425 6.51649 5.38407 6.05897 5.72152 5.72152C6.05897 5.38407 6.51649 5.19425 6.99371 5.19371V5.19395ZM5.19371 17.0065V16.308H7.28399V18.8065H6.99371C6.51645 18.806 6.05889 18.6161 5.72143 18.2786C5.38398 17.9411 5.19419 17.4835 5.19371 17.0063V17.0065ZM17.0063 18.8065H16.716V16.308H18.8066V17.0065C18.806 17.4838 18.6161 17.9413 18.2786 18.2787C17.9411 18.6161 17.4835 18.8058 17.0063 18.8063V18.8065Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
