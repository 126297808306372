import { SkeletonLoading } from "../loading/SkeletonLoading";
import { SeparatorVertical } from "../separators/SeparatorVertical";

type Props = {
  width?: string;
  noSeparator?: boolean;
};

export const SectionSkeleton = (props: Props) => {
  const { noSeparator, width } = props;

  return (
    <div
      className="d-flex justify-content-between align-items-center col px-1"
      style={{
        overflowX: "clip",
      }}
    >
      <div
        className="d-flex flex-column gap-8 pe-2"
        style={{ width: width || "100%" }}
      >
        <SkeletonLoading width="100px" height="20px" />
        <div
          className="d-flex align-items-center gap-8"
          style={{
            overflowX: "clip",
          }}
        >
          <SkeletonLoading width={width || "100%"} height="40px" />
        </div>
      </div>
      {!noSeparator && <SeparatorVertical style={{ height: "32px" }} />}
    </div>
  );
};
