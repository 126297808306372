import { SingleValue } from "react-select";
import { FindingsOverTimeStatsItem } from "../../../../types/Finding";
import { Option } from "../../../../components/elements/dropdowns/Dropdown";

const count = 0;
const overall_risk = 0;

export const sortByDate = (a: any, b: any) => {
  if (a.year === b.year) {
    if (a.month === b.month) {
      if (!a.day || !b.day) return 0;
      if (a.day > b.day) return 1;
      if (a.day < b.day) return -1;
    }
    if (a.month > b.month) return 1;
    if (a.month < b.month) return -1;
  }
  if (a.year > b.year) return 1;
  if (a.year < b.year) return -1;
  return 0;
};

const daysInPreviousMonth = (): number => {
  const date = new Date();
  const lastMonth = new Date(date.getFullYear(), date.getMonth() - 2);
  lastMonth.setDate(0);
  return lastMonth.getDate();
};

export const completeMissingMonths = (
  data: FindingsOverTimeStatsItem[],
  timeframe: SingleValue<Option>
) => {
  const today: Date = new Date();
  const thisMonth = today.getMonth();
  const thisYear = today.getFullYear();
  const months = [];

  // Get list of last 12 or 3 months
  const monthsInTimeframe = timeframe?.value === 365 ? 11 : 2;
  for (let i = 0; i < monthsInTimeframe; i++) {
    const date = new Date();
    if (thisMonth + 1 - i <= 0) {
      date.setMonth(thisMonth + 12 - i);
      date.setFullYear(thisYear - 1);
    } else {
      date.setMonth(thisMonth - i);
    }
    months.push({
      year: date.getFullYear(),
      month: date.getMonth() + 1, // Months are 0-indexed, so add 1
    });
  }
  // console.log(months);

  // Iterate over the last 12 months and check if each exists in the data
  months.forEach((monthObj) => {
    const { year, month } = monthObj;
    const found = data.some(
      (item) => item.year === year && item.month === month
    );
    // Add missing month object to the data if not found
    if (!found) data.push({ count, year, month, overall_risk });
  });

  // Return sorted data by year and month
  return data.sort(sortByDate);
};

export const completeMissingDays = (data: FindingsOverTimeStatsItem[]) => {
  const today: Date = new Date();
  const thisDate = today.getDate();
  const thisMonth = today.getMonth();
  const thisYear = today.getFullYear();

  const days = [];

  // Get list of last 30 days
  for (let i = 0; i < 30; i++) {
    const date = new Date();
    if (thisDate - i <= 0) {
      date.setDate(thisDate + daysInPreviousMonth() - i);
      if (thisMonth === 0) {
        date.setMonth(11); //If month is Jan(0) set it to Dec(11) // Months are 0-indexed
        date.setFullYear(thisYear - 1);
      } else {
        date.setMonth(thisMonth - 1);
      }
    } else {
      date.setDate(thisDate - i);
    }
    days.push({
      year: date.getFullYear(),
      month: date.getMonth() + 1, // Months are 0-indexed, so add 1
      day: date.getDate(),
    });
  }
  // console.log(days);

  // Iterate over the last 30 days and check if each exists in the data
  days.forEach((dayObj) => {
    const { year, month, day } = dayObj;
    const found = data.some(
      (item) => item.year === year && item.month === month && item.day === day
    );
    // Add missing day object to the data  if not found
    if (!found) data.push({ count, year, month, day, overall_risk });
  });

  // return sorted data by year, month and day
  return data.sort(sortByDate);
};
