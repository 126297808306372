import React, { useContext } from "react";
import { Box } from "../../components/elements/box/Box";
import { useApiAssetsCount } from "../../hooks/queries/assetsContext";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  BodyMedium,
  BodyRegular,
  BodySemiBold,
  LabelRegular,
} from "../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { SeparatorHorizontal } from "../../components/elements/separators/SeparatorHorizontal";
import { SkeletonLoading } from "../../components/elements/loading/SkeletonLoading";
import { IconButton } from "../../components/elements/button/icon/IconButton";
import { useNavigate } from "react-router";
import { toBase64AssetsView } from "../../shared/helper";
import { Tooltip } from "../../components/elements/tooltip/Tooltip";
import { VulnerabilityScanners } from "./ASMUpdates";

type Props = {
  runningVulnerabilitiesScanners: number;
};

export const ASMUpdatesCurrentStatus = ({
  runningVulnerabilitiesScanners,
}: Props) => {
  const theme = useContext(ThemeContext);
  const { data: assetsCounts, isFetching: isFetchingAssetsCounts } =
    useApiAssetsCount();
  const navigate = useNavigate();

  return (
    <Box>
      <Flex column gap="16px">
        {isFetchingAssetsCounts ? (
          <SkeletonLoading height="25px" width="75%" />
        ) : (
          <Flex justify="between" align="center" gap="8px">
            <Flex gap="4px">
              <BodySemiBold
                className="text-truncate"
                title={`${assetsCounts?.is_asm_enabled || 0} Assets in scope`}
              >{`${
                assetsCounts?.is_asm_enabled || 0
              } Assets in scope`}</BodySemiBold>
              <BodyRegular
                className="text-truncate"
                title={`(out of ${assetsCounts?.total || 0})`}
                color={theme.textSub}
              >{`(out of ${assetsCounts?.total || 0})`}</BodyRegular>
            </Flex>
            {(assetsCounts?.total || 0) - (assetsCounts?.is_asm_enabled || 0) >
              0 && (
              <Tooltip content={"Add assets to ASM scope"}>
                <IconButton
                  iconName="add"
                  size="small"
                  onClick={() =>
                    navigate(
                      `/assets/edit?view=${toBase64AssetsView({
                        filters: [
                          {
                            column: "is_asm_enabled",
                            value: "Disabled",
                            condition: "is",
                            order: 0,
                            next_condition: "and",
                          },
                        ],
                        name: "",
                      })}`
                    )
                  }
                />
              </Tooltip>
            )}
          </Flex>
        )}
        <SeparatorHorizontal />
        <Flex column gap="4px">
          {runningVulnerabilitiesScanners === 0 ? (
            <>
              <BodyMedium>All scans are complete for today</BodyMedium>
              <LabelRegular color={theme.textSub}>
                The next run will start at midnight
              </LabelRegular>
            </>
          ) : (
            <BodyMedium>{`${runningVulnerabilitiesScanners} out of ${VulnerabilityScanners.length} scanners are currently running`}</BodyMedium>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
