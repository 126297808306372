import { ReactNode, useContext } from "react";
import { MainButton } from "../../elements/button/main/MainButton";
import { Modal } from "../../elements/modal/Modal";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../elements/typography/Typography";
import { Flex } from "../../layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { TextButton } from "../../elements/button/text/TextButton";
import { Illustration } from "../../elements/illustrations/Illustration";

type Props = {
  header: string;
  body?: ReactNode;
  approveButtonLabel?: string;
  onApprove: () => void;
  onClose: () => void;
};

export const WarningModal = (props: Props) => {
  const {
    header,
    body,
    onApprove,
    onClose,
    approveButtonLabel = "Change",
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <Modal onClose={onClose} width="414px">
      <Flex column gap="24px" align="center">
        <Illustration name="error" />
        <HeaderSecondary>{header}</HeaderSecondary>
        {body && (
          <BodyRegular style={{ textAlign: "center" }}>{body}</BodyRegular>
        )}
        <MainButton
          label={approveButtonLabel}
          onClick={onApprove}
          style={{ background: theme.redPrimary, width: "100%" }}
          size="medium"
        />
        <TextButton color={theme.black800} label="Cancel" onClick={onClose} />
      </Flex>
    </Modal>
  );
};
