import { Dispatch, SetStateAction } from "react";
import { AdminFindingEdit, Finding } from "../../../types/Finding";
import { useApiAssetsGetById } from "../../../hooks/queries/assetsContext";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { SingleValue } from "react-select";
import { Option } from "../../../components/elements/dropdowns/Dropdown";
import { Flex } from "../../../components/layouts/flex/Flex";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { AssetsDropdown } from "../../projects/projectPane/AssetsDropdown";
import { useUpdateFindingInPlace } from "../../../shared/formUtils";
import { TagBadge } from "../../../components/composed/tagsLine/TagBadge";
import { useParams } from "react-router";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import { TagsLine } from "../../../components/composed/tagsLine/TagsLine";
import { Asset } from "../../../types/Asset";

type Props = {
  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const EditAffectedSuperusers = (props: Props) => {
  const {
    editableUpdateFindingData,
    setEditableUpdateFinding,
    setUpdateFinding,
    setCachedFindingData,
  } = props;

  const { data: customers } = useApiCustomers();
  const { id: updateFindingId } = useParams();

  const { updateInPlace, queryStatus } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  // HANDLE ASSETS
  const { data: assets } = useApiAssetsGetById(
    editableUpdateFindingData?.affected_assets || [],
    { "admin-mode": true }
  );

  const { data: products } = useApiProducts(
    {
      id: editableUpdateFindingData?.products,
      "admin-mode": true,
    },
    !!editableUpdateFindingData?.products?.length
  );

  const onAssetsAddedHandler = (
    assetOption: SingleValue<Option>,
    asset: Asset | undefined
  ) => {
    if (!!assetOption && asset) {
      setEditableUpdateFinding &&
        setEditableUpdateFinding((prev) => ({
          ...prev,
          affected_assets: prev?.affected_assets
            ? [...prev.affected_assets, Number(assetOption.value)]
            : [Number(assetOption.value)],
          affected_assets_displayed: prev?.affected_assets_displayed
            ? [...prev?.affected_assets_displayed, assetOption.label]
            : [assetOption.label],
          products: prev?.products
            ? [...new Set([...prev?.products, asset.product])]
            : [asset.product],
        }));
      const data = {
        affected_assets: editableUpdateFindingData?.affected_assets
          ? [
              ...editableUpdateFindingData?.affected_assets,
              Number(assetOption.value),
            ]
          : [Number(assetOption.value)],
        affected_assets_displayed:
          editableUpdateFindingData?.affected_assets_displayed
            ? [
                ...editableUpdateFindingData?.affected_assets_displayed,
                assetOption.label,
              ]
            : [assetOption.label],
        products: editableUpdateFindingData?.products
          ? [
              ...new Set([
                ...editableUpdateFindingData?.products,
                asset.product,
              ]),
            ]
          : [asset.product],
      };
      updateInPlace(data);
      setCachedFindingData && setCachedFindingData(data);
    }
  };

  const onAssetsDeletedHandler = (
    assetOption: SingleValue<Option>,
    asset: Asset
  ) => {
    if (!!assetOption && !!asset) {
      setEditableUpdateFinding &&
        setEditableUpdateFinding((prev) => ({
          ...prev,
          affected_assets: prev?.affected_assets
            ? prev.affected_assets.filter(
                (asset) => asset !== Number(assetOption.value)
              )
            : [],
          affected_assets_displayed: prev?.affected_assets_displayed
            ? prev.affected_assets_displayed.filter(
                (label) => label !== assetOption.label
              )
            : [],
          products: prev?.products
            ? prev?.products.filter((id) => id !== asset.product)
            : [],
        }));
      const data = {
        affected_assets: editableUpdateFindingData?.affected_assets
          ? editableUpdateFindingData.affected_assets.filter(
              (asset) => asset !== Number(assetOption.value)
            )
          : [],
        affected_assets_displayed:
          editableUpdateFindingData?.affected_assets_displayed
            ? editableUpdateFindingData.affected_assets_displayed.filter(
                (label) => label !== assetOption.label
              )
            : [],
        products: editableUpdateFindingData?.products
          ? editableUpdateFindingData?.products.filter(
              (id) => id === asset.product
            )
          : [],
      };
      updateInPlace(data);
      setCachedFindingData && setCachedFindingData(data);
    }
  };

  return (
    <Flex column gap="32px" data-testid="superuser-edit-affected">
      <Flex column gap="8px">
        <Flex justify="between">
          <LabelRegular>Affected Products</LabelRegular>
        </Flex>
        <TagsLine
          isEditable={false}
          selectedTags={
            products
              ?.filter((product) =>
                editableUpdateFindingData?.products?.includes(product.id)
              )
              ?.map((product) => product.name) || []
          }
        />
      </Flex>

      <Flex column gap="8px">
        <Flex justify="between">
          <Flex>
            <LabelRegular>Affected Assets</LabelRegular>
          </Flex>
        </Flex>
        <AssetsDropdown
          size="medium"
          customer={
            customers?.filter(
              (c) => c.id === editableUpdateFindingData?.customer
            )[0]
          }
          disabled={queryStatus !== "idle"}
          selected={editableUpdateFindingData?.affected_assets || []}
          onChange={(opt, asset) => onAssetsAddedHandler(opt, asset)}
        />
        <Flex flexWrap gap="8px">
          {!!editableUpdateFindingData?.affected_assets?.length &&
            assets?.map((asset) => (
              <TagBadge
                key={asset.id}
                option={{ label: asset.name, value: asset.id }}
                onDeleteOption={(opt) => onAssetsDeletedHandler(opt, asset)}
                isEditable
                isDisabled={queryStatus !== "idle"}
              />
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
