import { useQuery } from "@tanstack/react-query";

import { getItems } from "./sdk";
import {
  emptyGlobalSearchData,
  GlobalSearchData,
} from "../../types/GlobalSearchData";

const key = "global-search";

export const useApiGlobalSearch = (searchText: string) =>
  useQuery<GlobalSearchData, Error>({
    queryKey: [key, searchText],
    keepPreviousData: true,
    placeholderData: emptyGlobalSearchData,
    enabled: !!searchText,
    queryFn: async (): Promise<GlobalSearchData> =>
      getItems(key, { search: searchText }),
  });
