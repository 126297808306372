import { useQuery } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "../../../hooks/queries/utils";
import { bitAfterNow } from "../../../shared/helper";
import { getItems } from "../../../hooks/queries/sdk";

const key = "findings_trends";

type findingsTrend = {
  date: string;
  total_count: number;
};

export const useApiFindingsTrends = (filters?: { [key: string]: any }) =>
  useQuery<findingsTrend[], Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<findingsTrend[]> => getItems(key, filters),
  });
