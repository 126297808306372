import { Box } from "../../../components/elements/box/Box";
import {
  BodyMedium,
  BodyRegular,
  HeaderSecondary,
  LabelMini,
  TypographyBodyMedium,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { getDate } from "../../../shared/helper";
import { WhatsNew, WhatsNewParams } from "../../../types/WhatsNew";
import { Mixpanel } from "../../../shared/mixpanel";
import styled from "styled-components";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { useState } from "react";

type EmojiStyleProps = {
  fontSize: number;
  isSelected: boolean;
  disabled?: boolean;
};

export const EmojiStyle = styled.span<EmojiStyleProps>`
  ${TypographyBodyMedium}
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: ${(props) => props.fontSize + 11}px;
  height: ${(props) => props.fontSize + 11}px;
  border-radius: 50%;
  border: 5px solid;
  border-color: ${(props) =>
    props.isSelected ? props.theme.primary : "transparent"};
  font-size: ${(props) => props.fontSize}px;
  &: hover {
    font-size: ${(props) => !props.isSelected && props.fontSize + 8}px;
  }
`;

type Feedback = "negative" | "neutral" | "positive";

type Props = {
  whatsNew?: WhatsNew;
  whatsNewPreview?: WhatsNewParams;
};

export const WhatsNewItem = (props: Props) => {
  const { whatsNew, whatsNewPreview } = props;

  const featureName =
    whatsNewPreview?.feature_name || whatsNew?.feature_name || "";
  const htmlContent = whatsNewPreview?.html || whatsNew?.html || "";
  const publishedAt = whatsNew?.published_at || "N/A";

  const handleEmojiClick = (feedback: Feedback) => {
    Mixpanel.track("feature feedback", { featureName, feedback });
    localStorage.setItem(`featureFeedback${whatsNew?.id}`, feedback);
    setSavedFeedback(feedback);
  };

  const [savedFeedback, setSavedFeedback] = useState(
    localStorage.getItem(`featureFeedback${whatsNew?.id}`) || ""
  );

  return (
    <Box>
      <Flex
        column
        align="center"
        gap="12px"
        style={{ padding: "24px", position: "relative" }}
      >
        <HeaderSecondary>{featureName}</HeaderSecondary>
        <BodyRegular dangerouslySetInnerHTML={{ __html: htmlContent }} />

        <Flex
          column
          style={{ height: "60px" }}
          align="center"
          justify="center"
          gap="8px"
        >
          <Flex gap="12px" align="center" w100>
            <BodyMedium>Tell us what you think</BodyMedium>
            <Tooltip content={"I Didn't like it"}>
              <EmojiStyle
                fontSize={24}
                onClick={() => handleEmojiClick("negative")}
                isSelected={savedFeedback === "negative"}
              >
                😞
              </EmojiStyle>
            </Tooltip>
            <Tooltip content={"Mah..."}>
              <EmojiStyle
                fontSize={24}
                onClick={() => handleEmojiClick("neutral")}
                isSelected={savedFeedback === "neutral"}
              >
                😐
              </EmojiStyle>
            </Tooltip>
            <Tooltip content={"I like it!"}>
              <EmojiStyle
                fontSize={24}
                onClick={() => handleEmojiClick("positive")}
                isSelected={savedFeedback === "positive"}
              >
                😃
              </EmojiStyle>
            </Tooltip>
          </Flex>
        </Flex>

        <LabelMini style={{ position: "absolute", bottom: 0 }}>
          Published on {getDate(publishedAt)}
        </LabelMini>
      </Flex>
    </Box>
  );
};
